import { FC, Fragment, useState } from 'react'
import styled from 'styled-components'
import { CancelIcon } from '../../../assets/icons/crow'
import BackButton from '../../../components/BackButton'
import Drawer from '../../../components/Crow/Drawer/Drawer'
import { colors } from '../../../components/Crow/Style/theme'
import Tile from '../../../components/Crow/Tile/Tile'
import { Heading3, Title } from '../../../components/Crow/Typography'
import FullscreenPage from '../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import { displayNewNavigationMenu } from '../../../configs'
import useI18n from '../../../i18n/useI18n'
import { useLeakageDetectors } from '../../../services/requestHooks/leakageDetectors'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { useOnMount } from '../../../utils/commonHooks'
import FloorName from '../../../utils/places/FloorName'
import RoomName from '../../../utils/places/RoomName'
import { RoomSwitchIcon } from '../../../utils/places/RoomSwitchIcon'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import LeakageDetectorDetails from './LeakageDetectorDetails'
import LeakageDetectorsTileFooter from './LeakageDetectorsTileFooter'
import { Device, Section } from '../../../types/LeakageDetectors'

const CrowLeakageDetectorsPage: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const returnUrl = `/systems/${panelId}`
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false)
  const [leakageDetectorState, setLeakageDetectorState] = useState<Section>()

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
    error: panelStatusError,
  } = usePanelStatus()

  const {
    data: leakageDetectors,
    run: getLeakageDetectors,
    isLoading: loadingLeakageDetectors,
    error: LeakageDetectorError,
  } = useLeakageDetectors()

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadInfoError,
  } = usePanelInfo()

  const hasError = !!LeakageDetectorError || !!panelStatusError || !!loadInfoError

  useOnMount(() => {
    if (!loadingPanelInfo) {
      loadPanelInfo(panelId)
    }
    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
    if (!loadingLeakageDetectors) {
      getLeakageDetectors(panelId)
    }
  })

  const openDrawer = (
    floors: Section,
    roomName: string,
    roomKey: string,
    roomIndex: number | undefined,
    roomDevices: Device[],
  ) => () => {
    setLeakageDetectorState({
      Name: floors?.Name!,
      Key: floors?.Key!,
      Rooms: [
        {
          Name: roomName,
          Key: roomKey,
          Index: roomIndex,
          Devices: roomDevices,
        },
      ],
    })
    setDrawerIsOpen(true)
  }

  return (
    <>
      <TcOrCrowContainer panelInfo={panelInfo} fullscreen footer>
        <FullscreenPage
          isLoading={loadingLeakageDetectors || loadingPanelStatus}
          loaderProps={{
            loadingText: { text: t('Loading') },
            errorHandling: {
              loadingFailed: !loadingLeakageDetectors && hasError && !panelStatus?.IsOnline,
              errorHeaderText: t('Leakage_detectors'),
              errorText: !panelStatus?.IsOnline
                ? t(
                    'Your alarm system is not conntected to internet, your internet connection is offline',
                  )
                : t('Something went wrong. Please try again'),
            },
          }}
        >
          {!displayNewNavigationMenu && <BackButton backUrl={returnUrl} />}
          <Title large>{t('Leakage_detectors')}</Title>
          <>
            <StyledUl>
              {leakageDetectors?.Floors.map((floor) => (
                <SectionWrapper key={floor.Key} className="qa-area-container">
                  <SectionTitle className="qa-area-name">
                    <FloorName floorName={floor.Key} />
                  </SectionTitle>
                  <TilesWrapper>
                    {floor.Rooms.map((room) => (
                      <Tile
                        key={room.Key}
                        onClick={openDrawer(floor, room.Name, room.Key!, room.Index, room.Devices)}
                      >
                        <TileTop>
                          <RoomSwitchIcon roomKey={room.Key} />
                        </TileTop>
                        <TileCenter>
                          <RoomName roomName={room.Key} />
                          {room.Index && room.Index > 0 ? <span>&nbsp;{room.Index}</span> : null}
                        </TileCenter>
                        <TileBottom>
                          <FooterDevices>{t('Sensors')}</FooterDevices>
                          <LeakageDetectorsTileFooter props={room.Devices} />
                        </TileBottom>
                      </Tile>
                    ))}
                  </TilesWrapper>
                </SectionWrapper>
              ))}
              <Drawer
                isOpen={drawerIsOpen}
                position="right"
                handleClose={() => setDrawerIsOpen(false)}
                closeButton={true}
                closeIcon={<CancelIcon color={colors.blue} />}
                title={
                  <Header>
                    {leakageDetectorState?.Rooms?.map((room) => (
                      <Fragment key={room.Key}>
                        <span className="mr-auto">
                          <RoomName roomName={room.Key} />{' '}
                          {room.Index && room.Index > 0 ? <span>{room.Index}&nbsp;</span> : null}(
                          {<FloorName floorName={leakageDetectorState.Key} />})
                        </span>
                        <IconWrapper>
                          <RoomSwitchIcon roomKey={room.Key} />
                        </IconWrapper>
                      </Fragment>
                    ))}
                  </Header>
                }
              >
                <LeakageDetectorDetails floor={leakageDetectorState!} />
              </Drawer>
            </StyledUl>
          </>
        </FullscreenPage>
      </TcOrCrowContainer>
    </>
  )
}

const StyledUl = styled.ul`
  padding: 0;
  margin-top: 40px;
`

const FooterDevices = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${colors.black900};
`

const SectionTitle = styled(Heading3)`
  margin-left: 8px;
  color: ${colors.black700};
`

const Header = styled.div`
  padding-bottom: 50px;
  display: flex;
`

const IconWrapper = styled.span`
  position: relative;
  bottom: 15px;
`

const SectionWrapper = styled.div`
  margin-top: 24px;

  &:first-child {
    margin-top: 42px;
  }
`

const TilesWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media only screen and (max-width: 600px) {
    gap: 15px;
  }
`

const TileTop = styled.div`
  display: flex;
  justify-content: space-between;
`

const TileCenter = styled(Heading3)`
  display: flex;
  align-items: center;
  line-height: 22px;
  color: ${colors.black800};
  max-width: 138px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const TileBottom = styled.div`
  display: flex;
  flex-direction: column;
`

export default CrowLeakageDetectorsPage
