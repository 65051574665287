import { HomeIcon } from '../../../../assets/icons'
import {
  ArmedIcon,
  DisarmedIcon,
  DoorLockedIcon,
  DoorLockFailedIcon,
  DoorUnlockedIcon,
  PartiallyArmedIcon,
  PowerOffIcon,
  PowerOnIcon,
  SignOutIcon,
  WifiOffIcon,
  WifiOnIcon,
} from '../../../../assets/icons/crow'
import { colors } from '../../../../components/Crow/Style/theme'
import { tFunc } from '../../../../types/i18n'
import { PanelEvent } from '../../../../types/PanelEvent'
import { IconContainer } from '../HistoryStyle'

export const EventIcon = ({ eventType }: { eventType: string }) => {
  switch (eventType) {
    case 'armed':
    case 'armed_and_lock':
      return (
        <IconContainer className="w-10 h-10" color={colors.black900}>
          <ArmedIcon color={colors.white} />
        </IconContainer>
      )
    case 'armedannex':
      return (
        <IconContainer className="w-10 h-10" color={colors.black900}>
          <ArmedIcon color={colors.white} />
        </IconContainer>
      )
    case 'partialarmed':
    case 'partialarmed_and_lock':
      return (
        <IconContainer className="w-10 h-10" color={colors.white} border={colors.black800}>
          <PartiallyArmedIcon color={colors.black800} />
        </IconContainer>
      )
    case 'disarmed':
    case 'disarmed_and_unlock':
      return (
        <IconContainer className="w-10 h-10" color={colors.lightGray} border={colors.lightGray}>
          <DisarmedIcon color={colors.black800} />
        </IconContainer>
      )
    case 'disarmedannex':
      return (
        <IconContainer className="w-10 h-10" color={colors.lightGray} border={colors.lightGray}>
          <DisarmedIcon color={colors.black800} />
        </IconContainer>
      )
    case 'lock':
      return (
        <IconContainer className="w-10 h-10" border={colors.blue}>
          <DoorLockedIcon color={colors.blue} />
        </IconContainer>
      )
    case 'unlock':
      return (
        <IconContainer className="w-10 h-10" color={colors.lightGray} border={colors.lightGray}>
          <DoorUnlockedIcon color={colors.black800} />
        </IconContainer>
      )
    case 'lock_failed':
      return (
        <IconContainer className="w-10 h-10" border={colors.red}>
          <DoorLockFailedIcon color={colors.red} />
        </IconContainer>
      )
    case 'mains':
      return (
        <IconContainer className="w-10 h-10" color={colors.red} border={colors.red}>
          <PowerOffIcon color={colors.white} />
        </IconContainer>
      )
    case 'mainsrestore':
    case 'mains_restored':
      return (
        <IconContainer className="w-10 h-10" color={colors.green} border={colors.lightGreen}>
          <PowerOnIcon color={colors.white} />
        </IconContainer>
      )
    case 'Area_Disarmed_By_Doorlock_Log':
      return (
        <IconContainer className="w-6 h-6" border={colors.black800}>
          <DoorUnlockedIcon color={colors.black800} />
        </IconContainer>
      )
    case 'Area_Armed_By_Doorlock_Log':
    case 'Area_Stay_Armed_By_Doorlock_Log':
      return (
        <IconContainer className="w-6 h-6" border={colors.blue}>
          <DoorLockedIcon color={colors.blue} />
        </IconContainer>
      )
    case 'internet_lost':
      return (
        <IconContainer className="w-10 h-10" color={colors.white} border={colors.red}>
          <WifiOffIcon color={colors.red} />
        </IconContainer>
      )
    case 'internet_restored':
      return (
        <IconContainer className="w-10 h-10" color={colors.white} border={colors.green}>
          <WifiOnIcon color={colors.green} />
        </IconContainer>
      )
    case 'output_activated':
      return (
        <IconContainer
          style={{ width: '40px', height: '40px' }}
          color={colors.white}
          border={colors.blue}
        >
          <SignOutIcon color={colors.blue} />
        </IconContainer>
      )
    case 'output_deactivated':
      return (
        <IconContainer style={{ width: '40px', height: '40px' }} border={colors.black900}>
          <SignOutIcon color={colors.black900} />
        </IconContainer>
      )
    default:
      return (
        <IconContainer className="w-10 h-10" border={colors.black800}>
          <HomeIcon />
        </IconContainer>
      )
  }
}

export const getEventText = (event: PanelEvent, t: tFunc) => {
  const userName = event.UserTranslationKey ? t(event.UserTranslationKey) : event.User
  const lockName = event.LockName

  if (!userName || userName === 'user_not_found') {
    return ''
  }

  switch (event.UserTranslationKey) {
    case 'guard_user':
      return `${t('by')} ${t('guard_user')}`
    case 'installer_user':
      return `${t('by')} ${t('installer_user')}`
  }

  switch (event.EventType) {
    case 'mainsrestore':
    case 'mains_restored':
      return `${t('mains_restored')}`
    case 'mains':
      return `${t('mains')}`
    case 'armedannex':
      return `${t('by')} ${userName}`
    case 'armed':
    case 'disarmed':
    case 'partialarmed':
      return `${t('by')} ${userName}`
    case 'armed_and_lock':
    case 'partialarmed_and_lock':
      return t('history_event_armed_and_lock', { lockName })
    case 'disarmed_and_unlock':
      return t('history_event_disarmed_and_unlock', { lockName })
    case 'lock':
    case 'unlock':
      return userName && `${t('by')} ${userName}`
  }
}

export const getEventTitle = (event: PanelEvent, t: tFunc): string => {
  const { EventType, LockName } = event

  switch (EventType) {
    case 'mainsrestore':
    case 'mains_restored':
      return t('regain power')
    case 'mains':
      return t('lost power')
    case 'disarmed':
    case 'disarmed_and_unlock':
      return `${t('HISTORY_EVENT_DISARMED')}`
    case 'armed':
    case 'armed_and_lock':
      return `${t('HISTORY_EVENT_ARMED')}`
    case 'partialarmed':
    case 'partialarmed_and_lock':
      return `${t('HISTORY_EVENT_PARTIAL')}`
    case 'armedannex':
      return `Annex ${t('Fully armed')}`
    case 'disarmedannex':
      return `Annex ${t('disarmed')}`
    case 'lock':
      return `${LockName} ${t('history_locked')}`
    case 'unlock':
      return `${LockName} ${t('history_unlocked')}`
    case 'lock_failed':
      return `${LockName} ${t('lock_failed')}`
    case 'internet_restored':
      return t('internet_restored')
    case 'internet_lost':
      return t('internet_lost')
    case 'output_activated':
      return `${LockName} ${t('On')}`
    case 'output_deactivated':
      return `${LockName} ${t('Off')}`
    case 'Area_Armed_By_Doorlock_Log':
      return t('Area_Armed_By_Doorlock_Log')
    case 'Area_Disarmed_By_Doorlock_Log':
      return t('Area_Disarmed_By_Doorlock_Log')
    case 'Area_Stay_Armed_By_Doorlock_Log':
      return t('Area_Stay_Armed_By_Doorlock_Log')
    case 'battery_low':
      return t('BATTERY_LOW')
    case 'battery_restored':
      return t('BATTERY_RESTORED')
    default:
      return ''
  }
}
