import React, { FC, FormEventHandler } from 'react'
import useI18n from '../../../i18n/useI18n'
import FormButtons from '../../../components/forms/FormButtons'

interface Props {
  onSubmit: () => void
  isLoading: boolean
}

const RequestCodeForm: FC<Props> = ({ onSubmit, isLoading, children }) => {
  const { t } = useI18n()

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
    onSubmit()
  }

  return (
    <form onSubmit={handleSubmit} className="mx-4 slide-from-right">
      <h1 className="text-2xl mb-8">{t('request_code_to_register_account')}</h1>
      {children}
      <FormButtons isLoading={isLoading} submitText="REQUEST_CODE" cancelUrl={'/'} />
    </form>
  )
}

export default RequestCodeForm
