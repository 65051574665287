import React from 'react'

import styled from 'styled-components'
import useI18n from '../../../i18n/useI18n'

import { Trans } from 'react-i18next'
import { colors } from '../../../components/Crow/Style/theme'
import { SubTitle } from '../../../components/Crow/Typography'
import { useResourceInfo } from '../../../services/requestHooks/user'
import { Section, Device } from '../../../types/LeakageDetectors'
import CrowCard from '../../../components/Crow/CrowCard/CrowCard'
import DeviceName from '../../../utils/places/DeviceName'

interface LeakageDetectorDetailsType {
  floor: Section
}

const LeakageDetectorDetails = ({ floor }: LeakageDetectorDetailsType) => {
  const { t } = useI18n()
  const resources = useResourceInfo()

  return (
    <LeakageDetectorDetailsContainer key={floor.Key}>
      {floor.Rooms.flatMap((room: { Devices: Device[] }) =>
        room.Devices.map((device, index) => (
          <React.Fragment key={device.SerialString}>
            <LeakageDetectorCrowCard
              key={device.SerialString}
              as="li"
              className={`qa-${device.Label}-${device.Id}`}
            >
              <DeviceTitle light>
                <DeviceName deviceName={device.Type} />
                &nbsp;{room.Devices.length > 1 && index + 1}
              </DeviceTitle>
              <DeviceInfo>
                {device.LowBattery && <DisplayLowBattery>{t('BATTERY_LOW')}</DisplayLowBattery>}
              </DeviceInfo>
            </LeakageDetectorCrowCard>
            {device.LowBattery && (
              <CustomerServiceParagraph>
                <Trans
                  i18nKey="CUSTOMER_SERVICE_VISIT"
                  components={{
                    address: (
                      <a href={resources.CustomerServiceUrl} target="blank">
                        Customer Service
                      </a>
                    ),
                  }}
                />
              </CustomerServiceParagraph>
            )}
          </React.Fragment>
        )),
      )}
    </LeakageDetectorDetailsContainer>
  )
}

const CustomerServiceParagraph = styled.p`
  a {
    color: ${colors.blue};
    text-decoration: underline;
  }
`
const DeviceInfo = styled.div`
  display: flex;
  margin-left: auto;
`

const DeviceTitle = styled(SubTitle)`
  margin: 0;
  line-height: 16px;
  margin: 0.5rem 0;
`

const DisplayLowBattery = styled.div`
  display: inline-block;
  background-color: ${colors.yellow100};
  border-radius: 12px;
  color: ${colors.black800};
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'Metric';
  font-weight: bolder;
  padding: 0 8px;
`

const LeakageDetectorDetailsContainer = styled.ul`
  padding: 0;
`

const LeakageDetectorCrowCard = styled(CrowCard)`
  border-radius: 12px;
  border: 1px solid ${colors.black300};
  padding: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
`

export default LeakageDetectorDetails
