import * as React from 'react'

const SystemIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const stroke: string = props.stroke || '#333131'

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 6.75C9.38071 6.75 10.5 5.63071 10.5 4.25C10.5 2.86929 9.38071 1.75 8 1.75C6.61929 1.75 5.5 2.86929 5.5 4.25C5.5 5.63071 6.61929 6.75 8 6.75Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.75 13.25C13.1307 13.25 14.25 12.1307 14.25 10.75C14.25 9.36929 13.1307 8.25 11.75 8.25C10.3693 8.25 9.25 9.36929 9.25 10.75C9.25 12.1307 10.3693 13.25 11.75 13.25Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.25 13.25C5.63071 13.25 6.75 12.1307 6.75 10.75C6.75 9.36929 5.63071 8.25 4.25 8.25C2.86929 8.25 1.75 9.36929 1.75 10.75C1.75 12.1307 2.86929 13.25 4.25 13.25Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { SystemIcon }
