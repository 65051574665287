const BoilerRoomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 17C23.4477 17 23 17.4477 23 18V20H25V18C25 17.4477 24.5523 17 24 17Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 24C26.7614 24 29 21.7614 29 19C29 16.2386 26.7614 14 24 14C21.2386 14 19 16.2386 19 19C19 21.7614 21.2386 24 24 24ZM24 22C25.6569 22 27 20.6569 27 19C27 17.3431 25.6569 16 24 16C22.3431 16 21 17.3431 21 19C21 20.6569 22.3431 22 24 22Z"
        fill="#333131"
      />
      <path
        d="M26 28C26 29.1046 25.1046 30 24 30C22.8954 30 22 29.1046 22 28C22 26.8954 22.8954 26 24 26C25.1046 26 26 26.8954 26 28Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4958 37.1182C34.7414 36.8782 35 36.5017 35 36V8C35 7.10097 34.397 6.44523 33.7927 6.0166C33.1624 5.56956 32.3152 5.2079 31.3584 4.92085C29.432 4.34294 26.8307 4 24 4C21.1693 4 18.568 4.34294 16.6416 4.92085C15.6848 5.2079 14.8376 5.56956 14.2073 6.0166C13.603 6.44523 13 7.10097 13 8V36C13 36.5017 13.2586 36.8782 13.5042 37.1182C13.7481 37.3566 14.0543 37.5387 14.3584 37.6825C14.9717 37.9725 15.7952 38.2073 16.7328 38.3948C17.4088 38.53 18.1712 38.6458 19 38.7389V40.5C19 41.6046 18.1046 42.5 17 42.5H5C4.44771 42.5 4 42.9477 4 43.5C4 44.0523 4.44771 44.5 5 44.5H17C19.2091 44.5 21 42.7091 21 40.5V38.9104C21.9524 38.9688 22.9599 39 24 39C25.0401 39 26.0476 38.9688 27 38.9104V40.5C27 42.7091 28.7909 44.5 31 44.5H43C43.5523 44.5 44 44.0523 44 43.5C44 42.9477 43.5523 42.5 43 42.5H31C29.8954 42.5 29 41.6046 29 40.5V38.7389C29.8288 38.6458 30.5912 38.53 31.2672 38.3948C32.2048 38.2073 33.0283 37.9725 33.6416 37.6825C33.9457 37.5387 34.2519 37.3566 34.4958 37.1182ZM32.6356 7.64792C32.8982 7.83419 32.9737 7.95585 32.9942 8H15.0058C15.0263 7.95585 15.1018 7.83419 15.3644 7.64792C15.7462 7.37714 16.3635 7.09235 17.2163 6.83651C18.9092 6.32863 21.3079 6 24 6C26.6921 6 29.0908 6.32863 30.7837 6.83651C31.6365 7.09235 32.2538 7.37714 32.6356 7.64792ZM15.5123 36C15.906 36.1485 16.4453 36.2977 17.125 36.4336C18.8527 36.7792 21.2839 37 24 37C26.7161 37 29.1473 36.7792 30.875 36.4336C31.5547 36.2977 32.094 36.1485 32.4877 36H15.5123ZM15 10V34H33V10H15Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 10H15V34H33V10ZM29 19C29 21.7614 26.7614 24 24 24C21.2386 24 19 21.7614 19 19C19 16.2386 21.2386 14 24 14C26.7614 14 29 16.2386 29 19ZM26 28C26 29.1046 25.1046 30 24 30C22.8954 30 22 29.1046 22 28C22 26.8954 22.8954 26 24 26C25.1046 26 26 26.8954 26 28Z"
        fill="#E3E1E1"
      />
    </svg>
  )
}

export { BoilerRoomIcon }
