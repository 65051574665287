import classNames from 'classnames'
import { FC } from 'react'
import useI18n from '../../../i18n/useI18n'
import { isTranslationKey } from '../../../types/generated/TranslationKey'

type Props = {
  text?: string
  align?: 'left'
  errorId?: string
}

const FailedRequest: FC<Props> = ({ text, align, errorId }) => {
  if (!text || !isTranslationKey(text)) {
    text = 'Something went wrong. Please try again'
  }

  const { t } = useI18n()
  return (
    <div
      className={classNames(
        'notification',
        'notification--error',
        !!align && `notification--align-${align}`,
      )}
      data-id={errorId}
    >
      {t(text)}
    </div>
  )
}

export default FailedRequest
