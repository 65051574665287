import * as React from 'react'

const SettingsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      data-testid="sector_settings_icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      {...props}
    >
      <path d="M70 82.5c-6.9 0-12.5-5.6-12.5-12.5S63.1 57.5 70 57.5 82.5 63.1 82.5 70 76.9 82.5 70 82.5zm0-21.3c-5 0-9 4-9 9s4 9 9 9 9-4 9-9c.1-5-4-9-9-9 0-.1 0-.1 0 0z" />
      <path d="M74.9 102.3h-9.8c-2.5 0-4.5-2-4.5-4.5l-.4-3c-2.5-1-4.9-2.4-7-4.1L50.4 92c-2.2 1.1-4.8.3-6-1.8L39.6 82c-1.3-2.1-.7-4.8 1.4-6.1l2.5-1.7c-.4-2.7-.4-5.4 0-8.1L41 64.3c-2.1-1.3-2.7-4.1-1.4-6.1l4.9-8.4c1.2-2.2 3.9-3 6.1-1.9l2.8 1.3c2.1-1.7 4.5-3.1 7-4.1l.2-3.1c0-2.5 2-4.5 4.5-4.5h9.8c2.4 0 4.4 1.9 4.5 4.3l.5 3.4c2.5 1 4.9 2.4 7 4.1l2.8-1.3c2.2-1.1 4.9-.3 6.1 1.9l4.9 8.4c1.3 2.1.7 4.8-1.4 6.1l-2.5 1.7c.4 2.7.4 5.4 0 8.1l2.5 1.7c2.1 1.3 2.7 4.1 1.4 6.1l-4.9 8.3c-1.2 2.2-3.9 3-6.1 1.9l-2.8-1.3c-2.1 1.7-4.5 3.1-7 4.1l-.4 3c-.1 2.5-2.2 4.4-4.6 4.3zM53.5 86.9c.4 0 .9.2 1.2.5 2.3 2 5 3.5 7.9 4.5.7.2 1.2.9 1.2 1.6l.3 4.2c0 .5.5.9 1 .9h9.8c.5 0 .9-.4 1-.9l.3-4.2c.1-.7.5-1.3 1.2-1.6 2.9-1 5.6-2.5 7.9-4.5.6-.5 1.3-.6 2-.3l3.8 1.9c.5.2 1.1.1 1.3-.4l4.9-8.4c.3-.5.1-1-.3-1.3l-3.5-2.4c-.6-.4-.9-1.1-.8-1.9.6-3 .6-6 0-9-.1-.7.2-1.4.8-1.9l3.5-2.4c.4-.3.6-.9.3-1.3l-4.9-8.4c-.3-.5-.8-.6-1.3-.4L87.3 53c-.7.3-1.4.2-2-.3-2.3-2-5-3.5-7.9-4.5-.7-.2-1.2-.9-1.2-1.6l-.3-4.4c0-.5-.5-.9-1-.9h-9.8c-.5 0-.9.4-1 .9l-.3 4.2c-.1.7-.5 1.3-1.2 1.6-2.9 1-5.6 2.5-7.9 4.5-.5.5-1.3.6-2 .3L48.9 51c-.5-.2-1 0-1.3.4l-4.9 8.5c-.3.5-.1 1 .3 1.3l3.5 2.4c.6.4.9 1.1.8 1.9-.6 3-.6 6 0 9 .1.7-.2 1.4-.8 1.9L43 78.7c-.4.3-.6.9-.3 1.3l4.9 8.4c.3.4.8.6 1.3.4l3.8-1.9h.8z" />
    </svg>
  )
}

export { SettingsIcon }
