/* eslint-disable import/no-unused-modules */
import FocusTrap from 'focus-trap-react'
import React, { ReactNode, useState, useEffect, MouseEventHandler } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'
import { colors } from '../Style/theme'
import { Title as DefaultTitle } from '../Typography'

export type Props = {
  className?: string
  closeButtonClassName?: string
  children?: ReactNode
  isOpen: boolean
  handleClose: MouseEventHandler<HTMLButtonElement>
  position: 'top' | 'bottom' | 'left' | 'right'
  title?: ReactNode
  closeButton?: boolean
  closeIcon?: ReactNode
  pauseFocusTrap?: boolean
}

const closeTime = 0.25

const Drawer = ({
  className,
  closeButtonClassName,
  children,
  isOpen,
  handleClose,
  position,
  title,
  closeButton,
  closeIcon,
  pauseFocusTrap,
}: Props) => {
  const rootRef = document.getElementById('root')
  const heightSubtrahend = document.getElementById('portal-container')?.clientHeight || 0
  const [shouldShow, setShouldShow] = useState(isOpen)

  useEffect(() => {
    return () => {
      // cleanup before the component unmounts
      rootRef?.style.removeProperty('overflow')
      rootRef?.style.removeProperty('position')
      rootRef?.style.removeProperty('width')
      rootRef?.style.removeProperty('min-height')
      rootRef?.style.removeProperty('top')
    }
  }, [rootRef])

  useEffect(() => {
    if (!isOpen && shouldShow) {
      const scrollY = -(parseInt(rootRef!.style.top) || 0) + heightSubtrahend
      setTimeout(() => {
        rootRef!.style.removeProperty('overflow')
        rootRef!.style.removeProperty('position')
        rootRef!.style.removeProperty('width')
        rootRef?.style.removeProperty('min-height')
        rootRef!.style.removeProperty('top')
        window.scrollTo(0, scrollY)
        setShouldShow(false)
      }, closeTime * 1000)
    }
    if (isOpen) {
      const scrollY = window.scrollY - heightSubtrahend
      rootRef!.style.overflow = 'hidden'
      rootRef!.style.position = 'fixed'
      rootRef!.style.width = '100%'
      rootRef!.style.minHeight = `calc(100vh - ${heightSubtrahend}px)`
      rootRef!.style.top = `${-scrollY}px`
      setShouldShow(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  if (!shouldShow) {
    return null
  }

  return createPortal(
    <FocusTrap paused={!!pauseFocusTrap}>
      <Container position={position} heightSubtrahend={heightSubtrahend}>
        <Close isOpen={isOpen} onClick={handleClose} />
        <Content
          className={className}
          isOpen={isOpen}
          position={position}
          heightSubtrahend={heightSubtrahend}
        >
          {(!!title || closeButton) && (
            <Header className="drawer-header">
              {closeButton && (
                <CloseButton
                  onClick={handleClose}
                  className={
                    closeButtonClassName ? `drawer-close ${closeButtonClassName}` : 'drawer-close'
                  }
                >
                  <span>{closeIcon}</span>
                </CloseButton>
              )}
              {!!title && <Title className="drawer-title mt-0">{title}</Title>}
            </Header>
          )}
          {children}
        </Content>
      </Container>
    </FocusTrap>,
    document.body,
  )
}

const positionCSS = (position: 'top' | 'bottom' | 'left' | 'right', heightSubtrahend: number) => {
  return {
    top: css`
      align-self: start;
      width: 100%;
      max-height: 90vh;
      overflow-y: auto;
      @keyframes open-top {
        from {
          transform: translateY(-100%);
        }
        to {
          transform: translateY(0);
        }
      }
      @keyframes close-top {
        from {
          transform: translateY(0);
        }
        to {
          transform: translateY(-100%);
        }
      }
    `,
    bottom: css`
      align-self: end;
      width: 100%;
      max-height: 90vh;
      overflow-y: auto;
      @keyframes open-bottom {
        from {
          transform: translateY(100%);
        }
        to {
          transform: translateY(0);
        }
      }
      @keyframes close-bottom {
        from {
          transform: translateY(0);
        }
        to {
          transform: translateY(100%);
        }
      }
    `,
    left: css`
      height: calc(100vh - ${heightSubtrahend}px);
      justify-self: start;
      overflow-x: auto;
      @keyframes open-left {
        from {
          transform: translateX(-100%);
          position: absolute;
        }
        to {
          transform: translateX(0);
        }
      }
      @keyframes close-left {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-100%);
        }
      }
    `,
    right: css`
      height: calc(100vh - ${heightSubtrahend}px);
      justify-self: end;
      overflow-x: auto;
      @keyframes open-right {
        from {
          transform: translateX(100%);
        }
        to {
          transform: translateX(0);
        }
      }
      @keyframes close-right {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(100%);
        }
      }
    `,
  }[position]
}

const Container = styled.div<{
  position: 'top' | 'bottom' | 'left' | 'right'
  heightSubtrahend: number
}>`
  position: fixed;
  top: ${({ heightSubtrahend }) => heightSubtrahend + 'px'};
  left: 0;
  width: 100vw;
  ${({ position }) =>
    position === 'top' || position === 'bottom'
      ? css`
          overflow: hidden;
        `
      : css`
          overflow-y: auto;
          overflow-x: hidden;
        `}
  display: grid;
  grid-template-areas: 'drawer';
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`

const Close = styled.button<{ isOpen: boolean }>`
  grid-area: drawer;
  background-color: ${colors.black};

  animation-duration: ${closeTime}s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-name: ${({ isOpen }) => (isOpen ? 'show-button' : 'hide-button')};

  @keyframes show-button {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.55;
    }
  }
  @keyframes hide-button {
    from {
      opacity: 0.55;
    }
    to {
      opacity: 0;
    }
  }
`

const Content = styled.div<{
  isOpen: boolean
  position: 'top' | 'bottom' | 'left' | 'right'
  heightSubtrahend: number
}>`
  grid-area: drawer;
  padding: 40px 72px;
  width: 100%;
  max-width: 636px;
  height: 100%;
  background-color: ${colors.white};


  @media only screen and (max-width: 600px) {
    padding: 40px 24px;
  }

  ${({ position, heightSubtrahend }) => positionCSS(position, heightSubtrahend)}
  animation-duration: ${closeTime}s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-name: ${({ isOpen, position }) => `${isOpen ? 'open' : 'close'}-${position}`};
`

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'title close';
  margin: 0;
`

const Title = styled(DefaultTitle)`
  grid-area: title;
  margin: 0;

  @media only screen and (max-width: 600px) {
    margin-left: 48px;
  }
`

const CloseButton = styled.button`
  grid-area: close;
  position: absolute;
  width: 16px;
  top: 48px;
  left: 28px;
  font-size: 24px;
`

export default Drawer
