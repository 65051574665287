import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from '../Style/theme'

interface Props {
  onClick?: () => void
  disabled?: boolean
  borderRadius?: string
  footerPadding?: string
  useDynamicHeight?: ReactNode
  topRightIcon?: any
  children?: ReactNode
  className?: string
}

const Tile: FC<Props> = ({ onClick, disabled, children, className }) => {
  const classNames = className ? `${className} qa-tile-container` : 'qa-tile-container'

  return (
    <Container
      role="tile"
      onClick={onClick}
      onKeyPress={onClick}
      disabled={disabled}
      className={classNames}
      tabIndex={disabled || onClick === undefined ? -1 : 0}
    >
      {children}
    </Container>
  )
}

const Container = styled.div<{ onClick?: any; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 164px;
  height: 164px;
  padding: 16px;
  border-radius: 16px;
  background-color: ${({ disabled }) => (disabled ? colors.black200 : colors.white)};
  cursor: ${({ onClick, disabled }) => (onClick && !disabled ? 'pointer' : 'default')};
  pointer-events: ${({ onClick, disabled }) =>
    disabled || onClick === undefined ? 'none' : 'auto'};
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 14px;

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 4px 8px 0px;
  }

  &:focus-visible {
    outline: 2px solid ${colors.blue};
  }
`

export default Tile
