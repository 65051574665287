import classNames from 'classnames'
import React, { FC, MouseEventHandler } from 'react'
import useI18n from '../../i18n/useI18n'
import './close-button.scss'

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>
  className?: string
  iconOnly?: boolean
}

const CloseButton: FC<Props> = ({ onClick, className, iconOnly }) => {
  const { t } = useI18n()

  return (
    <button onClick={onClick} className={classNames('button-close', className)} title={t('close')}>
      {!iconOnly && <div className="button-close__text">ESC</div>}
    </button>
  )
}

interface CloseButtonFixedProps {
  onCancel: MouseEventHandler
}
export const CloseButtonFixed: FC<CloseButtonFixedProps> = ({ onCancel }) => {
  return (
    <button className="close-button ugly-orange-focus" onClick={onCancel}>
      <div className="close-button__cross">×</div>
    </button>
  )
}

export default CloseButton
