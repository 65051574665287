import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  TemperaturePreference,
  TemperaturePreferenceValue,
  TemperatureAverage,
  PropertyAverageTemperature,
} from '../types/Temperature'

type TemperaturePreferenceState = Record<
  string,
  { temperatureUserPreference?: TemperaturePreference[] } | undefined
>
type TemperatureAverageState = Record<
  string,
  | {
      temperatureAverage?: TemperatureAverage
      temperaturePropertyAverage?: PropertyAverageTemperature
    }
  | undefined
>

const initialState: TemperaturePreferenceState = {}
const initialAverageState: TemperatureAverageState = {}

type UpdateTemperaturePreferencePayload = TemperaturePreference[]
type UpdateTemperatureAveragePayload = TemperatureAverage

type PropertyAverage = PropertyAverageTemperature

const temperatureUserPreferenceSlice = createSlice({
  name: 'temperatureSettings',
  initialState,
  reducers: {
    GET_TEMPERATUREPREFERENCE(
      state,
      action: PayloadAction<{ data: UpdateTemperaturePreferencePayload; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = { ...state[panelId], temperatureUserPreference: data }
    },
    POST_TEMPERATUREPREFERENCE(
      state,
      action: PayloadAction<{ data: TemperaturePreferenceValue; panelId: string }>,
    ) {},
    DELETE_TEMPERATUREPREFERENCE(state, action: PayloadAction<{ panelId: string }>) {},
  },
})

const temperatureAverageSlice = createSlice({
  name: 'temperatureAverage',
  initialState: initialAverageState,
  reducers: {
    GET_TEMPERATUREAVERAGEPROPERTY(
      state,
      action: PayloadAction<{ data: PropertyAverage; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = { ...state[panelId], temperaturePropertyAverage: data }
    },
    GET_TEMPERATUREAVERAGESECTIONS(
      state,
      action: PayloadAction<{ data: UpdateTemperatureAveragePayload; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = { ...state[panelId], temperatureAverage: data }
    },
  },
})

export const {
  POST_TEMPERATUREPREFERENCE,
  DELETE_TEMPERATUREPREFERENCE,
  GET_TEMPERATUREPREFERENCE,
} = temperatureUserPreferenceSlice.actions
export const {
  GET_TEMPERATUREAVERAGEPROPERTY,
  GET_TEMPERATUREAVERAGESECTIONS,
} = temperatureAverageSlice.actions

export const temperaturePreferenceReducer = temperatureUserPreferenceSlice.reducer
export const temperatureAverageReducer = temperatureAverageSlice.reducer
