import styled from 'styled-components'

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 5px;
  & > *:last-child {
    margin-left: 8px;
  }
`

export const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3px;
`

export const ModalButtonContainer = styled.div`
  display: flex;
  margin-top: 15px;
  & > *:first-child {
    margin-right: 10px;
  }
`
