import { WifiDetails } from '../../../types/WifiDetails'

type Props = {
  wifiItem: WifiDetails
}

const WifiSignalIcon = ({ wifiItem }: Props) => {
  const signalBarColors = () => {
    let barColor = '#58595B'
    if (wifiItem.Connected === 'True' || wifiItem.Connected === 'true') {
      barColor = '#3CA07A'
    }

    const levelNumber = +wifiItem.RadioLevel

    if (levelNumber >= -49) {
      return [barColor, barColor, barColor, barColor]
    } else if (levelNumber >= -70) {
      return ['#D5D5D5', barColor, barColor, barColor]
    } else if (levelNumber >= -80) {
      return ['#D5D5D5', '#D5D5D5', barColor, barColor]
    }
    return ['#D5D5D5', '#D5D5D5', '#D5D5D5', barColor]
  }

  const signalBarColorList = signalBarColors()

  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99943 1.00024C4.72346 1.00024 2.53881 1.89544 0.917261 3.49251C0.72052 3.68629 0.403947 3.68388 0.210175 3.48714C0.0164031 3.2904 0.0188103 2.97382 0.215551 2.78005C2.02418 0.998721 4.46088 0.000244141 6.99943 0.000244141C9.53799 0.000244141 11.9747 0.998721 13.7833 2.78005C13.9801 2.97382 13.9825 3.2904 13.7887 3.48714C13.5949 3.68388 13.2783 3.68629 13.0816 3.49251C11.4601 1.89544 9.2754 1.00024 6.99943 1.00024Z"
        fill={signalBarColorList[0]}
      />
      <path
        d="M3.03546 5.61524C4.09515 4.57989 5.51787 4.00024 6.99939 4.00024C8.4809 4.00024 9.90362 4.57989 10.9633 5.61524C11.1608 5.80822 11.4774 5.80454 11.6704 5.60702C11.8634 5.40951 11.8597 5.09295 11.6622 4.89997C10.4156 3.68209 8.7421 3.00024 6.99939 3.00024C5.25668 3.00024 3.58313 3.68209 2.33661 4.89997C2.1391 5.09295 2.13542 5.40951 2.3284 5.60702C2.52138 5.80454 2.83794 5.80822 3.03546 5.61524Z"
        fill={signalBarColorList[1]}
      />
      <path
        d="M5.15249 7.74049C5.6499 7.26537 6.3113 7.00024 6.99916 7.00024C7.68702 7.00024 8.34842 7.26537 8.84583 7.74049C9.04552 7.93122 9.36202 7.92397 9.55276 7.72428C9.74349 7.5246 9.73624 7.2081 9.53655 7.01736C8.85309 6.36453 7.94431 6.00024 6.99916 6.00024C6.05401 6.00024 5.14523 6.36453 4.46177 7.01736C4.26209 7.2081 4.25483 7.5246 4.44557 7.72428C4.6363 7.92397 4.9528 7.93122 5.15249 7.74049Z"
        fill={signalBarColorList[2]}
      />
      <path
        d="M7.75 9.5C7.75 9.91421 7.41421 10.25 7 10.25C6.58579 10.25 6.25 9.91421 6.25 9.5C6.25 9.08579 6.58579 8.75 7 8.75C7.41421 8.75 7.75 9.08579 7.75 9.5Z"
        fill={signalBarColorList[3]}
      />
    </svg>
  )
}

export { WifiSignalIcon }
