const LibraryIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 20H7V27H9V33H6V41H30V33H33V27H31V20ZM28 23H22V29L25 28L28 29V23Z"
        fill="#E3E1E1"
      />
      <path d="M37 19H39V33H37V19Z" fill="#333131" />
      <path d="M37 13V17H39V13H37Z" fill="#333131" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 19V10C33 9.44772 33.4477 9 34 9H42C42.5523 9 43 9.44772 43 10V42H33V34H31V40H32V42H7C5.89543 42 5 41.1046 5 40V34C5 32.8954 5.89543 32 7 32H8L8 28C6.89543 28 6 27.1046 6 26V21C6 19.8954 6.89543 19 8 19H33ZM35 11H41V40H35V11ZM33 25.75V21.25H32V25.75H33ZM29 34V40H7V34H29ZM32 32H10V28H21V31L25 29L29 31V28H32V32ZM21 26V23C21 22.4477 21.4477 22 22 22H28C28.5523 22 29 22.4477 29 23V26H30V21H8V26H21ZM25 26.7639L23 27.7639V24H27V27.7639L25 26.7639Z"
        fill="#333131"
      />
      <path
        d="M4 43C3.44772 43 3 43.4477 3 44C3 44.5523 3.44772 45 4 45H44C44.5523 45 45 44.5523 45 44C45 43.4477 44.5523 43 44 43H4Z"
        fill="#333131"
      />
    </svg>
  )
}

export { LibraryIcon }
