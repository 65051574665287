const FoodStorageIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 39C41 39.3373 40.6124 40 39.625 40H28.375C27.3876 40 27 39.3373 27 39V20H41V39ZM38 24C38 24.5523 37.5523 25 37 25C36.4477 25 36 24.5523 36 24C36 23.4477 36.4477 23 37 23C37.5523 23 38 23.4477 38 24ZM40 34C40 34.5523 39.5523 35 39 35C38.4477 35 38 34.5523 38 34C38 33.4477 38.4477 33 39 33C39.5523 33 40 33.4477 40 34ZM31 33C31 33.5523 30.5523 34 30 34C29.4477 34 29 33.5523 29 33C29 32.4477 29.4477 32 30 32C30.5523 32 31 32.4477 31 33ZM33 30C33 30.5523 32.5523 31 32 31C31.4477 31 31 30.5523 31 30C31 29.4477 31.4477 29 32 29C32.5523 29 33 29.4477 33 30ZM33.5 39C34.8807 39 36 37.8807 36 36.5C36 35.1193 34.8807 34 33.5 34C32.1193 34 31 35.1193 31 36.5C31 37.8807 32.1193 39 33.5 39ZM37.5 32C38.8807 32 40 30.8807 40 29.5C40 28.1193 38.8807 27 37.5 27C36.1193 27 35 28.1193 35 29.5C35 30.8807 36.1193 32 37.5 32ZM31.5 27C32.8807 27 34 25.8807 34 24.5C34 23.1193 32.8807 22 31.5 22C30.1193 22 29 23.1193 29 24.5C29 25.8807 30.1193 27 31.5 27Z"
        fill="#E3E1E1"
      />
      <path d="M21 30.7808V40H7V30.7807L13.9999 29.0308L21 30.7808Z" fill="#E3E1E1" />
      <path
        d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H44C44.5523 7 45 6.55228 45 6C45 5.44772 44.5523 5 44 5H4Z"
        fill="#333131"
      />
      <path
        d="M37 25C37.5523 25 38 24.5523 38 24C38 23.4477 37.5523 23 37 23C36.4477 23 36 23.4477 36 24C36 24.5523 36.4477 25 37 25Z"
        fill="#333131"
      />
      <path
        d="M40 34C40 34.5523 39.5523 35 39 35C38.4477 35 38 34.5523 38 34C38 33.4477 38.4477 33 39 33C39.5523 33 40 33.4477 40 34Z"
        fill="#333131"
      />
      <path
        d="M30 34C30.5523 34 31 33.5523 31 33C31 32.4477 30.5523 32 30 32C29.4477 32 29 32.4477 29 33C29 33.5523 29.4477 34 30 34Z"
        fill="#333131"
      />
      <path
        d="M33 30C33 30.5523 32.5523 31 32 31C31.4477 31 31 30.5523 31 30C31 29.4477 31.4477 29 32 29C32.5523 29 33 29.4477 33 30Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 36.5C36 37.8807 34.8807 39 33.5 39C32.1193 39 31 37.8807 31 36.5C31 35.1193 32.1193 34 33.5 34C34.8807 34 36 35.1193 36 36.5ZM34 36.5C34 36.7761 33.7761 37 33.5 37C33.2239 37 33 36.7761 33 36.5C33 36.2239 33.2239 36 33.5 36C33.7761 36 34 36.2239 34 36.5Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5 32C38.8807 32 40 30.8807 40 29.5C40 28.1193 38.8807 27 37.5 27C36.1193 27 35 28.1193 35 29.5C35 30.8807 36.1193 32 37.5 32ZM37.5 30C37.7761 30 38 29.7761 38 29.5C38 29.2239 37.7761 29 37.5 29C37.2239 29 37 29.2239 37 29.5C37 29.7761 37.2239 30 37.5 30Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 24.5C34 25.8807 32.8807 27 31.5 27C30.1193 27 29 25.8807 29 24.5C29 23.1193 30.1193 22 31.5 22C32.8807 22 34 23.1193 34 24.5ZM32 24.5C32 24.7761 31.7761 25 31.5 25C31.2239 25 31 24.7761 31 24.5C31 24.2239 31.2239 24 31.5 24C31.7761 24 32 24.2239 32 24.5Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.125 15H26C25.4477 15 25 14.5523 25 14V11C25 10.4477 25.4477 10 26 10L42 10C42.5523 10 43 10.4477 43 11V14C43 14.5523 42.5523 15 42 15H41.875V15.2676C42.5475 15.6134 43 16.2597 43 17V39C43 40.6569 41.489 42 39.625 42H28.375C26.511 42 25 40.6569 25 39V17C25 16.2597 25.4525 15.6134 26.125 15.2676V15ZM41 12V13L27 13V12L41 12ZM28.125 15V15.8778C28.125 16.2525 27.9155 16.5958 27.5823 16.7671L27.0396 17.0462C27.023 17.0547 27.0099 17.0632 27 17.0706V18H41V17.0706C40.9901 17.0632 40.977 17.0547 40.9604 17.0462L40.4177 16.7671C40.0845 16.5958 39.875 16.2525 39.875 15.8778V15H28.125ZM27 39V20H41V39C41 39.3373 40.6124 40 39.625 40H28.375C27.3876 40 27 39.3373 27 39Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 21C5.89543 21 5 21.8954 5 23V40C5 41.1046 5.89543 42 7 42H21C22.1046 42 23 41.1046 23 40V23C23 21.8954 22.1046 21 21 21H7ZM21 23H7V28.7192L13.9999 26.9692L21 28.7193V23ZM21 40V30.7808L13.9999 29.0308L7 30.7808V40H21Z"
        fill="#333131"
      />
      <path
        d="M3 44C3 43.4477 3.44772 43 4 43H44C44.5523 43 45 43.4477 45 44C45 44.5523 44.5523 45 44 45H4C3.44772 45 3 44.5523 3 44Z"
        fill="#333131"
      />
    </svg>
  )
}

export { FoodStorageIcon }
