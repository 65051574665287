import { ReactNode } from 'react'
import {
  AvatarIcon,
  CameraIcon,
  DoorsIcon,
  HistoryIcon,
  HumidityIcon,
  InboxIcon,
  LeakageDetectorIcon,
  LockedIcon,
  MiniAppsIcon,
  PeopleIcon,
  SettingsIcon,
  SignOutIcon,
  SmartplugIcon,
  SmokeDetectorIcon,
  TemperatureIcon,
} from '../../../assets/icons/crow'

const systemsRoute = '/systems/:panelId'
type RouteEntry = {
  name: string
  path: string
  itemName: string
  Icon: ReactNode
}

type SystemRouteNames =
  | 'Home'
  | 'Temperature'
  | 'Humidity'
  | 'Door&Win'
  | 'Locks'
  | 'People'
  | 'Smoke_detectors'
  | 'Leakage_detectors'
  | 'Camera'
  | 'SmartPlug'
  | 'History'
  | 'Settings'

type UserRouteNames = 'Inbox' | 'Account' | 'LogOut'
export const userRouteList: Record<UserRouteNames, RouteEntry> = {
  Inbox: {
    name: 'Inbox',
    path: `${systemsRoute}/messages`,
    itemName: 'Inbox',
    Icon: <InboxIcon />,
  },
  Account: {
    name: 'Account',
    path: `${systemsRoute}/account`,
    itemName: 'Avatar',
    Icon: <AvatarIcon />,
  },
  LogOut: {
    name: 'Sign-out',
    path: ``,
    itemName: 'Sign-out',
    Icon: <SignOutIcon />,
  },
}

export const systemRouteList: Record<SystemRouteNames, RouteEntry> = {
  Home: {
    name: 'Home',
    path: `${systemsRoute}`,
    itemName: 'Mini-apps',
    Icon: <MiniAppsIcon width="22" />,
  },
  Temperature: {
    name: 'Temperature',
    path: `${systemsRoute}/temperatures`,
    itemName: 'Temperature',
    Icon: <TemperatureIcon width="20" />,
  },
  Humidity: {
    name: 'Humidity',
    path: `${systemsRoute}/humidity`,
    itemName: 'Humidity',
    Icon: <HumidityIcon width="24" />,
  },
  History: {
    name: 'History',
    path: `${systemsRoute}/history`,
    itemName: 'History',
    Icon: <HistoryIcon />,
  },
  Leakage_detectors: {
    name: 'Leakage detectors',
    path: `${systemsRoute}/leakage-detectors`,
    itemName: 'Leakage-Detector',
    Icon: <LeakageDetectorIcon />,
  },
  'Door&Win': {
    name: 'Door&Win',
    path: `${systemsRoute}/doors-and-windows`,
    itemName: 'Doors',
    Icon: <DoorsIcon />,
  },
  People: {
    name: 'People',
    path: `${systemsRoute}/people`,
    itemName: 'People',
    Icon: <PeopleIcon />,
  },
  Locks: {
    name: 'Locks',
    path: `${systemsRoute}/locks`,
    itemName: 'Unlocked',
    Icon: <LockedIcon />,
  },
  Smoke_detectors: {
    name: 'Smoke detectors',
    path: `${systemsRoute}/smoke-detectors`,
    itemName: 'Smoke-Detector',
    Icon: <SmokeDetectorIcon width="23" />,
  },
  Camera: {
    name: 'Camera',
    path: `${systemsRoute}/picture`,
    itemName: 'Camera',
    Icon: <CameraIcon />,
  },
  SmartPlug: {
    name: 'smartplugs',
    path: `${systemsRoute}/smartplugs`,
    itemName: 'SmartPlug',
    Icon: <SmartplugIcon />,
  },
  Settings: {
    name: 'Settings',
    path: `${systemsRoute}/settings`,
    itemName: 'Settings',
    Icon: <SettingsIcon />,
  },
}
