import styled from 'styled-components'
import BackButton from '../../../components/BackButton'
import { colors } from '../../../components/Crow/Style/theme'
import Tile from '../../../components/Crow/Tile/Tile'
import { Heading3, Title } from '../../../components/Crow/Typography'
import FullscreenPage from '../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import { displayNewNavigationMenu } from '../../../configs'
import useI18n from '../../../i18n/useI18n'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { useSmokeDetectors } from '../../../services/requestHooks/smokeDetectors'
import { useOnMount } from '../../../utils/commonHooks'
import { logBackEvent } from '../../../utils/firebaseAnalytics'
import FloorName from '../../../utils/places/FloorName'
import RoomName from '../../../utils/places/RoomName'
import { RoomSwitchIcon } from '../../../utils/places/RoomSwitchIcon'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import SmokeDetectorsTileFooter from './SmokeDetectorsTileFooter'
import { StyledUl } from './SmokeDetectorStyle'

const CrowSmokeDetectorsPage = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const returnUrl = `/systems/${panelId}`

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
    error: panelStatusError,
  } = usePanelStatus()

  const {
    data: smokeDetectors,
    run: getSmokeDetectors,
    isLoading: loadingSmokeDetectors,
    error: SmokeDetectorError,
  } = useSmokeDetectors()

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadInfoError,
  } = usePanelInfo()

  const hasError = !!SmokeDetectorError || !!panelStatusError || !!loadInfoError

  useOnMount(() => {
    if (!loadingPanelInfo) {
      loadPanelInfo(panelId)
    }
    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
    if (!loadingSmokeDetectors) {
      getSmokeDetectors(panelId)
    }
  })

  return (
    <>
      <TcOrCrowContainer panelInfo={panelInfo} fullscreen footer>
        <FullscreenPage
          isLoading={loadingSmokeDetectors || loadingPanelStatus}
          loaderProps={{
            loadingText: { text: t('Loading') },
            errorHandling: {
              loadingFailed: !loadingSmokeDetectors && hasError && !panelStatus?.IsOnline,
              errorHeaderText: t('Smoke detectors'),
              errorText: !panelStatus?.IsOnline
                ? t('You can not control smoke detectors while you are offline')
                : t('Something went wrong. Please try again'),
            },
          }}
        >
          {!displayNewNavigationMenu && (
            <BackButton backUrl={returnUrl} onClick={() => logBackEvent('smoke_detectors')} />
          )}
          <Title large>{t('Smoke detectors')}</Title>
          <p className="pt-4 mb-10">{t('SMOKE_DETECTORS_DESCRIPTION')}</p>
          <>
            <StyledUl>
              {smokeDetectors?.Floors.map((floor) => (
                <li key={floor.Key}>
                  <SectionTitle>
                    <FloorName floorName={floor.Key} />
                  </SectionTitle>
                  <TilesWrapper className={`qa-${floor.Key}`}>
                    {floor.Rooms.map((room) => (
                      <Tile key={room.Key + room.Index}>
                        <TileTop>
                          <RoomSwitchIcon roomKey={room.Key} />
                        </TileTop>
                        <TileCenter>
                          <RoomNameTitle>
                            <RoomName roomName={room.Key} />
                            {room.Index && room.Index > 0 ? <span>&nbsp;{room.Index}</span> : null}
                          </RoomNameTitle>
                        </TileCenter>
                        <TileBottom className={`qa-${room.Name}-${room.Index}`}>
                          <FooterDevices>{t('Sensors')}</FooterDevices>
                          <SmokeDetectorsTileFooter props={room.Devices} />
                        </TileBottom>
                      </Tile>
                    ))}
                  </TilesWrapper>
                </li>
              ))}
            </StyledUl>
          </>
        </FullscreenPage>
      </TcOrCrowContainer>
    </>
  )
}

const SectionTitle = styled(Heading3)`
  margin-left: 8px;
  color: ${colors.black700};
`

const TilesWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media only screen and (max-width: 600px) {
    gap: 15px;
  }
`

const TileTop = styled.div`
  margin-top: -6px;
`

const TileCenter = styled(Heading3)`
  margin-top: 9px;
  display: flex;
  align-items: center;
  line-height: 22px;
  color: ${colors.black800};
  max-width: 138px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const TileBottom = styled.div`
  display: flex;
  flex-direction: column;
`

const FooterDevices = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${colors.black900};
`

const RoomNameTitle = styled(Heading3)`
  display: flex;
  align-items: center;
  line-height: 22px;
  color: ${colors.black800};
  max-width: 138px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export default CrowSmokeDetectorsPage
