import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PanelAction, PanelPayloadAction } from '../services/requestHooks/PanelAction'
import { DoorLock, LanguageSettings, LockSettingsPayload } from '../types/Lock'
import { InstallationStatus, PanelModel, PanelStatus } from '../types/PanelModel'

type Expandable = 'plugs' | 'locks' | 'temp' | 'photo'

interface PanelSettingsPayload {
  PanelId: string
  DisplayName: string
  QuickArm: boolean
  Password: string
}

export interface PanelStatusResponse {
  error: boolean
  Status: PanelStatus | undefined
  IsOnline: boolean
  AnnexStatus: PanelStatus | undefined
  StatusTime: string
  StatusTimeUtc: string
  TimeZoneName: string
}

export interface PanelState {
  Info?: PanelModel
  Locks?: DoorLock[]
  LockLanguages?: LanguageSettings
  Status?: PanelStatusResponse
  expanded: Expandable[]
  hasClosedWizard: boolean
}

type PanelStates = Record<string, PanelState | undefined>

const initialPanelState: PanelState = {
  expanded: [],
  hasClosedWizard: false,
}

const initialState: PanelStates = {}

const updatePanel = (panelState = initialPanelState, partialPanelState: Partial<PanelState>) => {
  return {
    ...panelState,
    ...partialPanelState,
  }
}

const updatePanelStatus = (
  panelState: PanelState = initialPanelState,
  status: PanelStatus,
): PanelState => {
  return {
    ...panelState,
    Status: {
      ...panelState.Status!,
      Status: status,
      StatusTimeUtc: new Date().toISOString(),
    },
  }
}

const updateAnnexStatus = (
  panelState: PanelState = initialPanelState,
  status: PanelStatus,
): PanelState => {
  return {
    ...panelState,
    Status: {
      ...panelState.Status!,
      AnnexStatus: status,
    },
  }
}

const panelSlice = createSlice({
  name: 'PANEL',
  initialState,
  reducers: {
    LOAD_PANEL_INFO_SUCCESS(state, action: PanelPayloadAction<PanelModel>) {
      const { panelId, data } = action.payload
      state[panelId] = {
        ...(state[panelId] || initialPanelState),
        Info: { ...data, Capabilities: data.Capabilities || [] },
        Locks: data.Locks || [],
      }
    },
    SET_LOCK_SETTINGS_SUCCESS(
      state,
      action: PayloadAction<{ data: LockSettingsPayload; panelId: string }>,
    ) {
      const { panelId, data } = action.payload
      const locks = state[panelId]?.Locks

      if (data) {
        const changeLock = locks?.filter((item) => item.Serial === data.Serial)[0]
        if (changeLock) {
          changeLock.AutoLockEnabled = data.AutoLock
          changeLock.SoundLevel = data.SoundLevel
        }
      }

      const updatedLockLanguages: LanguageSettings = {
        Languages: state[panelId]?.LockLanguages?.Languages,
        Selected: data ? data.LanguageSettings.Selected : state[panelId]?.LockLanguages?.Selected,
      }

      state[panelId] = {
        ...(state[panelId] || initialPanelState),
        Locks: locks,
        LockLanguages: updatedLockLanguages,
      }
    },
    LOAD_GET_LOCK_LANGUAGE_SUCCESS(
      state,
      action: PayloadAction<{ data: LanguageSettings; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = {
        ...(state[panelId] || initialPanelState),
        LockLanguages: data,
      }
    },
    CHANGE_LOCK_NAME_SUCCESS(state, action: PanelPayloadAction<PanelModel>) {
      const { panelId, data } = action.payload
      state[panelId] = {
        ...(state[panelId] || initialPanelState),
        Info: { ...data, Capabilities: data.Capabilities || [] },
      }
    },
    LOAD_PANEL_STATUS_SUCCESS(state, action: PanelPayloadAction<PanelStatusResponse>) {
      const { panelId, data } = action.payload
      state[panelId] = updatePanel(state[panelId], { Status: data })
    },
    LOAD_PANEL_INFO_REQUEST() {},
    LOAD_PANEL_STATUS_REQUEST() {},
    SEND_ARM_COMMAND_REQUEST() {},
    SEND_PARTIAL_ARM_COMMAND_REQUEST() {},
    SEND_DISARM_COMMAND_REQUEST() {},
    GET_PANEL_LIST_REQUEST() {},
    ACTIVATE_PANEL(state, { payload: { panelId } }: PanelAction) {
      if (state[panelId]?.Info) {
        state[panelId]!.Info! = {
          ...state[panelId]!.Info!,
          InstallationStatus: InstallationStatus.ACTIVE,
        }
      }
    },
    ARM_COMMAND_SUCCESS(state, { payload: { panelId } }: PanelAction) {
      state[panelId] = updatePanelStatus(state[panelId], PanelStatus.FULLY_ARMED)
    },
    PARTIAL_ARM_COMMAND_SUCCESS(state, { payload: { panelId } }: PanelAction) {
      state[panelId] = updatePanelStatus(state[panelId], PanelStatus.HOME_MODE)
    },
    DISARM_COMMAND_SUCCESS(state, { payload: { panelId } }: PanelAction) {
      state[panelId] = updatePanelStatus(state[panelId], PanelStatus.TURNED_OFF)
      state[panelId] = updateAnnexStatus(state[panelId], PanelStatus.TURNED_OFF)
    },
    ARM_ANNEX_SUCCESS(state, { payload: { panelId } }: PanelAction) {
      state[panelId] = updateAnnexStatus(state[panelId], PanelStatus.FULLY_ARMED)
    },
    DISARM_ANNEX_SUCCESS(state, { payload: { panelId } }: PanelAction) {
      state[panelId] = updateAnnexStatus(state[panelId], PanelStatus.TURNED_OFF)
    },
    EXPAND_MENU(state, action: PayloadAction<{ panelId: string; menu: Expandable }>) {
      const { panelId, menu } = action.payload
      let expanded = state[panelId]?.expanded || []
      if (expanded.includes(menu)) {
        expanded = expanded.filter((openMenu) => openMenu !== menu)
      } else {
        expanded = [...expanded, menu]
      }
      state[panelId] = updatePanel(state[panelId], { expanded })
    },
    CLOSE_WIZARD(state, { payload: { panelId } }: PanelAction) {
      state[panelId] = updatePanel(state[panelId], { hasClosedWizard: true })
    },
    TOGGLE_ARC_CONSENT(state, action: PanelPayloadAction<{ Consent: boolean }>) {
      const { panelId, data } = action.payload
      if (state[panelId]?.Info) {
        state[panelId]!.Info!.ArcVideoConsent = data.Consent
      }
    },
    SET_PANEL_SETTINGS_SUCCESS(state, action: PanelPayloadAction<PanelSettingsPayload>) {
      const { panelId, data } = action.payload

      if (state[panelId]?.Info) {
        state[panelId]!.Info = {
          ...state[panelId]!.Info!,
          DisplayName: data.DisplayName,
          QuickArmEnabled: data.QuickArm,
        }
      }
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  ARM_COMMAND_SUCCESS,
  PARTIAL_ARM_COMMAND_SUCCESS,
  DISARM_COMMAND_SUCCESS,
  ARM_ANNEX_SUCCESS,
  DISARM_ANNEX_SUCCESS,
  LOAD_PANEL_INFO_SUCCESS,
  LOAD_PANEL_STATUS_SUCCESS,
  ACTIVATE_PANEL,
  CLOSE_WIZARD,
  TOGGLE_ARC_CONSENT,
  SET_PANEL_SETTINGS_SUCCESS,
  CHANGE_LOCK_NAME_SUCCESS,
  SET_LOCK_SETTINGS_SUCCESS,
  LOAD_GET_LOCK_LANGUAGE_SUCCESS,
} = panelSlice.actions

export const toggleExpandableMenu = panelSlice.actions.EXPAND_MENU

export const selectIsExpandedMenu = ({
  panelId,
  menu,
}: {
  panelId: string
  menu: Expandable
}) => (state: { panel: PanelStates }): boolean =>
  state.panel[panelId]?.expanded?.includes(menu) || false

export default panelSlice.reducer
