import { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import Button from '../../../../components/Crow/Button/Button'
import { colors } from '../../../../components/Crow/Style/theme'
import { Paragraph } from '../../../../components/Crow/Typography'
import { Loader } from '../../../../components/Elements/Loaders'
import useI18n from '../../../../i18n/useI18n'
import { useGetBenefits, useGetPartners } from '../../../../services/requestHooks/benefits'
import { Benefit } from '../../../../types/Benefit'
import { useOnMount } from '../../../../utils/commonHooks'
import { AddNewBenefit } from './AddNewBenefit'
import { BenefitsItem } from './BenefitsItem'
import { ProductOverview } from './ProductOverview'

const BenefitContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TextCss = css`
  font-style: normal;
  font-weight: 400;
`

const PointsText = styled(Paragraph)`
  ${TextCss}
  font-size: 20px;
  line-height: 24px;
  color: ${colors.black900};
`

const FindBenefitText = styled(Paragraph)`
  ${TextCss}
  font-size: 20px;
  color: ${colors.grayShade};
  line-height: 24px;
`

const DescriptionText = styled(Paragraph)`
  ${TextCss}
  font-size: 16px;
  color: ${colors.grayShade};
`

const SectionCss = css`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;
`

const Section = styled.div`
  ${SectionCss};

  & > * {
    flex: 1;
  }
`
const AddBenefitSection = styled.div`
  ${SectionCss};

  & > *:first-child {
    flex: 3;
  }
  & > *:last-child {
    flex: 1;
  }
`

const PartnersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    margin-bottom: 10px;
  }
`

const Benefits = () => {
  const { data: benefits = [], run: getBenefits, isLoading: loadingBenefits } = useGetBenefits()
  const { data: partners = [], run: getPartners, isLoading: loadingPartners } = useGetPartners()
  const [selectedBenefit, setSelectedBenefit] = useState<Benefit>()
  const [addBenefit, setAddBenefit] = useState(false)

  const nonActivatedPartners = useMemo(() => {
    const activatedPartnerIds = benefits.map((benefit) => benefit.PartnerId)
    return partners.filter((partner) => !activatedPartnerIds.includes(partner.Id))
  }, [benefits, partners])

  useOnMount(() => {
    getBenefits()
    getPartners()
  })
  const { t } = useI18n()
  const points = benefits.reduce((total, next) => total + next.EarnedPoints, 0)
  const loading = loadingBenefits || loadingPartners

  return (
    <BenefitContainer>
      {!addBenefit && benefits.length > 0 && <DescriptionText>{t('benefit_list')}</DescriptionText>}

      {addBenefit && nonActivatedPartners.length > 0 && (
        <DescriptionText>{t('benefit_list_activate')}</DescriptionText>
      )}

      {!addBenefit && nonActivatedPartners && nonActivatedPartners.length > 0 && (
        <AddBenefitSection>
          <FindBenefitText>{t('benefit_cannot_find')}</FindBenefitText>
          <Button level="primary" type="button" onClick={() => setAddBenefit(true)}>
            {t('add_benefit')}
          </Button>
        </AddBenefitSection>
      )}
      {!addBenefit &&
        nonActivatedPartners &&
        nonActivatedPartners.length === 0 &&
        benefits.length === 0 &&
        !loading && <FindBenefitText> {t('no_benefits')}</FindBenefitText>}
      <Section>
        {benefits.length > 0 && points > 0 && benefits.length > 0 && (
          <PointsText>{t('partner_points_to_spend', { points })}</PointsText>
        )}
        {loading && <Loader />}
      </Section>
      <PartnersContainer>
        {benefits.map((benefit) => (
          <BenefitsItem
            benefit={benefit}
            onClick={() => setSelectedBenefit(benefit)}
            currentlySelectedBenefit={benefit === selectedBenefit}
          />
        ))}
        {/* {nonActivatedPartners.map((partner) => (
          <PartnerItem partner={partner} />
        ))} */}
      </PartnersContainer>
      {addBenefit && nonActivatedPartners.length > 0 && (
        <AddNewBenefit partners={nonActivatedPartners} handleClose={() => setAddBenefit(false)} />
      )}

      {selectedBenefit && (
        <ProductOverview
          partnerId={selectedBenefit.PartnerId}
          handleClose={() => setSelectedBenefit(undefined)}
        />
      )}
    </BenefitContainer>
  )
}

export { Benefits }
