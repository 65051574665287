const AnnexIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 24V19H37.4914C37.9379 19 38.3303 19.296 38.4529 19.7253L39.6743 24H7Z"
        fill="#E3E1E1"
      />
      <path d="M39 40H7V44H39V40Z" fill="#E3E1E1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 28C11 27.4477 11.4477 27 12 27H22C22.5523 27 23 27.4477 23 28V34C23 34.5523 22.5523 35 22 35H12C11.4477 35 11 34.5523 11 34V28ZM13 33V29H16V33H13ZM18 33H21V29H18V33Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4C8 3.44772 7.55228 3 7 3C6.44772 3 6 3.44772 6 4L6 43H4C3.44772 43 3 43.4477 3 44C3 44.5523 3.44772 45 4 45H39C39.5523 45 40 44.5523 40 44V40C40 39.4477 39.5523 39 39 39V25H39.6743C40.3386 25 40.8183 24.3641 40.6358 23.7253L39.4144 19.4506C39.1691 18.592 38.3844 18 37.4914 18H8L8 4ZM38.3485 23H8V20H37.4914L38.3485 23ZM8 43V41H38V43H8ZM35 39H37V25H8L8 39H26V28C26 27.4477 26.4477 27 27 27H34C34.5523 27 35 27.4477 35 28V39ZM28 39H33V29H28V39Z"
        fill="#333131"
      />
    </svg>
  )
}

export { AnnexIcon }
