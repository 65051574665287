import createHTTPClient from './createHTTPClient'

const HTTPv2 = createHTTPClient('/v2/housecheck')
const HTTP = createHTTPClient('/housecheck')

export const housecheckDoorsAndWindows = (panelId: string) => {
  return HTTP.post(`/doorsandwindows`, { panelId })
}

export const housecheckSmokedetectors = (panelId: string) => {
  return HTTP.post(`/smokedetectors`, { panelId })
}

export const housecheckLeakagedetectors = (panelId: string) => {
  return HTTP.post(`/leakagedetectors`, { panelId })
}

export const postTemperatures = (panelId: string) => {
  return HTTPv2.post(`/temperatures`, { panelId })
}

export const temperaturePropertyAverage = (panelId: string) => {
  return HTTP.post(`/temperatures/average`, { panelId })
}

export const temperatureSectionsAverage = (panelId: string, locationName: string) => {
  return HTTP.post(`/temperatures/average/${locationName}`, { panelId })
}

export const housecheckHumidity = (panelId: string) => {
  return HTTP.get(`/panels/${panelId}/humidity`)
}
