import { format, isBefore } from 'date-fns'
import { ComponentType, FC } from 'react'
import { SaWarrantyIcon, UserIcon } from '../../../../assets/icons'
import Button from '../../../../components/Button'
import CrowCard from '../../../../components/Crow/CrowCard/CrowCard'
import { Card, CardButton } from '../../../../components/Elements/Card'
import { useFeatureToggle } from '../../../../components/FeatureToggle'
import LoadPage from '../../../../components/LoadPage'
import useI18n from '../../../../i18n/useI18n'
import { useSetPreInstallationWizardDone } from '../../../../services/requestHooks/booking'
import { UserFeature } from '../../../../types/UserDetails'
import { useDateFnsLocale } from '../../../../utils/useDateFnsLocale'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../hooks/usePanelId'
import PropertyContactModal, {
  usePropertyContactModal,
} from './PropertyContact/PropertyContactModal'
import TimeSlotModal, { useTimeSlotModal } from './TimeSlot/TimeSlotModal'

type BookingButtonProps = {
  onClick: () => void
  Icon: ComponentType<{ className?: string }>
  title: string
  interactive?: boolean
}

const BookingButton: FC<BookingButtonProps> = ({ onClick, Icon, title, children, interactive }) => {
  const Element = interactive ? CardButton : Card

  return (
    <Element
      className="w-full text-left flex"
      onClick={interactive ? onClick : undefined}
      title={interactive ? title : undefined}
    >
      <Icon className="icon icon--small mr-4 my-2" />
      <div className="flex-1">{children}</div>
    </Element>
  )
}

type Props = {
  refreshPanelInfo: () => void
  startDateString?: string
  endDateString?: string
  propertyContact: {
    FirstName: string
    LastName: string
    PhoneNumber: string
  }
}

const BookingWizard: FC<Props> = ({
  refreshPanelInfo,
  startDateString,
  endDateString,
  propertyContact,
}) => {
  const canChangeBooking = useFeatureToggle(UserFeature.BOOKING)

  const { t } = useI18n()
  const locale = useDateFnsLocale()
  const panelId = usePanelId()

  const { modalState: timeSlotState, promptForTimeSlot } = useTimeSlotModal()
  const { modalState: propertyContactState, promptForContactUser } = usePropertyContactModal()

  const startDate = startDateString && new Date(startDateString)
  const endDate = endDateString && new Date(endDateString)

  const dateIsOverAyearAgo = (date: Date) => {
    return isBefore(date, new Date().setMonth(new Date().getMonth() - 12))
  }

  const datesAreInvalid = (startDate: any, endDate: any): boolean => {
    if (!startDate || !endDate) {
      return true
    }
    return dateIsOverAyearAgo(startDate) || dateIsOverAyearAgo(endDate)
  }

  const hasBooking = !!startDate && !!endDate && !datesAreInvalid(startDate, endDate)

  const {
    run: setWizardDone,
    isLoading: settingWizardDone,
    error: setWizardDoneError,
  } = useSetPreInstallationWizardDone({
    onSuccess: () => {
      refreshPanelInfo()
    },
  })

  const onStartClick = async () => {
    const timeSlotChosen = await promptForTimeSlot()
    if (timeSlotChosen) {
      const userWasSet = await promptForContactUser()
      if (userWasSet) {
        setWizardDone({ panelId })
      }
    }
  }

  const onTimeSlotClick = async () => {
    const timeSlotChosen = await promptForTimeSlot()
    if (timeSlotChosen) {
      refreshPanelInfo()
    }
  }

  const onPropertyContactClick = async () => {
    const userWasSet = await promptForContactUser()
    if (userWasSet) {
      refreshPanelInfo()
    }
  }

  return (
    <>
      <LoadPage isLoading={settingWizardDone}>
        {hasBooking ? (
          <>
            <p className="mt-2 mb-4">{t('your_order_details')}</p>
            <BookingButton
              onClick={onTimeSlotClick}
              Icon={SaWarrantyIcon}
              title={t('CHOOSE_INSTALL_DATE')}
              interactive={canChangeBooking}
            >
              <div>{format(startDate, 'PPP', { locale })}</div>
              <div>{`${format(startDate, 'p', {
                locale,
              })} - ${format(endDate, 'p', {
                locale,
              })}`}</div>
            </BookingButton>
            <BookingButton
              onClick={onPropertyContactClick}
              Icon={UserIcon}
              title={t('CHANGE_PROPERTY_CONTACT_HEADER')}
              interactive
            >
              <div>{`${propertyContact.FirstName} ${propertyContact.LastName}`}</div>
              <div>{`${propertyContact.PhoneNumber}`}</div>
              <div>
                <i>{t('PROPERTY_CONTACT')}</i>
              </div>
            </BookingButton>
            {!!setWizardDoneError && (
              <FailedRequest errorId="bookingwizard" text={setWizardDoneError} />
            )}
          </>
        ) : (
          canChangeBooking && (
            <CrowCard>
              <div className="user-info">
                <h3 className="bold">{t('INSTALLATION_INFORMATION')}</h3>
                <p className="margin-top">{t('INSTALLATION_INFORMATION_DETAILS')}</p>
              </div>
              <Button
                id="START_SETUP"
                className="margin-top--large"
                buttonType="primary"
                onClick={onStartClick}
              >
                {t('START_SETUP')}
              </Button>
            </CrowCard>
          )
        )}
      </LoadPage>
      <PropertyContactModal {...propertyContactState} />
      <TimeSlotModal {...timeSlotState} />
    </>
  )
}

export default BookingWizard
