import { FC, useEffect } from 'react'
import styled from 'styled-components'

import Button from '../../components/Crow/Button/Button'
import { colors } from '../../components/Crow/Style/theme'
import { Paragraph, Title } from '../../components/Crow/Typography/Typography'
import { Loader } from '../../components/Elements/Loaders'
import useI18n from '../../i18n/useI18n'
import { useAuth } from '../../services/authentication/AuthContext'
import { useGetUserInfo, useUserInfo } from '../../services/requestHooks/user'
import { useResendEmailVerification } from '../../services/requestHooks/users'

const EmailConfirmationPage: FC = () => {
  const { run: runGetUserInfo, isLoading, hasLoaded } = useGetUserInfo()

  useEffect(() => {
    runGetUserInfo()
  }, [runGetUserInfo])

  if (isLoading || !hasLoaded) {
    return null
  }

  return <Content />
}

const Content: FC = () => {
  const { t } = useI18n()
  const { login } = useAuth()
  const { UserName: email } = useUserInfo()
  const { run: resendEmailVerification, isLoading, hasLoaded, error } = useResendEmailVerification()

  const refresh = () => login()

  return (
    <Container>
      <ConfirmationTitle>{t('EMAIL_CONFIRMATION_REQUIRED')}</ConfirmationTitle>
      <Instructions large={true}>{t('email_verification_description', { email })}</Instructions>
      <ResendButton onClick={resendEmailVerification} level="primary" size="l">
        {isLoading ? <Loader /> : t('RESEND_EMAIL')}
      </ResendButton>
      {hasLoaded && <ResentText>{t('email_verification_resent')}</ResentText>}
      {error && <ResentErrorText>{t(error)}</ResentErrorText>}
      <AlreadyConfirmedWrapper>
        <AlreadyConfirmedText large={true}>{t('EMAIL_ALREADY_CONFIRMED')}</AlreadyConfirmedText>
        <RefreshButton onClick={refresh}>{t('REFRESH_NOW')}</RefreshButton>
      </AlreadyConfirmedWrapper>
    </Container>
  )
}

const Container = styled.div`
  border-radius: 20px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e5e5e5;
  width: 100%;
  height: 100%;
`

const ConfirmationTitle = styled(Title)`
  margin: 0;
  color: ${colors.yellow};
`

const Instructions = styled(Paragraph)`
  margin-top: 1rem;
  color: ${colors.black900};
`

const ResendButton = styled(Button)`
  margin-top: 2.5rem;
`

const ResentText = styled(Paragraph)`
  margin-top: 0.5rem;
  color: ${colors.lightBlue};
`

const ResentErrorText = styled(Paragraph)`
  margin-top: 0.5rem;
  color: ${colors.red};
`

const AlreadyConfirmedWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AlreadyConfirmedText = styled(Paragraph)`
  color: ${colors.black900};
`

const RefreshButton = styled.button`
  color: ${colors.blue};
`

export default EmailConfirmationPage
