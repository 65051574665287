const TVRoomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="32" y="15" width="4" height="17" fill="#E3E1E1" />
      <path
        d="M35 17C35 17.5523 34.5523 18 34 18C33.4477 18 33 17.5523 33 17C33 16.4477 33.4477 16 34 16C34.5523 16 35 16.4477 35 17Z"
        fill="#333131"
      />
      <path
        d="M34 21C34.5523 21 35 20.5523 35 20C35 19.4477 34.5523 19 34 19C33.4477 19 33 19.4477 33 20C33 20.5523 33.4477 21 34 21Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2071 6.20711C27.5976 5.81658 27.5976 5.18342 27.2071 4.79289C26.8166 4.40237 26.1834 4.40237 25.7929 4.79289L18 12.5858L12.7071 7.29289C12.3166 6.90237 11.6834 6.90237 11.2929 7.29289C10.9024 7.68342 10.9024 8.31658 11.2929 8.70711L15.5858 13H11C9.89543 13 9 13.8954 9 15V32C9 33.1046 9.89543 34 11 34H12C12 35.1046 12.8954 36 14 36C15.1046 36 16 35.1046 16 34H31C31 35.1046 31.8954 36 33 36C34.1046 36 35 35.1046 35 34H36C37.1046 34 38 33.1046 38 32V15C38 13.8954 37.1046 13 36 13H20.4142L27.2071 6.20711ZM36 28V29H32V28H36ZM36 32V31H32V32H36ZM11 15H30V32H11L11 15ZM36 26V15H32V26H36Z"
        fill="#333131"
      />
      <path
        d="M5 37C4.44772 37 4 37.4477 4 38C4 38.5523 4.44771 39 5 39H43C43.5523 39 44 38.5523 44 38C44 37.4477 43.5523 37 43 37H5Z"
        fill="#333131"
      />
    </svg>
  )
}

export { TVRoomIcon }
