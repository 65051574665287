const KitchenIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 29H4V44H30V29ZM15 36.7271H8V39.7271H15V36.7271Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00106 14.0658C9.00036 14.044 9 14.022 9 14V11C9 9.89543 9.89543 9 11 9H13C14.1046 9 15 9.89543 15 11V14C15 14.0221 14.9996 14.044 14.9989 14.0659C15.5676 14.2158 16.0515 14.6121 16.3053 15.1619L18.3448 19.5809C18.6507 20.2436 18.1667 21 17.4369 21H6.5627C5.83291 21 5.34892 20.2436 5.65474 19.5809L7.69431 15.1619C7.94812 14.612 8.43219 14.2156 9.00106 14.0658ZM11 11H13V14H11V11ZM9.51023 16L8.12561 19H15.874L14.4894 16H9.51023Z"
        fill="#333131"
      />
      <path
        d="M32 19C32 19.5523 32.4477 20 33 20C33.5523 20 34 19.5523 34 19V17C34 16.4477 33.5523 16 33 16C32.4477 16 32 16.4477 32 17V19Z"
        fill="#333131"
      />
      <path
        d="M33 29C32.4477 29 32 28.5523 32 28V26C32 25.4477 32.4477 25 33 25C33.5523 25 34 25.4477 34 26V28C34 28.5523 33.5523 29 33 29Z"
        fill="#333131"
      />
      <path
        d="M16 33C16 33.5523 15.5523 34 15 34H8C7.44771 34 7 33.5523 7 33C7 32.4477 7.44771 32 8 32H15C15.5523 32 16 32.4477 16 33Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 37C7 36.4477 7.44772 36 8 36H15C15.5523 36 16 36.4477 16 37V40C16 40.5523 15.5523 41 15 41H8C7.44772 41 7 40.5523 7 40V37ZM9 39V38H14V39H9Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 13C29 11.8954 29.8954 11 31 11H43C44.1046 11 45 11.8954 45 13V45H5C3.89543 45 3 44.1046 3 43V30C3 28.8954 3.89543 28 5 28H29V13ZM31 13H43V21H31V13ZM31 23H43V43H31V23ZM29 30H20V43H29V30ZM5 30H18L18 43H5V30Z"
        fill="#333131"
      />
    </svg>
  )
}

export { KitchenIcon }
