import * as React from 'react'

const UsersIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" {...props}>
      <path d="M74.7 81.3c-2.9-1.3-10.1-4.1-10.1-4.1-.6-.2-1.2 0-1.7.3-2.1 1.5-4.6 2.3-7.1 2.3-7.8 0-14.2-7.8-14.2-17.3S48 45.1 55.8 45.1 70 52.9 70 62.5c0 3.7-1 7.4-3 10.6-.5.8-.3 2 .5 2.5s2 .3 2.5-.5c2.4-3.8 3.6-8.1 3.6-12.6 0-11.6-8-21-17.8-21s-18.1 9.4-18.1 21 8 21 18.1 21c3 0 5.9-.9 8.4-2.5 2 .8 6.7 2.7 9 3.7 5.1 2.2 5.8 3.5 5.8 7.5V95H32.6v-2.6c0-4 .7-5.3 5.8-7.5l4-1.7c.9-.4 1.3-1.5.9-2.4-.4-.9-1.5-1.3-2.4-.9L37 81.3c-6.2 2.7-8 5.1-8 10.8v4.2c0 1 .8 1.8 1.8 1.8H81c1 0 1.8-.8 1.8-1.8v-4.2c0-5.6-1.8-8.1-8.1-10.8zm30.3-3.7l-7.2-2.9c-.6-.2-1.2 0-1.7.3-1.4 1-3.1 1.6-4.8 1.6-5.9-.7-10.2-5.9-9.6-11.8-.6-5.9 3.7-11.2 9.6-11.8 5.9.7 10.2 5.9 9.6 11.8 0 1.8-.3 3.5-1 5.2-.4.9.1 2 1 2.4.9.4 2-.1 2.4-1 .8-2.1 1.2-4.3 1.2-6.5.6-7.9-5.4-14.8-13.3-15.5-7.9.6-13.8 7.6-13.3 15.5-.6 7.9 5.4 14.8 13.3 15.5 2.1 0 4.2-.6 6.1-1.8l6.2 2.5c3.5 1.5 3.8 2.3 3.8 4.9v1.2h-18c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h19.8c1 0 1.8-.8 1.8-1.8v-3.2c.3-3.8-2.2-7.3-5.9-8.2z" />
    </svg>
  )
}

export { UsersIcon }
