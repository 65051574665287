import { ReactNode } from 'react'
import styled from 'styled-components'
import { displayNewNavigationMenu } from '../configs'
import { Capability, PanelModel } from '../types/PanelModel'
import Layout from './Crow/NavigationMenu/Layout/Layout'
import { colors } from './Crow/Style/theme'
import Footer from './Footer'

type Props = {
  panelInfo?: PanelModel
  children?: ReactNode
  hideWrapper?: boolean
  fullscreen?: boolean
  footer?: boolean
}

export const TcOrCrowContainer = ({
  children,
  panelInfo,
  hideWrapper,
  fullscreen,
  footer,
}: Props) => {
  const displayOldNavigationMenu =
    panelInfo?.Capabilities?.includes(Capability.USE_LEGACY_HOMESCREEN) || !displayNewNavigationMenu

  if (panelInfo?.Capabilities)
    return (
      <>
        {displayOldNavigationMenu ? (
          hideWrapper ? (
            <>{children}</>
          ) : fullscreen ? (
            <div className="flex flex-1 fullscreen-page">
              <div className="grid py-16 px-8">
                <div className="grid__half">{children}</div>
              </div>
            </div>
          ) : (
            <Container bg>{children}</Container>
          )
        ) : (
          <Layout transparentBackground={hideWrapper} panelInfo={panelInfo}>
            <Container column>
              {children}
              {footer && (
                <div className="mt-auto">
                  <Footer />
                </div>
              )}
            </Container>
          </Layout>
        )}
      </>
    )

  return null
}

const Container = styled.div<{ bg?: boolean; column?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ column }) => column && 'column'};
  background-color: ${({ bg }) => bg && colors.black100};
`
