import * as React from 'react'

const TrashCanIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="#E4464E" />
      <g clipPath="url(#clip0_16704_108166)">
        <path
          d="M24.2495 9.25L7.74951 9.25001"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.25 6.25H19.75"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.75 9.25V23.5C22.75 23.6989 22.671 23.8897 22.5303 24.0303C22.3897 24.171 22.1989 24.25 22 24.25H10C9.80109 24.25 9.61032 24.171 9.46967 24.0303C9.32902 23.8897 9.25 23.6989 9.25 23.5V9.25"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.01109 6.98901C-50.5852 8.80889 -107.223 6.96987 -161.216 15.9687"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.01114 21.3567C-46.8911 25.0512 -97.9609 25.6032 -148.943 27.6425C-166.161 28.3312 -183.49 30.3364 -200.727 30.3364"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#E4464E" />
      <defs>
        <clipPath id="clip0_16704_108166">
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export { TrashCanIcon }
