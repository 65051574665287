const GunRoomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.9999 9C30.9999 8.44772 30.5522 8 29.9999 8C29.4476 8 28.9999 8.44772 28.9999 9V18H26.9999C26.4476 18 25.9999 18.4477 25.9999 19V23.5774L23.5358 25C23.0575 25.2761 22.8937 25.8877 23.1698 26.366C23.4459 26.8443 24.0575 27.0082 24.5358 26.7321L25.9999 25.8868V26.8334L23.1269 28.0305C22.4349 28.3188 21.9623 28.9699 21.9025 29.7171L21.0863 39.9203C21.0397 40.5022 21.4993 41.0001 22.0831 41.0001H26.9999C27.5522 41.0001 27.9999 40.5523 27.9999 40.0001V34H29.9999C30.5522 34 30.9999 33.5523 30.9999 33L30.9999 11C31.5522 11 31.9999 10.5523 31.9999 10C31.9999 9.44772 31.5522 9 30.9999 9ZM27.9999 27.5001L27.9999 27.4988L27.9999 20H28.9999L28.9999 32H27.9999L27.9999 27.5001ZM23.8961 29.8766L25.9999 29.0001L25.9999 39.0001H23.1663L23.8961 29.8766Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.6004 5C33.6453 4.98046 33.6921 4.96404 33.7405 4.95103L39.7405 3.33853C40.3758 3.1678 41 3.64643 41 4.30426V44.6958C41 45.3536 40.3758 45.8323 39.7405 45.6615L33.7405 44.049C33.692 44.036 33.6452 44.0196 33.6003 44H12C11.4477 44 11 43.5523 11 43V6C11 5.44772 11.4477 5 12 5H33.6004ZM39 43.3916L35 42.3166V6.6835L39 5.6085V43.3916ZM13 21.4649C13.5883 21.8052 14.2714 22 15 22C17.2091 22 19 20.2091 19 18C19 15.7909 17.2091 14 15 14C14.2714 14 13.5883 14.1948 13 14.5351V12.3414C13.6256 12.1203 14.2987 12 15 12C18.3137 12 21 14.6863 21 18C21 21.3137 18.3137 24 15 24C14.2987 24 13.6256 23.8797 13 23.6586V21.4649ZM13 7V10.252C13.3264 10.168 13.6608 10.1041 14.0019 10.0617C14.0006 10.0413 14 10.0207 14 10V9C14 8.44772 14.4477 8 15 8C15.5523 8 16 8.44772 16 9V10C16 10.0207 15.9994 10.0413 15.9981 10.0617C19.6184 10.5122 22.4878 13.3816 22.9383 17.0019C22.9587 17.0006 22.9793 17 23 17H24C24.5523 17 25 17.4477 25 18C25 18.5523 24.5523 19 24 19H23C22.9793 19 22.9587 18.9994 22.9383 18.9981C22.4878 22.6184 19.6184 25.4878 15.9981 25.9383C15.9994 25.9587 16 25.9793 16 26V27C16 27.5523 15.5523 28 15 28C14.4477 28 14 27.5523 14 27V26C14 25.9793 14.0006 25.9587 14.0019 25.9383C13.6608 25.8959 13.3264 25.832 13 25.748V42H33V7H13ZM13 18C13 16.8954 13.8954 16 15 16C16.1046 16 17 16.8954 17 18C17 19.1046 16.1046 20 15 20C13.8954 20 13 19.1046 13 18Z"
        fill="#333131"
      />
      <path
        d="M15 22C14.2714 22 13.5883 21.8052 13 21.4649V23.6586C13.6256 23.8797 14.2987 24 15 24C18.3137 24 21 21.3137 21 18C21 14.6863 18.3137 12 15 12C14.2987 12 13.6256 12.1203 13 12.3414V14.5351C13.5883 14.1948 14.2714 14 15 14C17.2091 14 19 15.7909 19 18C19 20.2091 17.2091 22 15 22Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 10.252V7H33V42H13V25.748C13.3264 25.832 13.6608 25.8959 14.0019 25.9383C14.0006 25.9587 14 25.9793 14 26V27C14 27.5523 14.4477 28 15 28C15.5523 28 16 27.5523 16 27V26C16 25.9793 15.9994 25.9587 15.9981 25.9383C19.6184 25.4878 22.4878 22.6184 22.9383 18.9981C22.9587 18.9994 22.9793 19 23 19H24C24.5523 19 25 18.5523 25 18C25 17.4477 24.5523 17 24 17H23C22.9793 17 22.9587 17.0006 22.9383 17.0019C22.4878 13.3816 19.6184 10.5122 15.9981 10.0617C15.9994 10.0413 16 10.0207 16 10V9C16 8.44772 15.5523 8 15 8C14.4477 8 14 8.44772 14 9V10C14 10.0207 14.0006 10.0413 14.0019 10.0617C13.6608 10.1041 13.3264 10.168 13 10.252ZM29.9999 8C30.5522 8 30.9999 8.44772 30.9999 9C31.5522 9 31.9999 9.44772 31.9999 10C31.9999 10.5523 31.5522 11 30.9999 11L30.9999 33C30.9999 33.5523 30.5522 34 29.9999 34H27.9999V40.0001C27.9999 40.5523 27.5522 41.0001 26.9999 41.0001H22.0831C21.4993 41.0001 21.0397 40.5022 21.0863 39.9203L21.9025 29.7171C21.9623 28.9699 22.4349 28.3188 23.1269 28.0305L25.9999 26.8334V25.8868L24.5358 26.7321C24.0575 27.0082 23.4459 26.8443 23.1698 26.366C22.8937 25.8877 23.0575 25.2761 23.5358 25L25.9999 23.5774V19C25.9999 18.4477 26.4476 18 26.9999 18H28.9999V9C28.9999 8.44772 29.4476 8 29.9999 8Z"
        fill="#E3E1E1"
      />
    </svg>
  )
}

export { GunRoomIcon }
