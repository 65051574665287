import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Benefit } from '../types/Benefit'
import { BonusProduct } from '../types/BonusProduct'
import { Partner } from '../types/Partner'

type BenefitsState = {
  benefits: Benefit[]
  partners: Partner[]
  bonusProducts: BonusProduct[]
  transactions: any[]
}

const initialState: BenefitsState = {
  benefits: [],
  partners: [],
  bonusProducts: [],
  transactions: [],
}

const benefitsSlice = createSlice({
  name: 'benefits',
  initialState,
  reducers: {
    UPDATE_BENEFITS(state, action: PayloadAction<Benefit[]>) {
      state.benefits = action.payload
    },
    UPDATE_PARTNERS(state, action: PayloadAction<Partner[]>) {
      state.partners = action.payload
    },
    LINK_TO_PARTNER(state, action) {},
    GET_BONUS_PRODUCTS_SUCCESS(state, action: PayloadAction<BonusProduct[]>) {
      state.bonusProducts = action.payload
    },
    BUY_BONUS_PRODUCT_SUCCESS() {},
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  UPDATE_BENEFITS,
  UPDATE_PARTNERS,
  LINK_TO_PARTNER,
  GET_BONUS_PRODUCTS_SUCCESS,
  BUY_BONUS_PRODUCT_SUCCESS,
} = benefitsSlice.actions

export default benefitsSlice.reducer
