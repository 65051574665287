import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'

import Button from '../../../components/Crow/Button/Button'
import { default as NameField } from '../../../components/Crow/Input/TextFieldForForm'
import { addToast } from '../../../components/Crow/Toast/Toast'
import { SubTitle } from '../../../components/Crow/Typography'
import { Loader } from '../../../components/Elements/Loaders'
import useI18n from '../../../i18n/useI18n'
import { usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { Panel } from '../../../types/PermanentUser'

import { useSetSmartplugLabel } from '../../../services/requestHooks/plugs'
import { isTranslationKey } from '../../../types/generated/TranslationKey'

interface Props {
  smartPlug: {
    Label: string
    PanelId: string
    SerialNo: string
  }
  closeDrawer: () => void
  panelPermissions: Panel | undefined
}

const SmartPlugsSettings = ({
  smartPlug: { Label, PanelId, SerialNo },
  closeDrawer,
  panelPermissions,
}: Props) => {
  const { t } = useI18n()
  const { run: setSmartplugLabel, isLoading: loadingSmartplugLabel } = useSetSmartplugLabel({
    onSuccess: async () => {
      closeDrawer()
    },
    onFailure: ({ message }) => {
      addToast({
        type: 'error',
        time: 5000,
        title: t('error_toast_generic_title'),
        content: isTranslationKey(message) ? t(message) : t('error_toast_generic_content'),
      })
    },
  })

  const { data: panelStatus } = usePanelStatus()

  const resolver = yup.object({
    Label: yup.string().trim(),
  })

  const defaultValues = {
    Label,
    PanelId,
    Serial: SerialNo,
  }

  const { register, handleSubmit, watch } = useForm({
    defaultValues: defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(resolver),
  })

  const onSubmit = async (formData: any) => {
    setSmartplugLabel(formData)
  }

  const formIsDisabled =
    panelStatus?.Status !== 1 ||
    !(panelPermissions?.Accesses.IsAdminUser || panelPermissions?.Accesses.IsLegalOwner)

  const labelLength = watch('Label').length

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SubTitle>{t('Name')}</SubTitle>
        <NameField maxLength={40} disabled={formIsDisabled} {...register('Label')} />
        {!formIsDisabled && (
          <SmartPlugsBtnContainer>
            <Button
              className="qa-save-btn"
              level="secondary"
              disabled={loadingSmartplugLabel || labelLength === 0}
            >
              {loadingSmartplugLabel ? <Loader /> : t('Save')}
            </Button>
          </SmartPlugsBtnContainer>
        )}
      </form>
    </div>
  )
}

const SmartPlugsBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`

export default SmartPlugsSettings
