import { FC, Fragment, useState } from 'react'
import styled from 'styled-components'

import { CancelIcon, TemperatureIcon } from '../../../assets/icons/crow'
import BackButton from '../../../components/BackButton'
import Drawer from '../../../components/Crow/Drawer/Drawer'
import { colors } from '../../../components/Crow/Style/theme'
import Tile from '../../../components/Crow/Tile/Tile'
import { Heading3, Title } from '../../../components/Crow/Typography'
import FullscreenPage from '../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import { displayNewNavigationMenu, displayTemperatureLiveApp } from '../../../configs'
import useI18n from '../../../i18n/useI18n'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { usePostTemperatures } from '../../../services/requestHooks/temperatureList'
import { useTemperatureSettings } from '../../../services/requestHooks/temperatureSettings'
import { Section, Temperature, TemperatureSettingsType } from '../../../types/Temperature'
import { useOnMount } from '../../../utils/commonHooks'
import { logBackEvent } from '../../../utils/firebaseAnalytics'
import FloorName from '../../../utils/places/FloorName'
import RoomName from '../../../utils/places/RoomName'
import { RoomSwitchIcon } from '../../../utils/places/RoomSwitchIcon'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import GeneralSettings from './GeneralSettings'
import TemperatureDetails from './TemperatureDetails'
import TemperatureTileFooter from './TemperatureTileFooter'

const CrowTemperaturePage: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()

  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false)
  const [temperatureState, setTemperatureState] = useState<Section>()
  const displayTemperatureSetting = displayTemperatureLiveApp === 'true'
  const returnUrl = `/systems/${panelId}`

  const {
    data: panelStatus,
    isLoading: loadingPanelStatus,
    error: panelStatusError,
  } = usePanelStatus()

  const {
    run: getTemperaturesSettings,
    isLoading: loadingTemperaturesSettings,
    data: temperaturesSettings,
  } = useTemperatureSettings()

  const {
    run: getTemperatures,
    isLoading: loadingTemperatures,
    error: temperatureError,
    data: temperatures,
  } = usePostTemperatures()

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadInfoError,
  } = usePanelInfo()

  const hasError = !!temperatureError || !!panelStatusError || !!loadInfoError

  useOnMount(() => {
    if (!loadingPanelInfo) {
      loadPanelInfo(panelId)
    }
    if (!loadingTemperatures) {
      getTemperatures(panelId)
    }
    if (!loadingTemperaturesSettings && displayTemperatureSetting) {
      temperaturesSettings ? temperaturesSettings! : getTemperaturesSettings(panelId)!
    }
  })

  const openDrawer = (
    floors: Section,
    roomName: string,
    roomKey: string,
    roomIndex: number | undefined,
    roomDevices: Temperature[],
    averageTemp?: number,
  ) => () => {
    setTemperatureState({
      Name: floors?.Name!,
      Key: floors?.Key!,
      Places: [
        {
          Name: roomName,
          Key: roomKey,
          Index: roomIndex,
          Components: roomDevices,
          AverageTemperature: averageTemp!,
        },
      ],
    })
    setDrawerIsOpen(true)
  }
  const conditionForLiveApp =
    temperaturesSettings?.length! > 0 && temperaturesSettings![0].Key === 'TemperatureLiveApp'

  const temperatureSettingPreference: TemperatureSettingsType = {
    liveappSetting: conditionForLiveApp ? temperaturesSettings![0].Value.Option : 'places',
    liveappToggle: conditionForLiveApp ? true : false,
  }
  const closeDrawer = () => {
    setDrawerIsOpen(false)
  }
  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen footer>
      <FullscreenPage
        isLoading={loadingTemperatures || loadingPanelStatus}
        loaderProps={{
          loadingText: t('Loading'),
          errorHandling: {
            loadingFailed: !loadingTemperatures && hasError && !panelStatus?.IsOnline,
            errorHeaderText: t('Temperature'),
            errorText: !panelStatus?.IsOnline
              ? t('You can not get tempratures while you are offline')
              : t('Something went wrong. Please try again'),
          },
        }}
      >
        {!displayNewNavigationMenu && (
          <BackButton backUrl={returnUrl} onClick={() => logBackEvent('temperature')} />
        )}
        <div className="flex flex-row justify-center items-center">
          <Title large>{t('Temperature')}</Title>
          <div className="ml-auto">
            {displayTemperatureSetting && (
              <GeneralSettings
                temperatureSetting={temperatureSettingPreference}
                panelId={panelId}
              />
            )}
          </div>
        </div>
        <Description>{t('TEMPERATURES_DESCRIPTION')}</Description>
        <>
          <div>
            {temperatures?.Sections.map((floor) => (
              <SectionWrapper key={floor.Key} className="qa-area-container">
                <SectionTitle className="qa-area-name">
                  <FloorName floorName={floor.Key} />
                </SectionTitle>
                <TilesWrapper>
                  {floor.Places.map((room, index) => (
                    /* Use room.Index as key instead when data is correct*/
                    <Tile
                      key={index}
                      onClick={openDrawer(floor, room.Name, room.Key!, room.Index, room.Components)}
                    >
                      <TileTop aria-label="tile-content">
                        <RoomTemperature className="qa-place-average-temperature">
                          {Math.round(room.AverageTemperature)}°
                        </RoomTemperature>
                        <TemperatureIcon />
                      </TileTop>
                      <TileCenter className="qa-place-name">
                        <RoomName roomName={room.Key} />
                        {room.Index && room.Index > 0 ? <span>&nbsp; {room.Index}</span> : null}
                      </TileCenter>
                      <TileBottom>
                        <FooterDevices className="qa-sensors-count">{t('Sensors')}</FooterDevices>
                        <TemperatureTileFooter props={room.Components}></TemperatureTileFooter>
                      </TileBottom>
                    </Tile>
                  ))}
                </TilesWrapper>
              </SectionWrapper>
            ))}
          </div>

          <Drawer
            isOpen={drawerIsOpen}
            position="right"
            handleClose={closeDrawer}
            closeButton={true}
            closeIcon={<CancelIcon color={colors.blue} />}
            closeButtonClassName={'qa-close-sensor-overlay'}
            title={
              <Header>
                {temperatureState?.Places.map((room) => (
                  <Fragment key={room.Name}>
                    <span className="mr-auto qa-place-name-location">
                      <RoomName roomName={room.Key} />{' '}
                      {room.Index && room.Index > 0 ? <span>{room.Index}&nbsp;</span> : null}(
                      {<FloorName floorName={temperatureState.Key} />})
                    </span>
                    <IconWrapper>
                      <RoomSwitchIcon roomKey={room.Key} />
                    </IconWrapper>
                  </Fragment>
                ))}
              </Header>
            }
          >
            <TemperatureDetails floors={temperatureState!} />
          </Drawer>
        </>
      </FullscreenPage>
    </TcOrCrowContainer>
  )
}

const Description = styled.p`
  margin-top: 16px;
`

const SectionWrapper = styled.div`
  margin-top: 24px;

  &:first-child {
    margin-top: 42px;
  }
`

const SectionTitle = styled(Heading3)`
  margin-left: 8px;
  color: ${colors.black700};
`

const TilesWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media only screen and (max-width: 600px) {
    gap: 15px;
  }
`

const TileTop = styled.div`
  display: flex;
  justify-content: space-between;
`

const RoomTemperature = styled.div`
  font-size: 56px;
  line-height: 35px;
`

const TileCenter = styled(Heading3)`
  margin-top: 9px;
  display: flex;
  align-items: center;
  line-height: 22px;
  color: ${colors.black800};
  max-width: 138px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const TileBottom = styled.div`
  display: flex;
  flex-direction: column;
`

const FooterDevices = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${colors.black900};
`

const Header = styled.div`
  padding-bottom: 32px;
  display: flex;
`

const IconWrapper = styled.span`
  position: relative;
  bottom: 15px;
`

export default CrowTemperaturePage
