const DoorLockedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 2V18H17V17C17 16.4477 17.4477 16 18 16C18.5523 16 19 16.4477 19 17V18H21C21.5523 18 22 18.4477 22 19C22 19.5523 21.5523 20 21 20H1C0.447715 20 0 19.5523 0 19C0 18.4477 0.447715 18 1 18H3V1.75C3 1.29779 3.17263 0.856864 3.49121 0.526028C3.81103 0.19391 4.25302 0 4.72222 0L17.2778 0C17.747 0 18.189 0.193909 18.5088 0.526028C18.8274 0.856865 19 1.29779 19 1.75V3C19 3.55228 18.5523 4 18 4C17.4477 4 17 3.55228 17 3V2H5Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M11 11C11.5523 11 12 10.5523 12 10C12 9.44771 11.5523 9 11 9C10.4477 9 10 9.44771 10 10C10 10.5523 10.4477 11 11 11Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8C13.4477 8 13 8.44771 13 9V14C13 14.5523 13.4477 15 14 15H21C21.5523 15 22 14.5523 22 14V9C22 8.44772 21.5523 8 21 8C21 6.14645 19.2132 5 17.5 5C15.7868 5 14 6.14645 14 8ZM15 10V13H20V10H15ZM16 8H19C19 7.64441 18.5482 7 17.5 7C16.4518 7 16 7.64441 16 8Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { DoorLockedIcon }
