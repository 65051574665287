import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import Button from '../../../components/Crow/Button/Button'
import CrowRadioButton from '../../../components/Crow/CrowRadioButton/CrowRadioButton'
import { translations } from '../../../i18n/translations'
import useI18n from '../../../i18n/useI18n'
import { useGetUserInfo, useSetCulture, useUserInfo } from '../../../services/requestHooks/user'
import { TranslationKeyOverride } from '../../../types/TranslationKeyOverride'
import { UserCultureInfo } from '../../../types/UserDetails'

const getLanguageName = (culture: string): string | undefined => {
  return Object.values(translations).find((it) => it.culture === culture)?.name
}

type FormValues = {
  userCultureInfo: UserCultureInfo
  culture: UserCultureInfo
}

interface LanguageContentProps {
  closeDrawer: () => void
}

const LanguageContent = ({ closeDrawer }: LanguageContentProps) => {
  const { t } = useI18n()
  const account = useUserInfo()

  const { run: refreshUserInfo } = useGetUserInfo()
  const { run: setCulture } = useSetCulture({
    onSuccess: () => {
      refreshUserInfo()
    },
  })

  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: { userCultureInfo: account.UserCultureInfo },
    mode: 'all',
  })

  const onSubmit = (values: FormValues) => {
    if (values.userCultureInfo !== account.UserCultureInfo) {
      setCulture(values.userCultureInfo)
    }
  }

  const selectedLanguage = getLanguageName(account.UserCultureInfo) as TranslationKeyOverride
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LanguageContainer>
        {Object.values(translations).map(({ culture, name }) => (
          <CrowRadioButton
            {...register('userCultureInfo')}
            title={t(name)}
            defaultChecked={name === selectedLanguage}
            key={culture}
            value={culture}
            id={name}
          />
        ))}
        <Button size="l" level="secondary" type="submit" onClick={() => closeDrawer()}>
          {t('Save')}
        </Button>
      </LanguageContainer>
    </form>
  )
}

const LanguageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  & > *:last-child {
    margin-top: 10px;
    align-self: flex-end;
  }
`

export { LanguageContent }
