import {
  AnnexIcon,
  AtticIcon,
  BackGardenIcon,
  BalconyIcon,
  BarIcon,
  BathroomIcon,
  BedroomHallwayIcon,
  BedroomIcon,
  BikeShedIcon,
  BoilerRoomIcon,
  CellarIcon,
  ConservatoryIcon,
  DiningAreaIcon,
  DiningRoomIcon,
  DressingRoomIcon,
  EmployeeLoungeIcon,
  EntranceIcon,
  FoodStorageIcon,
  FrontGardenIcon,
  GaragePlaceIcon,
  GardenRoomIcon,
  GuestBedroomIcon,
  GunRoomIcon,
  GymIcon,
  HallwayIcon,
  ItServerRoomIcon,
  KidsRoomIcon,
  KitchenIcon,
  LaundryRoomIcon,
  LibraryIcon,
  LivingRoomIcon,
  MasterBedroomIcon,
  MeetingRoomIcon,
  OfficeIcon,
  PlayRoomIcon,
  PoolIcon,
  ReceptionIcon,
  RestaurantIcon,
  ShopIcon,
  ShowRoomIcon,
  StairsIcon,
  StorageRoomIcon,
  TerraceIcon,
  TheaterIcon,
  ToiletIcon,
  TVRoomIcon,
  UnknownIcon,
  WaitingRoomIcon,
  WardrobeIcon,
  WorkshopIcon,
} from '../../assets/icons/crow'

interface RoomSwitchIconProps {
  roomKey: string
}

const RoomSwitchIcon = ({ roomKey }: RoomSwitchIconProps) => {
  switch (roomKey) {
    case 'HALLWAY_PLACE':
      return <HallwayIcon />
    case 'CONSERVATORY_PLACE':
      return <ConservatoryIcon />
    case 'KITCHEN_PLACE':
      return <KitchenIcon />
    case 'ENTRANCE_PLACE':
      return <EntranceIcon />
    case 'DINING_ROOM_PLACE':
      return <DiningRoomIcon />
    case 'MASTER_BEDROOM_PLACE':
      return <MasterBedroomIcon />
    case 'BEDROOM_PLACE':
      return <BedroomIcon />
    case 'KIDS_ROOM_PLACE':
      return <KidsRoomIcon />
    case 'STAIRS_PLACE':
      return <StairsIcon />
    case 'CELLAR_PLACE':
    case 'BASEMENT_PLACE':
    case 'TECHNICAL_ROOM_PLACE':
    case 'WINE_CELLAR_PLACE':
      return <CellarIcon />
    case 'LIVING_ROOM_PLACE':
      return <LivingRoomIcon />
    case 'GUEST_BEDROOM_PLACE':
      return <GuestBedroomIcon />
    case 'TV_ROOM_PLACE':
      return <TVRoomIcon />
    case 'OFFICE_PLACE':
      return <OfficeIcon />
    case 'BATHROOM_PLACE':
      return <BathroomIcon />
    case 'TOILET_PLACE':
      return <ToiletIcon />
    case 'BAR_PLACE':
      return <BarIcon />
    case 'STORAGE_ROOM_PLACE':
      return <StorageRoomIcon />
    case 'HOME_THEATER_PLACE':
      return <TheaterIcon />
    case 'LIBRARY_PLACE':
      return <LibraryIcon />
    case 'GYM_PLACE':
      return <GymIcon />
    case 'LAUNDRY_ROOM_PLACE':
      return <LaundryRoomIcon />
    case 'PLAY_ROOM_PLACE':
      return <PlayRoomIcon />
    case 'ATTIC_PLACE':
      return <AtticIcon />
    case 'POOL_PLACE':
      return <PoolIcon />
    case 'DRESSING_ROOM_PLACE':
      return <DressingRoomIcon />
    case 'FOOD_STORAGE_PLACE':
      return <FoodStorageIcon />
    case 'BOILER_HOUSE_PLACE':
      return <BoilerRoomIcon />
    case 'GUN_SAFE_PLACE':
      return <GunRoomIcon />
    case 'CRAFTS_ROOM_PLACE':
      return <WorkshopIcon />
    case 'WALK_IN_CLOSET_PLACE':
      return <DressingRoomIcon />
    case 'BIKE_SHED_PLACE':
      return <BikeShedIcon />
    case 'GUEST_HOUSE_PLACE':
    case 'RENTAL_PLACE':
      return <AnnexIcon />
    case 'GARAGE_PLACE':
      return <GaragePlaceIcon />
    case 'INDOOR_GARAGE_PLACE':
      return <GaragePlaceIcon />
    case 'GARDEN_ROOM_PLACE':
      return <GardenRoomIcon />
    case 'RECEPTION_PLACE':
      return <ReceptionIcon />
    case 'SHOP_PLACE':
      return <ShopIcon />
    case 'RESTAURANT_PLACE':
      return <RestaurantIcon />
    case 'EMPLOYEE_LOUNGE_PLACE':
      return <EmployeeLoungeIcon />
    case 'DINING_AREA_PLACE':
      return <DiningAreaIcon />
    case 'MEETING_ROOM_PLACE':
      return <MeetingRoomIcon />
    case 'IT_SERVER_ROOM_PLACE':
      return <ItServerRoomIcon />
    case 'WARDROBE_PLACE':
      return <WardrobeIcon />
    case 'WAITING_ROOM_PLACE':
      return <WaitingRoomIcon />
    case 'SHOWROOM_PLACE':
      return <ShowRoomIcon />
    case 'BEDROOM_HALLWAY_PLACE':
      return <BedroomHallwayIcon />
    case 'BALCONY_PLACE':
      return <BalconyIcon />
    case 'TERRACE_PLACE':
      return <TerraceIcon />
    case 'FRONT_GARDEN_PLACE':
      return <FrontGardenIcon />
    case 'BACK_GARDEN_PLACE':
      return <BackGardenIcon />
    case 'UNKNOWN':
      return <UnknownIcon />
    default:
      return <UnknownIcon />
  }
}

export { RoomSwitchIcon }
