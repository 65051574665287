import React, { FC } from 'react'
import BackgroundImagePage from '../../../components/pages/BackgroundImagePage'
import OnboardingFullscreenPage from '../../../components/pages/OnboardingFullscreenPage'
import { OnboardingWizardStep } from '../constants'
import OnboardingWizardFooter from './OnboardingWizardFooter'

type Props = {
  activeStep: OnboardingWizardStep
  isLoading?: boolean
  backgroundImage?: boolean
}

const OnboardingWizardSubPage: FC<Props> = ({
  activeStep,
  isLoading,
  children,
  backgroundImage,
}) => {
  const Page = backgroundImage ? BackgroundImagePage : OnboardingFullscreenPage

  return (
    <Page isLoading={isLoading} footer={<OnboardingWizardFooter activeStep={activeStep} />}>
      {children}
    </Page>
  )
}

export default OnboardingWizardSubPage
