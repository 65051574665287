import { ComponentType } from 'react'
import {
  AppCustomerSupportIcon,
  HomeIcon,
  InvoicesIcon,
  LocationIcon,
  PanelUsersIcon,
  TechSupportIcon,
  UsersIcon,
} from '../../../../assets/icons'
import { TinyArmedIcon } from '../../../../assets/icons/crow/TinyArmedIcon'
import { displayNewSettings } from '../../../../configs'
import { TranslationKey } from '../../../../types/generated/TranslationKey'
import { Capability, PanelAccess, PanelModel } from '../../../../types/PanelModel'
import { UserDetails, UserFeature } from '../../../../types/UserDetails'

export type MenuItemMap = {
  [key: string]: {
    accessKey?: PanelAccess
    visibleCondition: (
      panelInfo: Partial<PanelModel>,
      userInfo: UserDetails,
      _: boolean,
    ) => boolean | undefined
    svg: ComponentType
    title: TranslationKey
    newTitle?: TranslationKey
    irishTitle?: TranslationKey
    description: TranslationKey
    requiresCRM?: boolean
  }
}

export const userMenuItems: MenuItemMap = {
  'panel-users': {
    accessKey: PanelAccess.PanelUsers,
    visibleCondition: ({ SupportsPanelUsers }) => SupportsPanelUsers,
    svg: PanelUsersIcon,
    title: 'Users and codes',
    description: 'Add and remove users',
  },
  contacts: {
    accessKey: PanelAccess.ContactPersons,
    visibleCondition: (Panel) => Panel.InterviewDisplayStatus && !Panel.DisplayWizard,
    svg: UsersIcon,
    title: 'Contact persons',
    irishTitle: 'PW_CONTACT_PERSONS',
    description: 'Add persons to contact i case of emergency.',
  },
  'app-users': {
    accessKey: PanelAccess.AppUserSettings,
    visibleCondition: (Panel) => !!Panel.Access,
    svg: UsersIcon,
    title: 'APP_USERS_SETTING_MENU',
    description: 'APP_USERS_SETTING_MENU_INFO_TEXT',
  },
}
export const panelMenuItems: MenuItemMap = {
  'system-settings': {
    accessKey: PanelAccess.AlarmSystemSettings,
    visibleCondition: (Panel) => !!Panel.Access,
    svg: HomeIcon,
    title: 'System',
    newTitle: 'change_system_name',
    description: 'Change system name and more',
  },
  notifications: {
    visibleCondition: () => true,
    svg: TechSupportIcon,
    title: 'NOTIFICATIONS',
    newTitle: 'NOTIFICATIONS_SETTINGS',
    description: 'NOTIFICATIONS_INFO_TEXT',
  },

  'security-question': {
    accessKey: PanelAccess.SecurityQuestion,
    visibleCondition: (Panel) => Panel.InterviewDisplayStatus && !Panel.DisplayWizard,
    svg: HomeIcon,
    title: 'Security question',
    description: 'Change your security password.',
  },
  'quick-arming': {
    visibleCondition: (Panel) =>
      displayNewSettings &&
      Panel.QuickArmEnabled &&
      !Panel.Capabilities?.includes(Capability.USE_LEGACY_HOMESCREEN),
    title: 'SETTINGS_QUICK_ARMING',
    description: '',
    svg: TinyArmedIcon,
  },
  wizard: {
    accessKey: PanelAccess.PreInstallationSettings,
    visibleCondition: (Panel) => Panel.DisplayWizard,
    svg: AppCustomerSupportIcon,
    title: 'New features',
    newTitle: 'New system onboarding',
    description: 'Access new features.',
  },
  directions: {
    accessKey: PanelAccess.Directions,
    visibleCondition: (Panel) => Panel.InterviewDisplayStatus && !Panel.DisplayWizard,
    svg: LocationIcon,
    title: 'Location settings',
    description: 'Add a description about where you property is.',
  },
  consent: {
    visibleCondition: (Panel, user) =>
      Panel.HasVideo &&
      user.Features.includes(UserFeature.ARC_CONSENT) &&
      !Panel.Capabilities?.includes(Capability.USE_LEGACY_HOMESCREEN),
    svg: InvoicesIcon,
    title: 'CONSENT_TITLE',
    newTitle: 'video_consent_modal_title',
    description: 'CONSENT_SHORT_DESCRIPTION',
  },
}
export const deviceMenuItems: MenuItemMap = {
  smartplug: {
    accessKey: PanelAccess.AddProducts,
    visibleCondition: (Panel) => Panel.CanAddSmartplug,
    svg: AppCustomerSupportIcon,
    title: 'SETTINGS_ADD_SMART_PLUG',
    description: 'SETTINGS_ADD_SMART_PLUG_INFOTEXT',
  },
  wifi: {
    accessKey: PanelAccess.Wifi,
    visibleCondition: (Panel) =>
      !!Panel.Access &&
      !!Panel.Wifi
        ?.WifiExist /* &&
      !!Panel.Capabilities?.includes(Capability.USE_LEGACY_HOMESCREEN), */,
    svg: TechSupportIcon,
    title: 'WIFI_SETTINGS',
    description: 'WIFI_INFO_TEXT',
  },
}
