const BikeShedIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 3.79168C3 3.10671 3.6731 2.6245 4.32167 2.84483L28.3217 10.9981C28.7272 11.1359 29 11.5167 29 11.945V38C29 38.5523 28.5523 39 28 39H4C3.44772 39 3 38.5523 3 38V3.79168Z"
        fill="#E3E1E1"
      />
      <path
        d="M3 3.7917C3 3.10673 3.6731 2.62452 4.32167 2.84485L28.3217 10.9982C28.7272 11.1359 29 11.5167 29 11.945V38C29 38.5523 28.5523 39 28 39H4C3.44772 39 3 38.5523 3 38V3.7917Z"
        fill="#E3E1E1"
        stroke="#333131"
        stroke-width="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8671 26.5019C26.6888 26.1914 26.3581 26 26 26H21C20.2043 26 19.4413 26.3161 18.8787 26.8787C18.3161 27.4413 18 28.2043 18 29C18 29.5523 18.4477 30 19 30C19.5523 30 20 29.5523 20 29C20 28.7348 20.1054 28.4804 20.2929 28.2929C20.4804 28.1054 20.7348 28 21 28H24.259L21.118 33.3845C20.4594 33.136 19.7456 33 19 33C15.6863 33 13 35.6863 13 39C13 42.3137 15.6863 45 19 45C22.3137 45 25 42.3137 25 39C25 37.1492 24.162 35.4942 22.8448 34.3936L24.241 32H31.4256L27.6362 38.4961C27.3579 38.9732 27.5191 39.5855 27.9961 39.8638C28.4732 40.1421 29.0855 39.9809 29.3638 39.5039L33.2597 32.8251L34.7063 34.809C33.6506 35.8905 33 37.3693 33 39C33 42.3137 35.6863 45 39 45C42.3137 45 45 42.3137 45 39C45 35.6863 42.3137 33 39 33C38.0374 33 37.1278 33.2267 36.3216 33.6295L34.3598 30.9392L36.0744 28H39C39.5523 28 40 27.5523 40 27C40 26.4477 39.5523 26 39 26H35.5C35.1443 26 34.8154 26.1889 34.6362 26.4961L32.5923 30H25.4077L26.8638 27.5039C27.0442 27.1946 27.0455 26.8124 26.8671 26.5019ZM37.5254 35.2806C37.9817 35.0995 38.4792 35 39 35C41.2091 35 43 36.7909 43 39C43 41.2091 41.2091 43 39 43C36.7909 43 35 41.2091 35 39C35 38.0354 35.3414 37.1506 35.91 36.4597L38.1919 39.5892C38.5173 40.0354 39.1429 40.1334 39.5891 39.808C40.0354 39.4826 40.1333 38.8571 39.8079 38.4108L37.5254 35.2806ZM19.8638 39.5039L21.8153 36.1585C22.5469 36.8833 23 37.8888 23 39C23 41.2091 21.2091 43 19 43C16.7909 43 15 41.2091 15 39C15 36.7909 16.7909 35 19 35C19.3772 35 19.7422 35.0522 20.0882 35.1498L18.1362 38.4961C17.8579 38.9732 18.0191 39.5855 18.4961 39.8638C18.9732 40.1421 19.5855 39.9809 19.8638 39.5039Z"
        fill="#333131"
      />
    </svg>
  )
}

export { BikeShedIcon }
