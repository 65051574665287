import classNames from 'classnames'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import useI18n from '../../i18n/useI18n'

type Props = {
  backUrl: string
  onClick?: () => void
}

const BackButton: FC<Props> = ({ backUrl, onClick }) => {
  const { t } = useI18n()
  return (
    <Link
      className={classNames(`fullscreen__back no-underline`, `qa-back-btn`)}
      to={backUrl}
      onClick={onClick}
    >
      {t('Back')}
    </Link>
  )
}

export default BackButton
