import * as React from 'react'

const MobileIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const fill: string = props.fill || '#4D4A4A'

  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 12C4.27614 12 4.5 11.7761 4.5 11.5C4.5 11.2239 4.27614 11 4 11C3.72386 11 3.5 11.2239 3.5 11.5C3.5 11.7761 3.72386 12 4 12Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.5C8 0.671573 7.32843 0 6.5 0H1.5C0.671573 0 0 0.671573 0 1.5V12.5C0 13.3284 0.671573 14 1.5 14H6.5C7.32843 14 8 13.3284 8 12.5V1.5ZM6.5 1C6.77614 1 7 1.22386 7 1.5V12.5C7 12.7761 6.77614 13 6.5 13H1.5C1.22386 13 1 12.7761 1 12.5V1.5C1 1.22386 1.22386 1 1.5 1H6.5Z"
        fill={fill}
      />
    </svg>
  )
}

export { MobileIcon }
