import React from 'react'
import styled from 'styled-components'

import { colors } from '../../../components/Crow/Style/theme'
import { Body, Heading3 } from '../../../components/Crow/Typography'
import useI18n from '../../../i18n/useI18n'
import { HistoryIcon } from '../../../assets/icons'
import { ErrorIcon } from '../../../assets/icons/crow'
import { PanelEvent } from '../../../types/PanelEvent'

interface EmptyEventsType {
  panelEvents: [string, PanelEvent[]][] | undefined
  panelEventsError: string
}

const EventsHasErrorOrEmpty = ({ panelEvents, panelEventsError }: EmptyEventsType) => {
  const { t } = useI18n()

  if (!!panelEventsError) {
    return (
      <EmptyEventsContainer>
        <ErrorIcon color={colors.red} />
        <Title>{t('Something went wrong. Please try again')}</Title>
        <Description>{t('history no events')}</Description>
      </EmptyEventsContainer>
    )
  } else if (panelEvents?.length === 0) {
    return (
      <EmptyEventsContainer>
        <HistoryIcon color={colors.blue} />
        <Title>{t('Nothing here... yet!')}</Title>
        <Description>{t('history no events')}</Description>
      </EmptyEventsContainer>
    )
  }
  return null
}

const EmptyEventsContainer = styled.div`
  max-width: 610px;
  max-height: 240px;
  border-radius: 12px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 32px;
`

const Title = styled(Heading3)`
  margin-top: 1.5rem;
`

const Description = styled(Body)`
  margin-top: 1rem;
  text-align: center;
`

export default EventsHasErrorOrEmpty
