import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { CancelIcon } from '../../../assets/icons/crow'
import BackButton from '../../../components/BackButton'
import Drawer from '../../../components/Crow/Drawer/Drawer'
import { colors } from '../../../components/Crow/Style/theme'
import Toast, { addToast } from '../../../components/Crow/Toast/Toast'
import { Title, Heading3 } from '../../../components/Crow/Typography'
import FullscreenPage from '../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import { displayNewNavigationMenu } from '../../../configs'
import useI18n from '../../../i18n/useI18n'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import {
  useGetSmartplugsStatus,
  useTurnOffSmartplug,
  useTurnOnSmartplug,
} from '../../../services/requestHooks/plugs'
import { useGetPanelPermissions } from '../../../services/requestHooks/users'
import { SmartPlug } from '../../../types/SmartPlug'
import { useOnMount } from '../../../utils/commonHooks'
import { logBackEvent, logSmartplugEvent } from '../../../utils/firebaseAnalytics'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import { CrowSmartplugItem } from './CrowSmartplugItem'
import SmartPlugsSettings from './SmartPlugsSettings'

const CrowSmartPlugPage: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const returnUrl = `/systems/${panelId}`

  const [currentPlug, setCurrentPlug] = useState<string | undefined>()
  const [smartPlugsState, setSmartPlugsState] = useState<SmartPlug>()
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false)

  const { run: turnOn, isLoading: isTurningOn, error: turnOnError } = useTurnOnSmartplug({
    onSuccess: ({ response }: any) => {
      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: t('toast_smart_plug_turn_on_success'),
      })
    },
  })
  const { run: turnOff, isLoading: isTurningOff, error: turnOffError } = useTurnOffSmartplug({
    onSuccess: ({ response }: any) => {
      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: t('toast_smart_plug_turn_off_success'),
      })
    },
  })

  const isToggling = isTurningOff || isTurningOn

  const {
    run: loadPlugs,
    data: plugsWithStatus,
    isLoading: loadingPlugs,
    error: plugsError,
  } = useGetSmartplugsStatus()

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
    error: panelStatusError,
  } = usePanelStatus()

  const {
    run: getPanelPermissions,
    isLoading: loadingPanelPermissions,
    data: panelPermissions,
  } = useGetPanelPermissions()

  const { data: panelInfo } = usePanelInfo()

  const togglePlug = (plug: SmartPlug) => async (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    event.stopPropagation()
    const command = plug.Status === 'On' ? turnOff : turnOn
    setCurrentPlug(plug.SerialNo)
    logSmartplugEvent(plug.Status === 'On' ? 'OFF' : 'ON', 'SAS')
    command({
      panelId,
      Id: plug.Id,
    })
  }

  const plugs = plugsWithStatus || panelInfo?.Smartplugs || []
  const indoorPlugs = plugs.filter((plug) => plug.Manufacturer !== 'Outdoor SmartPlug')
  const outdoorPlugs = plugs.filter((plug) => plug.Manufacturer === 'Outdoor SmartPlug')
  const showOutdoorSectionTitle = outdoorPlugs.length > 0
  const showIndoorSectionTitle = showOutdoorSectionTitle && indoorPlugs.length > 0

  useOnMount(() => {
    if (!loadingPlugs) {
      loadPlugs(panelId)
    }
    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
    if (!loadingPanelPermissions) {
      getPanelPermissions()
    }
  })

  useEffect(() => {
    if (turnOnError) {
      addToast({
        type: 'error',
        time: 5000,
        title: t('error_toast_generic_title'),
        content: t('toast_smart_plug_turn_on_error'),
      })
    }
  }, [turnOnError, t])

  useEffect(() => {
    if (turnOffError) {
      addToast({
        type: 'error',
        time: 5000,
        title: t('error_toast_generic_title'),
        content: t('toast_smart_plug_turn_off_error'),
      })
    }
  }, [turnOffError, t])

  const openDrawer = (smartPlug: SmartPlug) => () => {
    setSmartPlugsState({
      ...smartPlug,
    })

    if (!panelStatus?.IsOnline) {
      return
    }

    setDrawerIsOpen(true)
  }

  const closeDrawer = () => {
    setDrawerIsOpen(false)
  }

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen footer>
      <FullscreenPage
        isLoading={loadingPlugs}
        loaderProps={{
          loadingText: { text: t('Loading') },
          errorHandling: {
            loadingFailed: !!plugsError || !!panelStatusError,
            errorHeaderText: t('smartplugs'),
            errorText: t('Could not load smartplug status'),
          },
        }}
      >
        <Toast />
        {!displayNewNavigationMenu && (
          <BackButton backUrl={returnUrl} onClick={() => logBackEvent('smartplugs')} />
        )}
        <Title large>{t('Smart plugs')}</Title>
        <Description>{t('SMARTPLUGS_DESCRIPTION')}</Description>
        {showIndoorSectionTitle && (
          <SectionTitle color={colors.black800}>{t('INDOOR')}</SectionTitle>
        )}
        <SmartplugList>
          {indoorPlugs.map((plug) => {
            const enabled = plug.Status === 'On'
            const isDisabled =
              plug.Status === 'Disconnected' ||
              plug.Status === 'Unreachable' ||
              plug.Status === 'unknown'
            return (
              <CrowSmartplugItem
                key={plug.SerialNo}
                id={plug.SerialNo}
                text={plug.Label}
                timeDetails={{
                  status: plug.Status,
                  formatDate: plug.LastChanged,
                  panelStatus: panelStatus?.Status,
                  TimeZoneName: panelStatus?.TimeZoneName,
                }}
                checked={enabled}
                onClick={openDrawer(plug)}
                onToggle={togglePlug(plug)}
                isLoading={(isToggling && currentPlug === plug.SerialNo) || loadingPlugs}
                disabled={isDisabled}
                className={`qa-${plug.Label}`}
              />
            )
          })}
          <Drawer
            isOpen={drawerIsOpen}
            position="right"
            handleClose={closeDrawer}
            closeButton={true}
            closeIcon={<CancelIcon color={colors.blue} />}
            title={<S.Header>{smartPlugsState?.Label}</S.Header>}
          >
            <SmartPlugsSettings
              smartPlug={smartPlugsState!}
              closeDrawer={closeDrawer}
              panelPermissions={panelPermissions}
            />
          </Drawer>
        </SmartplugList>
        {showOutdoorSectionTitle && (
          <SectionTitle color={colors.black800}>{t('OUTDOOR')}</SectionTitle>
        )}
        <SmartplugList>
          {outdoorPlugs.map((plug) => {
            const enabled = plug.Status === 'On'
            const isDisabled =
              plug.Status === 'Disconnected' ||
              plug.Status === 'Unreachable' ||
              plug.Status === 'unknown'
            return (
              <CrowSmartplugItem
                key={plug.SerialNo}
                id={plug.SerialNo}
                text={plug.Label}
                timeDetails={{
                  status: plug.Status,
                  formatDate: plug.LastChanged,
                  panelStatus: panelStatus?.Status,
                  TimeZoneName: panelStatus?.TimeZoneName,
                }}
                checked={enabled}
                onClick={openDrawer(plug)}
                onToggle={togglePlug(plug)}
                isLoading={(isToggling && currentPlug === plug.SerialNo) || loadingPlugs}
                disabled={isDisabled}
                className={`qa-${plug.Label}`}
                isOutdoor
              />
            )
          })}
        </SmartplugList>
      </FullscreenPage>
    </TcOrCrowContainer>
  )
}

const Description = styled.p`
  margin: 16px 0 32px;
`

const SectionTitle = styled(Heading3)`
  margin-left: 16px;
  margin-top: 38px;
`

const SmartplugList = styled.div`
  margin-top: 10px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media only screen and (max-width: 600px) {
    gap: 15px;
  }
`

const S = {
  Header: styled.div`
    padding-bottom: 50px;
  `,
  CloseButton: styled.button`
    margin-right: 28px;
  `,
  SoundSettings: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `,
}

export default CrowSmartPlugPage
