const DoorUnlockedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 2V18H17V16.5C17 15.9477 17.4477 15.5 18 15.5C18.5523 15.5 19 15.9477 19 16.5V18H21.125C21.6773 18 22.125 18.4477 22.125 19C22.125 19.5523 21.6773 20 21.125 20H1C0.447715 20 0 19.5523 0 19C0 18.4477 0.447715 18 1 18H3V1.75C3 1.29779 3.17263 0.856864 3.49121 0.526028C3.81103 0.19391 4.25302 0 4.72222 0L17.2778 0C17.747 0 18.189 0.193909 18.5088 0.526028C18.8274 0.856865 19 1.29779 19 1.75V3C19 3.55228 18.5523 4 18 4C17.4477 4 17 3.55228 17 3V2H5Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M11 11C11.5523 11 12 10.5523 12 10C12 9.44771 11.5523 9 11 9C10.4477 9 10 9.44771 10 10C10 10.5523 10.4477 11 11 11Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0546 7.71766C16.048 7.74259 16.0423 7.76765 16.0374 7.79281C16.0242 7.86137 16.0176 7.93066 16.0176 8H21C21.5523 8 22 8.44772 22 9V14C22 14.5523 21.5523 15 21 15H14C13.4477 15 13 14.5523 13 14V9C13 8.44772 13.4477 8 14 8C14 7.86541 14.0106 7.73088 14.0317 7.59733C14.0457 7.50883 14.0643 7.42077 14.0875 7.3334C14.0927 7.31392 14.0981 7.29449 14.1037 7.27512C14.1106 7.25155 14.1177 7.22807 14.1252 7.20467C14.2332 6.86816 14.4089 6.55003 14.6449 6.26479C14.7038 6.19355 14.7662 6.12468 14.8319 6.05833C15.0627 5.82536 15.3341 5.62358 15.6369 5.46035C15.7103 5.42077 15.7854 5.38361 15.8618 5.34891C16.1903 5.19976 16.545 5.09619 16.9126 5.04255C16.9419 5.03827 16.9713 5.03432 17.0007 5.03068C17.387 4.98296 17.7798 4.99096 18.1628 5.05429C18.2012 5.06063 18.2395 5.06754 18.2777 5.075C18.3796 5.09491 18.4803 5.11869 18.5794 5.14623C18.8271 5.21508 19.0652 5.30741 19.2891 5.42154L19.2885 5.42246C19.6266 5.55599 19.8658 5.88571 19.8658 6.2713C19.8658 6.77515 19.4574 7.18359 18.9536 7.18359C18.726 7.18359 18.4648 7.02734 18.3581 6.96245L18.3574 6.96346C18.2308 6.88647 18.0909 6.82713 17.9432 6.78746C17.9057 6.7774 17.8678 6.76861 17.8294 6.76111C17.6396 6.72403 17.4431 6.71936 17.2512 6.74737C17.2218 6.75166 17.1926 6.75671 17.1636 6.7625C17.0034 6.79448 16.8504 6.84912 16.7109 6.92433C16.6358 6.9648 16.5653 7.01087 16.5001 7.0619C16.4222 7.12288 16.352 7.19095 16.2907 7.26505C16.2299 7.3385 16.1786 7.4171 16.1374 7.49946C16.1023 7.56974 16.0745 7.64276 16.0546 7.71766ZM15 13V10H20V13H15Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { DoorUnlockedIcon }
