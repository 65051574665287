import React from 'react'
import styled from 'styled-components'

import useI18n from '../../../i18n/useI18n'
import TextField from '../../Crow/Input/TextFieldForForm'

interface Props {
  register: any
  errors: any
  className?: string
}

const ContactInformation = ({ register, errors, className }: Props) => {
  const { t } = useI18n()

  return (
    <Wrapper className={className}>
      <TextField
        id="firstName"
        label={t('First name')}
        {...register('FirstName')}
        error={errors.FirstName?.message}
      />
      <TextField
        id="lastName"
        label={t('Last name')}
        {...register('LastName')}
        error={errors.LastName?.message}
      />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export default ContactInformation
