const PeopleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ? props.width : '24'}
      height={props.height ? props.height : '16'}
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8823 10.7429C13.2481 9.66711 14.125 7.99846 14.125 6.125C14.125 2.88033 11.4947 0.25 8.25 0.25C5.00533 0.25 2.375 2.88033 2.375 6.125C2.375 7.99846 3.25191 9.66711 4.61768 10.7429C4.40373 10.8342 4.19289 10.9338 3.98565 11.0414C2.66844 11.7255 1.53517 12.7166 0.681528 13.9308C0.363897 14.3826 0.472668 15.0064 0.924474 15.324C1.37628 15.6416 2.00003 15.5329 2.31766 15.0811C2.98677 14.1293 3.87505 13.3525 4.90751 12.8163C5.93997 12.28 7.0863 12 8.24971 12C8.24976 12 8.24966 12 8.24971 12C9.41308 12 10.5595 12.2799 11.592 12.816C12.6245 13.3522 13.5128 14.1289 14.182 15.0806C14.4997 15.5324 15.1234 15.6411 15.5752 15.3235C16.027 15.0058 16.1357 14.382 15.818 13.9302C14.9643 12.7161 13.831 11.7251 12.5137 11.0411C12.3067 10.9336 12.096 10.8341 11.8823 10.7429ZM4.375 6.125C4.375 3.9849 6.1099 2.25 8.25 2.25C10.3901 2.25 12.125 3.9849 12.125 6.125C12.125 8.2651 10.3901 10 8.25 10C8.24988 10 8.25012 10 8.25 10C6.11006 9.9998 4.375 8.26498 4.375 6.125Z"
        fill="currentColor"
      />
      <path
        d="M14.8413 2.39414C15.3743 2.24397 15.9332 2.20976 16.4806 2.29381C17.0279 2.37787 17.5509 2.57823 18.0142 2.88142C18.4776 3.1846 18.8706 3.58357 19.1668 4.05143C19.463 4.5193 19.6555 5.04521 19.7313 5.59373C19.8072 6.14226 19.7646 6.70067 19.6064 7.23135C19.4483 7.76203 19.1782 8.25265 18.8145 8.67018C18.4508 9.08771 18.0018 9.42244 17.4978 9.65183C16.997 9.87977 16.4534 9.99844 15.9033 10C15.8996 10 15.8958 10 15.8921 10C15.3398 10.0004 14.8924 10.4484 14.8928 11.0007C14.8931 11.3457 15.068 11.6497 15.3338 11.8293C15.4358 11.8982 15.5511 11.9487 15.6751 11.9762C15.7452 11.9918 15.8181 12 15.893 12C15.8971 12 15.9012 12 15.9053 12C17.0645 12.001 18.2067 12.2804 19.2356 12.8148C20.268 13.3509 21.156 14.1279 21.8244 15.08C22.1418 15.532 22.7654 15.6412 23.2174 15.3239C23.6695 15.0065 23.7786 14.3828 23.4613 13.9308C22.6082 12.7157 21.4749 11.7241 20.1574 11.0399C19.9503 10.9323 19.7397 10.8329 19.5259 10.7417C19.8138 10.5152 20.0806 10.2616 20.3225 9.98391C20.8739 9.35092 21.2833 8.6071 21.5231 7.80256C21.7629 6.99803 21.8275 6.15145 21.7125 5.31985C21.5975 4.48826 21.3057 3.69095 20.8566 2.98164C20.4076 2.27233 19.8118 1.66748 19.1093 1.20783C18.4068 0.748186 17.6139 0.444418 16.7842 0.316987C15.9544 0.189555 15.1069 0.241419 14.2989 0.469086C13.7673 0.61886 13.4578 1.17121 13.6076 1.7028C13.7573 2.23439 14.3097 2.54391 14.8413 2.39414Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { PeopleIcon }
