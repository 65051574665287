const TheaterIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40 10H8V18H10C11.1046 18 12 18.8954 12 20V31H36V20C36 18.8954 36.8954 18 38 18H40V10Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5 19.6273C29.1667 20.0152 29.1667 20.9848 28.5 21.3727L22.5 24.8635C21.8333 25.2514 21 24.7665 21 23.9908V17.0092C21 16.2335 21.8333 15.7486 22.5 16.1365L28.5 19.6273ZM23 18.7546L26 20.5L23 22.2454V18.7546Z"
        fill="#333131"
      />
      <path
        d="M7 31C7.55228 31 8 30.5523 8 30C8 29.4477 7.55228 29 7 29C6.44772 29 6 29.4477 6 30C6 30.5523 6.44772 31 7 31Z"
        fill="#333131"
      />
      <path
        d="M7 36C8.10457 36 9 35.1046 9 34C9 32.8954 8.10457 32 7 32C5.89543 32 5 32.8954 5 34C5 35.1046 5.89543 36 7 36Z"
        fill="#333131"
      />
      <path
        d="M42 30C42 30.5523 41.5523 31 41 31C40.4477 31 40 30.5523 40 30C40 29.4477 40.4477 29 41 29C41.5523 29 42 29.4477 42 30Z"
        fill="#333131"
      />
      <path
        d="M41 36C42.1046 36 43 35.1046 43 34C43 32.8954 42.1046 32 41 32C39.8954 32 39 32.8954 39 34C39 35.1046 39.8954 36 41 36Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 18H42V10C42 8.89543 41.1046 8 40 8H8C6.89543 8 6 8.89543 6 10V18H4C2.89543 18 2 18.8954 2 20V37C2 38.1046 2.89543 39 4 39H10C11.1046 39 12 38.1046 12 37V33H36V37C36 38.1046 36.8954 39 38 39H44C45.1046 39 46 38.1046 46 37V20C46 18.8954 45.1046 18 44 18ZM8 10H40V18H38C36.8954 18 36 18.8954 36 20V31H12V20C12 18.8954 11.1046 18 10 18H8V10ZM10 20H4V37H10V20ZM38 20H44V37H38V20Z"
        fill="#333131"
      />
    </svg>
  )
}

export { TheaterIcon }
