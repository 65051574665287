import i18n from 'i18next'
import Backend from 'i18next-chained-backend'
import HttpApi from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import sprintf from 'i18next-sprintf-postprocessor'
import { initReactI18next } from 'react-i18next'
import { baseUrl } from '../configs'
import { UserCultureInfo } from '../types/UserDetails'
import { isRunningCypress } from '../utils/tests/isRunningCypress'
import { getCultureDataFromUrl } from './translations'

const preferredLangKey = 'preferred_lang'
export const setPreferredLang = (lang: UserCultureInfo) => {
  window.localStorage.setItem(preferredLangKey, lang)
}
export const getPreferredLang = () =>
  window.localStorage.getItem(preferredLangKey) as UserCultureInfo | null

const getLocalization = (): UserCultureInfo => {
  if (isRunningCypress()) {
    return 'cimode' as UserCultureInfo
  }
  let defaultLanguage = getPreferredLang()
  if (!defaultLanguage) {
    defaultLanguage = getCultureDataFromUrl()?.culture || UserCultureInfo.ENGLISH
    setPreferredLang(defaultLanguage)
  }
  return defaultLanguage
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(sprintf)
  .init({
    lng: getLocalization(),
    load: 'currentOnly',
    preload: [UserCultureInfo.ENGLISH],
    fallbackLng: UserCultureInfo.ENGLISH,
    keySeparator: false,
    overloadTranslationOptionHandler: sprintf.overloadTranslationOptionHandler,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [LocalStorageBackend, HttpApi],
      backendOptions: [
        { expirationTime: 60 * 60 * 24 * 7 },
        {
          crossDomain: true,
          loadPath: `${baseUrl}/account/GetTextByCultureAndPlatform2?culture={{lng}}&platform=web`,
          customHeaders: {
            'API-Version': 5,
          },
        },
      ],
    },
  })

export default i18n
