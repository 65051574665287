import CrowCard from '../../../../components/Crow/CrowCard/CrowCard'
import Toast from '../../../../components/Crow/Toast/Toast'
import { ModalPromptProps } from '../../../../components/Modal/Modal'
import Switch from '../../../../components/Switch'
import TermsLink from '../../../../components/TermsLink'
import TwoFactorPrompt from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { errorCodesTriggerNew2FA } from '../../../../utils/errorCodesTriggerNew2FA'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import SettingsSubPage from '../components/SettingsSubPage'
import RemoveVideoControlModal from './RemoveVideoControlModal'
import VideoControlModal from './VideoControlModal'

type Props = {
  panelId: string
  loadingPanelInfo: boolean
  panelInfoError: any
  company: 'Phonewatch ' | 'Sector Alarm '
  toggleConsent: () => Promise<void>
  toggle: boolean
  settingArcConsent: boolean
  arcConsentError: string | undefined
  videoControlState: ModalPromptProps<true>
  removeVideoControlState: ModalPromptProps<true>
  twoFactorPromptState: {
    onSubmit: ((validationCode: string) => void) | undefined
    onCancel: (() => void) | undefined
  }
}

const OldConsentPage = ({
  panelId,
  loadingPanelInfo,
  panelInfoError,
  company,
  toggleConsent,
  toggle,
  settingArcConsent,
  arcConsentError,
  videoControlState,
  removeVideoControlState,
  twoFactorPromptState,
}: Props) => {
  const { t } = useI18n()

  return (
    <SettingsSubPage
      returnUrl={`/systems/${panelId}/settings`}
      isLoading={loadingPanelInfo}
      loaderProps={{
        loadingText: { text: 'Loading' },
        errorHandling: {
          loadingFailed: !!panelInfoError,
          errorText: 'Something went wrong. Please try again',
          returnUrl: `/systems/${panelId}/settings`,
          errorHeaderText: 'System settings',
        },
      }}
    >
      <h1>{t('CONSENT_TITLE')}</h1>
      <p className="mt-2">{t('CONSENT_DESCRIPTION')}</p>
      <CrowCard className=" mt-6 flex justify-between items-center">
        <div>
          <h2>{t('CONSENT_VIDEO_TITLE')}</h2>
          <p>{t('WEB_CONSENT_VIDEO_DESCRIPTION', { company })}</p>
        </div>
        <Switch
          testId="toggle-consent"
          onClick={toggleConsent}
          checked={toggle === true && true}
          isLoading={settingArcConsent}
          hideText
        />
      </CrowCard>
      {!!arcConsentError &&
        !errorCodesTriggerNew2FA.includes(arcConsentError.toLocaleLowerCase()) && (
          <FailedRequest text={arcConsentError} />
        )}
      <CrowCard className="mt-2 flex justify-between items-center">
        <div>
          <h2>{t('CONSENT_TERMS_TITLE')}</h2>
          <p>{t('CONSENT_TERMS_DESCRIPTION')}</p>
        </div>
        <TermsLink>{t('OPEN')}</TermsLink>
      </CrowCard>
      <VideoControlModal {...videoControlState} />
      <RemoveVideoControlModal {...removeVideoControlState} />
      <TwoFactorPrompt promptState={twoFactorPromptState} />
      <Toast />
    </SettingsSubPage>
  )
}

export default OldConsentPage
