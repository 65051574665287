const MiniAppsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ? props.width : '20'}
      height={props.height ? props.height : '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0C0.447715 0 0 0.447715 0 1V7C0 7.55228 0.447715 8 1 8H7C7.55228 8 8 7.55228 8 7V1C8 0.447715 7.55228 0 7 0H1ZM2 6V2H6V6H2Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0C10.4477 0 10 0.447715 10 1V7C10 7.55228 10.4477 8 11 8H17C17.5523 8 18 7.55228 18 7V1C18 0.447715 17.5523 0 17 0H11ZM12 6V2H16V6H12Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11C0 10.4477 0.447715 10 1 10H7C7.55228 10 8 10.4477 8 11V17C8 17.5523 7.55228 18 7 18H1C0.447715 18 0 17.5523 0 17V11ZM2 12V16H6V12H2Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 10C10.4477 10 10 10.4477 10 11V17C10 17.5523 10.4477 18 11 18H17C17.5523 18 18 17.5523 18 17V11C18 10.4477 17.5523 10 17 10H11ZM12 16V12H16V16H12Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { MiniAppsIcon }
