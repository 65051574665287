import React from 'react'
import { CancelIcon } from '../../../../assets/icons/crow/CancelIcon'
import Drawer from '../../../../components/Crow/Drawer/Drawer'
import { colors } from '../../../../components/Crow/Style/theme'
import Form from '../../../../components/forms/AddContactForm'
import useI18n from '../../../../i18n/useI18n'
import { ContactPerson } from '../../../../types/ContactPerson'

interface Props {
  isOpen: boolean
  handleClose: () => void
  addContact: (newContact: ContactPerson) => void
  isAdding: boolean
}

const AddContactDrawer = ({ isOpen, handleClose, addContact, isAdding }: Props) => {
  const { t } = useI18n()

  return (
    <Drawer
      isOpen={isOpen}
      position="right"
      title={t('ADD_CONTACT')}
      handleClose={handleClose}
      closeButton={true}
      closeIcon={<CancelIcon color={colors.blue} />}
    >
      <Form onSubmit={addContact} cancelAction={handleClose} isLoading={isAdding} />
    </Drawer>
  )
}

export default AddContactDrawer
