import * as React from 'react'

const DownloadIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 0C6.27614 0 6.5 0.223858 6.5 0.5V6.29316L8.27149 4.52213C8.46678 4.3269 8.78336 4.32694 8.9786 4.52223C9.17384 4.71751 9.17379 5.0341 8.97851 5.22933L6.35351 7.85364C6.15826 8.04885 5.84174 8.04885 5.64649 7.85364L3.02149 5.22933C2.82621 5.0341 2.82616 4.71751 3.0214 4.52223C3.21664 4.32694 3.53322 4.3269 3.72851 4.52213L5.5 6.29316V0.5C5.5 0.223858 5.72386 0 6 0Z"
        fill={props.color ? props.color : '#4D4A4A'}
      />
      <path
        d="M1 7.5C1 7.22386 0.776142 7 0.5 7C0.223858 7 0 7.22386 0 7.5V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H11C11.2652 12 11.5196 11.8946 11.7071 11.7071C11.8946 11.5196 12 11.2652 12 11V7.5C12 7.22386 11.7761 7 11.5 7C11.2239 7 11 7.22386 11 7.5V11H1V7.5Z"
        fill={props.color ? props.color : '#4D4A4A'}
      />
    </svg>
  )
}

export { DownloadIcon }
