import styled from 'styled-components'
import { colors } from '../../components/Crow/Style/theme'
import { InvoiceStatus } from '../../types/Invoices'

interface StatusProps {
  status: InvoiceStatus
}

const getTextColor = (status: InvoiceStatus) => {
  switch (status) {
    case 'PAID':
      return colors.darkerGray
    case 'UNPAID':
      return colors.black900
    case 'OVERDUE':
      return colors.white
    case 'ENDED':
      return colors.darkerGray
    case 'UNKNOWN':
      return colors.black900
    default:
      return colors.black900
  }
}

const getBackgroundColor = (status: InvoiceStatus) => {
  switch (status) {
    case 'PAID':
      return colors.green100
    case 'UNPAID':
      return colors.yellow100
    case 'OVERDUE':
      return colors.red
    case 'ENDED':
      return colors.green100
    case 'UNKNOWN':
      return colors.black300
    default:
      return colors.black300
  }
}

export const InvoiceStatusLabel = ({ status }: StatusProps) => {
  return (
    <StyledLabel textColor={getTextColor(status)} backgroundColor={getBackgroundColor(status)}>
      {status}
    </StyledLabel>
  )
}

interface LabelProps {
  textColor: string
  backgroundColor: string
}

const StyledLabel = styled.span<LabelProps>`
  padding: 1px 8px 1px;
  font-weight: bolder;
  border-radius: 20px;
  color: ${({ textColor }) => textColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
`
