import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import useI18n from '../../../../i18n/useI18n'
import Button from '../../../../components/Crow/Button/Button'
import TextField from '../../../../components/Crow/Input/TextFieldForForm'
import { colors } from '../../../../components/Crow/Style/theme'
import { Heading3, Body } from '../../../../components/Crow/Typography/Typography'
import { Loader } from '../../../../components/Elements/Loaders'
import { useSetSecurityQuestion } from '../../../../services/requestHooks/securityQuestion'
import { usePanelId } from '../../hooks/usePanelId'
import TwoFactorPrompt, {
  useTwoFactorPrompt,
} from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import PasswordPrompt, { usePasswordPrompt } from '../../Panel/PasswordPromt'
import { addToast } from '../../../../components/Crow/Toast/Toast'
import Overlay from '../../../../components/Crow/Overlay'
import { CheckmarkIconLarge } from '../../../../assets/icons/crow'

type FormValues = {
  alarmCenterCodeword: string
  customerCodeword: string
}

const SecurityQuestionForm = () => {
  const { t } = useI18n()
  const PanelId = usePanelId()
  const [showOverlay, setShowOverlay] = useState<boolean>(false)
  const { promptState: twoFactorPromptState, promptForTwoFactor } = useTwoFactorPrompt()
  const { promptState, promptForPassword } = usePasswordPrompt()
  const { run: setQuestion, isLoading } = useSetSecurityQuestion({
    onSuccess: () => {
      setShowOverlay(true)
    },
    onFailure: () => {
      addToast({
        type: 'error',
        time: 5000,
        title: t('error_toast_generic_title'),
        content: 'error',
      })
    },
  })

  const baseValidator = yup
    .string()
    .required(t('Required'))
    .min(2, t('At least 2 characters is required'))
    .max(30, t('SECURITY_QUESTION_TEXT_MAX_30'))
    .matches(
      /^[\x20-\x7E\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178]+$/,
      t('SQ_VALIDATION_ERROR'),
    )

  const resolver = yup.object().shape({
    alarmCenterCodeword: baseValidator,
    customerCodeword: baseValidator.test('equal', t('The code words can not be equal'), function(
      value,
      context,
    ) {
      return value?.toLowerCase() !== context.parent.alarmCenterCodeword?.toLowerCase()
    }),
  })

  const defaultValues = {
    alarmCenterCodeword: '',
    customerCodeword: '',
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(resolver),
  })

  const onSubmit = async (formValues: FormValues) => {
    const Password = await promptForPassword()
    if (Password) {
      const validationCode = await promptForTwoFactor()
      if (validationCode) {
        setQuestion({ ...formValues, PanelId, Password, validationCode })
      }
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
        <Info>{t('SECURITY_QUESTION_INFO')}</Info>

        <Section>
          <TextField
            label={t('SECURITY_QUESTIONS_QUESTION_TITLE')}
            placeholder={t('SECURITY_QUESTIONS_QUESTION_HINT')}
            {...register('alarmCenterCodeword')}
            error={errors?.alarmCenterCodeword?.message}
          />
          <Info>{t('SECURITY_QUESTIONS_QUESTION_DESCRIPTION')}</Info>
        </Section>

        <Section>
          <TextField
            label={t('SECURITY_QUESTIONS_ANSWER_TITLE')}
            placeholder={t('SECURITY_QUESTIONS_ANSWER_HINT')}
            {...register('customerCodeword')}
            error={errors?.customerCodeword?.message}
          />
          <Info>{t('SECURITY_QUESTIONS_ANSWER_DESCRIPTION')}</Info>
        </Section>

        <SaveButton className="qa-save-btn" level="secondary" disabled={false}>
          {isLoading ? <Loader /> : t('Save')}
        </SaveButton>
      </form>
      {showOverlay && (
        <Overlay onClose={() => setShowOverlay(false)}>
          <OverlayContent>
            <CheckmarkIconLarge />
            <OverlayHeader>{t('SECURITY_QUESTIONS_UPDATED_TITLE')}</OverlayHeader>
            <OverlayParagraph>{t('SECURITY_QUESTIONS_UPDATED_BODY')}</OverlayParagraph>
          </OverlayContent>
        </Overlay>
      )}
      <TwoFactorPrompt promptState={twoFactorPromptState} />
      <PasswordPrompt promptState={promptState} />
    </>
  )
}

const Info = styled(Body)`
  margin-top: 0.5rem;
  padding: 0 1rem;
  color: ${colors.black800};
`

const Section = styled.div`
  margin-top: 2.5rem;
`

const SaveButton = styled(Button)`
  margin-top: 2.5rem;
  float: right;
`

const OverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.green};
  width: 524px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    padding: 0 2rem;
  }
`

const OverlayHeader = styled(Heading3)`
  margin-top: 2.5rem;
  color: ${colors.green};
`

const OverlayParagraph = styled(Body)`
  margin-top: 0.5rem;
  color: ${colors.black300};
`

export default SecurityQuestionForm
