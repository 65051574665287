import styled from 'styled-components'
import Button from '../../../../components/Crow/Button/Button'
import useI18n from '../../../../i18n/useI18n'
import { BonusProduct } from '../../../../types/BonusProduct'
import { getProductUrl } from '../../Benefits/productImages'
import { BenefitsItemContainer, Description, SubContainer, Title } from './StyledComponents'

interface BenefitProductProps {
  product: BonusProduct
  selectable: boolean
  onClick: () => void
}

const Image = styled.img`
  width: 60px;
`

export const BenefitProduct = ({ product, onClick, selectable }: BenefitProductProps) => {
  const { t } = useI18n()
  return (
    <BenefitsItemContainer>
      <SubContainer>
        <Image alt="product__img" src={getProductUrl(product.ProductNumber)} />
      </SubContainer>
      <SubContainer>
        <Title>{product.Name}</Title>
        <Description>{`${product.Points} ${t('points')}`}</Description>
      </SubContainer>
      <SubContainer>
        <Button level="primary" onClick={onClick}>
          {t('Purchase')}
        </Button>
      </SubContainer>
    </BenefitsItemContainer>
  )
}
