import classNames from 'classnames'
import { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../../components/Crow/Style/theme'
import Toast, { addToast } from '../../../../components/Crow/Toast/Toast'
import Toggle from '../../../../components/Crow/Toogle/Toggle'
import { Paragraph } from '../../../../components/Crow/Typography'
import { Loader } from '../../../../components/Elements/Loaders'
import TwoFactorPrompt, {
  useTwoFactorPrompt,
} from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { usePanelInfo, useSetPanelSettings } from '../../../../services/requestHooks/panelInfo'
import { Accesses } from '../../../../types/PermanentUser'
import { useOnMount } from '../../../../utils/commonHooks'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../hooks/usePanelId'
import PasswordPrompt, { usePasswordPrompt } from '../../Panel/PasswordPromt'

const QuickArming = ({
  displayName,
  quickArmEnabled,
  permissions,
}: {
  displayName: string
  quickArmEnabled: boolean
  permissions: Accesses | undefined
}) => {
  const { t } = useI18n()

  const panelId = usePanelId()

  const { data, run: getPanelInfo, isLoading } = usePanelInfo()
  const [isQuickArmEnabled, setIsQuickArmEnabled] = useState(quickArmEnabled)

  const {
    run: savePanelSettings,
    isLoading: isLoadingPanelSettings,
    error: isPanelSettingsError,
  } = useSetPanelSettings({
    onSuccess: () => {
      setIsQuickArmEnabled(!isQuickArmEnabled)
    },
  })

  useOnMount(() => {
    if (!isLoading && panelId !== data?.PanelId) {
      getPanelInfo(panelId)
    }
  })

  const { promptState, promptForPassword } = usePasswordPrompt()
  const { promptState: twoFactorPromptState, promptForTwoFactor } = useTwoFactorPrompt()

  const toggleQuickArm = async () => {
    const validationCode = await promptForTwoFactor()
    if (validationCode) {
      const Password = await promptForPassword()
      if (!Password) return
      if (!permissions?.IsLegalOwner && !permissions?.IsAdminUser) {
        addToast({
          title: `${t('full_access')} ${t('Required')}`,
          type: 'error',
          time: 2000,
        })
      } else
        savePanelSettings({
          DisplayName: displayName,
          QuickArm: !isQuickArmEnabled,
          Password: Password,
          PanelId: panelId,
        })
    }
  }

  return (
    <>
      <QuickArmingDescription light grey>
        {t('SETTINGS_QUICK_ARMING_DESCRIPTION')}
      </QuickArmingDescription>
      <QuickArmingToogleContainer>
        <QuickArmingTextContainer>
          <QuickArmingParagraph>{t('SETTINGS_QUICK_ARMING_BUTTON_TITLE')}</QuickArmingParagraph>
          <QuickArmingParagraph2 light grey className="qa-quickarm-status">
            {isQuickArmEnabled ? <>{t('enabled')}</> : <>{t('Disabled')}</>}
          </QuickArmingParagraph2>
        </QuickArmingTextContainer>
        <Toggle
          id="main"
          key={1}
          enabled={isQuickArmEnabled!}
          onClick={toggleQuickArm}
          className={classNames(`ml-auto`, `qa-quickarming-toggle`)}
        />
      </QuickArmingToogleContainer>

      {isLoadingPanelSettings && (
        <div className="flex align-center">
          <Loader />
          <SystemNameChangeLoadingText grey light>
            {t('change_in_progress')}
          </SystemNameChangeLoadingText>
        </div>
      )}
      {isPanelSettingsError && <FailedRequest text={t('Something went wrong. Please try again')} />}
      <TwoFactorPrompt promptState={twoFactorPromptState} />
      <PasswordPrompt promptState={promptState} />
      <Toast />
    </>
  )
}

const QuickArmingDescription = styled(Paragraph)`
  max-width: 460px;
  margin-bottom: 38px;
  padding-left: 16px;
`

const QuickArmingToogleContainer = styled.div`
  border: 1px solid ${colors.black400};
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 32px;
`

const QuickArmingTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const QuickArmingParagraph = styled(Paragraph)`
  color: ${colors.black800};
`
const QuickArmingParagraph2 = styled(Paragraph)`
  line-height: 12px;
`

const SystemNameChangeLoadingText = styled(Paragraph)`
  line-height: 18px;
  padding-left: 12px;
`

export default QuickArming
