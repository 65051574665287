const WifiOffIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.24018 1.07733C3.86868 0.668677 3.23623 0.63856 2.82757 1.01007C2.41891 1.38157 2.3888 2.01402 2.7603 2.42268L4.13727 3.93735C2.85619 4.61108 1.67948 5.47185 0.646815 6.49343C0.25419 6.88185 0.250775 7.515 0.639187 7.90763C1.0276 8.30025 1.66076 8.30367 2.05338 7.91526C3.06925 6.91028 4.24652 6.08549 5.53454 5.47434L7.31292 7.43056C6.01548 7.92914 4.82814 8.69046 3.82849 9.67321C3.43465 10.0604 3.42925 10.6935 3.81643 11.0874C4.20361 11.4812 4.83675 11.4866 5.23059 11.0994C6.22383 10.123 7.44768 9.41859 8.78425 9.04902L10.7889 11.2542C9.38086 11.306 8.04036 11.8718 7.02093 12.8449C6.62144 13.2263 6.60674 13.8593 6.98809 14.2588C7.36944 14.6583 8.00243 14.673 8.40192 14.2916C9.06991 13.6539 9.94901 13.2841 10.8719 13.2525C11.7729 13.2216 12.6543 13.5151 13.3564 14.0783L17.7603 18.9227C18.1318 19.3313 18.7643 19.3615 19.1729 18.9899C19.5816 18.6184 19.6117 17.986 19.2402 17.5773C14.24 12.0772 9.24039 6.57756 4.24018 1.07733Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M11.0002 17.875C11.6216 17.875 12.1252 17.3713 12.1252 16.75C12.1252 16.1287 11.6216 15.625 11.0002 15.625C10.3789 15.625 9.87524 16.1287 9.87524 16.75C9.87524 17.3713 10.3789 17.875 11.0002 17.875Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M19.9458 7.91526C17.5654 5.56045 14.3501 4.24293 11.0017 4.25027L10.9992 4.25028C10.3919 4.2501 9.78534 4.29277 9.18402 4.378C8.6372 4.45549 8.13109 4.07503 8.0536 3.52821C7.9761 2.98139 8.35656 2.47528 8.90338 2.39778C9.59745 2.29942 10.2976 2.25013 10.9986 2.25028C14.8747 2.24211 18.5967 3.76743 21.3524 6.49341C21.745 6.88182 21.7484 7.51497 21.36 7.90761C20.9716 8.30024 20.3384 8.30366 19.9458 7.91526Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M12.2913 7.78483C12.4253 7.24903 12.9682 6.92326 13.504 7.0572C15.265 7.4974 16.8762 8.4006 18.1706 9.67318C18.5644 10.0604 18.5698 10.6935 18.1826 11.0873C17.7955 11.4812 17.1623 11.4866 16.7685 11.0994C15.7285 10.0769 14.4339 9.35118 13.019 8.99749C12.4832 8.86356 12.1574 8.32063 12.2913 7.78483Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { WifiOffIcon }
