import React from 'react'
import styled from 'styled-components'
import { ArrowRightIcon } from '../../../../assets/icons/crow'
import { HoverContainer } from '../../../../components/Crow/AnimatedContainers/HoverContainer'

interface MenuItemProps {
  children: React.ReactNode
  disabled?: boolean
  onClick: () => void
}

export const MenuItem = ({ children, disabled, onClick }: MenuItemProps) => {
  return (
    <Wrapper disabled={disabled}>
      <HoverContainer disabled={disabled}>
        <MenuItemContainer onClick={!disabled ? onClick : () => {}}>
          <SubContainer>{children}</SubContainer>
          <SubContainer>
            <ArrowRightIcon />
          </SubContainer>
        </MenuItemContainer>
      </HoverContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1.0)};
`

const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 12px;
  height: 64px;
  border: 1px solid #e3e1e1;
  width: 100%;

  & > *:first-child {
    justify-content: flex-start;
  }

  & > *:last-child {
    justify-content: flex-end;
  }
  cursor: pointer;
`

const SubContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
`
