const CancelIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6977 1.76217C16.1008 1.35905 16.1008 0.705463 15.6977 0.302341C15.2945 -0.10078 14.6409 -0.10078 14.2378 0.302341L8 6.54017L1.76217 0.302341C1.35905 -0.10078 0.705463 -0.10078 0.302341 0.302341C-0.10078 0.705463 -0.10078 1.35905 0.302341 1.76217L6.54017 8L0.302341 14.2378C-0.10078 14.6409 -0.10078 15.2945 0.302341 15.6977C0.705463 16.1008 1.35905 16.1008 1.76217 15.6977L8 9.45983L14.2378 15.6977C14.6409 16.1008 15.2945 16.1008 15.6977 15.6977C16.1008 15.2945 16.1008 14.6409 15.6977 14.2378L9.45983 8L15.6977 1.76217Z"
        fill={props.color ? props.color : 'currentColor'}
        className="qa-cancel-btn"
      />
    </svg>
  )
}

export { CancelIcon }
