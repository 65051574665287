import { yupResolver } from '@hookform/resolvers/yup'
import { CountryCode } from 'libphonenumber-js'
import React, { useEffect, useImperativeHandle } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'
import { panelCodeValidator, phoneNumberValidator } from '../../../components/forms/validators'
import useI18n from '../../../i18n/useI18n'
import { usePanelInfo } from '../../../services/requestHooks/panelInfo'
import { getPanelCountryCode } from '../../../utils/numberField'
import Button from '../../Crow/Button/Button'
import { Loader } from '../../Elements/Loaders'
import Information from './Information'
import Privileges from './Privileges'
import Settings from './Settings'

export interface AddUserFormRef {
  handleReSubmit?: (() => void) | undefined
}

interface Props {
  onSubmit: SubmitHandler<any>
  cancelAction?: () => void
  isLoading?: boolean
  outsideRef: React.MutableRefObject<AddUserFormRef>
}

const AddUserForm = ({ onSubmit, cancelAction, isLoading, outsideRef }: Props) => {
  const { t } = useI18n()

  const { data: panelInfo } = usePanelInfo()
  const countryCode: CountryCode = getPanelCountryCode(panelInfo?.PanelId || 'noPanelId')
  const panelCodeLength: number = panelInfo!.PanelCodeLength

  const resolver = yup.object({
    FirstName: yup.string().min(2, t('error_first_name_at_least_2')),
    LastName: yup.string().min(2, t('error_last_name_at_least_2')),
    IsChild: yup.boolean(),
    IsSystemUser: yup.boolean(),
    IsAppUser: yup.boolean(),
    IsAdminUser: yup.boolean(),
    PhoneNumber: phoneNumberValidator(t).when('IsAppUser', {
      is: true,
      then: yup.string().required(t('error_phone_number_required')),
    }),
    PanelPinCode: panelCodeValidator(panelCodeLength, t).when('IsSystemUser', {
      is: true,
      then: yup.string().required(t('error_pin_code_required')),
    }),
  })

  const defaultValues = {
    FirstName: '',
    LastName: '',
    IsChild: false,
    IsSystemUser: false,
    IsAppUser: false,
    IsAdminUser: false,
    PhoneNumber: '',
    PanelPinCode: '',
  }

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(resolver),
  })
  const isChild = watch('IsChild')
  const isSystemUser = watch('IsSystemUser')
  const isAppUser = watch('IsAppUser')
  const isAdminUser = watch('IsAdminUser')

  const shouldBeDisabled = (fieldName: 'IsSystemUser' | 'IsAdminUser' | 'IsAppUser') => {
    return (
      (isChild && fieldName === 'IsAdminUser') ||
      (!isSystemUser && (fieldName === 'IsAppUser' || fieldName === 'IsAdminUser'))
    )
  }

  const handleReSubmit = () => {
    handleSubmit(onSubmit)()
  }

  useImperativeHandle(
    outsideRef,
    () => ({
      handleReSubmit,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleReSubmit, onSubmit],
  )

  useEffect(() => {
    if (isChild) {
      isAdminUser && setValue('IsAdminUser', false)
    }
    // eslint-disable-next-line
  }, [isChild])

  useEffect(() => {
    if (!isSystemUser) {
      isAppUser && setValue('IsAppUser', false)
      isAdminUser && setValue('IsAdminUser', false)
      setValue('PanelPinCode', '')
    }
    // eslint-disable-next-line
  }, [isSystemUser])

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
      <S.Content>
        <div>
          <Information register={register} errors={errors} />
          <Settings register={register} />
        </div>
        <Privileges
          register={register}
          shouldBeDisabled={shouldBeDisabled}
          isSystemUser={isSystemUser}
          panelCodeLength={panelCodeLength}
          isAppUser={isAppUser}
          control={control}
          countryCode={countryCode}
          isChild={isChild}
          errors={errors}
        />
      </S.Content>
      <S.ButtonRow>
        <Button
          type="button"
          onClick={cancelAction}
          level="secondary"
          size="l"
          disabled={isLoading}
        >
          {t('Cancel')}
        </Button>
        <Button level="primary" size="l" disabled={isLoading}>
          {isLoading ? <Loader /> : t('Add')}
        </Button>
      </S.ButtonRow>
    </form>
  )
}

const S = {
  Content: styled.div`
    margin-top: 48px;
    display: grid;
    column-gap: 78px;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;

    @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  `,
  ButtonRow: styled.div`
    margin-top: 107px;
    display: flex;
    gap: 24px;

    @media only screen and (max-width: 600px) {
      justify-content: center;
    }
  `,
}

export default AddUserForm
