import { FC, MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'

import { colors } from '../Style/theme'

export interface Props {
  enabled: boolean
  onClick?: MouseEventHandler<HTMLElement>
  id?: string
  name?: any
  isLoading?: boolean
  className?: string
  disabled?: boolean
}

const Toggle: FC<Props> = ({ enabled, onClick, name, id, disabled, className }) => {
  return (
    <S.Switch id={id} enabled={enabled} disabled={disabled} onClick={onClick} className={className}>
      <S.Slider className="slider" enabled={enabled} disabled={disabled} />
      <S.Input type="checkbox" disabled={disabled} />
    </S.Switch>
  )
}

const S = {
  Switch: styled.span<{ enabled: boolean; disabled?: boolean }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    width: 64px;
    height: 32px;
    border-radius: 26px;

    ${({ enabled, disabled }) => {
      if (disabled) {
        if (enabled) {
          return css`
            background-color: ${colors.blue100};
            pointer-events: none;
          `
        } else {
          return css`
            background-color: ${colors.black300};
            pointer-events: none;
          `
        }
      } else {
        if (enabled) {
          return css`
            background-color: ${colors.blue};
          `
        } else {
          return css`
            background-color: ${colors.black500};
          `
        }
      }
    }}
  `,
  Slider: styled.span<{ enabled: boolean; disabled?: boolean }>`
    margin-left: 4px;
    display: block;
    height: 24px;
    width: 24px;
    border-radius: 100%;
    background-color: ${({ disabled }) => (disabled ? `#FCFAFA` : `${colors.white}`)};
    -webkit-transition: 0.4s;
    transition: 0.4s;

    ${({ enabled }) => {
      return (
        enabled &&
        css`
          -webkit-transform: translateX(32px);
          -ms-transform: translateX(32px);
          transform: translateX(32px);
        `
      )
    }}
  `,
  Input: styled.input`
    height: 0;
    width: 0;
    opacity: 0;
  `,
}

export default Toggle
