const InfoIconLarge = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 2C12.2975 2 2 12.2975 2 25C2 37.7025 12.2975 48 25 48C37.7025 48 48 37.7025 48 25C48 12.2975 37.7025 2 25 2ZM0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 23C22 22.4477 22.4477 22 23 22H25C25.5523 22 26 22.4477 26 23V36H27C27.5523 36 28 36.4477 28 37C28 37.5523 27.5523 38 27 38H25C24.4477 38 24 37.5523 24 37V24H23C22.4477 24 22 23.5523 22 23Z"
        fill="#333131"
      />
      <path
        d="M24.5 14C25.3284 14 26 13.3284 26 12.5C26 11.6716 25.3284 11 24.5 11C23.6716 11 23 11.6716 23 12.5C23 13.3284 23.6716 14 24.5 14Z"
        fill="#333131"
      />
    </svg>
  )
}

export { InfoIconLarge }
