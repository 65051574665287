import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Humidity } from '../types/Humidity'

type HumidityState = Record<string, { humidity?: Humidity } | undefined>

const initialState: HumidityState = {}

const humiditySlice = createSlice({
  name: 'humidity',
  initialState,
  reducers: {
    GET_HUMIDITY(state, action: PayloadAction<{ data: Humidity; panelId: string }>) {
      const { data, panelId } = action.payload
      state[panelId] = { humidity: data }
    },
  },
})

export const GET_HUMIDITY = humiditySlice.actions.GET_HUMIDITY

export const humidityReducer = humiditySlice.reducer
