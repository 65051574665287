import { useContext, useState } from 'react'
import AddButton from '../../../../components/Button/AddButton'
import ConfirmationPrompt, {
  useConfirmationPrompt,
} from '../../../../components/ComfirmationPrompt/ConfirmationPrompt'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import {
  useGetAppUsers,
  useInviteAppUser,
  useUnInviteAppUser,
} from '../../../../services/requestHooks/appUser'
import { AccessGroupType, AppUser } from '../../../../types/AppUser'
import { TranslationKey } from '../../../../types/generated/TranslationKey'
import { useOnMount } from '../../../../utils/commonHooks'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../hooks/usePanelId'
import SettingsSubPage from '../components/SettingsSubPage'
import ContactFormPrompt, { useContactFormPrompt } from '../Contacts/components/ContactFormPrompt'
import './appUser.scss'
import AppUserListItem from './components/AppUserListItem'

const AppUsersPage = () => {
  const panelId = usePanelId()
  const { t } = useI18n()
  const [selectedUser, setSelectedUser] = useState<AppUser | undefined>()
  const [userToBeDeleted, setUserToBeDeleted] = useState<AppUser | undefined>()

  const [userType, setUserType] = useState<AccessGroupType | undefined>()
  const { promptForTwoFactor } = useContext(TwofactorContext)
  const { promptState, promptForContact } = useContactFormPrompt()
  const { promptState: confirmationPromptState, prompt: confirmPrompt } = useConfirmationPrompt()

  const {
    run: getAppUsers,
    error: getUsersError,
    data: users = [],
    isLoading: isLoadingUsers,
  } = useGetAppUsers()

  const { run: inviteUser, error: inviteUserError, isLoading: isInvitingUser } = useInviteAppUser({
    onSuccess: () => {
      getAppUsers(panelId)
    },
  })

  const {
    run: unInviteUser,
    isLoading: isUnInvitingUser,
    error: uninviteUserError,
  } = useUnInviteAppUser({
    onSuccess: () => {
      getAppUsers(panelId)
    },
  })

  const errorMessage = getUsersError || inviteUserError || uninviteUserError
  const guestUsers = users.filter((user) => user.AccessGroup === AccessGroupType.USER)
  const adminUsers = users.filter(
    ({ AccessGroup }) =>
      AccessGroup === AccessGroupType.ADMINISTRATOR || AccessGroup === AccessGroupType.LEGAL_OWNER,
  )

  useOnMount(() => {
    const onLoad = async () => {
      const code = await promptForTwoFactor(`/systems/${panelId}/settings`)
      if (code && !isLoadingUsers) {
        getAppUsers(panelId)
      }
    }
    onLoad()
  })

  const openContactForm = (accessType: AccessGroupType) => async () => {
    setUserType(accessType)
    const values = await promptForContact()
    const ValidationCode = await promptForTwoFactor()
    if (!ValidationCode) return
    if (values) {
      inviteUser({
        FirstName: values.FirstName,
        LastName: values.LastName,
        PanelId: panelId,
        AccessGroup: accessType,
        PhoneNumber: values.Mobile,
        ValidationCode: ValidationCode,
      })
    }
  }

  const formTitle = () => {
    if (userType === AccessGroupType.USER) return t('ADD_USER')
    return t('ADD_ADMINISTRATOR')
  }

  const handleDeletePrompt = async (user: AppUser) => {
    try {
      setUserToBeDeleted(user)
      await confirmPrompt()
      unInviteUser({
        AppUserId: user.AppUserId,
        PanelId: panelId,
      })
    } catch (canceledError) {
    } finally {
      setUserToBeDeleted(undefined)
    }
  }

  return (
    <SettingsSubPage isLoading={isLoadingUsers || isInvitingUser || isUnInvitingUser}>
      <div className="status-list padding-bottom">
        <h1 className="margin-bottom">{t('APP_USERS_HEADER')}</h1>
        <p className="large">{t('APP_USERS_HEADER_DESCRIPTION')}</p>
        {errorMessage && <FailedRequest text={'Something went wrong. Please try again'} />}
        <div className="status-list">
          <h2 className="gray mt-8 pb-2 white-underline text-lg">{t('ADMINISTRATOR')}</h2>
          {adminUsers.map((user) => (
            <AppUserListItem
              onDelete={() => handleDeletePrompt(user)}
              onSelectUser={() => setSelectedUser(user)}
              onUnSelectUser={() => setSelectedUser(undefined)}
              panelId={panelId}
              key={user.AppUserId}
              user={user}
              isSelected={user.AppUserId === selectedUser?.AppUserId}
            />
          ))}
          <AddUserButton
            onAddUser={openContactForm(AccessGroupType.ADMINISTRATOR)}
            text={'ADD_ADMINISTRATOR'}
          />
        </div>
        <div className="status-list">
          <h2 className="gray mt-8 pb-2 white-underline text-lg">{t('Users')}</h2>
          {guestUsers.map((user) => (
            <AppUserListItem
              onDelete={() => handleDeletePrompt(user)}
              onSelectUser={() => setSelectedUser(user)}
              onUnSelectUser={() => setSelectedUser(undefined)}
              panelId={panelId}
              key={user.AppUserId}
              user={user}
              isSelected={user.AppUserId === selectedUser?.AppUserId}
            />
          ))}
          <AddUserButton onAddUser={openContactForm(AccessGroupType.USER)} text="ADD_USER" />
        </div>
      </div>
      <ContactFormPrompt
        onlyMobile
        title={formTitle()}
        helpText={t('ADD_ADMIN_INFOTEXT_2')}
        {...promptState}
      />

      {userToBeDeleted && (
        <ConfirmationPrompt promptState={confirmationPromptState}>
          <h1 className="text-2xl">
            {t('Delete')} : {userToBeDeleted.FirstName} {userToBeDeleted.LastName}
          </h1>
          <div className="mt-2 mb-2">{t('CONFIRM_USER_DELETE')}</div>
        </ConfirmationPrompt>
      )}
    </SettingsSubPage>
  )
}

interface UserListProps {
  onAddUser: () => void
  text: TranslationKey
}

const AddUserButton = ({ onAddUser, text }: UserListProps) => {
  const { t } = useI18n()
  return (
    <div>
      <AddButton onClick={onAddUser} className="margin-top flex flex-1">
        <span className="add-new__text">{t(text)}</span>
      </AddButton>
    </div>
  )
}

export default AppUsersPage
