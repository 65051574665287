const BalconyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 0C10.4477 0 10 0.447715 10 1L10 16C10 16.5523 10.4477 17 11 17C11.5523 17 12 16.5523 12 16L12 2L20 2V16C20 16.5523 20.4477 17 21 17C21.5523 17 22 16.5523 22 16V2L30 2V16C30 16.5523 30.4477 17 31 17C31.5523 17 32 16.5523 32 16V1C32 0.965484 31.9983 0.931375 31.9948 0.897758C31.9436 0.393501 31.5178 0 31 0H11Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 18C0.447716 18 0 18.4477 0 19C0 19.5523 0.447716 20 1 20H2L2 33H1C0.447716 33 0 33.4477 0 34V37C0 37.5523 0.447715 38 1 38H41C41.5523 38 42 37.5523 42 37V34C42 33.4477 41.5523 33 41 33H40V20H41C41.5523 20 42 19.5523 42 19C42 18.4477 41.5523 18 41 18H1ZM40 36V35H2L2 36H40ZM6 33H4L4 20H6L6 33ZM10 33H8L8 20H10L10 33ZM14 33H12L12 20H14L14 33ZM18 33H16V20H18V33ZM22 20V33H20V20H22ZM26 33H24V20H26V33ZM30 33H28V20H30V33ZM34 33H32V20H34V33ZM38 33H36V20H38V33Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
    </svg>
  )
}

export { BalconyIcon }
