import { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import BackButton from '../../../components/BackButton'
import Card from '../../../components/Crow/Card/Card'
import { colors } from '../../../components/Crow/Style/theme'
import Toast from '../../../components/Crow/Toast/Toast'
import { Paragraph, SubTitle, Title } from '../../../components/Crow/Typography/Typography'
import FullscreenPage from '../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import { displayNewContactPersons, displayNewNavigationMenu } from '../../../configs'
import useI18n from '../../../i18n/useI18n'
import { usePanelInfo } from '../../../services/requestHooks/panelInfo'
import { Capability } from '../../../types/PanelModel'
import { useOnMount } from '../../../utils/commonHooks'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'

const CrowPeoplePage: FC = () => {
  const [peopleModuleNotEnabled, setPeopleModuleNotEnabled] = useState<boolean>(false)

  const { t } = useI18n()
  const panelId = usePanelId()
  const {
    run: getPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadPanelInfoError,
  } = usePanelInfo()
  const history = useHistory()
  const returnUrl = `/systems/${panelId}`

  useOnMount(() => {
    if (!loadingPanelInfo && panelId !== panelInfo?.PanelId) {
      getPanelInfo(panelId)
    }
    if (panelInfo?.Capabilities.includes(Capability.USE_LEGACY_PEOPLE)) {
      setPeopleModuleNotEnabled(true)
    }
  })

  const contactsUrl = displayNewContactPersons
    ? `/systems/${panelId}/people/contacts`
    : `/systems/${panelId}/settings/contacts`

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen footer>
      <S.Page
        isLoading={loadingPanelInfo}
        loaderProps={{
          loadingText: { text: 'Loading' },
          errorHandling: {
            loadingFailed: !loadingPanelInfo && !!loadPanelInfoError,
            errorHeaderText: t('PEOPLE'),
            errorText: peopleModuleNotEnabled
              ? 'People module not enabled for this user'
              : 'Something went wrong. Please try again',
          },
        }}
      >
        {!displayNewNavigationMenu && <BackButton backUrl={returnUrl} />}

        <Title large>{t('PEOPLE')}</Title>
        <S.Description>{t('users_description')} </S.Description>
        <CardWrapper>
          <Card col onClick={() => history.push(`/systems/${panelId}/people/users`)}>
            <CardTitle>{t('people_permanent_users')}</CardTitle>
            <CardDescription>{t('people_permanent_users_description')}</CardDescription>
          </Card>

          <Card col onClick={() => history.push(contactsUrl)}>
            <CardTitle>{t('CONTACT_PERSONS')}</CardTitle>
            <CardDescription>{t('people_contact_users_description')}</CardDescription>
          </Card>
        </CardWrapper>
        <Toast />
      </S.Page>
    </TcOrCrowContainer>
  )
}

const S = {
  Page: styled(FullscreenPage)`
    min-width: 600px;

    & > div {
      align-items: normal;
    }

    & .grid__half {
      flex: 1;
    }

    @media only screen and (max-width: 600px) {
      min-width: 0;
    }
  `,
  Description: styled(Paragraph)`
    margin-top: 16px;
    color: ${colors.black800};
  `,
}

const CardWrapper = styled.div`
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const CardTitle = styled(SubTitle)`
  margin-top: 0;
  color: ${colors.black900};
`

const CardDescription = styled.p`
  font-weight: 600;
  line-height: 18px;
  font-size: 14px;
`

export default CrowPeoplePage
