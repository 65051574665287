const PoolIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 17C24.3431 17 23 18.3431 23 20V38C23 38.5523 23.4477 39 24 39C24.5523 39 25 38.5523 25 38V35H35V35.5C35 36.0523 35.4477 36.5 36 36.5C36.5523 36.5 37 36.0523 37 35.5V20C37 19.4477 37.4477 19 38 19C38.5523 19 39 19.4477 39 20V27.5C39 28.0523 39.4477 28.5 40 28.5C40.5523 28.5 41 28.0523 41 27.5V20C41 18.3431 39.6568 17 38 17C36.3431 17 35 18.3431 35 20V25H25V20C25 19.4477 25.4477 19 26 19C26.5523 19 27 19.4477 27 20V22C27 22.5523 27.4477 23 28 23C28.5523 23 29 22.5523 29 22V20C29 18.3431 27.6568 17 26 17ZM35 33H25V31H35V33ZM35 29V27H25V29H35Z"
        fill="#333131"
      />
      <path
        d="M5.16437 37.9819C4.98368 37.46 4.55228 37 3.99999 37C3.44771 37 2.98956 37.4522 3.09872 37.9936C3.55952 40.279 5.57878 42 7.99999 42C9.6356 42 11.0878 41.2147 12 40.0005C12.9122 41.2147 14.3644 42 16 42C17.6356 42 19.0878 41.2147 20 40.0005C20.9122 41.2147 22.3644 42 24 42C25.6356 42 27.0878 41.2147 28 40.0005C28.9122 41.2147 30.3644 42 32 42C33.6356 42 35.0878 41.2147 36 40.0005C36.9122 41.2147 38.3644 42 40 42C42.4212 42 44.4405 40.279 44.9013 37.9936C45.0104 37.4522 44.5523 37 44 37C43.4477 37 43.0163 37.46 42.8356 37.9819C42.4289 39.1565 41.313 40 40 40C38.687 40 37.571 39.1565 37.1644 37.9819C36.9837 37.46 36.5523 37 36 37C35.4477 37 35.0163 37.46 34.8356 37.9819C34.4289 39.1565 33.313 40 32 40C30.687 40 29.571 39.1565 29.1644 37.9819C28.9837 37.46 28.5523 37 28 37C27.4477 37 27.0163 37.46 26.8356 37.9819C26.4289 39.1565 25.313 40 24 40C22.687 40 21.571 39.1565 21.1644 37.9819C20.9837 37.46 20.5523 37 20 37C19.4477 37 19.0163 37.46 18.8356 37.9819C18.4289 39.1565 17.313 40 16 40C14.687 40 13.571 39.1565 13.1644 37.9819C12.9837 37.46 12.5523 37 12 37C11.4477 37 11.0163 37.46 10.8356 37.9819C10.4289 39.1565 9.31297 40 7.99999 40C6.68702 40 5.57105 39.1565 5.16437 37.9819Z"
        fill="#333131"
      />
      <path d="M35 27H25V29H35V27Z" fill="#E3E1E1" />
      <path d="M35 31H25V33H35V31Z" fill="#E3E1E1" />
    </svg>
  )
}

export { PoolIcon }
