import styled from 'styled-components'
import useI18n from '../../../i18n/useI18n'
import { useOnMount } from '../../../utils/commonHooks'
import { logErrorEvent } from '../../../utils/firebaseAnalytics'
import { colors } from '../Style/theme'

interface ErrorMessageProps {
  text?: string
  errorLocation?: string
}

const ErrorText = styled.p`
  font-size: 16px;
  color: ${colors.red};
  margin: 10px 0 10px 0;
`

export const ErrorMessage = ({ text, errorLocation }: ErrorMessageProps) => {
  useOnMount(() => {
    logErrorEvent(errorLocation ? errorLocation : 'unknown', text ? text : 'unknown')
  })

  const { t } = useI18n()
  return <ErrorText>{text ? text : t('Something went wrong. Please try again')}</ErrorText>
}
