const RestaurantIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9478 12.6819C20.1235 13.2055 19.8415 13.7724 19.3179 13.9481C17.8663 14.4352 16.5368 15.2612 15.4315 16.3665C14.326 17.472 13.4739 18.8279 12.9428 20.3328C12.759 20.8536 12.1878 21.1268 11.667 20.943C11.1462 20.7592 10.873 20.188 11.0568 19.6672C11.6857 17.8853 12.6971 16.2724 14.0172 14.9523C15.3375 13.632 16.9322 12.6391 18.6816 12.052C19.2052 11.8763 19.7721 12.1583 19.9478 12.6819Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 6C27 6.42986 26.9096 6.8386 26.7468 7.20825C35.3842 8.53077 42 15.9928 42 25L41.9999 25.05C43.1411 25.2816 44 26.2905 44 27.5C44 28.8807 42.8807 30 41.5 30H6.5C5.11929 30 4 28.8807 4 27.5C4 26.2905 4.85891 25.2816 6.00007 25.05L6 25C6 15.9928 12.6158 8.53077 21.2532 7.20825C21.0904 6.8386 21 6.42986 21 6C21 4.34315 22.3431 3 24 3C25.6569 3 27 4.34315 27 6ZM25 6C25 6.55228 24.5523 7 24 7C23.4477 7 23 6.55228 23 6C23 5.44772 23.4477 5 24 5C24.5523 5 25 5.44772 25 6ZM24 9C32.8366 9 40 16.1634 40 25H8C8 16.1634 15.1634 9 24 9ZM42 27.5C42 27.2239 41.7761 27 41.5 27H6.5C6.22386 27 6 27.2239 6 27.5C6 27.7761 6.22386 28 6.5 28H41.5C41.7761 28 42 27.7761 42 27.5Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9001 33.3026C20.9001 32.0721 20.6057 31.3432 19.6534 31.0451C18.936 30.8206 18.1539 31.0485 17.6694 31.6232L14.5342 35.8454C12.8381 36.0503 11.4366 37.0929 10.7004 38.5117L5.40634 40.0317C4.24255 40.3128 4.00046 41.5 4 42.6695V44.5C4.3237 45.3754 5.07452 46.1211 6 46L15.1538 44.5C16.663 44.3025 18.0492 44.0298 19.4963 43.7451C20.2702 43.5928 21.0616 43.4371 21.8985 43.2877C22.3515 43.2068 22.9855 42.7614 23.5466 42.3672C23.8532 42.1518 24.1382 41.9516 24.3597 41.8347L33.8924 35.5695C35.1224 34.8725 35.5449 33.2937 34.8134 32.0721C34.1645 30.9885 32.8153 30.5501 31.6534 31.0453C29.5353 31.8517 27.6566 32.7421 25.9924 33.5309C24.8659 34.0648 23.8377 34.5522 22.9001 34.9353C22.1813 35.229 21.5158 35.4615 20.9001 35.6068V33.3026ZM23.4496 40.0513C23.4695 40.0389 32.3339 34.1967 32.3339 34.1967L32.356 34.182C32.5333 34.0637 32.7081 33.9471 32.8909 33.8379C33.1588 33.6966 33.2531 33.3594 33.0975 33.0996C32.962 32.8733 32.6802 32.7817 32.4375 32.8852L32.4015 32.9005L32.365 32.9144C30.3244 33.6914 28.5144 34.549 26.844 35.3406L26.8303 35.3471C26.6738 35.4212 26.5186 35.4948 26.3645 35.5676C24.6302 36.3876 22.9063 37.1883 21.3594 37.5533L18.9001 38.1338V33.3206L15.6274 37.7279L14.774 37.831C13.7644 37.9529 12.9216 38.5733 12.4756 39.4329L12.0793 40.1965L6.06772 41.9227C6.05685 41.9667 6.04547 42.0228 6.03525 42.0927C6.01228 42.2499 6.00012 42.4418 6 42.6695V43.9733L14.8623 42.5211L14.8943 42.5169C16.346 42.3269 17.6229 42.0753 19.023 41.7993C19.7842 41.6493 20.5819 41.492 21.4639 41.3337C21.4809 41.3256 21.5055 41.3134 21.5379 41.2958C21.663 41.228 21.8242 41.1262 22.0304 40.9856C22.1616 40.8962 22.2639 40.8242 22.3741 40.7467C22.4621 40.6847 22.5552 40.6192 22.6721 40.5384C22.8778 40.3962 23.1533 40.21 23.4265 40.0658C23.4225 40.0679 23.4296 40.0635 23.4496 40.0513Z"
        fill="#333131"
      />
      <path
        d="M42 27.5C42 27.2239 41.7761 27 41.5 27H6.5C6.22386 27 6 27.2239 6 27.5C6 27.7761 6.22386 28 6.5 28H41.5C41.7761 28 42 27.7761 42 27.5Z"
        fill="#E3E1E1"
      />
      <path
        d="M24.3597 41.8347L33.0202 36.1427C30.827 37.6037 24.8704 41.5653 24.3597 41.8347Z"
        fill="#E3E1E1"
      />
      <path
        d="M32.356 34.182C32.5333 34.0637 32.7081 33.9471 32.8909 33.8379C32.9814 33.7901 33.0521 33.72 33.1001 33.6381C33.1763 33.5079 33.195 33.3478 33.1443 33.2001C33.1325 33.1658 33.117 33.1321 33.0975 33.0996C32.962 32.8733 32.6802 32.7817 32.4375 32.8852L32.4015 32.9005L32.365 32.9144C30.3244 33.6914 28.5144 34.549 26.844 35.3406L26.3645 35.5676C24.6302 36.3876 22.9063 37.1883 21.3594 37.5533L18.9001 38.1338V33.3206L15.6274 37.7279L14.774 37.831C13.7644 37.9529 12.9216 38.5733 12.4756 39.4329L12.0793 40.1965L6.06772 41.9227C6.05685 41.9667 6.04547 42.0228 6.03525 42.0927C6.01228 42.2499 6.00012 42.4418 6 42.6695V43.9733L14.8623 42.5211L14.8943 42.5169C16.346 42.3269 17.6229 42.0752 19.023 41.7993C19.7842 41.6492 20.5819 41.492 21.4639 41.3336C21.4809 41.3255 21.5055 41.3133 21.5379 41.2957C21.663 41.228 21.8242 41.1261 22.0304 40.9856C22.1616 40.8962 22.2639 40.8242 22.3741 40.7467C22.462 40.6848 22.5554 40.619 22.6721 40.5384C22.8778 40.3962 23.1533 40.21 23.4265 40.0658C23.4267 40.0658 23.4261 40.0661 23.4265 40.0658"
        fill="#E3E1E1"
      />
      <path d="M32.3339 34.1967L32.356 34.182L32.3339 34.1967Z" fill="#E3E1E1" />
    </svg>
  )
}

export { RestaurantIcon }
