import React, { FC, MouseEventHandler } from 'react'
import { Link } from 'react-router-dom'
import useI18n from '../../i18n/useI18n'
import { Loader } from '../Elements/Loaders'
import Button from './Button'

type Props = { isLoading?: boolean } & (
  | { cancelUrl: string; onClick?: never }
  | { onClick: MouseEventHandler<HTMLButtonElement>; cancelUrl?: never }
)

const CancelButton: FC<Props> = ({ cancelUrl, onClick, isLoading, children }) => {
  const { t } = useI18n()

  const content = children || t('Cancel')

  if (isLoading) {
    return <Loader as="span" />
  }
  if (cancelUrl) {
    return <Link to={cancelUrl}>{content}</Link>
  }
  return (
    <Button className="qa-cancel" buttonType="link" type="button" onClick={onClick}>
      {content}
    </Button>
  )
}

export default CancelButton
