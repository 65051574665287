const KidsRoomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.8445 10C31.64 10.7361 31.6346 11.5133 31.8289 12.2522C32.0233 12.9911 32.4104 13.6651 32.9506 14.2054C33.4909 14.7456 34.1649 15.1327 34.9038 15.327C35.6426 15.5213 36.4199 15.5159 37.156 15.3114C36.9541 16.0374 36.5653 16.6978 36.0286 17.2269C35.492 17.7559 34.826 18.1352 34.0971 18.3268C33.3683 18.5184 32.6019 18.5156 31.8744 18.3188C31.1469 18.122 30.4837 17.7381 29.9508 17.2052C29.4179 16.6723 29.0339 16.0091 28.8371 15.2816C28.6404 14.5541 28.6376 13.7877 28.8292 13.0589C29.0208 12.33 29.4 11.664 29.9291 11.1273C30.4581 10.5907 31.1185 10.202 31.8445 10Z"
        fill="#E3E1E1"
      />
      <path
        d="M14 16.1559C14 17.2605 13.1046 18.1559 12 18.1559C10.8954 18.1559 10 17.2605 10 16.1559C10 15.0514 10.8954 14.1559 12 14.1559C13.1046 14.1559 14 15.0514 14 16.1559Z"
        fill="#E3E1E1"
      />
      <path
        d="M21 20.1559C21 21.2605 20.1046 22.1559 19 22.1559C17.8954 22.1559 17 21.2605 17 20.1559C17 19.0514 17.8954 18.1559 19 18.1559C20.1046 18.1559 21 19.0514 21 20.1559Z"
        fill="#E3E1E1"
      />
      <path
        d="M8 6C7.44772 6 7 6.44772 7 7C7 7.55228 7.44772 8 8 8H11V11C11 11.5523 11.4477 12 12 12C12.5523 12 13 11.5523 13 11V8H18V15C18 15.5523 18.4477 16 19 16C19.5523 16 20 15.5523 20 15V8H24C24.5523 8 25 7.55228 25 7C25 6.44772 24.5523 6 24 6H8Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 20C22 21.6569 20.6569 23 19 23C17.3431 23 16 21.6569 16 20C16 18.3431 17.3431 17 19 17C20.6569 17 22 18.3431 22 20ZM20 20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20C18 19.4477 18.4477 19 19 19C19.5523 19 20 19.4477 20 20Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 16C15 17.6569 13.6569 19 12 19C10.3431 19 9 17.6569 9 16C9 14.3431 10.3431 13 12 13C13.6569 13 15 14.3431 15 16ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 26C5 25.4477 5.44772 25 6 25C6.55228 25 7 25.4477 7 26V27H41V26C41 25.4477 41.4477 25 42 25C42.5523 25 43 25.4477 43 26V46C43 46.5523 42.5523 47 42 47C41.4477 47 41 46.5523 41 46V45H7V46C7 46.5523 6.55228 47 6 47C5.44772 47 5 46.5523 5 46V26ZM41 43V29H39V43H41ZM35 29H37V43H35V29ZM33 29H31V43H33V29ZM27 29H29V43H27V29ZM25 29H23V43H25V29ZM19 29H21V43H19V29ZM17 29H15V43H17V29ZM11 29H13V43H11V29ZM9 29H7V43H9V29Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1358 8.88736C31.948 8.83018 31.7492 8.82997 31.5638 8.88422C30.6744 9.13404 29.8655 9.61143 29.2169 10.2694C28.5652 10.9305 28.098 11.7509 27.862 12.6487C27.626 13.5466 27.6294 14.4906 27.8718 15.3868C28.1143 16.2829 28.5872 17.0999 29.2437 17.7564C29.9002 18.4128 30.7172 18.8858 31.6133 19.1282C32.5095 19.3706 33.4535 19.374 34.3514 19.138C35.2492 18.902 36.0696 18.4348 36.7307 17.7831C37.3883 17.1348 37.8656 16.3263 38.1155 15.4375C38.168 15.2589 38.17 15.0676 38.1189 14.8855C37.9978 14.4535 37.6037 14.1549 37.155 14.1553C37.0564 14.1554 36.9605 14.1699 36.8696 14.1971C36.3096 14.3492 35.7195 14.3516 35.1581 14.204C34.5905 14.0547 34.0727 13.7573 33.6577 13.3423C33.2427 12.9273 32.9453 12.4095 32.7961 11.8419C32.6483 11.2801 32.6508 10.6893 32.8033 10.1289C32.8292 10.0419 32.8435 9.9503 32.8446 9.85615C32.85 9.41161 32.5611 9.01685 32.1358 8.88736ZM30.6412 11.6734C30.6673 11.647 30.6938 11.621 30.7207 11.5955C30.7493 11.8494 30.7964 12.1018 30.8618 12.3506C31.1012 13.2608 31.578 14.0911 32.2435 14.7565C32.909 15.422 33.7393 15.8988 34.6494 16.1382C34.8983 16.2036 35.1506 16.2507 35.4045 16.2794C35.379 16.3063 35.353 16.3327 35.3266 16.3588C34.9143 16.7652 34.4028 17.0565 33.8429 17.2037C33.283 17.3508 32.6944 17.3487 32.1355 17.1976C31.5767 17.0464 31.0673 16.7515 30.6579 16.3421C30.2486 15.9328 29.9536 15.4233 29.8024 14.8645C29.6513 14.3057 29.6492 13.717 29.7963 13.1571C29.9435 12.5973 30.2348 12.0857 30.6412 11.6734Z"
        fill="#333131"
      />
    </svg>
  )
}

export { KidsRoomIcon }
