const VisibleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.2496 8.00009C7.2496 5.37674 9.37624 3.25009 11.9996 3.25009C14.6229 3.25009 16.7496 5.37674 16.7496 8.00009C16.7496 10.6234 14.6229 12.7501 11.9996 12.7501C9.37624 12.7501 7.2496 10.6234 7.2496 8.00009ZM11.9996 5.25009C10.4808 5.25009 9.2496 6.48131 9.2496 8.00009C9.2496 9.51888 10.4808 10.7501 11.9996 10.7501C13.5184 10.7501 14.7496 9.51888 14.7496 8.00009C14.7496 6.48131 13.5184 5.25009 11.9996 5.25009Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.22995 3.91757C5.05127 2.09605 7.91698 0.249268 11.9996 0.249268C16.0822 0.249268 18.9479 2.09605 20.7692 3.91757C21.677 4.82544 22.3313 5.73157 22.7599 6.41243C22.9747 6.75363 23.1343 7.04053 23.2419 7.24596C23.2957 7.34875 23.3367 7.43135 23.3651 7.49041C23.377 7.51517 23.3867 7.53581 23.3943 7.55208C23.4619 7.68682 23.5 7.83897 23.5 8C23.5 8.16106 23.4619 8.31322 23.3943 8.44798C23.3867 8.46425 23.377 8.48489 23.3651 8.50965C23.3366 8.5687 23.2957 8.65129 23.2419 8.75405C23.1343 8.95944 22.9747 9.24629 22.7599 9.58743C22.3312 10.2681 21.6769 11.1741 20.7692 12.0818C18.9478 13.9029 16.0821 15.7493 11.9996 15.7493C7.91706 15.7493 5.05136 13.9029 3.23003 12.0818C2.32225 11.1741 1.66797 10.2681 1.23933 9.58743C1.02451 9.24629 0.864937 8.95944 0.757341 8.75405C0.703505 8.65129 0.662569 8.5687 0.634136 8.50965C0.619916 8.48012 0.608812 8.45646 0.600775 8.43908L0.591011 8.41776L0.587839 8.41073L0.586679 8.40814L0.586205 8.40708C0.573183 8.37778 0.561636 8.34799 0.551564 8.31782C0.51812 8.21798 0.5 8.1111 0.5 8C0.5 7.8889 0.518119 7.78202 0.551564 7.68218C0.561627 7.65203 0.573163 7.62227 0.586172 7.59299L0.586646 7.59193L0.587806 7.58934L0.590977 7.58231L0.600741 7.561C0.608777 7.54361 0.61988 7.51994 0.634099 7.49041C0.662531 7.43135 0.703465 7.34875 0.757299 7.24596C0.864893 7.04053 1.02447 6.75363 1.23928 6.41243C1.66791 5.73157 2.32218 4.82544 3.22995 3.91757ZM2.62274 7.99995C2.70243 8.14397 2.80515 8.32069 2.93174 8.52172C3.29997 9.10649 3.86444 9.88785 4.64417 10.6675C6.19783 12.221 8.58214 13.7493 11.9996 13.7493C15.4171 13.7493 17.8014 12.221 19.355 10.6675C20.1348 9.88785 20.6992 9.10649 21.0675 8.52172C21.194 8.32069 21.2968 8.14397 21.3765 7.99995C21.2968 7.85588 21.194 7.67909 21.0674 7.47797C20.6992 6.89306 20.1347 6.11152 19.355 5.3317C17.8013 3.77785 15.417 2.24927 11.9996 2.24927C8.58221 2.24927 6.19793 3.77785 4.64424 5.3317C3.86451 6.11152 3.30003 6.89306 2.93179 7.47797C2.80518 7.67909 2.70244 7.85588 2.62274 7.99995Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { VisibleIcon }
