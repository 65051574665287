import {
  GET_APP_USERS,
  INVITE_APP_USER,
  RESEND_INVITE_APP_USER,
  UNINVITE_APP_USER,
} from '../../reducers/appUserSlice'
import { RootState } from '../../store'
import { getAppUsers, inviteAppUser, resendInviteAppUser, unInviteAppUser } from '../settingsApi'
import { createUseRequest } from './createUseRequest'

export const useGetAppUsers = createUseRequest({
  successActionCreator: GET_APP_USERS,
  apiCall: getAppUsers,
  selector: (state: RootState, panelId: string) => state.appUsers[panelId]?.users,
  requirePanelId: true,
})

export const useInviteAppUser = createUseRequest({
  successActionCreator: INVITE_APP_USER,
  apiCall: inviteAppUser,
  requirePanelId: true,
})

export const useUnInviteAppUser = createUseRequest({
  successActionCreator: UNINVITE_APP_USER,
  apiCall: unInviteAppUser,
  requirePanelId: true,
})

export const useResendInviteAppUser = createUseRequest({
  successActionCreator: RESEND_INVITE_APP_USER,
  apiCall: resendInviteAppUser,
  requirePanelId: true,
})
