import { yupResolver } from '@hookform/resolvers/yup'
import { CountryCode } from 'libphonenumber-js'
import React, { useEffect, useImperativeHandle } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'
import { AvatarIcon } from '../../../assets/icons/crow'
import { panelCodeValidator, phoneNumberValidator } from '../../../components/forms/validators'
import { getCustomerSupportUrl } from '../../../i18n/supportUrls'
import useI18n from '../../../i18n/useI18n'
import { usePanelInfo } from '../../../services/requestHooks/panelInfo'
import { useUserInfo } from '../../../services/requestHooks/user'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import { Panel, PermanentUser } from '../../../types/PermanentUser'
import { getPanelCountryCode } from '../../../utils/numberField'
import Button from '../../Crow/Button/Button'
import ButtonBox from '../../Crow/ButtonBox'
import KeyBox from '../../Crow/KeyBox'
import { colors } from '../../Crow/Style/theme'
import Tooltip from '../../Crow/Tooltip'
import { Paragraph } from '../../Crow/Typography/Typography'
import { Loader } from '../../Elements/Loaders'
import UserInformation from './UserInformation'
import UserPrivileges from './UserPrivileges'
import UserSettings from './UserSettings'

export interface PeopleEditFormRef {
  handleReSubmit?: (() => void) | undefined
}

interface Props {
  user: PermanentUser
  showEditUserInformation: boolean
  panelIsArmed: boolean
  panelPermissions: Panel
  panelCodeLength: number
  onSubmit: SubmitHandler<any>
  onUserInfoSubmit: SubmitHandler<any>
  onUserInfoCancel: () => void
  setShowPanelCodeModal: (val: boolean) => void
  onRemoveClick: any
  isRemoving: boolean
  title?: TranslationKey
  className?: string
  isLoading: boolean
  cancelAction?: () => void

  outsideRef: React.MutableRefObject<PeopleEditFormRef>
}

const PeopleEditForm = ({
  user,
  showEditUserInformation,
  panelIsArmed,
  panelPermissions,
  panelCodeLength,
  onSubmit,
  onUserInfoSubmit,
  onUserInfoCancel,
  setShowPanelCodeModal,
  onRemoveClick,
  isRemoving,
  title,
  className,
  isLoading,
  cancelAction,
  outsideRef,
}: Props) => {
  const { t } = useI18n()

  const { data: panelInfo } = usePanelInfo()
  const countryCode: CountryCode = getPanelCountryCode(panelInfo?.PanelId || 'noPanelId')

  const resolver = yup.object({
    FirstName: yup
      .string()
      .min(2, t('error_first_name_at_least_2'))
      .max(32, t('error_first_name_length'))
      .trim(),
    LastName: yup
      .string()
      .min(2, t('error_last_name_at_least_2'))
      .max(32, t('error_last_name_length'))
      .trim(),
    IsChild: yup.boolean(),
    IsSystemUser: yup.boolean(),
    IsAppUser: yup.boolean(),
    IsAdminUser: yup.boolean(),
    InvitePhoneNumber: user.Accesses.IsAppUser
      ? phoneNumberValidator(t)
      : phoneNumberValidator(t).when('IsAppUser', {
          is: true,
          then: yup.string().required(t('error_phone_number_required')),
        }),
    PanelPinCode: user.Accesses.IsSystemUser
      ? panelCodeValidator(panelCodeLength, t)
      : panelCodeValidator(panelCodeLength, t).when('IsSystemUser', {
          is: true,
          then: yup.string().required(t('error_pin_code_required')),
        }),
  })

  const defaultValues = {
    FirstName: user.FirstName,
    LastName: user.LastName,
    IsChild: user.Accesses.IsChild,
    IsSystemUser: user.Accesses.IsSystemUser,
    IsAppUser: user.Accesses.IsAppUser,
    IsAdminUser: user.Accesses.IsAdminUser,
    InvitePhoneNumber: '',
    PanelPinCode: '',
  }

  const {
    register,
    control,
    formState: { dirtyFields, errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(resolver),
  })

  const isUnderage = watch('IsChild')
  const hasSystemAccess = watch('IsSystemUser')
  const hasAppAccess = watch('IsAppUser')
  const hasAdminAccess = watch('IsAdminUser')

  const userInformationIsDirty =
    dirtyFields.hasOwnProperty('FirstName') || dirtyFields.hasOwnProperty('LastName')
  const formIsDirty =
    dirtyFields.hasOwnProperty('IsChild') ||
    dirtyFields.hasOwnProperty('IsSystemUser') ||
    dirtyFields.hasOwnProperty('IsAppUser') ||
    dirtyFields.hasOwnProperty('IsAdminUser') ||
    dirtyFields.hasOwnProperty('InvitePhoneNumber') ||
    dirtyFields.hasOwnProperty('PanelPinCode')

  const {
    IsLegalOwner: loggedInUserIsLegalOwner,
    IsAdminUser: loggedInUserIsAdminUser,
  } = panelPermissions.Accesses
  const readOnly: boolean = !(loggedInUserIsLegalOwner || loggedInUserIsAdminUser)

  const shouldBeDisabled = (fieldName: 'IsSystemUser' | 'IsAdminUser' | 'IsAppUser') => {
    return (
      readOnly ||
      (panelIsArmed && fieldName === 'IsSystemUser') ||
      (isUnderage && fieldName === 'IsAdminUser') ||
      (!hasSystemAccess && (fieldName === 'IsAppUser' || fieldName === 'IsAdminUser')) ||
      user.Accesses.IsLegalOwner
    )
  }

  const initiallyHasSystemAccess: boolean = defaultValues!.IsSystemUser
  const initiallyHasAppAccess: boolean = defaultValues!.IsAppUser

  const currentUser = useUserInfo()
  const isCurrentUser: boolean = currentUser.Id === user.PersonId

  const canChangePanelPinCode: boolean = isCurrentUser || !readOnly

  const handleReSubmit = () => {
    handleSubmit(onSubmit)()
  }

  useImperativeHandle(
    outsideRef,
    () => ({
      handleReSubmit,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleReSubmit, onSubmit],
  )

  const canRemoveUser: boolean =
    // you have to be the owner or an admin
    (loggedInUserIsLegalOwner || loggedInUserIsAdminUser) &&
    // you can't remove yourself
    !isCurrentUser &&
    // admins can't remove other admin
    (!user.Accesses.IsAdminUser || loggedInUserIsLegalOwner)

  useEffect(() => {
    if (isUnderage) {
      hasAdminAccess && setValue('IsAdminUser', false)
    }
    // eslint-disable-next-line
  }, [isUnderage])

  useEffect(() => {
    if (!hasSystemAccess) {
      hasAppAccess && setValue('IsAppUser', false)
      setValue('PanelPinCode', '')
    }
    // eslint-disable-next-line
  }, [hasSystemAccess])

  return (
    <S.Wrapper className={className}>
      {showEditUserInformation ? (
        <form onSubmit={handleSubmit(onUserInfoSubmit)} data-testid="form">
          <S.UserContent>
            <UserInformation register={register} errors={errors} />
          </S.UserContent>
          <S.ButtonRow>
            <Button
              type="button"
              onClick={onUserInfoCancel}
              level="secondary"
              size="l"
              disabled={isLoading}
            >
              {t('Cancel')}
            </Button>
            {userInformationIsDirty && (
              <Button level="primary" size="l" disabled={isLoading}>
                {isLoading ? <Loader /> : t('Save')}
              </Button>
            )}
          </S.ButtonRow>
        </form>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
          {title && <h1 className="mb-6 text-2xl">{t(title)}</h1>}
          <S.UserContent>
            {isCurrentUser && (
              <S.AccountInformation>
                {loggedInUserIsLegalOwner ? t('change_user_owner') : t('remove_account')}
                <S.AccountLink to="/account">
                  <AvatarIcon width="16px" />
                  {t('my_profile')}
                </S.AccountLink>
              </S.AccountInformation>
            )}
            <UserSettings register={register} shouldBeDisabled={shouldBeDisabled} />
            <UserPrivileges
              initiallyHasSystemAccess={initiallyHasSystemAccess}
              isUnderage={isUnderage}
              hasSystemAccess={hasSystemAccess}
              canChangePanelPinCode={canChangePanelPinCode}
              panelIsArmed={panelIsArmed}
              register={register}
              shouldBeDisabled={shouldBeDisabled}
              panelCodeLength={panelCodeLength}
              initiallyHasAppAccess={initiallyHasAppAccess}
              hasAppAccess={hasAppAccess}
              control={control}
              countryCode={countryCode}
              setShowPanelCodeModal={setShowPanelCodeModal}
              errors={errors}
            />

            {user.Tag?.Color && <KeyBox text={t('key_tag')} subText={t('owns_key_tag')} />}
          </S.UserContent>
          <S.ButtonRow>
            <Button
              type="button"
              onClick={cancelAction}
              level="secondary"
              size="l"
              disabled={isLoading}
            >
              {t('Cancel')}
            </Button>
            {formIsDirty && (
              <Button level="primary" size="l" disabled={isLoading}>
                {isLoading ? <Loader /> : t('Save')}
              </Button>
            )}
          </S.ButtonRow>
        </form>
      )}
      <S.Other>
        {loggedInUserIsLegalOwner && isCurrentUser && (
          <S.SupportInformation>
            <S.SupportText>{t('remove_account_owner')}</S.SupportText>
            <S.SupportLink
              href={getCustomerSupportUrl(currentUser.NationId)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('contact_support')}
            </S.SupportLink>
          </S.SupportInformation>
        )}
        {canRemoveUser && (
          <Tooltip text={t('system_must_be_disarmed_to_remove')} active={panelIsArmed}>
            <ButtonBox
              text={t('remove_user')}
              textColor={colors.red}
              btnLevel="secondary"
              btnSize="s"
              btnVariant="error"
              onClick={onRemoveClick}
              disabled={panelIsArmed || isRemoving}
            >
              {isRemoving ? <Loader /> : t('remove_user')}
            </ButtonBox>
          </Tooltip>
        )}
      </S.Other>
    </S.Wrapper>
  )
}

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  UserContent: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 48px;
  `,
  AccountInformation: styled(Paragraph)`
    margin-bottom: 32px;
    color: ${colors.black800};
  `,
  AccountLink: styled(NavLink)`
    display: inline flex;
    margin-left: 8px;
    position: relative;
    top: 5px;
    text-decoration: none;
    width: fit-content;
    color: ${colors.blue};

    &:hover {
      color: ${colors.lightBlue};
    }

    & > * {
      margin-right: 3px;
    }
  `,
  UnderAgeInfo: styled.p`
    padding: 0 16px;
    font-size: 16px;
    color: ${colors.black800};
  `,
  ButtonRow: styled.div`
    margin-top: 65px;
    display: flex;
    gap: 24px;

    @media only screen and (max-width: 600px) {
      justify-content: center;
    }
  `,
  Other: styled.div`
    margin-top: 36px;
  `,
  SupportInformation: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  `,
  SupportText: styled(Paragraph)`
    color: ${colors.black800};
  `,
  SupportLink: styled.a`
    text-decoration: none;
    color: ${colors.red};

    &:hover {
      color: ${colors.lightRed};
    }
  `,
}

export default PeopleEditForm
