const BathroomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 30H5V33C5 37.9706 9.02944 42 14 42H34C38.9706 42 43 37.9706 43 33V30H22V36.9972H11V30Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4811 6C36.7973 6 38.7049 7.75002 38.9536 10H38.981V29H35V28C35 27.4477 34.5523 27 34 27C33.4477 27 33 27.4477 33 28V29H31V28.1111C31 27.5588 30.5523 27.1111 30 27.1111C29.4477 27.1111 29 27.5588 29 28.1111V29H27V28C27 27.4477 26.5523 27 26 27C25.4477 27 25 27.4477 25 28V29H23C23 27.8954 22.1046 27 21 27H12C10.8954 27 10 27.8954 10 29H5C4.44772 29 4 29.4477 4 30V33C4 35.9416 5.27011 38.5865 7.29183 40.4163L5.44721 44.1056C5.20022 44.5995 5.40045 45.2002 5.89443 45.4472C6.38841 45.6942 6.98908 45.494 7.23607 45L8.92646 41.6192C10.4138 42.4966 12.1481 43 14 43H34C35.8519 43 37.5862 42.4966 39.0735 41.6192L40.7639 45C41.0109 45.494 41.6116 45.6942 42.1056 45.4472C42.5996 45.2002 42.7998 44.5995 42.5528 44.1056L40.7082 40.4163C42.7299 38.5865 44 35.9416 44 33V30C44 29.4477 43.5523 29 43 29H40.981V10H40.9621C40.7068 6.64378 37.9027 4 34.4811 4C31.4075 4 28.8322 6.1333 28.155 9H25C24.4477 9 24 9.44772 24 10C24 10.5523 24.4477 11 25 11V12C25 12.5523 25.4477 13 26 13C26.5523 13 27 12.5523 27 12V11H33V12C33 12.5523 33.4477 13 34 13C34.5523 13 35 12.5523 35 12V11C35.5523 11 36 10.5523 36 10C36 9.44772 35.5523 9 35 9H30.2371C30.8549 7.25221 32.5217 6 34.4811 6ZM21 29H12V31.7271H21V29ZM12 36V33.7271H21V36H12ZM23 31H42V33C42 37.4183 38.4183 41 34 41H14C9.58172 41 6 37.4183 6 33V31H10V38H23V31Z"
        fill="#333131"
      />
      <path
        d="M27 16C27 15.4477 26.5523 15 26 15C25.4477 15 25 15.4477 25 16V18C25 18.5523 25.4477 19 26 19C26.5523 19 27 18.5523 27 18V16Z"
        fill="#333131"
      />
      <path
        d="M26 21C26.5523 21 27 21.4477 27 22V24C27 24.5523 26.5523 25 26 25C25.4477 25 25 24.5523 25 24V22C25 21.4477 25.4477 21 26 21Z"
        fill="#333131"
      />
      <path
        d="M31 13C31 12.4477 30.5523 12 30 12C29.4477 12 29 12.4477 29 13V13.8889C29 14.4412 29.4477 14.8889 30 14.8889C30.5523 14.8889 31 14.4412 31 13.8889V13Z"
        fill="#333131"
      />
      <path
        d="M30 16.4444C30.5523 16.4444 31 16.8922 31 17.4444V19.2222C31 19.7745 30.5523 20.2222 30 20.2222C29.4477 20.2222 29 19.7745 29 19.2222V17.4444C29 16.8922 29.4477 16.4444 30 16.4444Z"
        fill="#333131"
      />
      <path
        d="M31 22.7778C31 22.2255 30.5523 21.7778 30 21.7778C29.4477 21.7778 29 22.2255 29 22.7778V24.5556C29 25.1078 29.4477 25.5556 30 25.5556C30.5523 25.5556 31 25.1078 31 24.5556V22.7778Z"
        fill="#333131"
      />
      <path
        d="M34 15C34.5523 15 35 15.4477 35 16V18C35 18.5523 34.5523 19 34 19C33.4477 19 33 18.5523 33 18V16C33 15.4477 33.4477 15 34 15Z"
        fill="#333131"
      />
      <path
        d="M35 22C35 21.4477 34.5523 21 34 21C33.4477 21 33 21.4477 33 22V24C33 24.5523 33.4477 25 34 25C34.5523 25 35 24.5523 35 24V22Z"
        fill="#333131"
      />
    </svg>
  )
}

export { BathroomIcon }
