import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import { usePanelInfo } from '../../../services/requestHooks/panelInfo'
import { useOnMount } from '../../../utils/commonHooks'
import { usePanelId } from '../hooks/usePanelId'
import SettingsMenu from './components/SettingsMenu'
import Toast from '../../../components/Crow/Toast/Toast'

const SettingsScreen = () => {
  const panelId = usePanelId()
  const { data: panelInfo, run: loadPanelInfo, isLoading: loadingPanelInfo } = usePanelInfo()

  useOnMount(() => {
    if (!loadingPanelInfo && !panelInfo) {
      loadPanelInfo(panelId)
    }
  })

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen>
      <SettingsMenu panelInfo={panelInfo} loading={loadingPanelInfo} />
      <Toast />
    </TcOrCrowContainer>
  )
}

export default SettingsScreen
