export enum WifiEncryptionType {
  NOT_ENCRYPTED = '0',
  WEP = '1',
  WPA = '2',
}

export type WifiDetails = {
  Name: string
  Connected: 'true' | 'false' | 'True' | 'False'
  RadioLevel: string
  EncryptionType: WifiEncryptionType
  SsidHidden: string
}
