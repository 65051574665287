const ItServerRoomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 37C19 37.5523 18.5523 38 18 38C17.4477 38 17 37.5523 17 37C17 36.4477 17.4477 36 18 36C18.5523 36 19 36.4477 19 37Z"
        fill="#333131"
      />
      <path
        d="M15 37C15 37.5523 14.5523 38 14 38C13.4477 38 13 37.5523 13 37C13 36.4477 13.4477 36 14 36C14.5523 36 15 36.4477 15 37Z"
        fill="#333131"
      />
      <path
        d="M11 29C11 29.5523 10.5523 30 10 30C9.44772 30 9 29.5523 9 29C9 28.4477 9.44772 28 10 28C10.5523 28 11 28.4477 11 29Z"
        fill="#333131"
      />
      <path
        d="M18 30C18.5523 30 19 29.5523 19 29C19 28.4477 18.5523 28 18 28C17.4477 28 17 28.4477 17 29C17 29.5523 17.4477 30 18 30Z"
        fill="#333131"
      />
      <path
        d="M15 21C15 21.5523 14.5523 22 14 22C13.4477 22 13 21.5523 13 21C13 20.4477 13.4477 20 14 20C14.5523 20 15 20.4477 15 21Z"
        fill="#333131"
      />
      <path
        d="M11 13C11 13.5523 10.5523 14 10 14C9.44772 14 9 13.5523 9 13C9 12.4477 9.44772 12 10 12C10.5523 12 11 12.4477 11 13Z"
        fill="#333131"
      />
      <path
        d="M15 13C15 13.5523 14.5523 14 14 14C13.4477 14 13 13.5523 13 13C13 12.4477 13.4477 12 14 12C14.5523 12 15 12.4477 15 13Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 41V9C5 8.44772 5.44772 8 6 8H22C22.5523 8 23 8.44772 23 9V41C23 41.5523 22.5523 42 22 42H6C5.44772 42 5 41.5523 5 41ZM7 40V34H21V40H7ZM7 26H21V32H7V26ZM7 18H21V24H7V18ZM21 16H7V10H21V16Z"
        fill="#333131"
      />
      <path
        d="M38 14C38.5523 14 39 13.5523 39 13C39 12.4477 38.5523 12 38 12C37.4477 12 37 12.4477 37 13C37 13.5523 37.4477 14 38 14Z"
        fill="#333131"
      />
      <path
        d="M34 14C34.5523 14 35 13.5523 35 13C35 12.4477 34.5523 12 34 12C33.4477 12 33 12.4477 33 13C33 13.5523 33.4477 14 34 14Z"
        fill="#333131"
      />
      <path
        d="M31 37C31 37.5523 30.5523 38 30 38C29.4477 38 29 37.5523 29 37C29 36.4477 29.4477 36 30 36C30.5523 36 31 36.4477 31 37Z"
        fill="#333131"
      />
      <path
        d="M38 38C38.5523 38 39 37.5523 39 37C39 36.4477 38.5523 36 38 36C37.4477 36 37 36.4477 37 37C37 37.5523 37.4477 38 38 38Z"
        fill="#333131"
      />
      <path
        d="M30 30C30.5523 30 31 29.5523 31 29C31 28.4477 30.5523 28 30 28C29.4477 28 29 28.4477 29 29C29 29.5523 29.4477 30 30 30Z"
        fill="#333131"
      />
      <path
        d="M34 30C34.5523 30 35 29.5523 35 29C35 28.4477 34.5523 28 34 28C33.4477 28 33 28.4477 33 29C33 29.5523 33.4477 30 34 30Z"
        fill="#333131"
      />
      <path
        d="M39 21C39 21.5523 38.5523 22 38 22C37.4477 22 37 21.5523 37 21C37 20.4477 37.4477 20 38 20C38.5523 20 39 20.4477 39 21Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 9C25 8.44772 25.4477 8 26 8H42C42.5523 8 43 8.44772 43 9V41C43 41.5523 42.5523 42 42 42H26C25.4477 42 25 41.5523 25 41V9ZM41 10V16H27V10H41ZM27 34V40H41V34H27ZM41 32H27V26H41V32ZM41 24H27V18H41V24Z"
        fill="#333131"
      />
      <path
        d="M3 44C3 43.4477 3.44772 43 4 43H44C44.5523 43 45 43.4477 45 44C45 44.5523 44.5523 45 44 45H4C3.44772 45 3 44.5523 3 44Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 40V34H21V40H7ZM15 37C15 37.5523 14.5523 38 14 38C13.4477 38 13 37.5523 13 37C13 36.4477 13.4477 36 14 36C14.5523 36 15 36.4477 15 37ZM19 37C19 37.5523 18.5523 38 18 38C17.4477 38 17 37.5523 17 37C17 36.4477 17.4477 36 18 36C18.5523 36 19 36.4477 19 37Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 26H21V32H7V26ZM10 30C10.5523 30 11 29.5523 11 29C11 28.4477 10.5523 28 10 28C9.44772 28 9 28.4477 9 29C9 29.5523 9.44772 30 10 30ZM18 30C18.5523 30 19 29.5523 19 29C19 28.4477 18.5523 28 18 28C17.4477 28 17 28.4477 17 29C17 29.5523 17.4477 30 18 30Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 18H21V24H7V18ZM14 22C14.5523 22 15 21.5523 15 21C15 20.4477 14.5523 20 14 20C13.4477 20 13 20.4477 13 21C13 21.5523 13.4477 22 14 22Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 16H7V10H21V16ZM11 13C11 13.5523 10.5523 14 10 14C9.44772 14 9 13.5523 9 13C9 12.4477 9.44772 12 10 12C10.5523 12 11 12.4477 11 13ZM14 14C14.5523 14 15 13.5523 15 13C15 12.4477 14.5523 12 14 12C13.4477 12 13 12.4477 13 13C13 13.5523 13.4477 14 14 14Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 10V16H27V10H41ZM39 13C39 13.5523 38.5523 14 38 14C37.4477 14 37 13.5523 37 13C37 12.4477 37.4477 12 38 12C38.5523 12 39 12.4477 39 13ZM34 14C34.5523 14 35 13.5523 35 13C35 12.4477 34.5523 12 34 12C33.4477 12 33 12.4477 33 13C33 13.5523 33.4477 14 34 14Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 34V40H41V34H27ZM30 38C30.5523 38 31 37.5523 31 37C31 36.4477 30.5523 36 30 36C29.4477 36 29 36.4477 29 37C29 37.5523 29.4477 38 30 38ZM38 38C38.5523 38 39 37.5523 39 37C39 36.4477 38.5523 36 38 36C37.4477 36 37 36.4477 37 37C37 37.5523 37.4477 38 38 38Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 32H27V26H41V32ZM31 29C31 29.5523 30.5523 30 30 30C29.4477 30 29 29.5523 29 29C29 28.4477 29.4477 28 30 28C30.5523 28 31 28.4477 31 29ZM34 30C34.5523 30 35 29.5523 35 29C35 28.4477 34.5523 28 34 28C33.4477 28 33 28.4477 33 29C33 29.5523 33.4477 30 34 30Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 24H27V18H41V24ZM38 22C38.5523 22 39 21.5523 39 21C39 20.4477 38.5523 20 38 20C37.4477 20 37 20.4477 37 21C37 21.5523 37.4477 22 38 22Z"
        fill="#E3E1E1"
      />
    </svg>
  )
}

export { ItServerRoomIcon }
