const HistoryIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5398 1.45412C12.8496 0.754008 10.9897 0.570827 9.1954 0.92774C7.40108 1.28465 5.75288 2.16563 4.45925 3.45927L2.98431 4.9342V3.59839C2.98431 3.0461 2.5366 2.59839 1.98431 2.59839C1.43203 2.59839 0.984314 3.0461 0.984314 3.59839V7.34839C0.984314 7.52063 1.02786 7.68271 1.10455 7.8242C1.12706 7.86579 1.15274 7.90612 1.18159 7.94484C1.23787 8.02046 1.30458 8.08786 1.3796 8.1449C1.42649 8.18059 1.47581 8.21159 1.52692 8.23789C1.66399 8.30852 1.8195 8.34839 1.98431 8.34839H5.73431C6.2866 8.34839 6.73431 7.90067 6.73431 7.34839C6.73431 6.7961 6.2866 6.34839 5.73431 6.34839H4.39855L5.87346 4.87348C6.88739 3.85955 8.17922 3.16905 9.58558 2.88931C10.9919 2.60957 12.4497 2.75314 13.7744 3.30188C15.0992 3.85061 16.2315 4.77986 17.0281 5.97212C17.8248 7.16438 18.25 8.56609 18.25 10C18.25 11.4339 17.8248 12.8356 17.0281 14.0279C16.2315 15.2201 15.0992 16.1494 13.7744 16.6981C12.4497 17.2469 10.9919 17.3904 9.58558 17.1107C8.17922 16.831 6.88739 16.1405 5.87346 15.1265C5.48294 14.736 4.84977 14.736 4.45925 15.1265C4.06872 15.5171 4.06872 16.1502 4.45925 16.5407C5.75288 17.8344 7.40108 18.7154 9.1954 19.0723C10.9897 19.4292 12.8496 19.246 14.5398 18.5459C16.23 17.8458 17.6747 16.6602 18.6911 15.139C19.7075 13.6179 20.25 11.8295 20.25 10C20.25 8.17053 19.7075 6.38213 18.6911 4.86098C17.6747 3.33982 16.23 2.15423 14.5398 1.45412Z"
        fill="currentColor"
      />
      <path
        d="M10.9999 4.49994C11.5522 4.49994 11.9999 4.94765 11.9999 5.49994V9.42261L15.3971 11.384C15.8754 11.6601 16.0393 12.2717 15.7631 12.75C15.487 13.2283 14.8754 13.3922 14.3971 13.116L10.5158 10.8751C10.4827 10.8568 10.4507 10.8366 10.42 10.8147C10.3017 10.7307 10.2065 10.6245 10.1368 10.5053C10.0624 10.3784 10.0152 10.2338 10.003 10.0792C10.0005 10.0474 9.99944 10.0155 9.99994 9.98343V5.49994C9.99994 4.94765 10.4477 4.49994 10.9999 4.49994Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { HistoryIcon }
