import React, { FC } from 'react'
import Button from '../components/Button'
import BackgroundImagePage from '../components/pages/BackgroundImagePage'
import useI18n from '../i18n/useI18n'
import { useAuth } from '../services/authentication/AuthContext'
import { useChallengeAccount } from '../services/requestHooks/user'
import { useOnMount } from '../utils/commonHooks'
const getNonce = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('nonce') || ''
}

const ChallengeAccountPage: FC = () => {
  const { t } = useI18n()
  const { login } = useAuth()
  const { run, isLoading, error } = useChallengeAccount()

  useOnMount(() => {
    run(getNonce())
  })

  return (
    <BackgroundImagePage isLoading={isLoading}>
      <>
        {!error ? (
          <>
            <h1 className="mb-16">{t('account_has_been_activated')}</h1>
            <Button
              buttonType="third"
              onClick={() => {
                login('/login')
              }}
            >
              {t('Go to login')}
            </Button>
          </>
        ) : (
          <h1 className="mb-16">
            {t('You could not validate your account. Did you enter the wrong adress?')}
          </h1>
        )}
      </>
    </BackgroundImagePage>
  )
}

export default ChallengeAccountPage
