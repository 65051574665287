import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { format } from 'date-fns'
import _ from 'lodash'
import { PanelEventForInfiniteScroll, PanelEvent } from '../types/PanelEvent'

type HistoryState = Record<
  string,
  {
    panelEvents: [string, PanelEvent[]][]
  }
>

const initialState: HistoryState = {}

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    RESET_HISTORY(state, { payload: { panelId } }: PayloadAction<{ panelId: string }>) {
      delete state[panelId]
    },
    GET_HISTORY(
      state,
      { payload: { panelId, data } }: PayloadAction<{ panelId: string; data: PanelEvent[] }>,
    ) {
      const groupedEvents = Object.entries(
        _.groupBy(data, (day) => format(new Date(day.Time), 'yyyy-MM-dd')),
      )
      state[panelId] = { panelEvents: groupedEvents }
    },
    GET_HISTORY_INCREMENTALLY(
      state,
      {
        payload: { panelId, data },
      }: PayloadAction<{ panelId: string; data: PanelEventForInfiniteScroll }>,
    ) {
      const prevPanelEvents = state[panelId]?.panelEvents
      const groupedEvents = _.groupBy(data.Records, (day) =>
        format(new Date(day.Time), 'yyyy-MM-dd'),
      )
      if (prevPanelEvents) {
        const prevEventsObject = Object.fromEntries(prevPanelEvents)
        for (const key in groupedEvents) {
          if (prevEventsObject.hasOwnProperty(key)) {
            prevEventsObject[key] = [...prevEventsObject[key], ...groupedEvents[key]]
          } else {
            prevEventsObject[key] = groupedEvents[key]
          }
        }
        state[panelId] = { panelEvents: Object.entries(prevEventsObject) }
      } else {
        state[panelId] = { panelEvents: Object.entries(groupedEvents) }
      }
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const { RESET_HISTORY, GET_HISTORY, GET_HISTORY_INCREMENTALLY } = historySlice.actions

export const historyReducer = historySlice.reducer
