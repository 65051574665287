import { FC, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AddButton from '../../../../../components/Button/AddButton'
import ConfirmationPrompt, {
  useConfirmationPrompt,
} from '../../../../../components/ComfirmationPrompt/ConfirmationPrompt'
import Toast, { addToast } from '../../../../../components/Crow/Toast/Toast'
import FullScreenLoader from '../../../../../components/FullScreenLoader'
import Modal from '../../../../../components/Modal/Modal'
import {
  clearOngoingPrompt,
  TwofactorContext,
} from '../../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../../i18n/useI18n'
import { useGetPanelUsers, useSavePanelUser } from '../../../../../services/requestHooks/panelUsers'
import { TranslationKey } from '../../../../../types/generated/TranslationKey'
import { PanelUserChangeOperation } from '../../../../../types/UserDetails'
import { errorCodesTriggerNew2FA } from '../../../../../utils/errorCodesTriggerNew2FA'
import PasswordPrompt, { usePasswordPrompt } from '../../../Panel/PasswordPromt'
import { UserType } from '../constants/panelUserTypes'
import { User } from '../state/panelUsersSlice'
import PanelUserEdit from './PanelUserEdit'
import PanelUsersListItem from './PanelUsersListItem'

type Props = {
  allowUserChanges: boolean
}

const PanelUsers: FC<Props> = ({ allowUserChanges }) => {
  const { t } = useI18n()
  const { panelId } = useParams<{ panelId: string }>()

  const { promptForTwoFactor } = useContext(TwofactorContext)
  const { promptState, promptForPassword } = usePasswordPrompt()
  const { promptState: confirmationPromptState, prompt: promptDelete } = useConfirmationPrompt()

  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)
  const [editingInfo, setEditingInfo] = useState<
    | {
        user: User
        operation: PanelUserChangeOperation
      }
    | undefined
  >(undefined)
  const [userToDelete, setUserToDelete] = useState<Omit<User, 'HasTag'> | undefined>(undefined)

  const { run: getPanelUsers, data: panelUsers } = useGetPanelUsers()
  const { run: saveUser, isLoading: isSavingPanelUser, error: saveUserError } = useSavePanelUser({
    onSuccess: () => {
      getPanelUsers(panelId)
    },
  })

  const { Users: users = [], CodeLength: codeLength } = panelUsers || {}

  const deleteUser = async (user: User) => {
    try {
      setUserToDelete(user)
      await promptDelete()
      const ValidationCode = await promptForTwoFactor()
      if (!ValidationCode) return
      const Password = await promptForPassword()
      if (!Password) return
      saveUser({
        operation: PanelUserChangeOperation.DELETE,
        payload: {
          Password,
          ValidationCode,
          PanelId: panelId,
          UserType: user.Type,
          Name: user.Name,
          Index: user.Index,
        },
      })
    } finally {
      setUserToDelete(undefined)
    }
  }

  useEffect(() => {
    const prompt = async () => {
      await promptForTwoFactor()
      addToast({
        type: 'warning',
        time: 20000,
        title: t('warning_toast_generic_title'),
        content: t('2fa_expired_and_refreshed'),
      })
    }

    if (saveUserError) {
      const toastError = t(saveUserError)
      if (errorCodesTriggerNew2FA.includes(saveUserError.toLocaleLowerCase())) {
        clearOngoingPrompt()
        prompt()
      } else {
        addToast({
          type: 'error',
          time: 5000,
          title: t('error_toast_generic_title'),
          content: `${t('error_toast_specific_content', { toastError })}`,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveUserError])

  const handleCancelEdit = () => setEditingInfo(undefined)

  if (isSavingPanelUser) {
    return <FullScreenLoader text="Updating user" />
  }

  const maxNumberOfUsersReached = users.length >= 9

  return (
    <>
      <div className="status-list padding-bottom">
        <h1 className="mb-4 mt-2">{t('Users and codes')}</h1>
        {!allowUserChanges && (
          <p className="notification notification--error notification--align-left">
            {t('CAN_NOT_MOFIFY_PANELUSER_WHEN_ARMED')}
          </p>
        )}
        {maxNumberOfUsersReached && (
          <p className="notification notification--error notification--align-left">
            {t('Available slots execeded. Please delete a user to continue.')}
          </p>
        )}
        <div className="status-list">
          <h4 className="gray mt-8">{t('Permanent user')}</h4>
          <hr />
          {users
            .filter((u) => u.Type === UserType.PERMANENT_USER)
            .map((user) => (
              <PanelUsersListItem
                selected={user.Index === selectedUser?.Index}
                onSelect={() => setSelectedUser(user)}
                onEdit={(operation: PanelUserChangeOperation) =>
                  setEditingInfo({ user, operation })
                }
                onDelete={() => deleteUser(user)}
                user={user}
                saveUser={saveUser}
                key={user.Index}
                disabled={!allowUserChanges}
              />
            ))}
          <AddPanelUserButton
            text="Add permanent user"
            helpText="Typically used for family members or other trustees."
            onClick={() =>
              setEditingInfo({
                user: {
                  Name: '',
                  Type: UserType.PERMANENT_USER,
                  IsActive: true,
                },
                operation: PanelUserChangeOperation.ADD,
              })
            }
            disabled={!allowUserChanges || maxNumberOfUsersReached}
            testId="add-permanent-user"
          />
        </div>
        <div className="status-list">
          <h4 className="gray margin-top--large">{t('24-hour visitors')}</h4>
          <hr />
          {users
            .filter((u) => u.Type === UserType.TWENTY_FOUR_HOUR_USER)
            .map((user) => (
              <PanelUsersListItem
                selected={user.Index === selectedUser?.Index}
                onSelect={() => setSelectedUser(user)}
                onEdit={(operation: PanelUserChangeOperation) =>
                  setEditingInfo({ user, operation })
                }
                onDelete={() => deleteUser(user)}
                user={user}
                key={user.Index}
                saveUser={saveUser}
                disabled={!allowUserChanges}
              />
            ))}
          <AddPanelUserButton
            text={'Add 24 hour user'}
            helpText={'24-hours users are given a panel code that lasts for 24 hours.'}
            onClick={() =>
              setEditingInfo({
                user: {
                  Name: '',
                  Type: UserType.TWENTY_FOUR_HOUR_USER,
                  IsActive: true,
                },
                operation: PanelUserChangeOperation.ADD,
              })
            }
            disabled={!allowUserChanges || maxNumberOfUsersReached}
            testId="add-24h-user"
          />
        </div>
      </div>
      {!!editingInfo && !!codeLength && (
        <Modal handleClose={handleCancelEdit}>
          <PanelUserEdit
            codeLength={codeLength}
            user={editingInfo.user}
            operation={editingInfo.operation}
            cancelEdit={handleCancelEdit}
          />
        </Modal>
      )}
      {!!userToDelete && (
        <ConfirmationPrompt promptState={confirmationPromptState}>
          <h2 className="text-2xl margin-bottom">
            {t('Sure you want to delete')} {userToDelete?.Name}?
          </h2>
        </ConfirmationPrompt>
      )}
      <PasswordPrompt promptState={promptState} />
      <Toast />
    </>
  )
}

interface AddPanelUserButtonProps {
  onClick: () => void
  text: TranslationKey
  helpText: TranslationKey
  disabled?: boolean
  testId?: string
}

const AddPanelUserButton = ({
  onClick,
  text,
  helpText,
  disabled,
  testId,
}: AddPanelUserButtonProps) => {
  const { t } = useI18n()
  return (
    <AddButton className="mt-4 flex" onClick={onClick} disabled={disabled} data-testid={testId}>
      <div>
        <span className="add-new__text">{t(text)}</span>
        <span className="add-new__help-text">{t(helpText)}</span>
      </div>
    </AddButton>
  )
}

export default PanelUsers
