import classNames from 'classnames'
import React, { FC } from 'react'
import './crow-card.scss'

type CrowCardProps = {
  as?: keyof JSX.IntrinsicElements
  border?: boolean
  shadow?: boolean
  size?: string
  className?: string
  onClick?: () => void
}

const CrowCard: FC<CrowCardProps> = ({
  children,
  as: Component = 'div',
  border = false,
  shadow = false,
  size,
  className,
  onClick,
}) => {
  return (
    <Component
      className={classNames(
        'crow-card',
        border && 'crow-card--border',
        shadow && 'crow-card--shadow',
        size && `crow-card--${size}`,
        className,
      )}
      onClick={onClick}
    >
      {children}
    </Component>
  )
}

export default CrowCard
