import { useState } from 'react'
import styled from 'styled-components'
import { default as DefaultCard } from '../../../../components/Crow/Card/Card'
import { colors } from '../../../../components/Crow/Style/theme'
import { Panel, PermanentUser } from '../../../../types/PermanentUser'
import CategoryTag from './CategoryTag'
import EditUserDrawer from './EditUserDrawer'
import Permissions from './Permissions'

interface Props {
  user: PermanentUser
  panelIsArmed: boolean
  panelPermissions: Panel
}

const Person = ({ user, panelIsArmed, panelPermissions }: Props) => {
  const [displayModal, setModalVisibility] = useState<boolean>(false)

  return (
    <Wrapper>
      <Card onClick={() => setModalVisibility(true)}>
        <Top>
          <Name>{`${user.FirstName} ${user.LastName}`}</Name>
          <CategoryTag user={user} />
        </Top>
        <Bottom>
          <Permissions user={user} />
        </Bottom>
      </Card>
      <EditUserDrawer
        user={user}
        panelIsArmed={panelIsArmed}
        panelPermissions={panelPermissions}
        isOpen={displayModal}
        handleClose={() => setModalVisibility(false)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.li`
  margin: 0;
`

const Card = styled(DefaultCard)`
  display: flex;
  flex-direction: column;
  min-height: 132px;
  height: auto;
`

const Top = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #e4e3e3;
  width: 100%;
`

const Name = styled.span`
  font-family: 'Metric';
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  color: ${colors.black900};
`

const Bottom = styled.div`
  padding-top: 16px;
  height: auto;
`

export default Person
