import { UPDATE_LEAKAGEDETECTORS } from '../../reducers/leakageDetectorsSlice'
import { RootState } from '../../store'
import { housecheckLeakagedetectors } from '../apiHouseCheck'
import { createUseRequest } from './createUseRequest'

const selectLeakageDetectors = (state: RootState, panelId: string) =>
  state.leakageDetectors[panelId]?.leakageDetectors

export const useLeakageDetectors = createUseRequest({
  successActionCreator: UPDATE_LEAKAGEDETECTORS,
  apiCall: housecheckLeakagedetectors,
  selector: selectLeakageDetectors,
  requirePanelId: true,
})
