import {
  DELETE_CUSTOMER_FEEDBACK,
  GET_CUSTOMER_FEEDBACK_STATUS,
  SEND_CUSTOMER_FEEDBACK,
} from '../../reducers/customerFeedbackSlice'
import { RootState } from '../../store'
import {
  deleteCustomerFeedback,
  getCustomerFeedbackStatus,
  sendCustomerFeedback,
} from '../apiCustomerFeedback'

import { createUseRequest } from './createUseRequest'

const selectFeedbackStatus = (state: RootState) => state.customerFeedback

export const useGetCustomerFeedbackStatus = createUseRequest({
  successActionCreator: GET_CUSTOMER_FEEDBACK_STATUS,
  apiCall: getCustomerFeedbackStatus,
  selector: selectFeedbackStatus,
})

export const useSendCustomerFeedback = createUseRequest({
  successActionCreator: SEND_CUSTOMER_FEEDBACK,
  apiCall: sendCustomerFeedback,
})

export const useDeleteCustomerFeedback = createUseRequest({
  successActionCreator: DELETE_CUSTOMER_FEEDBACK,
  apiCall: deleteCustomerFeedback,
})
