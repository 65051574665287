import React, { ElementRef, forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import ChevronUp from '../../../components/Crow/Assets/icons/ChevronUp.svg'
import { colors } from '../Style/theme'

export interface DropDownType {
  label: ReactNode
  value?: any
  onChange: any
  onBlur?: any
  name?: string
  disabled?: boolean
  className?: string
  children: ReactNode
}

const DropDownList: ForwardRefRenderFunction<ElementRef<'select'>, DropDownType> = (
  { label, onChange, onBlur, name, disabled, className, children },
  ref,
) => {
  return (
    <Wrapper className={className}>
      <Label htmlFor="dropdown">{label}</Label>
      <Select
        ref={ref}
        id="dropdown"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        className="qa-dropdown-field"
      >
        {children}
      </Select>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.label`
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: ${colors.black800};
`

const Select = styled.select<{ disabled?: boolean }>`
  padding: 11px;
  border-radius: 12px;
  box-shadow: 0 0 0 1px ${colors.black200};
  height: 62px;
  background-color: ${colors.white};
  appearance: none;
  background-image: url(${ChevronUp});
  background-repeat: no-repeat;
  background-position: 96.5% 50%;
  font-size: 20px;
  line-height: 24px;
  color: ${({ disabled }) => (disabled ? colors.black500 : colors.black800)};
  ${({ disabled }) => {
    return (
      disabled &&
      css`
        background-color: ${colors.black200};
        pointer-events: none;
      `
    )
  }}

  &:focus {
    box-shadow: 0 0 0 2px ${colors.blue};
    outline: none;
  }
`

export default forwardRef(DropDownList)
