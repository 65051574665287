import * as React from 'react'

const TagIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31" {...props}>
      <path
        fill="#575756"
        d="M15.5 23.5c-4 0-5.8-7.4-5.8-10.2 0-3.2 2.6-5.8 5.8-5.8s5.8 2.6 5.8 5.8c0 2.8-1.8 10.2-5.8 10.2zm0-15.2c-2.8 0-5 2.3-5 5s1.8 9.4 5 9.4 5-6.7 5-9.4c0-2.8-2.2-5-5-5z"
      />
      <path
        fill="#575756"
        d="M15.5 21.7c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm0-2.3c-.4 0-.7.3-.7.7 0 .4.3.7.7.7.4 0 .7-.3.7-.7s-.3-.7-.7-.7z"
      />
    </svg>
  )
}

export { TagIcon }
