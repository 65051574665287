import classNames from 'classnames'
import { format } from 'date-fns'
import { ca, enUS, es, fi, fr, it, nb, pt, sv } from 'date-fns/locale'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { HomeIcon, SettingsIcon } from '../../assets/icons'
import { AccessRevoked } from '../../components/Crow/InfoMessage/AccessRevoked'
import NavBarPage from '../../components/pages/NavBarPage'
import CustomerServiceInfo from '../../components/system/CustomerServiceInfo'
import useI18n from '../../i18n/useI18n'
import { System } from '../../reducers/systemListSlice'
import { useSystemList } from '../../services/requestHooks/systemList'
import { useUserInfo } from '../../services/requestHooks/user'
import { AccessGroupType } from '../../types/AppUser'
import { TranslationKey } from '../../types/generated/TranslationKey'
import { InstallationStatus as InstallationStatusEnum, PanelStatus } from '../../types/PanelModel'
import { useOnMount } from '../../utils/commonHooks'

const PanelListPage: FC = () => {
  const { run, isLoading, data: systems } = useSystemList()
  const { t } = useI18n()
  const ownedSystems = systems?.filter((it) => it.AccessGroup === AccessGroupType.LEGAL_OWNER) || []
  const userSystems = systems?.filter((it) => it.AccessGroup === AccessGroupType.USER) || []
  const adminSystems =
    systems?.filter((it) => it.AccessGroup === AccessGroupType.ADMINISTRATOR) || []

  const shownCategories = [ownedSystems, userSystems, adminSystems].filter(
    (systemGroup) => !!systemGroup.length,
  )
  const showHeaders: boolean = shownCategories.length > 1

  useOnMount(() => {
    run()
  })

  return (
    <NavBarPage isLoading={isLoading}>
      <h1 className="t-only-screenreaders">{t('Alarm systems')}</h1>
      {!systems?.length ? (
        <AccessRevoked />
      ) : (
        <>
          {!!ownedSystems.length && showHeaders && <ListHeading text={'LEGAL_OWNER'} />}
          <ul className="no-padding">
            {ownedSystems.map((panel) => (
              <AlarmSystemListItem key={panel.PanelId} panel={panel} />
            ))}
          </ul>
          {!!adminSystems.length && showHeaders && <ListHeading text={'ADMINISTRATOR'} />}
          <ul className="no-padding">
            {adminSystems.map((panel) => (
              <AlarmSystemListItem key={panel.PanelId} panel={panel} />
            ))}
          </ul>
          {!!userSystems.length && showHeaders && <ListHeading text={'USER'} />}
          <ul className="no-padding">
            {userSystems.map((panel) => (
              <AlarmSystemListItem key={panel.PanelId} panel={panel} />
            ))}
          </ul>
        </>
      )}
      <CustomerServiceInfo />
    </NavBarPage>
  )
}

interface AlarmSystemListItemProps {
  panel: System
}

const ListHeading = ({ text }: { text: 'ADMINISTRATOR' | 'LEGAL_OWNER' | 'USER' }) => {
  const { t } = useI18n()
  return (
    <div className="grid bg-gray-200 border-t-2 border-b-2 border-gray-200 border px-8">
      <h2 className="grid__two-third pb-4 pt-2">{t(text)}</h2>
    </div>
  )
}

const getPanelStatusText = (panel: System): TranslationKey => {
  const { Status, InstallationStatus } = panel
  if (InstallationStatus === InstallationStatusEnum.READY_FOR_ACTIVATION)
    return 'UNBOOKED_INSTALLATION'
  if (InstallationStatus === InstallationStatusEnum.PLANNED)
    return 'System is scheduled for instalation'
  if (Status === PanelStatus.TURNED_OFF) return 'Turned off'
  if (Status === PanelStatus.HOME_MODE) return 'Home mode'
  return 'Fully armed'
}

const AlarmSystemListItem = ({ panel }: AlarmSystemListItemProps) => {
  const { t } = useI18n()
  const { Status, PanelId, InstallationStatus } = panel
  const panelStatusText = getPanelStatusText(panel)

  const account = useUserInfo()
  const getLocale = () => {
    switch (account.UserCultureInfo) {
      case 'es-ES':
        return es
      case 'sv-SE':
        return sv
      case 'nb-NO':
        return nb
      case 'fi-FI':
        return fi
      case 'fr-FR':
        return fr
      case 'ca-ES':
        return ca
      case 'it-IT':
        return it
      case 'en-US':
        return enUS
      case 'pt-PT':
        return pt
      default:
        return enUS
    }
  }

  const panelStatusDate = () => {
    if (InstallationStatus === InstallationStatusEnum.PLANNED) {
      return ''
    }
    return format(new Date(panel.PanelTime), 'd. MMMM p', {
      locale: getLocale(),
    })
  }

  return (
    <li className="system">
      <Link className="no-underline" to={`/systems/${PanelId}`}>
        <div className="grid md:grid-cols-3">
          <div className="grid__two-third system__item">
            <div className="system__sidebar">
              <div
                className={classNames('system__icon', {
                  'system__icon--shell-armed': Status === PanelStatus.HOME_MODE,
                  'system__icon--armed': Status === PanelStatus.FULLY_ARMED,
                })}
              >
                {InstallationStatus !== InstallationStatusEnum.ACTIVE ? (
                  <SettingsIcon />
                ) : (
                  <HomeIcon />
                )}
              </div>
            </div>
            <div className="system__header">
              <h1 className="system__title">{panel.DisplayName}</h1>
              <div>{`${t('LEGAL_OWNER')}: ${panel.LegalOwnerName}`}</div>
              <div>{`${t(panelStatusText)} ${panelStatusDate()}`}</div>
            </div>
          </div>
        </div>
      </Link>
    </li>
  )
}

export default PanelListPage
