/* eslint-disable */
import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { SignalIcon } from '../../../../assets/icons/crow'
import { usePanelId } from '../../../../pages/AlarmSystems/hooks/usePanelId'
import { usePanelStatus } from '../../../../services/requestHooks/panelInfo'
import ArmingStatusText from '../../../../utils/ArmingStatusText'
import { useOnMount } from '../../../../utils/commonHooks'
import Drawer from '../../Drawer/Drawer'
import PropertySelect from '../../PropertySelect/PropertySelect'
import { colors } from '../../Style/theme'
import { SubTitle } from '../../Typography'
import NavigationMenu from '../NavigationMenu'

type Props = {
  children: ReactNode
  displayName?: string
  padding?: ReactNode
  className?: string
  transparentBackground?: boolean
}

const Tablet = ({ children, displayName, padding, className, transparentBackground }: Props) => {
  const [sidebarExpanded, setSidebarExpanded] = useState(false)
  const panelId = usePanelId()
  const minHeightSubtrahend = document.getElementById('portal-container')?.clientHeight || 0

  const closeSidebar = () => {
    setSidebarExpanded(false)
  }

  const { run: getPanelStatus, data: panelStatus, isLoading: loadingPanelStatus } = usePanelStatus()

  useOnMount(() => {
    if (!loadingPanelStatus) {
      getPanelStatus({ panelId })
    }
  })

  return (
    <S.Tablet className={className} minHeightSubtrahend={minHeightSubtrahend}>
      <S.Drawer isOpen={sidebarExpanded} handleClose={closeSidebar} position="left">
        <S.Sidebar>{sidebarExpanded && <NavigationMenu onItemClick={closeSidebar} />}</S.Sidebar>
      </S.Drawer>
      <S.Square>
        <SignalIcon color={colors.red} />
      </S.Square>
      <S.PropertySelectContainer>
        <PropertySelect displayName={displayName} />
        <ArmingStatusText panelStatus={panelStatus!} isLoading={loadingPanelStatus} />
      </S.PropertySelectContainer>
      <S.CollapsedMenu minimized />
      <S.Content displayBackground={!transparentBackground} padding={padding!}>
        {children}
      </S.Content>
    </S.Tablet>
  )
}

const sidebarSize = '80px'
const S = {
  Tablet: styled.div<{ minHeightSubtrahend: number }>`
    position: relative;
    min-height: calc(100vh - ${({ minHeightSubtrahend }) => minHeightSubtrahend}px);
    height: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'square arminfo'
      'menu content';
  `,
  Square: styled.div`
    width: 100%;
    grid-area: square;
    height: ${sidebarSize};
    background-color: ${colors.black};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Drawer: styled(Drawer)`
    display: flex;
  `,
  Sidebar: styled.aside`
    padding: 24px 16px;
    background-color: ${colors.black900};
    color: ${colors.white};
  `,
  CollapsedMenu: styled(NavigationMenu)`
    grid-area: menu;
    display: flex;
    justify-content: flex-start;
    padding-right: 8px;
    padding-left: 8px;
  `,
  Content: styled.div<{ padding: ReactNode; displayBackground: boolean }>`
    color: ${colors.black900};
    padding: ${({ padding }) => (padding ? padding : '2rem')};
    grid-area: content;
    ${({ displayBackground }) =>
      displayBackground &&
      `
    background-color: ${colors.black100};
    `}
  `,
  SubTitle: styled(SubTitle)`
    margin: 0;
    padding-top: 12px;
  `,
  PropertySelectContainer: styled.div`
    background-color: ${colors.black900};
    padding-right: 32px;
    padding-left: 16px;
    height: ${sidebarSize};
  `,
}

export default Tablet
