const SmokeDetectorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ? props.width : '20'}
      height={props.height ? props.height : '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.447715 0.447715 0 1 0H19C19.5523 0 20 0.447715 20 1V4C20 5.65685 18.6569 7 17 7H3C1.34315 7 0 5.65685 0 4V1ZM2 4V2H18V4C18 4.55228 17.5523 5 17 5H3C2.44772 5 2 4.55229 2 4Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M5.42475 17.8881C5.84409 16.6301 5.74651 15.257 5.15347 14.0709L4.6354 13.0347C4.27958 12.3231 4.22103 11.4992 4.47263 10.7444L4.94869 9.31625C5.12334 8.79231 4.84018 8.22599 4.31624 8.05134C3.79229 7.8767 3.22597 8.15986 3.05132 8.6838L2.57527 10.112C2.15592 11.37 2.25351 12.7431 2.84655 13.9292L3.36461 14.9653C3.72044 15.677 3.77899 16.5008 3.52738 17.2556L3.05132 18.6838C2.87668 19.2077 3.15984 19.7741 3.68378 19.9487C4.20772 20.1234 4.77404 19.8402 4.94869 19.3163L5.42475 17.8881Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M17.1535 14.0709C17.7465 15.257 17.8441 16.6301 17.4247 17.8881L16.9487 19.3163C16.774 19.8402 16.2077 20.1234 15.6838 19.9487C15.1598 19.7741 14.8767 19.2077 15.0513 18.6838L15.5274 17.2556C15.779 16.5008 15.7204 15.677 15.3646 14.9653L14.8465 13.9292C14.2535 12.7431 14.1559 11.37 14.5753 10.112L15.0513 8.6838C15.226 8.15986 15.7923 7.8767 16.3162 8.05134C16.8402 8.22599 17.1233 8.79231 16.9487 9.31625L16.4726 10.7444C16.221 11.4992 16.2796 12.3231 16.6354 13.0347L17.1535 14.0709Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M11.4247 17.8881C11.8441 16.6301 11.7465 15.257 11.1535 14.0709L10.6354 13.0347C10.2796 12.3231 10.221 11.4992 10.4726 10.7444L10.9487 9.31625C11.1233 8.79231 10.8402 8.22599 10.3162 8.05134C9.79229 7.8767 9.22597 8.15986 9.05132 8.6838L8.57527 10.112C8.15592 11.37 8.25351 12.7431 8.84655 13.9292L9.36461 14.9653C9.72044 15.677 9.77899 16.5008 9.52738 17.2556L9.05132 18.6838C8.87668 19.2077 9.15984 19.7741 9.68378 19.9487C10.2077 20.1234 10.774 19.8402 10.9487 19.3163L11.4247 17.8881Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { SmokeDetectorIcon }
