import classNames from 'classnames'
import { FC } from 'react'
import useI18n from '../../i18n/useI18n'
import { TranslationKey } from '../../types/generated/TranslationKey'
import BackButton from '../BackButton'

type LoaderType = 'large' | 'switchReplace' | 'fly-in'

export type LoaderProps = {
  loadingText?: { text: TranslationKey; color?: string }
  errorHandling?: {
    loadingFailed: boolean
    errorText: TranslationKey
    returnUrl?: string
    errorHeaderText: TranslationKey
  }
  types?: LoaderType[]
  className?: string
}

export type LoadingText = { text?: string; color?: string }

export const Loader: FC<{
  types?: LoaderType[]
  as?: keyof JSX.IntrinsicElements
  className?: string
  loadingText?: LoadingText
}> = ({ types = [], as: Component = 'div', className, loadingText }) => {
  const loaderTypes = types.map((type) => `loader--${type}`)
  return (
    <Component
      className={classNames(
        'loader',
        'loader--fly-in',
        !!loaderTypes.length && loaderTypes,
        className,
      )}
      aria-busy="true"
      aria-live="polite"
    >
      {loadingText && loadingText.text && (
        <div
          style={{
            marginTop: '20px',
            backgroundColor: '#fff',
            color: loadingText.color ? loadingText.color : undefined,
          }}
        >
          {loadingText.text}
        </div>
      )}
    </Component>
  )
}

const RequestLoader: FC<LoaderProps> = ({ loadingText, errorHandling, types, className }) => {
  const { t } = useI18n()
  return (
    // TODO: Modal should not require interactive elements inside it (use focus-trap), this is a workaround
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div tabIndex={0} className={`${className} flex flex-col justify-center items-center h-full`}>
      {errorHandling?.loadingFailed ? (
        <div className="flex flex-col self-start">
          <BackButton backUrl={errorHandling.returnUrl!} />
          <h1 className="margin-bottom">{t(errorHandling.errorHeaderText)}</h1>
          <div className="notification notification--error">{t(errorHandling.errorText)}</div>
        </div>
      ) : (
        <>
          <Loader types={types} />
          {!!loadingText && (
            <div className={classNames(types?.indexOf('fly-in') !== -1 && 'loader--fly-in')}>
              {t(loadingText.text)}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default RequestLoader
