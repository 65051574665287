import { FC, useEffect, useState } from 'react'
import { TinyArmedIcon, TinyHomeModeIcon, TinyUnarmedIcon } from '../../../assets/icons/crow'
import { stopPerformanceTimer } from '../../../firebase/traces'
import useI18n from '../../../i18n/useI18n'
import { usePanelId } from '../../../pages/AlarmSystems/hooks/usePanelId'
import PinCodePrompt, { usePinCodePrompt } from '../../../pages/AlarmSystems/Panel/PinCodeConfirm'
import FailedRequest from '../../../pages/onboardingWizard/components/FailedRequest'
import {
  useArmCommand,
  useDisarmCommand,
  usePartialArmCommand,
} from '../../../services/requestHooks/panelInfo'
import { useSystemList } from '../../../services/requestHooks/systemList'
import { PanelStatus } from '../../../types/PanelModel'
import { logArmingEvent } from '../../../utils/firebaseAnalytics'
import { LoadingText } from '../../Elements/Loaders'
import LoadingOverlay from '../../LoadingOverlay'
import { colors } from '../Style/theme'
import ArmSlider, { ArmSliderOption } from './ArmSlider'

type Props = {
  canPartialArm: boolean | undefined
  quickArmEnabled: boolean | undefined
  panelStatus: PanelStatus | undefined
  hasOpenDoorsOrWindows: boolean
}

const ArmPanelSlider: FC<Props> = ({
  quickArmEnabled = false,
  canPartialArm = false,
  panelStatus = PanelStatus.NOT_AVAILABLE,
  hasOpenDoorsOrWindows,
}) => {
  const { data: panels, isLoading: isLoadingAlarmSystems, error: panelsError } = useSystemList()

  const panelId = usePanelId()
  const [loadingText, setLoadingText] = useState<LoadingText>()
  const { t } = useI18n()
  const { run: armPanel, error: armError, isLoading: isLoadingArm } = useArmCommand()
  const { run: disarmPanel, error: disarmError, isLoading: isLoadingDisarm } = useDisarmCommand()
  const {
    run: partialArmPanel,
    error: partialArmError,
    isLoading: isLoadingPartialArm,
  } = usePartialArmCommand()

  const { promptState: pincodePromptState, promptForPinCode } = usePinCodePrompt(panelId)

  const arm = async () => {
    let PanelCode: string | undefined
    if (!quickArmEnabled) {
      PanelCode = await promptForPinCode()
      if (!PanelCode) return
    }
    armPanel({ PanelCode, PanelId: panelId })
  }

  const partialArm = async () => {
    let PanelCode: string | undefined
    if (!quickArmEnabled) {
      PanelCode = await promptForPinCode()
      if (!PanelCode) return
    }
    partialArmPanel({ PanelCode, PanelId: panelId })
  }
  const disarm = async () => {
    const PanelCode = await promptForPinCode()
    if (!PanelCode) return
    disarmPanel({ PanelCode, PanelId: panelId })
  }

  const error = armError || disarmError || partialArmError
  const isChangingStatus = isLoadingArm || isLoadingDisarm || isLoadingPartialArm

  useEffect(() => {
    if (hasOpenDoorsOrWindows && isLoadingArm) {
      setLoadingText({ text: t('ARMING_WITH_OPEN_ENTRANCES'), color: colors.lightRed })
    } else if (hasOpenDoorsOrWindows && isLoadingPartialArm) {
      setLoadingText({ text: t('PARTIALLY_ARMING_WITH_OPEN_ENTRANCES'), color: colors.lightRed })
    } else {
      setLoadingText({})
    }
  }, [isLoadingArm, isLoadingPartialArm, hasOpenDoorsOrWindows, t])

  const panelsReady =
    !isLoadingAlarmSystems && !panelsError && panels !== undefined && Array.isArray(panels)
  const shouldStopPerformanceTimer =
    !(isChangingStatus || error || panelStatus === undefined) && panelsReady && panels?.length > 0

  useEffect(() => {
    if (!shouldStopPerformanceTimer) return
    /** Using timer to ignore false positives caused by rerendering */
    const timer = setTimeout(async () => {
      await stopPerformanceTimer('Arming_Slider_Ready', (trace) => {
        trace.putAttribute('Panels', String(panels?.length ?? null))
      })
    }, 1000)
    return () => clearTimeout(timer)
  }, [shouldStopPerformanceTimer, panels?.length])

  return (
    <>
      <LoadingOverlay isLoading={isChangingStatus} className="mt-4" loadingText={loadingText}>
        <ArmSlider canPartialArm={canPartialArm}>
          <ArmSliderOption
            onClick={() => {
              arm()
              logArmingEvent('total_arm', 'SAS')
            }}
            text="Fully armed"
            Icon={TinyArmedIcon}
            active={panelStatus === PanelStatus.FULLY_ARMED}
            disabled={panelStatus !== PanelStatus.TURNED_OFF}
          />
          <ArmSliderOption
            onClick={() => {
              disarm()
              logArmingEvent('disarm', 'SAS')
            }}
            text="disarmed"
            Icon={TinyUnarmedIcon}
            active={panelStatus === PanelStatus.TURNED_OFF}
          />
          {canPartialArm && (
            <ArmSliderOption
              onClick={() => {
                partialArm()
                logArmingEvent('partial_arm', 'SAS')
              }}
              text="Home mode"
              Icon={TinyHomeModeIcon}
              active={panelStatus === PanelStatus.HOME_MODE}
              disabled={panelStatus !== PanelStatus.TURNED_OFF}
            />
          )}
        </ArmSlider>
      </LoadingOverlay>
      {!!error && <FailedRequest text={error} />}
      <PinCodePrompt promptState={pincodePromptState} />
    </>
  )
}

export default ArmPanelSlider
