import styled from 'styled-components'

import { Paragraph } from '../../../components/Crow/Typography/Typography'
import useI18n from '../../../i18n/useI18n'
import { ContactPersonType } from '../../../types/ContactPerson'
import RadioButton from '../../Crow/CrowRadioButton/CrowRadioButton'
import { ErrorMessage } from '../../Crow/ErrorMessage/ErrorMessage'

interface Props {
  register: any
  errors: any
  className?: string
}

const ContactGroups = ({ register, errors, className }: Props) => {
  const { t } = useI18n()

  return (
    <Wrapper className={className}>
      <Label>{t('SELECT_CONTACT_GROUP_TITLE')}</Label>
      <RadioButton
        title={t('SELECT_CONTACT_GROUP_RESIDENT_TITLE')}
        subTitle={t('people_contact_active_users_description')}
        value={ContactPersonType.RESIDENT}
        id="contact-residents"
        {...register('Type')}
      />
      <RadioButton
        title={t('SELECT_CONTACT_GROUP_NEIGHBOUR_TITLE')}
        subTitle={t('SELECT_CONTACT_GROUP_NEIGHBOUR_DESCRIPTION')}
        value={ContactPersonType.NEIGHBOR}
        id="contact-neighbors"
        {...register('Type')}
      />
      <RadioButton
        title={t('SELECT_CONTACT_GROUP_EMERGENCY_TITLE')}
        subTitle={t('SELECT_CONTACT_GROUP_EMERGENCY_DESCRIPTION')}
        value={ContactPersonType.OTHER}
        id="contact-emergency"
        {...register('Type')}
      />
      {errors && errors.Type && (
        <ErrorMessage text={errors.Type?.message} errorLocation="ContactGroups" />
      )}
      <Description grey={true}>{t('SELECT_CONTACT_GROUP_DESCRIPTION')}</Description>
    </Wrapper>
  )
}

const Wrapper = styled.section``

const Label = styled.label`
  font-family: 'Metric';
  font-weight: bold;
  line-height: 22px;
  margin: 0;
`

const Description = styled(Paragraph)`
  margin: 7px 0 0 16px;
`

export default ContactGroups
