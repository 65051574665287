const DiningRoomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.0001 11C21.5811 11 19.5634 12.7178 19.1001 15H28.9001C28.4368 12.7178 26.419 11 24.0001 11Z"
        fill="#E3E1E1"
      />
      <path
        d="M11 29C10.4477 29 10 29.4477 10 30V32C10 32.5523 10.4477 33 11 33H37C37.5523 33 38 32.5523 38 32V30C38 29.4477 37.5523 29 37 29H11Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 3C24.5523 3 25 3.44772 25 4V10C25 10.0277 24.9989 10.0552 24.9967 10.0824C27.5122 10.503 29.4976 12.4886 29.9177 15.0042C30.0087 15.549 29.5523 16 29 16H26C26 17.1046 25.1046 18 24 18C22.8954 18 22 17.1046 22 16H19C18.4477 16 17.9913 15.549 18.0823 15.0042C18.5024 12.4886 20.4878 10.503 23.0033 10.0824C23.0011 10.0552 23 10.0277 23 10V4C23 3.44772 23.4477 3 24 3ZM24 12C25.4806 12 26.7733 12.8044 27.4649 14H20.5351C21.2267 12.8044 22.5194 12 24 12Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.20884 26.1919C4.11106 25.6483 4.47243 25.1284 5.01599 25.0306C5.55955 24.9328 6.07946 25.2942 6.17724 25.8378L8.00534 36H12.0001V34H11C9.89543 34 9 33.1046 9 32V30C9 28.8954 9.89543 28 11 28H37C38.1046 28 39 28.8954 39 30V32C39 33.1046 38.1046 34 37 34H36V36H40.1828L42.0108 25.8378C42.1086 25.2942 42.6285 24.9328 43.1721 25.0306C43.7157 25.1284 44.077 25.6483 43.9793 26.1919L42.0262 37.0488C42.0138 37.1175 41.9947 37.1833 41.9697 37.2456C41.8602 37.6792 41.4676 38 41.0001 38V44C41.0001 44.5523 40.5523 45 40.0001 45C39.4478 45 39.0001 44.5523 39.0001 44V38H36V44C36 44.5523 35.5523 45 35 45C34.4477 45 34 44.5523 34 44V38H32.0001V44C32.0001 44.5523 31.5523 45 31.0001 45C30.4478 45 30.0001 44.5523 30.0001 44V38C29.4478 38 29.0001 37.5523 29.0001 37C29.0001 36.4477 29.4478 36 30.0001 36H34V34H14.0001V36H18.188C18.7403 36 19.188 36.4477 19.188 37C19.188 37.5523 18.7403 38 18.188 38V44C18.188 44.5523 17.7403 45 17.188 45C16.6358 45 16.188 44.5523 16.188 44V38H14.0001L14.0001 44C14.0001 44.5523 13.5523 45 13.0001 45C12.4478 45 12.0001 44.5523 12.0001 44L12.0001 38H9.18804L9.18804 44C9.18804 44.5523 8.74032 45 8.18804 45C7.63576 45 7.18804 44.5523 7.18804 44L7.18804 38C6.72051 38 6.32792 37.6792 6.21843 37.2456C6.19338 37.1833 6.17426 37.1175 6.1619 37.0488L4.20884 26.1919ZM37 32V30H11V32H37Z"
        fill="#333131"
      />
    </svg>
  )
}

export { DiningRoomIcon }
