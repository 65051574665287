import * as React from 'react'

const PanelUsersIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31" {...props}>
      <path
        fill="#575756"
        d="M20.3 23.3h-.2c-.2-.1-.3-.3-.2-.5l.6-1.2v-3c0-.2 0-.3-.2-.3-.4-.1-4.2-1.4-4.2-1.4-.2-.1-.3-.2-.3-.4v-3.7c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7V23c0 .2-.2.4-.4.4s-.4-.2-.4-.4V12.7c0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6v3.4c.9.3 3.6 1.2 4 1.3.7.2.8.7.8 1.1v3.3l-.6 1.3c-.4.1-.6.2-.7.2z"
      />
      <path
        fill="#575756"
        d="M18.8 14.1c-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4c0 .8-.6 1.4-1.4 1.4zm0-2c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6-.3-.6-.6-.6zm-7.7 2c-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4c0 .8-.7 1.4-1.4 1.4zm0-2c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6-.3-.6-.6-.6zm0 5.7c-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4c0 .7-.7 1.4-1.4 1.4zm0-2c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6-.3-.6-.6-.6zm0-5.4c-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4c0 .8-.7 1.4-1.4 1.4zm0-1.9c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6c0-.4-.3-.6-.6-.6zm3.9 1.9c-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4c-.1.8-.7 1.4-1.4 1.4zm0-1.9c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6c-.1-.4-.3-.6-.6-.6zm3.8 1.9c-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4c0 .8-.6 1.4-1.4 1.4zm0-1.9c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6c0-.4-.3-.6-.6-.6z"
      />
    </svg>
  )
}

export { PanelUsersIcon }
