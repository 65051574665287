import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import React from 'react'
import { Heading3, Paragraph } from '../../../../components/Crow/Typography/Typography'
import useI18n from '../../../../i18n/useI18n'
import { ContactPerson } from '../../../../types/ContactPerson'
import SortableItem from './SortableItem'

const getId = ({ FirstName, LastName, Type }: ContactPerson) => FirstName + LastName + Type

interface Props {
  title: string
  description: string
  items: ContactPerson[]
  setItems: any
  canChange: boolean
  removeContact: any
  isRemoving: boolean
  contactToBeRemoved: ContactPerson | undefined
  className?: string
}

const SortableList = ({
  title,
  description,
  items,
  setItems,
  canChange,
  removeContact,
  isRemoving,
  contactToBeRemoved,
  className,
}: Props) => {
  const { t } = useI18n()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      setItems((items: ContactPerson[]): ContactPerson[] => {
        const ids: string[] = items.map(getId)
        const oldIndex: number = ids.indexOf(active.id)
        const newIndex: number = ids.indexOf(over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  return (
    <div className={className}>
      <Heading3>{title}</Heading3>
      <Paragraph grey={true}>{description}</Paragraph>
      {items.length === 0 ? (
        <Paragraph>{t('people_contacts_empty')}</Paragraph>
      ) : (
        <div>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToParentElement]}
          >
            <SortableContext items={items.map(getId)} strategy={verticalListSortingStrategy}>
              {items.map((contact: ContactPerson) => {
                const id = getId(contact)
                return (
                  <SortableItem
                    key={id}
                    id={id}
                    contact={contact}
                    canChange={canChange}
                    removeContact={removeContact}
                    isRemoving={contact === contactToBeRemoved && isRemoving}
                  />
                )
              })}
            </SortableContext>
          </DndContext>
        </div>
      )}
    </div>
  )
}

export default SortableList
