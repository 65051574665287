import { FC, MouseEventHandler } from 'react'
import { AppCustomerSupportIcon, UserIcon } from '../../../../../assets/icons'
import FormButtons from '../../../../../components/forms/FormButtons'
import useI18n, { useIrishCheck } from '../../../../../i18n/useI18n'
import { usePanelId } from '../../../hooks/usePanelId'
import SpeechbubbleField from './SpeechbubbleField'

type Props = {
  openForm: MouseEventHandler<HTMLButtonElement>
}

const QuestionFormPreview: FC<Props> = ({ openForm }) => {
  const panelId = usePanelId()
  const { t } = useI18n()
  const { checkIrish } = useIrishCheck()

  return (
    <div>
      <SpeechbubbleField
        icon={AppCustomerSupportIcon}
        labelKey={checkIrish('Sector Alarm password', 'PW_WE_SAY')}
        placeholder={t('example_fish')}
        readonly
      />
      <SpeechbubbleField
        icon={UserIcon}
        labelKey={checkIrish('Your password', 'PW_YOU_SAY')}
        placeholder={t('example_tomato')}
        iconRight
        readonly
      />
      <div>
        <FormButtons
          submitText="Change password"
          onClick={openForm}
          cancelUrl={`/systems/${panelId}/settings`}
        />
      </div>
    </div>
  )
}

export default QuestionFormPreview
