import styled from 'styled-components'
import { DownloadIcon } from '../../assets/icons'
import { colors } from '../../components/Crow/Style/theme'
import { Paragraph } from '../../components/Crow/Typography'
import { Loader } from '../../components/Elements/Loaders'
import { Invoice } from '../../types/Invoices'
import { InvoiceStatusLabel } from './InvoiceStatusLabel'

interface InvoiceItemProps {
  invoice: Invoice
  onClick: () => void
  loadingPDF: boolean
}

export const InvoiceItem = ({ invoice, onClick, loadingPDF }: InvoiceItemProps) => {
  return (
    <ItemContainer>
      <ItemSubContainer>
        <StyledButton loadingPDF={loadingPDF} disabled={loadingPDF} onClick={onClick}>
          {loadingPDF ? <Loader /> : <DownloadIcon />}
        </StyledButton>
        <InvoiceTitle large>{invoice.MonthName}</InvoiceTitle>
        <InvoiceTitle large>{invoice.year}</InvoiceTitle>
      </ItemSubContainer>
      <ItemSubContainer>
        <InvoiceStatusLabel status={invoice.status} />
      </ItemSubContainer>
    </ItemContainer>
  )
}

interface StyledButtonProps {
  loadingPDF: boolean
}

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: ${colors.black200};
  border-radius: 8px;

  &:disabled {
    background-color: #fff;
  }
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  margin-top: 8px;
  height: 64px;
  border: 1px solid ${colors.black200};
  border-radius: 12px;

  & > *:last-child {
    justify-content: flex-end;
  }
`

const ItemSubContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  & > *:nth-child(n + 2) {
    margin-left: 12px;
  }

  & > *:nth-child(n + 3) {
    margin-left: 6px;
  }
`

const InvoiceTitle = styled(Paragraph)`
  color: ${colors.darkerGray};
  font-size: 20px;
`
