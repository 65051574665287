import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PencilIcon } from '../../../../assets/icons/crow'
import { CancelIcon } from '../../../../assets/icons/crow/CancelIcon'
import ConfirmationPrompt, {
  useConfirmationPrompt,
} from '../../../../components/Crow/ConfirmationPrompt'
import Drawer from '../../../../components/Crow/Drawer/Drawer'
import { colors } from '../../../../components/Crow/Style/theme'
import { addToast } from '../../../../components/Crow/Toast/Toast'
import PeopleEditForm, { PeopleEditFormRef } from '../../../../components/forms/PeopleEditForm'
import EditPanelCodeModal from '../../../../components/forms/PeopleEditForm/EditPanelCodeModal'
import TwoFactorPrompt, {
  clearOngoingPrompt,
  useTwoFactorPrompt,
} from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { usePanelInfo } from '../../../../services/requestHooks/panelInfo'
import {
  useChangeUserPinCode,
  useDeleteUserFromPanel,
  useSetUserPrivileges,
  useUpdateUserInformation,
} from '../../../../services/requestHooks/users'
import { isTranslationKey } from '../../../../types/generated/TranslationKey'
import {
  Panel,
  PermanentUser,
  UpdateUserInformationRequestBody,
  UpdateUserPrivilegesRequestBody,
} from '../../../../types/PermanentUser'
import { errorCodesTriggerNew2FA } from '../../../../utils/errorCodesTriggerNew2FA'
import { usePanelId } from '../../../AlarmSystems/hooks/usePanelId'
import PasswordPrompt, { usePasswordPrompt } from '../../../AlarmSystems/Panel/PasswordPromt'
import CategoryTag from './CategoryTag'

interface Props {
  user: PermanentUser
  panelIsArmed: boolean
  panelPermissions: Panel
  isOpen: boolean
  handleClose: () => void
}

const EditUserDrawer = ({ user, panelIsArmed, panelPermissions, isOpen, handleClose }: Props) => {
  const { t } = useI18n()
  const [pauseFocusTrap, setPauseFocusTrap] = useState<boolean>(false)
  const [showEditUserInformation, setShowEditUserInformation] = useState<boolean>(false)
  const [showPanelCodeModal, setShowPanelCodeModal] = useState<boolean>(false)

  const { data: panelInfo } = usePanelInfo()
  const panelCodeLength: number = panelInfo!.PanelCodeLength

  const ref = React.useRef<PeopleEditFormRef>({})

  const {
    run: updateUserInformation,
    isLoading: loadingUpdateUserInformation,
    hasLoaded: userInformationUpdated,
  } = useUpdateUserInformation({
    onFailure: (response: any) => {
      addToast({
        type: 'error',
        time: 5000,
        title: t('error_toast_generic_title'),
        content: isTranslationKey(response.message)
          ? t(response.message)
          : t('error_toast_generic_content'),
      })
    },
  })

  const {
    run: setUsersPrivileges,
    isLoading: loadingSetUserPrivileges,
    error: setUserPrivilegesError,
  } = useSetUserPrivileges({
    onSuccess: ({ response }: any) => {
      setPauseFocusTrap(true)

      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: t('toast_edit_user_privileges_success_content', {
          name: user.FirstName.concat(' ', user.LastName),
        }),
      })
      handleClose()
    },
  })

  const {
    run: changeUserPinCode,
    isLoading: loadingChangeUserPinCode,
    error: changeUserPinCodeError,
  } = useChangeUserPinCode({
    onSuccess: ({ response }: any) => {
      setPauseFocusTrap(true)
      setShowPanelCodeModal(false)
      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: t('toast_edit_user_pin_success_content', {
          name: user.FirstName.concat(' ', user.LastName),
        }),
      })
    },
  })

  const { run: deleteUser, isLoading: loadingDeleteUser } = useDeleteUserFromPanel({
    onSuccess: ({ response }: any) => {
      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: t('toast_delete_user_success_content', {
          name: user.FirstName.concat(' ', user.LastName),
        }),
      })
    },
    onFailure: ({ message }) => {
      if (message && errorCodesTriggerNew2FA.includes(message)) {
        clearOngoingPrompt()
        onRemoveClick()
      } else {
        addToast({
          type: 'error',
          time: 5000,
          title: t('error_toast_generic_title'),
          content: isTranslationKey(message) ? t(message) : t('error_toast_generic_content'),
        })
      }
    },
  })

  useEffect(() => {
    return () => {
      setPauseFocusTrap(false)
      setShowEditUserInformation(false)
      setShowPanelCodeModal(false)
    }
  }, [])

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setShowEditUserInformation(false), 250) // Match Drawer's closing speed: 250 ms.
    }
  }, [isOpen])

  useEffect(() => {
    if (userInformationUpdated) {
      setShowEditUserInformation(false)
    }
  }, [userInformationUpdated])

  useEffect(() => {
    if (setUserPrivilegesError && ref?.current?.handleReSubmit) {
      setPauseFocusTrap(true)

      if (errorCodesTriggerNew2FA.includes(setUserPrivilegesError)) {
        clearOngoingPrompt()
        ref.current.handleReSubmit()
      } else {
        addToast({
          type: 'error',
          time: 5000,
          title: t('error_toast_generic_title'),
          content: isTranslationKey(setUserPrivilegesError)
            ? t(setUserPrivilegesError)
            : t('error_toast_generic_content'),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserPrivilegesError, ref])

  const panelId = usePanelId()
  const { promptState: confirmationPromptState, prompt: promptForDelete } = useConfirmationPrompt()
  const { promptState: twoFactorPromptState, promptForTwoFactor } = useTwoFactorPrompt()
  const { promptState: passwordPromptState, promptForPassword } = usePasswordPrompt()
  type EditUserFormData = {
    FirstName: string
    LastName: string
    IsChild: boolean
    IsSystemUser: boolean
    IsAppUser: boolean
    IsAdminUser: boolean
    InvitePhoneNumber: string
    PanelPinCode: string
  }

  const mapUserPropertiesForInformation = (
    userFormData: EditUserFormData,
  ): UpdateUserInformationRequestBody => {
    const { FirstName, LastName } = userFormData
    return {
      FirstName,
      LastName,
    }
  }

  const mapUserPropertiesForPrivileges = (
    userFormData: EditUserFormData,
    ValidationCode: string,
    Password?: string,
  ): UpdateUserPrivilegesRequestBody => {
    const {
      IsChild,
      IsSystemUser,
      IsAppUser,
      IsAdminUser,
      InvitePhoneNumber,
      PanelPinCode,
    } = userFormData
    return {
      ValidationCode,
      Password,
      IsChild,
      IsSystemUser,
      IsAppUser,
      IsAdminUser,
      InvitePhoneNumber,
      PanelPinCode,
    }
  }

  const onSubmit = async (userFormData: EditUserFormData) => {
    try {
      if (user.Accesses.IsSystemUser !== userFormData.IsSystemUser) {
        const Password = await promptForPassword()
        if (Password) {
          const ValidationCode = await promptForTwoFactor()
          if (ValidationCode) {
            setUsersPrivileges(
              panelId,
              user.PersonId,
              mapUserPropertiesForPrivileges(userFormData, ValidationCode, Password),
            )
          }
        }
      } else {
        const ValidationCode = await promptForTwoFactor()
        if (ValidationCode) {
          setUsersPrivileges(
            panelId,
            user.PersonId,
            mapUserPropertiesForPrivileges(userFormData, ValidationCode),
          )
        }
      }
    } catch (error) {}
  }

  const onUserInfoSubmit = (userFormData: EditUserFormData) => {
    updateUserInformation(user.PersonId, mapUserPropertiesForInformation(userFormData))
  }

  const onRemoveClick = async () => {
    try {
      await promptForDelete()
      if (user.Accesses.IsSystemUser) {
        const Password = await promptForPassword()
        if (Password) {
          const ValidationCode = await promptForTwoFactor()
          if (ValidationCode) {
            deleteUser(panelId, user.PersonId, { Password, ValidationCode })
          }
        }
      } else {
        const ValidationCode = await promptForTwoFactor()
        if (ValidationCode) {
          deleteUser(panelId, user.PersonId, { ValidationCode })
        }
      }
    } catch (error) {}
  }

  const onEditPanelCodeModalSave = async (PanelPinCode: string) => {
    try {
      const Password = await promptForPassword()
      if (Password) {
        const ValidationCode = await promptForTwoFactor()
        if (ValidationCode) {
          changeUserPinCode(panelId, user.PersonId, {
            Password,
            ValidationCode,
            PanelPinCode,
          })
        }
      }
    } catch (error) {}
  }

  const { IsLegalOwner, IsAdminUser } = panelPermissions.Accesses

  const canChangeName: boolean =
    // you have to be the owner or an admin
    (IsLegalOwner || IsAdminUser) &&
    // admins can't change other admins' names
    (!user.Accesses.IsAdminUser || IsLegalOwner) &&
    // you can only change names of non-app users
    !user.Accesses.IsAppUser

  const fullName = `${user.FirstName} ${user.LastName}`

  return (
    <S.Drawer
      isOpen={isOpen}
      position="right"
      title={
        <S.Title>
          <S.NameWrapper>
            <S.Initials>{user.Initials}</S.Initials>
            {canChangeName ? (
              <S.NameEditButtonWrapper>
                <S.Name>{user.FirstName + ' ' + user.LastName}</S.Name>
                {!showEditUserInformation && (
                  <S.EditButton
                    onClick={() => setShowEditUserInformation(!showEditUserInformation)}
                  >
                    <PencilIcon />
                  </S.EditButton>
                )}
              </S.NameEditButtonWrapper>
            ) : (
              <S.Name>{user.FirstName + ' ' + user.LastName}</S.Name>
            )}
          </S.NameWrapper>
          <S.Tag user={user} />
        </S.Title>
      }
      handleClose={handleClose}
      closeButton={true}
      closeIcon={<CancelIcon color={colors.blue} />}
      pauseFocusTrap={pauseFocusTrap}
    >
      <S.Content
        user={user}
        showEditUserInformation={showEditUserInformation}
        panelIsArmed={panelIsArmed}
        panelPermissions={panelPermissions}
        panelCodeLength={panelCodeLength}
        isLoading={
          loadingUpdateUserInformation ||
          loadingChangeUserPinCode ||
          loadingSetUserPrivileges ||
          loadingDeleteUser
        }
        cancelAction={handleClose}
        onSubmit={onSubmit}
        onUserInfoSubmit={onUserInfoSubmit}
        onUserInfoCancel={() => setShowEditUserInformation(false)}
        setShowPanelCodeModal={setShowPanelCodeModal}
        onRemoveClick={onRemoveClick}
        isRemoving={loadingDeleteUser}
        outsideRef={ref}
      />
      {showPanelCodeModal && (
        <EditPanelCodeModal
          codeLength={panelCodeLength}
          onCancel={() => setShowPanelCodeModal(false)}
          onSave={onEditPanelCodeModalSave}
          isLoading={loadingChangeUserPinCode}
          error={changeUserPinCodeError}
        />
      )}
      <ConfirmationPrompt
        title={t('CONFIRM_USER_DELETE')}
        text={t('DIALOG_CONTACTS_REMOVE_MESSAGE', fullName)}
        promptState={confirmationPromptState}
        buttonProps={{ text: t('remove_user'), level: 'primary', variant: 'error' }}
      />
      <PasswordPrompt promptState={passwordPromptState} />
      <TwoFactorPrompt promptState={twoFactorPromptState} />
    </S.Drawer>
  )
}

const S = {
  Drawer: styled(Drawer)`
    display: flex;
    flex-direction: column;
    padding: 32px 72px 48px 72px;

    .drawer-title {
      margin: 0;
    }

    @media only screen and (max-width: 600px) {
      padding: 32px 24px calc(32px + env(safe-area-inset-bottom)) 24px;
    }
  `,
  Title: styled.div`
    display: flex;
    align-items: center;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      gap: 12px;
    }
  `,
  NameWrapper: styled.div`
    display: flex;
    align-items: center;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      gap: 12px;
    }
  `,
  Initials: styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.black};
    color: ${colors.white};
    border-radius: 50%;
    width: 48px;
    height: 48px;
    font-size: 18px;
    line-height: 22px;

    @media only screen and (max-width: 600px) {
      width: 100px;
      height: 100px;
      font-size: 28px;
      line-height: 32px;
    }
  `,
  NameEditButtonWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
  Name: styled.span`
    margin-left: 12px;

    @media only screen and (max-width: 600px) {
      margin-left: 0;
      font-size: 22px;
      line-height: 28px;
    }
  `,
  EditButton: styled.button`
    position: relative;
    top: 1px;
  `,
  Tag: styled(CategoryTag)`
    position: relative;
    top: 1px;
    margin-left: 1.5rem;
    border-radius: 20px;
    padding: 1.5px 14px;
    height: 24px;
    line-height: 20px;

    @media only screen and (max-width: 600px) {
      margin-left: 0;
    }
  `,
  Content: styled(PeopleEditForm)`
    flex: 1;
  `,
}

export default EditUserDrawer
