const HumidityIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="1.5 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5874 0.690612C12.4225 0.570718 12.2195 0.5 12 0.5C11.7805 0.5 11.5775 0.570721 11.4126 0.690619L11.3971 0.701622C11.3802 0.713683 11.3564 0.73082 11.3262 0.752921C11.2656 0.797115 11.1792 0.861197 11.0705 0.944251C10.8533 1.11029 10.5467 1.35258 10.1805 1.66384C9.4495 2.28517 8.47373 3.18749 7.49539 4.31257C5.56315 6.53465 3.5 9.76495 3.5 13.5C3.5 15.7543 4.39553 17.9163 5.98959 19.5104C7.58365 21.1045 9.74566 22 12 22C14.2543 22 16.4163 21.1045 18.0104 19.5104C19.6045 17.9163 20.5 15.7543 20.5 13.5C20.5 9.76495 18.4368 6.53465 16.5046 4.31257C15.5263 3.18749 14.5505 2.28517 13.8195 1.66384C13.4533 1.35258 13.1467 1.11029 12.9295 0.944251C12.8208 0.861197 12.7344 0.797115 12.6738 0.752921C12.6436 0.73082 12.6198 0.713683 12.6029 0.701622L12.5874 0.690612ZM12 2.75588C11.8486 2.87655 11.6719 3.02099 11.4758 3.18772C10.8005 3.7617 9.90127 4.59376 9.00461 5.62493C7.18685 7.71535 5.5 10.4851 5.5 13.5C5.5 15.2239 6.18482 16.8772 7.40381 18.0962C8.62279 19.3152 10.2761 20 12 20C13.7239 20 15.3772 19.3152 16.5962 18.0962C17.8152 16.8772 18.5 15.2239 18.5 13.5C18.5 10.4851 16.8132 7.71535 14.9954 5.62493C14.0987 4.59376 13.1995 3.7617 12.5242 3.18772C12.3281 3.02099 12.1514 2.87655 12 2.75588Z"
        fill="currentColor"
      />
      <path
        d="M7 13C7 14 7.27928 15.4416 8.11091 16.5C9.14236 17.8128 10.5413 18.5503 12 18.5503C13.4587 18.5503 14.8576 17.8128 15.8891 16.5C16.6353 15.5503 17 14 17 13H7Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { HumidityIcon }
