import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ArmPanelSlider from '../../../components/Crow/ArmSlider/ArmPanelSlider'
import CrowCard from '../../../components/Crow/CrowCard/CrowCard'
import { Loader } from '../../../components/Elements/Loaders'
import NavBarPage from '../../../components/pages/NavBarPage'
import PanelStatusInfo from '../../../components/PanelStatusInfo'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import { displayNewNavigationMenu } from '../../../configs'
import { CLOSE_WIZARD } from '../../../reducers/panelInfoSlice'
import { useDoorsAndWindows } from '../../../services/requestHooks/doorsandwindows'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { RootState } from '../../../store'
import { InstallationStatus } from '../../../types/PanelModel'
import { useOnMount } from '../../../utils/commonHooks'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import BookingWizard from '../../AlarmSystems/Panel/bookingWizard/BookingWizard'
import WizardLink from '../../AlarmSystems/Panel/WizardLink'
// import ArmAnnexSlider from '../../../components/Crow/ArmSlider/ArmAnnexSlider'
import FailedRequest from '../../onboardingWizard/components/FailedRequest'
import CrowMiniAppList from './CrowMiniAppList'

const CrowPanelPage: FC = () => {
  const panelId = usePanelId()
  const dispatch = useDispatch()
  const history = useHistory()
  const [hasOpenDoorOrWindow, setHasOpenDoorOrWindow] = useState(false)

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadInfoError,
  } = usePanelInfo()

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
    error: loadStatusError,
  } = usePanelStatus()
  const { data: doorsAndWindows } = useDoorsAndWindows()

  const closeWizard = () => {
    dispatch(CLOSE_WIZARD({ panelId }))
  }

  const hasClosedWizard = useSelector((state: RootState) => state.panel[panelId]?.hasClosedWizard)
  const showBooking = panelInfo?.InstallationStatus === InstallationStatus.PLANNED

  useOnMount(() => {
    if (!loadingPanelInfo && panelInfo?.PanelId !== panelId) {
      loadPanelInfo(panelId)
    }
    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
  })

  useEffect(() => {
    if (doorsAndWindows && doorsAndWindows.Floors) {
      doorsAndWindows.Floors.forEach((floor) => {
        floor.Rooms.forEach((room) => {
          room.Devices.forEach((device) => {
            if (device.Closed === false) {
              setHasOpenDoorOrWindow(true)
            }
          })
        })
      })
    }
  }, [doorsAndWindows])

  const isLoading = loadingPanelInfo || loadingPanelStatus
  const error = loadStatusError || loadInfoError

  const panelIsPlanned = panelInfo?.InstallationStatus === InstallationStatus.PLANNED
  const showStatus = !isLoading && (!error || panelIsPlanned)

  if (panelInfo?.InstallationStatus === InstallationStatus.INSTALLING) {
    history.replace(`/getting-started/${panelInfo.PanelId}`)
  }
  if (panelInfo?.InstallationStatus === InstallationStatus.READY_FOR_ACTIVATION) {
    history.replace(`/systems/${panelInfo.PanelId}/activate`)
  }

  const hideNavBar = displayNewNavigationMenu

  return (
    <TcOrCrowContainer panelInfo={panelInfo} hideWrapper>
      <NavBarPage crow hideNav={hideNavBar}>
        {panelInfo?.DisplayWizard && !hasClosedWizard && <WizardLink closeWizard={closeWizard} />}
        <div className="flex justify-center items-center h-full">
          <CrowCard className="min-w-1/2 flex flex-col items-center py-24">
            <>
              {isLoading && <Loader types={['large']} />}
              {showStatus && (
                <>
                  <PanelStatusInfo
                    panelStatus={panelStatus?.Status}
                    installationStatus={panelInfo?.InstallationStatus}
                    displayName={panelInfo?.DisplayName}
                    StatusTimeUtc={panelStatus?.StatusTimeUtc}
                    StatusTime={panelStatus?.StatusTime}
                    TimeZoneName={panelStatus?.TimeZoneName}
                    hasOpenDoorsOrWindows={hasOpenDoorOrWindow}
                    bookedStartDate={panelInfo?.BookedStartDate}
                  />
                  {!panelIsPlanned && (
                    <>
                      <ArmPanelSlider
                        panelStatus={panelStatus?.Status}
                        canPartialArm={panelInfo?.CanPartialArm}
                        quickArmEnabled={panelInfo?.QuickArmEnabled}
                        hasOpenDoorsOrWindows={hasOpenDoorOrWindow}
                      />
                    </>
                  )}
                </>
              )}
            </>
            {showBooking && !!panelInfo && (
              <BookingWizard
                propertyContact={panelInfo.PropertyContact}
                startDateString={panelInfo.BookedStartDate}
                endDateString={panelInfo.BookedEndDate}
                refreshPanelInfo={() => {
                  loadPanelInfo(panelId)
                }}
              />
            )}
            {!!error && !panelIsPlanned && (
              <FailedRequest text="Could not load alarms system status" />
            )}
            {panelInfo?.InstallationStatus === InstallationStatus.ACTIVE && (
              <CrowMiniAppList openDoorOrWindow={hasOpenDoorOrWindow} />
            )}
          </CrowCard>
        </div>
      </NavBarPage>
    </TcOrCrowContainer>
  )
}

export default CrowPanelPage
