import { MouseEventHandler, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { ChevronRightIcon } from '../../../assets/icons/crow'
import { colors } from '../Style/theme'
import { SubTitle } from '../Typography'

interface ClickableProps {
  title: string
  className?: string
  onClick?: MouseEventHandler
  rightContent?: boolean
  children?: ReactNode
}

export const Clickable = ({
  children,
  title,
  className,
  onClick,
  rightContent,
}: ClickableProps) => {
  return (
    <S3.Details className={className} onClick={onClick}>
      <S3.Summary>
        <S3.Inline>
          <SubTitle>{title}</SubTitle>
        </S3.Inline>
        {rightContent ? <RightContentContainer>{children}</RightContentContainer> : <S2.Chevron />}
      </S3.Summary>
    </S3.Details>
  )
}

// eslint-disable no-unused-modules
export interface CollapsibleProps {
  title: string
  className?: string
  onClick?: MouseEventHandler
  defaultOpen?: boolean
  children?: ReactNode
}
// eslint-disable no-unused-modules
export const Collapsible = ({
  children,
  title,
  className,
  defaultOpen,
  onClick,
}: CollapsibleProps) => {
  return (
    <S.Details open={defaultOpen} className={className} onClick={onClick}>
      <S.Summary>
        <S.Inline>
          <SubTitle>{title}</SubTitle>
        </S.Inline>
        <S2.Chevron />
      </S.Summary>
      {children}
    </S.Details>
  )
}

const pillCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`
const pillOuter = css`
  background-color: ${colors.white};
  padding: 1rem;
  border-radius: 16px;
  flex: 1;
`

const RightContentContainer = styled.div`
  margin-left: 1.5rem;
`

const S = {
  Summary: styled.summary`
    ${pillCss}
  `,
  Details: styled.details`
    ${pillOuter};
    cursor: pointer;
    overflow: hidden;
    transition: max-height 500ms ease-in-out;
    &[open] {
      > summary {
        margin-bottom: 1rem;
      }
    }

    &[open] > summary > [class='c-icon'] {
      transform: rotateZ(-90deg);
    }
  `,
  Inline: styled.span`
    display: flex;
    align-items: center;
  `,
}

const S2 = {
  Chevron: styled(ChevronRightIcon)`
    font-size: 26px;
    transition: transform 150ms ease-out;
  `,
}

const S3 = {
  Summary: styled.summary`
    ${pillCss}
  `,
  Details: styled.details`
    ${pillOuter};

    overflow: hidden;
    transition: max-height 500ms ease-in-out;
    max-height: 100px;
  `,
  Inline: styled.span`
    display: flex;
    align-items: center;
  `,
}
