import { useIntersection } from '@mantine/hooks'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import BackButton from '../../../components/BackButton'
import { Title } from '../../../components/Crow/Typography/Typography'
import { displayNewNavigationMenu } from '../../../configs'
import useI18n from '../../../i18n/useI18n'
import { RESET_HISTORY } from '../../../reducers/historySlice'
import { useIncrementalPanelEvents } from '../../../services/requestHooks/history'
import { usePanelInfo } from '../../../services/requestHooks/panelInfo'
import { Capability } from '../../../types/PanelModel'
import { useOnMount } from '../../../utils/commonHooks'
import { logBackEvent } from '../../../utils/firebaseAnalytics'
import { usePanelId } from '../hooks/usePanelId'
import EventsHasErrorOrEmpty from './EmptyEvents'
import EndOfEvents from './EndOfEvents'
import EventList from './EventList'
import EventListTitle from './EventListTitle'
import EventSkeletons from './EventSkeletons'
import { StyledParagraphNoEvents as NoEvents } from './HistoryStyle'

const cumulativeSum = (arr: number[]): number[] => {
  return arr.reduce((result: number[], currentValue: number, i: number) => {
    if (result.length === 0) {
      return [currentValue]
    }

    return [...result, result[i - 1] + currentValue]
  }, [])
}

const getGlobalRefPlacement = (totalNumberOfEvents: number, offsetFromBottom: number) => {
  if (totalNumberOfEvents === 0) {
    return 1
  }

  if (totalNumberOfEvents <= offsetFromBottom) {
    return 1
  }

  return totalNumberOfEvents - offsetFromBottom
}

export const computeRefIndeces = (
  panelEvents: [string, any[]][],
  offsetFromBottom: number,
): [number | undefined, number | undefined] => {
  if (panelEvents.length === 0) {
    return [undefined, undefined]
  }

  const cumulativeNumberOfEvents = cumulativeSum(panelEvents.map((pe) => pe[1].length))
  const totalNumberOfEvents = cumulativeNumberOfEvents[cumulativeNumberOfEvents.length - 1]
  const globalRefPlacement = getGlobalRefPlacement(totalNumberOfEvents, offsetFromBottom)
  const eventListRefIndex = cumulativeNumberOfEvents.findIndex((e) => e >= globalRefPlacement)
  const eventRefIndex =
    eventListRefIndex === 0
      ? globalRefPlacement - 1
      : globalRefPlacement - cumulativeNumberOfEvents[eventListRefIndex - 1] - 1
  return [eventListRefIndex, eventRefIndex]
}

const InfiniteScrollHistory = () => {
  const page = useRef<number>(0)
  const totalPages = useRef<undefined | number>(undefined)
  const [refIndeces, setRefIndeces] = useState<(undefined | number)[]>([undefined, undefined])

  const { t } = useI18n()
  const dispatch = useDispatch()
  const panelId = usePanelId()
  const {
    run: loadPanelEvents,
    data: panelEvents,
    isLoading: loadingPanelEvents,
    error: panelEventsError,
  } = useIncrementalPanelEvents({
    onSuccess: ({ response: { TotalPages } }) => {
      if (!totalPages.current) {
        totalPages.current = TotalPages
      }
      page.current++
    },
  })
  const { data: panelInfo } = usePanelInfo()
  const { ref, entry } = useIntersection({
    root: null,
  })

  useOnMount(() => {
    if (!loadingPanelEvents) {
      dispatch(RESET_HISTORY({ panelId }))
      loadPanelEvents(panelId, page.current + 1)
    }
  })

  const fetchedAllEvents = totalPages.current && page.current === totalPages.current

  useEffect(() => {
    if (!fetchedAllEvents && entry?.isIntersecting) {
      loadPanelEvents(panelId, page.current + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry?.isIntersecting])

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!fetchedAllEvents && e.key === 'End') {
        loadPanelEvents(panelId, page.current + 1)
      }
    }

    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [fetchedAllEvents, loadPanelEvents, panelId])

  useEffect(() => {
    if (panelEvents) {
      setRefIndeces(computeRefIndeces(panelEvents, 15))
    }
  }, [panelEvents])

  const showBackButton =
    panelInfo?.Capabilities.includes(Capability.USE_LEGACY_HOMESCREEN) || !displayNewNavigationMenu
  const returnUrl = `/systems/${panelId}`

  return (
    <Wrapper>
      {showBackButton && <BackButton backUrl={returnUrl} onClick={() => logBackEvent('history')} />}
      <Title large>{t('History')}</Title>
      <EventsHasErrorOrEmpty
        panelEvents={panelEvents}
        panelEventsError={panelEventsError ? panelEventsError : ''}
      />
      {panelEvents?.map(([timestamp, events], index) => (
        <EventGroup key={timestamp}>
          <EventListTitle timestamp={timestamp} />
          {events.length === 0 ? (
            // TODO: This will never actually be the case?
            <NoEvents large grey>
              {t('No events occured for this day')}
            </NoEvents>
          ) : (
            <EventList
              ref={index === refIndeces[0] ? ref : null}
              events={events}
              refIndex={refIndeces[1]}
            />
          )}
        </EventGroup>
      ))}
      {loadingPanelEvents && <EventSkeletons />}
      {fetchedAllEvents && <EndOfEvents />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;

  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`

const EventGroup = styled.div`
  margin-top: 3rem;
`

export default InfiniteScrollHistory
