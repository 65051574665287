const LeakageDetectorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ? props.width : '21'}
      height={props.height ? props.height : '22'}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9993 10H16.0007H19.9993ZM16.0007 10H15V11C15 11.5523 15.4477 12 16 12H20C20.5523 12 21 11.5523 21 11C21 10.4477 20.5516 10 19.9993 10"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11854 10C8.28425 9.31459 8.61735 8.69323 9.07751 8.19062C8.61877 8.68936 8.28338 9.30825 8.11728 10H8.11854ZM9 11C9 9.29512 10.2951 7.91304 12 7.91304C13.7049 7.91304 15 9.29512 15 11L17 11C17 10.6577 16.9675 10.3233 16.9053 10C16.4571 7.66941 14.4672 5.91304 12 5.91304C9.53283 5.91304 7.54295 7.66941 7.0947 10C7.03251 10.3233 7 10.6577 7 11H9ZM15.8827 10C15.4538 8.2136 13.8958 6.91304 12 6.91304C13.939 6.91304 15.4577 8.24705 15.8815 10H15.8827ZM20.9451 10C20.4476 5.50005 16.6326 2 12 2C7.36745 2 3.55237 5.50005 3.05493 10C3.01863 10.3283 3 10.662 3 11H5C5 7.13401 8.13401 4 12 4C15.866 4 19 7.13401 19 11H21C21 10.662 20.9814 10.3283 20.9451 10Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 20C2.44772 20 2 20.4477 2 21C2 21.5523 2.44772 22 3 22H11C11.5523 22 12 21.5523 12 21C12 20.4477 11.5523 20 11 20H3Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 21V11H5V21H3Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 21V11H9V21H7Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1382 14.0443C18.0994 14.0164 18.0516 14 18 14C17.9484 14 17.9006 14.0164 17.8618 14.0443L17.8581 14.0469C17.8542 14.0497 17.8486 14.0537 17.8414 14.0588C17.8272 14.0691 17.8069 14.084 17.7813 14.1033C17.7302 14.1419 17.658 14.1983 17.5719 14.2707C17.3999 14.4152 17.1703 14.625 16.9401 14.8866C16.4854 15.4034 16 16.1546 16 17.0233C16 17.5475 16.2107 18.0503 16.5858 18.421C16.9609 18.7917 17.4696 19 18 19C18.5304 19 19.0391 18.7917 19.4142 18.421C19.7893 18.0503 20 17.5475 20 17.0233C20 16.1546 19.5146 15.4034 19.0599 14.8866C18.8297 14.625 18.6001 14.4152 18.4281 14.2707C18.342 14.1983 18.2698 14.1419 18.2187 14.1033C18.1931 14.084 18.1728 14.0691 18.1586 14.0588C18.1514 14.0537 18.1458 14.0497 18.1419 14.0469L18.1382 14.0443Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { LeakageDetectorIcon }
