import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TimeSlot } from '../types/TimeSlot'

type BookingState = Record<
  string,
  | {
      availableTimeSlots: TimeSlot[]
    }
  | undefined
>

const initialState: BookingState = {}

type SetTimeSlotsPayload = TimeSlot[]

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    GET_AVAILABLE_TIMESLOTS(
      state,
      action: PayloadAction<{ data: SetTimeSlotsPayload; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = {
        availableTimeSlots: [...data].sort(
          (a, b) => +new Date(a.startField) - +new Date(b.startField),
        ),
      }
    },
    SET_TIMESLOT(state, action: PayloadAction<{ panelId: string }>) {},
    SET_BOOKING_DONE(state, action: PayloadAction<{ panelId: string }>) {},
  },
})

// eslint-disable-next-line import/no-unused-modules
export const { GET_AVAILABLE_TIMESLOTS, SET_TIMESLOT, SET_BOOKING_DONE } = bookingSlice.actions

export const bookingReducer = bookingSlice.reducer
