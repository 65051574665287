import styled from 'styled-components'
import { CheckmarkIconLarge } from '../assets/icons/crow'
import Button from '../components/Crow/Button/Button'
import { Paragraph, Title } from '../components/Crow/Typography'
import BackgroundImagePage from '../components/pages/BackgroundImagePage'
import useI18n from '../i18n/useI18n'
import { useAuth } from '../services/authentication/AuthContext'
const UserDeletedPage = () => {
  const { t } = useI18n()
  const { logout } = useAuth()

  return (
    <BackgroundImagePage>
      <Wrapper>
        <CheckmarkIconLarge />
        <Title center large>
          {t('ACCOUNT_REMOVE_SUCCESSFUL_TITLE')}
        </Title>
        <Paragraph large>{t('ACCOUNT_REMOVE_SUCCESSFUL_MESSAGE')}</Paragraph>
        <Button variant="white" level="secondary" size="l" onClick={() => logout()}>
          {t('DONE')}
        </Button>
      </Wrapper>
    </BackgroundImagePage>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  & > *:first-child {
    text-align: center;
    margin-bottom: 15px;
  }

  & > *:nth-child(n + 3) {
    margin-top: 16px;
  }

  & > *:last-child {
    margin-top: 32px;
  }
`

export default UserDeletedPage
