import * as React from 'react'

const SmartplugOutdoorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.08576 0.55024C6.50509 -0.209348 9.49136 -0.159968 13.8879 0.559449C15.1169 0.76056 16 1.84424 16 3.09143L16 13.4165C16 15.01 14.6048 16.1912 13.0893 15.9892C9.31923 15.4868 6.67288 15.5186 2.88935 16.0061C1.37701 16.201 0 15.0169 0 13.4362L0 3.07441C0 1.84062 0.865811 0.759922 2.08576 0.55024ZM13.5778 2.5177C9.37087 1.82931 6.60024 1.78592 2.41106 2.50595C2.153 2.5503 1.95122 2.78444 1.95122 3.07441L1.95122 13.4362C1.95122 13.7915 2.26689 14.087 2.64398 14.0384C6.57757 13.5316 9.39234 13.4961 13.3429 14.0226C13.729 14.074 14.0488 13.7726 14.0488 13.4165L14.0488 3.09143C14.0488 2.79971 13.8434 2.56117 13.5778 2.5177Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.34146 18.2859L2.34146 15.2727H4.29268L4.29268 18.2859C4.29268 18.6312 4.58811 18.916 4.94775 18.8854C7.10449 18.7013 8.77549 18.6479 11.0053 18.8485C11.4002 18.884 11.7073 18.5758 11.7073 18.2303V15.2727H13.6585V18.2303C13.6585 19.7973 12.3126 20.9573 10.8333 20.8242C8.74792 20.6367 7.19479 20.6841 5.11095 20.8619C3.63447 20.9878 2.34146 19.8136 2.34146 18.2859Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.12322 19.7541C5.09414 19.8514 5.07851 19.9547 5.07851 20.0617V22.9427C5.07851 23.5266 5.54417 24 6.1186 24C6.69303 24 7.15869 23.5266 7.15869 22.9427V20.0617C7.15869 19.9547 7.14305 19.8514 7.11398 19.7541H5.12322Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.07088 20.0617C9.07088 19.9547 9.08652 19.8514 9.11559 19.7541H11.1064C11.1354 19.8514 11.1511 19.9547 11.1511 20.0617L11.1511 22.9427C11.1511 23.5266 10.6854 24 10.111 24C9.53655 24 9.07088 23.5266 9.07088 22.9427L9.07088 20.0617Z"
        fill="currentColor"
      />
      <path
        d="M9.56098 10.5124C9.56098 11.3887 8.8621 12.0991 8 12.0991C7.1379 12.0991 6.43902 11.3887 6.43902 10.5124C6.43902 9.63601 7.1379 8.92559 8 8.92559C8.8621 8.92559 9.56098 9.63601 9.56098 10.5124Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { SmartplugOutdoorIcon }
