import * as React from 'react'

const HomeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      data-testid="sector_home_icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      {...props}
    >
      <path d="M91.6 100.9H78.9c-2.5 0-4.5-2-4.5-4.5v-9c0-.5-.4-.9-.9-.9h-6.8c-.5 0-.9.4-.9.9v9c0 2.5-2 4.5-4.5 4.5H48.4c-2.5 0-4.5-2-4.5-4.5V69.5c0-.6.3-1.1.8-1.5l22.6-16.4a4.5 4.5 0 015.4 0L101 71.9v-6c0-.3-.2-.6-.4-.8l-10.1-7.3c-.5-.3-.8-.9-.8-1.5V42.9h-6.4v6.2c0 1-.9 1.8-1.9 1.8-.4 0-.7-.1-1-.3l-9.7-7c-.3-.2-.8-.2-1.1 0L38.2 66.2c-.9.5-2 .2-2.5-.7-.4-.8-.3-1.7.4-2.3l31.2-22.6a4.5 4.5 0 015.4 0l6.8 4.9V41c0-.5.2-1 .5-1.3.3-.3.8-.6 1.2-.6h10.2c1 0 1.7.8 1.8 1.8v14.5l9.3 6.7c1.3.9 2 2.3 2 3.8v9.6c0 1-.9 1.8-1.9 1.8-.4 0-.7-.1-1-.3L70.5 54.5c-.3-.2-.8-.2-1.1 0l-21.9 16v25.9c0 .5.4.9.9.9h12.7c.5 0 .9-.4.9-.9v-9c0-2.5 2-4.5 4.5-4.5h6.7c2.5 0 4.5 2 4.5 4.5v9c0 .5.4.9.9.9h12.7c.5 0 .9-.4.9-.9V78.3c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8v18.1c.3 2.4-1.7 4.4-4.2 4.5z" />
    </svg>
  )
}

export { HomeIcon }
