const BedroomHallwayIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 10.7271C4 10.1748 4.44772 9.72705 5 9.72705H10C10.5523 9.72705 11 10.1748 11 10.7271V17.7271C11 18.2793 10.5523 18.7271 10 18.7271H5C4.44772 18.7271 4 18.2793 4 17.7271V10.7271ZM6 16.7271V11.7271H9V16.7271H6Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 25.7271V28.8987L9.12117 27.0199C8.73065 26.6293 8.09748 26.6293 7.70696 27.0199C7.31643 27.4104 7.31643 28.0435 7.70696 28.4341L11 31.7271V35.7271H8C7.44772 35.7271 7 36.1748 7 36.7271C7 37.2793 7.44772 37.7271 8 37.7271H8.22226L8.77782 42.7271H4C3.44772 42.7271 3 43.1748 3 43.7271C3 44.2793 3.44772 44.7271 4 44.7271L44 44.727C44.5523 44.727 45 44.2793 45 43.727C45 43.1748 44.5523 42.727 44 42.727L41 42.7271V14.9354C41 14.4045 40.8271 13.8658 40.4775 13.4434C40.1234 13.0156 39.5981 12.7271 39 12.7271H23C22.4019 12.7271 21.8766 13.0156 21.5225 13.4434C21.1729 13.8658 21 14.4045 21 14.9354V42.7271H15.2223L15.7778 37.7271H16C16.5523 37.7271 17 37.2793 17 36.7271C17 36.1748 16.5523 35.7271 16 35.7271H13V34.7271L16.293 31.4341C16.6836 31.0435 16.6836 30.4104 16.293 30.0199C15.9025 29.6293 15.2694 29.6293 14.8788 30.0199L13 31.8987L13 25.7271C13 25.1748 12.5523 24.7271 12 24.7271C11.4477 24.7271 11 25.1748 11 25.7271ZM39 14.9354V42.7271H23V33H31C32.1046 33 33 33.8954 33 35V42.5696H35V35C35 33.5194 34.1956 32.2267 33 31.5351V26C33 23.7909 31.2091 22 29 22H23V14.9354C23 14.8344 23.0318 14.7621 23.0568 14.7271H38.9432C38.9682 14.7621 39 14.8344 39 14.9354ZM23 24H29C30.1046 24 31 24.8954 31 26V31H29V30C29 27.7909 27.2091 26 25 26H23V24ZM23 28H25C26.1046 28 27 28.8954 27 30V31H23V28ZM13.21 42.7271L13.7655 37.7271H10.2346L10.7901 42.7271H13.21Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 15.7271C13 15.1748 13.4477 14.7271 14 14.7271H18C18.5523 14.7271 19 15.1748 19 15.7271V21.7271C19 22.2793 18.5523 22.7271 18 22.7271H14C13.4477 22.7271 13 22.2793 13 21.7271V15.7271ZM17 20.7271H15V16.7271H17V20.7271Z"
        fill="#333131"
      />
      <path
        d="M39 14.9354V42.7271H33.8637C33.8838 42.6756 33.9019 42.6231 33.9177 42.5696H35V35C35 33.5194 34.1956 32.2267 33 31.5351V26C33 23.7909 31.2091 22 29 22H23V14.9354C23 14.8344 23.0318 14.7621 23.0568 14.7271H38.9432C38.9682 14.7621 39 14.8344 39 14.9354Z"
        fill="#E3E1E1"
      />
    </svg>
  )
}

export { BedroomHallwayIcon }
