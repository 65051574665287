import { FC, MouseEventHandler, ReactNode } from 'react'
import styled from 'styled-components'

import { colors } from '../../../components/Crow/Style/theme'
import Tile from '../../../components/Crow/Tile/Tile'
import Toggle from '../../../components/Crow/Toogle/Toggle'
import { Heading3 } from '../../../components/Crow/Typography'
import LoadingOverlay from '../../../components/LoadingOverlay'
import useI18n from '../../../i18n/useI18n'
import { FlexRow } from './LockStyle'

type Props = {
  text: string
  subText?: ReactNode
  onClick?: () => void
  onToggle: MouseEventHandler<HTMLInputElement>
  checked?: boolean
  healthy?: boolean
  isLoading?: boolean
  settingsUrl?: string
  id?: string
  icon?: ReactNode
  className?: string
  disabled?: boolean
}

const LockTileItem: FC<Props> = ({
  text,
  subText,
  onClick,
  onToggle,
  checked,
  healthy,
  isLoading,
  id,
  icon,
  className,
  disabled,
}) => {
  const { t } = useI18n()

  const numberOfLines = text.split(/\s+/).length

  return (
    <Tile onClick={onClick} disabled={disabled} className={className}>
      {healthy || isLoading ? (
        <FlexRow>
          <LoadingOverlay isLoading={isLoading!}>
            <Toggle
              key={id}
              onClick={onToggle}
              enabled={checked!}
              disabled={disabled}
              className="qa-doorLocks-slider"
            />
          </LoadingOverlay>
          <IconWrapper disabled={disabled}>{icon ? icon : null}</IconWrapper>
        </FlexRow>
      ) : (
        <Unknown>{t('Unknown')}</Unknown>
      )}
      {numberOfLines > 1 ? (
        <MultiLineName
          color={disabled ? colors.black600 : colors.black800}
          className="qa-doorLocks-name"
        >
          {text}
        </MultiLineName>
      ) : (
        <SingleLineName
          color={disabled ? colors.black600 : colors.black800}
          className="qa-doorLocks-name"
        >
          {text}
        </SingleLineName>
      )}
      {!!subText && subText}
    </Tile>
  )
}

const IconWrapper = styled.span<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? colors.black500 : colors.black900)};
`

const Unknown = styled.div`
  height: 34px;
`

const SingleLineName = styled(Heading3)`
  margin-top: 16px;
  line-height: 22px;
  height: 44px;
  overflow: hidden;
  white-space: nowrap;
`

const MultiLineName = styled(Heading3)`
  margin-top: 16px;
  line-height: 22px;
  height: 44px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export default LockTileItem
