import React, { FC } from 'react'
import useI18n from '../../../../../i18n/useI18n'
import { useDispatch, useSelector } from 'react-redux'
import { TemperatureIcon } from '../../../../../assets/icons'
import ExpandableSection, {
  ExpandableListItem,
} from '../../../../../components/system/components/ExpandableList'
import { usePanelId } from '../../../hooks/usePanelId'
import { Temperature } from '../../../../../types/Temperature'
import { useTemperature } from '../../../../../services/requestHooks/temperatureList'
import { selectIsExpandedMenu, toggleExpandableMenu } from '../../../../../reducers/panelInfoSlice'
import { Loader } from '../../../../../components/Elements/Loaders'
import { usePanelInfo, usePanelStatus } from '../../../../../services/requestHooks/panelInfo'

const SystemTemp: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()

  const { data: panelInfo } = usePanelInfo()
  const { data: panelStatus } = usePanelStatus()
  const showTemperature = useSelector(selectIsExpandedMenu({ panelId, menu: 'temp' }))
  const dispatch = useDispatch()

  const { run: getTemperatures, isLoading, error, data: realTemperatures } = useTemperature()

  const hasError = !!error

  const temperatures: Temperature[] = realTemperatures || panelInfo?.Temperatures || []

  const toggleShowTemperatures = () => {
    if (!isLoading && panelStatus?.IsOnline) {
      getTemperatures(panelId)
    }
    dispatch(toggleExpandableMenu({ panelId, menu: 'temp' }))
  }

  return (
    <ExpandableSection
      open={showTemperature}
      onClick={toggleShowTemperatures}
      icon={<TemperatureIcon />}
      summary={<h2>{t('Temperature')}</h2>}
      error={
        hasError
          ? t('Failed to load temperatures')
          : !isLoading && !panelStatus?.IsOnline
          ? t('You can not get tempratures while you are offline')
          : undefined
      }
    >
      {temperatures?.map(({ Temprature, Label }, index) => (
        <ExpandableListItem key={index}>
          <div>
            {isLoading ? (
              <Loader className="ml-2 mr-16" />
            ) : panelStatus?.IsOnline && Temprature !== '-52' ? (
              <span className="badge green notranslate mr-12">{Temprature}°</span>
            ) : (
              <div className="switch switch--inactive">{t('Inactive')}</div>
            )}
            <span className="ng-binding">{Label}</span>
          </div>
        </ExpandableListItem>
      ))}
    </ExpandableSection>
  )
}

export default SystemTemp
