import { useContext, useMemo } from 'react'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import { usePanelInfo, usePanelStatus } from '../../../../services/requestHooks/panelInfo'
import { useGetPanelUsers } from '../../../../services/requestHooks/panelUsers'
import { Capability, PanelStatus } from '../../../../types/PanelModel'
import { useOnMount } from '../../../../utils/commonHooks'
import { usePanelId } from '../../hooks/usePanelId'
import SettingsSubPage from '../components/SettingsSubPage'
import PanelUsers from './components/PanelUsers'
import './style/panelUsers.scss'

function PanelUsersScreen() {
  const panelId = usePanelId()
  const returnUrl = '/systems/' + panelId + '/settings'

  const { promptForTwoFactor } = useContext(TwofactorContext)

  const {
    run: getPanelUsers,
    isLoading: isLoadingPanelUsers,
    error: loadPanelUsersError,
  } = useGetPanelUsers()
  const {
    run: loadPanelInfo,
    isLoading: isLoadingPanelInfo,
    error: panelInfoError,
    data: panelInfo,
  } = usePanelInfo()

  const {
    run: loadPanelStatus,
    isLoading: isLoadingPanelStatus,
    error: panelStatusError,
    data: panelStatus,
  } = usePanelStatus()

  useOnMount(() => {
    if (!isLoadingPanelInfo && panelId !== panelInfo?.PanelId) {
      loadPanelInfo(panelId)
    }
    if (!isLoadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
    promptForTwoFactor(returnUrl).then((code) => {
      if (code && !isLoadingPanelUsers) {
        getPanelUsers(panelId)
      }
    })
  })

  const allowUserChanges = useMemo(
    () =>
      panelInfo?.Capabilities.includes(Capability.CAN_MODIFY_WHEN_ARMED) ||
      panelStatus?.Status === PanelStatus.TURNED_OFF,
    [panelStatus?.Status, panelInfo?.Capabilities],
  )

  return (
    <SettingsSubPage
      isLoading={isLoadingPanelUsers || isLoadingPanelInfo || isLoadingPanelStatus}
      loaderProps={{
        loadingText: { text: 'Loading' },
        errorHandling: {
          loadingFailed: !!loadPanelUsersError || !!panelInfoError || !!panelStatusError,
          errorHeaderText: 'Panel users',
          errorText: 'Something went wrong. Please try again',
        },
      }}
    >
      <PanelUsers allowUserChanges={allowUserChanges} />
    </SettingsSubPage>
  )
}

export default PanelUsersScreen
