import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CancelIcon } from '../../../../assets/icons/crow/CancelIcon'
import DefaultDrawer from '../../../../components/Crow/Drawer/Drawer'
import { colors } from '../../../../components/Crow/Style/theme'
import { addToast } from '../../../../components/Crow/Toast/Toast'
import AddUserForm, { AddUserFormRef } from '../../../../components/forms/AddUserForm'
import TwoFactorPrompt, {
  clearOngoingPrompt,
  useTwoFactorPrompt,
} from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { useCreateNewUserForPanel } from '../../../../services/requestHooks/users'
import { isTranslationKey } from '../../../../types/generated/TranslationKey'
import { deriveInitials } from '../../../../utils/deriveInitials'
import { errorCodesTriggerNew2FA } from '../../../../utils/errorCodesTriggerNew2FA'
import { usePanelId } from '../../../AlarmSystems/hooks/usePanelId'
import PasswordPrompt, { usePasswordPrompt } from '../../../AlarmSystems/Panel/PasswordPromt'

const AddUserDrawer = ({ isOpen, handleClose }: { isOpen: boolean; handleClose: () => void }) => {
  const [pauseFocusTrap, setPauseFocusTrap] = useState<boolean>(false)
  const { t } = useI18n()

  const ref = React.useRef<AddUserFormRef>({})

  const {
    run: createNewUser,
    isLoading: newUserIsLoading,
    error: newUserError,
  } = useCreateNewUserForPanel({
    onSuccess: ({ response }: any) => {
      setPauseFocusTrap(true)
      const willSendSMS = response.Accesses.IsSystemUser && response.Accesses.IsAppUser

      const mainContent = t('toast_add_permanent_user_success_content', {
        name: response.FirstName.concat(' ', response.LastName),
      })
      const content = willSendSMS ? mainContent.concat(' ', t('toast_send_sms')) : mainContent

      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content,
      })
      handleClose()
    },
  })

  useEffect(() => {
    if (newUserError && ref?.current?.handleReSubmit) {
      setPauseFocusTrap(true)

      if (errorCodesTriggerNew2FA.includes(newUserError)) {
        clearOngoingPrompt()
        ref.current.handleReSubmit()
      } else {
        addToast({
          type: 'error',
          time: 5000,
          title:
            newUserError === '401statuscode'
              ? t('401_toast_title')
              : t('error_toast_generic_title'),
          content: isTranslationKey(newUserError)
            ? t(newUserError)
            : t('error_toast_generic_content'),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserError, ref])

  const panelId = usePanelId()

  const { promptState: passwordPromptState, promptForPassword } = usePasswordPrompt()
  const { promptState: twoFactorPromptState, promptForTwoFactor } = useTwoFactorPrompt()

  type AddUserFormData = {
    FirstName: string
    LastName: string
    PhoneNumber: string
    IsChild: boolean
    IsSystemUser: boolean
    IsAppUser: boolean
    IsAdminUser: boolean
    PanelPinCode: string
  }

  const onSubmit = async (userFormData: AddUserFormData) => {
    try {
      if (userFormData.IsSystemUser) {
        const Password = await promptForPassword()
        if (Password) {
          const ValidationCode = await promptForTwoFactor()
          if (ValidationCode) {
            createNewUser(panelId, {
              ...userFormData,
              Initials: deriveInitials(userFormData.FirstName, userFormData.LastName),
              Password,
              ValidationCode,
            })
          }
        }
      } else {
        const ValidationCode = await promptForTwoFactor()
        if (ValidationCode) {
          createNewUser(panelId, {
            ...userFormData,
            Initials: deriveInitials(userFormData.FirstName, userFormData.LastName),
            ValidationCode,
          })
        }
      }
    } catch (error) {}
  }

  return (
    <Drawer
      isOpen={isOpen}
      position="right"
      title={t('Add permanent user')}
      handleClose={handleClose}
      closeButton={true}
      closeIcon={<CancelIcon color={colors.blue} />}
      pauseFocusTrap={pauseFocusTrap}
    >
      <AddUserForm
        onSubmit={onSubmit}
        cancelAction={handleClose}
        isLoading={newUserIsLoading}
        outsideRef={ref}
      />
      <PasswordPrompt promptState={passwordPromptState} />
      <TwoFactorPrompt promptState={twoFactorPromptState} />
    </Drawer>
  )
}

const Drawer = styled(DefaultDrawer)`
  max-width: 950px;

  @media only screen and (max-width: 600px) {
    padding: 40px 24px calc(40px + env(safe-area-inset-bottom)) 24px;
  }
`

export default AddUserDrawer
