import React from 'react'
import styled from 'styled-components'
import { CancelIcon } from '../../../../assets/icons/crow/CancelIcon'
import DefaultDrawer from '../../../../components/Crow/Drawer/Drawer'
import { colors } from '../../../../components/Crow/Style/theme'
import Form from '../../../../components/forms/EditContactForm'
import { ContactPerson } from '../../../../types/ContactPerson'
import { deriveInitials } from '../../../../utils/deriveInitials'
import RemoveContactButton from './RemoveContactButton'

interface Props {
  isOpen: boolean
  handleClose: () => void
  contact: ContactPerson
  onRemoveClick: any
  isRemoving: boolean
}

const EditContactDrawer = ({ isOpen, handleClose, contact, onRemoveClick, isRemoving }: Props) => {
  const { FirstName, LastName } = contact

  return (
    <Drawer
      isOpen={isOpen}
      position="right"
      title={
        <Title>
          <Initials>{deriveInitials(FirstName, LastName)}</Initials>
          <Name>{FirstName.concat(' ', LastName)}</Name>
        </Title>
      }
      handleClose={handleClose}
      closeButton={true}
      closeIcon={<CancelIcon color={colors.blue} />}
    >
      <Content>
        <Form contact={contact} />
        <Other>
          <RemoveContactButton
            disabled={false}
            onRemoveClick={onRemoveClick}
            isRemoving={isRemoving}
          />
        </Other>
      </Content>
    </Drawer>
  )
}

const Drawer = styled(DefaultDrawer)`
  display: flex;
  flex-direction: column;
  padding: 32px 72px 48px 72px;

  .drawer-title {
    margin: 0;
  }

  @media only screen and (max-width: 600px) {
    padding: 40px 24px;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    gap: 12px;
  }
`

const Initials = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.black};
  color: ${colors.white};
  border-radius: 50%;
  width: 48px;
  height: 48px;
  font-size: 18px;
  line-height: 22px;

  @media only screen and (max-width: 600px) {
    width: 100px;
    height: 100px;
    font-size: 28px;
    line-height: 32px;
  }
`

const Name = styled.span`
  margin-left: 12px;

  @media only screen and (max-width: 600px) {
    margin-left: 0;
    font-size: 22px;
    line-height: 28px;
  }
`

const Content = styled.div`
  margin-top: 66px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Other = styled.div``

export default EditContactDrawer
