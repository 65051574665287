import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React, { useState } from 'react'
import styled from 'styled-components'
import { HomeIcon } from '../../../../assets/icons'
import { TrashCanIcon } from '../../../../assets/icons/crow'
import DefaultCard from '../../../../components/Crow/Card/Card'
import { colors } from '../../../../components/Crow/Style/theme'
import { Loader } from '../../../../components/Elements/Loaders'
import { ContactPerson } from '../../../../types/ContactPerson'
import EditContactDrawer from './EditContactDrawer'

interface Props {
  id: string
  contact: ContactPerson
  canChange: boolean
  removeContact: any
  isRemoving: boolean
}

const SortableItem = ({ id, contact, canChange, removeContact, isRemoving }: Props) => {
  const { FirstName, LastName } = contact
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false)

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const handleMouseDown = () => {
    // Maybe I can use setNodeRef here?
    document.getElementById(`${id}-handler`)!.style.cursor = 'grabbing'
  }

  const handleMouseUp = () => {
    // Maybe I can use setNodeRef here?
    document.getElementById(`${id}-handler`)!.style.removeProperty('cursor')
  }

  return (
    <Wrapper ref={setNodeRef} style={style}>
      <Card>
        <Text onClick={() => setDrawerIsOpen(true)}>{FirstName.concat(' ', LastName)}</Text>
        {/* Note: onMouseDown and onMouseUp might interfere with dnd-kit */}
        {canChange && (
          <Handler
            id={`${id}-handler`}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            {...attributes}
            {...listeners}
          >
            <HomeIcon />
          </Handler>
        )}
      </Card>
      {canChange && (
        <button onClick={() => removeContact(contact)}>
          {isRemoving ? <Loader /> : <TrashCanIcon />}
        </button>
      )}
      <EditContactDrawer
        isOpen={drawerIsOpen}
        handleClose={() => setDrawerIsOpen(false)}
        contact={contact}
        onRemoveClick={() => removeContact(contact)}
        isRemoving={isRemoving}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 16px;
`

const Card = styled(DefaultCard)`
  flex: 1;
  padding: 0;
  height: 56px;
`

const Text = styled.button`
  padding: 16px;
  flex: 1;
  font-family: 'Metric';
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  color: ${colors.black900};
`

const Handler = styled.button`
  cursor: grab;
  padding: 16px;
  touch-action: none;
`

export default SortableItem
