import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserDetails } from '../types/UserDetails'
import history from '../utils/history'

export type UserState = {
  User: UserDetails | undefined
  Resources: {
    CustomerServicePhone: string
    NoLocksUrl: string
    NoPlugsUrl: string
    TermsUrl: string
    CustomerServiceUrl: string
  }
  DefaultPanelId: string
  benefits: any[]
}

const initialState: UserState = {
  User: undefined,
  Resources: {
    CustomerServicePhone: '',
    NoLocksUrl: '',
    NoPlugsUrl: '',
    TermsUrl: '',
    CustomerServiceUrl: '',
  },
  DefaultPanelId: '',
  benefits: [],
}

interface LoadUserPayload {
  User: UserDetails
  Resources: UserState['Resources']
  DefaultPanelId: string
  redirect?: boolean
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    LOAD_USER_INFO(state, action: PayloadAction<LoadUserPayload>) {
      const { User, Resources, DefaultPanelId, redirect } = action.payload
      state.User = User
      state.Resources = Resources
      state.DefaultPanelId = DefaultPanelId
      if (redirect) {
        history.push(`/systems/${DefaultPanelId}`) // denne burde nok flyttes til et annet sted
      }
    },
    DELETE_ME_SUCCESS() {},
    CHANGE_PASSWORD_SUCCESS() {},
    RESET_PASSWORD_SUCCESS() {},
    SET_CULTURE() {},
    ACTIVATE_ACCOUNT() {},
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  LOAD_USER_INFO,
  CHANGE_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  SET_CULTURE,
  ACTIVATE_ACCOUNT,
  DELETE_ME_SUCCESS,
} = userSlice.actions

export const userReducer = userSlice.reducer
