const PowerOnIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4566 0.550302C12.8543 0.754422 13.0683 1.19773 12.9806 1.63609L11.6474 8.30223L16.8511 10.2536C17.1638 10.3709 17.3973 10.6364 17.4736 10.9615C17.5499 11.2865 17.4589 11.6282 17.2311 11.8723L6.73107 23.1223C6.42604 23.4491 5.94115 23.5338 5.54342 23.3297C5.14569 23.1255 4.93176 22.6822 5.01944 22.2439L6.35266 15.5777L1.14889 13.6263C0.836265 13.5091 0.602772 13.2435 0.526475 12.9185C0.450178 12.5934 0.541144 12.2517 0.768961 12.0077L11.269 0.757659C11.574 0.430842 12.0589 0.346183 12.4566 0.550302ZM3.25164 12.2788L7.85114 14.0036C8.31019 14.1758 8.57675 14.6553 8.4806 15.1361L7.67101 19.184L14.7484 11.6011L10.1489 9.87631C9.68984 9.70416 9.42329 9.22461 9.51944 8.74386L10.329 4.69592L3.25164 12.2788Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { PowerOnIcon }
