const TinyTemperatureIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50501 9.57099C6.77956 9.78452 6.25 10.4554 6.25 11.25C6.25 12.2165 7.0335 13 8 13C8.9665 13 9.75 12.2165 9.75 11.25C9.75 10.4554 9.22044 9.78452 8.49499 9.57099C8.49829 9.5478 8.5 9.5241 8.5 9.5V6.5C8.5 6.22386 8.27614 6 8 6C7.72386 6 7.5 6.22386 7.5 6.5V9.5C7.5 9.5241 7.50171 9.5478 7.50501 9.57099ZM7.25 11.25C7.25 10.8358 7.58579 10.5 8 10.5C8.41421 10.5 8.75 10.8358 8.75 11.25C8.75 11.6642 8.41421 12 8 12C7.58579 12 7.25 11.6642 7.25 11.25Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 3.5V8.45473C11.0173 8.91739 11.3973 9.5158 11.5954 10.1843C11.8173 10.9329 11.7999 11.7321 11.5458 12.4704C11.2916 13.2087 10.8134 13.8492 10.1778 14.3027C9.54212 14.7561 8.7808 14.9998 8 14.9998C7.21921 14.9998 6.45788 14.7561 5.82224 14.3027C5.1866 13.8492 4.70835 13.2087 4.45421 12.4704C4.20007 11.7321 4.18271 10.9329 4.40455 10.1843C4.60267 9.5158 4.98272 8.91739 5.5 8.45473V3.5C5.5 2.83696 5.76339 2.20107 6.23223 1.73223C6.70107 1.26339 7.33696 1 8 1C8.66304 1 9.29893 1.26339 9.76777 1.73223C10.2366 2.20107 10.5 2.83696 10.5 3.5ZM6.93934 2.43934C7.22064 2.15804 7.60218 2 8 2C8.39783 2 8.77936 2.15804 9.06066 2.43934C9.34197 2.72064 9.5 3.10218 9.5 3.5V8.68909C9.5 8.85517 9.58203 9.00701 9.71354 9.09894C10.1541 9.44994 10.4765 9.92795 10.6367 10.4685C10.7993 11.0174 10.7866 11.6035 10.6002 12.1449C10.4139 12.6863 10.0632 13.1561 9.59702 13.4886C9.13089 13.8211 8.57258 13.9998 8 13.9998C7.42742 13.9998 6.86911 13.8211 6.40298 13.4886C5.93684 13.1561 5.58612 12.6863 5.39975 12.1449C5.21338 11.6035 5.20065 11.0174 5.36334 10.4685C5.52352 9.92795 5.84587 9.44994 6.28646 9.09894C6.41797 9.00701 6.5 8.85517 6.5 8.68909V3.5C6.5 3.10218 6.65804 2.72064 6.93934 2.43934Z"
        fill="#333131"
      />
    </svg>
  )
}

export { TinyTemperatureIcon }
