import styled from 'styled-components'
import CrowCard from '../../../components/Crow/CrowCard/CrowCard'
import { colors } from '../../../components/Crow/Style/theme'

const LockCrowCard = styled(CrowCard)<{ justifyContent: string }>`
  display: flex;

  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'space-between')};
  align-items: center;
  margin-top: 0px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${colors.black300};
  background-color: ${colors.white};
`

const LabelContainer = styled.div<{ position: 'row' | 'column' }>`
  font-weight: 500;
  display: flex;
  flex-direction: ${({ position }) => (position === 'column' ? 'column' : 'row')};
`
const FlexRow = styled.div`
  font-weight: 500;
  display: flex;
  justify-content: space-between;
`

const FlexColumn = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Label = styled.span`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.black800};
`

const LabelWrapper = styled.span`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.black700};
`

const LockSettingsBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`

const GeneralSettingsBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 67px;
`

const LocksWrapper = styled.div`
  margin-top: 42px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media only screen and (max-width: 600px) {
    gap: 15px;
  }
`

const Unreachable = styled.span`
  color: ${colors.red};
`

const LockListItemContainer = styled.div`
  width: 100%;
`

export {
  LockCrowCard,
  LabelContainer,
  FlexRow,
  FlexColumn,
  Label,
  LabelWrapper,
  LockSettingsBtnContainer,
  GeneralSettingsBtnContainer,
  LocksWrapper,
  Unreachable,
  LockListItemContainer,
}
