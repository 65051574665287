/* eslint-disable import/no-unused-modules */
import { useState } from 'react'
import styled, { css } from 'styled-components'
import { CancelIcon, SwitchIcon } from '../../../assets/icons/crow'
import useI18n from '../../../i18n/useI18n'
import { usePanelId } from '../../../pages/AlarmSystems/hooks/usePanelId'
import { useSystemList } from '../../../services/requestHooks/systemList'
import { AccessGroupType } from '../../../types/AppUser'
import { useOnMount } from '../../../utils/commonHooks'
import { Loader } from '../../Elements/Loaders'
import { colors } from '../Style/theme'
import { Title } from '../Typography'
import Expandable from './Expandable'
import PropertyOption from './PropertyOption'

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 26px;
  height: 26px;
  border-radius: 8px;
`

const SwitchIconContainer = styled(IconContainer)`
  background-color: ${colors.blue};
`

const FlipIcon = ({ flipped }: { flipped: boolean }) => {
  return (
    <S.FlipCard>
      <S.FlipInner flipped={flipped}>
        <S.FlipFront>
          <SwitchIconContainer>
            <SwitchIcon color={colors.white} />
          </SwitchIconContainer>
        </S.FlipFront>
        <S.FlipBack>
          <IconContainer>
            <CancelIcon />
          </IconContainer>
        </S.FlipBack>
      </S.FlipInner>
    </S.FlipCard>
  )
}

const PropertySelect = ({ displayName }: { displayName?: string }) => {
  const [isOpen, setIsOpen] = useState(false)
  const panelId = usePanelId()
  const { t } = useI18n()

  const { run: getAlarmSystems, isLoading: isLoadingAlarmSystems, data: systems } = useSystemList()

  const ownedSystems = systems?.filter((it) => it.AccessGroup === AccessGroupType.LEGAL_OWNER) || []

  useOnMount(() => {
    if (!isLoadingAlarmSystems) {
      getAlarmSystems()
    }
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedProperty, setSelectedProperty] = useState(
    ownedSystems.find((x) => x.PanelId === panelId)?.DisplayName || displayName,
  )

  return (
    <>
      <div className="flex mt-5">
        <S.Title>{displayName}</S.Title>

        <S.Content onClick={() => setIsOpen(!isOpen)}>
          <FlipIcon flipped={isOpen} />
        </S.Content>
      </div>

      <Expandable isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        {isOpen && (
          <S.OptionsContainer>
            {isLoadingAlarmSystems ? (
              <div className="flex items-center">
                <Loader />
                <span className="pl-4">{t('Loading')}</span>
              </div>
            ) : (
              <>
                {ownedSystems.map((data) => (
                  <PropertyOption
                    key={data.PanelId}
                    name={data.DisplayName}
                    selected={displayName === data.DisplayName}
                    onClick={(name) => setSelectedProperty(name)}
                    panelId={data.PanelId}
                    panelTime={data.PanelTime}
                    panelStatus={data.Status}
                  />
                ))}
              </>
            )}
          </S.OptionsContainer>
        )}
      </Expandable>
    </>
  )
}

const S = {
  Title: styled(Title)`
    color: ${colors.white};
    margin: 0;
    margin-right: auto;
  `,
  Content: styled.div`
    padding: 0rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,
  OptionsContainer: styled.div`
    padding: 2rem;
  `,
  FlipCard: styled.div`
    height: 24px;
    width: 24px;
    color: ${colors.white};
  `,
  FlipInner: styled.div<{ flipped: boolean }>`
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    ${({ flipped }) => {
      return (
        flipped &&
        css`
          transform: rotateY(180deg);
        `
      )
    }}
  `,
  FlipFront: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  `,
  FlipBack: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    transform: rotateY(180deg);
  `,
}

export default PropertySelect
