import { useMemo } from 'react'
import { logFirebaseEvent } from '../../../../utils/firebaseAnalytics'
import { usePanelId } from '../../hooks/usePanelId'
import SettingsSubPage from '../components/SettingsSubPage'
import { CrowWifiSection } from './CrowWifiSection'

const CrowWifiPage = () => {
  const panelId = usePanelId()
  const returnUrl = useMemo(() => `/systems/${panelId}/settings`, [panelId])
  return (
    <SettingsSubPage
      returnUrl={returnUrl}
      isLoading={false}
      onBackClick={() => logFirebaseEvent('settings_wifi_closed')}
    >
      <CrowWifiSection />
    </SettingsSubPage>
  )
}

export { CrowWifiPage }
