import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'
import Button from '../../../../components/Crow/Button/Button'
import { CrowCheckbox } from '../../../../components/Crow/CrowCheckbox/CrowCheckbox'
import { ErrorMessage } from '../../../../components/Crow/ErrorMessage/ErrorMessage'
import TextField from '../../../../components/Crow/Input/TextFieldForForm'
import { Paragraph } from '../../../../components/Crow/Typography'
import { Loader } from '../../../../components/Elements/Loaders'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { useConnectToWifi } from '../../../../services/requestHooks/wifi'
import { logFirebaseEvent } from '../../../../utils/firebaseAnalytics'
import { usePanelId } from '../../hooks/usePanelId'
import { AnimatedContainer } from './AnimatedContainer'

type FormValues = {
  ssid: string
  password: string
}

interface FormProps {
  onCancel: () => void
  WiFiCardSerial: string | undefined
  loadWifiList: () => void
}

export const WifiConnectForm = ({ onCancel, WiFiCardSerial, loadWifiList }: FormProps) => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const { run: connectToWifi, isLoading, error: wifiConnectError } = useConnectToWifi({
    onSuccess: () => {
      logFirebaseEvent('settings_wifi_connect_connect_success')
      loadWifiList()
      onCancel()
    },
    onFailure: () => {
      logFirebaseEvent('settings_wifi_connect_connect_failure')
    },
  })

  const { promptForTwoFactor } = useContext(TwofactorContext)
  const [showPassword, setShowPassword] = useState(false)

  const onSubmit = async ({ ssid, password }: FormValues) => {
    if (!WiFiCardSerial) return
    const TwoFactorPin = await promptForTwoFactor()
    if (!TwoFactorPin) return
    logFirebaseEvent('settings_wifi_connect_request_made')
    logFirebaseEvent('settings_wifi_connect_submit_form')
    connectToWifi({
      Ssid: ssid,
      Password: password,
      password,
      TwoFactorPin,
      ValidationCode: TwoFactorPin,
      WiFiCardSerial,
      PanelId: panelId,
      panelId,
    })
  }

  const resolver = yup.object({
    ssid: yup.string().min(2, t('WIFI_SSID_INFO_TEXT')),
    password: yup.string().min(2, t('WIFI_PASSWORD_INFO_TEXT')),
  })

  const defaultValues = {
    ssid: '',
    password: '',
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(resolver),
  })

  return (
    <AnimatedContainer>
      <FormContainer>
        {isLoading && (
          <LoaderContainer>
            <Loader types={['large']} />
          </LoaderContainer>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <FieldContainer>
            <Paragraph>{t('add_network_manually')}</Paragraph>
            <TextField
              id="SSID"
              label={t('WIFI_SSID')}
              {...register('ssid')}
              error={errors.ssid?.message}
              disabled={isLoading}
            />
            <TextField
              id="Password"
              label={t('WIFI_PASSWORD')}
              {...register('password')}
              error={errors.password?.message}
              type={showPassword ? 'text' : 'password'}
              disabled={isLoading}
            />

            <CrowCheckbox
              onChange={() => setShowPassword(!showPassword)}
              checked={showPassword}
              text={t('show_password')}
            />
            {!!wifiConnectError && wifiConnectError === '401statuscode' ? (
              <ErrorMessage text={t('wifi_unauthorized')} errorLocation="WifiConnectForm" />
            ) : (
              !!wifiConnectError && <ErrorMessage />
            )}
            <ButtonContainer>
              <Button disabled={isLoading} level="primary">
                {t('CONNECT')}
              </Button>
              <Button
                onClick={() => {
                  logFirebaseEvent('settings_wifi_connect_close_form')
                  onCancel()
                }}
                level="secondary"
              >
                {t('Cancel')}
              </Button>
            </ButtonContainer>
          </FieldContainer>
        </form>
      </FormContainer>
    </AnimatedContainer>
  )
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  padding: 24px;
`

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:nth-child(n + 2) {
    margin-top: 15px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  & > *:first-child {
    margin-right: 10px;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
