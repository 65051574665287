const DiningAreaIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 12H5V14H13V12Z" fill="#E3E1E1" />
      <path d="M43 12H35V14H43V12Z" fill="#E3E1E1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.92947 4C9.92947 3.44772 9.48176 3 8.92947 3C8.37719 3 7.92947 3.44772 7.92947 4V10C7.92947 10.0277 7.9306 10.0552 7.93282 10.0824C5.41732 10.503 3.43191 12.4886 3.01172 15.0042C2.92073 15.549 3.37719 16 3.92947 16H6.92947C6.92947 17.1046 7.8249 18 8.92947 18C10.034 18 10.9295 17.1046 10.9295 16H13.9295C14.4818 16 14.9382 15.549 14.8472 15.0042C14.427 12.4886 12.4416 10.503 9.92613 10.0824C9.92834 10.0552 9.92947 10.0277 9.92947 10V4ZM12.3943 14C11.7027 12.8044 10.41 12 8.92947 12C7.44891 12 6.15622 12.8044 5.4646 14H12.3943Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.9295 4C39.9295 3.44772 39.4818 3 38.9295 3C38.3772 3 37.9295 3.44772 37.9295 4V10C37.9295 10.0277 37.9306 10.0552 37.9328 10.0824C35.4173 10.503 33.4319 12.4886 33.0117 15.0042C32.9207 15.549 33.3772 16 33.9295 16H36.9295C36.9295 17.1046 37.8249 18 38.9295 18C40.034 18 40.9295 17.1046 40.9295 16H43.9295C44.4818 16 44.9382 15.549 44.8472 15.0042C44.427 12.4886 42.4416 10.503 39.9261 10.0824C39.9283 10.0552 39.9295 10.0277 39.9295 10V4ZM42.3943 14C41.7027 12.8044 40.41 12 38.9295 12C37.4489 12 36.1562 12.8044 35.4646 14H42.3943Z"
        fill="#333131"
      />
      <path
        d="M32.0001 32C32.0001 32.5523 32.4478 33 33.0001 33H33.9953V36H29.8126L27.9845 25.8378C27.8867 25.2942 27.3668 24.9328 26.8232 25.0306C26.2797 25.1284 25.9183 25.6483 26.0161 26.1919L27.9691 37.0487C27.9815 37.1175 28.0006 37.1833 28.0257 37.2456C28.1352 37.6792 28.5277 38 28.9953 38V44C28.9953 44.5523 29.443 45 29.9953 45C30.5476 45 30.9953 44.5523 30.9953 44V38H33.9953V44C33.9953 44.5523 34.443 45 34.9953 45C35.5476 45 35.9953 44.5523 35.9953 44V38H37.9953V44C37.9953 44.5523 38.443 45 38.9953 45C39.5476 45 39.9953 44.5523 39.9953 44V38C40.5476 38 40.9953 37.5523 40.9953 37C40.9953 36.4477 40.5476 36 39.9953 36H35.9953V33H43.9953C44.5476 33 44.9953 32.5523 44.9953 32C44.9953 31.449 44.5497 31.0021 43.9993 31H33.0001C32.4478 31 32.0001 31.4477 32.0001 32Z"
        fill="#333131"
      />
      <path
        d="M14.9952 33C15.5475 33 15.9952 32.5523 15.9952 32C15.9952 31.4477 15.5475 31 14.9952 31H3.99605C3.44558 31.0021 3 31.449 3 32C3 32.5523 3.44772 33 4 33H12V36H8.00005C7.44777 36 7.00005 36.4477 7.00005 37C7.00005 37.5523 7.44777 38 8.00005 38V44C8.00005 44.5523 8.44777 45 9.00005 45C9.55234 45 10.0001 44.5523 10.0001 44V38H12V44C12 44.5523 12.4477 45 13 45C13.5523 45 14 44.5523 14 44V38H17.0001V44C17.0001 44.5523 17.4478 45 18.0001 45C18.5523 45 19.0001 44.5523 19.0001 44V38C19.4676 38 19.8602 37.6792 19.9697 37.2456C19.9947 37.1833 20.0138 37.1175 20.0262 37.0487L21.9793 26.1919C22.077 25.6483 21.7157 25.1284 21.1721 25.0306C20.6285 24.9328 20.1086 25.2942 20.0108 25.8378L18.1828 36H14V33H14.9952Z"
        fill="#333131"
      />
    </svg>
  )
}

export { DiningAreaIcon }
