export const deriveInitials = (firstName: string, lastName: string) => {
  let firstNames = firstName.trim().split(' ')
  let lastNames = lastName.trim().split(' ')

  return firstNames
    .splice(0, 1)
    .concat(lastNames.slice(-1))
    .map((s) => s[0])
    .join('')
}
