import { TemperaturePreferenceValue } from '../types/Temperature'
import createHTTPClient from './createHTTPClient'

const HTTP = createHTTPClient('/panels')

export const getTemperaturePreference = async (panelId: string) => {
  return HTTP.get(`/${panelId}/personpreferences/platforms/Web/keys`)
}

export const postTemperaturePreference = async (
  panelId: string,
  payload: TemperaturePreferenceValue,
) => {
  return HTTP.put(`/${panelId}/personpreferences/platforms/Web/keys/TemperatureLiveApp`, payload)
}

export const deleteTemperaturePreference = async (panelId: string) => {
  return HTTP.put(`/${panelId}/personpreferences/platforms/Web/keys/TemperatureLiveApp/reset`)
}
