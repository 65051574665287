import React, { ElementRef, forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { WarningIcon } from '../../../assets/icons/crow'
import { colors } from '../Style/theme'

interface Props {
  onChange?: any
  onBlur?: any
  type?: string
  error?: string
  id?: string
  name?: string
  label?: string
  disabled?: boolean
  prefix?: ReactNode
  placeholder?: string
  value?: string
  autoComplete?: string
  postfix?: ReactNode
  maxLength?: number
  className?: string
}

const TextField: ForwardRefRenderFunction<ElementRef<'input'>, Props> = (
  {
    id = 'textfieldforform',
    type = 'text',
    name,
    label,
    disabled,
    prefix,
    placeholder,
    value,
    autoComplete = 'on',
    postfix,
    maxLength,
    onChange,
    onBlur,
    error,
    className,
  },
  ref,
) => {
  return (
    <Wrapper className={className}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Container disabled={disabled} error={error}>
        {prefix && (
          <Prefix content={prefix} disabled={disabled}>
            {prefix}
          </Prefix>
        )}
        <Input
          ref={ref}
          type={type}
          id={id}
          name={name}
          value={value}
          autoComplete={autoComplete}
          maxLength={maxLength}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          className="textfield-input qa-input-field"
        />
        {postfix && (
          <Postfix content={postfix} disabled={disabled}>
            {postfix}
          </Postfix>
        )}
        {error && (
          <Error disabled={disabled}>
            <WarningIcon />
          </Error>
        )}
      </Container>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Label = styled.label`
  font-family: 'Metric';
  font-weight: bold;
  line-height: 22px;
`

const Container = styled.div<{ border?: boolean; disabled?: boolean; error?: string }>`
  display: flex;
  align-items: center;
  padding: 15px;
  width: 100%;
  height: 54px;
  border-radius: 12px;
  background-color: ${({ disabled }) => (disabled ? colors.black200 : colors.white)};
  box-shadow: 0 0 0 1px ${colors.black200};
  ${({ error }) =>
    !!error &&
    css`
      box-shadow: 0 0 0 2px ${colors.red};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${colors.black200};
    `}

  &> input {
    height: inherit;
  }

  &:focus-within {
    box-shadow: 0 0 0 2px ${colors.blue};
  }
`

const Prefix = styled.span<{ content: ReactNode; disabled?: boolean }>`
  display: flex;
  align-items: center;
  margin-right: 12px;
  color: ${({ disabled }) => (disabled ? colors.black500 : colors.black700)};
  ${({ content }) =>
    typeof content === 'string'
      ? css`
          font-family: 'Metric Medium';
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
        `
      : css`
          font-size: 22px;
        `}
`

const Input = styled.input<Props>`
  margin: 0;
  border: none;
  border-radius: 12px;
  outline: none;
  padding: 14px 0;
  font-size: 20px;
  line-height: 22px;
  color: ${colors.black800};
  &:focus {
    color: ${colors.black700};
  }
  &:focus::placeholder {
    color: transparent;
  }
  &:disabled {
    border: none;
    background-color: ${colors.black200};
    color: ${colors.black500};
  }
`

const Postfix = styled.span<{ content: ReactNode; disabled?: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 12px;
  color: ${({ disabled }) => (disabled ? colors.black500 : colors.black700)};
  ${({ content }) =>
    typeof content === 'string'
      ? css`
          font-family: 'Metric';
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
        `
      : css`
          font-size: 22px;
        `}
`

const Error = styled.span<{ disabled?: boolean }>`
  color: ${colors.red};
  font-size: 22px;
  margin-left: 10px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.red200};
    `}
`

const ErrorMessage = styled.p`
  margin: 7px 0 0 16px;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.red};
`

export default forwardRef(TextField)
