import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CustomerFeedback {
  PersonId: string
  CustomerNumber: string
  AppVersion: string
  DeviceType: string
  Country: string
  Score: number
  Feedback: string
  CreatedDate?: string
  AppStoreDate?: string
  HasFeedback?: boolean
  IsFeedbackSelected?: boolean
  Message?: string
}

interface CustomerFeedbackStatus {
  CanAskForFeedback: boolean
  CanAskForNative: boolean
  HasDeclinedFeedback: boolean
}

const initialState: FeedbackState = {}

interface FeedbackState {
  feedbackStatus?: CustomerFeedbackStatus
}

const customerFeedbackSlice = createSlice({
  name: 'customerFeedback',
  initialState,
  reducers: {
    SEND_CUSTOMER_FEEDBACK(state) {
      if (state.feedbackStatus) {
        state.feedbackStatus.CanAskForFeedback = false
      }
    },
    DELETE_CUSTOMER_FEEDBACK(state, action: PayloadAction<CustomerFeedbackStatus[]>) {
      state.feedbackStatus = action.payload[0]
    },
    GET_CUSTOMER_FEEDBACK_STATUS(state, action: PayloadAction<CustomerFeedbackStatus>) {
      state.feedbackStatus = action.payload
    },
    GET_CUSTOMER_FEEDBACK(state, action: PayloadAction<{ data: CustomerFeedback }>) {},
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  SEND_CUSTOMER_FEEDBACK,
  GET_CUSTOMER_FEEDBACK_STATUS,
  GET_CUSTOMER_FEEDBACK,
  DELETE_CUSTOMER_FEEDBACK,
} = customerFeedbackSlice.actions

export const customerFeedbackReducer = customerFeedbackSlice.reducer
