import { format, isToday } from 'date-fns'

import { Heading3 } from '../../../components/Crow/Typography/Typography'
import useI18n from '../../../i18n/useI18n'
import { useDateFnsLocale } from '../../../utils/useDateFnsLocale'
import { tFunc } from '../../../types/i18n'

const formatEventGroupDay = (timestamp: Date, t: tFunc, options: { locale: Locale }) => {
  if (isToday(timestamp)) {
    return `${t('Today')}, `
  }
  return `${format(timestamp, 'EEEE', options)}, `
}

const formatEventGroupMonth = (timestamp: Date, options: { locale: Locale }) => {
  return `${format(timestamp, 'PPP', options)}`
}

interface EventListTitleProps {
  timestamp: string
}

const EventListTitle = ({ timestamp }: EventListTitleProps) => {
  const { t } = useI18n()
  const locale = useDateFnsLocale()
  const timestampToDate = new Date(timestamp)

  return (
    <Heading3>
      {formatEventGroupDay(timestampToDate, t, { locale })}
      <span className="font-light">
        {formatEventGroupMonth(timestampToDate, { locale: locale })}
      </span>
    </Heading3>
  )
}

export default EventListTitle
