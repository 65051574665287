const CheckmarkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9571 1.0434C18.3476 1.43393 18.3476 2.0671 17.9571 2.45761L7.45709 12.9571C7.06658 13.3476 6.43345 13.3476 6.04292 12.9572L0.792925 7.70763C0.402383 7.31712 0.402355 6.68396 0.792862 6.29341C1.18337 5.90287 1.81653 5.90284 2.20708 6.29335L6.74998 10.8359L16.5429 1.04337C16.9334 0.65285 17.5666 0.652864 17.9571 1.0434Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { CheckmarkIcon }
