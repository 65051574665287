import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import Button from '../../../../components/Button'
import Switch from '../../../../components/Switch'
import useI18n from '../../../../i18n/useI18n'
import { useUserInfo } from '../../../../services/requestHooks/user'
import { TranslationKey } from '../../../../types/generated/TranslationKey'
import { DoorLock, LanguageSettings, SoundLevel } from '../../../../types/Lock'
import { NationId } from '../../../../types/UserDetails'
import { usePanelId } from '../../hooks/usePanelId'
import './locks-form.scss'

const volumeOptions: { label: TranslationKey; level: SoundLevel }[] = [
  {
    label: 'LOCKSETTINGS_OFF',
    level: 0,
  },
  {
    label: 'Low',
    level: 1,
  },
  {
    label: 'High (recomended)',
    level: 2,
  },
]

export interface LockSettingsFormValues {
  language: string
  soundLevel: string
  autoLockEnabled: boolean
}

interface Props {
  lock: DoorLock
  onSubmit: (values: LockSettingsFormValues) => void
  lockLanguage: LanguageSettings
}

const LockSettingsForm: FC<Props> = ({ lock, onSubmit, lockLanguage }) => {
  const panelId = usePanelId()
  const { t } = useI18n()
  const User = useUserInfo()
  const showFGText = lock.AutoLockEnabled && User.NationId === NationId.NORWAY

  const {
    register,
    formState: { isDirty },
    reset,
    handleSubmit,
  } = useForm<LockSettingsFormValues>()

  useEffect(() => {
    reset({
      autoLockEnabled: lock.AutoLockEnabled,
      soundLevel: lock.SoundLevel?.toString() || SoundLevel.Unknown.toString(),
      language: lockLanguage.Selected?.Id,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lock])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="lock-settings">
        <div className="lock-setting mb-4">
          <label className="label" htmlFor="language">
            {t('Language')}
          </label>
          <div className="select ml-4">
            <select
              {...register('language')}
              defaultValue={lockLanguage.Selected?.Id}
              id="language"
            >
              {lockLanguage.Languages?.map((item) => (
                <option value={item.Id} key={item.Id}>
                  {t(item.Language)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="lock-setting mb-4">
          <fieldset className="flex flex-auto flex-wrap">
            <legend className="label">{t('Volume')}</legend>
            <div className="flex flex-auto justify-end">
              <input className="hidden" {...register('soundLevel')} type="radio" value={-1} />
              {volumeOptions.map(({ label, level }) => (
                <label key={label} className="px-2 radio ">
                  <input {...register('soundLevel')} type="radio" value={level} />
                  <i className="radio__indicator" />
                  {t(label)}
                </label>
              ))}
            </div>
          </fieldset>
        </div>
        <div className="lock-setting mb-4 flex flex-col">
          <div className="flex justify-between flex-auto">
            <label className="label" htmlFor="autoLockEnabled">
              {t('Automatic lock')}
            </label>
            <Switch
              {...register('autoLockEnabled')}
              initialValue={lock.AutoLockEnabled}
              unControlled
              id="autoLockEnabled"
            />
          </div>
          <div>
            <p className="small">{t('The door locks automaticly after 1 seconds when closed.')}</p>
            {showFGText && (
              <>
                <p>{t('Your lock is not FG approved when is not used with automatic lock.')}</p>
                <a href="https://www.sectoralarm.no/kundeservice/fg-informasjon/">
                  {t('Read more about FG approval')}
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4">
        {t('Want to change code on locks?')}
        <a className="ml-2" href={`/systems/${panelId}/settings/panel-users`}>
          {t('Go to panel user')}
        </a>
      </div>
      <div className="mt-4">
        <Button disabled={!isDirty} className="button button--primary button--small">
          {t('Save')}
        </Button>
        <Link to={`/systems/${panelId}`}>{t('Cancel')}</Link>
      </div>
    </form>
  )
}

export default LockSettingsForm
