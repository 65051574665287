const ChevronRightIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="c-icon"
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.306019 1.78168C-0.102006 1.37409 -0.102006 0.71327 0.306019 0.305687C0.714044 -0.101896 1.37558 -0.101896 1.78361 0.305688L7.69397 6.20964C7.91132 6.42676 8.01289 6.71573 7.99868 7.00001C8.01289 7.28428 7.91132 7.57325 7.69397 7.79037L1.78361 13.6943C1.37558 14.1019 0.714044 14.1019 0.306019 13.6943C-0.102006 13.2867 -0.102006 12.6259 0.306019 12.2183L5.53001 7.00001L0.306019 1.78168Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { ChevronRightIcon }
