import classNames from 'classnames'
import React from 'react'
import Button from '../../../../../components/Button'
import useI18n from '../../../../../i18n/useI18n'
import { SmartPlugTimeTrigger } from '../../../../../types/SmartPlug'
import { formatHour, formatMinutes } from '../../../../../utils/formatNumber'
import { days, selectedDay } from './common'
import './time-trigger-list-item.scss'

const TimeTriggerListItem = ({
  trigger,
  onClick,
  onDelete,
}: {
  trigger: SmartPlugTimeTrigger
  onClick: () => void
  onDelete: () => void
}) => {
  const { t } = useI18n()
  return (
    <li className="time-trigger">
      <div className="time-trigger__title text-2xl bold">
        {t(trigger.On ? 'On' : 'Off') +
          ` ${formatHour(trigger.Hour)}:${formatMinutes(trigger.Minute)}`}
      </div>
      <div className="flex justify-between sm:flex-nowrap flex-wrap">
        <div className="flex sm:flex-nowrap flex-wrap">
          {days.map((day) => (
            <div
              key={day}
              className={classNames('time-trigger__weekday', {
                'time-trigger__weekday--selected': selectedDay(trigger, day),
              })}
            >
              {t(day).substring(0, 2)}
            </div>
          ))}
        </div>
        <div className="flex flex-row flex-grow justify-end items-center">
          <Button onClick={onClick} size="small" buttonType="secondary">
            {t('Edit')}
          </Button>
          <Button onClick={onDelete} size="small" buttonType="third">
            {t('Delete')}
          </Button>
        </div>
      </div>
    </li>
  )
}

export default TimeTriggerListItem
