import { yupResolver } from '@hookform/resolvers/yup'
import cx from 'classnames'
import { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useSelector } from 'react-redux'
import Button from '../../../../../components/Button'
import TextField from '../../../../../components/forms/TextField'
import { phoneValidator } from '../../../../../components/forms/validators'
import useI18n from '../../../../../i18n/useI18n'
import { RootState } from '../../../../../store'
import { ContactPerson, PhoneNumberType } from '../../../../../types/ContactPerson'
import { TranslationKey } from '../../../../../types/generated/TranslationKey'
import { usePanelId } from '../../../hooks/usePanelId'
import PhoneNumbers from './PhoneNumbers'

type FormValues = Omit<ContactPerson, 'Type'>

export type ContactFormSubmit = (contact: FormValues) => void

type Props = {
  helpText?: string
  initialValues?: FormValues
  onSubmit?: ContactFormSubmit
  onCancel?: () => void
  onlyMobile?: boolean
  onSubmitText?: TranslationKey
  allowReuseOfPhonenumber?: boolean
}

const baseValidator = yup
  .string()
  .required('Required')
  .min(2, 'At least 2 characters is required')
  .max(30, 'SECURITY_QUESTION_TEXT_MAX_30')
  .matches(
    /^[\x20-\x7E\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178]*$/,
    'Special characters are not allowed',
  )

const defaultPhoneValidator = (translation1: string, translation2: string) =>
  yup
    .string()
    .matches(/^[0-9.+]*$/, translation1)
    .test('length', translation2, (val) => !val || val.length >= 7)

const noPhoneReuseValidator = (registeredPhoneNumbers: string[]) => {
  return phoneValidator.test(
    'already used',
    'This number is already registred.',
    (val) => !val || !registeredPhoneNumbers.includes(val),
  )
}

const resolverCreator = (
  registeredPhoneNumbers: string[],
  translation1: string,
  translation2: string,
  allowReuseOfPhonenumber?: boolean,
) => {
  if (allowReuseOfPhonenumber) {
    return yup.object<{ [key in keyof FormValues]: yup.AnySchema }>().shape({
      FirstName: baseValidator,
      LastName: baseValidator,
      Mobile: defaultPhoneValidator(translation1, translation2).nullable(),
      Home: defaultPhoneValidator(translation1, translation2).nullable(),
      Work: defaultPhoneValidator(translation1, translation2).nullable(),
    })
  } else {
    return yup.object<{ [key in keyof FormValues]: yup.AnySchema }>().shape({
      FirstName: baseValidator,
      LastName: baseValidator,
      Mobile: noPhoneReuseValidator(registeredPhoneNumbers).nullable(),
      Home: noPhoneReuseValidator(registeredPhoneNumbers).nullable(),
      Work: noPhoneReuseValidator(registeredPhoneNumbers).nullable(),
    })
  }
}

const ContactForm: FC<Props> = ({
  onSubmit,
  onCancel,
  initialValues,
  onlyMobile,
  helpText,
  onSubmitText = 'Save',
  allowReuseOfPhonenumber,
}) => {
  const { t } = useI18n()

  const [formError, setFormError] = useState<string>()

  const panelId = usePanelId()
  const registeredPhoneNumbers: string[] = useSelector((state: RootState) =>
    (state.appUsers[panelId]?.users || []).map(({ PhoneNumber }) => PhoneNumber),
  )

  const resolver = useMemo(() => {
    return yupResolver(
      resolverCreator(
        registeredPhoneNumbers,
        t('provide_valid_phone_number'),
        t('phone_number_length_requirement'),
        allowReuseOfPhonenumber,
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelId, allowReuseOfPhonenumber])

  const {
    register,
    formState: { isValid, isDirty, errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<FormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: resolver as any,
  })

  useEffect(() => {
    reset(initialValues ?? {})
  }, [initialValues, reset])

  const [Mobile, Home, Work] = watch(['Mobile', 'Home', 'Work'])

  useEffect(() => {
    if (isDirty) {
      if (!(Mobile || Home || Work)) {
        setFormError(t('at_least_one_number'))
      } else {
        setFormError(undefined)
      }
    }
  }, [Home, Mobile, Work, isDirty, t])

  const handleCancel = () => {
    reset()
    onCancel?.()
  }

  return (
    <form className="slide-from-right" onSubmit={handleSubmit(onSubmit!)}>
      <TextField
        className={'mt-4'}
        {...register('FirstName')}
        label="First name"
        autoComplete="given-name"
        errorKey={errors.FirstName?.message as TranslationKey}
      />
      <TextField
        {...register('LastName')}
        label="Last name"
        autoComplete="family-name"
        errorKey={errors.LastName?.message as TranslationKey}
      />

      {onlyMobile ? (
        <TextField
          {...register(PhoneNumberType.MOBILE)}
          label="Mobile"
          autoComplete="tel-national"
          errorKey={errors.Mobile?.message as TranslationKey}
          type="number"
        />
      ) : (
        <PhoneNumbers
          register={register}
          errors={errors}
          setValue={setValue}
          initialValues={initialValues}
        />
      )}
      <p
        className={cx(
          'notification',
          'notification--error',
          !(formError && isDirty) && 'invisible',
        )}
        aria-hidden={!(formError && isDirty)}
      >
        {formError || 'no error'}
      </p>
      {helpText && <span className="mt-8 block">{helpText}</span>}

      <div>
        <Button buttonType="primary" type="submit" disabled={!isDirty || !isValid || !!formError}>
          {t(onSubmitText)}
        </Button>
        <Button type="button" buttonType="link" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
      </div>
    </form>
  )
}

export default ContactForm
