import styled from 'styled-components'
import { colors } from '../../../components/Crow/Style/theme'
import { Paragraph } from '../../../components/Crow/Typography'

const IconContainer = styled.span<{ color?: string; border?: string }>`
  border-radius: 10px;
  background-color: ${({ color }) => (color ? color : colors.white)};
  border: ${({ border }) => (border ? '1px solid' : null)};
  border-color: ${({ border }) => (border ? border : null)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ border }) => (border ? '11px' : '12px')};
  margin-right: 16px;
`

const StyledParagraphNoEvents = styled(Paragraph)`
  padding-top: 20px;
  padding-bottom: 50px;
`

export { IconContainer, StyledParagraphNoEvents }
