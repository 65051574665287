import { motion } from 'framer-motion'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ErrorMessage } from '../../components/Crow/ErrorMessage/ErrorMessage'
import { CrowLoader } from '../../components/Crow/Loader/CrowLoader'
import { Paragraph } from '../../components/Crow/Typography'
import useI18n from '../../i18n/useI18n'
import { useLoadInvoicePDF, useLoadInvoices } from '../../services/requestHooks/invoice'
import { RootState } from '../../store'
import { useOnMount } from '../../utils/commonHooks'
import { InvoiceItem } from './InvoiceItem'

export const CrowInvoices = () => {
  const { t } = useI18n()
  const [loadingPDFs, setLoadingPFDs] = useState<string[]>([])
  const { InvoicesYears, Invoices } = useSelector((state: RootState) => state.invoices)
  const { run: loadInvoices, isLoading, error: invoicesError } = useLoadInvoices({})
  const { run: loadInvoicePDF } = useLoadInvoicePDF({
    onSuccess: () => setLoadingPFDs([]),
    onFailure: () => setLoadingPFDs([]),
  })

  useOnMount(() => {
    if (isLoading) return
    loadInvoices()
  })

  const handleLoadPDF = (invoiceNumber: string) => {
    setLoadingPFDs([...loadingPDFs, invoiceNumber])
    loadInvoicePDF(invoiceNumber)
  }

  return (
    <CrowInvoicesWrapper>
      {isLoading && <CrowLoader text={t('loading_invoices')} />}
      {invoicesError && (
        <ErrorMessage
          text={t('Could not load invoices. Please try again or contact customer services')}
          errorLocation="CrowInvoices"
        />
      )}

      <ListWrapper>
        {InvoicesYears.map((year) => {
          const yearInvoices = Invoices.filter((invoice) => invoice.year === year)
          return (
            <YearSection
              initial={{ opacity: 0.2 }}
              animate={{ opacity: 1, transition: { duration: 0.3 } }}
            >
              <Paragraph large>{year}</Paragraph>
              {yearInvoices.map((inv) => (
                <InvoiceItem
                  loadingPDF={loadingPDFs.includes(inv.invoiceNumber)}
                  invoice={inv}
                  onClick={() => handleLoadPDF(inv.invoiceNumber)}
                />
              ))}
            </YearSection>
          )
        })}
      </ListWrapper>
    </CrowInvoicesWrapper>
  )
}

const YearSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > *:first-child {
    margin-left: 8px;
    margin-top: 10px;
  }
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > *:nth-child(n + 2) {
    margin-top: 30px;
  }
`

const CrowInvoicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 40px;
  padding-left: 35px;
`
