import styled from 'styled-components'
import { Loader } from '../../../../components/Elements/Loaders'
import useI18n from '../../../../i18n/useI18n'
import { useNotificationSettings } from '../../../../services/requestHooks/notifications'
import { useOnMount } from '../../../../utils/commonHooks'
import { usePanelId } from '../../hooks/usePanelId'
import NotificationSettingList from './components/NotificationSettingList'

const Notifications = () => {
  const panelId = usePanelId()
  const { t } = useI18n()
  const {
    run: getNotificationSettings,
    data: notificationSettings,
    isLoading: loadingNotification,
  } = useNotificationSettings()

  useOnMount(() => {
    getNotificationSettings(panelId)
  })

  return (
    <>
      {loadingNotification ? (
        <LoaderContainer>
          <Loader types={['large']} />
          <span>{t('Loading notifications')}</span>
        </LoaderContainer>
      ) : (
        <>
          {notificationSettings &&
            notificationSettings.map((notificationSetting) => (
              <NotificationSettingList
                key={notificationSetting.TranslationKey}
                {...notificationSetting}
              />
            ))}
        </>
      )}
    </>
  )
}

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`

export default Notifications
