import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { version } from '../../../package.json'
import { CancelIcon } from '../../assets/icons/crow'
import Button from '../../components/Crow/Button/Button'
import { Clickable } from '../../components/Crow/Collapsible/Collapsible'
import ConfirmationPrompt, { useConfirmationPrompt } from '../../components/Crow/ConfirmationPrompt'
import Drawer from '../../components/Crow/Drawer/Drawer'
import { CrowLoader } from '../../components/Crow/Loader/CrowLoader'
import { colors } from '../../components/Crow/Style/theme'
import Toast, { addToast } from '../../components/Crow/Toast/Toast'
import { Heading3, Paragraph, Title } from '../../components/Crow/Typography'
import CustomerFeedbackForm from '../../components/forms/CustomerFeedbackForm/CustomerFeedbackForm'
import NavBarPage from '../../components/pages/NavBarPage'
import useI18n from '../../i18n/useI18n'
import { useGetCustomerFeedbackStatus } from '../../services/requestHooks/customerFeedback'
import { useUserInfo } from '../../services/requestHooks/user'
import { useDeleteMeFromPanel } from '../../services/requestHooks/users'
import { isTranslationKey } from '../../types/generated/TranslationKey'
import { UserFeature } from '../../types/UserDetails'
import { useOnMount } from '../../utils/commonHooks'
import { deriveInitials } from '../../utils/deriveInitials'
import { logFeedbackOpenEvent } from '../../utils/firebaseAnalytics'
import PasswordPrompt, { usePasswordPrompt } from '../AlarmSystems/Panel/PasswordPromt'
import { CrowInvoices } from '../CrowInvoices/CrowInvoices'
import { AccountDetails, Benefits, LanguageContent } from './drawerContent'
import { ChangePassword } from './drawerContent/ChangePassword'
import { getCustomerSupportUrl } from '../../i18n/supportUrls'

export const CrowAccountPage = () => {
  const user = useUserInfo()
  const history = useHistory()
  const { promptState: confirmationPromptState, prompt: promptForDelete } = useConfirmationPrompt()
  const { promptState: passwordPromptState, promptForPassword } = usePasswordPrompt()

  const { FirstName, LastName, CustomerNo, CellPhone, Features, CanDeleteOwnAccount } = user
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedMenuItem, setSelectedMenuItem] = useState(0)

  // Assume the user is legal owner in case the user IS a legal owner, but the panel info is corrupted / empty, when in reality not
  // In this case, hide the delete option
  const [openFeedbackForm, setOpenFeedbackForm] = useState(false)

  const {
    run: getFeedbackStatus,
    isLoading: isLoadingFeedbackStatus,
    data: feedbackStatus,
  } = useGetCustomerFeedbackStatus()

  const User = useUserInfo()
  const isInvoiceNation = [1, 2, 4].includes(User.NationId)

  useOnMount(() => {
    getFeedbackStatus({ personId: user.Id })
  })

  const { run: deleteMe, isLoading: loadingDeleteUser } = useDeleteMeFromPanel({
    onSuccess: () => {
      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: t('toast_delete_user_success_content', {
          name: user.FirstName.concat(user.FirstName, user.LastName),
        }),
      })
      history.push('/user/deleteSuccess')
    },
    onFailure: ({ message }) => {
      addToast({
        type: 'error',
        time: 5000,
        title: t('error_toast_generic_title'),
        content: isTranslationKey(message) ? t(message) : t('error_toast_generic_content'),
      })
    },
  })

  const setDrawerContent = (itemNumber: number) => {
    setOpenDrawer(true)
    setSelectedMenuItem(itemNumber)
  }

  const { t } = useI18n()
  const menuTitles = [t('account_info'), t('Language'), t('Password'), t('Benefits'), t('Invoices')]

  const onDeleteUserClick = async () => {
    try {
      await promptForDelete()
      const Password = await promptForPassword()
      if (Password) {
        deleteMe({ Password })
      }
    } catch (error) {}
  }

  return (
    <NavBarPage>
      <>
        <AccountContainer>
          <CustomerFeedbackForm
            open={openFeedbackForm}
            onClose={() => setOpenFeedbackForm(false)}
            onSuccess={() =>
              addToast({
                type: 'success',
                time: 5000,
                title: t('toast_generic_success_title'),
                content: t('feedback_thank_you'),
              })
            }
          />
          {loadingDeleteUser ? (
            <CrowLoader type="large" text={t('loading_deleting_account')} />
          ) : (
            <>
              <TopContainer>
                <TitleContainer>
                  <Title color={colors.black900}>{t('ACCOUNT')}</Title>
                  <Paragraph color={colors.black700}>{t('Account_text')}</Paragraph>
                </TitleContainer>
                <NameEmailContainer>
                  <div>
                    <AccountName>
                      {FirstName} {LastName}
                    </AccountName>
                    <AccountEmail>{user.UserName}</AccountEmail>
                  </div>
                  <AccountCircle>
                    <Paragraph>{deriveInitials(FirstName, LastName)}</Paragraph>
                  </AccountCircle>
                </NameEmailContainer>
              </TopContainer>
              <BottomContainer>
                <Heading3 color={colors.black800} style={{ marginBottom: '10px' }}>
                  {t('account_info')}
                </Heading3>
                <Clickable title={t('account_info')} onClick={() => setDrawerContent(0)} />
                <Clickable title={t('Language')} onClick={() => setDrawerContent(1)} />
                <Clickable title={t('Password')} rightContent>
                  <ChangePassword />
                </Clickable>
                {Features.includes(UserFeature.BENEFITS) && (
                  <Clickable title={t('Benefits')} onClick={() => setDrawerContent(3)} />
                )}
              </BottomContainer>
              <BottomContainer>
                <Heading3 color={colors.black800} style={{ marginBottom: '10px' }}>
                  {t('account_settings')}
                </Heading3>
                {isInvoiceNation && (
                  <Clickable title={t('Invoices')} onClick={() => setDrawerContent(4)} />
                )}
                <Clickable title={t('account_support_contact_page')} rightContent>
                  <Button
                    level="secondary"
                    onClick={() => window.open(getCustomerSupportUrl(user.NationId), '_blank')}
                  >
                    {t('OPEN')}
                  </Button>
                </Clickable>
                {CanDeleteOwnAccount && (
                  <Clickable title={t('ACCOUNT_REMOVE')} rightContent>
                    <Button variant="error" level="primary" onClick={() => onDeleteUserClick()}>
                      {t('ACCOUNT_REMOVE')}
                    </Button>
                  </Clickable>
                )}
              </BottomContainer>
              {!isLoadingFeedbackStatus && feedbackStatus?.feedbackStatus?.CanAskForFeedback && (
                <BottomContainer>
                  <Heading3 color={colors.black800} style={{ marginBottom: '10px' }}>
                    {t('app_feedback')}
                  </Heading3>
                  <Clickable
                    title={t('app_feedback_subtitle')}
                    onClick={() => {
                      setOpenFeedbackForm(true)
                      logFeedbackOpenEvent()
                    }}
                  />
                </BottomContainer>
              )}
              <Paragraph style={{ textAlign: 'center', marginTop: '20px', color: colors.black600 }}>
                {t('account_version', version)}
              </Paragraph>
            </>
          )}
        </AccountContainer>
        <Drawer
          isOpen={openDrawer}
          position="right"
          handleClose={() => setOpenDrawer(false)}
          closeButton={true}
          closeIcon={<CancelIcon color={colors.blue} />}
          title={
            <Header>
              <StyledTitle>{menuTitles[selectedMenuItem]}</StyledTitle>
            </Header>
          }
        >
          {selectedMenuItem === 0 ? (
            <AccountDetails
              firstName={FirstName}
              lastName={LastName}
              email={user.UserName}
              customerNumber={CustomerNo}
              cellPhone={CellPhone}
            />
          ) : selectedMenuItem === 1 ? (
            <LanguageContent closeDrawer={() => setOpenDrawer(false)} />
          ) : selectedMenuItem === 3 ? (
            <Benefits />
          ) : selectedMenuItem === 4 ? (
            <CrowInvoices />
          ) : (
            <></>
          )}
        </Drawer>
        <ConfirmationPrompt
          title={t('ACCOUNT_REMOVE')}
          text={t('ACCOUNT_REMOVE_MESSAGE')}
          promptState={confirmationPromptState}
          buttonProps={{ text: t('Confirm'), level: 'primary', variant: 'error' }}
        />
        <PasswordPrompt promptState={passwordPromptState} />
      </>
      <Toast />
    </NavBarPage>
  )
}

const Header = styled.div`
  padding-bottom: 50px;
`

const AccountContainer = styled.div<{ hideBackground?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ hideBackground }) =>
    !hideBackground &&
    `
      background-color: ${colors.lightGray};
      `}
  min-height: 90vh;
  & > * {
    width: 50%;
    min-width: 300px;
    margin-top: 10px;
  }
  padding-bottom: 20px;
`

const StyledTitle = styled(Title)`
  margin: 0;
  margin-left: 10px;
`

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;

  & > *:first-child {
    flex: 2;
    padding-right: 15px;
  }

  & > *:last-child {
    flex: 1;
    justify-content: flex-end;
  }
`

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > *:first-child {
    padding-left: 15px;
  }
  & > * {
    margin-top: 4px;
  }
`

const AccountName = styled.p`
  width: 100%;
  text-align: right;
  font-weight: bold;
  font-family: Metric;
  font-size: 18px;
  margin: 0;
  padding: 0;
  color: ${colors.black900};
`

const AccountEmail = styled.p`
  width: 100%;
  text-align: right;
  font-weight: bold;
  font-family: Metric;
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: ${colors.black900};
`

const AccountCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  min-width: 50px;
  width: 50px;
  background-color: ${colors.black900};
  border-radius: 100%;

  & > * {
    font-size: 20px;
    color: white;
    text-align: center;
  }
`
const NameEmailContainer = styled.div`
  margin-top: 20px;
  display: flex;
  & > *:first-child {
    margin-right: 16px;
  }
`

const TitleContainer = styled.div`
  & > *:last-child {
    padding-top: 16px;
  }
`
// eslint-disable-next-line import/no-unused-modules
export default CrowAccountPage
