import { ReactElement } from 'react'
import styled, { css } from 'styled-components'
import useI18n from '../../../i18n/useI18n'
import { usePanelInfo } from '../../../services/requestHooks/panelInfo'
import { TranslationKeyOverride } from '../../../types/TranslationKeyOverride'
import { colors } from '../Style/theme'
import { Hr } from '../Typography'
import NavigationIem from './NavigationItem'
import { systemRouteList, userRouteList } from './NavigationRoutes'

type Props = {
  className?: string
  minimized?: boolean
  onItemClick?: () => void
}

const NavigationMenu = ({ className, minimized = false, onItemClick }: Props): ReactElement => {
  const { t } = useI18n()
  const { data: panelInfo } = usePanelInfo()
  const hasHumidityReading = panelInfo?.HasHumidityReading

  return (
    <S.Sidebar data-testid="menu-mini-apps" minimized={minimized} className={className}>
      {Object.values(systemRouteList).map((navItem) => {
        if (!hasHumidityReading && navItem.itemName === 'Humidity') {
          return null
        }

        return (
          <NavigationIem
            key={navItem.path}
            path={navItem.path}
            itemName={navItem.itemName!}
            Icon={navItem.Icon}
            name={t(navItem.name! as TranslationKeyOverride)}
            onClick={onItemClick}
          />
        )
      })}
      <Hr className="mt-4 mb-4 ml-2 mr-2" color={colors.black500} />
      {Object.values(userRouteList).map((navItem) => {
        return (
          <NavigationIem
            key={navItem.path}
            path={navItem.path}
            itemName={navItem.itemName!}
            Icon={navItem.Icon}
            name={t(navItem.name! as TranslationKeyOverride)}
            onClick={onItemClick}
          />
        )
      })}
    </S.Sidebar>
  )
}

const S = {
  Sidebar: styled.div<{ minimized: boolean }>`
    padding-top: 1rem;
    background-color: ${colors.black900};
    color: ${colors.white};

    ${({ minimized }) =>
      minimized &&
      css`
        display: flex;
        flex-direction: column;
      `}
  `,
}

export default NavigationMenu
