import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Button from '../../../../../components/Button'
import useI18n from '../../../../../i18n/useI18n'
import { TimeSlot } from '../../../../../types/TimeSlot'
import RadioButtonField from '../components/RadioButtonField'
import TimeSlotItem from './TimeSlotItem'

export type TimeSlotFormSubmit = (values: FormValues) => void

type FormValues = {
  timeSlotIndex: string
}

const resolver = yup.object({
  timeSlotIndex: yup.string().required('Required'),
})

type Props = {
  timeSlots: TimeSlot[] | undefined
  onSubmit: TimeSlotFormSubmit
  onCancel: () => void
}

const TimeSlotForm: FC<Props> = ({ timeSlots = [], onSubmit, onCancel }) => {
  const { t } = useI18n()

  const {
    watch,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(resolver),
  })

  const selectedTimeSlotIndex = watch('timeSlotIndex')

  return (
    <form onSubmit={handleSubmit(onSubmit!)}>
      <RadioButtonField
        legend={
          <>
            <h1 className="text-3xl">{t('AVAILABLE_TIMESLOTS_HEADER')}</h1>
            <p className="pb-4">{t('AVAILABLE_TIMESLOTS_INFO')}</p>
          </>
        }
      >
        {timeSlots?.map((timeSlot, value) => (
          <TimeSlotItem
            {...register('timeSlotIndex')}
            key={value.toString()}
            timeSlot={timeSlot}
            value={value}
            isSelected={value.toString() === selectedTimeSlotIndex}
          />
        ))}
      </RadioButtonField>
      <div className="mt-6">
        <Button buttonType="primary" disabled={!isValid}>
          {t('Next')}
        </Button>
        <Button type="button" onClick={onCancel} buttonType="secondary">
          {t('Cancel')}
        </Button>
      </div>
    </form>
  )
}

export default TimeSlotForm
