import {
  Accesses,
  AddUserRequestBody,
  ChangeUserPinCodeRequestBody,
  DeleteMeRequestBody,
  DeleteUserRequestBody,
  PermanentUser,
  UpdateUserInformationRequestBody,
  UpdateUserInformationResponseBody,
  UpdateUserPrivilegesRequestBody,
} from '../types/PermanentUser'
import createHTTPClient from './createHTTPClient'

const HTTP = createHTTPClient('/persons')

export const resendEmailVerification = async () => {
  return HTTP.post('/me/resendemailverification')
}

export const getPermanentUsers = async (panelId: string) => {
  return HTTP.get(`/panels/${panelId}`)
}

export const getMyPanels = async () => {
  return HTTP.get(`/me/panels`)
}

export const updateUserInformation = async (
  personId: string,
  payload: UpdateUserInformationRequestBody,
) => {
  let response = HTTP.patch(`/${personId}`, payload)
  return await response.then(
    (userInformation: UpdateUserInformationResponseBody) => userInformation,
  )
}

export const updateUserPrivileges = async (
  panelId: string,
  personId: string,
  payload: UpdateUserPrivilegesRequestBody,
) => {
  let response = HTTP.patch(`/${personId}/panels/${panelId}`, payload)
  return await response.then((accesses: Accesses) => {
    return { accesses, personId }
  })
}

export const changeUserPinCode = async (
  panelId: string,
  personId: string,
  payload: ChangeUserPinCodeRequestBody,
) => {
  let response = HTTP.post(`/${personId}/panels/${panelId}/pincode`, payload)
  return await response.then(() => payload)
}

export const createNewUserForPanel = async (panelId: string, payload: AddUserRequestBody) => {
  let response = HTTP.post(`/panels/${panelId}`, payload)
  return await response.then((user: PermanentUser) => {
    return user
  })
}

export const deleteUserFromPanel = async (
  panelId: string,
  personId: string,
  payload: DeleteUserRequestBody,
) => {
  let response = HTTP.post(`/${personId}/panels/${panelId}/delete`, payload)
  return await response.then(() => {
    return personId
  })
}

export const deleteMeFromPanel = async (payload: DeleteMeRequestBody) => {
  let response = HTTP.post('/me/delete/', payload)
  return await response.then(() => {
    return payload
  })
}
