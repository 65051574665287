import React from 'react'

import Tooltip from '../../../../components/Crow/Tooltip'
import ButtonBox from '../../../../components/Crow/ButtonBox'
import { colors } from '../../../../components/Crow/Style/theme'
import useI18n from '../../../../i18n/useI18n'
import { Loader } from '../../../../components/Elements/Loaders'

interface Props {
  disabled: boolean
  onRemoveClick: any
  isRemoving: boolean
}

const RemoveContactButton = ({ disabled, onRemoveClick, isRemoving }: Props) => {
  const { t } = useI18n()

  return (
    <Tooltip text={t('system_must_be_disarmed_to_remove')} active={disabled}>
      <ButtonBox
        text={t('DIALOG_CONTACTS_REMOVE_TITLE')}
        textColor={colors.red}
        btnLevel="secondary"
        btnSize="s"
        btnVariant="error"
        onClick={onRemoveClick}
        disabled={disabled}
      >
        {isRemoving ? <Loader /> : t('REMOVE')}
      </ButtonBox>
    </Tooltip>
  )
}

export default RemoveContactButton
