const ConservatoryIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.28881 0.0425538C0.760022 -0.116805 0.202162 0.182675 0.0427993 0.711469C-0.116563 1.24026 0.18292 1.79812 0.711713 1.95749L37 12.7431V37H20V36.3273L23.027 33.3002C25.2684 34.3476 27.5725 34.0315 29.2905 32.4319C31.1199 30.7286 32.1039 27.7413 31.8821 23.9705C31.8524 23.4644 31.4486 23.0607 30.9426 23.0309C27.1718 22.8091 24.1845 23.7931 22.4812 25.6226C20.8817 27.3406 20.5655 29.6446 21.6129 31.886L20 33.4989V14C20 13.4477 19.5523 13 19 13C18.4477 13 18 13.4477 18 14V27.5858L16.3002 25.886C17.3476 23.6446 17.0314 21.3406 15.4319 19.6226C13.7286 17.7931 10.7413 16.8091 6.97044 17.0309C6.46438 17.0607 6.06066 17.4644 6.03089 17.9705C5.80908 21.7413 6.79312 24.7286 8.62254 26.4319C10.3406 28.0315 12.6447 28.3476 14.886 27.3002L18 30.4142V37H1C0.447716 37 0 37.4477 0 38C0 38.5523 0.447716 39 1 39H18V40C18 40.5523 18.4477 41 19 41C19.5523 41 20 40.5523 20 40V39H41C41.5523 39 42 38.5523 42 38C42 37.4477 41.5523 37 41 37H39V12.7488C39 11.8638 38.4184 11.084 37.5701 10.8317L1.28881 0.0425538ZM14.7547 24.3405L10.6202 20.206C10.2297 19.8155 9.59654 19.8155 9.20601 20.206C8.81549 20.5965 8.81549 21.2297 9.20601 21.6202L13.3405 25.7547C12.0453 26.1296 10.8833 25.8041 9.98538 24.9681C8.85985 23.9202 7.99717 21.9237 7.99971 18.9997C10.9236 18.9972 12.9202 19.8599 13.9681 20.9854C14.804 21.8833 15.1295 23.0452 14.7547 24.3405ZM23.1584 30.3404L27.2928 26.206C27.6833 25.8155 28.3165 25.8155 28.707 26.206C29.0975 26.5965 29.0975 27.2297 28.707 27.6202L24.5726 31.7547C25.8678 32.1296 27.0298 31.8041 27.9277 30.9681C29.0532 29.9202 29.9158 27.9237 29.9133 24.9997C26.9894 24.9972 24.9929 25.8599 23.945 26.9854C23.1091 27.8833 22.7836 29.0452 23.1584 30.3404Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
    </svg>
  )
}

export { ConservatoryIcon }
