import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConnectWifiPayload, DisconnectWifiPayload } from '../services/apiPanel'
import { WifiDetails } from '../types/WifiDetails'

type WifiState = Record<
  string,
  {
    wifiList: WifiDetails[]
  }
>

const initialState: WifiState = {}

const wifiSlice = createSlice({
  name: 'wifi',
  initialState,
  reducers: {
    SET_WIFI_LIST(state, action: PayloadAction<{ data: WifiDetails[]; panelId: string }>) {
      const { data, panelId } = action.payload
      state[panelId] = { wifiList: data }
    },
    CONNECT_TO_WIFI(state, action: PayloadAction<{ panelId: string; data: ConnectWifiPayload }>) {
      const { panelId, data } = action.payload
      if (!!data) {
        state[panelId]?.wifiList.forEach((item) => {
          item.Connected = 'False'
        })
        const changeItemList = state[panelId]?.wifiList.filter((item) => item.Name === data.Ssid)
        if (changeItemList && changeItemList.length > 0) {
          changeItemList[0].Connected = 'True'
        }
      }
    },
    DISCONNECT_FROM_WIFI(
      state,
      action: PayloadAction<{ panelId: string; data: DisconnectWifiPayload }>,
    ) {
      const { panelId, data } = action.payload
      if (!!data) {
        const changeItemList = state[panelId]?.wifiList.filter(
          (item) => item.Name === data.WifiDetails?.Name,
        )
        if (changeItemList && changeItemList.length > 0) {
          changeItemList[0].Connected = 'False'
        }
      }
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const { SET_WIFI_LIST, CONNECT_TO_WIFI, DISCONNECT_FROM_WIFI } = wifiSlice.actions

export const wifiReducer = wifiSlice.reducer
