const CheckmarkIconLarge = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.6905 19.7234C37.09 19.342 37.1047 18.709 36.7234 18.3095C36.342 17.91 35.709 17.8953 35.3095 18.2766L21.3333 31.6176L14.6905 25.2766C14.291 24.8953 13.658 24.91 13.2767 25.3095C12.8953 25.709 12.91 26.342 13.3095 26.7234L20.6428 33.7234C21.0292 34.0922 21.6373 34.0922 22.0237 33.7234L36.6905 19.7234Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0ZM2 25C2 12.2975 12.2975 2 25 2C37.7025 2 48 12.2975 48 25C48 37.7025 37.7025 48 25 48C12.2975 48 2 37.7025 2 25Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { CheckmarkIconLarge }
