import React from 'react'
import styled from 'styled-components'

import useI18n from '../../../i18n/useI18n'
import TextField from '../../Crow/Input/TextFieldForForm'

const UserInformation = ({ register, errors }: any) => {
  const { t } = useI18n()

  return (
    <Wrapper>
      <TextField
        id="firstName"
        label={t('First name')}
        {...register('FirstName')}
        error={errors.firstName?.message}
      />
      <TextField
        id="lastName"
        label={t('Last name')}
        {...register('LastName')}
        error={errors.lastName?.message}
      />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export default UserInformation
