import { format } from 'date-fns'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import packageJson from '../../../../package.json'
import { InfoIcon } from '../../../assets/icons/crow'
import useI18n from '../../../i18n/useI18n'
import { useSendCustomerFeedback } from '../../../services/requestHooks/customerFeedback'
import { useUserInfo } from '../../../services/requestHooks/user'
import Button from '../../Crow/Button/Button'
import TextField from '../../Crow/Input/TextFieldForForm'
import Modal from '../../Crow/Modal/Modal'
import { colors } from '../../Crow/Style/theme'
import { Heading3, Paragraph } from '../../Crow/Typography'
import { RatingPicker } from './RatingPicker'
import { Loader } from '../../Elements/Loaders'
import { ErrorMessage } from '../../Crow/ErrorMessage/ErrorMessage'
import { logFeedbackSentEvent, logFeedbackRatingDetails } from '../../../utils/firebaseAnalytics'
import { brand } from '../../../configs'

interface FeedbackFormProps {
  open: boolean
  onSuccess: () => void
  onClose: () => void
}

type FormValues = {
  rating: number
  feedbackMessage: string
}

const CustomerFeedbackForm = ({ open, onClose, onSuccess }: FeedbackFormProps) => {
  const { t } = useI18n()
  const userInfo = useUserInfo()

  const {
    run: sendCustomerFeedback,
    isLoading: isLoadingSend,
    error: feedbackError,
  } = useSendCustomerFeedback({
    onSuccess: () => {
      onSuccess()
      onClose()
    },
  })

  const defaultValues = {
    rating: -2,
    feedbackMessage: '',
  }

  const onSubmit = async ({ rating, feedbackMessage }: FormValues) => {
    logFeedbackSentEvent()
    logFeedbackRatingDetails(rating, feedbackMessage)
    sendCustomerFeedback({
      PersonId: userInfo.Id,
      CustomerNumber: userInfo.CustomerNo,
      AppVersion: packageJson.version,
      DeviceType: packageJson.name,
      Country: userInfo.UserCultureInfo,
      Score: rating,
      Feedback: feedbackMessage,
      CreatedDate: format(new Date(), 'dd-MM-yy'),
      HasFeedback: feedbackMessage ? true : false,
      IsFeedbackSelected: true,
    })
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onTouched',
  })

  const company = brand === 'phonewatch' ? 'Phonewatch ' : 'Sector Alarm '

  return (
    <>
      {open && (
        <Modal alignRightBottom>
          <CloseButton
            name="Close"
            onClick={() => {
              reset()
              onClose()
            }}
          >
            {t('skip')}
          </CloseButton>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container>
              <StyledH3>{t('feedback_form_subtitle_web')}</StyledH3>
              <RatingPicker
                onClickRating={(ratingValue) => {
                  setValue('rating', ratingValue)
                }}
                range={10}
                {...register('rating')}
              />
              <InputContainer>
                <TextField
                  {...register('feedbackMessage')}
                  error={errors?.feedbackMessage?.message}
                  placeholder={t('feedback_share_opinion')}
                  disabled={isLoadingSend}
                />
              </InputContainer>
              <InfoContainer>
                <InfoIconContainer>
                  <InfoIcon color={colors.blue} width={14} height={14} />
                </InfoIconContainer>
                <Paragraph color={colors.black700}>{t('feedback_form_first_disclaimer')}</Paragraph>
              </InfoContainer>
              {isLoadingSend ? (
                <Loader />
              ) : (
                <Button size="l" disabled={watch('rating') <= 0} level="primary">
                  {t('feedback_form_send_button_text')}
                </Button>
              )}

              <BottomTextContainer>
                <InfoStorageText light color={colors.black800}>
                  {t('feedback_form_second_disclaimer', company)}
                </InfoStorageText>
              </BottomTextContainer>
              {feedbackError && <ErrorMessage text={feedbackError} />}
            </Container>
          </form>
        </Modal>
      )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 100%;

  & > * {
    width: 100%;
  }
`

const InfoStorageText = styled(Paragraph)`
  font-size: 12px;
  width: 269px;
  line-height: 16px;
`

const StyledH3 = styled(Heading3)`
  margin-bottom: 35px;
  text-align: center;
  margin-top: 20px;
`

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 32px;
  color: ${colors.blue};
  font-size: 16px;
  font-weight: lighter;
  &:focus {
    outline: 0;
  }
`

const InfoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;

  & > *:last-child {
    color: ${colors.darkGray};
    margin-left: 9px;
  }
`

const InfoIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
`

const BottomTextContainer = styled.div`
  margin-top: 8px;
  & > * {
    text-align: center;
  }
`

export default CustomerFeedbackForm
