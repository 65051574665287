import { FC, Fragment, useState } from 'react'
import styled from 'styled-components'
import { HumidityIcon } from '../../../assets/icons'
import { CancelIcon } from '../../../assets/icons/crow'
import BackButton from '../../../components/BackButton'
import Drawer from '../../../components/Crow/Drawer/Drawer'
import { colors } from '../../../components/Crow/Style/theme'
import Tile from '../../../components/Crow/Tile/Tile'
import { Heading3, Title } from '../../../components/Crow/Typography'
import FullscreenPage from '../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import { displayNewNavigationMenu } from '../../../configs'
import useI18n from '../../../i18n/useI18n'
import { useHumidity } from '../../../services/requestHooks/humidity'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { useSmokeDetectors } from '../../../services/requestHooks/smokeDetectors'
import { useOnMount } from '../../../utils/commonHooks'
import FloorName from '../../../utils/places/FloorName'
import RoomName from '../../../utils/places/RoomName'
import { RoomSwitchIcon } from '../../../utils/places/RoomSwitchIcon'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import { StyledUl } from '../SmokeDetectors/SmokeDetectorStyle'
import HumidityDetails from './HumidityDetails'
import HumidityTileFooter from './HumidityTileFooter'
import { logBackEvent } from '../../../utils/firebaseAnalytics'
import { HumidityDevice, Section } from '../../../types/Humidity'

const CrowHumidityPage: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const returnUrl = `/systems/${panelId}`
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false)
  const [humidityState, setHumidityState] = useState<Section>()

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
    error: panelStatusError,
  } = usePanelStatus()

  const {
    run: getSmokeDetectors,
    isLoading: loadingSmokeDetectors,
    error: SmokeDetectorError,
  } = useSmokeDetectors()

  const { data: humidity, run: getHumidity, isLoading: loadingHumidity } = useHumidity()

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadInfoError,
  } = usePanelInfo()

  const hasError = !!SmokeDetectorError || !!panelStatusError || !!loadInfoError

  useOnMount(() => {
    if (!loadingPanelInfo) {
      loadPanelInfo(panelId)
    }
    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
    if (!loadingSmokeDetectors) {
      getSmokeDetectors(panelId)
    }

    if (!loadingHumidity) {
      getHumidity(panelId)
    }
  })

  const openDrawer = (
    floors: Section,
    roomName: string,
    roomKey: string,
    roomIndex: number | undefined,
    roomDevices: HumidityDevice[],
    averageHumidity?: number,
  ) => () => {
    setHumidityState({
      Name: floors?.Name!,
      Key: floors?.Key!,
      Places: [
        {
          Name: roomName,
          Key: roomKey,
          Index: roomIndex,
          Components: roomDevices,
          AverageHumidity: averageHumidity!,
        },
      ],
    })
    setDrawerIsOpen(true)
  }

  return (
    <>
      <TcOrCrowContainer panelInfo={panelInfo} fullscreen footer>
        <FullscreenPage
          isLoading={loadingSmokeDetectors || loadingPanelStatus}
          loaderProps={{
            loadingText: { text: t('Loading') },
            errorHandling: {
              loadingFailed: !loadingSmokeDetectors && hasError && !panelStatus?.IsOnline,
              errorHeaderText: t('HUMIDITY'),
              errorText: !panelStatus?.IsOnline
                ? t('You can not control humidity while you are offline')
                : t('Something went wrong. Please try again'),
            },
          }}
        >
          {!displayNewNavigationMenu && (
            <BackButton backUrl={returnUrl} onClick={() => logBackEvent('humidity')} />
          )}
          <Title large>{t('HUMIDITY')}</Title>
          <>
            <StyledUl>
              {humidity?.Sections?.map((floor) => (
                <li key={floor.Key}>
                  <SectionTitle className="qa-section-name">
                    <FloorName floorName={floor.Key} />
                  </SectionTitle>
                  <TilesWrapper className={`qa-${floor.Key}`}>
                    {floor.Places.map((room) => (
                      <Tile
                        key={room.Key}
                        onClick={openDrawer(
                          floor,
                          room.Name,
                          room.Key!,
                          room.Index,
                          room.Components,
                        )}
                      >
                        <TileTop aria-label="tile-content">
                          <StyledHumiditySubtitle className="qa-place-average-humidity">
                            {Math.round(room.AverageHumidity)}%
                          </StyledHumiditySubtitle>
                          <HumidityIcon />
                        </TileTop>
                        <TileCenter className="qa-place-name">
                          <RoomName roomName={room.Key} />
                          {room.Index && room.Index > 0 ? <span>&nbsp;{room.Index}</span> : null}
                        </TileCenter>
                        <TileBottom>
                          <FooterDevices className="qa-sensors-count">{t('Sensors')}</FooterDevices>
                          <HumidityTileFooter props={room.Components} />
                        </TileBottom>
                      </Tile>
                    ))}
                  </TilesWrapper>
                </li>
              ))}
              <Drawer
                isOpen={drawerIsOpen}
                position="right"
                handleClose={() => setDrawerIsOpen(false)}
                closeButton={true}
                closeIcon={<CancelIcon color={colors.blue} />}
                title={
                  <Header>
                    {humidityState?.Places?.map((room) => (
                      <Fragment key={room.Key}>
                        <span className="mr-auto qa-humidity-details-place">
                          <RoomName roomName={room.Key} />{' '}
                          {room.Index && room.Index > 0 ? <span>{room.Index}&nbsp;</span> : null}(
                          {<FloorName floorName={humidityState.Key} />})
                        </span>
                        <IconWrapper>
                          <RoomSwitchIcon roomKey={room.Key} />
                        </IconWrapper>
                      </Fragment>
                    ))}
                  </Header>
                }
              >
                <HumidityDetails floors={humidityState!} />
              </Drawer>
            </StyledUl>
          </>
        </FullscreenPage>
      </TcOrCrowContainer>
    </>
  )
}

const SectionTitle = styled(Heading3)`
  margin-left: 8px;
  color: ${colors.black700};
`

const TilesWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media only screen and (max-width: 600px) {
    gap: 15px;
  }
`

const StyledHumiditySubtitle = styled(Title)`
  margin: 0;
  padding-bottom: 10px;
  font-size: 56px;
`

const FooterDevices = styled.span`
  font-size: 12px;
  color: ${colors.black900};
`

const TileTop = styled.div`
  display: flex;
  justify-content: space-between;
`

const TileCenter = styled(Heading3)`
  margin-top: 9px;
  display: flex;
  align-items: center;
  line-height: 22px;
  color: ${colors.black800};
  max-width: 138px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const TileBottom = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  padding-bottom: 32px;
  display: flex;
`

const IconWrapper = styled.span`
  position: relative;
  bottom: 15px;
`

export default CrowHumidityPage
