import CrowToggle from './components/CrowToggle'
import DomoPanelPage from './pages/AlarmSystems/Panel/PanelPage'
import CrowPhotoPage from './pages/crow/CrowPhotoPage'
import CrowVideoPage from './pages/crow/CrowVideoPage'
import CrowDoorAndWindowPage from './pages/crow/DoorAndWindowPage/CrowDoorsAndWindowsPage'
import CrowLeakageDetectorsPage from './pages/crow/LeakageDetectors/CrowLeakageDetectors'
import CrowHumidityPage from './pages/crow/HumidityPage/CrowHumidityPage'
import CrowLocksPage from './pages/crow/Locks/CrowLocksPage'
import CrowPanelPage from './pages/crow/PanelPage/CrowPanelPage'
import CrowContactsPage from './pages/crow/PeoplePage/contacts/CrowContactsPage'
import CrowPeoplePage from './pages/crow/PeoplePage/CrowPeoplePage'
import CrowUsersPage from './pages/crow/PeoplePage/users/CrowUsersPage'
import CrowSmartPlugsPage from './pages/crow/SmartPlugsPage/CrowSmartPlugsPage'
import CrowSmokeDetectorsPage from './pages/crow/SmokeDetectors/CrowSmokeDetectors'
import CrowTemperaturePage from './pages/crow/TemperaturePage/CrowTemperaturePage'

export const PanelPage = () => {
  return <CrowToggle CrowComponent={CrowPanelPage} DomoComponent={DomoPanelPage} />
}

export const LocksPage = () => {
  return <CrowToggle CrowComponent={CrowLocksPage} />
}

export const SmartPlugsPage = () => {
  return <CrowToggle CrowComponent={CrowSmartPlugsPage} />
}

export const TemperaturesPage = () => {
  return <CrowToggle CrowComponent={CrowTemperaturePage} />
}

export const HumidityPage = () => {
  return <CrowToggle CrowComponent={CrowHumidityPage} />
}

export const PeoplePage = () => {
  return <CrowToggle CrowComponent={CrowPeoplePage} />
}

export const UsersPage = () => {
  return <CrowToggle CrowComponent={CrowUsersPage} />
}

export const ContactsPage = () => {
  return <CrowToggle CrowComponent={CrowContactsPage} />
}

export const VideoPage = () => {
  return <CrowToggle CrowComponent={CrowVideoPage} />
}

export const PhotoPage = () => {
  return <CrowToggle CrowComponent={CrowPhotoPage} />
}

export const DoorAndWindowPage = () => {
  return <CrowToggle CrowComponent={CrowDoorAndWindowPage} />
}

export const SmokeDetectorsPage = () => {
  return <CrowToggle CrowComponent={CrowSmokeDetectorsPage} />
}

export const LeakageDetectorsPage = () => {
  return <CrowToggle CrowComponent={CrowLeakageDetectorsPage} />
}
