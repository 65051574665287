const BatteryLowIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 4C5 3.44772 4.55228 3 4 3C3.44772 3 3 3.44772 3 4V11C3 11.5523 3.44772 12 4 12C4.55228 12 5 11.5523 5 11V4Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 0C18.1307 0 19.25 1.11929 19.25 2.5V12.5C19.25 13.8807 18.1307 15 16.75 15H2.5C1.11929 15 0 13.8807 0 12.5V2.5C0 1.11929 1.11929 0 2.5 0H16.75ZM17.25 2.5C17.25 2.22386 17.0261 2 16.75 2H2.5C2.22386 2 2 2.22386 2 2.5V12.5C2 12.7761 2.22386 13 2.5 13H16.75C17.0261 13 17.25 12.7761 17.25 12.5V2.5Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M21 3.75C21.5523 3.75 22 4.19772 22 4.75V10.75C22 11.3023 21.5523 11.75 21 11.75C20.4477 11.75 20 11.3023 20 10.75V4.75C20 4.19772 20.4477 3.75 21 3.75Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { BatteryLowIcon }
