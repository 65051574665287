import { MouseEventHandler, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../Style/theme'

const variantColors = {
  default: {
    color: colors.blue,
    activeColor: colors.lightBlue,
  },
  warning: {
    color: colors.yellow,
    activeColor: colors.yellow200,
  },
  error: {
    color: colors.red,
    activeColor: colors.red200,
  },
  white: {
    color: colors.white,
    activeColor: colors.lightGray,
  },
}

export type Props = {
  type?: 'button' | 'submit' | 'reset'
  level: 'primary' | 'secondary'
  size?: 'xl' | 'l' | 'm' | 's'
  variant?: 'default' | 'warning' | 'error' | 'white'
  className?: string
  children: ReactNode
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

type ButtonSize = 'xl' | 'l' | 'm' | 's'

const SizeCSS = (size: ButtonSize) => {
  if (size === 'xl') {
    return css`
      height: 64px;
      font-size: 22px;
      padding: 16px 24px;
    `
  } else if (size === 'l') {
    return css`
      height: 48px;
      font-size: 16px;
      padding: 12px 16px;
    `
  } else if (size === 'm') {
    return css`
      height: 32px;
      font-size: 14px;
      padding: 8px 12px;
    `
  }
  return css`
    height: 24px;
    font-size: 14px;
    padding: 4px 8px;
    text-transform: uppercase;
  `
}

const Button = ({
  type,
  level,
  size = 'm',
  variant = 'default',
  children,
  className,
  disabled = false,
  onClick,
}: Props) => {
  const colors = variantColors[variant]
  return (
    <StyledButton
      type={type}
      level={level}
      size={size}
      {...colors}
      className={className}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  )
}

const StyledButton = styled.button<{
  color: string
  activeColor: string
  level: 'primary' | 'secondary'
  size: ButtonSize
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  background-color: ${({ level, color }) => (level === 'primary' ? color : 'transparent')};
  color: ${({ level, color }) => (level === 'primary' ? colors.white : color)};
  ${({ size }) => SizeCSS(size)};
  border: 2px solid;
  border-color: ${({ color }) => color};
  border-radius: 999px;

  &:hover {
    ${({ level, activeColor }) => {
      if (level === 'primary') {
        return css`
          background-color: ${activeColor};
          border-color: ${activeColor};
        `
      }
      return css`
        background-color: transparent;
        color: ${activeColor};
      `
    }}
  }

  &:focus-within {
    ${({ level, activeColor }) => {
      if (level === 'primary') {
        return css`
          background-color: ${activeColor};
          color: ${colors.white};
          border-color: ${activeColor};
        `
      }
      return css`
        background-color: transparent;
        color: ${activeColor};
        border-color: ${activeColor};
      `
    }}
  }

  &:disabled {
    pointer-events: none;
    background-color: transparent;
    ${({ level }) => {
      if (level === 'primary') {
        return css`
          background-color: ${colors.black200};
          color: ${colors.black500};
          border-color: ${colors.black200};
        `
      }
      return css`
        background-color: transparent;
        color: ${colors.black500};
        border-color: ${colors.black500};
      `
    }}
  }
`

export default Button
