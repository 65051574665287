const WarningIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 4.5C10.5523 4.5 11 4.94772 11 5.5V10.375C11 10.9273 10.5523 11.375 10 11.375C9.44771 11.375 9 10.9273 9 10.375V5.5C9 4.94772 9.44771 4.5 10 4.5Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M11.5 14.125C11.5 14.9534 10.8284 15.625 10 15.625C9.17157 15.625 8.5 14.9534 8.5 14.125C8.5 13.2966 9.17157 12.625 10 12.625C10.8284 12.625 11.5 13.2966 11.5 14.125Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { WarningIcon }
