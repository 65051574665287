import cx from 'classnames'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { TCSmartplugIcon } from '../../../../assets/icons'
import { Loader } from '../../../../components/Elements/Loaders'
import Switch from '../../../../components/Switch'
import ExpandableList, {
  ExpandableListItem,
} from '../../../../components/system/components/ExpandableList'
import useI18n from '../../../../i18n/useI18n'
import { selectIsExpandedMenu, toggleExpandableMenu } from '../../../../reducers/panelInfoSlice'
import {
  useGetSmartplugsStatus,
  useTurnOffTCSmartplug,
  useTurnOnTCSmartplug,
} from '../../../../services/requestHooks/plugs'
import { useUserInfo } from '../../../../services/requestHooks/user'
import { RootState } from '../../../../store'
import { PanelAccess } from '../../../../types/PanelModel'
import { SmartPlug } from '../../../../types/SmartPlug'
import { logSmartplugEvent } from '../../../../utils/firebaseAnalytics'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../hooks/usePanelId'

// TODO: Find all urls that should be here
const nationUrlMapping: Record<number, string> = {
  2: 'http://www.sectoralarm.no/boligalarm/smartplugger/',
  1: 'http://www.sectoralarm.se/hemlarm/smartplugs/',
}

const SystemPlugs: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const [loadingPlug, setLoadingPlug] = useState<string | undefined>(undefined)
  const accesses = useSelector((state: RootState) => state.panel[panelId]?.Info?.Access || [])
  const { NationId } = useUserInfo()
  const plugsWithoutStatus = useSelector(
    (state: RootState) => state.panel[panelId]?.Info?.Smartplugs,
  )

  const { run: turnOn, isLoading: isTurningOn, error: turnOnError } = useTurnOnTCSmartplug()
  const { run: turnOff, isLoading: isTurningOff, error: turnOffError } = useTurnOffTCSmartplug()
  const isToggling = isTurningOff || isTurningOn
  const toggleError = turnOnError || turnOffError

  const {
    run: loadPlugs,
    data: plugsWithStatus,
    isLoading: loadingPlugs,
    error: plugsError,
  } = useGetSmartplugsStatus()

  const dispatch = useDispatch()
  const isOpen = useSelector(selectIsExpandedMenu({ panelId, menu: 'plugs' }))

  const handleHidePlugs = () => dispatch(toggleExpandableMenu({ panelId, menu: 'plugs' }))
  const handleLoadAndShowPlugs = () => {
    dispatch(toggleExpandableMenu({ panelId, menu: 'plugs' }))
    loadPlugs(panelId)
  }

  const hasSmartPlugAccess = accesses.includes(PanelAccess.SmartplugSettings)

  // TODO: Handle multiple ongoing requests
  const handleTogglePlug = ({ Status, Id }: SmartPlug) => {
    setLoadingPlug(Id)
    if (Status.toLocaleLowerCase() === 'on') {
      logSmartplugEvent('OFF', 'TC')
      turnOff({ panelId, Id })
    }

    if (Status.toLocaleLowerCase() === 'off') {
      logSmartplugEvent('ON', 'TC')
      turnOn({ panelId, Id })
    }
  }

  const plugs = plugsWithStatus || plugsWithoutStatus || []

  const hasStatus = (plug: SmartPlug) => {
    return plug.Status === 'On' || plug.Status === 'Off'
  }

  return (
    <ExpandableList
      open={isOpen}
      onClick={isOpen ? handleHidePlugs : handleLoadAndShowPlugs}
      icon={<TCSmartplugIcon />}
      summary={<h2>{t('Smart plugs')}</h2>}
      error={plugsError ? t('Failed to load smart plugs status') : undefined}
    >
      {plugs.length === 0 && !loadingPlugs ? (
        <p>
          {t("You don't have any")}
          <br />
          <a target="_blank" rel="noopener noreferrer" href={nationUrlMapping[NationId]}>
            {t('Read more about smart plugs')}
          </a>
        </p>
      ) : (
        plugs.map((plug, index) => (
          <ExpandableListItem key={index}>
            <div className={cx('switch', { 'mr-6': hasStatus(plug) && !loadingPlugs })}>
              {loadingPlugs && <Loader className="mr-16" />}
              {!loadingPlugs && !hasStatus(plug) && (
                <div className="switch switch--inactive">{t('Unknown')}</div>
              )}
              {!loadingPlugs && hasStatus(plug) && (
                <Switch
                  id={plug.SerialNo}
                  onClick={() => handleTogglePlug(plug)}
                  checked={plug.Status === 'On'}
                  isLoading={isToggling && loadingPlug === plug.Id}
                />
              )}
            </div>
            <label htmlFor={plug.SerialNo}>{plug.Label}</label>
            {hasSmartPlugAccess && (
              <Link to={`/systems/${panelId}/smartplugs/${plug.SerialNo}`} className="right">
                {t('Settings')}
              </Link>
            )}
          </ExpandableListItem>
        ))
      )}
      {toggleError && <FailedRequest text={'Failed to load smart plugs status'} />}
      <div className="margin-top" />
    </ExpandableList>
  )
}

export default SystemPlugs
