const BedroomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 23C6 21.3431 7.34315 20 9 20H39C40.6569 20 42 21.3431 42 23V29H38V27C38 25.3431 36.6569 24 35 24H29C27.3431 24 26 25.3431 26 27V29H22V27C22 25.3431 20.6569 24 19 24H13C11.3431 24 10 25.3431 10 27V29H6V23Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 42H43V44C43 44.5523 43.4477 45 44 45C44.5523 45 45 44.5523 45 44V32C45 30.5194 44.1956 29.2267 43 28.5351V23C43 20.7909 41.2091 19 39 19H9C6.79086 19 5 20.7909 5 23V28.5351C3.8044 29.2267 3 30.5194 3 32V44C3 44.5523 3.44772 45 4 45C4.55228 45 5 44.5523 5 44V42ZM9 21H39C40.1046 21 41 21.8954 41 23V28H39V27C39 24.7909 37.2091 23 35 23H29C26.7909 23 25 24.7909 25 27V28H23V27C23 24.7909 21.2091 23 19 23H13C10.7909 23 9 24.7909 9 27V28H7V23C7 21.8954 7.89543 21 9 21ZM27 28H37V27C37 25.8954 36.1046 25 35 25H29C27.8954 25 27 25.8954 27 27V28ZM7 30C5.89543 30 5 30.8954 5 32V40H43V32C43 30.8954 42.1046 30 41 30H7ZM21 27V28H11V27C11 25.8954 11.8954 25 13 25H19C20.1046 25 21 25.8954 21 27Z"
        fill="#333131"
      />
    </svg>
  )
}

export { BedroomIcon }
