import { getAnalytics } from 'firebase/analytics'
import { FirebaseOptions, initializeApp } from 'firebase/app'
import { getPerformance } from 'firebase/performance'
import { getRemoteConfig } from 'firebase/remote-config'
import { FeatureFlags, getFeatureFlags } from './remoteConfig'
import { initializeTraces } from './traces'

export let firebaseApp: ReturnType<typeof initializeApp> | undefined
export let googleAnalytics: ReturnType<typeof getAnalytics> | undefined
export let firebasePerformance: ReturnType<typeof getPerformance> | undefined
export let featureFlags: FeatureFlags | undefined

export function initializeFirebase() {
  const firebaseOptions: FirebaseOptions = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  }

  const isMissingConfig = Object.values(firebaseOptions).includes(undefined)
  if (isMissingConfig) return

  firebaseApp = initializeApp(firebaseOptions)
  googleAnalytics = getAnalytics(firebaseApp)
  firebasePerformance = getPerformance(firebaseApp)

  let remoteConfig = getRemoteConfig(firebaseApp)
  const isProduction = process.env.REACT_APP_BUILDVERSION?.toLowerCase() === 'production'
  remoteConfig.settings.minimumFetchIntervalMillis = isProduction ? 43200000 : 3600000

  featureFlags = getFeatureFlags(remoteConfig)

  initializeTraces(firebasePerformance)
}
