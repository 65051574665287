import { FC, useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import SplitPage from '../../../components/pages/SplitPage'
import useI18n from '../../../i18n/useI18n'
import {
  useCreateUser,
  useCreateUserVerifyPin,
  useRequestCustomerRegistrationSMS,
} from '../../../services/requestHooks/simpleRegistration'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import { useOnMount } from '../../../utils/commonHooks'
import PinCodeForm from '../AccountRegistrationWizard/OneTimeCodeForm'
import FormError from './FormError'
import PasswordForm from './PasswordForm'
import RegistrationFinished from './RegistrationFinished'

const getRegistrationId = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('registrationId')
}

const getHeadingText = (
  hasValidatedPin: boolean,
  hasCreatedUser: boolean | undefined,
): TranslationKey => {
  if (hasCreatedUser) return 'congrats_account_onboarding'
  return !hasValidatedPin ? 'You should have received an SMS' : 'Almost done'
}

const SimpleAccountRegistrationWizard: FC = () => {
  const RegistrationId = useMemo(getRegistrationId, [])
  const { t } = useI18n()
  const {
    run: validateSMSCode,
    data: PinCode,
    isLoading: isValidatingSMSCode,
    error: validateSMSCodeError,
  } = useCreateUserVerifyPin()

  const {
    run: createUser,
    data: hasCreatedUser,
    isLoading: isCreatingUser,
    error: createUserError,
  } = useCreateUser()

  const {
    run: requestSMS,
    isLoading: isRequestingSMS,
    error: requestSMSError,
  } = useRequestCustomerRegistrationSMS()

  const submitPinCode = (values: { pinCode: string }) => {
    if (!!RegistrationId) {
      validateSMSCode({ PinCode: values.pinCode, RegistrationId })
    }
  }

  const submitPassword = (values: { password: string; passwordRepeat: string }) => {
    if (!!RegistrationId && !!PinCode) {
      createUser({ PinCode, Password: values.password, RegistrationId })
    }
  }

  useOnMount(() => {
    if (RegistrationId) {
      requestSMS(RegistrationId)
    }
  })

  const hasError = !!requestSMSError || !!createUserError

  const render = () => {
    if (!RegistrationId) {
      return <Redirect to="/404" />
    }
    if (hasCreatedUser) {
      return <RegistrationFinished />
    }
    if (!!requestSMSError) {
      return <FormError errorMessage={requestSMSError} size="big" />
    }
    if (!!createUserError) {
      return <FormError errorMessage={createUserError} size="big" />
    }
    if (!!PinCode) {
      return <PasswordForm onSubmit={submitPassword} isLoading={isCreatingUser} />
    }
    return (
      <>
        <PinCodeForm onSubmit={submitPinCode} isLoading={isValidatingSMSCode} />
        <FormError errorMessage={validateSMSCodeError} />
      </>
    )
  }

  return (
    <SplitPage
      heading={<>{!hasError && <h1>{t(getHeadingText(!!PinCode, hasCreatedUser))}</h1>}</>}
      isLoading={isRequestingSMS}
    >
      {render()}
    </SplitPage>
  )
}

export default SimpleAccountRegistrationWizard
