import { useParams } from 'react-router'
import FullscreenPage from '../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../components/TcOrCrowContainer'
import useI18n from '../../i18n/useI18n'
import { useLoadMessageById } from '../../services/requestHooks/messages'
import { usePanelInfo } from '../../services/requestHooks/panelInfo'
import { MessageDetail, MessageTemplate } from '../../types/Messages'
import { useOnMount } from '../../utils/commonHooks'
import { usePanelId } from '../AlarmSystems/hooks/usePanelId'
import FailedRequest from '../onboardingWizard/components/FailedRequest'
import MessagePage from './MessagePage'
import MessageBox from './messageTypes/MessageBox'
import './_message.scss'

const Page = ({ hideNavbar }: { hideNavbar?: boolean }) => {
  const { run: getMessage, data: currentMessage, isLoading, error } = useLoadMessageById()
  const { id } = useParams<{ id: string }>()
  useOnMount(() => {
    getMessage(id)
  })

  return (
    <MessagePage isLoading={isLoading} hideNavbar={hideNavbar}>
      {currentMessage && <MessageDetails message={currentMessage} />}
      {error && <FailedRequest text={error} />}
    </MessagePage>
  )
}

const MessageDetailPage = () => <Page />

const MessageDetails = ({ message }: { message: MessageDetail }) => {
  switch (message.TemplateId) {
    case MessageTemplate.Newsletter:
    case MessageTemplate.PriceChange:
      return <MessageBox message={message} />
    default:
      return <div>No template found. Contact customer service</div>
  }
}

export const MessageDetailsPageWithPanelId = () => {
  const panelId = usePanelId()

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadInfoError,
  } = usePanelInfo()

  useOnMount(() => {
    if (!loadingPanelInfo) {
      loadPanelInfo(panelId)
    }
  })

  const { t } = useI18n()

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen footer>
      <FullscreenPage
        isLoading={loadingPanelInfo}
        loaderProps={{
          loadingText: { text: t('Loading') },
          errorHandling: {
            loadingFailed: !loadingPanelInfo && !!loadInfoError,
            errorHeaderText: t('messages'),
            errorText: t('Something went wrong. Please try again'),
            returnUrl: `/systems/${panelId}`,
          },
        }}
      >
        <Page hideNavbar />
      </FullscreenPage>
    </TcOrCrowContainer>
  )
}

export default MessageDetailPage
