import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'

import Button from '../../../components/Crow/Button/Button'
import { default as SoundLevelSelect } from '../../../components/Crow/DropDown/DropDownList'
import { default as NameField } from '../../../components/Crow/Input/TextFieldForForm'
import { colors } from '../../../components/Crow/Style/theme'
import { addToast } from '../../../components/Crow/Toast/Toast'
import { default as AutoLockToggle } from '../../../components/Crow/Toogle/ToggleForForm'
import { Heading3, Hr } from '../../../components/Crow/Typography'
import { Loader } from '../../../components/Elements/Loaders'
import TwoFactorPrompt, {
  useTwoFactorPrompt,
} from '../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../i18n/useI18n'
import { useGetLockStatus, useSetLockSettingsCrow } from '../../../services/requestHooks/locks'
import { usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { isTranslationKey } from '../../../types/generated/TranslationKey'
import { SoundLevel as SoundLevelEnum } from '../../../types/Lock'
import PasswordPrompt, { usePasswordPrompt } from '../../AlarmSystems/Panel/PasswordPromt'
import { LockListItemContainer, LockSettingsBtnContainer } from './LockStyle'

interface Props {
  lock: {
    Label: string
    PanelId: string
    Serial: string
    AutoLockEnabled?: boolean
    Status?: 'lock' | 'unlock'
    SoundLevel?: SoundLevelEnum | null
    LastChanged?: string | number
  }
  closeDrawer: () => void
}

const LockSettings = ({
  lock: { Label, PanelId, Serial, AutoLockEnabled, SoundLevel },
  closeDrawer,
}: Props) => {
  const { t } = useI18n()

  const { promptState: twoFactorPromptState, promptForTwoFactor } = useTwoFactorPrompt()
  const { promptState: passwordPromptState, promptForPassword } = usePasswordPrompt()

  const { run: getLockStatus } = useGetLockStatus()
  const { run: setLockSettingsCrow, isLoading: loadingLockSettings } = useSetLockSettingsCrow({
    onSuccess: async () => {
      if (watch('Label').length === 0) {
        // Calling this because in this case, the backend needs to provdie the default label
        await getLockStatus(PanelId)
      }

      closeDrawer()
    },
    onFailure: ({ message }) => {
      addToast({
        type: 'error',
        time: 5000,
        title: t('error_toast_generic_title'),
        content: isTranslationKey(message) ? t(message) : t('error_toast_generic_content'),
      })
    },
  })

  const { data: panelStatus } = usePanelStatus()

  const resolver = yup.object({
    Label: yup.string().trim(),
    AutoLock: yup.boolean(),
    SoundLevel: yup.number(),
  })

  const defaultValues = {
    Label,
    AutoLock: AutoLockEnabled,
    SoundLevel,
    PanelId,
    Serial,
    Language: '',
    LanguageSettings: {},
  }

  const { register, handleSubmit, watch } = useForm({
    defaultValues: defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(resolver),
  })

  const onSubmit = async (formData: any) => {
    const ValidationCode = await promptForTwoFactor()
    if (ValidationCode) {
      const Password = await promptForPassword()
      if (Password) {
        setLockSettingsCrow({
          ...formData,
          Password,
          ValidationCode,
        })
      }
    }
  }

  const formIsDisabled = panelStatus?.Status !== 1

  return (
    <>
      <LockListItemContainer>
        <Hr className="mt-16" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <SectionTitle>{t('Name')}</SectionTitle>
          <NameField maxLength={40} disabled={formIsDisabled} {...register('Label')} />
          <SectionTitle>{t('Automatic lock')}</SectionTitle>
          <AutoLockToggle
            text={t('lock close door')}
            subText={t('auto lock')}
            disabled={formIsDisabled}
            {...register('AutoLock')}
          />
          <SectionTitle>{t('sound settings')}</SectionTitle>
          <SoundLevelSelect
            label={`${t('lock')} ${t('sound volume')}`}
            disabled={formIsDisabled}
            {...register('SoundLevel')}
          >
            <option value={SoundLevelEnum.Disabled}>{t('Off')}</option>
            <option value={SoundLevelEnum.Low}>{t('Low')}</option>
            <option value={SoundLevelEnum.High}>{t('High')}</option>
          </SoundLevelSelect>
          {!formIsDisabled && (
            <LockSettingsBtnContainer>
              <Button
                size="l"
                className="qa-save-btn"
                level="secondary"
                disabled={loadingLockSettings}
              >
                {loadingLockSettings ? <Loader /> : t('Save')}
              </Button>
            </LockSettingsBtnContainer>
          )}
        </form>
      </LockListItemContainer>
      <PasswordPrompt promptState={passwordPromptState} />
      <TwoFactorPrompt promptState={twoFactorPromptState} />
    </>
  )
}

const SectionTitle = styled(Heading3)`
  margin: 34px 0 18px 0;
  color: ${colors.black800};
`

export default LockSettings
