import { TranslationKey } from './generated/TranslationKey'

export enum SoundLevel {
  Unknown = -1,
  Disabled = 0,
  Low = 1,
  High = 2,
}

export type DoorLock = {
  Id?: number
  Label: string
  PanelId: string
  Serial: string
  AutoLockEnabled?: boolean
  BatteryLow?: boolean
  Status?: 'lock' | 'unlock'
  SoundLevel?: SoundLevel | null
  LastChanged?: string
}

export interface LockCommandPayload {
  PanelId: string
  PanelCode?: string
  Platform: string
  LockSerial: string
}

export interface LockSettingsPayload {
  Password?: string
  Serial?: string
  Label: string
  PanelId?: string
  SoundLevel?: SoundLevel
  AutoLock?: boolean
  Language?: string | undefined
  ValidationCode?: string
  LanguageSettings: LanguageSettings
}

export type LanguageSettings = {
  Languages?: { Language: TranslationKey; Id: string }[]
  Selected?: { Id: string; Language: null | TranslationKey }
}
