import { yupResolver } from '@hookform/resolvers/yup'
import cx from 'classnames'
import React, { EventHandler, Fragment, useContext } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { PanelUsersIcon, TagIcon, UserIcon } from '../../../../../assets/icons'
import Button from '../../../../../components/Button'
import ExpandableHelpTag from '../../../../../components/ExpandableHelpTag'
import TextField from '../../../../../components/forms/TextField'
import {
  addPanelUserValidator,
  baseValidator,
  legacyPanelCodeValidator,
} from '../../../../../components/forms/validators'
import { TwofactorContext } from '../../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../../i18n/useI18n'
import { useGetPanelUsers, useSavePanelUser } from '../../../../../services/requestHooks/panelUsers'
import { TranslationKey } from '../../../../../types/generated/TranslationKey'
import { tFunc } from '../../../../../types/i18n'
import { TranslationKeyOverride } from '../../../../../types/TranslationKeyOverride'
import { PanelUserChangeOperation } from '../../../../../types/UserDetails'
import PasswordPrompt, { usePasswordPrompt } from '../../../Panel/PasswordPromt'
import { UserType } from '../constants/panelUserTypes'
import { User } from '../state/panelUsersSlice'

interface Props {
  user: User
  operation: PanelUserChangeOperation
  codeLength: number
  cancelEdit: EventHandler<any>
}

interface FormValues {
  name: string
  panelCode?: string
  panelCodeRepeat?: string
}

const newUserResolver = (codeLength: number, t: tFunc) =>
  yup.object({
    name: addPanelUserValidator(t('name_limit_8')).required('Required'),
    panelCode: legacyPanelCodeValidator(codeLength, t).required(),
    panelCodeRepeat: legacyPanelCodeValidator(codeLength, t)
      .test('equal', 'Repeat code', (value, context) => value === context.parent.panelCode)
      .required(),
  })

const editNameResolver = (codeLength: number) =>
  yup.object({
    name: baseValidator.required('Required'),
  })

const editPanelCodeResolver = (codeLength: number, t: tFunc) =>
  yup.object({
    panelCode: legacyPanelCodeValidator(codeLength, t).required(),
    panelCodeRepeat: legacyPanelCodeValidator(codeLength, t)
      .test('equal', 'Repeat code', (value, context) => value === context.parent.panelCode)
      .required(),
  })

const getFormHeading = (isNewUser: boolean, user: User, t: (text: TranslationKey) => string) => {
  if (isNewUser) {
    return user.Type === UserType.PERMANENT_USER ? t('Add permanent user') : t('Add 24-hour user')
  }
  return `${t('Edit')} ${user.Name}`
}

const PanelUserEdit = ({ user, cancelEdit, codeLength, operation }: Props) => {
  const { t } = useI18n()
  const { panelId: PanelId } = useParams<{ panelId: string }>()

  const isEditingPanelCode = operation === PanelUserChangeOperation.CHANGE_CODE
  const isEditingName = operation === PanelUserChangeOperation.CHANGE_NAME
  const isNewUser = operation === PanelUserChangeOperation.ADD

  const resolver = isNewUser
    ? newUserResolver
    : isEditingName
    ? editNameResolver
    : editPanelCodeResolver

  const formHeading = getFormHeading(isNewUser, user, t)

  const { promptForTwoFactor } = useContext(TwofactorContext)
  const { promptForPassword, promptState } = usePasswordPrompt()
  const { run: loadPanelUsers } = useGetPanelUsers()
  const { run: saveUser } = useSavePanelUser({
    onSuccess: () => {
      loadPanelUsers(PanelId)
    },
  })

  const onSubmit: SubmitHandler<FormValues> = async (values, event) => {
    const ValidationCode = await promptForTwoFactor()
    if (!ValidationCode) return
    const Password = await promptForPassword()
    if (!Password) return
    const { Type: UserType, ...restUser } = user
    saveUser({
      operation,
      payload: {
        UserType,
        PanelId,
        Password,
        ValidationCode,
        Name: values.name || restUser.Name,
        PanelCode: values.panelCode,
        Index: user.Index === undefined ? -1 : user.Index,
      },
    })
    cancelEdit(event)
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, isDirty, errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: user.Name,
    },

    resolver: yupResolver(resolver(codeLength, t)) as any,
  })

  const panelCode = watch('panelCode')

  return (
    <Fragment>
      <div>
        <div className="center">
          <UserIcon
            className={cx(
              'icon icon-small',
              user.Type === UserType.PERMANENT_USER ? 'icon--user-permanent' : 'icon--user-temp',
            )}
          />
        </div>
        <h1 className="text-2xl margin-bottom center">{formHeading}</h1>

        <form name="editPanelUserFrm" className="form" onSubmit={handleSubmit(onSubmit)}>
          {(isEditingName || isNewUser) && (
            <TextField
              errorKey={errors.name?.message as TranslationKeyOverride}
              label="Name"
              {...register('name')}
            />
          )}

          {(isEditingPanelCode || isNewUser) && (
            <div className="paneluserform__inputgroup">
              <div className="flex flex-row">
                <PanelUsersIcon className="status-list__icon-right form-icon" />
                <span className="text-xl">{t('ACCESS')}</span>
              </div>
              <div className="paneluserform__columngroup">
                <div>
                  <TextField
                    errorKey={errors.panelCode?.message as TranslationKeyOverride}
                    type="password"
                    label="New code"
                    {...register('panelCode')}
                  />
                  {panelCode?.length === codeLength && (
                    <TextField
                      errorKey={errors.panelCodeRepeat?.message as TranslationKeyOverride}
                      type="password"
                      label="Repeat code"
                      {...register('panelCodeRepeat')}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {!isNewUser && (
            <div className="paneluserform__inputgroup">
              <div className="flex items-center">
                <span className="flex">
                  <TagIcon className="status-list__icon-right form-icon" />
                  <span className="text-lg ">
                    {user.HasTag ? t('Tag activated on user') : t('Tag not activated on user')}
                  </span>
                </span>
                <ExpandableHelpTag
                  className={'mb-4 ml-2'}
                  title={'Key tags'}
                  text="Key tags are connected by installer during installation. To connect tag to an user, please rename users with key tags."
                />
              </div>
            </div>
          )}
          <div className="center margin-top">
            <Button disabled={!isValid || !isDirty} buttonType="primary" size="small">
              {t(isNewUser ? 'Add user' : 'Save user')}
            </Button>
            <Button size="small" buttonType="secondary" onClick={cancelEdit}>
              {t('Cancel')}
            </Button>
          </div>
        </form>
      </div>
      <PasswordPrompt promptState={promptState} />
    </Fragment>
  )
}

export default PanelUserEdit
