import { FC, FormEventHandler, useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from '../../../../../components/Button'
import { Loader } from '../../../../../components/Elements/Loaders'
import useI18n from '../../../../../i18n/useI18n'
import { useSetPlugScenarioTrigger } from '../../../../../services/requestHooks/plugs'
import { TranslationKey } from '../../../../../types/generated/TranslationKey'
import { SmartPlugScenarioTrigger } from '../../../../../types/SmartPlug'
import FailedRequest from '../../../../onboardingWizard/components/FailedRequest'
import { ScenarioTrigger } from './ScenarioTrigger'
import { getScenarioConflict } from './scenarioValidation'
import WarningPrompt, { useWarningPrompt } from './WarningPrompt'

export enum Behaviour {
  NO_CHANGE = 'NO_CHANGE',
  PLUG_ON = 'PLUG_ON',
  PLUG_OFF = 'PLUG_OFF',
}

interface FormValues {
  onActivate: Behaviour
  onDeactivate: Behaviour
  onTrigger: Behaviour
}

interface Props {
  scenarios: SmartPlugScenarioTrigger
}

const ScenarioTriggerForm = ({ scenarios: initialScenarios }: Props) => {
  const { t } = useI18n()
  const [isTouched, setIsTouched] = useState(false)
  const { panelId, smartplugSerial } = useParams<{ panelId: string; smartplugSerial: string }>()
  const [scenarios, setScenarios] = useState(toFormValues(initialScenarios))

  const { run: saveScenarios, isLoading, error } = useSetPlugScenarioTrigger()
  const { promptState, prompt } = useWarningPrompt()
  const handleChange = async ({ name, value }: { name: string; value: Behaviour }) => {
    setIsTouched(true)
    const updatedScenarios = {
      ...scenarios,
      [name]: value,
    }
    const trigger = toScenarioTrigger(scenarios)
    const updatedTrigger = toScenarioTrigger(updatedScenarios)
    const changeField = (Object.keys(trigger) as (keyof ScenarioTrigger)[]).find(
      (field) => trigger[field] !== updatedTrigger[field],
    )
    if (!changeField) throw Error('No fieldchange was found')
    const conflict = getScenarioConflict({ field: changeField }, trigger)
    if (conflict) {
      const [conflictWarning, conflictFields] = conflict
      const shouldSubmit = await prompt(conflictWarning)
      if (!shouldSubmit) return
      const resolved: ScenarioTrigger = conflictFields.reduce((trigger, conflictField) => {
        return {
          ...trigger,
          [conflictField]: !trigger[conflictField],
        }
      }, updatedTrigger)
      setScenarios(toFormValues(resolved))
    } else {
      setScenarios(updatedScenarios)
    }
  }

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault()
    saveScenarios({
      ...toScenarioTrigger(scenarios),
      PanelId: panelId,
      Serial: smartplugSerial,
    })
    setIsTouched(false)
  }

  const getLabel = (behaviour: Behaviour): TranslationKey => {
    if (behaviour === Behaviour.PLUG_ON) return 'Turn on'
    if (behaviour === Behaviour.PLUG_OFF) return 'Turn off'
    return 'Do nothing'
  }

  const buttonProps = (name: keyof FormValues, behavior: Behaviour): RadioButtonProps => ({
    name,
    checked: scenarios[name] === behavior,
    value: behavior,
    onChange: handleChange,
    label: getLabel(behavior),
  })

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid flex-wrap">
        <div className="grid__one-third">
          <fieldset className="">
            <legend>
              <p className="text-lg">{t('On arming')}</p>
            </legend>
            <RadioButton {...buttonProps('onActivate', Behaviour.PLUG_ON)} />
            <RadioButton {...buttonProps('onActivate', Behaviour.PLUG_OFF)} />
            <RadioButton {...buttonProps('onActivate', Behaviour.NO_CHANGE)} />
          </fieldset>
        </div>
        <div className="grid__one-third">
          <fieldset className="">
            <legend>
              <p className="text-lg">{t('On disarming')}</p>
            </legend>
            <RadioButton {...buttonProps('onDeactivate', Behaviour.PLUG_ON)} />
            <RadioButton {...buttonProps('onDeactivate', Behaviour.PLUG_OFF)} />
            <RadioButton {...buttonProps('onDeactivate', Behaviour.NO_CHANGE)} />
          </fieldset>
        </div>
        <div className="grid__one-third">
          <fieldset className="">
            <legend>
              <p className="text-lg">{t('When alarm goes off')}</p>
            </legend>
            <RadioButton {...buttonProps('onTrigger', Behaviour.PLUG_ON)} />
            <RadioButton {...buttonProps('onTrigger', Behaviour.NO_CHANGE)} />
          </fieldset>
        </div>
      </div>
      <Button disabled={!isTouched || isLoading} buttonType="primary">
        {t('Save scenarios')}
      </Button>
      {isLoading && <Loader as="span" />}
      <WarningPrompt promptState={promptState} />
      {error && <FailedRequest text={'Something went wrong. Please try again later.'} />}
    </form>
  )
}

interface RadioButtonProps {
  label: TranslationKey
  name: string
  value: Behaviour
  checked: boolean
  onChange: (value: { name: string; value: Behaviour }) => void
}
const RadioButton: FC<RadioButtonProps> = ({ label, name, value, onChange, checked }) => {
  const { t } = useI18n()
  return (
    <label className="radio radio--large">
      <input
        type="radio"
        value={value}
        checked={checked}
        name={name}
        onChange={(e) =>
          onChange({ name: e.currentTarget.name, value: e.currentTarget.value as Behaviour })
        }
      />
      {t(label)}
      <i className="radio__indicator" />
    </label>
  )
}

export const toFormValues = (trigger: ScenarioTrigger): FormValues => {
  return {
    onActivate: trigger.OnArmingTurnOn
      ? Behaviour.PLUG_ON
      : trigger.OnArmingTurnOff
      ? Behaviour.PLUG_OFF
      : Behaviour.NO_CHANGE,
    onDeactivate: trigger.OnDisarmingTurnOn
      ? Behaviour.PLUG_ON
      : trigger.OnDisarmingTurnOff
      ? Behaviour.PLUG_OFF
      : Behaviour.NO_CHANGE,
    onTrigger: trigger.OnAlarmTurnOn ? Behaviour.PLUG_ON : Behaviour.NO_CHANGE,
  }
}

export const toScenarioTrigger = (values: FormValues): ScenarioTrigger => {
  return {
    OnAlarmTurnOn: values.onTrigger === Behaviour.PLUG_ON,
    OnArmingTurnOff: values.onActivate === Behaviour.PLUG_OFF,
    OnArmingTurnOn: values.onActivate === Behaviour.PLUG_ON,
    OnDisarmingTurnOff: values.onDeactivate === Behaviour.PLUG_OFF,
    OnDisarmingTurnOn: values.onDeactivate === Behaviour.PLUG_ON,
  }
}

export default ScenarioTriggerForm
