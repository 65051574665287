const AtticIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8319 18.2481C24.4361 17.6544 23.5636 17.6544 23.1678 18.2481L7.03633 42.4453C6.59329 43.1099 7.06969 44 7.86838 44H40.1313C40.93 44 41.4064 43.1099 40.9634 42.4453L24.8319 18.2481ZM23.9999 40C26.209 40 27.9999 38.2092 27.9999 36C27.9999 33.7909 26.209 32 23.9999 32C21.7907 32 19.9999 33.7909 19.9999 36C19.9999 38.2092 21.7907 40 23.9999 40Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 36C29 38.7614 26.7614 41 24 41C21.2386 41 19 38.7614 19 36C19 33.2386 21.2386 31 24 31C26.7614 31 29 33.2386 29 36ZM21.1707 35H23V33.1707C22.1476 33.472 21.472 34.1476 21.1707 35ZM25 33.1707V35H26.8293C26.528 34.1476 25.8524 33.472 25 33.1707ZM21.1707 37C21.472 37.8524 22.1476 38.528 23 38.8293V37H21.1707ZM25 38.8293C25.8524 38.528 26.528 37.8524 26.8293 37H25V38.8293Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.6641 17.4961C24.8725 16.3086 23.1275 16.3086 22.3359 17.4961L5.33328 43H4C3.44772 43 3 43.4477 3 44C3 44.5523 3.44772 45 4 45H44C44.5523 45 45 44.5523 45 44C45 43.4477 44.5523 43 44 43H42.6667L25.6641 17.4961ZM40.263 42.9999L30.9297 29H17.0703L7.73703 42.9999H40.263ZM25 20.1055L29.5964 27H25V20.1055ZM18.4036 27L23 20.1055V27H18.4036Z"
        fill="#333131"
      />
    </svg>
  )
}

export { AtticIcon }
