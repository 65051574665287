import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { HumidityIcon } from '../../../assets/icons'
import CrowCard from '../../../components/Crow/CrowCard/CrowCard'
import { colors } from '../../../components/Crow/Style/theme'
import { SubTitle } from '../../../components/Crow/Typography'
import useI18n from '../../../i18n/useI18n'
import { useResourceInfo } from '../../../services/requestHooks/user'
import { Section, HumidityDevice } from '../../../types/Humidity'
import DeviceName from '../../../utils/places/DeviceName'

interface HumidityDetailsType {
  floors: Section
}

const HumidityDetails = ({ floors }: HumidityDetailsType) => {
  const { t } = useI18n()
  const resources = useResourceInfo()

  return (
    <HumidityDetailsContainer key={floors.Key}>
      {floors.Places.flatMap((room: { Components: HumidityDevice[] }) =>
        room.Components.map((device, index) => (
          <React.Fragment key={device.SerialNo}>
            <HumidityCrowCard
              key={device.SerialNo}
              as="li"
              className={`qa-${device.Label}-${device.Id}`}
            >
              <SubTitle light className="pl-3 pr-3 qa-component">
                <DeviceName deviceName={device.Type} />
                &nbsp;{room.Components.length > 1 && index + 1}
              </SubTitle>
              <DeviceInfo>
                {device.LowBattery && <DisplayLowBattery>{t('BATTERY_LOW')}</DisplayLowBattery>}
                <DeviceHumidity>
                  <HumidityIconContainer>
                    <HumidityIcon />
                  </HumidityIconContainer>
                  <span className="qa-humidity-details-component-reading">{device.Humidity} %</span>
                </DeviceHumidity>
              </DeviceInfo>
            </HumidityCrowCard>
            {device.LowBattery && (
              <CustomerServiceParagraph>
                <Trans
                  i18nKey="CUSTOMER_SERVICE_VISIT"
                  components={{
                    address: (
                      <a href={resources.CustomerServiceUrl} target="blank">
                        Customer Service
                      </a>
                    ),
                  }}
                />
              </CustomerServiceParagraph>
            )}
          </React.Fragment>
        )),
      )}
    </HumidityDetailsContainer>
  )
}

const CustomerServiceParagraph = styled.p`
  margin-left: 16px;
  a {
    color: #66a8d8;
    text-decoration: underline;
  }
`

const HumidityIconContainer = styled.div`
  opacity: 0.5;

  svg {
    width: 18px;
    margin-right: 2px;
  }
`

const DeviceInfo = styled.div`
  display: flex;
  margin-left: auto;
`

const DisplayLowBattery = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.yellow100};
  border-radius: 12px;
  color: ${colors.black900};
  text-transform: uppercase;
  font-size: 14px;
  padding: 0 8px;
  font-weight: bolder;
`

const HumidityDetailsContainer = styled.div``

const HumidityCrowCard = styled(CrowCard)`
  border-radius: 12px;
  border: 1px solid ${colors.black300};
  padding: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
`

const DeviceHumidity = styled.div`
  margin-left: 26px;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.grayShade};
  display: inline-flex;
`

export default HumidityDetails
