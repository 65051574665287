const TerraceIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C19.4477 0 19 0.447715 19 1V1.70449L3.73622 11.919C2.91469 12.4688 3.30387 13.7501 4.29238 13.7501H35.8128C36.8013 13.7501 37.1904 12.4688 36.3689 11.919L21 1.63414V1C21 0.447715 20.5523 0 20 0ZM17.2094 5.30927L7.58474 11.7501H13.4445C13.4619 11.7082 13.4823 11.6669 13.506 11.6266L17.2094 5.30927ZM15.7785 11.7501H24.2219L20.0002 4.54873L15.7785 11.7501ZM26.5559 11.7501C26.5385 11.7082 26.518 11.6669 26.4944 11.6266L22.7234 5.19394L32.5204 11.7501H26.5559Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
      <path
        d="M0.20884 22.1919C0.111059 21.6483 0.472433 21.1284 1.01599 21.0306C1.55955 20.9328 2.07946 21.2942 2.17724 21.8378L4.00534 32H14.188C14.7403 32 15.188 32.4477 15.188 33C15.188 33.5523 14.7403 34 14.188 34V40C14.188 40.5523 13.7403 41 13.188 41C12.6358 41 12.188 40.5523 12.188 40V34H5.18804L5.18804 40C5.18804 40.5523 4.74032 41 4.18804 41C3.63576 41 3.18804 40.5523 3.18804 40L3.18804 34C2.72051 34 2.32792 33.6792 2.21843 33.2456C2.19338 33.1833 2.17426 33.1175 2.1619 33.0487L0.20884 22.1919Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
      <path
        d="M26.0001 32H36.1828L38.0108 21.8378C38.1086 21.2942 38.6285 20.9328 39.1721 21.0306C39.7157 21.1284 40.077 21.6483 39.9793 22.1919L38.0262 33.0487C38.0138 33.1175 37.9947 33.1833 37.9697 33.2456C37.8602 33.6792 37.4676 34 37.0001 34V40C37.0001 40.5523 36.5523 41 36.0001 41C35.4478 41 35.0001 40.5523 35.0001 40V34H28.0001V40C28.0001 40.5523 27.5523 41 27.0001 41C26.4478 41 26.0001 40.5523 26.0001 40V34C25.4478 34 25.0001 33.5523 25.0001 33C25.0001 32.4477 25.4478 32 26.0001 32Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
      <path
        d="M14 29H19V40C19 40.5523 19.4477 41 20 41C20.5523 41 21 40.5523 21 40V29H26C26.5523 29 27 28.5523 27 28C27 27.4477 26.5523 27 26 27H14C13.4477 27 13 27.4477 13 28C13 28.5523 13.4477 29 14 29Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
    </svg>
  )
}

export { TerraceIcon }
