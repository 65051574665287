import { ReactNode } from 'react'
import { generatePath, NavLink, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { usePanelId } from '../../../pages/AlarmSystems/hooks/usePanelId'
import { useAuth } from '../../../services/authentication/AuthContext'
import { useDoorsAndWindows } from '../../../services/requestHooks/doorsandwindows'
import { usePanelInfo } from '../../../services/requestHooks/panelInfo'
import { useSmokeDetectors } from '../../../services/requestHooks/smokeDetectors'
import { useLeakageDetectors } from '../../../services/requestHooks/leakageDetectors'
import { useGetUsers } from '../../../services/requestHooks/users'
import { useOnMount } from '../../../utils/commonHooks'
import { logNewNavigationEvent } from '../../../utils/firebaseAnalytics'
import { colors } from '../Style/theme'
import { Paragraph } from '../Typography'

type Props = {
  path: string
  name: ReactNode
  itemName: string
  Icon: ReactNode
  onClick?: () => void
}

const NavigationItem = ({ path, name, Icon, itemName, onClick }: Props) => {
  const panelId = usePanelId()

  const params = useParams()
  const to = generatePath(path, params)

  const { data: panelInfo, run: loadPanelInfo, isLoading: loadingPanelInfo } = usePanelInfo()

  const {
    run: getDoorsAndWindows,
    isLoading: loadingDoorsAndWindows,
    data: doorsAndWindows,
  } = useDoorsAndWindows()

  const { run: getUsers, isLoading: loadingUsers, data: usersData } = useGetUsers()

  const {
    run: getSmokeDetectors,
    isLoading: loadingSmokeDetectors,
    data: smokeDetectors,
  } = useSmokeDetectors()

  const {
    run: getLeakageDetectors,
    isLoading: loadingLeakageDetectors,
    data: leakageDetectors,
  } = useLeakageDetectors()

  useOnMount(() => {
    if (!loadingPanelInfo && !panelInfo) {
      loadPanelInfo(panelId)
    }
    if (!loadingDoorsAndWindows && !doorsAndWindows) {
      getDoorsAndWindows(panelId)
    }
    if (!loadingUsers && !usersData) {
      getUsers(panelId)
    }
    if (!loadingSmokeDetectors && !smokeDetectors) {
      getSmokeDetectors(panelId)
    }
    if (!loadingLeakageDetectors && !leakageDetectors) {
      getLeakageDetectors(panelId)
    }
  })

  const { logout } = useAuth()

  switch (itemName) {
    case panelInfo?.Smartplugs?.length! <= 0 && 'smartplug':
      return null
    case panelInfo?.Temperatures.length! <= 0 && 'Temperature':
      return null
    case doorsAndWindows?.Floors.length! <= 0 && 'Doors':
      return null
    case panelInfo?.Locks.length! <= 0 && 'Unlocked':
      return null
    case usersData === undefined && 'People':
      return null
    case smokeDetectors?.Floors.length! <= 0 && 'Smoke-Detector':
      return null
    case leakageDetectors?.Floors.length! <= 0 && 'Leakage-Detector':
      return null
    case panelInfo?.Photos.length! <= 0 && 'Camera':
      return null
  }

  return (
    <S.Link
      className={`qa-nav-${name}`}
      to={to}
      exact
      onClick={() => {
        if (itemName === 'Sign-out') {
          logNewNavigationEvent('sign_out')
          logout()
        } else {
          onClick?.()
        }
      }}
      activeStyle={{
        color: colors.blue,
        boxShadow: `0 0 0 1px ${colors.blue}`,
        borderRadius: '12px',
      }}
    >
      <S.IconContaier>{Icon}</S.IconContaier>
      <S.Paragraph className="ml-5" large>
        {name}
      </S.Paragraph>
    </S.Link>
  )
}

const S = {
  Link: styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 16px;
    padding-left: 14px;
    font-size: 25px;
    text-decoration: none;
    color: ${colors.white};

    &:hover {
      color: ${colors.blue};
    }
  `,
  IconContaier: styled.span`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
  `,
  Paragraph: styled(Paragraph)`
    margin-left: 20px;
  `,
}

export default NavigationItem
