import React, { FC } from 'react'
import FocusTrap from 'focus-trap-react'
import styled from 'styled-components'
import ReactDOM from 'react-dom'

import { CancelIcon } from '../../../assets/icons/crow'
import { getPortalContainer } from '../../../utils/getContainer'
import { colors } from '../Style/theme'

interface Props {
  onClose: () => void
}

const Overlay: FC<Props> = ({ onClose, children }) => {
  return ReactDOM.createPortal(
    <FocusTrap>
      <Wrapper>
        <CloseButton onClick={onClose}>
          <CancelIcon />
        </CloseButton>
        {children}
      </Wrapper>
    </FocusTrap>,
    getPortalContainer(),
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.black900}F2;
`

const CloseButton = styled.button`
  position: absolute;
  top: 52px;
  right: 52px;
  color: white;
`

export default Overlay
