import FocusTrap from 'focus-trap-react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { getPortalContainer } from '../../../utils/getContainer'
import { colors } from '../Style/theme'

interface ModalProps {
  children: React.ReactNode
  alignRightBottom?: boolean
}

const Modal = ({ children, alignRightBottom }: ModalProps) => {
  return ReactDOM.createPortal(
    <FocusTrap>
      {alignRightBottom ? (
        <RightBottomWrapper>
          <Content alignRightBottom>{children}</Content>
        </RightBottomWrapper>
      ) : (
        <Wrapper>
          <Content>{children}</Content>
        </Wrapper>
      )}
    </FocusTrap>,
    getPortalContainer(),
  )
}

const Wrapper = styled.div.attrs({
  role: 'dialog',
  'aria-modal': 'true',
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  overflow: auto;

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    backdrop-filter: blur(5px);
  }

  /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(0, 0, 0, 0.4);
  }
`

const RightBottomWrapper = styled(Wrapper)`
  justify-content: flex-end;
  align-items: flex-end;
  top: unset;
  left: unset;
  right: 0;
  bottom: 0;
`

interface ContentProps {
  alignRightBottom?: boolean
}

const Content = styled.div<ContentProps>`
  position: relative;
  box-shadow: -1px 2px 11px 1px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 2.5rem;
  width: 500px;
  background: ${colors.white};
  margin: ${({ alignRightBottom }) => (alignRightBottom ? '32px' : 0)};

  @media only screen and (max-width: 600px) {
    width: min-content;
  }
`

export default Modal
