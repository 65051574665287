import { ReactNode } from 'react'
import styled from 'styled-components'
import { CancelIcon } from '../../../../assets/icons/crow/CancelIcon'
import { colors } from '../../../../components/Crow/Style/theme'
import { Title } from '../../../../components/Crow/Typography'
import FullscreenPage from '../../../../components/pages/FullscreenPage'

type DarkFullScreenSuccessType = {
  title: string
  subtitle?: ReactNode
  icon?: ReactNode
  className?: string
  close?: any
}

const DarkFullScreenSuccess = ({
  title,
  subtitle,
  icon,
  close,
  className,
}: DarkFullScreenSuccessType) => {
  return (
    <>
      {close && <CloseButton onClick={close}>{<CancelIcon color={colors.blue} />}</CloseButton>}

      <DarkFullScreenConfirmContainer className={className}>
        <IconContainer>{icon}</IconContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledTitle>{subtitle}</StyledTitle>
      </DarkFullScreenConfirmContainer>
    </>
  )
}

const DarkFullScreenConfirmContainer = styled(FullscreenPage)`
  background-color: ${colors.black900};
  padding: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledTitle = styled(Title)`
  margin-bottom: 16px;
  max-width: 600px;
  display: flex;
  justify-content: center;
`

const CloseButton = styled.div`
  width: 100vw;
  background-color: ${colors.black900};
  color: ${colors.white};
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 20px;
  cursor: pointer;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`

export default DarkFullScreenSuccess
