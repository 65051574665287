import { Fragment, ReactElement, useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import styled, { css } from 'styled-components'
import { ArmedIcon, DisarmedIcon, PartiallyArmedIcon } from '../../../assets/icons/crow'
import { usePanelId } from '../../../pages/AlarmSystems/hooks/usePanelId'
import PinCodePrompt, { usePinCodePrompt } from '../../../pages/AlarmSystems/Panel/PinCodeConfirm'
import FailedRequest from '../../../pages/onboardingWizard/components/FailedRequest'
import {
  useArmCommand,
  useDisarmCommand,
  usePanelInfo,
  usePanelStatus,
  usePartialArmCommand,
} from '../../../services/requestHooks/panelInfo'
import { PanelStatus } from '../../../types/PanelModel'
import ArmingStatusText from '../../../utils/ArmingStatusText'
import { useOnMount } from '../../../utils/commonHooks'
import LoadingOverlay from '../../LoadingOverlay'
import { colors } from '../Style/theme'

const sliderItems: { isArmed: boolean; content: ReactElement }[] = [
  { isArmed: false, content: <ArmedIcon /> },
  { isArmed: false, content: <DisarmedIcon /> },
  { isArmed: false, content: <PartiallyArmedIcon /> },
]

const sliderHeight = 64
const sliderWidth = 280
const sliderPadding = 4
const numberOfIcons = sliderItems.length
const itemWidth = () => sliderHeight - 2 * sliderPadding
const spacerWidth = () =>
  (sliderWidth - numberOfIcons * (sliderHeight - 2 * sliderPadding)) / (numberOfIcons - 1)

type SliderOption = {
  isArmed: boolean
  content: ReactElement
  xPosition: number
}

const sliderOptions: SliderOption[] = sliderItems.map((item, index) => ({
  ...item,
  xPosition: index * (spacerWidth() + itemWidth()) + sliderPadding, // where the slider is when dragging
}))

const NavigationMenuSlider = () => {
  const {
    run: getPanelStatus,
    data: panelStatus,
    isLoading: loadingPanelStatus,
    error: loadingPanelStatusError,
  } = usePanelStatus()

  const { data: panelInfo } = usePanelInfo()

  const setActivePosition = () => {
    if (panelStatus?.Status === PanelStatus.FULLY_ARMED) {
      return 4
    }
    if (panelStatus?.Status === PanelStatus.TURNED_OFF) {
      return 116
    }
    if (panelStatus?.Status === PanelStatus.HOME_MODE) {
      return 226
    }
    return 116
  }

  const [activeOption, setActiveOption] = useState<SliderOption>({
    isArmed: true,
    content: <ArmedIcon />,
    xPosition: setActivePosition(),
  })

  const panelId = usePanelId()
  const [isMoving, setIsMoving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onDragStart = () => {
    setIsMoving(true)
  }

  const { promptState: pincodePromptState, promptForPinCode } = usePinCodePrompt(panelId)

  const { run: armPanel, error: armError, isLoading: isLoadingArm } = useArmCommand()
  const { run: disarmPanel, error: disarmError, isLoading: isLoadingDisarm } = useDisarmCommand()
  const {
    run: partialArmPanel,
    error: partialArmError,
    isLoading: isLoadingPartialArm,
  } = usePartialArmCommand()

  useOnMount(() => {
    if (!loadingPanelStatus) {
      getPanelStatus({ panelId })
    }
  })

  useEffect(() => {
    switch (panelStatus?.Status) {
      case PanelStatus.FULLY_ARMED:
        return setActiveOption({ xPosition: 4, isArmed: true, content: <ArmedIcon /> })
      case PanelStatus.TURNED_OFF:
        return setActiveOption({ xPosition: 116, isArmed: true, content: <DisarmedIcon /> })
      case PanelStatus.HOME_MODE:
        return setActiveOption({
          xPosition: 226,
          isArmed: true,
          content: <PartiallyArmedIcon />,
        })
    }
  }, [panelStatus?.Status])

  let PanelCode: string | undefined

  const onDragStop: any = async (_e: any, positon: { x: number }) => {
    const closestItem = sliderOptions.reduce(
      (closest, { xPosition }, index) => {
        const distance = Math.abs(xPosition - positon.x)
        if (closest.distance > distance) {
          return { value: xPosition, distance, index }
        }
        return closest
      },
      { value: 0, distance: sliderWidth, index: -1 },
    )
    setIsMoving(false)

    if (
      sliderOptions[closestItem.index].xPosition === 4 &&
      panelStatus?.Status !== PanelStatus.TURNED_OFF
    )
      return

    if (
      sliderOptions[closestItem.index].xPosition === 228 &&
      panelStatus?.Status !== PanelStatus.TURNED_OFF
    )
      return

    switch (sliderOptions[closestItem.index].xPosition) {
      case 4:
        if (!panelInfo?.QuickArmEnabled) {
          PanelCode = await promptForPinCode()
          if (!PanelCode) return
        }
        return armPanel({ PanelCode, PanelId: panelId })
      case 116:
        PanelCode = await promptForPinCode()
        if (!PanelCode) return
        return disarmPanel({ PanelCode, PanelId: panelId })
      case 228:
        if (!panelInfo?.QuickArmEnabled) {
          PanelCode = await promptForPinCode()
          if (!PanelCode) return
        }
        return partialArmPanel({ PanelCode, PanelId: panelId })
    }
    setActiveOption(sliderOptions[closestItem.index])

    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }

  const changeArmingStatusOnClick = async (position: number) => {
    switch (position) {
      case 4:
        if (!panelInfo?.QuickArmEnabled) {
          PanelCode = await promptForPinCode()
          if (!PanelCode) return
        }
        return armPanel({ PanelCode, PanelId: panelId })

      case 116:
        PanelCode = await promptForPinCode()
        if (!PanelCode) return
        return disarmPanel({ PanelCode, PanelId: panelId })

      case 228:
        if (!panelInfo?.QuickArmEnabled) {
          PanelCode = await promptForPinCode()
          if (!PanelCode) return
        }
        return partialArmPanel({ PanelCode, PanelId: panelId })
    }
  }

  const setDisableOptions = (index: number) => {
    if (panelStatus?.Status === PanelStatus.FULLY_ARMED && index === 2) {
      return true
    }
    if (panelStatus?.Status === PanelStatus.HOME_MODE && index === 0) {
      return true
    }
  }

  const error = loadingPanelStatusError || armError || disarmError || partialArmError
  const isChangingStatus =
    isLoadingArm || isLoadingDisarm || isLoadingPartialArm || loadingPanelStatus

  return (
    <>
      <S.Container>
        <LoadingOverlay isLoading={isChangingStatus}>
          <S.Slider data-testid="menu-arming-slider">
            {sliderOptions.map((sliderOption, index) => (
              <Fragment key={sliderOption.xPosition}>
                {
                  <S.Icon
                    onClick={() => {
                      changeArmingStatusOnClick(sliderOption.xPosition)
                    }}
                    disabled={setDisableOptions(index)!}
                  >
                    {sliderOption.content}
                  </S.Icon>
                }
                {index < numberOfIcons - 1 && <S.Spacer />}
              </Fragment>
            ))}

            <>
              <Draggable
                axis="x"
                bounds={{
                  left: sliderPadding,
                  right: sliderWidth - itemWidth() + sliderPadding,
                }}
                position={{ x: activeOption.xPosition, y: 0 }}
                onStart={onDragStart}
                onStop={onDragStop}
                disabled={isLoading}
              >
                <S.Draggable isMoving={isMoving}>
                  <S.Handle
                    isMoving={isMoving}
                    isArmed={activeOption.isArmed!}
                    isLoading={isLoading}
                  >
                    {activeOption.content}
                  </S.Handle>
                </S.Draggable>
              </Draggable>
            </>
          </S.Slider>
        </LoadingOverlay>

        <S.StatusText>
          {!!error ? (
            <FailedRequest text="Could not load alarms system status" />
          ) : (
            <ArmingStatusText panelStatus={panelStatus!} isLoading={loadingPanelStatus} />
          )}
        </S.StatusText>
      </S.Container>
      <PinCodePrompt promptState={pincodePromptState} />
    </>
  )
}

const S = {
  Container: styled.div`
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 16px 32px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 25px;
  `,
  StatusText: styled.div`
    text-align: center;
    grid-row: 3/4;
    color: ${colors.black600};
    padding-top: 12px;
  `,
  Slider: styled.div`
    box-sizing: content-box;
    position: relative;
    grid-row: 2/3;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    border-radius: ${sliderHeight / 2}px;
    padding: ${sliderPadding}px;
    background-color: ${colors.black900};
  `,
  Icon: styled.button<{ disabled?: boolean }>`
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${itemWidth()}px;
    height: ${itemWidth()}px;
    border-radius: 50%;
    color: ${colors.black100};
    background-color: transparent;
    &:disabled {
      background: transparent !important;
    }
  `,
  Spacer: styled.div`
    height: 2px;
    width: ${spacerWidth()}px;
    background-color: ${colors.black800};
    border-radius: 2px;
  `,
  Draggable: styled.div<{ isMoving: boolean }>`
    transition: ${({ isMoving }) => (isMoving ? 'none' : 'all 0.25s ease-in-out;')};
    position: absolute;
  `,
  Handle: styled.button<{
    isMoving: boolean
    isArmed: boolean
    isLoading: boolean
  }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${sliderHeight - 2 * sliderPadding}px;
    height: ${sliderHeight - 2 * sliderPadding}px;
    border: none;
    border-radius: 50%;
    color: ${colors.white};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    transition: all 0.25s ease-in-out;

    ${({ isMoving, isArmed }) =>
      isMoving
        ? css`
            background-color: ${colors.lightGray};
            content-visibility: hidden;
          `
        : css`
            ${isArmed
              ? css`
                  background-color: ${colors.lightRed};
                `
              : css`
                  background-color: transparent;
                  border: 2px solid ${colors.black100};
                  content-visibility: hidden;
                `}
          `};
  `,
}

export default NavigationMenuSlider
