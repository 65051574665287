import { ReactNode } from 'react'
import styled from 'styled-components'
import PropertySelect from '../../PropertySelect/PropertySelect'
import { colors } from '../../Style/theme'
import NavigationMenu from '../NavigationMenu'
import NavigationMenuSlider from '../NavigationMenuSlider'

type Props = {
  children?: ReactNode
  className?: string
  padding?: ReactNode
  transparentBackground?: boolean
  displayName?: string
}

const Desktop = ({ children, className, padding, transparentBackground, displayName }: Props) => {
  const minHeightSubtrahend = document.getElementById('portal-container')?.clientHeight || 0
  return (
    <S.Desktop className={className} minHeightSubtrahend={minHeightSubtrahend}>
      <S.Sidebar>
        <div className="pb-5 pl-3">
          <PropertySelect displayName={displayName} />
        </div>
        <NavigationMenuSlider />
        <NavigationMenu />
      </S.Sidebar>
      <S.Content padding={padding!} displayBackground={!transparentBackground}>
        {children}
      </S.Content>
    </S.Desktop>
  )
}

const S = {
  Desktop: styled.div<{ minHeightSubtrahend: number }>`
    display: flex;
    min-height: calc(100vh - ${({ minHeightSubtrahend }) => minHeightSubtrahend}px);
    height: 100%;
    width: 100%;
    margin-right: 50px;
    color: ${colors.black900};
  `,

  Sidebar: styled.aside`
    padding: 24px 16px;
    background-color: ${colors.black900};
    color: ${colors.white};
  `,
  Content: styled.div<{ padding: ReactNode; displayBackground: boolean }>`
    padding: ${({ padding }) => (padding ? padding : '3rem')};
    display: flex;
    flex: 1;
    justify-content: center;

    ${({ displayBackground }) =>
      displayBackground &&
      `
    background-color: ${colors.black100};
    `}
  `,
  Top: styled.div`
    display: flex;
  `,
  displayName: styled.div`
    display: flex;
    justify-content: center;
  `,
}

export default Desktop
