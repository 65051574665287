import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import { usePanelInfo } from '../../../services/requestHooks/panelInfo'
import { Capability } from '../../../types/PanelModel'
import { useOnMount } from '../../../utils/commonHooks'
import { usePanelId } from '../hooks/usePanelId'
import InfiniteScrollHistory from './InfiniteScrollHistory'
import LegacyHistory from './LegacyHistory'

const HistoryScreen = () => {
  const { data: panelInfo, run: loadPanelInfo, isLoading: loadingPanelInfo } = usePanelInfo()
  const panelId = usePanelId()

  useOnMount(() => {
    if (!loadingPanelInfo) {
      loadPanelInfo(panelId)
    }
  })

  const supportsInfiniteScroll = !panelInfo?.Capabilities.includes(Capability.USE_LEGACY_HOMESCREEN)

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen footer>
      {supportsInfiniteScroll ? <InfiniteScrollHistory /> : <LegacyHistory />}
    </TcOrCrowContainer>
  )
}

export default HistoryScreen
