import { DoorsAndWindows } from './DoorsAndWindows'
import { DoorLock } from './Lock'
import { PermanentUser } from './PermanentUser'
import { SmartPlug } from './SmartPlug'
import { SmokeDetectors } from './SmokeDetectors'
import { Temperature } from './Temperature'

export enum PanelStatus {
  NOT_AVAILABLE = 0,
  TURNED_OFF = 1,
  HOME_MODE = 2,
  FULLY_ARMED = 3,
}

export const isPanelArmed = (panelStatus: PanelStatus) => {
  return panelStatus === PanelStatus.FULLY_ARMED || panelStatus === PanelStatus.HOME_MODE
}

export enum InstallationStatus {
  PLANNED = 0,
  INSTALLING = 1,
  READY_FOR_ACTIVATION = 2,
  ACTIVE = 3,
}

export enum Capability {
  CAN_MODIFY_WHEN_ARMED = 'CanModifyPanelWhileArmed',
  CAN_SCAN_FOR_WIFI = 'CanScanForWifi',
  USE_LEGACY_HOMESCREEN = 'UseLegacyHomeScreen',
  USE_LEGACY_PEOPLE = 'useLegacyPeople',
}

export enum PanelAccess {
  None = 'None',
  History = 'History',
  Directions = 'Directions',
  SecurityQuestion = 'SecurityQuestion',
  ContactPersons = 'ContactPersons',
  AlarmSystemSettings = 'AlarmSystemSettings',
  LockSettings = 'LockSettings',
  SmartplugSettings = 'SmartplugSettings',
  Photos = 'Photos',
  Smartplugs = 'Smartplugs',
  Cameras = 'Cameras',
  CameraSettings = 'CameraSettings',
  PanelUsers = 'PanelUsers',
  AddProducts = 'AddProducts',
  AppUserSettings = 'AppUserSettings',
  PreInstallationSettings = 'PreInstallationSettings',
  Wifi = 'Wifi',
  Booking = 'Booking',
  Components = 'Components',
}

export type PanelModel = {
  ArcVideoConsent: boolean | null
  PanelCodeLength: number
  LockLanguage: number
  HasAnnex: boolean
  DisplayWizard: boolean
  CanAddDoorLock: boolean
  CanAddSmartplug: boolean
  CanPartialArm: boolean
  Capabilities: Capability[]
  QuickArmEnabled: boolean
  SupportsPanelUsers: boolean
  SupportsTemporaryPanelUsers: true
  SupportsRegisterDevices: boolean
  InterviewDisplayStatus: true
  PreInstallationWizardDone: boolean
  CanChangeInstallationDate: boolean
  WizardStep: number
  PanelId: string
  DisplayName: string
  InstallationAddress: string | null
  InstallationStatus: InstallationStatus
  BookedStartDate?: string
  BookedEndDate?: string
  PropertyContact: {
    AppUserId: string
    FirstName: string
    LastName: string
    PhoneNumber: string
    AccessGroup: number
    IsPropertyContact: boolean
    IsInvite: boolean
    AddSmartPlugUserOverride: boolean
  }
  Wifi: { WifiExist: boolean; Serial: string } | null
  HasVideo: boolean
  Video: number
  Access: PanelAccess[]
  Locks: DoorLock[]
  Smartplugs: SmartPlug[]
  Temperatures: Temperature[]
  Photos: Photo[]
  People: PermanentUser[]
  DoorsAndWindows: DoorsAndWindows[]
  SmokeDetectors: SmokeDetectors[]
  HasHumidityReading: boolean
}

export interface Photo {
  Label: string
  Id: string
}
