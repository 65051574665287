const PowerOffIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9804 1.69322C13.0681 1.25486 12.8542 0.811551 12.4564 0.607431C12.0587 0.403312 11.5738 0.487971 11.2688 0.814787L6.96622 5.42466C6.58938 5.82841 6.6112 6.4612 7.01495 6.83804C7.4187 7.21487 8.05149 7.19305 8.42832 6.7893L10.3288 4.75304L9.51925 8.80099C9.4231 9.28174 9.68966 9.76129 10.1487 9.93344L14.7482 11.6583L13.6767 12.8063C13.2999 13.21 13.3217 13.8428 13.7255 14.2196C14.1292 14.5965 14.762 14.5746 15.1389 14.1709L17.2309 11.9294C17.4587 11.6853 17.5497 11.3436 17.4734 11.0186C17.3971 10.6935 17.1636 10.428 16.851 10.3108L11.6472 8.35936L12.9804 1.69322Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.827334 3.0097C1.23599 2.63819 1.86844 2.66831 2.23995 3.07697L17.2399 19.577C17.6115 19.9856 17.5813 20.6181 17.1727 20.9896C16.764 21.3611 16.1316 21.331 15.7601 20.9223L12.3444 17.1651L6.73107 23.1794C6.42604 23.5062 5.94115 23.5909 5.54342 23.3868C5.14569 23.1826 4.93176 22.7393 5.01944 22.301L6.35266 15.6348L1.14889 13.6834C0.836265 13.5662 0.602772 13.3007 0.526475 12.9756C0.450178 12.6505 0.541144 12.3089 0.768961 12.0648L4.28401 8.29865L0.760067 4.42231C0.38856 4.01366 0.418677 3.38121 0.827334 3.0097ZM3.25164 12.3359L5.6339 9.78353L10.9945 15.6802L7.67101 19.2412L8.4806 15.1932C8.57675 14.7125 8.31019 14.2329 7.85114 14.0608L3.25164 12.3359Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { PowerOffIcon }
