import { ChangeEventHandler, FormEventHandler, useState } from 'react'
import styled from 'styled-components'
import Button from '../../../../components/Crow/Button/Button'
import { addToast } from '../../../../components/Crow/Toast/Toast'
import { Paragraph } from '../../../../components/Crow/Typography'
import { Loader } from '../../../../components/Elements/Loaders'
import TwoFactorPrompt, {
  useTwoFactorPrompt,
} from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { usePanelInfo, useSetPanelSettings } from '../../../../services/requestHooks/panelInfo'
import { Accesses } from '../../../../types/PermanentUser'
import { useOnMount } from '../../../../utils/commonHooks'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../hooks/usePanelId'
import PasswordPrompt, { usePasswordPrompt } from '../../Panel/PasswordPromt'

const SystemNameChange = ({
  displayName,
  quickArmEnabled,
  permissions,
}: {
  displayName: string
  quickArmEnabled: boolean
  permissions: Accesses | any
}) => {
  const { t } = useI18n()

  const panelId = usePanelId()
  const { data, run: getPanelInfo, isLoading } = usePanelInfo()
  const [name, setName] = useState(displayName)

  useOnMount(() => {
    if (!isLoading && panelId !== data?.PanelId) {
      getPanelInfo(panelId)
    }
  })

  const {
    run: save,
    isLoading: isLoadingPanelSettings,
    error: isPanelSettingsError,
  } = useSetPanelSettings({
    onSuccess: () => {
      addToast({
        title: `${t('system_name_changed')} ${name}`,
        type: 'success',
        time: 2000,
      })
    },
  })

  const { promptState: twoFactorPromptState, promptForTwoFactor } = useTwoFactorPrompt()
  const { promptState, promptForPassword } = usePasswordPrompt()

  const handleTextChange: ChangeEventHandler<HTMLInputElement> = (e) => setName(e.target.value)

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    const ValidationCode = await promptForTwoFactor()
    if (ValidationCode) {
      const Password = await promptForPassword()
      if (!Password) return
      if (!permissions?.IsLegalOwner && !permissions?.IsAdminUser) {
        addToast({
          title: `${t('full_access')} ${t('Required')}`,
          type: 'error',
          time: 2000,
        })
      } else
        save({
          DisplayName: name,
          QuickArm: quickArmEnabled,
          Password: Password,
          PanelId: panelId,
        })
    }
  }

  return (
    <SystemNameChangeForm>
      <label htmlFor="changeName">{t('Name')}</label>
      <StyledInput
        maxLength={20}
        onChange={handleTextChange}
        id="changeName"
        data-cy="changeName"
        value={name}
        className="qa-change-panel-name"
      />
      <Paragraph grey light className="pb-10 pl-3 qa-change-panel-name-desc">
        {t('change_system_description')}
      </Paragraph>
      <Button
        disabled={isLoading}
        level="secondary"
        className="ml-auto qa-settings-save"
        onClick={handleSubmit}
      >
        {isLoadingPanelSettings ? <span>{t('Updating')}</span> : <span> {t('Save')}</span>}
      </Button>
      {isLoadingPanelSettings && (
        <div className="flex align-center">
          <Loader />
          <SystemNameChangeLoadingText grey light>
            {t('change_in_progress')}
          </SystemNameChangeLoadingText>
        </div>
      )}
      {isPanelSettingsError && <FailedRequest text={t('Something went wrong. Please try again')} />}
      <TwoFactorPrompt promptState={twoFactorPromptState} />
      <PasswordPrompt promptState={promptState} />
    </SystemNameChangeForm>
  )
}

const SystemNameChangeForm = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledInput = styled.input`
  border-radius: 12px;
  padding: 12px;
`

const SystemNameChangeLoadingText = styled(Paragraph)`
  line-height: 18px;
  padding-left: 12px;
`

export default SystemNameChange
