import classNames from 'classnames'
import { isAfter } from 'date-fns'
import { createContext, FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useI18n from '../../i18n/useI18n'
import Modal from '../Modal'
import CreateSMSCode from './components/CreateSMSCode'
import ValidateSMSCode from './components/ValidateSMSCode'
import { EXPIRES_AT, VALIDATION_CODE } from './constants'

type Props = {
  promptState: {
    onSubmit?: (validationCode: string) => void
    onCancel?: () => void
  }
  cancelUrl?: string
  panelIdOptional?: boolean
}

const TwoFactorPrompt: FC<Props> = ({
  promptState: { onSubmit, onCancel },
  cancelUrl,
  panelIdOptional,
}) => {
  const { t } = useI18n()
  const [step, setStep] = useState<'CREATE_CODE' | 'VALIDATE_CODE'>('CREATE_CODE')
  const showModal = !!(onSubmit && onCancel)

  useEffect(() => {
    if (step === 'VALIDATE_CODE' && !showModal) {
      setStep('CREATE_CODE')
    }
    return () => {
      // Reset when back button is pushed to keep modal active
      clearOngoingPrompt()
    }
  }, [showModal, step])

  if (!showModal) return null
  return (
    <Modal>
      <>
        <h1 className={classNames(`text-2xl`, `qa-smsvalidation-title`)}>{t('SMS Validation')}</h1>

        {step === 'VALIDATE_CODE' ? (
          <ValidateSMSCode
            onSubmit={onSubmit!}
            onCancel={onCancel!}
            cancelUrl={cancelUrl}
            panelIdOptional={panelIdOptional}
          />
        ) : (
          <CreateSMSCode
            openValidateCode={() => {
              setStep('VALIDATE_CODE')
            }}
            onCancel={onCancel!}
            cancelUrl={cancelUrl}
          />
        )}
      </>
    </Modal>
  )
}

type PromptHandlers = {
  onSubmit: (validationCode: string) => void
  onCancel: () => void
}

let ongoingPrompt: undefined | Promise<string | undefined>

export const clearOngoingPrompt = () => {
  ongoingPrompt = undefined
}

export const useTwoFactorPrompt = () => {
  const [handlers, setHandlers] = useState<PromptHandlers>()
  const history = useHistory()
  const promptForTwoFactor = useCallback(
    (cancelUrl?: string) => {
      if (ongoingPrompt) return ongoingPrompt
      ongoingPrompt = new Promise<string | undefined>((resolve) => {
        const validationCode = sessionStorage.getItem(VALIDATION_CODE)
        const expiresAt = sessionStorage.getItem(EXPIRES_AT)
        const isExpired = !expiresAt || isAfter(new Date(), new Date(expiresAt))
        if (validationCode && !isExpired) {
          resolve(validationCode)
        } else {
          setHandlers({
            onSubmit: (validationCode) => {
              setHandlers(undefined)
              sessionStorage.setItem(VALIDATION_CODE, validationCode)
              resolve(validationCode)
              ongoingPrompt = undefined
            },
            onCancel: () => {
              if (cancelUrl) {
                history.push(cancelUrl)
              }
              setHandlers(undefined)
              resolve(undefined)
              ongoingPrompt = undefined
            },
          })
        }
      })
      return ongoingPrompt
    },
    [history],
  )
  return {
    promptForTwoFactor,
    promptState: {
      onSubmit: handlers?.onSubmit,
      onCancel: handlers?.onCancel,
    },
  }
}

type TwofactorContextType = {
  promptForTwoFactor: (cancelUrl?: string) => Promise<string | undefined>
  cancelUrl: string
}

const initialContext: TwofactorContextType = {
  promptForTwoFactor: async () => {
    return ''
  },
  cancelUrl: '/',
}

export const TwofactorContext = createContext(initialContext)

export default TwoFactorPrompt
