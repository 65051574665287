import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, Route, Router, Switch, useHistory } from 'react-router-dom'
import PrivateRoute from '../components/authentication/PrivateRoute'
import TwoFactorRoute from '../components/authentication/TwoFactorRoute'
import BuildTag from '../components/BuildTag/BuildTag'
import '../components/Crow/Assets/fonts/metric-font.css'
import '../components/Crow/Assets/fonts/SA-Icon-Font.css'
import ErrorBoundary from '../components/ErrorBoundary'
import { ModalProvider } from '../components/Modal/Modal'
import { brand } from '../configs'
import { initializeFirebase } from '../firebase'
import { startPerformanceTimer } from '../firebase/traces'
import useI18n from '../i18n/useI18n'
import NotFoundPage from '../pages/errors/NotFoundPage'
import { RouteEntry, routes, TwoFactorRouteEntry } from '../routes'
import '../sass/tailwindUtilities.scss'
import { useAuth } from '../services/authentication/AuthContext'
import { useOnMount } from '../utils/commonHooks'
import { logNewNavigationEvent, logStateChangeEvent } from '../utils/firebaseAnalytics'
import getPageTitle from '../utils/getPageTitle'
import './app.scss'
import BrowserNotSupported from './BrowserNotSupported'

initializeFirebase()

function isIE() {
  // IE 10 and IE 11
  return /Trident\/|MSIE/.test(window.navigator.userAgent)
}

function isProduction() {
  const environment = process.env.REACT_APP_BUILDVERSION
  return environment?.toLowerCase() === 'production'
}

const flatMapRoutes = <R extends TwoFactorRouteEntry | RouteEntry>(
  routes: R[],
  prefix: string = '',
): R[] => {
  return routes
    .map((route) => ({ ...route, path: `${prefix}${route.path}` }))
    .map((route): R[] => {
      const { path, children } = route
      return [route, ...flatMapRoutes((children || []) as R[], path)]
    })
    .flat()
}

const App: FC = () => {
  const { isAuthenticated } = useAuth()
  const history = useHistory()
  function shouldStartPerformanceTimer(location: typeof history.location) {
    return (location.pathname === '/' || location.pathname.includes('/systems/')) && isAuthenticated
  }

  function trackPageView() {
    logNewNavigationEvent(getPageTitle())
  }

  useEffect(() => {
    trackPageView()
    history.listen(trackPageView)
  }, [history])

  useOnMount(() => {
    logStateChangeEvent('open')
    const title = brand === 'phonewatch' ? 'Phone Watch' : 'Sector Alarm'
    document.title = title
  })

  if (shouldStartPerformanceTimer(history.location)) {
    startPerformanceTimer('Arming_Slider_Ready')
  } else {
    history.listen((location) => {
      if (shouldStartPerformanceTimer(location)) startPerformanceTimer('Arming_Slider_Ready')
    })
  }

  const { i18n } = useI18n()
  return (
    <ModalProvider>
      <Helmet htmlAttributes={{ lang: i18n.language, dir: i18n.dir() }} />
      {!isProduction() && <BuildTag />}
      <Router history={history}>
        <ErrorBoundary>
          <Switch>
            {flatMapRoutes(routes.unauthenticatedRoutes).map((route) => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
            {flatMapRoutes(routes.authenticatedRoutes).map((route) => (
              <PrivateRoute
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
            {flatMapRoutes(routes.twoFactorRoutes).map((route) => (
              <TwoFactorRoute
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
                cancelUrl={route.cancelUrl}
              />
            ))}
            <Route path="/404">
              <NotFoundPage />
            </Route>
            <Route>
              <Redirect to="/404" />
            </Route>
          </Switch>
        </ErrorBoundary>
      </Router>
      {isIE() && <BrowserNotSupported />}
    </ModalProvider>
  )
}

export default App
