import styled from 'styled-components'
import { Loader } from '../../Elements/Loaders'
import { Paragraph } from '../Typography'

type LoaderType = 'large' | 'switchReplace' | 'fly-in'

interface CrowLoaderProps {
  text?: string
  type?: LoaderType
}

export const CrowLoader = ({ text, type }: CrowLoaderProps) => (
  <LoaderContainer style={type === 'large' ? { flexDirection: 'column' } : {}}>
    <Loader types={type ? [type] : undefined} />
    <Paragraph>{text}</Paragraph>
  </LoaderContainer>
)

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 5px;

  & > *:last-child {
    margin-left: 8px;
  }
`
