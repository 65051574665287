import { CountryCode } from 'libphonenumber-js'
import React from 'react'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import { MobileIcon, PencilIcon, StarIcon, SystemIcon } from '../../../assets/icons/crow'
import useI18n from '../../../i18n/useI18n'
import NumberField from '../../Crow/Input/NumberField'
import TextField from '../../Crow/Input/TextFieldForForm'
import { colors } from '../../Crow/Style/theme'
import Toggle from '../../Crow/Toogle/ToggleForForm'
import Tooltip from '../../Crow/Tooltip'
import { GroupTitle } from './Shared'

interface UserPrivilegesProps {
  initiallyHasSystemAccess: boolean
  isUnderage: boolean
  hasSystemAccess: boolean
  canChangePanelPinCode: boolean
  panelIsArmed: boolean
  register: any
  shouldBeDisabled: any
  panelCodeLength: number
  initiallyHasAppAccess: boolean
  hasAppAccess: boolean
  control: any
  countryCode: CountryCode
  setShowPanelCodeModal: (val: boolean) => void
  errors: any
}

const UserPrivileges = ({
  initiallyHasSystemAccess,
  isUnderage,
  hasSystemAccess,
  canChangePanelPinCode,
  panelIsArmed,
  register,
  shouldBeDisabled,
  panelCodeLength,
  initiallyHasAppAccess,
  hasAppAccess,
  control,
  countryCode,
  setShowPanelCodeModal,
  errors,
}: UserPrivilegesProps) => {
  const { t } = useI18n()

  return (
    <Wrapper>
      <GroupTitle>{t('user_privileges')}</GroupTitle>
      <Privileges>
        <Tooltip text={t('disarm_to_edit_panel_access')} active={panelIsArmed}>
          <Toggle
            key="system"
            text={t('panel_access')}
            subText={t('pin_code_to_panel')}
            Icon={
              <SystemIcon
                stroke={shouldBeDisabled('IsSystemUser') ? colors.black500 : colors.black900}
              />
            }
            disabled={shouldBeDisabled('IsSystemUser')}
            {...register('IsSystemUser')}
          />
        </Tooltip>
        {initiallyHasSystemAccess && hasSystemAccess && canChangePanelPinCode && (
          <>
            <Tooltip text={t('DISARM_TO_EDIT_PIN')} active={panelIsArmed}>
              <TextField
                prefix={'PIN'}
                postfix={
                  !panelIsArmed && (
                    <button type="button" onClick={() => setShowPanelCodeModal(true)}>
                      <PencilIcon />
                    </button>
                  )
                }
                placeholder={'•'.repeat(panelCodeLength)}
                disabled={true}
              />
            </Tooltip>
          </>
        )}
        {!initiallyHasSystemAccess && hasSystemAccess && (
          <TextField
            key="add-user-pin"
            id="add-user-pin"
            maxLength={panelCodeLength}
            placeholder={t('code_digits', { panelCodeLength })}
            {...register('PanelPinCode')}
            error={errors.PanelPinCode?.message}
          />
        )}
        <>
          <Toggle
            key="app"
            text={t('App')}
            subText={t('allow_use_of_mobile_app')}
            Icon={
              <MobileIcon
                fill={shouldBeDisabled('IsAppUser') ? colors.black500 : colors.black900}
              />
            }
            disabled={shouldBeDisabled('IsAppUser')}
            {...register('IsAppUser')}
          />
          {!initiallyHasAppAccess && hasAppAccess && (
            <Controller
              name="InvitePhoneNumber"
              control={control}
              render={({ field }) => (
                <NumberField
                  {...field}
                  value={field.value || ''}
                  defaultCountryCode={countryCode}
                  error={errors.InvitePhoneNumber?.message}
                />
              )}
            />
          )}
        </>
        <>
          <Toggle
            key="admin"
            text={t('administration_rights')}
            subText={t('can_manage_users')}
            Icon={
              <StarIcon
                fill={shouldBeDisabled('IsAdminUser') ? colors.black500 : colors.black900}
              />
            }
            disabled={shouldBeDisabled('IsAdminUser')}
            {...register('IsAdminUser')}
          />
          {hasSystemAccess && isUnderage && <UnderAgeInfo>{t('info_admin_underage')}</UnderAgeInfo>}
        </>
      </Privileges>
    </Wrapper>
  )
}

const Wrapper = styled.section``

const Privileges = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const UnderAgeInfo = styled.p`
  padding: 0 16px;
  font-size: 16px;
  color: ${colors.black800};
`

export default UserPrivileges
