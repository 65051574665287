import * as React from 'react'

const HamburgerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" {...props}>
      <path d="M31.3 15.9c0 .7-.5 1.3-1.1 1.3H13.5c-.6 0-1.1-.6-1.1-1.3 0-.7.5-1.2 1.1-1.2h16.7c.6 0 1.1.6 1.1 1.2zm0 6.3c0 .7-.5 1.2-1.1 1.2H13.5c-.6 0-1.1-.6-1.1-1.2 0-.7.5-1.2 1.1-1.2h16.7c.6 0 1.1.5 1.1 1.2zm.1 6.3c0 .7-.5 1.2-1.1 1.2H13.6c-.6 0-1.1-.6-1.1-1.2 0-.7.5-1.2 1.1-1.2h16.7c.6-.1 1.1.5 1.1 1.2z" />
    </svg>
  )
}

export { HamburgerIcon }
