import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'
import Button from '../../../../components/Crow/Button/Button'
import { CrowCheckbox } from '../../../../components/Crow/CrowCheckbox/CrowCheckbox'
import { ErrorMessage } from '../../../../components/Crow/ErrorMessage/ErrorMessage'
import TextField from '../../../../components/Crow/Input/TextFieldForForm'
import Modal from '../../../../components/Crow/Modal'
import { colors } from '../../../../components/Crow/Style/theme'
import { Paragraph, Title } from '../../../../components/Crow/Typography'
import LoadPage from '../../../../components/LoadPage'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { useConnectToWifi } from '../../../../services/requestHooks/wifi'
import { WifiDetails, WifiEncryptionType } from '../../../../types/WifiDetails'
import { logFirebaseEvent } from '../../../../utils/firebaseAnalytics'
import { usePanelId } from '../../hooks/usePanelId'
import { ModalButtonContainer, ModalTitleContainer } from './StyledWifiComponents'

type FormValues = {
  password: string
}

type ModalState = {
  show: boolean
  wifi: WifiDetails
  onSubmit?: () => void
  onCancel?: () => void
  WiFiCardSerial?: string
}

const ConnectWifiModal = ({ show, wifi, onCancel, onSubmit, WiFiCardSerial }: ModalState) => {
  const panelId = usePanelId()
  const { t } = useI18n()
  const [showPassword, setShowPassword] = useState(false)

  const { promptForTwoFactor } = useContext(TwofactorContext)
  const { run: connectToWifi, isLoading, error: wifiConnectError } = useConnectToWifi({
    onSuccess: () => {
      reset({ password: '' })
      logFirebaseEvent('settings_wifi_connect_connect_success')
      onSubmit?.()
    },
    onFailure: () => {
      logFirebaseEvent('settings_wifi_connect_connect_failure')
    },
  })

  const handleCancel = () => {
    onCancel?.()
  }

  const onSubmitConnect = async ({ password }: FormValues) => {
    if (!WiFiCardSerial) return
    const TwoFactorPin = await promptForTwoFactor()
    if (!TwoFactorPin) return
    logFirebaseEvent('settings_wifi_connect_request_made')
    connectToWifi({
      Ssid: wifi.Name,
      Password: password,
      password,
      TwoFactorPin,
      ValidationCode: TwoFactorPin,
      WiFiCardSerial,
      PanelId: panelId,
      panelId,
      EncryptionType: wifi.EncryptionType,
    })
  }

  const resolver = yup.object({
    password: yup.string().min(2, t('WIFI_PASSWORD_INFO_TEXT')),
  })

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(resolver),
  })

  if (!show) return null

  return (
    <Modal>
      <LoadPage isLoading={isLoading}>
        <ModalTitleContainer>
          <Title>{t('CONNECT')}</Title>
          <Paragraph>{`${t('CONNECT_TO_WIFI')} ${wifi.Name}`}</Paragraph>
        </ModalTitleContainer>
        <form onSubmit={handleSubmit(onSubmitConnect)}>
          <input hidden id="ssid" {...register('ssid')} />
          {wifi.EncryptionType !== WifiEncryptionType.NOT_ENCRYPTED && (
            <FieldContainer>
              <TextField
                id="password"
                label={t('WIFI_PASSWORD')}
                {...register('password')}
                error={errors.ssid?.message}
                disabled={isLoading}
                type={showPassword ? 'text' : 'password'}
                placeholder={t('WIFI_PASSWORD')}
              />
              <CrowCheckbox
                onChange={() => setShowPassword(!showPassword)}
                checked={showPassword}
                text={t('show_password')}
              />
            </FieldContainer>
          )}
          {+wifi.RadioLevel <= -75 && (
            <MarginContainer>
              <Paragraph color={colors.red}>({t('bad_connection')})</Paragraph>
            </MarginContainer>
          )}
          <ModalButtonContainer>
            <Button disabled={isLoading} level="primary">
              {t('Confirm')}
            </Button>
            <Button level="secondary" type="button" onClick={handleCancel}>
              {t('Cancel')}
            </Button>
          </ModalButtonContainer>
        </form>
        {!!wifiConnectError && wifiConnectError === '401statuscode' ? (
          <ErrorMessage text={t('wifi_unauthorized')} errorLocation="ConnectWifiModal" />
        ) : (
          !!wifiConnectError && <ErrorMessage />
        )}
      </LoadPage>
      {isLoading && (
        <Paragraph large>
          {t('wifi_connecting_to')} {wifi.Name}...
        </Paragraph>
      )}
    </Modal>
  )
}

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  & > *:last-child {
    margin-top: 10px;
  }
`

const MarginContainer = styled.div`
  margin-top: 5px;
  margin-left: 3px;
`

const emptyWifi: WifiDetails = {
  Name: '',
  Connected: 'false',
  EncryptionType: WifiEncryptionType.NOT_ENCRYPTED,
  RadioLevel: '0',
  SsidHidden: 'false',
}

/* eslint-disable import/no-unused-modules */
export const useConfirmConnect = () => {
  const [modalState, setModalState] = useState<ModalState>({
    show: false,
    wifi: emptyWifi,
  })

  const promptForConnect = (wifi: WifiDetails) => {
    return new Promise<boolean>((resolve) => {
      setModalState({
        show: true,
        wifi,
        onSubmit: () => {
          setModalState({
            show: false,
            wifi: wifi,
          })
          resolve(true)
        },
        onCancel: () => {
          setModalState({
            show: false,
            wifi: emptyWifi,
          })
          resolve(false)
        },
      })
    })
  }
  return {
    promptForConnect,
    modalState: modalState,
  }
}

export default ConnectWifiModal
