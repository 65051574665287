import './phonenumbers.scss'
import React, { FC, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { FieldError, UseFormRegister } from 'react-hook-form'
import Button from '../../../../../components/Button'
import { TranslationKey } from '../../../../../types/generated/TranslationKey'
import useI18n from '../../../../../i18n/useI18n'
import { ContactPerson, PhoneNumberType } from '../../../../../types/ContactPerson'
import TextField from '../../../../../components/forms/TextField'

type Props = {
  register: UseFormRegister<Omit<ContactPerson, 'Type'>>
  errors: { [key in PhoneNumberType]?: FieldError }
  setValue: (
    name: 'Mobile' | 'Home' | 'Work',
    value: string,
    config?: { shouldValidate: boolean },
  ) => void
  initialValues?: { [key in PhoneNumberType]?: string }
}

const PhoneNumbers: FC<Props> = ({ errors, setValue, initialValues, register }) => {
  const { t } = useI18n()

  const [visiblePhoneNumbers, setVisiblePhoneNumbers] = useState<
    { [key in PhoneNumberType]: boolean }
  >({
    [PhoneNumberType.MOBILE]: false,
    [PhoneNumberType.HOME]: false,
    [PhoneNumberType.WORK]: false,
  })

  useEffect(() => {
    const showHome = !!initialValues?.Home
    const showWork = !!initialValues?.Work
    const showMobile = !(showHome || showWork) || !!initialValues?.Mobile
    setVisiblePhoneNumbers({
      [PhoneNumberType.MOBILE]: showMobile,
      [PhoneNumberType.HOME]: showHome,
      [PhoneNumberType.WORK]: showWork,
    })
  }, [initialValues])

  const [showNumberAlternatives, setShowNumberAlternatives] = useState(false)

  const allVisible = useMemo(
    () => Object.values(visiblePhoneNumbers).every((isVisible) => isVisible),
    [visiblePhoneNumbers],
  )

  const addPhoneNumber = (phoneNumberType: PhoneNumberType) => () => {
    setVisiblePhoneNumbers((prevValue) => ({ ...prevValue, [phoneNumberType]: true }))
    setShowNumberAlternatives(false)
    setValue(phoneNumberType, initialValues?.[phoneNumberType] || '', { shouldValidate: true })
  }

  const removePhoneNumber = (phoneNumberType: PhoneNumberType) => () => {
    setVisiblePhoneNumbers((prevValue) => ({ ...prevValue, [phoneNumberType]: false }))
    setValue(phoneNumberType, '', { shouldValidate: true })
  }

  const openNumberAlternatives = () => {
    setShowNumberAlternatives(true)
  }

  const closeNumberAlternatives = () => {
    setShowNumberAlternatives(false)
  }

  return (
    <div className="phonenumbers mt-4">
      {Object.entries(visiblePhoneNumbers).map(([key, isVisible]) => (
        <div
          className={cx('phonenumbers__item', !isVisible && 'phonenumbers__item--hidden', 'mb-6')}
          aria-hidden={!isVisible}
          key={key}
          data-testid={`${key}-container`}
        >
          <Button
            className="phonenumbers__remove-button"
            type="button"
            buttonType="third"
            size="small"
            title={`${t('remove phone number')}: ${t(key as PhoneNumberType).toLocaleLowerCase()}`}
            onClick={removePhoneNumber(key as PhoneNumberType)}
          >
            —
          </Button>

          <TextField
            className="phonenumbers__label"
            {...register(key as PhoneNumberType)}
            label={key as PhoneNumberType}
            autoComplete="tel-national"
            errorKey={errors[key as PhoneNumberType]?.message as TranslationKey}
          />
        </div>
      ))}
      {!allVisible && (
        <>
          {showNumberAlternatives ? (
            <div className="slide-down">
              {t('Select type')}
              <div className="phonenumbers__add-list">
                {Object.entries(visiblePhoneNumbers)
                  .filter(([_key, isVisible]) => !isVisible)
                  .map(([key]) => (
                    <Button
                      key={key}
                      buttonType="secondary"
                      type="button"
                      size="small"
                      onClick={addPhoneNumber(key as PhoneNumberType)}
                    >
                      {t(key as PhoneNumberType)}
                    </Button>
                  ))}
              </div>
              <Button
                buttonType="link"
                size="small"
                type="button"
                onClick={closeNumberAlternatives}
              >
                {t('Cancel')}
              </Button>
            </div>
          ) : (
            <Button buttonType="link" type="button" onClick={openNumberAlternatives}>
              {`+ ${t('add phone number')}`}
            </Button>
          )}
        </>
      )}
    </div>
  )
}

export default PhoneNumbers
