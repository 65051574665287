const ReceptionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 18C18.433 18 20 16.433 20 14.5C20 12.567 18.433 11 16.5 11C14.567 11 13 12.567 13 14.5C13 16.433 14.567 18 16.5 18ZM16.5 16C17.3284 16 18 15.3284 18 14.5C18 13.6716 17.3284 13 16.5 13C15.6716 13 15 13.6716 15 14.5C15 15.3284 15.6716 16 16.5 16Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 25V23C23 20.2386 20.7614 18 18 18H16.5H15C12.2386 18 10 20.2386 10 23V25H7.21016C6.02691 25 5.10234 26.0216 5.22008 27.199L5.60018 31H4C3.44772 31 3 31.4477 3 32C3 32.5523 3.44772 33 4 33H5.80018L6.80018 43H4C3.44772 43 3 43.4477 3 44C3 44.5523 3.44772 45 4 45H43.0002C44.1047 45 45.0002 44.1046 45.0002 43V27C45.0002 25.8954 44.1047 25 43.0002 25H36V23H40C41.1046 23 42 22.1046 42 21V15C42 13.8954 41.1046 13 40 13H30C28.8954 13 28 13.8954 28 15V21C28 22.1046 28.8954 23 30 23H34V25H23ZM7.21016 27L7.61016 31H25C25.5523 31 26 31.4477 26 32C26 32.5523 25.5523 33 25 33H7.81016L8.81015 43H43.0002V27H7.21016ZM21 25V23C21 21.3431 19.6569 20 18 20H15C13.3431 20 12 21.3431 12 23V25H21ZM40 15H30V21H40V15Z"
        fill="#333131"
      />
      <path
        d="M18 14.5C18 15.3284 17.3284 16 16.5 16C15.6716 16 15 15.3284 15 14.5C15 13.6716 15.6716 13 16.5 13C17.3284 13 18 13.6716 18 14.5Z"
        fill="#E3E1E1"
      />
      <path
        d="M18 20C19.6569 20 21 21.3431 21 23V25H12V23C12 21.3431 13.3431 20 15 20H18Z"
        fill="#E3E1E1"
      />
    </svg>
  )
}

export { ReceptionIcon }
