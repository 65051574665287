const ArmedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1513 6.42391L11.1811 0.405676C10.4637 -0.136679 9.49255 -0.136679 8.77511 0.414452L0.83989 6.45012C0.315001 6.85261 0 7.49984 0 8.18217V18.2417C0 19.2127 0.76111 20 1.68855 20H6.168C7.29656 20 8.21522 19.0378 8.21522 17.8481V15.0227C8.21522 14.6203 8.51267 14.2792 8.86267 14.2792H10.9099C11.2686 14.2792 11.566 14.6203 11.566 15.0315V17.8569C11.566 19.0378 12.4847 20 13.6133 20H18.3114C19.2389 20 20 19.2127 20 18.2417V8.16473C20 7.47362 19.6763 6.82628 19.1513 6.42391Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

const SmallArmedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4059 5.49673L8.82678 1.28397C8.32457 0.904324 7.64479 0.904325 7.14258 1.29012L1.58792 5.51509C1.2205 5.79683 1 6.24989 1 6.72752V13.7692C1 14.4489 1.53278 15 2.18199 15H5.56695C6.35693 15 7 14.3265 7 13.4937V11.5C7 11.2239 7.22386 11 7.5 11H8.5C8.77614 11 9 11.2239 9 11.5V13.4998C9 14.3265 9.64307 15 10.4331 15H13.818C14.4672 15 15 14.4489 15 13.7692V6.71531C15 6.23154 14.7734 5.7784 14.4059 5.49673Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { ArmedIcon, SmallArmedIcon }
