/* eslint-disable import/no-unused-modules */
import styled, { css } from 'styled-components'
import { colors } from '../Style/theme'

// TODO: Should implement the following:
//       https://www.figma.com/file/sH2HSnhOwGW5AdBilWOFsE/Sector-Alarm-Design-System?node-id=234%3A2

export interface TitleProps {
  large?: boolean
  textLength?: number
  center?: boolean
  color?: string
}

export const Title = styled.h1<TitleProps>`
  font-family: 'Metric';
  font-weight: 600;
  font-size: ${({ large }) => (large ? '32px' : '28px')};
  line-height: ${({ large }) => (large ? '40px' : '32px')};
  letter-spacing: 0;
  text-align: ${({ center }) => (center ? 'center' : 'unset')};
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`

interface Heading3Props {
  color?: string
}

export const Heading3 = styled.h3<Heading3Props>`
  font-family: Metric;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: ${({ color }) => color || colors.black900};
`

interface Heading4Props {
  color?: string
}

export const Heading4 = styled.h4<Heading4Props>`
  font-family: Metric;
  font-weight: 300;
  font-size: 22px;
  line-height: 28px;
  color: ${({ color }) => color || colors.black900};
`

export interface SubTitleProps {
  light?: boolean
  center?: boolean
}

export const SubTitle = styled.h2<SubTitleProps>`
  font-family: 'Metric';
  font-weight: ${({ light }) => (light ? 400 : 800)};
  line-height: 24px;
  font-size: 20px;
  margin: 0.5rem 0;
  color: ${colors.black900};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
`

export interface ParagraphProps {
  large?: boolean
  light?: boolean
  bold?: boolean
  grey?: boolean
  color?: string
}

export const Paragraph = styled.p<ParagraphProps>`
  font-family: 'Metric';
  font-weight: ${({ light }) => (light ? 400 : 500)};
  font-weight: ${({ bold }) => (bold ? 800 : 500)};
  font-size: ${({ large }) => (large ? '20px' : '16px')};
  line-height: ${({ large }) => (large ? '24px' : '22px')};
  ${({ grey }) =>
    grey &&
    css`
      color: ${colors.black600};
    `}
  margin: 0;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`

export const SubHeadline = styled.p`
  font-weight: 600;
  line-height: 18px;
  font-size: 14px;
`

interface BodyProps {
  color?: string
}

export const Body = styled.p<BodyProps>`
  font-family: Metric;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ color }) => color || colors.black900};
  margin: 0;
`

interface FootnoteProps {
  color?: string
}

export const Footnote = styled.p<FootnoteProps>`
  font-family: Metric;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ color }) => color || colors.black900};
`

export interface HrProps {
  width?: number | string
  color?: string
}

export const Hr = styled.hr<HrProps>`
  width: ${({ width }) => width};
  border-color: ${({ color }) => (color ? color : colors.black200)};
  height: 1px;
`
