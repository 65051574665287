import * as React from 'react'

const TCSmartplugIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" {...props}>
      <path
        fill="currentColor"
        d="M91.4 105.1H49.2c-1.1 0-2-.8-2-2V37.3c0-.6.2-1 .6-1.4.3-.3.8-.6 1.4-.6h42c1.1 0 2 .8 2 2v58.6c0 1.1-.8 2-2 2-1.1 0-2-.8-2-2V39.3h-38V101h40.1c1.1 0 2 .8 2 2 0 1-.7 2.1-1.9 2.1"
      />
      <path
        fill="currentColor"
        d="M70.3 94.3c-7.9 0-14.4-6.7-14.4-14.8s6.4-14.8 14.4-14.8 14.4 6.7 14.4 14.8c-.1 8.3-6.4 14.8-14.4 14.8m0-25.5c-5.7 0-10.3 4.8-10.3 10.7s4.7 10.7 10.3 10.7v2-2c5.7 0 10.3-4.8 10.3-10.7S76 68.8 70.3 68.8"
      />
      <path
        fill="currentColor"
        d="M63.1 79.9c0 1.3 1.1 2.3 2.3 2.3 1.3 0 2.3-1.1 2.3-2.3 0-1.3-1.1-2.3-2.3-2.3-1.2 0-2.3 1.1-2.3 2.3m9.7 0c0 1.3 1.1 2.3 2.3 2.3 1.2 0 2.3-1.1 2.3-2.3 0-1.3-1.1-2.3-2.3-2.3-1.2 0-2.3 1.1-2.3 2.3"
      />
    </svg>
  )
}

export { TCSmartplugIcon }
