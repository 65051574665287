import * as React from 'react'

const LocationIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" {...props}>
      <path d="M74.6 93.3c-1 0-1.8-.9-1.7-1.9 0-.4.2-.8.4-1.1L85 78.6c4-3.9 6.2-9.3 6.2-14.9 0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8c0 6.6-2.6 12.9-7.2 17.5L75.9 92.9c-.4.3-.9.5-1.3.4zM70 98c-.5 0-1-.2-1.3-.5L52.5 81.3c-9.8-9.6-9.9-25.2-.3-35 4.7-4.8 11.1-7.5 17.8-7.4 1 0 1.8.8 1.8 1.8s-.8 1.8-1.8 1.8c-11.6-.1-21.1 9.3-21.2 21 0 5.7 2.2 11.2 6.3 15.2l16.2 16.2c.7.7.8 1.8.1 2.6-.4.3-.9.5-1.4.5z" />
      <path d="M70 73.4c-5.4 0-9.8-4.4-9.8-9.8s4.4-9.8 9.8-9.8 9.8 4.4 9.8 9.8-4.4 9.8-9.8 9.8zm0-15.9c-3.4 0-6.1 2.7-6.1 6.1 0 3.4 2.7 6.1 6.1 6.1s6.1-2.7 6.1-6.1c0-3.3-2.7-6.1-6.1-6.1z" />
      <circle cx={89.9} cy={44.2} r={2.2} />
      <path d="M92.9 58.3h-6c-.9 0-1.7-.7-1.8-1.7V55c-.7-.3-1.3-.7-1.9-1.1l-1.4.7c-.9.4-1.9.1-2.4-.7l-3-5.2c-.5-.8-.3-1.8.4-2.4l1.3-.9v-2.2l-1.3-.9c-.8-.5-1-1.6-.5-2.4l3-5.2c.5-.8 1.5-1.1 2.4-.7l1.4.7c.6-.4 1.3-.8 1.9-1.1V32c.1-.9.9-1.7 1.8-1.7h6c1-.1 1.8.5 2 1.5v1.6c.7.3 1.3.7 1.9 1.1l1.4-.7c.9-.4 1.9-.1 2.4.7l3 5.2c.5.8.3 1.9-.5 2.4l-1.3.9v2.2l1.3.9c.8.5 1 1.6.5 2.4l-3 5.2c-.5.8-1.5 1.2-2.4.7l-1.4-.7c-.6.4-1.2.8-1.9 1.1v1.6c0 1.1-.8 1.9-1.9 1.9.1 0 0 0 0 0zm-4.5-3.7H91v-1c.1-.7.5-1.3 1.2-1.6 1-.3 2-.9 2.8-1.6.6-.5 1.3-.6 2-.3l.9.5 1.4-2.3-.9-.6c-.6-.4-.9-1.1-.8-1.9.2-1.1.2-2.2 0-3.3-.2-.8.2-1.6.9-2l.7-.5-1.2-2.1-.9.5c-.7.3-1.4.2-2-.3-.8-.7-1.8-1.3-2.8-1.6-.7-.2-1.2-.9-1.2-1.6v-1h-2.5v1c-.1.7-.5 1.3-1.2 1.6-1 .3-2 .9-2.8 1.6-.5.5-1.3.6-2 .3l-1.3-.5-1.3 2.2 1.3.5c.6.4.9 1.1.8 1.9-.2 1.1-.2 2.2 0 3.3.1.7-.2 1.4-.8 1.9l-.9.6.9 2.4.9-.5c.7-.3 1.4-.2 2 .3.8.6 1.8 1.2 2.8 1.5.7.2 1.2.9 1.2 1.6l.2 1z" />
    </svg>
  )
}

export { LocationIcon }
