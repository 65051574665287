const LockedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 17.25C12.8284 17.25 13.5 16.5784 13.5 15.75C13.5 14.9216 12.8284 14.25 12 14.25C11.1716 14.25 10.5 14.9216 10.5 15.75C10.5 16.5784 11.1716 17.25 12 17.25Z"
        fill={props.color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.125 7.125V9H19.5C20.3284 9 21 9.67157 21 10.5V21C21 21.8284 20.3284 22.5 19.5 22.5H4.5C3.67157 22.5 3 21.8284 3 21V10.5C3 9.67157 3.67157 9 4.5 9H7.875V7.125C7.875 6.03098 8.3096 4.98177 9.08319 4.20818C9.85677 3.4346 10.906 3 12 3C13.094 3 14.1432 3.4346 14.9168 4.20818C15.6904 4.98177 16.125 6.03098 16.125 7.125ZM10.1438 5.26884C10.6361 4.77656 11.3038 4.5 12 4.5C12.6962 4.5 13.3639 4.77656 13.8562 5.26884C14.3484 5.76113 14.625 6.42881 14.625 7.125V9H9.375V7.125C9.375 6.42881 9.65156 5.76113 10.1438 5.26884ZM4.5 21V10.5H19.5V21H4.5Z"
        fill={props.color || 'currentColor'}
        className="qa-doorLocks-lockImage"
      />
    </svg>
  )
}

export { LockedIcon }
