import React, { FC, useEffect, useState } from 'react'
import Button from '../../../../../components/Button'
import Toast, { addToast } from '../../../../../components/Crow/Toast/Toast'
import LoadPage from '../../../../../components/LoadPage'
import Modal from '../../../../../components/Modal'
import useI18n from '../../../../../i18n/useI18n'
import { useAvailableTimeSlots, useSetTimeSlot } from '../../../../../services/requestHooks/booking'
import { useResourceInfo } from '../../../../../services/requestHooks/user'
import FailedRequest from '../../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../../hooks/usePanelId'
import TimeSlotForm, { TimeSlotFormSubmit } from './TimeSlotForm'
type Props = {
  show: boolean
  onSubmit?: () => void
  onCancel?: () => void
}

const TimeSlotModal: FC<Props> = ({ show, onSubmit, onCancel }) => {
  const [isLoading, setIsLoading] = useState(true)
  const panelId = usePanelId()
  const { t } = useI18n()
  const {
    run: getAvailableTimeSlots,
    data: timeSlots,
    isLoading: loadingTimeSlots,
  } = useAvailableTimeSlots({
    onSuccess: () => setIsLoading(false),
  })

  const resources = useResourceInfo()

  const { run: setTimeSlot, isLoading: settingTimeSlot, error: setTimeSlotError } = useSetTimeSlot({
    onSuccess: () => {
      onSubmit?.()
      addToast({ title: 'Timeslot successfully chosen', type: 'success' })
    },
    onFailure: ({ message }) => {
      addToast({ title: 'Error selecting timeslot', type: 'error', content: <div>{message}</div> })
    },
  })

  const handleSubmit: TimeSlotFormSubmit = (values) => {
    if (timeSlots) {
      const timeSlotIndex = +values.timeSlotIndex
      const offer = timeSlots[timeSlotIndex]
      setTimeSlot({
        offer,
        panelId,
      })
    }
  }

  useEffect(() => {
    getAvailableTimeSlots({
      panelId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelId])

  useEffect(() => {
    if (loadingTimeSlots || settingTimeSlot) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [loadingTimeSlots, settingTimeSlot])

  if (!onSubmit || !onCancel) return null

  return (
    <>
      <Toast />
      {show && (
        <Modal handleClose={onCancel}>
          <LoadPage isLoading={isLoading}>
            {!!timeSlots && timeSlots.length > 0 ? (
              <TimeSlotForm timeSlots={timeSlots} onSubmit={handleSubmit} onCancel={onCancel!} />
            ) : (
              <div>
                <h1>{t('no_time_slots_header')}</h1>
                <p>
                  {t('BookDateTime_NoAvailableTimes')} {t('Phone number')}:{' '}
                  {resources.CustomerServicePhone}
                </p>
                <Button buttonType="primary" onClick={onCancel}>
                  {t('close')}
                </Button>
                {/* todo: find correct key */}
              </div>
            )}
            {!!setTimeSlotError && (
              <FailedRequest errorId="timeslotmodal" text={setTimeSlotError} />
            )}
          </LoadPage>
        </Modal>
      )}
    </>
  )
}

export const useTimeSlotModal = () => {
  const [handlers, setHandlers] = useState<{
    show: boolean
    onSubmit?: () => void
    onCancel?: () => void
  }>({ show: false })

  const promptForTimeSlot = () => {
    return new Promise<true | undefined>((resolve) => {
      setHandlers({
        show: true,
        onSubmit: () => {
          setHandlers({
            show: false,
          })
          resolve(true)
        },
        onCancel: () => {
          setHandlers({ show: false })
          resolve(undefined)
        },
      })
    })
  }
  return {
    promptForTimeSlot,
    modalState: handlers,
  }
}

export default TimeSlotModal
