const MeetingRoomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 28C10.4477 28 10 28.4477 10 29V31C10 31.5523 10.4477 32 11 32H37C37.5523 32 38 31.5523 38 31V29C38 28.4477 37.5523 28 37 28H11Z"
        fill="#E3E1E1"
      />
      <path
        d="M35.2072 10.2929C35.5978 10.6834 35.5978 11.3166 35.2072 11.7071L29.6233 17.291C29.1097 17.8046 28.358 17.9994 27.6596 17.7999L21.653 16.0837L14.0301 20.848C13.5618 21.1407 12.9448 20.9983 12.6521 20.53C12.3594 20.0617 12.5018 19.4447 12.9701 19.152L20.593 14.3877C21.0732 14.0876 21.658 14.0051 22.2025 14.1606L28.2091 15.8768L33.793 10.2929C34.1836 9.90237 34.8167 9.90237 35.2072 10.2929Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 9C7.89543 9 7 8.10457 7 7V6C7 4.89543 7.89543 4 9 4H39C40.1046 4 41 4.89543 41 6V7C41 8.10457 40.1046 9 39 9V22C39 23.1046 38.1046 24 37 24H11C9.89543 24 9 23.1046 9 22V9ZM9 6H39V7H9V6ZM37 9H11V22H37V9Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.01599 24.0306C4.47243 24.1284 4.11106 24.6483 4.20884 25.1919L6.1619 36.0487C6.17426 36.1175 6.19338 36.1833 6.21843 36.2456C6.32792 36.6792 6.72051 37 7.18804 37L7.18804 43C7.18804 43.5523 7.63576 44 8.18804 44C8.74032 44 9.18804 43.5523 9.18804 43L9.18804 37H12.0001L12.0001 43C12.0001 43.5523 12.4478 44 13.0001 44C13.5523 44 14.0001 43.5523 14.0001 43L14.0001 37H16.188V43C16.188 43.5523 16.6358 44 17.188 44C17.7403 44 18.188 43.5523 18.188 43V37C18.7403 37 19.188 36.5523 19.188 36C19.188 35.4477 18.7403 35 18.188 35H14.0001V33H34V35H30.0001C29.4478 35 29.0001 35.4477 29.0001 36C29.0001 36.5523 29.4478 37 30.0001 37V43C30.0001 43.5523 30.4478 44 31.0001 44C31.5523 44 32.0001 43.5523 32.0001 43V37H34V43C34 43.5523 34.4477 44 35 44C35.5523 44 36 43.5523 36 43V37H39.0001V43C39.0001 43.5523 39.4478 44 40.0001 44C40.5523 44 41.0001 43.5523 41.0001 43V37C41.4676 37 41.8602 36.6792 41.9697 36.2456C41.9947 36.1833 42.0138 36.1175 42.0262 36.0487L43.9793 25.1919C44.077 24.6483 43.7157 24.1284 43.1721 24.0306C42.6285 23.9328 42.1086 24.2942 42.0108 24.8378L40.1828 35H36V33H37C38.1046 33 39 32.1046 39 31V29C39 27.8954 38.1046 27 37 27H11C9.89543 27 9 27.8954 9 29V31C9 32.1046 9.89543 33 11 33H12.0001V35H8.00534L6.17724 24.8378C6.07946 24.2942 5.55955 23.9328 5.01599 24.0306ZM37 29V31H11V29H37Z"
        fill="#333131"
      />
    </svg>
  )
}

export { MeetingRoomIcon }
