import styled from 'styled-components'
import { CheckmarkIcon, WifiSignalIcon } from '../../../../assets/icons/crow'
import { HoverContainer } from '../../../../components/Crow/AnimatedContainers/HoverContainer'
import { colors } from '../../../../components/Crow/Style/theme'
import { Paragraph } from '../../../../components/Crow/Typography/Typography'
import useI18n from '../../../../i18n/useI18n'
import { WifiDetails, WifiEncryptionType } from '../../../../types/WifiDetails'

const WifiItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 12px;
  height: 64px;
  border: 1px solid #e3e1e1;
  width: 100%;

  & > *:first-child {
    justify-content: flex-start;
  }

  & > *:last-child {
    justify-content: flex-end;
  }
  cursor: pointer;
`

const WifiItemSubContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`
const WifiIconWrapper = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid;
  border-color: ${({ color }) => color};
  border-radius: 8px;
  margin: 0 10px 0 0;
`

/* eslint-disable import/no-unused-modules */
export const WifiItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

interface WifiItemProps {
  wifiItem: WifiDetails
  disabled?: boolean
  onClick: () => void
}

export const WifiItem = ({ wifiItem, disabled, onClick }: WifiItemProps) => {
  const { t } = useI18n()
  const isConnected = wifiItem.Connected === 'True' || wifiItem.Connected === 'true'
  return (
    <HoverContainer disabled={disabled}>
      <WifiItemContainer onClick={!disabled ? onClick : () => {}}>
        <WifiItemSubContainer>
          <WifiIconWrapper color={isConnected ? '#3CA07A' : '#E3E1E1'}>
            <WifiSignalIcon wifiItem={wifiItem} />
          </WifiIconWrapper>
          <WifiItemTextContainer>
            <Paragraph>{wifiItem.Name}</Paragraph>
            <Paragraph grey>
              {wifiItem.EncryptionType === WifiEncryptionType.NOT_ENCRYPTED
                ? t('not_secured_network')
                : t('secured_network')}
            </Paragraph>
          </WifiItemTextContainer>
        </WifiItemSubContainer>
        <WifiItemSubContainer>
          {isConnected && <CheckmarkIcon color={colors.green} />}
        </WifiItemSubContainer>
      </WifiItemContainer>
    </HoverContainer>
  )
}
