import classNames from 'classnames'
import React, { ElementRef, forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from '../Style/theme'

interface Props {
  text?: string
  subText?: string
  onChange?: any
  onBlur?: any
  id?: string
  name?: string
  Icon?: ReactNode
  disabled?: boolean
  className?: string
}

const Toggle: ForwardRefRenderFunction<ElementRef<'input'>, Props> = (
  { text, subText, onChange, onBlur, id, name, Icon, disabled, className },
  ref,
) => {
  return (
    <S.Container className={className}>
      <S.InformationContainer>
        {Icon && <S.IconContainer>{Icon}</S.IconContainer>}
        <S.TextContainer>
          <S.Text disabled={disabled}>{text}</S.Text>
          <S.SubText disabled={disabled}>{subText}</S.SubText>
        </S.TextContainer>
      </S.InformationContainer>
      <S.Switch>
        <S.Input
          ref={ref}
          data-testid="toggleforform"
          type="checkbox"
          id={id}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          className={classNames(`toggle-input`, `qa-toggle-input-field`)}
        />
        <S.Slider className="toggle-slider" />
      </S.Switch>
    </S.Container>
  )
}

const S = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    box-shadow: 0 0 0 1px ${colors.black200};
    border-radius: 12px;
    padding: 15px;

    &:focus-within {
      box-shadow: 0 0 0 2px ${colors.blue};
    }
  `,
  InformationContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
  `,
  IconContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: ${colors.black200};
  `,
  /* Icon: styled(Icon)`
    color: ${colors.black800};

    ${({ disabled }) => {
      return (
        disabled &&
        css`
          color: ${colors.black500};
        `
      )
    }}
  `, */
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Text: styled.p<{ disabled?: boolean }>`
    margin: 0;
    font-family: Metric;
    font-size: 20px;
    line-height: 18px;
    color: ${({ disabled }) => (disabled ? colors.black500 : colors.black800)};
  `,
  SubText: styled.p<{ disabled?: boolean }>`
    font-family: Metric;
    font-size: 16px;
    line-height: 18px;
    color: ${({ disabled }) => (disabled ? colors.black400 : colors.black700)};
  `,
  Switch: styled.div`
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
    border-radius: 26px;
  `,
  Input: styled.input`
    opacity: 0;
    display: block;
    cursor: pointer;
    position: relative;
    z-index: 1;
    margin: 0;
    width: 100%;
    height: 100%;

    &:checked + .toggle-slider {
      background-color: ${colors.blue};
    }
    &:checked + .toggle-slider::before {
      -webkit-transform: translateX(24px);
      -ms-transform: translateX(24px);
      transform: translateX(24px);
    }

    &:disabled {
      cursor: default;
    }
    &:disabled + .toggle-slider {
      background-color: ${colors.black300};
    }
    &:disabled + .toggle-slider::before {
      background-color: ${colors.black200};
    }
  `,
  Slider: styled.span`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.black500};
    border-radius: 26px;
    -webkit-transition: 0.2s;
    transition: 0.2s;

    &::before {
      position: absolute;
      content: '';
      height: 18px;
      width: 18px;
      left: 3px;
      bottom: 3px;
      border-radius: 50%;
      background-color: ${colors.white};
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }
  `,
}

export default forwardRef(Toggle)
