/* eslint-disable import/no-unused-modules */
import { ComponentType, FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  CameraIcon,
  DoorlockIcon,
  DoorsIcon,
  HistoryIcon,
  LeakageDetectorIcon,
  HumidityIcon,
  PeopleIcon,
  SettingsIcon,
  SmartplugIcon,
  SmokeDetectorIcon,
  TemperatureIcon,
  VideoIcon,
} from '../../../assets/icons/crow'
import useI18n from '../../../i18n/useI18n'
import { usePanelId } from '../../../pages/AlarmSystems/hooks/usePanelId'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import { TemperatureAverage } from '../../../types/Temperature'
import { TranslationKeyOverride } from '../../../types/TranslationKeyOverride'
import { Carousel } from '../Carousel/Carousel'
import CrowCard from '../CrowCard/CrowCard'
import { colors } from '../Style/theme'

type Props = {
  Icon: ComponentType
  text?: TranslationKey
  to?: string
  size?: string
  averageList?: TemperatureAverage
  propertyName?: string
}

const AppLink: FC<Props> = ({ Icon, text, to, size = 'small', averageList, propertyName }) => {
  const { t } = useI18n()
  const panelId = usePanelId()

  const link = `/systems/${panelId}${to}`

  return (
    <>
      {averageList?.Averages?.length! > 0 && propertyName ? (
        <Carousel size={'smallest'} link={link} data={averageList} propertyName={propertyName!} />
      ) : (
        <S.Link to={link} className={`qa-miniapp-${text}`}>
          <CrowCard border size={size} className={`d flex flex-col items-center`}>
            <IconContainer>
              <Icon />
            </IconContainer>
            <p className="text-sm">{t(text!)}</p>
          </CrowCard>
        </S.Link>
      )}
    </>
  )
}

export const TemperatureApp: FC<Props> = ({ averageList, propertyName }) => (
  <>
    <AppLink
      Icon={TemperatureIcon}
      text="Temperature"
      to="/temperatures"
      averageList={averageList}
      propertyName={propertyName}
    />
  </>
)

export const SmartPlugApp = () => (
  <AppLink Icon={SmartplugIcon} text="smartplugs" to="/smartplugs" />
)

export const LocksApp = () => <AppLink Icon={DoorlockIcon} text="Locks" to="/locks" />

export const VideoApp = () => (
  <AppLink Icon={VideoIcon} text={'video' as TranslationKeyOverride} to="/video" />
)

export const HumidityApp = () => <AppLink Icon={HumidityIcon} text="HUMIDITY" to="/humidity" />

export const HistoryApp = () => <AppLink Icon={HistoryIcon} text="History" to="/history" />

export const SettingsApp = () => <AppLink Icon={SettingsIcon} text="Settings" to="/settings" />

export const PeopleApp = () => <AppLink Icon={PeopleIcon} text="PEOPLE" to="/people" />

export const PhotoApp = () => <AppLink Icon={CameraIcon} text="photo" to="/picture" />

export const DoorsAndWindowsApp = ({
  countOpenDoorOrWindows,
  displayExclamationMark,
}: {
  countOpenDoorOrWindows: number
  displayExclamationMark: boolean
}) => (
  <DoorsAndWindowsContainer hasOpenDoorOrWindow={countOpenDoorOrWindows && displayExclamationMark}>
    {displayExclamationMark && countOpenDoorOrWindows >= 1 && (
      <S.Exclamation>{countOpenDoorOrWindows}</S.Exclamation>
    )}
    <AppLink Icon={DoorsIcon} text="DOORS_AND_WINDOWS_MINIAPP_LABEL" to="/doors-and-windows" />
  </DoorsAndWindowsContainer>
)

export const SmokeDetectorsApp = () => (
  <AppLink Icon={SmokeDetectorIcon} text="Smoke detectors" to="/smoke-detectors" />
)

export const LeakageDetectorsApp = () => (
  <AppLink Icon={LeakageDetectorIcon} text="Leakage_detectors" to="/leakage-detectors" />
)

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
`

const S = {
  Link: styled(Link)`
    text-decoration: none;
    position: relative;
  `,
  Exclamation: styled.span`
    z-index: 1;
    margin-left: auto;
    position: relative;
    top: 18px;
    width: 20px;
    height: 20px;
    background-color: ${colors.lightRed};
    border-radius: 50%;
    color: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}

const DoorsAndWindowsContainer = styled.div<{ hasOpenDoorOrWindow: number | boolean }>`
  position: ${({ hasOpenDoorOrWindow }) => (hasOpenDoorOrWindow ? 'relative' : 'static')};
  bottom: ${({ hasOpenDoorOrWindow }) => hasOpenDoorOrWindow && '20px'};
  height: 72px;
`
