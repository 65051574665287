import { ReactNode } from 'react'
import styled from 'styled-components'
import { SignalIcon } from '../../../../assets/icons/crow'
import { colors } from '../../Style/theme'
import MobileMenu from './MobileMenu'

type Props = {
  children: ReactNode
  className?: string
  transparentBackground?: boolean
}

const Mobile = ({ children, className, transparentBackground }: Props) => {
  return (
    <S.Mobile className={className}>
      <S.Nav>
        <S.IconContainer>
          <SignalIcon width="16px" color={colors.red} />
        </S.IconContainer>
        <MobileMenu />
      </S.Nav>

      <S.Content displayBackground={!transparentBackground}>{children}</S.Content>
    </S.Mobile>
  )
}

const S = {
  Mobile: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  `,
  Nav: styled.aside`
    background-color: ${colors.black};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 16px;
    padding-left: 16px;
    min-height: 48px;
  `,
  Content: styled.div<{ displayBackground: boolean }>`
    color: ${colors.black900};
    ${({ displayBackground }) =>
      displayBackground &&
      `
    background-color: ${colors.black100};
    `}
    height: 100vh;
    overflow-y: scroll;
  `,
  IconContainer: styled.div`
    margin-right: auto;
  `,
}

export default Mobile
