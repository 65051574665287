const DoorlockIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2C8.46957 2 7.96086 2.21071 7.58579 2.58579C7.21071 2.96086 7 3.46957 7 4V6H11V4C11 3.46957 10.7893 2.96086 10.4142 2.58579C10.0391 2.21071 9.53043 2 9 2ZM5 4V6H1.72727C0.744543 6 0 6.81273 0 7.75V18.25C0 19.1873 0.744543 20 1.72727 20H16.2727C17.2555 20 18 19.1873 18 18.25V7.75C18 6.81273 17.2555 6 16.2727 6H13V4C13 2.93913 12.5786 1.92172 11.8284 1.17157C11.0783 0.421427 10.0609 0 9 0C7.93913 0 6.92172 0.421427 6.17157 1.17157C5.42143 1.92172 5 2.93913 5 4ZM2 8V18H16V8H2ZM10 13C10 13.5523 9.55229 14 9 14C8.44771 14 8 13.5523 8 13C8 12.4477 8.44771 12 9 12C9.55229 12 10 12.4477 10 13Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { DoorlockIcon }
