import styled from 'styled-components'
import { getCustomerSupportChatUrl } from '../../i18n/supportUrls'
import useI18n from '../../i18n/useI18n'
import { useUserInfo } from '../../services/requestHooks/user'
import { colors } from '../Crow/Style/theme'
import { Paragraph } from '../Crow/Typography'

const CustomerServiceInfo = () => {
  const { t } = useI18n()
  const { NationId } = useUserInfo()

  return (
    <Container>
      <Line />
      <Paragraph bold large color={colors.black}>
        {t('ACCOUNT_REMOVE_NEED_ASSISTANCE')}
      </Paragraph>
      <StyledLink
        href={getCustomerSupportChatUrl(NationId)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('Go to customer service')}
      </StyledLink>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledLink = styled.a`
  color: ${colors.blue};
  border: 2px ${colors.blue} solid;
  border-radius: 15px;
  text-decoration: none;
  padding: 10px 16px 10px 16px;
  margin-top: 24px;
  font-size: 16px;
  font-family: 'Metric';
  font-style: normal;
  font-weight: bold;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.blue};
  }
`

const Line = styled.div`
  border: 1px solid ${colors.lightGray};
  width: 40%;
  max-width: 500px;
  margin-top: 16px;
  margin-bottom: 16px;
`

export default CustomerServiceInfo
