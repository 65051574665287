const OfficeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 9C15 11.7614 12.7614 14 10 14C7.23858 14 5 11.7614 5 9C5 6.23858 7.23858 4 10 4C12.7614 4 15 6.23858 15 9Z"
        fill="#E3E1E1"
      />
      <path
        d="M4 29C4 28.4477 4.44772 28 5 28H10V32H5C4.44771 32 4 31.5523 4 31V29Z"
        fill="#E3E1E1"
      />
      <path
        d="M43 32H22V28H43C43.5523 28 44 28.4477 44 29V31C44 31.5523 43.5523 32 43 32Z"
        fill="#E3E1E1"
      />
      <path d="M22 34H10V38H22V34Z" fill="#E3E1E1" />
      <path
        d="M10 6C10.5523 6 11 6.44772 11 7V8H12C12.5523 8 13 8.44772 13 9C13 9.55228 12.5523 10 12 10H10C9.44772 10 9 9.55228 9 9V7C9 6.44772 9.44772 6 10 6Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9C16 12.3137 13.3137 15 10 15C6.68629 15 4 12.3137 4 9C4 5.68629 6.68629 3 10 3C13.3137 3 16 5.68629 16 9ZM14 9C14 11.2091 12.2091 13 10 13C7.79086 13 6 11.2091 6 9C6 6.79086 7.79086 5 10 5C12.2091 5 14 6.79086 14 9Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 25H38C39.1046 25 40 24.1046 40 23V16C40 14.8954 39.1046 14 38 14H27C25.8954 14 25 14.8954 25 16V23C25 24.1046 25.8954 25 27 25H29V27H23V21C23 19.8954 22.1046 19 21 19H11C9.89543 19 9 19.8954 9 21V27H5C3.89543 27 3 27.8954 3 29V31C3 32.1046 3.89543 33 5 33L5 44C5 44.5523 5.44772 45 6 45C6.55229 45 7 44.5523 7 44L7 33H9V37C9 38.1046 9.89543 39 11 39H14.9998V40.0003L14.9954 40.0028L9.49721 43.1322C9.01723 43.4054 8.84959 44.0159 9.12278 44.4959C9.39597 44.9759 10.0065 45.1436 10.4865 44.8704L14.9998 42.3016V44C14.9998 44.5523 15.4475 45 15.9998 45C16.5521 45 16.9998 44.5523 16.9998 44V42.3146L21.4901 44.8704C21.97 45.1436 22.5806 44.9759 22.8538 44.4959C23.127 44.0159 22.9594 43.4054 22.4794 43.1322L16.9998 40.0134V39H21C22.1046 39 23 38.1046 23 37V33H40V44C40 44.5523 40.4477 45 41 45C41.5523 45 42 44.5523 42 44V33H43C44.1046 33 45 32.1046 45 31V29C45 27.8954 44.1046 27 43 27H36V25ZM23 31H43V29H23V31ZM9 31V29H5L5 31H9ZM38 16H27V23H38V16ZM34 27H31V25H34V27ZM16.9998 33H21V21H11V33H14.9998V28C14.9998 27.4477 15.4475 27 15.9998 27C16.5521 27 16.9998 27.4477 16.9998 28V33ZM11 35V37H21V35H11Z"
        fill="#333131"
      />
    </svg>
  )
}

export { OfficeIcon }
