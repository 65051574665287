/* eslint-disable import/no-duplicates */
import classNames from 'classnames'
import cx from 'classnames'
import React, {
  ChangeEventHandler,
  ComponentType,
  ElementRef,
  FocusEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react'
import useI18n from '../../i18n/useI18n'
import { TranslationKey } from '../../types/generated/TranslationKey'
import { ValidationMessage } from './ValidationMessage'

type Props = {
  name: string
  label: TranslationKey | ComponentType
  helpText?: TranslationKey
  errorKey?: TranslationKey
  className?: string
  autoComplete?: string
  type?: 'text' | 'password' | 'checkbox' | 'email' | 'number'
  placeholder?: TranslationKey
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  disabled?: boolean
}

const TextField: ForwardRefRenderFunction<ElementRef<'input'>, Props> = (
  {
    placeholder,
    name,
    label: LabelText,
    errorKey,
    className,
    autoComplete,
    type,
    helpText,
    onChange,
    onBlur,
    disabled,
  },
  ref,
) => {
  const { t } = useI18n()

  return (
    <label htmlFor={name} className={cx('form', className)}>
      {typeof LabelText === 'string' ? t(LabelText) : <LabelText />}
      <input
        disabled={disabled}
        id={name}
        className={classNames('form__input', `qa-textfield-${name}`)}
        type={type || 'text'}
        ref={ref}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete={autoComplete}
        placeholder={(typeof placeholder === 'string' && t(placeholder.toString())) || undefined}
      />
      {!!helpText && <p className="text-sm">{t(helpText)}</p>}
      <ValidationMessage errorMessage={errorKey} />
    </label>
  )
}

export default forwardRef(TextField)
