import { yupResolver } from '@hookform/resolvers/yup'
import { CountryCode } from 'libphonenumber-js'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import styled from 'styled-components'
import { contactTypeValidator, phoneNumberValidator } from '../../../components/forms/validators'
import useI18n from '../../../i18n/useI18n'
import { usePanelInfo } from '../../../services/requestHooks/panelInfo'
import { getPanelCountryCode } from '../../../utils/numberField'
import Button from '../../Crow/Button/Button'
import NumberField from '../../Crow/Input/NumberField'
import { Loader } from '../../Elements/Loaders'
import ContactGroups from './ContactGroups'
import ContactInformation from './ContactInformation'

interface Props {
  onSubmit: SubmitHandler<any>
  cancelAction?: () => void
  isLoading?: boolean
}

const AddContactForm = ({ onSubmit, cancelAction, isLoading }: Props) => {
  const { t } = useI18n()

  const { data: panelInfo } = usePanelInfo()
  const countryCode: CountryCode = getPanelCountryCode(panelInfo?.PanelId || 'noPanelId')

  const defaultValues = {
    FirstName: '',
    LastName: '',
    Mobile: '',
    Type: -1,
  }

  const resolver = yup.object({
    FirstName: yup.string().min(2, t('error_first_name_at_least_2')),
    LastName: yup.string().min(2, t('error_last_name_at_least_2')),
    Mobile: phoneNumberValidator(t).required(t('error_phone_number_required')),
    Type: contactTypeValidator,
  })

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(resolver),
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
      <Content>
        <ContactInformation register={register} errors={errors} />
        <Controller
          name="Mobile"
          control={control}
          render={({ field }) => (
            <NumberField
              {...field}
              label={t('Phone number')}
              defaultCountryCode={countryCode}
              error={errors.Mobile?.message}
            />
          )}
        />
        <ContactGroups register={register} errors={errors} />
      </Content>
      <ButtonRow>
        <Button
          type="button"
          onClick={cancelAction}
          level="secondary"
          size="l"
          disabled={isLoading}
        >
          {t('Cancel')}
        </Button>
        <Button level="primary" size="l" disabled={isLoading}>
          {isLoading ? <Loader /> : t('ADD_CONTACT')}
        </Button>
      </ButtonRow>
    </form>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 48px;
`

const ButtonRow = styled.div`
  margin-top: 107px;
  display: flex;
  gap: 24px;

  @media only screen and (max-width: 600px) {
    justify-content: center;
  }
`

export default AddContactForm
