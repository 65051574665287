import { CountryCode } from 'libphonenumber-js'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import { MobileIcon, StarIcon, SystemIcon } from '../../../assets/icons/crow'
import useI18n from '../../../i18n/useI18n'
import NumberField from '../../Crow/Input/NumberField'
import TextField from '../../Crow/Input/TextFieldForForm'
import { colors } from '../../Crow/Style/theme'
import Toggle from '../../Crow/Toogle/ToggleForForm'

interface Props {
  register: any
  shouldBeDisabled: (fieldName: 'IsSystemUser' | 'IsAdminUser' | 'IsAppUser') => boolean
  isSystemUser: boolean
  panelCodeLength: number
  isAppUser: boolean
  control: any
  countryCode: CountryCode
  isChild: boolean
  errors: any
}

const Privileges = ({
  register,
  shouldBeDisabled,
  isSystemUser,
  panelCodeLength,
  isAppUser,
  control,
  countryCode,
  isChild,
  errors,
}: Props) => {
  const { t } = useI18n()

  return (
    <Wrapper>
      <Title>{t('user_privileges')}</Title>
      <Fields>
        <>
          <Toggle
            key="IsSystemUser"
            text={t('panel_access')}
            subText={t('pin_code_to_panel')}
            Icon={
              <SystemIcon
                stroke={shouldBeDisabled('IsSystemUser') ? colors.black500 : colors.black900}
              />
            }
            disabled={shouldBeDisabled('IsSystemUser')}
            {...register('IsSystemUser')}
          />
          {isSystemUser && (
            <TextField
              key="add-user-pin"
              id="add-user-pin"
              maxLength={panelCodeLength}
              placeholder={t('code_digits', { panelCodeLength })}
              {...register('PanelPinCode')}
              error={errors.PanelPinCode?.message}
            />
          )}
        </>
        <>
          <Toggle
            key="IsAppUser"
            text={t('App')}
            subText={t('allow_use_of_mobile_app')}
            Icon={
              <MobileIcon
                fill={shouldBeDisabled('IsAppUser') ? colors.black500 : colors.black900}
              />
            }
            disabled={shouldBeDisabled('IsAppUser')}
            {...register('IsAppUser')}
          />
          {isAppUser && (
            <Controller
              name="PhoneNumber"
              control={control}
              render={({ field }) => (
                <NumberField
                  {...field}
                  value={field.value || ''}
                  defaultCountryCode={countryCode}
                  error={errors.PhoneNumber?.message}
                />
              )}
            />
          )}
        </>
        <>
          <Toggle
            key="IsAdminUser"
            text={t('administration_rights')}
            subText={t('can_manage_users')}
            Icon={
              <StarIcon
                fill={shouldBeDisabled('IsAdminUser') ? colors.black500 : colors.black900}
              />
            }
            disabled={shouldBeDisabled('IsAdminUser')}
            {...register('IsAdminUser')}
          />
          {isSystemUser && isChild && <UnderAgeInfo>{t('info_admin_underage')}</UnderAgeInfo>}
        </>
      </Fields>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  @media only screen and (max-width: 600px) {
    margin-top: 26px;
  }
`

const Title = styled.h2`
  font-family: 'Metric';
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
`

const Fields = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const UnderAgeInfo = styled.p`
  padding: 0 16px;
  font-size: 16px;
  color: ${colors.black800};
`

export default Privileges
