import React from 'react'
import styled from 'styled-components'
import useI18n from '../../i18n/useI18n'
import { TranslationKey } from '../../types/generated/TranslationKey'
import { colors } from '../Crow/Style/theme'

const Container = styled.div`
  & > * {
    padding: 0.5rem;
    background: color(white);
    padding: 5px 0 0 0;
    color: ${colors.red};
  }
`

const ValidationMessage = ({ errorMessage }: { errorMessage?: TranslationKey }) => {
  const { t } = useI18n()
  if (!errorMessage) return null
  return (
    <Container>
      <p>{t(errorMessage)}</p>
    </Container>
  )
}

export { ValidationMessage }
