import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../components/Crow/Style/theme'
import { panelText } from '../components/PanelStatusInfo'
import useI18n from '../i18n/useI18n'
import { useDoorsAndWindows } from '../services/requestHooks/doorsandwindows'
import { PanelStatus } from '../types/PanelModel'
import convertAndFormatTime from './ConvertAndFormatTIme'
import { useDateFnsLocale } from './useDateFnsLocale'
interface ArmingStatusTextType {
  panelStatus: {
    Status: PanelStatus | undefined
    TimeZoneName: string | undefined
    StatusTimeUtc: string | undefined
  }
  isLoading: boolean
}

const ArmingStatusText = ({ panelStatus, isLoading }: ArmingStatusTextType) => {
  const [hasOpenDoorOrWindow, setHasOpenDoorOrWindow] = useState(false)
  const locale = useDateFnsLocale()
  const { t } = useI18n()
  const { data: doorsAndWindows } = useDoorsAndWindows()

  useEffect(() => {
    if (doorsAndWindows && doorsAndWindows.Floors) {
      doorsAndWindows.Floors.forEach((floor) => {
        floor.Rooms.forEach((room) => {
          room.Devices.forEach((device) => {
            if (device.Closed === false) {
              setHasOpenDoorOrWindow(true)
            }
          })
        })
      })
    }
  }, [doorsAndWindows])

  if (isLoading) {
    return <Text>{t('Loading alarm system status')}</Text>
  }

  if (!panelStatus) {
    return null
  }

  return (
    <Text>
      {t(panelText(panelStatus.Status!, hasOpenDoorOrWindow))}&nbsp;
      {convertAndFormatTime(panelStatus?.TimeZoneName, locale, panelStatus?.StatusTimeUtc, true)}
    </Text>
  )
}

const Text = styled.span`
  @media only screen and (min-width: 768px) and (max-width: 1280px) {
    color: ${colors.lightBlue};
  }
`

export default ArmingStatusText
