import classNames from 'classnames'
import React, { FC } from 'react'
import { LoaderProps } from '../Elements/Loaders'
import Footer from '../Footer'
import LoadPage from '../LoadPage'
import NavBar from '../NavBar'
import './pages.scss'

interface Props {
  isLoading?: boolean
  loaderProps?: LoaderProps
  crow?: boolean
  hideNav?: boolean
}

const NavBarPage: FC<Props> = ({ children, isLoading, loaderProps, crow = false, hideNav }) => {
  return (
    <div
      className={classNames(
        'flex flex-col flex-1 navbar-page',
        !hideNav && 'navbar-page--crow h-full',
      )}
    >
      {hideNav ? null : <NavBar />}
      <main className="flex flex-1 justify-center">
        <div className="w-full">
          <LoadPage isLoading={isLoading} loaderProps={loaderProps}>
            {children}
          </LoadPage>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default NavBarPage
