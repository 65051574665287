import { FC } from 'react'
import styled from 'styled-components'
import { AccessRevoked } from '../../components/Crow/InfoMessage/AccessRevoked'
import { Paragraph } from '../../components/Crow/Typography'
import BackgroundImagePage from '../../components/pages/BackgroundImagePage'
import NavBarPage from '../../components/pages/NavBarPage'
import CustomerServiceInfo from '../../components/system/CustomerServiceInfo'
import useI18n from '../../i18n/useI18n'
import { useAuth } from '../../services/authentication/AuthContext'
import { useGetUserInfo } from '../../services/requestHooks/user'
import { isTranslationKey } from '../../types/generated/TranslationKey'

type Props = {
  text?: string
}

const InternalErrorPage: FC<Props> = ({ text }) => {
  const auth = useAuth()
  const { hasLoaded } = useGetUserInfo()
  const { t } = useI18n()
  if (!text || !isTranslationKey(text)) {
    text = 'Something went wrong. Please try again later.'
  }

  if (auth.isAuthenticated && hasLoaded) {
    if (text === 'app_access_revoked') {
      return (
        <NavBarPage>
          <AccessRevoked />
          <CustomerServiceInfo />
        </NavBarPage>
      )
    }
    return (
      <NavBarPage>
        <TextWrapper>
          <Paragraph large>{t(text)}</Paragraph>
          <CustomerServiceInfo />
        </TextWrapper>
      </NavBarPage>
    )
  }

  return (
    <BackgroundImagePage>
      <span className="text-2xl">{t(text)}</span>
    </BackgroundImagePage>
  )
}

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default InternalErrorPage
