import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { SmartplugIcon } from '../../../../assets/icons/crow'
import { Loader } from '../../../../components/Elements/Loaders'
import FormButtons from '../../../../components/forms/FormButtons'
import FullscreenPage from '../../../../components/pages/FullscreenPage'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import {
  useAddDeviceContinue,
  useAddDeviceFinish,
  useAddDeviceStart,
} from '../../../../services/requestHooks/addDevice'
import { RootState } from '../../../../store'
import { TranslationKey } from '../../../../types/generated/TranslationKey'
import { TranslationKeyOverride } from '../../../../types/TranslationKeyOverride'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../hooks/usePanelId'
import SmartPlugNameForm from './SmartplugNameForm'

const AddSmartPlugPage = () => {
  const [step, setStep] = useState(0)
  const [label, setLabel] = useState('')
  const PanelId = usePanelId()
  const serial = useSelector((store: RootState) => store.addDevice[PanelId]?.serial)

  const { promptForTwoFactor } = useContext(TwofactorContext)
  const { run: addDeviceStart, error: startError } = useAddDeviceStart({
    onSuccess: () => {
      setStep(4)
      continueAddDevice()
    },
    onFailure: () => setStep(1),
  })
  const { run: addDeviceContinue, error: continueError } = useAddDeviceContinue({
    onSuccess: () => {
      setStep(5)
      finishAddDevice()
    },
    onFailure: () => setStep(1),
  })
  const { run: addDeviceFinish, error: finishError } = useAddDeviceFinish({
    onSuccess: () => setStep(5),
  })

  const handler = async () => {
    setStep(step + 1)
    if (step === 2) {
      const ValidationCode = await promptForTwoFactor()
      if (!ValidationCode) return
      addDeviceStart({ PanelId, ValidationCode })
    }
  }
  const continueAddDevice = async () => {
    const ValidationCode = await promptForTwoFactor()
    if (!ValidationCode) return
    addDeviceContinue({ PanelId, Label: label })
  }

  const finishAddDevice = async () => {
    const ValidationCode = await promptForTwoFactor()
    if (!ValidationCode) return
    addDeviceFinish({ PanelId, ValidationCode })
  }

  const getText = (): TranslationKey => {
    if (step === 2) return 'START_SEARCH'
    return 'Next'
  }

  const isLoadingStep = step > 2 && step < 5
  const error: TranslationKey | undefined = (startError ||
    continueError ||
    finishError) as TranslationKeyOverride

  return (
    <FullscreenPage returnURL={`/systems/${PanelId}/settings`}>
      <div className="center">
        {!isLoadingStep && <SmartplugIcon className="icon" />}
        {step === 0 && <SmartPlugNameInfo />}
        {step === 1 && <SmartPlugNameForm label={label} onChangeLabel={setLabel} />}
        {step === 2 && <SmartPlugSearch />}
        {step === 3 && <ContactScreen />}
        {step === 4 && <PressPlugScreen />}
        {!isLoadingStep && step !== 5 && (
          <div className="mt-4">
            <FormButtons
              isValid={step !== 1 || !!label}
              onClick={handler}
              submitText={getText()}
              cancelUrl={`/systems/${PanelId}/settings`}
            />
          </div>
        )}
        {step === 5 && serial && <PlugAddedScreen serial={serial} />}
        {error && <FailedRequest text={error} />}
      </div>
    </FullscreenPage>
  )
}

const SmartPlugNameInfo = () => {
  const { t } = useI18n()
  return (
    <>
      <p className="text-2xl mt-6">{t('PLUG_INTO_SOCKET')}</p>
    </>
  )
}

const SmartPlugSearch = () => {
  const { t } = useI18n()
  return <p className="text-2xl mt-4">{t('BEFORE_READY_FOR_PRESS_INFOTEXT')}</p>
}

const ContactScreen = () => {
  const { t } = useI18n()
  return (
    <div>
      <h1 className="text-xl mb-4">{t('CONTACTING_ALARM_SYSTEM')}</h1>
      <div className="center">
        <Loader types={['large']} />
      </div>
    </div>
  )
}

const PressPlugScreen = () => {
  const { t } = useI18n()
  return (
    <div>
      <h1 className="text-xl mb-4">{t('PRESS_AND_HOLD_FIVE_SECONDS')}</h1>
      <div className="center">
        <Loader types={['large']} />
      </div>
    </div>
  )
}

const PlugAddedScreen = ({ serial }: { serial: string }) => {
  const { t } = useI18n()
  const panelId = usePanelId()
  return (
    <>
      <p className=" mt-4">{t('SMART_PLUG_SUCCESSFULLY_ADDED')}</p>
      <Link to={`/systems/${panelId}/smartplugs/${serial}`} className="button button--primary mt-4">
        {t('CONFIGURE_SMART_PLUG')}
      </Link>
    </>
  )
}

export default AddSmartPlugPage
