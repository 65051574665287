import { NationId } from '../types/UserDetails'

const nationIdSupportPageMapping: Record<NationId, string> = {
  1: 'http://www.sectoralarm.se/kundservice/',
  2: 'http://www.sectoralarm.no/kundeservice/',
  3: 'http://www.phonewatch.ie/customer-support/',
  4: 'https://www.sectoralarm.fi/asiakaspalvelu/',
  5: 'https://www.sectoralarm.fr/service-client/',
  6: 'https://www.sectoralarm.es/contacta-con-nosotros/servicio-atencion-cliente/',
  7: 'https://www.sectoralarm.com/',
  8: 'https://www.sectoralarm.pt/atencao-ao-cliente/',
}

const nationIdSupportChatPageMapping: Record<NationId, string> = {
  1: 'https://www.sectoralarm.se/kundservice/kontakta-oss/chatt/',
  2: 'https://www.sectoralarm.no/kundeservice/kontakt-kundeservice/chat/',
  3: 'https://www.phonewatch.ie/customer-support/contact/chat/',
  4: 'https://www.sectoralarm.fi/asiakaspalvelu/ota-yhteytta/keskustele-kanssamme-chatissa/',
  5: 'https://www.sectoralarm.fr/service-client/contactez-nous/chat/',
  6: 'https://www.sectoralarm.es/atencion-al-cliente/contacto/chat/',
  7: 'https://www.sectoralarm.com/',
  8: 'https://www.sectoralarm.pt/',
}

export const getCustomerSupportUrl = (
  nationId: keyof typeof nationIdSupportPageMapping,
): string => {
  return nationIdSupportPageMapping[nationId]
}

export const getCustomerSupportChatUrl = (
  nationId: keyof typeof nationIdSupportChatPageMapping,
): string => {
  return nationIdSupportChatPageMapping[nationId]
}
