import styled from 'styled-components'
import { version } from '../../package.json'
import Button from '../components/Crow/Button/Button'
import { Collapsible as DefaultCollapsible } from '../components/Crow/Collapsible/Collapsible'
import { Footnote as DefaultFootnote } from '../components/Crow/Typography'
import BackgroundImagePage from '../components/pages/BackgroundImagePage'
import { SectorLogo } from '../components/SectorLogo'
import { showMyPagesReleases } from '../configs'
import releases from '../releases.json'

type TicketType = {
  key: string
  message: string
  author: string
  jiraLink: string
  prLink?: string
}

type NonTicket = {
  key: string
  message: string
  author: string
  prLink: string
}

type Release = {
  version: string
  date: string
  tickets: TicketType[]
  nonTickets: NonTicket[]
}

const VersionPage = () => {
  const bannerHeight = document.getElementById('portal-container')?.clientHeight || 0

  const openAllTickets = (e: any, links: string[]) => {
    e.preventDefault()
    links.forEach((l) => window.open(l))
  }

  if (showMyPagesReleases) {
    return (
      <Wrapper>
        <BackgroundImage bannerHeight={bannerHeight} />
        <Content>
          <Header>
            <SectorLogo />
          </Header>
          <Main>
            {releases.map(({ version, date, tickets, nonTickets }: Release, index) => {
              const isDraft = date === 'Draft'
              const isHotfix = parseInt(version.split('.')[2], 10) > 0
              const title = isDraft ? `🚧 ${version}` : isHotfix ? `🔧 ${version}` : `🎉 ${version}`
              const jiraLinks: string[] = tickets.map((t) => t.jiraLink).filter((s) => s)

              return (
                <Collapsible key={version} title={title} defaultOpen={index === 0}>
                  <Tickets>
                    {isDraft ? <i>{date}</i> : <span>{date}</span>}
                    {jiraLinks.length > 0 && (
                      <>
                        <TicketsInfo>
                          <span>Number of tickets: {jiraLinks.length}</span>
                          <div>
                            <Button level="primary" onClick={(e) => openAllTickets(e, jiraLinks)}>
                              Open all tickets
                            </Button>
                          </div>
                        </TicketsInfo>
                        <Footnote>(Requires allowing pop-ups for the button to work.)</Footnote>
                      </>
                    )}
                    {tickets.map(({ key, jiraLink, prLink, message, author }, index) => (
                      <Ticket key={key + index}>
                        <Top>
                          {jiraLink ? (
                            <TicketLink href={jiraLink} target="_blank">
                              {key}
                            </TicketLink>
                          ) : (
                            <TicketNoLink>{key}</TicketNoLink>
                          )}
                          {prLink && (
                            <PRLink href={prLink} target="_blank">
                              PR
                            </PRLink>
                          )}
                        </Top>
                        <Message>{message}</Message>
                        <Author>{author}</Author>
                      </Ticket>
                    ))}
                    {nonTickets.length > 0 && (
                      <>
                        <br />
                        <SubCollapsible title="Merge commits without tickets">
                          {nonTickets.map(({ key, prLink, message, author }) => (
                            <Ticket key={key}>
                              <Top>
                                <TicketNoLink>{key}</TicketNoLink>
                                {prLink && (
                                  <PRLink href={prLink} target="_blank">
                                    PR
                                  </PRLink>
                                )}
                              </Top>
                              <Message>{message}</Message>
                              <Author>{author}</Author>
                            </Ticket>
                          ))}
                        </SubCollapsible>
                      </>
                    )}
                  </Tickets>
                </Collapsible>
              )
            })}
          </Main>
        </Content>
      </Wrapper>
    )
  } else {
    return (
      <BackgroundImagePage>
        <h1 className="text-4xl">{version}</h1>
      </BackgroundImagePage>
    )
  }
}

const Wrapper = styled.div`
  position: relative;
  flex: 1 1 0%;
`

const Content = styled.div`
  height: 100%;
  position: relative;
`

const BackgroundImage = styled.div<{ bannerHeight: number }>`
  background-image: url(/static/media/bg.0b534327.jpg);
  background-color: #494c50;
  background-position: 50%;
  background-size: cover;
  filter: brightness(0.25);
  position: fixed;
  width: 100%;
  height: ${({ bannerHeight }) => `calc(100vh - ${bannerHeight}px`};
`

const Header = styled.header`
  position: relative;
  padding: 1rem;
  width: 100%;
`

const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 295px 0 100px 0;

  & * {
    color: black;
  }
`

const Collapsible = styled(DefaultCollapsible)`
  width: 800px;
`

const SubCollapsible = styled(DefaultCollapsible)`
  width: 100%;
`

const Tickets = styled.ul`
  background-color: white;
  border-radius: 12px;
  padding: 0;
`

const TicketsInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Footnote = styled(DefaultFootnote)`
  margin-top: 8px;
  text-align: end;
`

const Ticket = styled.li`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px;
  color: black;
  background-color: #f5f0f0;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`

const TicketLink = styled.a`
  color: blue;
  font-weight: bold;
  text-decoration: none;
`

const TicketNoLink = styled.span`
  font-weight: bold;
`

const PRLink = styled.a`
  color: blue;
  font-weight: bold;
  text-decoration: none;
`

const Author = styled.span`
  color: black;
`

const Message = styled.p`
  color: black;
`

export default VersionPage
