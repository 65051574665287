const WindowsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3C3 2.44771 3.44772 2 4 2H12C12.5523 2 13 2.44772 13 3V12C13 12.5523 12.5523 13 12 13H4C3.44772 13 3 12.5523 3 12V3ZM4 3H7.5L7.5 9H4V3ZM8.5 9L8.5 3H12V9H8.5ZM4 10H7.5V12H4V10ZM12 12H8.5V10H12V12Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M1.5 14C1.22386 14 1 14.2239 1 14.5C1 14.7761 1.22386 15 1.5 15H14.5C14.7761 15 15 14.7761 15 14.5C15 14.2239 14.7761 14 14.5 14H1.5Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { WindowsIcon }
