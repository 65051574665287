import { ForwardedRef, forwardRef, useState } from 'react'
import styled from 'styled-components'
import useI18n from '../../../i18n/useI18n'
import { colors } from '../../Crow/Style/theme'
import { Paragraph } from '../../Crow/Typography'

interface RatingPickerProps {
  range: number
  onClickRating: (ratingValue: number) => void
}

export const RatingPicker = forwardRef(
  ({ range, onClickRating }: RatingPickerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [selectedRating, setSelectedRating] = useState(-2)
    const ratingList = Array.from({ length: range }, (_, index) => index + 1)
    const { t } = useI18n()
    return (
      <Container ref={ref}>
        <NumberContainer>
          {ratingList.map((i) => (
            <NumberButton
              type="button"
              key={i}
              selected={selectedRating === i}
              onClick={() => {
                setSelectedRating(i)
                onClickRating(i)
              }}
            >
              {i}
            </NumberButton>
          ))}
        </NumberContainer>
        <LabelContainer>
          <Paragraph>{t('feedback_form_low_rating_text')}</Paragraph> {/* Add translations / */}
          <Paragraph>{t('feedback_form_high_rating_text')}</Paragraph>
        </LabelContainer>
      </Container>
    )
  },
)

interface NumberButtonProps {
  selected?: boolean
}

const NumberButton = styled.button<NumberButtonProps>`
  border: ${({ selected }) => (selected ? `1.5px solid ${colors.blue}` : 'none')};
  color: ${({ selected }) => (selected ? `${colors.blue}` : 'black')};
  border-radius: 20px;
  width: 40px;
  height: 40px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 101%;
  height: 48px;
  border: 1px solid #f0eeee;
  border-radius: 40px;
  padding: 4px 12px 4px;
`

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
`
