import { CountryCode } from 'libphonenumber-js'
import {
  ElementRef,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Select, { components } from 'react-select'
import styled, { css } from 'styled-components'
import { CheckmarkIcon, ChevronRightIcon, WarningIcon } from '../../../../assets/icons/crow'
import useI18n from '../../../../i18n/useI18n'
import { useUserInfo } from '../../../../services/requestHooks/user'
import {
  formatPhoneNumber,
  getDefaultSelectedPrefixOption,
  getPhoneNumberPrefix,
  getPrefixOptions,
  parsePhoneNumber,
  PrefixOption,
} from '../../../../utils/numberField'
import { ErrorMessage } from '../../ErrorMessage/ErrorMessage'
import { colors } from '../../Style/theme'

interface Props {
  id?: string
  name?: string
  label?: string
  defaultCountryCode: CountryCode
  value?: string
  placeholder?: string
  disabled?: boolean
  onChange?: any
  onBlur?: any
  error?: string
  className?: string
}

const NumberField: ForwardRefRenderFunction<ElementRef<'input'>, Props> = (
  {
    id = 'phone-number-field',
    name,
    label,
    defaultCountryCode,
    value = '',
    placeholder,
    disabled,
    onChange,
    onBlur,
    error,
    className,
  },
  ref,
) => {
  const { t } = useI18n()
  const { UserCultureInfo } = useUserInfo()

  const [countryCode, setCountryCode] = useState<CountryCode>(defaultCountryCode)
  const [phoneNumberPrefix, setPhoneNumberPrefix] = useState<string>(
    getPhoneNumberPrefix(countryCode),
  )
  const [phoneNumber, setPhoneNumber] = useState<string>(value)
  const [showPrefixOptions, setShowPrefixOptions] = useState<boolean>(false)

  const prefixOptions = useMemo(() => getPrefixOptions(UserCultureInfo), [UserCultureInfo])
  const defaultSelectedPrefixOption = useMemo(
    () => getDefaultSelectedPrefixOption(prefixOptions, defaultCountryCode),
    [prefixOptions, defaultCountryCode],
  )
  const [selectedPrefixOption, setSelectedPrefixOption] = useState<any>(defaultSelectedPrefixOption)

  const containerRef = useRef<HTMLDivElement>(null)

  const onSelectChange = (val: PrefixOption) => {
    setSelectedPrefixOption(val)
    setShowPrefixOptions(false)
  }

  const handleOnChange = (e: any) => {
    setPhoneNumber(formatPhoneNumber(e.target?.value, countryCode))
    if (e.target?.value.length === 0) {
      onChange('')
    } else {
      onChange(parsePhoneNumber(phoneNumberPrefix + e.target?.value))
    }
  }

  const handleOnBlur = (e: any) => {
    const container = containerRef.current
    requestAnimationFrame(() => {
      if (!container?.contains(document.activeElement)) {
        onBlur(e)
      }
    })
  }

  useEffect(() => {
    const newPrefix = getPhoneNumberPrefix(countryCode)
    setPhoneNumberPrefix(newPrefix)
    setPhoneNumber(formatPhoneNumber(phoneNumber, countryCode))
    if (phoneNumber.length === 0) {
      onChange('')
    } else {
      onChange(parsePhoneNumber(newPrefix + phoneNumber))
    }
    // eslint-disable-next-line
  }, [countryCode])

  const initialRender = useRef(true)

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else if (!showPrefixOptions) {
      document.getElementById(id)?.focus()
    }
  }, [showPrefixOptions, id])

  useEffect(() => {
    setCountryCode(selectedPrefixOption.value)
  }, [selectedPrefixOption])

  const selectStyles = {
    container: (provided: any) => ({
      ...provided,
      width: '100%',
      height: '100%',
    }),
    control: (provided: any) => ({
      ...provided,
      border: 'none',
      boxShadow: `0 0 0 1px ${colors.black200}`,
      borderRadius: '12px',
      height: '100%',
      '&:hover': {
        borderColor: 'none',
      },
      '&:focus-within': {
        boxShadow: `0 0 0 2px ${colors.blue}`,
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      paddingLeft: '15px',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: '20px',
      lineHeight: '24px',
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: '12px',
      overflow: 'hidden',
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: '0',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 50,
      padding: '0 15px',
      backgroundColor: state.isFocused ? colors.black100 : colors.white,
      color: colors.black900,
      '&:not(:first-of-type)': {
        borderTop: `1px solid ${colors.black200}`,
      },
    }),
  }

  const CustomOption = (props: any) => {
    return (
      <components.Option {...props}>
        <span>{props.label}</span>
        {props.isSelected && <CheckmarkIcon color={colors.blue} />}
      </components.Option>
    )
  }

  return (
    <Wrapper className={className}>
      {label && (
        <Label htmlFor={id}>
          <LabelText>{label}</LabelText>
        </Label>
      )}
      <Container ref={containerRef} label={label}>
        <NumberType>
          <Text>{t('Mobile')}</Text>
          <ChevronRightIcon />
        </NumberType>
        {showPrefixOptions ? (
          /* eslint-disable jsx-a11y/no-autofocus */
          <Select
            styles={selectStyles}
            defaultValue={selectedPrefixOption}
            defaultMenuIsOpen={true}
            onChange={onSelectChange}
            options={prefixOptions}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              Option: CustomOption,
            }}
            placeholder="Select country or code..."
            autoFocus
            onBlur={() => setShowPrefixOptions(!showPrefixOptions)}
          />
        ) : (
          /* eslint-enable */
          <InputContainer error={error} disabled={disabled}>
            <CountryCodeButton
              type="button"
              id="phone-number-prefix"
              disabled={disabled}
              onClick={() => setShowPrefixOptions(!showPrefixOptions)}
            >
              {phoneNumberPrefix}
            </CountryCodeButton>
            <Separator disabled={disabled} />
            <Input
              ref={ref}
              id={id}
              name={name}
              value={phoneNumber}
              placeholder={placeholder}
              disabled={disabled}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              className="numberfield-input"
            />
            {error && (
              <Error disabled={disabled}>
                <WarningIcon />
              </Error>
            )}
          </InputContainer>
        )}
      </Container>
      {error && (
        <ErrorMessageWrapper>
          <ErrorMessage text={error} errorLocation="numberField" />
        </ErrorMessageWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Label = styled.label`
  margin: 0;
  font-weight: bold;
`
const LabelText = styled.span`
  font-size: 18px;
  line-height: 22px;
  color: ${colors.black900};
`
const Container = styled.div<{ label?: string }>`
  display: flex;
  gap: 8px;
  height: 54px;
  margin-top: ${({ label }) => !!label && '8px'};
`

const NumberType = styled.div`
  display: flex;
  align-items: center;
  gap: 21px;
  font-family: Metric;
  font-size: 18px;
  line-height: 22px;
  border-radius: 12px;
  padding: 16px 11px 16px 16px;
  background-color: ${colors.black200};
  color: ${colors.black700};
`

const Text = styled.span`
  font-weight: bold;
`

const InputContainer = styled.div<{ error?: string; disabled?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-radius: 12px;
  background-color: ${({ disabled }) => (disabled ? colors.black200 : colors.white)};
  box-shadow: 0 0 0 1px ${colors.black200};
  ${({ error }) =>
    !!error &&
    css`
      box-shadow: 0 0 0 2px ${colors.red};
    `}
  &:focus-within {
    box-shadow: 0 0 0 2px ${colors.blue};
  }
`

const CountryCodeButton = styled.button`
  border-radius: 12px 0 0 12px;
  padding: 16px 15px 16px 15px;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  color: ${colors.blue};
  outline: none;
  &:focus {
    box-shadow: 1px 0 0 1px ${colors.blue};
  }
  &:disabled {
    border: none;
    background-color: ${colors.black200};
    color: ${colors.black500};
    cursor: default;
  }
  &:disabled:hover {
    background-color: ${colors.black200};
  }
`

const Separator = styled.span<{ disabled?: boolean }>`
  position: relative;
  top: 2px;
  height: 16px;
  width: 0;
  border-left: 1px solid ${({ disabled }) => (disabled ? colors.white : colors.black200)};
`

const Input = styled.input`
  border: none;
  border-radius: 0 12px 12px 0;
  width: 100%;
  outline: none;
  margin: 0;
  padding: 15px;
  background-color: transparent;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.black900};
  &:focus {
    color: ${colors.black700};
  }
  &:disabled {
    border: none;
    background-color: ${colors.black200};
    color: ${colors.black500};
  }
  &::placeholder {
    color: ${colors.black600};
  }
`

const Error = styled.span<{ disabled?: boolean }>`
  color: ${colors.red};
  font-size: 22px;
  margin-right: 15px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.red200};
    `}
`

const ErrorMessageWrapper = styled.div`
  margin: -3px 0 0 16px;
`

export default forwardRef(NumberField)
