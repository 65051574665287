const ChevronLeftIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.69398 1.78168C8.10201 1.37409 8.10201 0.71327 7.69398 0.305687C7.28596 -0.101896 6.62442 -0.101896 6.21639 0.305688L0.306029 6.20964C0.0886784 6.42676 -0.0128918 6.71573 0.00131798 7.00001C-0.0128918 7.28428 0.0886784 7.57325 0.306029 7.79037L6.21639 13.6943C6.62442 14.1019 7.28596 14.1019 7.69398 13.6943C8.10201 13.2867 8.10201 12.6259 7.69398 12.2183L2.46999 7.00001L7.69398 1.78168Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { ChevronLeftIcon }
