import React, { FC, useState } from 'react'
import LoadPage from '../../../../../components/LoadPage'
import Modal from '../../../../../components/Modal'
import useI18n from '../../../../../i18n/useI18n'
import { useInvitePropertyContact } from '../../../../../services/requestHooks/propertyContact'
import FailedRequest from '../../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../../hooks/usePanelId'
import ContactForm, { ContactFormSubmit } from '../../../Settings/Contacts/components/ContactForm'

type Props = {
  show: boolean
  onSubmit?: () => void
  onCancel?: () => void
}

const AddPropertyContactModal: FC<Props> = ({ show, onSubmit, onCancel }) => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const { run: invitePropertyContact, isLoading, error } = useInvitePropertyContact({
    onSuccess: () => {
      onSubmit?.()
    },
  })

  const handleSubmit: ContactFormSubmit = ({ FirstName, LastName, Mobile }) => {
    invitePropertyContact({ FirstName, LastName, PhoneNumber: Mobile, PanelId: panelId })
  }

  return (
    <>
      {show && (
        <Modal handleClose={onCancel}>
          <LoadPage isLoading={isLoading}>
            <h1>{t('ADD_ADMINISTRATOR')}</h1>
            <p>{t('ADD_ADMIN_INFOTEXT')}</p>
            <ContactForm
              onlyMobile
              onSubmit={handleSubmit}
              onSubmitText="ADD_CONTACT" /* todo: find correct key */
              helpText={t('ADD_ADMIN_INFOTEXT_2')}
              onCancel={onCancel}
            />
            {!!error && <FailedRequest text={error} />}
          </LoadPage>
        </Modal>
      )}
    </>
  )
}

export default AddPropertyContactModal

export const useAddPropertyContactModal = () => {
  const [modalState, setModalState] = useState<{
    show: boolean
    onSubmit?: () => void
    onCancel?: () => void
  }>({ show: false })

  const promptForNewContact = () => {
    return new Promise<true | undefined>((resolve) => {
      setModalState({
        show: true,
        onSubmit: () => {
          setModalState({ show: false })
          resolve(true)
        },
        onCancel: () => {
          setModalState({ show: false })
          resolve(undefined)
        },
      })
    })
  }
  return {
    promptForNewContact,
    modalState,
  }
}
