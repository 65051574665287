import { FC, useState } from 'react'
import useI18n from '../../i18n/useI18n'
import Button from '../Button'
import Modal from '../Modal'

interface Props {
  promptState: PromptState
}

const ConfirmationPrompt: FC<Props> = ({ children, promptState }) => {
  const { t } = useI18n()
  const { onConfirm, onCancel } = promptState

  if (!onConfirm || !onCancel) return null

  return (
    <Modal>
      <div className="timer timer__add">
        {children}
        <Button buttonType="third" onClick={onConfirm}>
          {t('Delete')}
        </Button>
        <Button buttonType="secondary" onClick={onCancel}>
          <span>{t('Cancel')}</span>
        </Button>
      </div>
    </Modal>
  )
}

interface Handlers {
  onConfirm?: () => void
  onCancel?: () => void
}

interface PromptState {
  onConfirm?: () => void
  onCancel?: () => void
}

export const useConfirmationPrompt = (): { promptState: PromptState; prompt: () => void } => {
  const [handlers, setHandlers] = useState<Handlers>({})

  const prompt = () =>
    new Promise((resolve, reject) => {
      setHandlers({
        onConfirm: () => {
          setHandlers({})
          resolve(true)
        },
        onCancel: () => {
          setHandlers({})
          reject('Rejected')
        },
      })
    })

  return {
    promptState: {
      onConfirm: handlers.onConfirm,
      onCancel: handlers.onCancel,
    },
    prompt,
  }
}

export default ConfirmationPrompt
