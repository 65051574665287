const PlayRoomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44 17.8863V9.11362C44 9.07542 43.9969 9.03743 43.9909 9C43.8724 9.00153 43.7561 9.0329 43.6527 9.09131L35.8611 13.4935C35.7515 13.5554 35.6603 13.6455 35.5969 13.7544C35.5334 13.8634 35.5 13.9874 35.5 14.1136V22.8863C35.5 22.9245 35.5031 22.9625 35.5091 22.9999C35.6276 22.9984 35.7439 22.967 35.8473 22.9086L43.6389 18.5064C43.7485 18.4445 43.8397 18.3544 43.9031 18.2454C43.9666 18.1365 44 18.0125 44 17.8863Z"
        fill="#E3E1E1"
      />
      <path
        d="M24.5021 35.9674L21.625 35.9789L27.0255 41.9744C27.5519 42.5007 28.2323 42.8455 28.968 42.9587C29.7037 43.0719 30.4563 42.9476 31.1166 42.6039C31.7768 42.2602 32.3103 41.7149 32.6395 41.0474C32.9688 40.3798 33.0766 39.6246 32.9474 38.8916L30.9034 28.3388C30.9687 28.7089 31.0021 29.0864 31.0021 29.4674C31.0021 31.1913 30.3173 32.8446 29.0983 34.0636C27.8794 35.2826 26.2261 35.9674 24.5021 35.9674Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5 3C35.2058 3 34.9168 3.07612 34.6608 3.22072L26.8692 7.62294C26.6048 7.77232 26.3852 7.98936 26.2327 8.25132C26.0802 8.51324 26 8.81086 26 9.11368V17.8863C26 18.1891 26.0802 18.4868 26.2327 18.7487C26.3852 19.0106 26.6048 19.2277 26.8692 19.3771L34.6608 23.7793C34.9168 23.9239 35.2058 24 35.5 24C35.7942 24 36.0832 23.9239 36.3392 23.7793L44.1308 19.3771C44.3952 19.2277 44.6148 19.0106 44.7673 18.7487C44.9198 18.4868 45 18.1891 45 17.8863V9.11368C45 8.81086 44.9198 8.51324 44.7673 8.25132C44.6148 7.98936 44.3952 7.77232 44.1308 7.62294L36.3392 3.22072C36.0832 3.07612 35.7942 3 35.5 3ZM28.9499 8.74447L35.5 5.04374L42.142 8.79638L35.4389 12.5835L28.9499 8.74447ZM36.5 14.2812L43 10.6087V17.7188L36.5 21.3913V14.2812ZM34.5 14.3518V21.3913L28 17.7188V10.5063L34.5 14.3518Z"
        fill="#333131"
      />
      <path
        d="M15 27.5C15 26.9477 14.5523 26.5 14 26.5C13.4477 26.5 13 26.9477 13 27.5V28.5H12C11.4477 28.5 11 28.9477 11 29.5C11 30.0523 11.4477 30.5 12 30.5H13V31.5C13 32.0523 13.4477 32.5 14 32.5C14.5523 32.5 15 32.0523 15 31.5V30.5H16C16.5523 30.5 17 30.0523 17 29.5C17 28.9477 16.5523 28.5 16 28.5H15V27.5Z"
        fill="#333131"
      />
      <path
        d="M21 29.5C21 28.9477 21.4477 28.5 22 28.5H26C26.5523 28.5 27 28.9477 27 29.5C27 30.0523 26.5523 30.5 26 30.5H22C21.4477 30.5 21 30.0523 21 29.5Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8055 24.1642C28.3989 22.7576 26.4913 21.9675 24.5022 21.9675V22.9675L24.4992 21.9675L13.5004 22L13.4989 22C11.7361 22.0005 10.0298 22.6217 8.67936 23.7546C7.32851 24.8879 6.42001 26.4608 6.11335 28.1972C6.11164 28.2069 6.11007 28.2166 6.10864 28.2263L4.07201 38.7008L4.06881 38.7181C3.90269 39.6605 4.04135 40.6314 4.46465 41.4897C4.88794 42.348 5.57383 43.049 6.4227 43.4909C7.27156 43.9328 8.2392 44.0926 9.18508 43.9471C10.1309 43.8016 11.0058 43.3583 11.6826 42.6817C11.7007 42.6635 11.718 42.6449 11.7345 42.6257L16.8252 36.9982L21.1815 36.9808L26.2651 42.624C26.282 42.6438 26.2998 42.663 26.3185 42.6817C26.9952 43.3583 27.8701 43.8016 28.816 43.9471C29.7618 44.0927 30.7295 43.9329 31.5784 43.491C32.4272 43.049 33.1131 42.348 33.5364 41.4897C33.9597 40.6314 34.0984 39.6605 33.9322 38.7181L33.9292 38.7015L31.8856 28.1499C31.6179 26.6502 30.8974 25.2561 29.8055 24.1642ZM30.8708 33.4287C30.5705 33.9115 30.214 34.3623 29.8055 34.7708C28.3995 36.1768 26.4927 36.9669 24.5043 36.9675H24.5022L23.8637 36.97L27.753 41.2875C28.1261 41.6521 28.6041 41.891 29.1201 41.9704C29.6456 42.0512 30.1832 41.9625 30.6548 41.7169C31.1264 41.4714 31.5075 41.082 31.7427 40.6051C31.9767 40.1306 32.0541 39.5941 31.9639 39.0729L30.8708 33.4287ZM24.5022 23.9675H24.5036C25.9618 23.9678 27.3601 24.5473 28.3912 25.5784C29.4227 26.6098 30.0022 28.0088 30.0022 29.4675C30.0022 30.9262 29.4227 32.3251 28.3912 33.3566C27.3598 34.388 25.9608 34.9675 24.5022 34.9675L24.4982 34.9675L16.3758 35C16.0946 35.0011 15.8268 35.1206 15.6382 35.3291L10.2486 41.287C9.87546 41.6518 9.39724 41.8909 8.88094 41.9704C8.35543 42.0512 7.81783 41.9624 7.34622 41.7169C6.87461 41.4714 6.49354 41.082 6.25837 40.6051C6.02438 40.1307 5.94694 39.5943 6.03706 39.0732L8.08079 28.5621C8.08463 28.5424 8.08786 28.5226 8.09049 28.5028C8.32254 27.2461 8.98501 26.1088 9.96479 25.2868C10.9555 24.4557 12.2073 24.0001 13.5005 24L13.5034 24L24.5022 23.9675Z"
        fill="#333131"
      />
    </svg>
  )
}

export { PlayRoomIcon }
