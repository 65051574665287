import * as React from 'react'

const AddPhotoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31" {...props}>
      <defs>
        <style>
          {
            '.icon-sector-alarm-app-add-photo_svg__icon-sector-alarm-app-add-photo_svg__cls-2{fill:currentColor}'
          }
        </style>
      </defs>
      <g data-name="Layer 3">
        <path d="M15.5 31A15.5 15.5 0 1131 15.5 15.51 15.51 0 0115.5 31z" fill="#fff" />
        <path
          className="icon-sector-alarm-app-add-photo_svg__icon-sector-alarm-app-add-photo_svg__cls-2"
          d="M15.56 19.13A3.14 3.14 0 1118.7 16a3.15 3.15 0 01-3.14 3.13zm0-5.48A2.34 2.34 0 1017.9 16a2.34 2.34 0 00-2.34-2.35z"
        />
        <path
          className="icon-sector-alarm-app-add-photo_svg__icon-sector-alarm-app-add-photo_svg__cls-2"
          d="M15.56 13a3 3 0 103 3 3 3 0 00-3-3zm0 5.48A2.48 2.48 0 1118 16a2.48 2.48 0 01-2.44 2.47z"
        />
        <path
          className="icon-sector-alarm-app-add-photo_svg__icon-sector-alarm-app-add-photo_svg__cls-2"
          d="M9.8 21a1.64 1.64 0 01-1.65-1.64v-6.19a1.65 1.65 0 011.65-1.64h2.75l.12-.43a1.26 1.26 0 011.22-1.1h3.22a1.26 1.26 0 011.22 1.06l.12.43h2.75a1.65 1.65 0 011.65 1.64v.55a1.65 1.65 0 01-1.61 1.34h-1.6a.4.4 0 110-.8h1.56a.85.85 0 00.85-.85v-.15a.87.87 0 00-.85-.93h-3.36l-.28-1q-.15-.48-.45-.48h-3.22c-.25 0-.38.26-.44.48l-.29 1H9.8a.85.85 0 00-.85.84v6.15a.85.85 0 00.85.84h11.4a.85.85 0 00.85-.84v-3.72a.4.4 0 11.8 0v3.76A1.64 1.64 0 0121.2 21z"
        />
        <path
          className="icon-sector-alarm-app-add-photo_svg__icon-sector-alarm-app-add-photo_svg__cls-2"
          d="M10.31 14.19a.56.56 0 01-.57-.57.57.57 0 111.13 0 .56.56 0 01-.56.57z"
        />
        <circle
          className="icon-sector-alarm-app-add-photo_svg__icon-sector-alarm-app-add-photo_svg__cls-2"
          cx={10.3}
          cy={13.62}
          r={0.43}
        />
      </g>
    </svg>
  )
}

export { AddPhotoIcon }
