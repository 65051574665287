import * as React from 'react'

const LockIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" {...props}>
      <path d="M84.5 105.5h-29c-4 0-7.3-3.3-7.3-7.3V41.8c0-4 3.3-7.3 7.3-7.3h28.9c4 0 7.3 3.3 7.3 7.3v4c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8v-4c0-2-1.6-3.7-3.7-3.7H55.5c-2 0-3.7 1.6-3.7 3.7v56.5c0 2 1.6 3.7 3.7 3.7h28.9c2 0 3.7-1.6 3.7-3.7v-31c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8v31c.1 4-3.2 7.2-7.2 7.2z" />
      <path d="M70 94.8c-3.2 0-5.9-2.6-5.9-5.9v-2.4c-3.1-3.2-3-8.4.2-11.5 3.2-3.1 8.4-3 11.5.2 3 3.1 3 8.1 0 11.3V89c0 3.2-2.6 5.8-5.8 5.8zm0-18.3c-2.5.1-4.4 2.2-4.3 4.7.1 1.2.6 2.3 1.5 3.2.4.4.6.9.6 1.4V89c0 1.2 1 2.3 2.3 2.3s2.3-1 2.3-2.3v-3.2c0-.5.3-1.1.7-1.4 1.9-1.6 2.1-4.5.4-6.4-1-.9-2.2-1.5-3.5-1.5zm0-8.2c-6.4-.1-11.5-5.3-11.5-11.7s5.3-11.5 11.7-11.5c3.8 0 7.3 1.9 9.5 5.1h20.8c3.5 0 6.4 2.9 6.4 6.4S104 63 100.5 63H79.7c-2.1 3.3-5.7 5.3-9.7 5.3zm0-19.5c-4.4 0-7.9 3.6-7.9 7.9 0 4.4 3.6 7.9 7.9 7.9 2.9 0 5.6-1.6 7-4.2.3-.6 1-1 1.6-.9h21.9c1.5-.2 2.6-1.6 2.4-3.1-.2-1.2-1.1-2.2-2.4-2.4H78.7c-.7 0-1.3-.4-1.7-1-1.3-2.6-4-4.2-7-4.2z" />
    </svg>
  )
}

export { LockIcon }
