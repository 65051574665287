import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

interface HoverContainerProps {
  disabled?: boolean
  children: React.ReactNode
}

export const HoverContainer = ({ disabled, children }: HoverContainerProps) => (
  <StyledHoverContainer
    style={disabled ? { opacity: 0.6 } : {}}
    whileHover={{
      scale: !disabled ? 1.02 : 1,
    }}
    whileTap={{
      scale: !disabled ? 0.98 : 1,
    }}
  >
    {children}
  </StyledHoverContainer>
)

const StyledHoverContainer = styled(motion.div)`
  width: 100%;
`
