import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config'

export const remoteConfigKeys = [
  'display_temperature_liveapp',
  'display_new_navigationmenu',
  'display_new_contact_persons',
  'display_new_settings',
] as const

export type FeatureFlags = {
  [key in typeof remoteConfigKeys[number] | (string & {})]: boolean
}

function areValidFeatureFlags(value: unknown): value is FeatureFlags {
  return (
    typeof value === 'object' &&
    value !== null &&
    Object.keys(value).every((key) => remoteConfigKeys.includes(key as any))
  )
}

export function getFeatureFlags(
  remoteConfig: ReturnType<typeof getRemoteConfig>,
): FeatureFlags | undefined {
  const defaultConfig = Object.fromEntries(remoteConfigKeys.map((key) => [key, false]))

  remoteConfig.defaultConfig = defaultConfig

  fetchAndActivate(remoteConfig)

  const values = remoteConfigKeys.map((key) => {
    const value = getValue(remoteConfig, key)
    return value.asBoolean()
  })

  const remoteConfigValues = Object.fromEntries(
    remoteConfigKeys.map((key, index) => [key, values[index]]),
  )

  if (areValidFeatureFlags(remoteConfigValues)) return remoteConfigValues
  if (areValidFeatureFlags(defaultConfig)) return defaultConfig
  return undefined
}
