import { CSSProperties } from 'react'
import styled from 'styled-components'
import { DoorsIcon, WindowsIcon } from '../../../assets/icons/crow'
import { colors } from '../../../components/Crow/Style/theme'
import { DevicesType, MountType } from '../../../types/DoorsAndWindows'
import { IconContainer } from './DoorsAndWindowsStyle'
import { Footnote } from '../../../components/Crow/Typography'

type IconColors = {
  containerColor: string
  iconColor?: string
  style?: CSSProperties
}

const DoorsAndWindowsTileFooter = (props: { props: any[] }) => {
  const devices = props.props

  const CountRemainderDevices = () => {
    const doorsAndWindows = devices.filter(
      (device) => device.MountType === MountType.DOOR || device.MountType === MountType.WINDOW,
    )
    if (doorsAndWindows.length < 4) {
      return null
    }

    return (
      <IconContainer>
        <Footnote>{`+${doorsAndWindows.length - doorsAndWindows.slice(0, 3).length}`}</Footnote>
      </IconContainer>
    )
  }

  const getIconColors = (device: DevicesType, isClosed: boolean): IconColors => {
    let iconColors: IconColors = { containerColor: '', iconColor: '' }
    if (device.MountType === MountType.DOOR) {
      iconColors = { containerColor: colors.lightRed, iconColor: colors.white }
    } else {
      iconColors = {
        containerColor: colors.white,
        iconColor: colors.red,
        style: { border: `1px solid ${colors.red}` },
      }
    }
    if (isClosed) {
      iconColors = {
        containerColor: device.LowBattery ? colors.lightYellow : colors.black100,
        iconColor: undefined,
        style: undefined,
      }
    }
    return iconColors
  }

  const CountDevices = () => {
    const locksAndMagnets = ([] as Array<DevicesType>)
      .concat(devices)
      .sort((a, b) =>
        b.Closed === a.Closed ? a.Type - b.Type : Number(b.Closed) - Number(a.Closed),
      )
      .reverse()
      .slice(0, 3)

    const renderItems = (type: MountType, isClosed?: boolean) => {
      return locksAndMagnets!
        .filter(
          (device) =>
            device.MountType === type &&
            ((isClosed && device.Closed) || (!isClosed && !device.Closed)),
        )
        .map((device) => {
          const iconColors = getIconColors(device, isClosed ? isClosed : false)
          return (
            <IconContainer
              role="listitem"
              className="w-6 h-6"
              color={iconColors.containerColor}
              key={device.Serial}
              style={iconColors.style}
            >
              {type === 0 ? (
                <DoorsIcon width="14" height="14" color={iconColors.iconColor} />
              ) : (
                <WindowsIcon width="14" height="14" color={iconColors.iconColor} />
              )}
            </IconContainer>
          )
        })
    }

    return (
      <SortedDevices role="list">
        {renderItems(MountType.DOOR)}
        {renderItems(MountType.WINDOW)}
        {renderItems(MountType.DOOR, true)}
        {renderItems(MountType.WINDOW, true)}
      </SortedDevices>
    )
  }

  return (
    <>
      <CountDevices />
      <CountRemainderDevices />
    </>
  )
}

const SortedDevices = styled.ul`
  margin-top: 2px;
  display: flex;
  padding-left: 0;
  gap: 12px;
`

export default DoorsAndWindowsTileFooter
