const InboxIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0C0.447715 0 0 0.447715 0 1V14.2222C0 14.6822 0.175866 15.1304 0.499826 15.4663C0.824979 15.8035 1.2739 16 1.75 16H18.25C18.7261 16 19.175 15.8035 19.5002 15.4663C19.8241 15.1304 20 14.6822 20 14.2222V1C20 0.447715 19.5523 0 19 0H1ZM3.29808 2L10 8.14343L10.9571 7.26612C10.9601 7.26335 10.9631 7.2606 10.9661 7.25788L16.7019 2H3.29808ZM11.6365 9.35649L10.6757 10.2372C10.2934 10.5876 9.7066 10.5876 9.32428 10.2372L8.36361 9.35654L3.29805 14H16.7021L11.6365 9.35649ZM18 12.4766L13.1163 7.99992L18 3.52324V12.4766ZM2 3.52323L6.88372 7.99997L2 12.4767V3.52323Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { InboxIcon }
