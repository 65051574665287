const getPageTitle = () => {
  const splittedPaths = window.location.pathname.split('/')
  if (splittedPaths.length > 3) {
    return splittedPaths[splittedPaths.length - 1]
  }
  if (splittedPaths.length === 3 && splittedPaths[1] === 'systems') {
    return 'System dashboard'
  }
  return splittedPaths[1]
}

export default getPageTitle
