import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'
import Button from '../../components/Crow/Button/Button'
import CrowRadioButton from '../../components/Crow/CrowRadioButton/CrowRadioButton'
import { colors } from '../../components/Crow/Style/theme'
import Toggle from '../../components/Crow/Toogle/ToggleForForm'
import LoadingOverlay from '../../components/LoadingOverlay'
import useI18n from '../../i18n/useI18n'
import { TemperatureSettingsType } from '../../types/Temperature'

const liveappSettingsList = [
  {
    Title: 'LIVEAPP_TEMP_SETTINGS_TILE_PROPERTYAVERAGE_TITLE',
    SubText: 'LIVEAPP_TEMP_SETTINGS_TILE_PROPERTYAVERAGE_SUBTITLE',
    Value: 'average',
  },
  {
    Title: 'LIVEAPP_TEMP_SETTINGS_TILE_ROOMSCYCLE_TITLE',
    SubText: 'LIVEAPP_TEMP_SETTINGS_TILE_ROOMSCYCLE_SUBTITLE',
    Value: 'places',
  },
  {
    Title: 'LIVEAPP_TEMP_SETTINGS_TILE_FLOORSCYCLE_TITLE',
    SubText: 'LIVEAPP_TEMP_SETTINGS_TILE_FLOORSCYCLE_SUBTITLE',
    Value: 'sections',
  },
]

interface Props {
  temperatureSetting: TemperatureSettingsType
  onSubmit: SubmitHandler<any>
  isLoading: boolean
}

export const TemperatureSettingsForm = ({ temperatureSetting, onSubmit, isLoading }: Props) => {
  const { t } = useI18n()
  const defaultValues = {
    liveappSetting: temperatureSetting.liveappSetting,
    liveappToggle: temperatureSetting.liveappToggle,
  }

  const resolver = yup.object({
    liveappToggle: yup.boolean(),
  })
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    watch,
  } = useForm<TemperatureSettingsType>({
    defaultValues: defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(resolver),
  })
  const liveapp = watch('liveappToggle')

  return (
    <S.Wrapper>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
        <S.liveAppTitle>{t('LIVEAPP_TEMP_SETTINGS_SWITCH_TITLE')}</S.liveAppTitle>

        <S.Settings>
          <S.liveappInfo>{t('LIVEAPP_TEMP_SETTINGS_SWITCH_DESCRIPTION')}</S.liveappInfo>

          <Toggle
            key="liveapp"
            text={t('LIVEAPP_TEMP_SETTINGS_SWITCH_TEXT')}
            {...register('liveappToggle')}
          />
          {liveapp && (
            <>
              <S.liveAppTitle>{t('LIVEAPP_TEMP_SETTINGS_TILE_TITLE')}</S.liveAppTitle>
              <S.liveappInfo>{t('LIVEAPP_TEMP_SETTINGS_TILE_DESCRIPTION')} </S.liveappInfo>
              {liveappSettingsList.map((liveappSetting) => (
                <CrowRadioButton
                  {...register('liveappSetting')}
                  title={t(liveappSetting.Title)}
                  defaultChecked={liveappSetting.Value === defaultValues.liveappSetting}
                  key={liveappSetting.Title}
                  value={liveappSetting.Value}
                  id={liveappSetting.Title}
                  subTitle={t(liveappSetting.SubText)}
                />
              ))}
            </>
          )}
          {isDirty && (
            <S.ButtonRow>
              <LoadingOverlay isLoading={isLoading}>
                <Button level="secondary" type="submit">
                  {t('Save')}
                </Button>
              </LoadingOverlay>
            </S.ButtonRow>
          )}
        </S.Settings>
      </form>
    </S.Wrapper>
  )
}

const S = {
  Wrapper: styled.section`
    margin-bottom: 16px;
  `,
  Settings: styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,
  ButtonRow: styled.div`
    margin-top: 20px;
    display: flex;
    gap: 24px;
    justify-content: flex-end;
  `,
  liveappInfo: styled.p`
    font-size: 16px;
    color: ${colors.black800};
    flex: none;
    margin: 0px 10px;
    position: static;
    font-family: 'Metric';
    line-height: 22px;
    display: flex;
  `,
  liveAppTitle: styled.h3`
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: ${colors.black800};
  `,
}
