import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFeatureToggle } from '../../../../components/FeatureToggle'
import { useModalPrompt } from '../../../../components/Modal/Modal'
import {
  clearOngoingPrompt,
  useTwoFactorPrompt,
} from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import { usePanelInfo, useSetArcConsent } from '../../../../services/requestHooks/panelInfo'
import { UserFeature } from '../../../../types/UserDetails'
import { useOnMount } from '../../../../utils/commonHooks'
import { usePanelId } from '../../hooks/usePanelId'
import { brand } from '../../../../configs'
import OldConsentPage from './OldConsentPage'
import NewConsentContent from './NewConsentContent'
import Toast, { addToast } from '../../../../components/Crow/Toast/Toast'
import useI18n from '../../../../i18n/useI18n'
import { errorCodesTriggerNew2FA } from '../../../../utils/errorCodesTriggerNew2FA'
type Props = {
  oldConcentPage: boolean
}
const ConsentWrapper = ({ oldConcentPage }: Props) => {
  const panelId = usePanelId()
  const company = brand === 'phonewatch' ? 'Phonewatch ' : 'Sector Alarm '
  const { t } = useI18n()
  const { promptState: twoFactorPromptState, promptForTwoFactor } = useTwoFactorPrompt()
  const { promptForValue: promptForTerms, modalState: videoControlState } = useModalPrompt<true>()
  const { promptForValue: promptForRemove, modalState: removeVideoControlState } = useModalPrompt<
    true
  >()

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: panelInfoError,
  } = usePanelInfo()

  const {
    run: setArcConsent,
    isLoading: isLoadingArcConsent,
    error: arcConsentError,
  } = useSetArcConsent()
  const [toggle, setToggle] = useState<boolean>(!!panelInfo?.ArcVideoConsent)

  const history = useHistory()
  const hasArcConsent = useFeatureToggle(UserFeature.ARC_CONSENT)

  useEffect(() => {
    if (panelInfo) {
      setToggle(!!panelInfo.ArcVideoConsent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelInfo])

  useOnMount(() => {
    if (!hasArcConsent) {
      history.replace(`/systems/${panelId}/settings`)
    }

    if (!panelInfo && !loadingPanelInfo) {
      loadPanelInfo(panelId)
    }
  })

  const toggleConsent = async () => {
    const ValidationCode = await promptForTwoFactor()
    if (ValidationCode) {
      let confirm: boolean | undefined = false
      if (!toggle) {
        confirm = await promptForTerms()
      } else {
        confirm = await promptForRemove()
      }
      if (confirm) {
        const Consent = !panelInfo?.ArcVideoConsent
        setArcConsent({ Consent, PanelId: panelId, ValidationCode })
      }
    }
  }

  useEffect(() => {
    const prompt = async () => {
      const ValidationCode = await promptForTwoFactor()
      addToast({
        type: 'warning',
        time: 20000,
        title: t('warning_toast_generic_title'),
        content: t('2fa_expired_and_refreshed'),
      })
      if (ValidationCode) {
        const Consent = !panelInfo?.ArcVideoConsent
        setArcConsent({ Consent, PanelId: panelId, ValidationCode })
      }
    }

    if (arcConsentError) {
      if (errorCodesTriggerNew2FA.includes(arcConsentError.toLocaleLowerCase())) {
        clearOngoingPrompt()
        prompt()
      } else {
        addToast({
          type: 'error',
          time: 5000,
          title: t('error_toast_generic_title'),
          content: `${t('error_toast_generic_content')} ${
            arcConsentError ? ': ' + arcConsentError : ''
          }`,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arcConsentError])

  if (oldConcentPage) {
    return (
      <OldConsentPage
        panelId={panelId}
        loadingPanelInfo={loadingPanelInfo}
        panelInfoError={panelInfoError}
        company={company}
        toggleConsent={toggleConsent}
        toggle={toggle}
        arcConsentError={arcConsentError}
        videoControlState={videoControlState}
        removeVideoControlState={removeVideoControlState}
        twoFactorPromptState={twoFactorPromptState}
        settingArcConsent={isLoadingArcConsent}
      />
    )
  } else {
    return (
      <>
        <NewConsentContent
          toggleConsent={toggleConsent}
          panelInfo={panelInfo}
          arcConsentError={arcConsentError}
          isLoadingArcConsent={isLoadingArcConsent}
          videoControlState={videoControlState}
          removeVideoControlState={removeVideoControlState}
          twoFactorPromptState={twoFactorPromptState}
          toggle={toggle}
          setToggle={setToggle}
        />
        <Toast />
      </>
    )
  }
}

export const ConsentPage = () => <ConsentWrapper oldConcentPage={true} />

export const VideoPrivacyConsent = () => <ConsentWrapper oldConcentPage={false} />
