const UnlockedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15Z"
        fill={props.color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.375 3C11.7451 3 11.141 3.25022 10.6956 3.69562C10.2502 4.14102 10 4.74511 10 5.375V8H19.25C20.2165 8 21 8.7835 21 9.75V20.25C21 21.2165 20.2165 22 19.25 22H4.75C3.7835 22 3 21.2165 3 20.25V9.75C3 8.7835 3.7835 8 4.75 8H8V5.375C8 4.21468 8.46094 3.10188 9.28141 2.28141C10.1019 1.46094 11.2147 1 12.375 1C13.5353 1 14.6481 1.46094 15.4686 2.28141C15.8408 2.6536 16.139 3.08601 16.3538 3.55565C16.5834 4.05792 16.3624 4.65127 15.8602 4.88093C15.3579 5.11059 14.7646 4.8896 14.5349 4.38734C14.4183 4.13235 14.2564 3.89769 14.0544 3.69562C13.609 3.25022 13.0049 3 12.375 3ZM5 10V20H19V10H5Z"
        fill={props.color || 'currentColor'}
        className="qa-doorLocks-unLockImage"
      />
    </svg>
  )
}

export { UnlockedIcon }
