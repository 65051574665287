import React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { SmartplugIcon } from '../../../../assets/icons/crow'
import FormButtons from '../../../../components/forms/FormButtons'
import useI18n from '../../../../i18n/useI18n'
import { useGetSmartplug, useSetSmartplugLabel } from '../../../../services/requestHooks/plugs'
import { useOnMount } from '../../../../utils/commonHooks'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import SettingsSubPage from '../components/SettingsSubPage'
import * as yup from 'yup'
import { string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import TextField from '../../../../components/forms/TextField'
import { TranslationKey } from '../../../../types/generated/TranslationKey'

const PlugNameForm = ({ name, returnUrl }: { name: string; returnUrl: string }) => {
  const { t } = useI18n()
  const history = useHistory()
  const { panelId, smartplugSerial } = useParams<{ panelId: string; smartplugSerial: string }>()
  const { run: setLabel, error: saveError, isLoading: isSaving } = useSetSmartplugLabel({
    onSuccess: () => {
      history.replace(`/systems/${panelId}/smartplugs/${smartplugSerial}`)
    },
  })

  const resolver = yup.object({
    name: string().max(12, t('Maximum length is 12 characters')),
  })

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: { name },
    resolver: yupResolver(resolver),
  })

  const onSubmit = async ({ name }: { name: string }) => {
    setLabel({
      PanelId: panelId,
      Label: name,
      Serial: smartplugSerial,
    })
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col center">
        <SmartplugIcon className="icon status-list__icon-svg self-center mb-6" />
        <h1 className="text-2xl">{t('Edit name')}</h1>
        <TextField
          {...register('name')}
          errorKey={errors.name?.message as TranslationKey}
          label="Name"
          type="text"
        />
        <div>
          <FormButtons
            isValid={isValid}
            isLoading={isSaving}
            submitText={'Save new name'}
            cancelUrl={returnUrl}
          />
        </div>
      </form>
      {saveError && <FailedRequest text={saveError} />}
    </>
  )
}

const PlugNameFormWrapper = () => {
  const { panelId, smartplugSerial } = useParams<{ panelId: string; smartplugSerial: string }>()
  const { run: getPlug, isLoading, data: plug } = useGetSmartplug()
  const returnUrl = `/systems/${panelId}/smartplugs/${smartplugSerial}`
  const name = plug?.Label
  useOnMount(() => {
    if (!isLoading && !plug) getPlug({ panelId, serial: smartplugSerial })
  })
  return (
    <SettingsSubPage returnUrl={returnUrl} isLoading={isLoading}>
      {name && <PlugNameForm name={name} returnUrl={returnUrl} />}
    </SettingsSubPage>
  )
}

export default PlugNameFormWrapper
