const LaundryRoomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 36C26.7614 36 29 33.7614 29 31C29 28.2386 26.7614 26 24 26C21.2386 26 19 28.2386 19 31C19 33.7614 21.2386 36 24 36Z"
        fill="#E3E1E1"
      />
      <path d="M37 44V40H11V44H37Z" fill="#E3E1E1" />
      <path
        d="M14 18C13.4477 18 13 18.4477 13 19C13 19.5523 13.4477 20 14 20H17C17.5523 20 18 19.5523 18 19C18 18.4477 17.5523 18 17 18H14Z"
        fill="#333131"
      />
      <path
        d="M31 20C31.5523 20 32 19.5523 32 19C32 18.4477 31.5523 18 31 18C30.4477 18 30 18.4477 30 19C30 19.5523 30.4477 20 31 20Z"
        fill="#333131"
      />
      <path
        d="M34 20C34.5523 20 35 19.5523 35 19C35 18.4477 34.5523 18 34 18C33.4477 18 33 18.4477 33 19C33 19.5523 33.4477 20 34 20Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 31C30 34.3137 27.3137 37 24 37C20.6863 37 18 34.3137 18 31C18 27.6863 20.6863 25 24 25C27.3137 25 30 27.6863 30 31ZM21.1372 31.3416C21.6404 31.1739 22.1896 31.2129 22.6641 31.4502L24.4412 32.3387C25.39 32.8131 26.4885 32.8912 27.4949 32.5557L27.7165 32.4819C27.1277 33.9574 25.6856 35 24 35C22.0298 35 20.3923 33.5756 20.0611 31.7003L21.1372 31.3416ZM20.2835 29.518C20.8724 28.0426 22.3144 27 24 27C25.9702 27 27.6077 28.4244 27.9389 30.2996L26.8625 30.6584C26.3593 30.8261 25.81 30.7871 25.3356 30.5498L23.5585 29.6613C22.6096 29.1869 21.5111 29.1088 20.5047 29.4443L20.2835 29.518Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17C10 15.8954 10.8954 15 12 15H36C37.1046 15 38 15.8954 38 17V43C38 44.1046 37.1046 45 36 45H12C10.8954 45 10 44.1046 10 43V17ZM12 17H36V21H12V17ZM12 23V39H36V23H12ZM12 43V41H36V43H12Z"
        fill="#333131"
      />
    </svg>
  )
}

export { LaundryRoomIcon }
