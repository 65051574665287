const GymIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 12H11V26H8V23H5V15H8V12Z" fill="#E3E1E1" />
      <path d="M40 26L37 26V12H40V15L43 15V23H40V26Z" fill="#E3E1E1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14V13C7 11.8954 7.89543 11 9 11H10C11.1046 11 12 11.8954 12 13V18H13V15C13 14.4477 13.4477 14 14 14C14.5523 14 15 14.4477 15 15V18H33V15C33 14.4477 33.4477 14 34 14C34.5523 14 35 14.4477 35 15V18H36V13C36 11.8954 36.8954 11 38 11H39C40.1046 11 41 11.8954 41 13V14H42C43.1046 14 44 14.8954 44 16V18C44.5523 18 45 18.4477 45 19C45 19.5523 44.5523 20 44 20V22C44 23.1046 43.1046 24 42 24H41V25C41 26.1046 40.1046 27 39 27H38C36.8954 27 36 26.1046 36 25V20H35V43H44C44.5523 43 45 43.4477 45 44C45 44.5523 44.5523 45 44 45H4C3.44772 45 3 44.5523 3 44C3 43.4477 3.44772 43 4 43H13L13 20H12V25C12 26.1046 11.1046 27 10 27H9C7.89543 27 7 26.1046 7 25V24H6C4.89543 24 4 23.1046 4 22L4 20C3.44771 20 3 19.5523 3 19C3 18.4477 3.44772 18 4 18L4 16C4 14.8954 4.89543 14 6 14H7ZM9 13H10V25H9V13ZM33 43L33 20H15L15 43H33ZM41 22V16L42 16V22H41ZM39 13V25H38V13L39 13ZM7 16H6V22H7V16Z"
        fill="#333131"
      />
    </svg>
  )
}

export { GymIcon }
