import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { CancelIcon, HiddenIcon, VisibleIcon } from '../../../assets/icons/crow'
import useI18n from '../../../i18n/useI18n'
import { REQUEST_ERROR_RESET } from '../../../reducers/requestSlice'
import { isTranslationKey } from '../../../types/generated/TranslationKey'
import Button from '../../Crow/Button/Button'
import TextField from '../../Crow/Input/TextFieldForForm'
import Modal from '../../Crow/Modal'
import { colors } from '../../Crow/Style/theme'
import { Paragraph as DefaultParagraph } from '../../Crow/Typography'
import { Loader } from '../../Elements/Loaders'

const isNumeric = (str: any) => {
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ) // ...and ensure strings of whitespace fail
}

interface Props {
  codeLength: number
  onCancel: () => void
  onSave: (PanelPinCode: string) => void
  isLoading: boolean
  error: string | undefined
}

// TODO: Two-factor code errors are also shown, and probably should show.

const EditPanelCodeModal = ({ codeLength, onCancel, onSave, isLoading, error }: Props) => {
  const { t } = useI18n()
  const [value, setValue] = useState<string>('')
  const [showCode, setShowCode] = useState<boolean>(false)
  const dispatch = useDispatch()

  const onChange = (e: any) => {
    if (isNumeric(e.target.value) || e.target.value === '') {
      setValue(e.target.value)
    }
    if (error) {
      dispatch(REQUEST_ERROR_RESET())
    }
  }

  useEffect(() => {
    return () => {
      dispatch(REQUEST_ERROR_RESET())
    }
  }, [dispatch])

  const onSubmit = (e: any) => {
    onSave(value)
    e.preventDefault()
  }

  return (
    <Modal>
      <form onSubmit={onSubmit}>
        <Title>{t('people_pin_change')}</Title>
        <CloseButton type="button" onClick={onCancel}>
          <CancelIcon color={colors.blue} />
        </CloseButton>
        <Instructions>{t('people_pin_change_description')}</Instructions>
        <CodeField
          key="change-user-pin"
          id="change-user-pin"
          type={showCode ? 'text' : 'password'}
          autoComplete="off"
          value={value}
          maxLength={codeLength}
          postfix={
            <ShowCodeButton type="button" onClick={() => setShowCode(!showCode)}>
              {showCode ? <HiddenIcon /> : <VisibleIcon />}
            </ShowCodeButton>
          }
          placeholder={t('people_pin_change_hint')}
          onChange={onChange}
          error={error && isTranslationKey(error!) ? t(error) : error}
        />
        <ButtonRow>
          <Button type="button" level="secondary" size="l" onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button type="submit" level="primary" size="l">
            {isLoading ? <Loader /> : t('Save')}
          </Button>
        </ButtonRow>
      </form>
    </Modal>
  )
}

const Title = styled.h1`
  font-family: 'Metric';
  font-weight: 600;
  line-height: 28px;
  font-size: 22px;
  margin: 0.5rem 0;
`

const CloseButton = styled.button`
  position: absolute;
  top: 28px;
  right: 28px;
  font-size: 24px;
  color: ${colors.blue};
`

const Instructions = styled(DefaultParagraph)`
  margin-top: 2rem;
`

const CodeField = styled(TextField)`
  margin-top: 44px;
`

const ShowCodeButton = styled.button`
  color: ${colors.blue};
  font-size: 22px;
`

const ButtonRow = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 3rem;
`

export default EditPanelCodeModal
