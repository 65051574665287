import styled from 'styled-components'
import { HumidityIcon } from '../../../assets/icons'
import { HumidityDevice } from '../../../types/Humidity'
import { colors } from '../../../components/Crow/Style/theme'
import { Footnote } from '../../../components/Crow/Typography'

const HumidityTileFooter = (props: { props: HumidityDevice[] }) => {
  const devices = props.props

  const CountRemainderDevices = () => {
    if (devices.length < 4) {
      return null
    }
    return (
      <IconContainer className="w-6 h-6">
        <Footnote>{`+${devices.length - devices.slice(0, 3).length}`}</Footnote>
      </IconContainer>
    )
  }

  const SortedDevices = () => {
    const sortedDevices = ([] as Array<HumidityDevice>)
      .concat(devices)
      .sort((a, b) => Number(b.LowBattery) - Number(a.LowBattery))
      .slice(0, 3)

    const renderItems = (property?: keyof HumidityDevice) => {
      if (property === 'LowBattery') {
        return sortedDevices
          .filter((device) => device.LowBattery)
          .map((device) => (
            <IconContainer
              key={device.Id}
              role="listitem"
              className="w-6 h-6"
              color={colors.yellow}
            >
              <HumidityIcon width="14px" />
            </IconContainer>
          ))
      } else {
        return sortedDevices
          .filter((device) => !device.LowBattery)
          .map((device) => (
            <IconContainer key={device.Id} role="listitem" className="w-6 h-6">
              <HumidityIcon width="14px" />
            </IconContainer>
          ))
      }
    }

    return (
      <SortedDevicesUl role="list" aria-label="devices">
        {renderItems('LowBattery')}
        {renderItems()}
        <CountRemainderDevices />
      </SortedDevicesUl>
    )
  }

  return (
    <TileFooterContainer>
      <SortedDevices />
    </TileFooterContainer>
  )
}

const SortedDevicesUl = styled.ul`
  margin-top: 2px;
  display: flex;
  padding-left: 0;
  gap: 12px;
`

const IconContainer = styled.li<{ color?: any; border?: string }>`
  margin-top: 0;
  font-size: 16px;
  border-radius: 8px;
  background-color: ${({ color }) => (color ? colors.lightYellow : colors.black100)};
  border: ${({ border }) => (border ? border : border)};
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-child {
    margin-left: 0;
  }
  padding: 5.5px;
`

const TileFooterContainer = styled.div`
  display: flex;
  align-items: center;
`

export default HumidityTileFooter
