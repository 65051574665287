import * as React from 'react'

const SmileyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const fill: string = props.fill || '#4d4A4A'

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.53564 8.49833C3.64266 9.24235 3.98736 9.9371 4.52513 10.4749C5.1815 11.1313 6.07174 11.5 7 11.5C7.92826 11.5 8.8185 11.1313 9.47487 10.4749C10.0126 9.9371 10.3573 9.24235 10.4644 8.49833C10.5037 8.225 10.2761 8 10 8C9.72386 8 9.50504 8.22605 9.45017 8.49669C9.35327 8.97468 9.11777 9.41777 8.76777 9.76777C8.29893 10.2366 7.66304 10.5 7 10.5C6.33696 10.5 5.70107 10.2366 5.23223 9.76777C4.88223 9.41777 4.64673 8.97468 4.54983 8.49669C4.49496 8.22605 4.27614 8 4 8C3.72386 8 3.49633 8.225 3.53564 8.49833Z"
        fill={fill}
      />
      <path
        d="M10 5.75C10 6.16421 9.66421 6.5 9.25 6.5C8.83579 6.5 8.5 6.16421 8.5 5.75C8.5 5.33579 8.83579 5 9.25 5C9.66421 5 10 5.33579 10 5.75Z"
        fill={fill}
      />
      <path
        d="M4.75 6.5C5.16421 6.5 5.5 6.16421 5.5 5.75C5.5 5.33579 5.16421 5 4.75 5C4.33579 5 4 5.33579 4 5.75C4 6.16421 4.33579 6.5 4.75 6.5Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
        fill={fill}
      />
    </svg>
  )
}

export { SmileyIcon }
