import { createSlice } from '@reduxjs/toolkit'
import { PanelAction, PanelPayloadAction } from '../services/requestHooks/PanelAction'
import { AppUser } from '../types/AppUser'

type AppUsersState = {
  [panelId: string]: {
    users: AppUser[]
  }
}

const initialState: AppUsersState = {}

const appUserSlice = createSlice({
  name: 'appUsers',
  initialState,
  reducers: {
    GET_APP_USERS(state, { payload: { panelId, data } }: PanelPayloadAction<AppUser[]>) {
      state[panelId] = { users: data }
    },
    INVITE_APP_USER(state, action: PanelAction) {},
    UNINVITE_APP_USER(state, action: PanelAction) {},
    RESEND_INVITE_APP_USER(state, action: PanelAction) {},
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  GET_APP_USERS,
  INVITE_APP_USER,
  UNINVITE_APP_USER,
  RESEND_INVITE_APP_USER,
} = appUserSlice.actions

export default appUserSlice.reducer
