import { FC, Fragment, useState } from 'react'
import styled from 'styled-components'
import { CancelIcon } from '../../../assets/icons/crow'
import BackButton from '../../../components/BackButton'
import Drawer from '../../../components/Crow/Drawer/Drawer'
import { colors } from '../../../components/Crow/Style/theme'
import Tile from '../../../components/Crow/Tile/Tile'
import { Heading3, Title } from '../../../components/Crow/Typography'
import FullscreenPage from '../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import { displayNewNavigationMenu } from '../../../configs'
import useI18n from '../../../i18n/useI18n'
import { useDoorsAndWindows } from '../../../services/requestHooks/doorsandwindows'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { DevicesType, Floor } from '../../../types/DoorsAndWindows'
import { useOnMount } from '../../../utils/commonHooks'
import { logBackEvent } from '../../../utils/firebaseAnalytics'
import FloorName from '../../../utils/places/FloorName'
import RoomName from '../../../utils/places/RoomName'
import { RoomSwitchIcon } from '../../../utils/places/RoomSwitchIcon'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import DoorsAndWindowDetails from './DoorsAndWindowsDetails'
import { FooterDevices, RoomNameTitle, TileContainer } from './DoorsAndWindowsStyle'
import DoorsAndWindowsTileFooter from './DoorsAndWindowsTileFooter'

const CrowDoorAndWindowPage: FC = () => {
  const panelId = usePanelId()
  const { t } = useI18n()
  const returnUrl = `/systems/${panelId}`

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
    error: panelStatusError,
  } = usePanelStatus()

  const {
    run: getDoorsAndWindows,
    isLoading: loadingDoorsAndWindows,
    error: doorsAndWindowsError,
    data: doorsAndWindows,
  } = useDoorsAndWindows()

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadInfoError,
  } = usePanelInfo()

  const hasError = !!doorsAndWindowsError || !!panelStatusError || !!loadInfoError

  useOnMount(() => {
    if (!loadingPanelInfo) {
      loadPanelInfo(panelId)
    }
    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
    if (!loadingDoorsAndWindows) {
      getDoorsAndWindows(panelId)
    }
  })

  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false)
  const [doorsAndWindowsState, setDoorsAndWindowsState] = useState<Floor>()

  const openDrawer = (
    floors: Floor,
    roomName: string,
    roomKey: string,
    roomIndex: number | undefined,
    roomDevices: DevicesType[],
  ) => () => {
    setDoorsAndWindowsState({
      Name: floors?.Name!,
      Key: floors?.Key!,
      Rooms: [{ Name: roomName, Key: roomKey, Index: roomIndex, Devices: roomDevices }],
    })
    setDrawerIsOpen(true)
  }

  const closeDrawer = () => {
    setDrawerIsOpen(false)
  }

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen footer>
      <FullscreenPage
        isLoading={loadingDoorsAndWindows || loadingPanelStatus}
        loaderProps={{
          loadingText: { text: t('Loading') },
          errorHandling: {
            loadingFailed: hasError,
            errorHeaderText: t('Door&Win'),
            errorText: !panelStatus?.IsOnline
              ? 'Something went wrong. Please try again later.'
              : 'Something went wrong. Please try again',
          },
        }}
      >
        {!displayNewNavigationMenu && (
          <BackButton backUrl={returnUrl} onClick={() => logBackEvent('doors_windows')} />
        )}
        <Title large>{t('Door&Win')}</Title>
        <Description>{t('DOOR_WINDOWS_DESCRIPTION')}</Description>
        <>
          <div>
            {doorsAndWindows?.Floors.map((floor) => (
              <SectionWrapper className={`qa-${floor.Key}`} key={floor.Key}>
                <SectionTitle>
                  <FloorName floorName={floor.Key} />
                </SectionTitle>
                <TileContainer>
                  {floor!.Rooms!.map((room) => (
                    <div
                      className={`qa-${room.Name}-${room.Index}`}
                      key={`${room.Name}-${room.Index}`}
                    >
                      <Tile
                        onClick={openDrawer(
                          floor,
                          room.Name,
                          room.Key!,
                          room.Index!,
                          room.Devices!,
                        )}
                      >
                        <TileTop>
                          <RoomSwitchIcon roomKey={room.Key} />
                        </TileTop>
                        <TileCenter>
                          <RoomNameTitle>
                            <RoomName roomName={room.Key} />
                            {room.Index && room.Index > 0 ? <span>&nbsp;{room.Index}</span> : null}
                          </RoomNameTitle>
                        </TileCenter>
                        <TileBottom>
                          <FooterDevices>{t('Sensors')}</FooterDevices>
                          <DoorsAndWindowsTileFooter props={room.Devices} />
                        </TileBottom>
                      </Tile>
                    </div>
                  ))}
                </TileContainer>
              </SectionWrapper>
            ))}
          </div>

          <Drawer
            isOpen={drawerIsOpen}
            position="right"
            handleClose={closeDrawer}
            closeButton={true}
            closeIcon={<CancelIcon color={colors.blue} />}
            title={
              <Header>
                {doorsAndWindowsState?.Rooms.map((room) => (
                  <Fragment key={room.Name}>
                    <span className="mr-auto">
                      <RoomName roomName={room.Key} />{' '}
                      {room.Index && room.Index > 0 ? <span>{room.Index}&nbsp;</span> : null}(
                      {<FloorName floorName={doorsAndWindowsState.Key} />})
                    </span>
                    <IconWrapper>
                      <RoomSwitchIcon roomKey={room.Key} />
                    </IconWrapper>
                  </Fragment>
                ))}
              </Header>
            }
          >
            <DoorsAndWindowDetails panelStatus={panelStatus} floors={doorsAndWindowsState!} />
          </Drawer>
        </>
      </FullscreenPage>
    </TcOrCrowContainer>
  )
}

const Description = styled.p`
  margin: 16px 0 42px 0;
`

const SectionWrapper = styled.div`
  margin-top: 24px;

  &:first-child {
    margin-top: 42px;
  }
`

const SectionTitle = styled(Heading3)`
  margin-left: 8px;
  color: ${colors.black700};
`

const TileTop = styled.div`
  margin-top: -6px;
`

const TileCenter = styled.div``

const TileBottom = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  padding-bottom: 50px;
  display: flex;
`

const IconWrapper = styled.span`
  position: relative;
  bottom: 15px;
`

export default CrowDoorAndWindowPage
