import React, { useState } from 'react'
import useI18n from '../../../i18n/useI18n'
import {
  useDeleteCustomerFeedback,
  useGetCustomerFeedbackStatus,
} from '../../../services/requestHooks/customerFeedback'
import { useUserInfo } from '../../../services/requestHooks/user'
import { useOnMount } from '../../../utils/commonHooks'
import Button from '../../Crow/Button/Button'
import Toast, { addToast } from '../../Crow/Toast/Toast'
import FullscreenPage from '../../pages/FullscreenPage'
import CustomerFeedbackForm from './CustomerFeedbackForm'

const TemporaryFeedbackFormPage = () => {
  const user = useUserInfo()
  const { t } = useI18n()
  const [openFeed, setOpenFeedForm] = useState(false)
  const {
    run: getFeedbackStatus,
    // isLoading: isLoadingFeedbackStatus,
    data: feedbackStatus,
  } = useGetCustomerFeedbackStatus()

  const { run: delFeed } = useDeleteCustomerFeedback()

  useOnMount(() => {
    getFeedbackStatus({ personId: user.Id })
  })

  return (
    <FullscreenPage>
      <CustomerFeedbackForm
        open={openFeed}
        onClose={() => setOpenFeedForm(false)}
        onSuccess={() =>
          addToast({
            type: 'success',
            time: 5000,
            title: t('toast_generic_success_title'),
            content: t('feedback_thank_you'),
          })
        }
      />
      <Button onClick={() => setOpenFeedForm(true)} level={'primary'}>
        {t('app_feedback_subtitle')}
      </Button>
      {!feedbackStatus?.feedbackStatus?.CanAskForFeedback && (
        <Button level="secondary" onClick={() => delFeed({ personId: user.Id })}>
          Delete feedback
        </Button>
      )}
      <Toast />
    </FullscreenPage>
  )
}

export default TemporaryFeedbackFormPage
