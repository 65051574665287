const EntranceIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="11" width="14" height="31" fill="#E3E1E1" />
      <path
        d="M15 25C15 24.4477 14.5523 24 14 24C13.4477 24 13 24.4477 13 25L13 28C13 28.5523 13.4477 29 14 29C14.5523 29 15 28.5523 15 28L15 25Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 34H43C44.1046 34 45 34.8954 45 36V45C45 45.5523 44.5523 46 44 46C43.4477 46 43 45.5523 43 45V43H5L5 45C5 45.5523 4.55229 46 4 46C3.44772 46 3 45.5523 3 45V12C3 10.8954 3.89543 10 5 10H17C18.1046 10 19 10.8954 19 12V34ZM5 12H17V41H5V12ZM19 41V36H30V41H19ZM32 41V36H43V41H32Z"
        fill="#333131"
      />
      <path
        d="M28.2929 19.2929C27.9024 19.6834 27.9024 20.3166 28.2929 20.7071C28.6834 21.0976 29.3166 21.0976 29.7071 20.7071L33.7071 16.7071C34.0977 16.3166 34.0977 15.6834 33.7071 15.2929C33.3166 14.9024 32.6834 14.9024 32.2929 15.2929L28.2929 19.2929Z"
        fill="#333131"
      />
      <path
        d="M28.2929 23.2929L32.2929 19.2929C32.6834 18.9024 33.3166 18.9024 33.7071 19.2929C34.0977 19.6834 34.0977 20.3166 33.7071 20.7071L29.7071 24.7071C29.3166 25.0976 28.6834 25.0976 28.2929 24.7071C27.9024 24.3166 27.9024 23.6834 28.2929 23.2929Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 15C25 13.3431 26.3431 12 28 12H34C35.6569 12 37 13.3431 37 15V27C37 28.6569 35.6569 30 34 30H28C26.3431 30 25 28.6569 25 27V15ZM28 14H34C34.5523 14 35 14.4477 35 15V27C35 27.5523 34.5523 28 34 28H28C27.4477 28 27 27.5523 27 27V15C27 14.4477 27.4477 14 28 14Z"
        fill="#333131"
      />
    </svg>
  )
}

export { EntranceIcon }
