const GaragePlaceIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 31H13V37H35V31ZM17 34C17 34.5523 16.5523 35 16 35C15.4477 35 15 34.5523 15 34C15 33.4477 15.4477 33 16 33C16.5523 33 17 33.4477 17 34ZM32 35C32.5523 35 33 34.5523 33 34C33 33.4477 32.5523 33 32 33C31.4477 33 31 33.4477 31 34C31 34.5523 31.4477 35 32 35Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43 6C43 5.44772 42.5523 5 42 5H6C5.44772 5 5 5.44772 5 6V43C5 43.5523 5.44771 44 6 44C6.55228 44 7 43.5523 7 43L7 15H41V43C41 43.5523 41.4477 44 42 44C42.5523 44 43 43.5523 43 43V6ZM7 9V7H41V9H7ZM7 13L7 11H41V13H7Z"
        fill="#333131"
      />
      <path
        d="M17 34C17 34.5523 16.5523 35 16 35C15.4477 35 15 34.5523 15 34C15 33.4477 15.4477 33 16 33C16.5523 33 17 33.4477 17 34Z"
        fill="#333131"
      />
      <path
        d="M32 35C32.5523 35 33 34.5523 33 34C33 33.4477 32.5523 33 32 33C31.4477 33 31 33.4477 31 34C31 34.5523 31.4477 35 32 35Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.65 20H31.354C31.7418 20.0016 32.1208 20.1158 32.4448 20.3289C32.7677 20.5412 33.0221 20.8425 33.1774 21.1963L33.179 21.2L36.6496 29H38C38.5523 29 39 29.4477 39 30C39 30.5523 38.5523 31 38 31H37V41C37 41.5304 36.7893 42.0391 36.4142 42.4142C36.0391 42.7893 35.5304 43 35 43H32C31.4696 43 30.9609 42.7893 30.5858 42.4142C30.2107 42.0391 30 41.5304 30 41V39H18V41C18 41.5304 17.7893 42.0391 17.4142 42.4142C17.0391 42.7893 16.5304 43 16 43H13C12.4696 43 11.9609 42.7893 11.5858 42.4142C11.2107 42.0391 11 41.5304 11 41V31H10C9.44771 31 9 30.5523 9 30C9 29.4477 9.44771 29 10 29H11.3504L14.8227 21.1962C14.9779 20.8424 15.2324 20.5412 15.5552 20.3289C15.8792 20.1158 16.2582 20.0016 16.646 20L16.65 20ZM31.346 22L34.4605 29H13.5395L16.6512 22.0065L16.654 22L31.346 22ZM13 37V31H35V37H13ZM32 41V39H35V41H32ZM13 39H16V41H13V39Z"
        fill="#333131"
      />
    </svg>
  )
}

export { GaragePlaceIcon }
