import classNames from 'classnames'
import { brand } from '../configs'
import useI18n from '../i18n/useI18n'
import TermsLink from './TermsLink'

const Footer = () => {
  const { t } = useI18n()

  return (
    <footer className={classNames('footer')}>
      {new Date().getFullYear()} ©{brand === 'phonewatch' ? 'Phonewatch ' : 'Sector Alarm '}|{' '}
      <TermsLink>{t('User terms and cookies')}</TermsLink>
    </footer>
  )
}

export default Footer
