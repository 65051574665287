import { FC, useCallback, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { CrowLoader } from '../../../../components/Crow/Loader/CrowLoader'
import FormButtons from '../../../../components/forms/FormButtons'
import PageHeader from '../../../../components/PageHeader'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n, { useIrishCheck } from '../../../../i18n/useI18n'
import { useUpdateContacts } from '../../../../reducers/contactsSlice'
import { useContacts, useSetContacts } from '../../../../services/requestHooks/contacts'
import { usePanelInfo } from '../../../../services/requestHooks/panelInfo'
import { ContactPerson } from '../../../../types/ContactPerson'
import { Capability } from '../../../../types/PanelModel'
import { useOnMount } from '../../../../utils/commonHooks'
import { usePanelId } from '../../hooks/usePanelId'
import PasswordPrompt, { usePasswordPrompt } from '../../Panel/PasswordPromt'
import SettingsSubPage from '../components/SettingsSubPage'
import Ice from './components/Ice'
import Neighbors from './components/Neighbors'
import Residents from './components/Residents'

export const updatePerson = (
  contacts: ContactPerson[] | undefined,
  update: (contacts: ContactPerson[]) => void,
) => (updatedPerson: ContactPerson, originalPerson?: ContactPerson) => {
  if (contacts) {
    let updatedContacts: ContactPerson[]
    if (originalPerson) {
      updatedContacts = contacts.map((person) =>
        person === originalPerson ? updatedPerson : person,
      )
    } else {
      updatedContacts = [...contacts, updatedPerson]
    }
    update(updatedContacts)
  }
}

export const changePriority = (
  contacts: ContactPerson[] | undefined,
  update: (contacts: ContactPerson[]) => void,
) => (chosenPerson: ContactPerson, priority: 'up' | 'down') => {
  if (contacts) {
    const chosenIndex = contacts.indexOf(chosenPerson)
    const slicedList =
      priority === 'up' ? contacts.slice(0, chosenIndex).reverse() : contacts.slice(chosenIndex + 1)
    const closestPerson = slicedList.find((person) => person.Type === chosenPerson.Type)
    if (closestPerson) {
      const closestIndex = contacts.indexOf(closestPerson)
      const updatedContacts = contacts.map((person, index) => {
        if (index === closestIndex) {
          return chosenPerson
        }
        if (index === chosenIndex) {
          return closestPerson
        }
        return person
      })
      update(updatedContacts)
    }
  }
}

export const deletePerson = (
  contacts: ContactPerson[] | undefined,
  update: (contacts: ContactPerson[]) => void,
) => (chosenPerson: ContactPerson) => {
  if (contacts) {
    const updatedContacts = contacts.filter((person) => person !== chosenPerson)
    update(updatedContacts)
  }
}

export const ContactsHeader: FC = () => {
  const { checkIrish } = useIrishCheck()

  return (
    <PageHeader
      title={checkIrish('Contact persons', 'PW_CONTACT_PERSONS')}
      text={checkIrish(
        'In case of emergency, we will contact the people on this list in top-to-bottom order.',
        'PW_CP_INFO_IMPORTANT',
      )}
    />
  )
}

const ContactsPage: FC = () => {
  const history = useHistory()
  const panelId = usePanelId()
  const { t } = useI18n()
  const { data: panelInfo, isLoading: isLoadingPanelInfo } = usePanelInfo()
  const { run, data: contacts, isLoading } = useContacts()
  const updateContacts = useUpdateContacts()

  const returnUrl =
    !panelInfo ||
    panelInfo?.Capabilities.includes(Capability.USE_LEGACY_PEOPLE) ||
    panelInfo?.Capabilities.includes(Capability.USE_LEGACY_HOMESCREEN || isLoading)
      ? `/systems/${panelId}/settings`
      : `/systems/${panelId}/people`

  const onSuccess = useCallback(() => {
    history.push(returnUrl)
  }, [history, returnUrl])

  const { run: setContactPersons, isLoading: isSettingContacts } = useSetContacts({ onSuccess })
  const { promptForTwoFactor } = useContext(TwofactorContext)
  const { promptForPassword, promptState } = usePasswordPrompt()

  useOnMount(() => {
    const onLoad = async () => {
      const ValidationCode = await promptForTwoFactor(returnUrl)
      if (!ValidationCode) return
      run({ PanelId: panelId, ValidationCode })
    }
    onLoad()
  })

  const saveChanges = async () => {
    if (contacts) {
      const ValidationCode = await promptForTwoFactor()
      if (ValidationCode) {
        const Password = await promptForPassword()
        if (Password) {
          setContactPersons({
            ContactPersons: contacts,
            PanelId: panelId,
            ValidationCode,
            Password,
          })
        }
      }
    }
  }

  const actions = useMemo(
    () => ({
      updatePerson: updatePerson(contacts, updateContacts),
      changePriority: changePriority(contacts, updateContacts),
      deletePerson: deletePerson(contacts, updateContacts),
    }),
    [contacts, updateContacts],
  )

  return (
    <SettingsSubPage
      isLoading={isLoadingPanelInfo || (!contacts && isLoading)}
      returnUrl={returnUrl}
    >
      <ContactsHeader />
      {isLoading ? (
        <LoaderContainer>
          <CrowLoader type="large" text={t('Loading contact persons')} />
        </LoaderContainer>
      ) : (
        <>
          <Residents contacts={contacts} actions={actions} />
          <Neighbors contacts={contacts} actions={actions} />
          <Ice contacts={contacts} actions={actions} />
          <div className="mt-12">
            <FormButtons
              onClick={saveChanges}
              isLoading={isSettingContacts}
              submitText="Save contact persons"
              cancelUrl={returnUrl}
            />
          </div>
        </>
      )}
      <PasswordPrompt promptState={promptState} />
    </SettingsSubPage>
  )
}

const LoaderContainer = styled.div`
  padding-top: 35px;
`

export default ContactsPage
