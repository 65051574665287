import { GET_HUMIDITY } from '../../reducers/humiditySlice'
import { RootState } from '../../store'
import { housecheckHumidity } from '../apiHouseCheck'
import { createUseRequest } from './createUseRequest'

const selectHumidity = (state: RootState, panelId: string) => state.humidity[panelId]?.humidity

export const useHumidity = createUseRequest({
  successActionCreator: GET_HUMIDITY,
  apiCall: housecheckHumidity,
  selector: selectHumidity,
  requirePanelId: true,
})
