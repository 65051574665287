import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LeakageDetectors } from '../types/LeakageDetectors'

type LeakageDetectorsState = Record<string, { leakageDetectors?: LeakageDetectors } | undefined>

const initialState: LeakageDetectorsState = {}

type UpdateLeakageDetectorsPayload = LeakageDetectors

const leakageDetectorsSlice = createSlice({
  name: 'leakageDetectors',
  initialState,
  reducers: {
    UPDATE_LEAKAGEDETECTORS(
      state,
      action: PayloadAction<{ data: UpdateLeakageDetectorsPayload; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = { leakageDetectors: data }
    },
  },
})

export const UPDATE_LEAKAGEDETECTORS = leakageDetectorsSlice.actions.UPDATE_LEAKAGEDETECTORS

export const leakageDetectorsReducer = leakageDetectorsSlice.reducer
