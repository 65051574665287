import { FC } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styled, { createGlobalStyle } from 'styled-components'
import { ChevronLeftIcon, ChevronRightIcon, TemperatureIcon } from '../../../assets/icons/crow'
import { colors } from '../../../components/Crow/Style/theme'
import { Title } from '../../../components/Crow/Typography'
import useI18n from '../../../i18n/useI18n'
import { TemperatureAverage } from '../../../types/Temperature'
import FloorName from '../../../utils/places/FloorName'
import RoomName from '../../../utils/places/RoomName'
import CrowCard from '../CrowCard/CrowCard'

type Props = {
  size: string
  link: string
  data?: TemperatureAverage
  propertyName: string
}

export const Carousel: FC<Props> = ({ size, link, data, propertyName }) => {
  const { t } = useI18n()
  const NextCustomArrow = (props: any) => {
    const { className, style, onClick } = props
    return (
      <button
        className={className}
        onClick={onClick}
        style={{
          ...style,
        }}
      >
        <ChevronContainer>
          <ChevronRightIcon color={colors.black900} />
        </ChevronContainer>
      </button>
    )
  }
  const PrevCustomArrow = (props: any) => {
    const { className, style, onClick } = props
    return (
      <button
        className={className}
        onClick={onClick}
        style={{
          ...style,
        }}
      >
        <ChevronContainer>
          <ChevronLeftIcon color={colors.black900} />
        </ChevronContainer>
      </button>
    )
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextCustomArrow />,
    prevArrow: <PrevCustomArrow />,
  }

  return (
    <LiveAppContainer>
      <CrowCard border size={size} className={`d flex flex-col`}>
        <SliderStyle />
        <Slider {...settings}>
          {data?.Averages?.map((average, index) => (
            <StyledLink to={link} key={index}>
              <LiveAppList>
                <div role="list" aria-label="tile-content">
                  <LiveAppHeader>
                    <AverageTemperature>
                      {Math.round(average.AverageTemperature!)}°
                    </AverageTemperature>
                    <TemperatureIcon />
                  </LiveAppHeader>
                  {propertyName === 'sections' ? (
                    <>
                      <RoomNameTitle className="mr-auto">
                        <FloorName floorName={average.Key!} />
                      </RoomNameTitle>
                      <LiveAppFooterText>{t('average_room_temperature')}</LiveAppFooterText>
                    </>
                  ) : propertyName === 'places' ? (
                    <>
                      <RoomNameTitle className="mr-auto">
                        <RoomName roomName={average.Key!} />
                      </RoomNameTitle>
                      <LiveAppFooterText>{t('average_floor_temperature')}</LiveAppFooterText>
                    </>
                  ) : (
                    <>
                      <LiveAppFooterText pb={40}>{t(average.Key!)}</LiveAppFooterText>
                      <LiveAppFooter>{t('Temperature')}</LiveAppFooter>
                    </>
                  )}
                </div>
              </LiveAppList>
            </StyledLink>
          ))}
        </Slider>
      </CrowCard>
    </LiveAppContainer>
  )
}

const ChevronContainer = styled.div`
  font-size: 1.2rem;
  margin-top: 5px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  position: relative;
`

const LiveAppContainer = styled.div`
  width: 150px;
  margin-bottom: 24px;
`
const LiveAppList = styled.div`
  padding: 10px 10px 0px;
`
const LiveAppHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
`

const LiveAppFooter = styled.div`
  background: ${colors.lightGray};
  height: 24px;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  left: 0px;
  right: 0px;
  padding: 5px;
`

const LiveAppFooterText = styled.p<{ pb?: string | number }>`
  font-family: 'Metric';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: ${({ pb }) => pb + 'px'};
  inline-size: 120px;
  overflow-wrap: break-word;
`

const AverageTemperature = styled.p`
  font-size: 50px;
  line-height: 48px;
  font-weight: bold;
`
const RoomNameTitle = styled(Title)`
  font-size: 22px;
  line-height: 22px;
  max-width: 138px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-bottom: 10px;
`

const SliderStyle = createGlobalStyle`

  .slick-dots {
    bottom:-23px!important
  }
  .slick-dots li button:before {
    width: 2px!important;
  }
  .slick-dots li {
    margin: 0 2px!important;
    width:4px!important;
  }
 
  .slick-prev {
    font-size: 15px !important;
    left: -20px;
    transform:  translateX(-55px);
    z-index: 2;
  }
  .slick-next {
    font-size: 15px !important;
    right: -20px;
    transform:  translateX(55px);
    z-index: 1;
  }
   .slick-prev:before, .slick-next:before  {
    content: '' !important;
  }
  .slick-arrow {
    position: absolute;
    top: 100%;
    margin-left: 73px;
    margin-right: 73px;
    position: absolute;
  } 
`
