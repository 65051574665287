import { useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Collapsible } from '../../../../components/Crow/Collapsible/Collapsible'
import { ErrorMessage } from '../../../../components/Crow/ErrorMessage/ErrorMessage'
import { colors } from '../../../../components/Crow/Style/theme'
import { Paragraph } from '../../../../components/Crow/Typography'
import { Loader } from '../../../../components/Elements/Loaders'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { REQUEST_ERROR_RESET } from '../../../../reducers/requestSlice'
import { usePanelInfo, usePanelStatus } from '../../../../services/requestHooks/panelInfo'
import { useWifiList } from '../../../../services/requestHooks/wifi'
import { Capability, PanelStatus } from '../../../../types/PanelModel'
import { WifiDetails } from '../../../../types/WifiDetails'
import { useOnMount } from '../../../../utils/commonHooks'
import { logFirebaseEvent } from '../../../../utils/firebaseAnalytics'
import { usePanelId } from '../../hooks/usePanelId'
import { AnimatedContainer } from './AnimatedContainer'
import ConnectWifiModal, { useConfirmConnect } from './ConnectWifiModal'
import { CrowWifiList } from './CrowWifiList'
import DisconnectWifiModal, { useConfirmDisconnect } from './DisconnectWifiModal'
import { MenuItem } from './MenuItem'
import { LoaderContainer } from './StyledWifiComponents'
import { WifiConnectForm } from './WifiConnectForm'
import { WifiItem, WifiItemTextContainer } from './WifiItem'

export const CrowWifiSection = () => {
  const history = useHistory()
  const panelId = usePanelId()
  const { t } = useI18n()
  const { promptForTwoFactor } = useContext(TwofactorContext)
  const { modalState: connectState, promptForConnect } = useConfirmConnect()
  const { modalState: disconnectState, promptForDisconnect } = useConfirmDisconnect()

  const [openConnectForm, setOpenConnectForm] = useState(false)
  const [openScanList, setOpenScanList] = useState(false)

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
  } = usePanelStatus()

  const {
    run: getWifiList,
    data: wifiList = [],
    isLoading: loadingWifiList,
    error: loadWifiError,
  } = useWifiList()

  const { run: getPanelInfo, data: panelInfo, isLoading: loadingPanelInfo } = usePanelInfo({
    onSuccess: async ({ response: { Wifi } }) => {
      if (Wifi?.WifiExist) {
        const TwoFactorPin = await promptForTwoFactor(returnUrl)
        if (TwoFactorPin) {
          getWifiList({
            panelId,
            TwoFactorPin,
            ValidationCode: TwoFactorPin,
            WiFiCardSerial: Wifi.Serial,
          })
        }
      } else {
        history.replace(returnUrl)
      }
    },
  })

  const returnUrl = useMemo(() => `/systems/${panelId}/settings`, [panelId])

  const loadWifiList = useCallback(async () => {
    if (panelInfo && !!panelInfo.Wifi) {
      const TwoFactorPin = await promptForTwoFactor(returnUrl)
      if (TwoFactorPin) {
        getWifiList({
          panelId,
          ValidationCode: TwoFactorPin,
          TwoFactorPin,
          WiFiCardSerial: panelInfo.Wifi.Serial,
        })
      }
    }
  }, [getWifiList, panelId, panelInfo, promptForTwoFactor, returnUrl])

  const onConnectClick = async (wifi: WifiDetails) => {
    const connectedToWifi = await promptForConnect(wifi)
    if (connectedToWifi) {
      loadWifiList()
    }
  }

  const onDisconnectClick = async (wifi: WifiDetails) => {
    const disconnected = await promptForDisconnect(wifi)
    if (disconnected) {
      loadWifiList()
    }
  }

  const connectedWifis = wifiList.filter((wifi) => wifi.Connected.toLowerCase() === 'true')
  const otherWifis = wifiList.filter(
    (wifi) => wifi.Connected.toLowerCase() === 'false' && wifi.SsidHidden !== '1',
  )

  // const requiresSSid = !panelInfo?.Capabilities.includes(Capability.CAN_SCAN_FOR_WIFI)
  const isPanelArmed = panelStatus && panelStatus?.Status !== PanelStatus.TURNED_OFF

  useOnMount(() => {
    logFirebaseEvent('settings_wifi_viewed')

    if (connectedWifis.length > 0) {
      logFirebaseEvent('settings_wifi_has_wifi')
    } else {
      logFirebaseEvent('settings_wifi_no_wifi')
    }

    if (!loadingPanelInfo && panelId !== panelInfo?.PanelId) {
      getPanelInfo(panelId)
      loadPanelStatus({ panelId })
    } else {
      loadWifiList()
    }
  })

  const dispatch = useDispatch()

  const excludeErrorCase =
    loadWifiError &&
    loadWifiError.ReasonPhrase === null &&
    loadWifiError.Error === null &&
    loadWifiError.IsSuccessStatusCode === false

  const errorKey = (errKey: string) => {
    logFirebaseEvent('settings_wifi_failure')
    if (errKey === 'wrong_pincode') {
      return 'otp_expired'
    }
    return 'wifi_loading_error'
  }

  return (
    <>
      <AnimatedContainer>
        &nbsp;
        {!openConnectForm && !openScanList && (
          <Collapsible title={t('WIFI_SETTINGS')} defaultOpen={true}>
            {loadingWifiList ? (
              <LoaderContainer>
                <Loader />
                <Paragraph>{t('loading_wifi')}</Paragraph>
              </LoaderContainer>
            ) : (
              <>
                {connectedWifis.map((wifi, index) => (
                  <WifiItem
                    disabled={isPanelArmed}
                    key={wifi.Name}
                    {...wifi}
                    onClick={() => onDisconnectClick(wifi)}
                    wifiItem={wifi}
                  />
                ))}
              </>
            )}

            {loadWifiError && !excludeErrorCase && (
              <ErrorMessage text={t(errorKey(loadWifiError))} errorLocation="CrowWifiSection" />
            )}

            <WifiSettingsSection>
              <Paragraph grey>{t('change_connection')}</Paragraph>

              {panelInfo && !!panelInfo.Capabilities?.includes(Capability.USE_LEGACY_HOMESCREEN) && (
                <MenuItem
                  disabled={otherWifis.length === 0 || isPanelArmed}
                  onClick={() => otherWifis.length > 0 && setOpenScanList(true)}
                >
                  <WifiItemTextContainer>
                    <Paragraph>{t('scan_for_networks')}</Paragraph>
                  </WifiItemTextContainer>
                </MenuItem>
              )}

              {!loadingPanelStatus && (
                <MenuItem
                  onClick={() => {
                    logFirebaseEvent('settings_wifi_connect_view_form')
                    dispatch(REQUEST_ERROR_RESET())
                    setOpenConnectForm(true)
                  }}
                  disabled={isPanelArmed}
                >
                  <WifiItemTextContainer>
                    <Paragraph>{t('add_network_manually')}</Paragraph>
                  </WifiItemTextContainer>
                </MenuItem>
              )}
            </WifiSettingsSection>
            {isPanelArmed && <WifiArmedText>{t('panel_disarmed_wifi')}</WifiArmedText>}
          </Collapsible>
        )}
        {openScanList && otherWifis.length > 0 && (
          <CrowWifiList
            wifiList={wifiList}
            loading={loadingWifiList}
            onClick={(wifiItem: WifiDetails) => {
              if (wifiItem.Connected === 'True' || wifiItem.Connected === 'true') {
                onDisconnectClick(wifiItem)
              } else {
                dispatch(REQUEST_ERROR_RESET())
                onConnectClick(wifiItem)
              }
            }}
            onCancel={() => setOpenScanList(false)}
          />
        )}
        {openConnectForm && (
          <WifiConnectForm
            WiFiCardSerial={panelInfo?.Wifi?.Serial}
            loadWifiList={() => loadWifiList()}
            onCancel={() => setOpenConnectForm(false)}
          />
        )}
      </AnimatedContainer>
      <DisconnectWifiModal {...disconnectState} WiFiCardSerial={panelInfo?.Wifi?.Serial} />
      <ConnectWifiModal {...connectState} WiFiCardSerial={panelInfo?.Wifi?.Serial} />
    </>
  )
}

const WifiSettingsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  & > * {
    margin-bottom: 8px;
  }
`

const WifiArmedText = styled(Paragraph)`
  color: ${colors.blue};
  margin: 10px 10px 0 5px;
`
