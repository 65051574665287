import styled from 'styled-components'
import { Paragraph } from '../Typography'

interface CrowCheckboxProps {
  text?: string
  checked: boolean
  onChange: () => void
}

export const CrowCheckbox = ({ text, checked, onChange }: CrowCheckboxProps) => (
  <CheckboxContainer>
    <StyledCheckbox checked={checked} onChange={onChange} type="checkbox" />
    {text && <StyledParagraph onClick={onChange}>{text}</StyledParagraph>}
  </CheckboxContainer>
)

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledCheckbox = styled.input`
  width: 16px;
  margin: 0;
  padding: 0;
  cursor: pointer;
`

const StyledParagraph = styled(Paragraph)`
  margin-left: 5px;
  cursor: pointer;
`
