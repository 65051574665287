import { TOptions } from 'i18next'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserInfo } from '../services/requestHooks/user'
import { TranslationKey } from '../types/generated/TranslationKey'

const useI18n = () => {
  const { t: tfunction, i18n } = useTranslation()

  const t = useCallback(
    (key: TranslationKey, options?: TOptions | string) => tfunction(key, options),
    [tfunction],
  )
  return { t, i18n }
}

export const useIrishCheck = () => {
  const User = useUserInfo()

  const checkIrish = useCallback(
    (key: TranslationKey, irishKey: TranslationKey) => (User.NationId === 3 ? irishKey : key),
    [User.NationId],
  )

  return { checkIrish }
}

export default useI18n
