const WaitingRoomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39 9C39 8.44772 38.5523 8 38 8C37.4477 8 37 8.44772 37 9V11C37 11.5523 37.4477 12 38 12H40C40.5523 12 41 11.5523 41 11C41 10.4477 40.5523 10 40 10H39V9Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38 17C41.3137 17 44 14.3137 44 11C44 7.68629 41.3137 5 38 5C34.6863 5 32 7.68629 32 11C32 14.3137 34.6863 17 38 17ZM38 15C40.2091 15 42 13.2091 42 11C42 8.79086 40.2091 7 38 7C35.7909 7 34 8.79086 34 11C34 13.2091 35.7909 15 38 15Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 30V36C4.44772 36 4 36.4477 4 37V38C4 38.5523 4.44772 39 5 39H9V43H4C3.44772 43 3 43.4477 3 44C3 44.5523 3.44772 45 4 45H44C44.5523 45 45 44.5523 45 44C45 43.4477 44.5523 43 44 43H39V39H43C43.5523 39 44 38.5523 44 38V37C44 36.4477 43.5523 36 43 36V30C43 28.3431 41.6569 27 40 27H36C34.3431 27 33 28.3431 33 30V36C32.4477 36 32 36.4477 32 37V38C32 38.5523 32.4477 39 33 39H37V43H25V39H29C29.5523 39 30 38.5523 30 38V37C30 36.4477 29.5523 36 29 36V30C29 28.3431 27.6569 27 26 27H22C20.3431 27 19 28.3431 19 30V36C18.4477 36 18 36.4477 18 37V38C18 38.5523 18.4477 39 19 39H23V43H11V39H15C15.5523 39 16 38.5523 16 38V37C16 36.4477 15.5523 36 15 36V30C15 28.3431 13.6569 27 12 27H8C6.34315 27 5 28.3431 5 30ZM12 29H8C7.44772 29 7 29.4477 7 30V36H13V30C13 29.4477 12.5523 29 12 29ZM22 29H26C26.5523 29 27 29.4477 27 30V36H21V30C21 29.4477 21.4477 29 22 29ZM40 29C40.5523 29 41 29.4477 41 30V36H35V30C35 29.4477 35.4477 29 36 29H40Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38 15C40.2091 15 42 13.2091 42 11C42 8.79086 40.2091 7 38 7C35.7909 7 34 8.79086 34 11C34 13.2091 35.7909 15 38 15ZM39 9C39 8.44772 38.5523 8 38 8C37.4477 8 37 8.44772 37 9V11C37 11.5523 37.4477 12 38 12H40C40.5523 12 41 11.5523 41 11C41 10.4477 40.5523 10 40 10H39V9Z"
        fill="#E3E1E1"
      />
      <path
        d="M8 29H12C12.5523 29 13 29.4477 13 30V36H7V30C7 29.4477 7.44772 29 8 29Z"
        fill="#E3E1E1"
      />
      <path
        d="M26 29H22C21.4477 29 21 29.4477 21 30V36H27V30C27 29.4477 26.5523 29 26 29Z"
        fill="#E3E1E1"
      />
      <path
        d="M41 30C41 29.4477 40.5523 29 40 29H36C35.4477 29 35 29.4477 35 30V36H41V30Z"
        fill="#E3E1E1"
      />
    </svg>
  )
}

export { WaitingRoomIcon }
