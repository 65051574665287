import './pages.scss'

import React, { FC, ReactNode } from 'react'
import LoadPage from '../LoadPage'
import { LoaderProps } from '../Elements/Loaders'
import FullScreenClose from '../Elements/FullScreenClose'

type Props = {
  returnURL?: string
  isLoading?: boolean
  loaderProps?: LoaderProps
  footer?: ReactNode
}

const OnboardingFullscreenPage: FC<Props> = ({
  isLoading,
  loaderProps,
  returnURL,
  children,
  footer,
}) => {
  return (
    <>
      <main className="flex flex-1 fullscreen-page">
        {returnURL && <FullScreenClose returnURL={returnURL} />}
        <div className="grid py-16 px-8">
          <div className="grid__half">
            <LoadPage isLoading={isLoading} loaderProps={loaderProps}>
              {children}
            </LoadPage>
          </div>
        </div>
      </main>
      {!!footer && footer}
    </>
  )
}

export default OnboardingFullscreenPage
