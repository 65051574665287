import styled from 'styled-components'
import { InfoIconLarge } from '../../../assets/icons/crow'
import useI18n from '../../../i18n/useI18n'
import { colors } from '../Style/theme'
import { Paragraph, Title } from '../Typography'

export const AccessRevoked = () => {
  const { t } = useI18n()
  return (
    <InfoContainer>
      <InfoIconLarge />
      <InfoTextContainer>
        <Title color={colors.black}>{t('NO_SYSTEM_ACCESS_TITLE')}</Title>
        <Paragraph>{t('NO_SYSTEM_ACCESS_DESCRIPTION')}</Paragraph>
      </InfoTextContainer>
    </InfoContainer>
  )
}

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 546px;
  margin-top: 23px;

  & > *:last-child {
    margin-top: 16px;
    text-align: center;
  }
`
