import { CONNECT_TO_WIFI, DISCONNECT_FROM_WIFI, SET_WIFI_LIST } from '../../reducers/wifiSlice'
import { RootState } from '../../store'
import { connectToWifi, disconnectFromWifi, getWifiNetworks } from '../apiPanel'
import { createUseRequest } from './createUseRequest'

const selectWifiList = (state: RootState, panelId: string) => state.wifi[panelId]?.wifiList

export const useWifiList = createUseRequest({
  successActionCreator: SET_WIFI_LIST,
  apiCall: getWifiNetworks,
  selector: selectWifiList,
  requirePanelId: true,
})

export const useConnectToWifi = createUseRequest({
  successActionCreator: CONNECT_TO_WIFI,
  apiCall: connectToWifi,
  requirePanelId: true,
})

export const useDisconnectFromWifi = createUseRequest({
  successActionCreator: DISCONNECT_FROM_WIFI,
  selector: selectWifiList,
  apiCall: disconnectFromWifi,
  requirePanelId: true,
})
