import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import BackButton from '../../../../components/BackButton'
import { LoaderProps } from '../../../../components/Elements/Loaders'
import LoadPage from '../../../../components/LoadPage'
import { REQUEST_ERROR_RESET } from '../../../../reducers/requestSlice'
import { usePanelId } from '../../hooks/usePanelId'

type Props = {
  isLoading: boolean
  loaderProps?: LoaderProps
  returnUrl?: string
  onBackClick?: () => void
}

const SettingsSubPage: FC<Props> = ({
  returnUrl,
  isLoading,
  loaderProps,
  children,
  onBackClick,
}) => {
  const panelId = usePanelId()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(REQUEST_ERROR_RESET())
    }
  }, [dispatch])

  return (
    <main className={`flex flex-1 fullscreen-page `}>
      <div className="grid py-16 px-8">
        <div className="grid__half">
          <LoadPage isLoading={isLoading} loaderProps={loaderProps}>
            <BackButton
              backUrl={returnUrl || `/systems/${panelId}/settings`}
              onClick={onBackClick}
            />
            {children}
          </LoadPage>
        </div>
      </div>
    </main>
  )
}

export default SettingsSubPage
