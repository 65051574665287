import styled from 'styled-components'
import CrowCard from '../../../components/Crow/CrowCard/CrowCard'
import { colors } from '../../../components/Crow/Style/theme'
import { Paragraph, SubHeadline, Heading3 } from '../../../components/Crow/Typography'

const TileContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media only screen and (max-width: 600px) {
    gap: 15px;
  }
`

const RoomNameTitle = styled(Heading3)`
  display: flex;
  align-items: center;
  line-height: 22px;
  color: ${colors.black800};
  max-width: 138px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const FooterDevices = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${colors.black900};
`

const IconContainer = styled.li<{ color?: string }>`
  margin-top: 0;
  border-radius: 8px;
  background-color: ${({ color }) => (color ? color : colors.black100)};
  display: flex;
  align-items: center;
  justify-content: center;
`

// eslint-disable no-unused-modules

const DoorsCrowCard = styled(CrowCard)`
  display: flex;
  align-items: center;
  border-radius: 12px;
  margin-bottom: 8px;
  padding: 17px 16px 23px 16px;
  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }
`

const DeviceName = styled(Paragraph)`
  margin-right: auto;
  @media (max-width: 425px) {
    margin-right: 0px;
    display: flex;
    justify-content: center;
    padding-bottom: 12px;
  }
`

const WindowsCrowCard = styled(CrowCard)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 12px;
  padding: 0px;
  margin-bottom: 8px;
`

const WindowDeviceNameStatusContainer = styled.div`
  padding: 16px 16px 16px 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 425px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const StyledParagraphBatteryLow = styled(Paragraph)<{ color?: string }>`
  margin-right: auto;
  padding-left: 16px;
  @media (max-width: 425px) {
    margin-right: 0px;
    padding-left: 0px;
  }
  color: ${({ color }) => (color ? color : colors.black)};
`

const DeviceStatusContainer = styled.div<{ color?: any }>`
  border-radius: 20px;
  background-color: ${({ color }) => (color ? colors.black300 : colors.red)};
  height: 23px;
`

const StyledStrong = styled.strong<{ color?: any }>`
  color: ${({ color }) => (color ? colors.black900 : colors.white)};
  text-transform: uppercase;
  padding-left: 12px;
  padding-right: 12px;
`

const LowBatteryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 16px 16px 16px;
  border-top: 1px solid ${colors.black200};
  background-color: ${colors.white};
  border-bottom-right-radius: 11px;
  border-bottom-left-radius: 11px;
  width: 100%;
  color: ${colors.black800};

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 17px 0px 23px 0px;
  }
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledSubHeadline = styled(SubHeadline)<{ color?: string }>`
  color: ${({ color }) => (color ? color : colors.black700)};
  line-height: 16px;
  padding-bottom: 6px;
`

export {
  TileContainer,
  RoomNameTitle,
  FooterDevices,
  IconContainer,
  DoorsCrowCard,
  DeviceName,
  WindowsCrowCard,
  WindowDeviceNameStatusContainer,
  DeviceStatusContainer,
  StyledStrong,
  LowBatteryContainer,
  StyledParagraphBatteryLow,
  FlexColumn,
  StyledSubHeadline,
}
