const FrontGardenIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29 7.15256e-07C28.4822 7.15256e-07 28.0564 0.393501 28.0052 0.897757C28.0017 0.931374 28 0.965483 28 1V35H22C21.4477 35 21 35.4477 21 36C21 36.5523 21.4477 37 22 37H41C41.5523 37 42 36.5523 42 36C42 35.4477 41.5523 35 41 35H30V2H41C41.5523 2 42 1.55229 42 1C42 0.447716 41.5523 2.46331e-08 41 5.50197e-08L29 7.15256e-07Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 4C32.4477 4 32 4.44772 32 5V15C32 15.5523 32.4477 16 33 16H41C41.5523 16 42 15.5523 42 15C42 14.4477 41.5523 14 41 14H39V11H41C41.5523 11 42 10.5523 42 10C42 9.44771 41.5523 9 41 9H39V6H41C41.5523 6 42 5.55228 42 5C42 4.44772 41.5523 4 41 4L33 4ZM37 11V14H34V11H37ZM34 9H37V6H34V9Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.98042 5.52503C10.5934 5.67679 11.2756 5.84567 12.002 5.9355C12.0353 5.41328 12.4694 5 13 5C13.5306 5 13.9647 5.41328 13.998 5.9355C14.7244 5.84567 15.4066 5.67679 16.0196 5.52503C17.8022 5.08374 19 4.78721 19 6.99996C19 10.3137 16.3137 13 13 13C9.68629 13 7 10.3137 7 6.99996C7 4.78721 8.19781 5.08374 9.98042 5.52503ZM9.01491 7.3477C9.12579 7.37413 9.24476 7.40377 9.3767 7.43664C9.60788 7.49424 9.87899 7.56178 10.2161 7.63934C10.9878 7.81686 11.9482 8 13 8C14.0518 8 15.0122 7.81686 15.7839 7.63934C16.121 7.56178 16.3921 7.49425 16.6233 7.43665C16.7549 7.40386 16.8744 7.37408 16.9851 7.3477C16.8089 9.39401 15.092 11 13 11C10.908 11 9.19112 9.39401 9.01491 7.3477Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
      <path
        d="M33 18C32.4477 18 32 18.4477 32 19V20C32 20.5523 32.4477 21 33 21C33.5523 21 34 20.5523 34 20H36C36.5523 20 37 19.5523 37 19C37 18.4477 36.5523 18 36 18H33Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 37.3273V38H1C0.447716 38 0 38.4477 0 39C0 39.5523 0.447716 40 1 40H12V41C12 41.5523 12.4477 42 13 42C13.5523 42 14 41.5523 14 41V40H31C31.5523 40 32 39.5523 32 39C32 38.4477 31.5523 38 31 38H14V31.4142L17.114 28.3002C19.3553 29.3476 21.6594 29.0315 23.3775 27.4319C25.2069 25.7286 26.1909 22.7413 25.9691 18.9704C25.9393 18.4644 25.5356 18.0607 25.0296 18.0309C21.2587 17.8091 18.2714 18.7931 16.5681 20.6225C14.9686 22.3406 14.6524 24.6446 15.6998 26.886L14 28.5858V15C14 14.4477 13.5523 14 13 14C12.4477 14 12 14.4477 12 15V34.4988L10.3871 32.886C11.4345 30.6446 11.1183 28.3406 9.51878 26.6225C7.81554 24.7931 4.82821 23.8091 1.05743 24.0309C0.551376 24.0607 0.147652 24.4644 0.117882 24.9704C-0.103928 28.7413 0.880104 31.7286 2.7095 33.4319C4.42755 35.0315 6.73158 35.3476 8.97296 34.3002L12 37.3273ZM21.3798 21.206L17.2453 25.3404C16.8705 24.0452 17.196 22.8833 18.0319 21.9854C19.0798 20.8598 21.0764 19.9972 24.0003 19.9997C24.0028 22.9236 23.1402 24.9202 22.0146 25.9681C21.1167 26.8041 19.9547 27.1295 18.6595 26.7547L22.794 22.6202C23.1845 22.2297 23.1845 21.5965 22.794 21.206C22.4035 20.8155 21.7703 20.8155 21.3798 21.206ZM4.70723 27.206L8.84158 31.3404C9.21639 30.0452 8.89091 28.8832 8.05498 27.9854C7.00709 26.8598 5.01058 25.9972 2.08671 25.9997C2.08416 28.9236 2.94683 30.9202 4.07235 31.9681C4.97022 32.8041 6.1322 33.1295 7.42742 32.7547L3.29301 28.6202C2.90248 28.2297 2.90249 27.5965 3.29301 27.206C3.68354 26.8154 4.31671 26.8155 4.70723 27.206Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
    </svg>
  )
}

export { FrontGardenIcon }
