const GardenRoomIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.62 24.206L19.7545 28.3405C20.1293 27.0453 19.8038 25.8833 18.9679 24.9854C17.92 23.8599 15.9234 22.9972 12.9995 22.9998C12.997 25.9237 13.8596 27.9202 14.9852 28.9681C15.8831 29.8041 17.0451 30.1296 18.3403 29.7547L14.2058 25.6202C13.8153 25.2297 13.8153 24.5966 14.2058 24.206C14.5963 23.8155 15.2295 23.8155 15.62 24.206Z"
        fill="#E3E1E1"
      />
      <path
        d="M32.2926 30.206L28.1582 34.3404C27.7834 33.0452 28.1089 31.8833 28.9448 30.9854C29.9927 29.8599 31.9892 28.9972 34.9131 28.9998C34.9156 31.9237 34.053 33.9202 32.9275 34.9681C32.0296 35.8041 30.8676 36.1296 29.5724 35.7547L33.7068 31.6202C34.0973 31.2297 34.0973 30.5965 33.7068 30.206C33.3163 29.8155 32.6831 29.8155 32.2926 30.206Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.82902 15.4012C6.30839 15.7772 6 16.3804 6 17.0226V41H4C3.44772 41 3 41.4477 3 42C3 42.5523 3.44772 43 4 43H23V44C23 44.5523 23.4477 45 24 45C24.5523 45 25 44.5523 25 44V43H44C44.5523 43 45 42.5523 45 42C45 41.4477 44.5523 41 44 41H42V17.0226C42 16.3804 41.6916 15.7772 41.171 15.4012L25.171 3.84568C24.472 3.34083 23.528 3.34083 22.829 3.84568L6.82902 15.4012ZM40 41V17L24.5836 5.91946C24.2349 5.66882 23.7651 5.66882 23.4164 5.91946L8 17V41H23V34.4142L19.886 31.3002C17.6447 32.3476 15.3406 32.0314 13.6225 30.4319C11.7931 28.7286 10.8091 25.7412 11.0309 21.9704C11.0607 21.4644 11.4644 21.0606 11.9704 21.0309C15.7413 20.809 18.7286 21.7931 20.4319 23.6225C22.0314 25.3406 22.3476 27.6446 21.3002 29.886L23 31.5857V18C23 17.4477 23.4477 17 24 17C24.5523 17 25 17.4477 25 18V37.4988L26.6129 35.8859C25.5655 33.6446 25.8817 31.3406 27.4812 29.6225C29.1845 27.7931 32.1718 26.809 35.9426 27.0309C36.4486 27.0606 36.8524 27.4644 36.8821 27.9704C37.1039 31.7412 36.1199 34.7286 34.2905 36.4318C32.5725 38.0314 30.2684 38.3476 28.027 37.3002L25 40.3273V41H40ZM15.6202 24.206L19.7547 28.3404C20.1295 27.0452 19.804 25.8832 18.9681 24.9853C17.9202 23.8598 15.9236 22.9971 12.9997 22.9997C12.9972 25.9236 13.8598 27.9202 14.9854 28.9681C15.8833 29.804 17.0453 30.1295 18.3405 29.7546L14.206 25.6202C13.8155 25.2296 13.8155 24.5965 14.206 24.206C14.5965 23.8154 15.2297 23.8154 15.6202 24.206ZM32.2928 30.206L28.1584 34.3404C27.7836 33.0452 28.1091 31.8832 28.945 30.9853C29.9929 29.8598 31.9894 28.9971 34.9133 28.9997C34.9158 31.9236 34.0532 33.9202 32.9277 34.9681C32.0298 35.804 30.8678 36.1295 29.5726 35.7546L33.707 31.6202C34.0975 31.2296 34.0975 30.5965 33.707 30.2059C33.3165 29.8154 32.6833 29.8154 32.2928 30.206Z"
        fill="#333331"
      />
    </svg>
  )
}

export { GardenRoomIcon }
