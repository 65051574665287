import * as React from 'react'

const AppCustomerSupportIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg data-name="Layer 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31" {...props}>
      <defs>
        <style>
          {
            '.icon-sector-alarm-app-customer-support_svg__icon-sector-alarm-app-customer-support_svg__cls-2{fill:#58595b}'
          }
        </style>
      </defs>
      <path d="M15.5 31A15.5 15.5 0 1131 15.5 15.52 15.52 0 0115.5 31z" fill="#fff" />
      <path
        className="icon-sector-alarm-app-customer-support_svg__icon-sector-alarm-app-customer-support_svg__cls-2"
        d="M14.74 20.88a.4.4 0 01-.16 0 2.1 2.1 0 01-1.51-2.2.4.4 0 01.4-.4h3.63a.405.405 0 110 .81h-3.19a1.28 1.28 0 001 1 .404.404 0 01-.17.79z"
      />
      <path
        className="icon-sector-alarm-app-customer-support_svg__icon-sector-alarm-app-customer-support_svg__cls-2"
        d="M15.27 23.6c-3.72 0-6.74-3.73-6.74-8.32s2.9-7.89 6.74-7.89c3.57 0 6.34 2.91 6.7 7h.27a.4.4 0 01.32.15 2.42 2.42 0 01.3 1.7 7.94 7.94 0 01-.4 1.93.41.41 0 01-.38.26h-.4a6 6 0 01-1.75 2.29.4.4 0 01-.25.08h-2.75a.405.405 0 110-.81h2.61A4.87 4.87 0 0021 17.9a.4.4 0 01.39-.29h.39a7.63 7.63 0 00.22-1.45 2.66 2.66 0 00-.07-1h-.34a.4.4 0 01-.4-.38C21 11 18.51 8.2 15.27 8.2s-5.93 3-5.93 7.08S12 22.8 15.27 22.8a5 5 0 003-1 .407.407 0 01.49.65 5.81 5.81 0 01-3.49 1.15z"
      />
      <path
        className="icon-sector-alarm-app-customer-support_svg__icon-sector-alarm-app-customer-support_svg__cls-2"
        d="M13 16.26a.77.77 0 01-.77-.77v-.41a.77.77 0 111.54 0v.41a.77.77 0 01-.77.77zm4.59 0a.77.77 0 01-.77-.77v-.41a.77.77 0 111.54 0v.41a.77.77 0 01-.77.77z"
      />
    </svg>
  )
}

export { AppCustomerSupportIcon }
