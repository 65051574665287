const DoorLockFailedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 18.9407V2.94067H17V6.94067C17 7.49296 17.4477 7.94067 18 7.94067C18.5523 7.94067 19 7.49296 19 6.94067V2.69067C19 2.23847 18.8274 1.79754 18.5088 1.4667C18.189 1.13458 17.747 0.940674 17.2778 0.940674H4.72222C4.25302 0.940674 3.81103 1.13458 3.49121 1.4667C3.17263 1.79754 3 2.23847 3 2.69067V18.9407H1C0.447715 18.9407 0 19.3884 0 19.9407C0 20.493 0.447715 20.9407 1 20.9407H21.125C21.6773 20.9407 22.125 20.493 22.125 19.9407C22.125 19.3884 21.6773 18.9407 21.125 18.9407H19V14.9407C19 14.3884 18.5523 13.9407 18 13.9407C17.4477 13.9407 17 14.3884 17 14.9407V18.9407H5Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M14 10.9407C14 11.493 13.5523 11.9407 13 11.9407C12.4477 11.9407 12 11.493 12 10.9407C12 10.3884 12.4477 9.94067 13 9.94067C13.5523 9.94067 14 10.3884 14 10.9407Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M20.2929 7.23357C20.6834 6.84304 21.3166 6.84304 21.7071 7.23357C22.0976 7.62409 22.0976 8.25726 21.7071 8.64778L19.4142 10.9407L21.7071 13.2336C22.0976 13.6241 22.0976 14.2573 21.7071 14.6478C21.3166 15.0383 20.6834 15.0383 20.2929 14.6478L18 12.3549L15.7071 14.6478C15.3166 15.0383 14.6834 15.0383 14.2929 14.6478C13.9024 14.2573 13.9024 13.6241 14.2929 13.2336L16.5858 10.9407L14.2929 8.64778C13.9024 8.25726 13.9024 7.62409 14.2929 7.23357C14.6834 6.84304 15.3166 6.84304 15.7071 7.23357L18 9.52646L20.2929 7.23357Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { DoorLockFailedIcon }
