const UnknownIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41 26C41 26.5523 40.5523 27 40 27C39.4477 27 39 26.5523 39 26C39 25.4477 39.4477 25 40 25C40.5523 25 41 25.4477 41 26Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 44C3.44772 44 3 43.5523 3 43C3 42.4477 3.44772 42 4 42H22V10C22 9.44772 22.4477 9 23 9H43C43.5523 9 44 9.44772 44 10V43C44 43.5523 43.5523 44 43 44H4ZM24 11V42H42V11H24Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 14C4.44772 14 4 14.4477 4 15V24C4 24.5523 4.44772 25 5 25H17C17.5523 25 18 24.5523 18 24V15C18 14.4477 17.5523 14 17 14H12C12 13.4477 11.5523 13 11 13C10.4477 13 10 13.4477 10 14H5ZM6 16V23H16V16H6Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42 11H24V42H42V11ZM40 27C40.5523 27 41 26.5523 41 26C41 25.4477 40.5523 25 40 25C39.4477 25 39 25.4477 39 26C39 26.5523 39.4477 27 40 27Z"
        fill="#E3E1E1"
      />
    </svg>
  )
}

export { UnknownIcon }
