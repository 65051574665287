import { logEvent } from 'firebase/analytics'
import { googleAnalytics } from '../firebase'

type EventType =
  | 'login'
  | 'default_panel'
  | 'open_miniapp'
  | 'arm_status_set'
  | 'tab_select'
  | 'lock_toggle'
  | 'smart_plug_toggle'
  | 'miniapp_leave'
  | 'app_state_change'
  | 'feedback_select'
  | 'feedback_sent'
  | 'rate_toast_show'
  | 'rate_toast_refuse'
  | 'rate_toast_positive'
  | 'rate_toast_negative'
  | '7_days_block'
  | 'error_show'
  | 'timed_out_logins'
  | 'wifi_change_wifi_connection'
  | 'nav_select_web'
  | 'settings_wifi_disconnect_click'
  | 'settings_wifi_disconnect_request_made'
  | 'settings_wifi_disconnect_success'
  | 'settings_wifi_disconnect_failure'
  | 'settings_wifi_clicked_to_open'
  | 'settings_wifi_viewed'
  | 'settings_wifi_closed'
  | 'settings_wifi_has_wifi'
  | 'settings_wifi_no_wifi'
  | 'settings_wifi_failure'
  | 'settings_wifi_connect_view_form'
  | 'settings_wifi_connect_close_form'
  | 'settings_wifi_connect_submit_form'
  | 'settings_wifi_connect_request_made'
  | 'settings_wifi_connect_connect_success'
  | 'settings_wifi_connect_connect_failure'
  | 'feedback_select'
  | 'feedback_sent'
  | 'feedback_rate_details'

type ArmStatus = 'total_arm' | 'partial_arm' | 'disarm' | 'arm_annex' | 'disarm_annex'
type PanelType = 'TC' | 'SAS'
type LockStatus = 'ON' | 'OFF'

type StateType = 'close' | 'open'
type MiniApp =
  | 'home'
  | 'temperature'
  | 'history'
  | 'humidity'
  | 'doors_windows'
  | 'people'
  | 'doorlocks'
  | 'smoke_detectors'
  | 'camera'
  | 'smartplugs'
  | 'settings'
  | 'inbox'
  | 'account'
  | 'sign_out'
type SmartplugStatus = 'ON' | 'OFF'

export const logFirebaseEvent = (
  eventType: EventType,
  eventParams?: {
    [key: string]: any
  },
) => {
  if (!googleAnalytics) {
    // eslint-disable-next-line no-console
    console.error('Firebase not initialized!')
  } else {
    logEvent(googleAnalytics, eventType.toString(), eventParams)
  }
}

export const logErrorEvent = (location: string, errorMessage: string) => {
  logFirebaseEvent('error_show', {
    error_location_web: location,
    error_message: errorMessage,
  })
}

export const logArmingEvent = (armStatus: ArmStatus, panelType: PanelType) => {
  logFirebaseEvent('arm_status_set', {
    arm_status: armStatus,
    panel_type: panelType,
  })
}

export const logSmartplugEvent = (smartplugStatus: SmartplugStatus, panelType: PanelType) => {
  logFirebaseEvent('smart_plug_toggle', {
    new_state: smartplugStatus,
    panel_type: panelType,
  })
}

export const logLockEvent = (lockStatus: LockStatus, panelType: PanelType) => {
  logFirebaseEvent('lock_toggle', {
    new_state: lockStatus,
    panel_type: panelType,
  })
}

export const logNewNavigationEvent = (navigationPath: string) => {
  logFirebaseEvent('nav_select_web', {
    location: navigationPath,
  })
}
export const logStateChangeEvent = (type: StateType) => {
  logFirebaseEvent('app_state_change', {
    type: type,
  })
}

export const logBackEvent = (backFrom: MiniApp) => {
  logFirebaseEvent('miniapp_leave', {
    type: backFrom,
  })
}

export const logFeedbackOpenEvent = () => {
  logFirebaseEvent('feedback_select', {})
}

export const logFeedbackSentEvent = () => {
  logFirebaseEvent('feedback_sent', {})
}

export const logFeedbackRatingDetails = (rating: number, message: string) => {
  logFirebaseEvent('feedback_rate_details', {
    score: rating,
    message: message,
  })
}
