import { ComponentType, FC } from 'react'
import styled from 'styled-components'
import { ChevronRightIcon } from '../../../../assets/icons/crow'
import { colors } from '../../../../components/Crow/Style/theme'
import { SubTitle } from '../../../../components/Crow/Typography'
import useI18n from '../../../../i18n/useI18n'
import { TranslationKey } from '../../../../types/generated/TranslationKey'

type Props = {
  url?: string
  title: TranslationKey
  description?: TranslationKey
  svg?: ComponentType<{ className?: string }>
  chevron?: boolean
  onClick?: () => void
  id?: string
}

const SettingMenuItemCrow: FC<Props> = ({
  title,
  description,
  svg: SvgIcon,
  chevron,
  onClick,
  id,
}) => {
  const { t } = useI18n()

  return (
    <StyledLink className="settings__item" onClick={onClick} id={id}>
      {SvgIcon && (
        <div className="settings__side">
          <SvgIcon className="icon icon--small" />
        </div>
      )}
      <div className="w-full">
        {chevron ? (
          <>
            <SettingsItemContainer>
              <div className={`qa-settings-${title}`}>
                {title && (
                  <StyledSubtitle className={`qa-settings-h2-${title}`}>{t(title)}</StyledSubtitle>
                )}
                {description && <p>{t(description!)}</p>}
              </div>
              {chevron && (
                <RightIconContainer>
                  <ChevronRightIcon />
                </RightIconContainer>
              )}
            </SettingsItemContainer>
          </>
        ) : (
          <>
            {title && <StyledSubtitle>{t(title)}</StyledSubtitle>}
            {description && <p>{t(description!)}</p>}
          </>
        )}
      </div>
    </StyledLink>
  )
}

const StyledLink = styled.li`
  margin: 0;
  margin-bottom: 8px;
  border-radius: 12px;
  padding: 22px 24px;
  cursor: pointer;
`

const SettingsItemContainer = styled.div`
  display: flex;
`

const StyledSubtitle = styled(SubTitle)`
  margin: 0;
`

const RightIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  color: ${colors.blue};
  font-size: 22px;
`

export default SettingMenuItemCrow
