import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { colors } from '../../../components/Crow/Style/theme'

const EventSkeletons = () => (
  <SkeletonTheme baseColor={colors.black200} borderRadius="12px">
    <div className="mt-3">
      <Skeleton height={72} />
    </div>
    <div className="mt-3">
      <Skeleton height={72} />
    </div>
    <div className="mt-3">
      <Skeleton height={72} />
    </div>
    <div className="mt-3">
      <Skeleton height={72} />
    </div>
  </SkeletonTheme>
)

export default EventSkeletons
