import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import InternalErrorPage from '../pages/errors/InternalErrorPage'

type Props = RouteComponentProps

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    if ((window as any).isJestRunning) return
    // eslint-disable-next-line no-console
    console.log(error)
    // eslint-disable-next-line no-console
    console.log(errorInfo)
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.location.pathname === this.props.location.pathname) return
    this.setState({ hasError: false })
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state

    if (!hasError) {
      return children
    }

    return <InternalErrorPage />
  }
}

export default withRouter(ErrorBoundary)
