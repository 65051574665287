import { FC, useState } from 'react'
import styled from 'styled-components'
import { PlusIcon } from '../../../../assets/icons/crow'
import BackButton from '../../../../components/BackButton'
import { colors } from '../../../../components/Crow/Style/theme'
import Toast from '../../../../components/Crow/Toast/Toast'
import Tooltip from '../../../../components/Crow/Tooltip'
import { Paragraph, Title } from '../../../../components/Crow/Typography/Typography'
import FullscreenPage from '../../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../../components/TcOrCrowContainer'
import useI18n from '../../../../i18n/useI18n'
import { usePanelInfo, usePanelStatus } from '../../../../services/requestHooks/panelInfo'
import { useGetPanelPermissions, useGetUsers } from '../../../../services/requestHooks/users'
import { Capability } from '../../../../types/PanelModel'
import { PermanentUser } from '../../../../types/PermanentUser'
import { useOnMount } from '../../../../utils/commonHooks'
import { usePanelId } from '../../../AlarmSystems/hooks/usePanelId'
import AddUserDrawer from './AddUserDrawer'
import Person from './Person'

const CrowUsersPage: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const [displayModal, setModalVisibility] = useState<boolean>(false)
  const [peopleModuleNotEnabled, setPeopleModuleNotEnabled] = useState<boolean>(false)

  const {
    run: getPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadPanelInfoError,
  } = usePanelInfo()

  const {
    run: getPanelStatus,
    data: panelStatus,
    isLoading: loadingPanelStatus,
    error: loadingPanelStatusError,
  } = usePanelStatus()
  const panelIsArmed: boolean = panelStatus?.Status !== 1

  const {
    run: getUsers,
    isLoading: loadingUsers,
    error: usersError,
    data: usersData,
  } = useGetUsers()

  const {
    run: getPanelPermissions,
    isLoading: loadingPanelPermissions,
    error: panelPermissionsError,
    data: panelPermissions,
  } = useGetPanelPermissions()

  const users: PermanentUser[] = usersData || []

  const isLoading =
    loadingPanelInfo || loadingPanelStatus || loadingUsers || loadingPanelPermissions
  const hasError =
    loadPanelInfoError || loadingPanelStatusError || usersError || panelPermissionsError
  const canAddUsers =
    panelPermissions?.Accesses.IsLegalOwner || panelPermissions?.Accesses.IsAdminUser

  const returnUrl = `/systems/${panelId}/people`

  useOnMount(() => {
    if (panelInfo?.Capabilities.includes(Capability.USE_LEGACY_PEOPLE)) {
      setPeopleModuleNotEnabled(true)
    }
    if (!loadingPanelInfo && panelId !== panelInfo?.PanelId) {
      getPanelInfo(panelId)
    }
    if (!loadingPanelStatus) {
      getPanelStatus({ panelId })
    }
    if (!loadingUsers) {
      getUsers(panelId)
    }
    if (!loadingPanelPermissions) {
      getPanelPermissions()
    }
  })

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen>
      <S.Page
        isLoading={isLoading}
        loaderProps={{
          loadingText: { text: 'Loading' },
          errorHandling: {
            loadingFailed: peopleModuleNotEnabled || (!isLoading && !!hasError),
            errorHeaderText: t('PEOPLE'),
            errorText: peopleModuleNotEnabled
              ? 'People module not enabled for this user'
              : 'Something went wrong. Please try again',
            returnUrl,
          },
        }}
      >
        <BackButton backUrl={returnUrl} />
        <Title large>{t('permanent_users') + ` (${users.length})`}</Title>
        <S.Description>{t('permanent_users_description')}</S.Description>
        <S.PermanentUsers>
          {canAddUsers && (
            <S.AddButtonTooltip
              text={t('system_must_be_disarmed_to_add')}
              position="right"
              active={panelIsArmed}
              shouldGrow={false}
            >
              <S.Button onClick={() => setModalVisibility(true)} disabled={panelIsArmed}>
                <PlusIcon />
              </S.Button>
            </S.AddButtonTooltip>
          )}

          <S.UserList>
            {panelPermissions &&
              users.map((user) => (
                <Person
                  key={user.PersonId}
                  user={user}
                  panelIsArmed={panelIsArmed}
                  panelPermissions={panelPermissions}
                />
              ))}
          </S.UserList>
          <AddUserDrawer isOpen={displayModal} handleClose={() => setModalVisibility(false)} />
        </S.PermanentUsers>
        <Toast />
      </S.Page>
    </TcOrCrowContainer>
  )
}

const S = {
  Page: styled(FullscreenPage)`
    min-width: 600px;

    @media only screen and (min-width: 800px) {
      min-width: 700px;
    }

    & > div {
      align-items: normal;
    }

    & .grid__half {
      flex: 1;
    }

    & .fullscreen__back:hover {
      color: ${colors.lightBlue};
    }
    & .fullscreen__back::before {
      border-color: ${colors.blue};
    }

    @media only screen and (max-width: 600px) {
      min-width: 0;
    }
  `,
  Description: styled(Paragraph)`
    margin-top: 16px;
    color: ${colors.black800};
  `,
  PermanentUsers: styled.div``,
  AddButtonTooltip: styled(Tooltip)`
    margin-top: 16px;
  `,
  Button: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${colors.blue};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.lightBlue};
    }

    &:disabled {
      cursor: default;
      background-color: ${colors.black200};
      color: ${colors.white};
    }

    &:disabled:hover {
      background-color: ${colors.black200};
      color: ${colors.white};
    }
  `,
  UserList: styled.ul`
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin-top: 16px;
    padding: 0;

    @media only screen and (max-width: 600px) {
      grid-template-columns: none;
    }
  `,
}

export default CrowUsersPage
