import { forwardRef, Ref } from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Body, Footnote } from '../../../components/Crow/Typography/Typography'
import { EventIcon, getEventText, getEventTitle } from './util/Helpers'
import convertAndFormatTime from '../../../utils/ConvertAndFormatTIme'
import { useDateFnsLocale } from '../../../utils/useDateFnsLocale'
import useI18n from '../../../i18n/useI18n'
import { PanelEvent } from '../../../types/PanelEvent'
import { usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { colors } from '../../../components/Crow/Style/theme'

const computeInset = (index: number, listLength: number) => {
  if (listLength === 1) {
    return css``
  } else if (index === 0) {
    return css`
      inset: 50% auto -16px -33px;
    `
  } else if (index === listLength - 1) {
    return css`
      inset: 0px auto 50% -33px;
    `
  }
  return css`
    inset: 0px auto -16px -33px;
  `
}

interface EventListProps {
  events: PanelEvent[]
  refIndex?: number
}

const EventList = forwardRef(({ events, refIndex }: EventListProps, ref: Ref<HTMLLIElement>) => {
  const { t } = useI18n()
  const { data: panelStatus } = usePanelStatus()
  const locale = useDateFnsLocale()

  const listItems = events.map((event: any, index: number) => (
    <li ref={index === refIndex ? ref : null} key={index} className="flex items-center">
      <Time>{convertAndFormatTime(panelStatus?.TimeZoneName, locale, event.Time, false)}</Time>
      <Card inset={computeInset(index, events.length)}>
        <EventIcon eventType={event.EventType} />
        <div>
          <Body>{getEventTitle(event, t)}</Body>
          <Footnote color={colors.black600}>{getEventText(event, t)}</Footnote>
        </div>
      </Card>
    </li>
  ))

  return <ul className="p-0">{listItems}</ul>
})

const Time = styled(Body)`
  z-index: 1;
  background-color: #f0eeee;
  flex-shrink: 0;
  width: 62px;
  text-align: center;
`

interface CardProps {
  inset: FlattenSimpleInterpolation
}

const Card = styled.div<CardProps>`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  padding: 16px;
  width: 100%;
  border-radius: 16px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    border-left: 2px solid rgb(227, 225, 225);
    ${({ inset }) => inset}
  }
`

export default EventList
