import { isToday } from 'date-fns'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { CancelIcon, LockedIcon, UnlockedIcon } from '../../../assets/icons/crow'
import BackButton from '../../../components/BackButton'
import Drawer from '../../../components/Crow/Drawer/Drawer'
import { colors } from '../../../components/Crow/Style/theme'
import Toast, { addToast } from '../../../components/Crow/Toast/Toast'
import Toggle from '../../../components/Crow/Toogle/Toggle'
import { SubHeadline, Title } from '../../../components/Crow/Typography'
import LoadingOverlay from '../../../components/LoadingOverlay'
import FullscreenPage from '../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../components/TcOrCrowContainer'
import { displayNewNavigationMenu } from '../../../configs'
import useI18n from '../../../i18n/useI18n'
import { useGetLockStatus, useLock, useUnlock } from '../../../services/requestHooks/locks'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { DoorLock } from '../../../types/Lock'
import { useOnMount } from '../../../utils/commonHooks'
import convertAndFormatTime from '../../../utils/ConvertAndFormatTIme'
import { logBackEvent, logLockEvent } from '../../../utils/firebaseAnalytics'
import { useDateFnsLocale } from '../../../utils/useDateFnsLocale'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import PinCodePrompt, { usePinCodePrompt } from '../../AlarmSystems/Panel/PinCodeConfirm'
import LockSettings from './LockSettings'
import LockTileItem from './LocksTileItem'
import { FlexColumn, LocksWrapper, Unreachable } from './LockStyle'

const CrowLocksPage: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const locale = useDateFnsLocale()

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
    error: panelStatusError,
  } = usePanelStatus()

  const { run: sendLock, isLoading: isLoadingLockCommand } = useLock({
    onSuccess: () => {
      setCurrentLock(undefined)
      loadPanelStatus({ panelId })
    },
    onFailure: () => {
      addToast({
        title: t('failed_to_lock_door'),
        type: 'error',
      })
      return
    },
  })

  const { run: sendUnlock, isLoading: isLoadingUnlockCommand } = useUnlock({
    onSuccess: () => {
      setCurrentLock(undefined)
      loadPanelStatus({ panelId })
    },
    onFailure: () => {
      addToast({
        title: t('failed_to_unlock_door'),
        type: 'error',
      })
      return
    },
  })

  const {
    run: loadPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: panelInfoError,
  } = usePanelInfo()

  const {
    run: getLockStatus,
    data: lockStatus,
    isLoading: loadingLockStatus,
    error: lockStatusError,
  } = useGetLockStatus()

  useOnMount(() => {
    if (!loadingLockStatus) {
      getLockStatus(panelId)
    }
    if (!loadingPanelInfo && panelId !== panelInfo?.PanelId) {
      loadPanelInfo(panelId)
    }
    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
  })

  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false)
  const [lockState, setLockState] = useState<DoorLock>()
  const [currentLock, setCurrentLock] = useState<string | undefined>()

  const handleLockOrUnlock = (lock: DoorLock) => async (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    event.stopPropagation()

    if (!panelStatus?.IsOnline) {
      addToast({
        title: 'Lost connection',
        content: 'It seems like you have lost connection. Connect to the internet and try again.',
        type: 'warning',
        time: 4000,
      })
      return
    }

    if (lock.Status === 'unlock') {
      if (panelInfo?.QuickArmEnabled) {
        setCurrentLock(lock.Serial)
        sendLock({
          LockSerial: lock.Serial!,
          PanelCode: '',
          PanelId: panelId,
          Platform: 'web',
        })
      } else {
        const pinCode = await promptForPinCode()
        if (!pinCode) return
        setCurrentLock(lock.Serial)
        logLockEvent('ON', 'SAS')
        sendLock({
          LockSerial: lock.Serial!,
          PanelCode: pinCode,
          PanelId: panelId,
          Platform: 'web',
        })
      }
    } else {
      const pinCode = await promptForPinCode()
      if (!pinCode) return
      setCurrentLock(lock.Serial)
      logLockEvent('OFF', 'SAS')
      sendUnlock({
        LockSerial: lock.Serial!,
        PanelCode: pinCode,
        PanelId: panelId,
        Platform: 'web',
      })
    }
  }

  const openDrawer = (lock: DoorLock) => () => {
    const findById = lockStatus?.find(({ Id }) => Id === lock.Id)

    setLockState({
      Status: findById?.Status,
      PanelId: findById?.PanelId!,
      Serial: findById?.Serial!,
      Label: findById?.Label!,
      SoundLevel: findById?.SoundLevel!,
      LastChanged: findById?.LastChanged!,
      AutoLockEnabled: findById?.AutoLockEnabled!,
    })

    if (!panelStatus?.IsOnline) {
      return
    }
    setDrawerIsOpen(true)
  }

  const closeDrawer = () => {
    setDrawerIsOpen(false)
  }

  const isTogglingLock = isLoadingLockCommand || isLoadingUnlockCommand

  const returnUrl = `/systems/${panelId}`

  const { promptState, promptForPinCode } = usePinCodePrompt(panelId)

  const FormatLastChanged = ({
    LastChanged,
    Status,
    color,
  }: {
    LastChanged: string | undefined
    Status: 'lock' | 'unlock'
    color: string
  }) => {
    return (
      <>
        {LastChanged !== null && (
          <StyledSubHeadline color={color} className="qa-doorLocks-status">
            {`${Status === 'lock' ? t('locked') : t('unlocked')} ${
              !isToday(new Date(LastChanged!)) ? t('SINCE') : ''
            } ${convertAndFormatTime(panelStatus?.TimeZoneName, locale, LastChanged)} ${
              isToday(new Date(LastChanged!)) ? t('Today') : ''
            }`}
          </StyledSubHeadline>
        )}
      </>
    )
  }

  return (
    <>
      <TcOrCrowContainer panelInfo={panelInfo} fullscreen footer>
        <FullscreenPage
          isLoading={loadingLockStatus || loadingPanelInfo}
          loaderProps={{
            loadingText: { text: t('Loading') },
            errorHandling: {
              loadingFailed: !!lockStatusError || !!panelInfoError || !!panelStatusError,
              errorHeaderText: t('Locks'),
              errorText: t('Something went wrong. Please try again'),
              returnUrl,
            },
          }}
        >
          {!displayNewNavigationMenu && (
            <BackButton backUrl={returnUrl} onClick={() => logBackEvent('doorlocks')} />
          )}
          <Title large>{t('Locks')}</Title>
          <p className={`mt-4 mb-0 ${colors.black600} qa-doorLocks-message`}>
            {t('LOCKS_DESCRIPTION')}
          </p>
          <LocksWrapper className={`pl-0 mt-10 qa-doorlocks`}>
            {lockStatus?.map((lock) => {
              const locked = lock.Status === 'lock'
              const healthy = lock.Status === 'lock' || lock.Status === 'unlock'

              return (
                <LockTileItem
                  key={lock.Id}
                  text={lock.Label}
                  subText={
                    panelStatus?.IsOnline ? (
                      <FormatLastChanged
                        LastChanged={lock.LastChanged}
                        Status={lock.Status!}
                        color={colors.black700}
                      />
                    ) : (
                      <Unreachable>{t('Unreachable')}</Unreachable>
                    )
                  }
                  checked={locked}
                  healthy={healthy}
                  onClick={openDrawer(lock)}
                  onToggle={handleLockOrUnlock(lock!)}
                  isLoading={(isTogglingLock && currentLock === lock.Serial) || loadingLockStatus}
                  icon={locked ? <LockedIcon /> : <UnlockedIcon />}
                  disabled={panelStatus?.IsOnline ? false : true}
                />
              )
            })}
            <S.Drawer
              isOpen={drawerIsOpen}
              position="right"
              handleClose={closeDrawer}
              closeButton={true}
              closeIcon={<CancelIcon color={colors.blue} />}
              title={lockState?.Label}
            >
              <FlexColumn>
                <LoadingOverlay
                  isLoading={
                    (isTogglingLock && currentLock === lockState?.Serial) || loadingLockStatus
                  }
                >
                  {lockStatus?.map((lock) => (
                    <div key={lock.Id}>
                      {lock.Serial === lockState?.Serial! ? (
                        <S.ToggleWrapper>
                          <Toggle
                            enabled={lock?.Status === 'lock'}
                            onClick={handleLockOrUnlock(lock!)}
                            className="qa-doorLock-slider"
                          />
                          <div className="pt-3">
                            <FormatLastChanged
                              LastChanged={lock.LastChanged}
                              Status={lock.Status!}
                              color={colors.blue}
                            />
                          </div>
                        </S.ToggleWrapper>
                      ) : null}
                    </div>
                  ))}
                </LoadingOverlay>
                <LockSettings lock={lockState!} closeDrawer={closeDrawer} />
              </FlexColumn>
              {/*  Note: To ble implemented in future version.
                  <StyledCollapsible title={`${lockState?.Label} Doorlock events`}>
                  <FormatDayMonth statusTime={panelStatus?.StatusTime} />
                  </StyledCollapsible> */}
            </S.Drawer>
          </LocksWrapper>
        </FullscreenPage>
      </TcOrCrowContainer>
      <Toast />
      <PinCodePrompt promptState={promptState} />
    </>
  )
}

const StyledSubHeadline = styled(SubHeadline)<{ color?: string }>`
  margin-top: 3px;
  height: 36px;
  color: ${({ color }) => (color ? color : colors.black700)};
`

const S = {
  Drawer: styled(Drawer)`
    @media only screen and (max-width: 600px) {
      padding: 40px calc(32px + env(safe-area-inset-bottom)) 24px;
    }
  `,
  ToggleWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  SoundSettings: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `,
}

export default CrowLocksPage
