const DoorsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ? props.width : '22'}
      height={props.height ? props.height : '20'}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 10C16 10.5523 15.5523 11 15 11C14.4477 11 14 10.5523 14 10C14 9.44771 14.4477 9 15 9C15.5523 9 16 9.44771 16 10Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 18H3V1.75C3 1.28587 3.18438 0.840751 3.51256 0.512563C3.84075 0.184375 4.28587 0 4.75 0H17.25C17.7141 0 18.1592 0.184374 18.4874 0.512563C18.8156 0.840753 19 1.28587 19 1.75V18H21C21.5523 18 22 18.4477 22 19C22 19.5523 21.5523 20 21 20H1C0.447715 20 0 19.5523 0 19C0 18.4477 0.447715 18 1 18ZM5 2V18H17V2H5Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { DoorsIcon }
