const DisarmedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.98392 2.00781L2.05616 8.03781C2.05044 8.04242 2.036 8.05645 2.02235 8.08455C2.00814 8.11384 2 8.14804 2 8.18217V18H6.15664C6.1592 17.998 6.16258 17.9951 6.16658 17.9909C6.18812 17.9682 6.21522 17.9222 6.21522 17.8481V15.0227C6.21522 13.7839 7.15782 12.2792 8.86267 12.2792H10.9099C12.6215 12.2792 13.566 13.7817 13.566 15.0315V17.8569C13.566 17.9229 13.5904 17.9666 13.6127 17.9899C13.6173 17.9947 13.6211 17.9979 13.6239 18H18V8.16473C18 8.13144 17.9921 8.09769 17.9772 8.06737C17.9629 8.03819 17.9464 8.0208 17.9365 8.01269L9.98392 2.00781ZM19.1513 6.42391L11.1811 0.405676C10.4637 -0.136679 9.49255 -0.136679 8.77511 0.414452L0.83989 6.45012C0.315001 6.85261 0 7.49984 0 8.18217V18.2417C0 19.2127 0.76111 20 1.68855 20H6.168C7.29656 20 8.21522 19.0378 8.21522 17.8481V15.0227C8.21522 14.6203 8.51267 14.2792 8.86267 14.2792H10.9099C11.2686 14.2792 11.566 14.6203 11.566 15.0315V17.8569C11.566 19.0378 12.4847 20 13.6133 20H18.3114C19.2389 20 20 19.2127 20 18.2417V8.16473C20 7.47362 19.6763 6.82628 19.1513 6.42391Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

const SmallDisarmedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22374 2.08169C8.07891 1.9722 7.89532 1.97286 7.75177 2.08314L7.74798 2.08605L2.19532 6.30949C2.08278 6.39638 2 6.55187 2 6.72752V13.7692C2 13.9331 2.12091 14 2.18199 14H5.56695C5.75928 14 6 13.8207 6 13.4937V11.5C6 10.6716 6.67157 10 7.5 10H8.5C9.32843 10 10 10.6716 10 11.5V13.4998C10 13.8179 10.2381 14 10.4331 14H13.818C13.8791 14 14 13.9331 14 13.7692V6.71531C14 6.54051 13.9167 6.38278 13.7996 6.29199L8.22374 2.08169ZM14.4059 5.49673L8.82678 1.28397C8.32457 0.904324 7.64479 0.904325 7.14258 1.29012L1.58792 5.51509C1.2205 5.79683 1 6.24989 1 6.72752V13.7692C1 14.4489 1.53278 15 2.18199 15H5.56695C6.35693 15 7 14.3265 7 13.4937V11.5C7 11.2239 7.22386 11 7.5 11H8.5C8.77614 11 9 11.2239 9 11.5V13.4998C9 14.3265 9.64307 15 10.4331 15H13.818C14.4672 15 15 14.4489 15 13.7692V6.71531C15 6.23154 14.7734 5.7784 14.4059 5.49673Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { DisarmedIcon, SmallDisarmedIcon }
