import React, { useState } from 'react'
import styled from 'styled-components'
import { CancelIcon, SettingsIcon } from '../../../assets/icons/crow'
import Drawer from '../../../components/Crow/Drawer/Drawer'
import { colors } from '../../../components/Crow/Style/theme'
import Toast, { addToast } from '../../../components/Crow/Toast/Toast'
import { TemperatureSettingsForm } from '../../../components/forms/TemperatureSettingsForm'
import useI18n from '../../../i18n/useI18n'
import {
  usePostTemperatureSettings,
  useResetTemperatureSettings,
  useTemperatureSettings,
} from '../../../services/requestHooks/temperatureSettings'
import { isTranslationKey } from '../../../types/generated/TranslationKey'
import { TemperaturePreferenceValue, TemperatureSettingsType } from '../../../types/Temperature'

interface Props {
  temperatureSetting: TemperatureSettingsType
  panelId: string
}

const GeneralSettings = ({ temperatureSetting, panelId }: Props) => {
  const { t } = useI18n()
  const [drawerState, setDrawerState] = useState<{
    isOpen: boolean
    position: 'top' | 'bottom' | 'left' | 'right'
  }>({ isOpen: false, position: 'top' })

  const openDrawer = (position: 'top' | 'bottom' | 'left' | 'right') => () => {
    setDrawerState({
      position,
      isOpen: true,
    })
  }

  const closeDrawer = () => {
    setDrawerState((prevValue) => ({ ...prevValue, isOpen: false }))
  }

  const mapSettingProperties = (e: TemperatureSettingsType): TemperaturePreferenceValue => {
    return {
      Value: { Option: e.liveappSetting },
    }
  }
  let FormValue: TemperatureSettingsType = {
    liveappSetting: '',
    liveappToggle: false,
  }
  const { run: getTemperaturesSettings } = useTemperatureSettings()

  const {
    run: postTemperaturesSettings,
    isLoading: loadingPostTemperaturesSettings,
    error: temperatureSettingsError,
  } = usePostTemperatureSettings({
    onSuccess: () => {
      getTemperaturesSettings(panelId)
      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: t('toast_person_preference_success_content'),
      })
      setDrawerState((prevValue) => ({ ...prevValue, isOpen: false }))
    },
    onFailure: ({ message }) => {
      addToast({
        type: 'error',
        time: 5000,
        title: t('error_toast_generic_title'),
        content: isTranslationKey(temperatureSettingsError!)
          ? t(temperatureSettingsError)
          : t('error_toast_generic_content'),
      })
    },
  })
  const {
    run: resetTemperaturesSettings,
    isLoading: loadingTemperaturesReset,
    error: temperatureSettingsReset,
  } = useResetTemperatureSettings({
    onSuccess: () => {
      if (FormValue.liveappToggle) {
        postTemperaturesSettings(panelId!, mapSettingProperties(FormValue))
      } else {
        getTemperaturesSettings(panelId)
        addToast({
          type: 'success',
          time: 5000,
          title: t('toast_generic_success_title'),
          content: t('toast_person_preference_success_content'),
        })
        setDrawerState((prevValue) => ({ ...prevValue, isOpen: false }))
      }
    },
    onFailure: ({ message }) => {
      addToast({
        type: 'error',
        time: 5000,
        title: t('error_toast_generic_title'),
        content: isTranslationKey(temperatureSettingsReset!)
          ? t(temperatureSettingsReset)
          : t('error_toast_generic_content'),
      })
    },
  })

  const onSubmit = (values: TemperatureSettingsType) => {
    try {
      FormValue = values
      if (!temperatureSetting.liveappToggle && values.liveappToggle) {
        resetTemperaturesSettings(panelId!)
      } else if (temperatureSetting.liveappToggle && !values.liveappToggle) {
        resetTemperaturesSettings(panelId!)
      } else if (temperatureSetting.liveappToggle && values.liveappToggle) {
        postTemperaturesSettings(panelId!, mapSettingProperties(values))
      }
    } catch (error) {}
  }

  return (
    <div className="ml-auto">
      <button onClick={openDrawer('right')}>
        <SettingsIcon color={colors.blue} />
      </button>
      <Drawer
        isOpen={drawerState.isOpen}
        position={drawerState.position}
        handleClose={closeDrawer}
        closeButton={true}
        closeIcon={<CancelIcon color={colors.blue} />}
        title={
          <Header>
            <span className="mr-auto">{t('liveapp_settings')}</span>
          </Header>
        }
      >
        <TemperatureSettingsForm
          temperatureSetting={temperatureSetting}
          onSubmit={onSubmit}
          isLoading={loadingPostTemperaturesSettings || loadingTemperaturesReset}
        />
      </Drawer>
      <Toast />
    </div>
  )
}

const Header = styled.div`
  padding-bottom: 32px;
`

export default GeneralSettings
