import { useContext, useState } from 'react'
import Button from '../../../../components/Crow/Button/Button'
import Modal from '../../../../components/Crow/Modal'
import { Paragraph, Title } from '../../../../components/Crow/Typography'
import LoadPage from '../../../../components/LoadPage'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { useDisconnectFromWifi } from '../../../../services/requestHooks/wifi'
import { WifiDetails } from '../../../../types/WifiDetails'
import { logFirebaseEvent } from '../../../../utils/firebaseAnalytics'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../hooks/usePanelId'
import { ModalButtonContainer, ModalTitleContainer } from './StyledWifiComponents'

type ModalProps = {
  show: boolean
  onSubmit?: () => void
  onCancel?: () => void
  wifi?: WifiDetails
  WiFiCardSerial?: string | undefined
}

const DisconnectWifiModal = ({ show, onSubmit, onCancel, wifi, WiFiCardSerial }: ModalProps) => {
  const panelId = usePanelId()
  const { t } = useI18n()
  const { promptForTwoFactor } = useContext(TwofactorContext)
  const { run: disconnectFromWifi, isLoading, error } = useDisconnectFromWifi({
    onSuccess: () => {
      logFirebaseEvent('settings_wifi_disconnect_success')
      onSubmit?.()
    },
    onFailure: () => {
      logFirebaseEvent('settings_wifi_disconnect_failure')
    },
  })

  const onDisconnectClick = async () => {
    logFirebaseEvent('settings_wifi_disconnect_click')
    if (WiFiCardSerial) {
      const TwoFactorPin = await promptForTwoFactor()
      if (TwoFactorPin) {
        logFirebaseEvent('settings_wifi_disconnect_request_made')
        disconnectFromWifi({
          panelId,
          TwoFactorPin,
          ValidationCode: TwoFactorPin,
          WiFiCardSerial,
          WifiDetails: wifi,
        })
      }
    }
  }

  if (!show || !wifi) return null

  return (
    <Modal>
      <LoadPage isLoading={isLoading}>
        <ModalTitleContainer>
          <Title>{t('DISCONNECT')}</Title>
          <Paragraph>{`${t('DISCONNECT_QUESTION')} ${wifi.Name}`}</Paragraph>
        </ModalTitleContainer>
        <ModalButtonContainer>
          <Button level="primary" onClick={onDisconnectClick}>
            {t('Confirm')}
          </Button>
          <Button level="secondary" type="button" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </ModalButtonContainer>
        {!!error && <FailedRequest text={error} />}
      </LoadPage>
      {isLoading && (
        <Paragraph large>
          {t('wifi_disconnecting_from')} {wifi.Name}...
        </Paragraph>
      )}
    </Modal>
  )
}

export const useConfirmDisconnect = () => {
  const [modalState, setModalState] = useState<ModalProps>({ show: false, wifi: undefined })

  const promptForDisconnect = (wifi: WifiDetails) => {
    return new Promise<true | undefined>((resolve) => {
      setModalState({
        show: true,
        wifi,
        onSubmit: () => {
          setModalState({
            wifi: undefined,
            show: false,
          })
          resolve(true)
        },
        onCancel: () => {
          setModalState({ show: false, wifi: undefined })
          resolve(undefined)
        },
      })
    })
  }
  return {
    promptForDisconnect,
    modalState: modalState,
  }
}

export default DisconnectWifiModal
