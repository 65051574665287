const StairsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44 24H25V29H19V34H13V39H44V24Z" fill="#E3E1E1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3247 14L22.0048 16.5291V28H24.0048V23H43.0048C44.1093 23 45.0048 23.8954 45.0048 25V43C45.0048 44.1046 44.1093 45 43.0048 45H8.00477C6.9002 45 6.00477 44.1046 6.00477 43V38H8.00477V27.1944L4.60605 29.7836C4.16672 30.1182 3.53926 30.0334 3.20458 29.5941C2.8699 29.1547 2.95473 28.5273 3.39406 28.1926L24.3772 12.2076C24.5222 12.0971 24.6877 12.0323 24.8564 12.0109C24.9048 12.0037 24.9544 12 25.0048 12H44.0048C44.5571 12 45.0048 12.4477 45.0048 13C45.0048 13.5523 44.5571 14 44.0048 14L25.3247 14ZM16.0048 21.0999L20.0048 18.0527V28H18.0048V33H16.0048V21.0999ZM10.0048 25.6708L14.0048 22.6236V33H12.0048V38H10.0048V25.6708ZM43.0048 25H26.0048V28H43.0048V25ZM43.0048 30V33H20.0048V30H43.0048ZM43.0048 35H14.0048V38H43.0048V35ZM43.0048 40V43H8.00477V40H43.0048Z"
        fill="#333131"
      />
    </svg>
  )
}

export { StairsIcon }
