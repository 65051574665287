import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DoorLock, LockCommandPayload, LockSettingsPayload } from '../types/Lock'

type LocksState = Record<
  string,
  | {
      Locks: undefined | DoorLock[]
    }
  | undefined
>

const initialState: LocksState = {}

const lockSlice = createSlice({
  initialState,
  name: 'lock',
  reducers: {
    LOCK_COMMAND_SUCCESS(
      state,
      action: PayloadAction<{ data: LockCommandPayload; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = {
        Locks: state[panelId]?.Locks?.map((lock) => {
          return lock.Serial === data.LockSerial
            ? { ...lock, Status: 'lock', LastChanged: new Date().toISOString() }
            : lock
        }),
      }
    },
    UNLOCK_COMMAND_SUCCESS(
      state,
      action: PayloadAction<{ data: LockCommandPayload; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = {
        Locks: state[panelId]?.Locks?.map((lock) => {
          return lock.Serial === data.LockSerial
            ? { ...lock, Status: 'unlock', LastChanged: new Date().toISOString() }
            : lock
        }),
      }
    },
    GET_LOCK_STATUS_SUCCESS(state, action: PayloadAction<{ data: DoorLock[]; panelId: string }>) {
      const { data, panelId } = action.payload
      state[panelId] = { Locks: data }
    },

    SET_LOCK_SETTINGS_SUCCESS_CROW(
      state,
      action: PayloadAction<{ data: LockSettingsPayload; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = {
        Locks: state[panelId]?.Locks?.map((lock) => {
          return lock.Serial === data.Serial
            ? {
                ...lock,
                Label: data.Label,
                AutoLockEnabled: data.AutoLock,
                SoundLevel: data.SoundLevel,
              }
            : lock
        }),
      }
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  LOCK_COMMAND_SUCCESS,
  UNLOCK_COMMAND_SUCCESS,
  GET_LOCK_STATUS_SUCCESS,
  SET_LOCK_SETTINGS_SUCCESS_CROW,
} = lockSlice.actions

export default lockSlice.reducer
