import styled from 'styled-components'
import { TinyTemperatureIcon } from '../../../assets/icons/crow'
import { colors } from '../../../components/Crow/Style/theme'
import { Temperature } from '../../../types/Temperature'
import { Footnote } from '../../../components/Crow/Typography'

const TemperatureTileFooter = (props: { props: Temperature[] }) => {
  const devices = props.props

  const CountRemainderDevices = () => {
    if (devices.length < 4) {
      return null
    }

    return (
      <IconContainer className="w-6 h-6">
        <Footnote>{`+${devices.length - devices.slice(0, 3).length}`}</Footnote>
      </IconContainer>
    )
  }

  const SortedDevices = () => {
    const sortedDevices = ([] as Array<Temperature>)
      .concat(devices)
      .sort((a, b) => Number(a.Temperature) - Number(b.Temperature))
      .slice(0, 3)

    const renderItems = (property?: keyof Temperature) => {
      return (
        sortedDevices
          // .filter((device) => device.Temprature) // TODO: filter by device statuses
          .map((device) => (
            <IconContainer
              key={device.Id}
              color={device.LowBattery ? 1 : 0}
              role="listitem"
              className="w-6 h-6"
            >
              <TinyTemperatureIcon />
            </IconContainer>
          ))
      )
    }

    return (
      <SortedDevicesUl role="list" aria-label="devices">
        {renderItems()}
        <CountRemainderDevices />
      </SortedDevicesUl>
    )
  }

  return (
    <TileFooterContainer>
      <SortedDevices />
    </TileFooterContainer>
  )
}

const SortedDevicesUl = styled.ul`
  margin-top: 2px;
  display: flex;
  padding-left: 0;
  gap: 12px;
`

const IconContainer = styled.li<{ color?: any; border?: string }>`
  margin-top: 0;
  border-radius: 8px;
  background-color: ${({ color }) => (color ? colors.lightYellow : colors.black100)};
  border: ${({ border }) => (border ? border : border)};
  display: flex;
  align-items: center;
  justify-content: center;
`

const TileFooterContainer = styled.div`
  display: flex;
  align-items: center;
`

export default TemperatureTileFooter
