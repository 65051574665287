import { GET_HISTORY, GET_HISTORY_INCREMENTALLY } from '../../reducers/historySlice'
import { RootState } from '../../store'
import { getHistory, getNewHistory } from '../apiPanel'
import { createUseRequest } from './createUseRequest'

const selectPanelEvents = (state: RootState, panelId: string) => state.history[panelId]?.panelEvents

export const usePanelEvents = createUseRequest({
  successActionCreator: GET_HISTORY,
  apiCall: getHistory,
  selector: selectPanelEvents,
  requirePanelId: true,
})

export const useIncrementalPanelEvents = createUseRequest({
  successActionCreator: GET_HISTORY_INCREMENTALLY,
  apiCall: getNewHistory,
  selector: selectPanelEvents,
  requirePanelId: true,
})
