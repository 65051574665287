import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import BackButton from '../../../../components/BackButton'
import Button from '../../../../components/Crow/Button/Button'
import { colors } from '../../../../components/Crow/Style/theme'
import Toast, { addToast } from '../../../../components/Crow/Toast/Toast'
import { Paragraph, Title } from '../../../../components/Crow/Typography/Typography'
import { Loader } from '../../../../components/Elements/Loaders'
import FullscreenPage from '../../../../components/pages/FullscreenPage'
import { TcOrCrowContainer } from '../../../../components/TcOrCrowContainer'
import TwoFactorPrompt, {
  useTwoFactorPrompt,
} from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { useContacts, useSetContacts } from '../../../../services/requestHooks/contacts'
import { usePanelInfo } from '../../../../services/requestHooks/panelInfo'
import { ContactPerson, ContactPersonType } from '../../../../types/ContactPerson'
import { Capability } from '../../../../types/PanelModel'
import { useOnMount } from '../../../../utils/commonHooks'
import { usePanelId } from '../../../AlarmSystems/hooks/usePanelId'
import PasswordPrompt, { usePasswordPrompt } from '../../../AlarmSystems/Panel/PasswordPromt'
import DefaultAddContactButton from './AddContactButton'
import AddContactDrawer from './AddContactDrawer'
import DefaultSortableList from './SortableList'

const newContactPersons = (
  contacts: ContactPerson[],
  newContact: ContactPerson,
): ContactPerson[] => {
  let insertIndex
  const { RESIDENT, NEIGHBOR } = ContactPersonType
  if (newContact.Type === RESIDENT) {
    insertIndex = contacts.filter((c) => c.Type === ContactPersonType.RESIDENT).length
  } else if (newContact.Type === NEIGHBOR) {
    insertIndex = contacts.filter(
      (c) => c.Type === ContactPersonType.RESIDENT || c.Type === ContactPersonType.NEIGHBOR,
    ).length
  } else {
    // Type === OTHER
    insertIndex = contacts.length
  }

  return [...contacts.slice(0, insertIndex), newContact, ...contacts.slice(insertIndex)]
}

const computeIsReordered = (
  originalContacts: ContactPerson[] | undefined,
  currentContacts: ContactPerson[],
): boolean => {
  if (originalContacts === undefined) return false
  return originalContacts.some((val, idx) => val !== currentContacts[idx])
}

const CrowContactsPage = () => {
  const { t } = useI18n()
  const PanelId = usePanelId()
  const [addContactDrawerIsOpen, setAddContactDrawerIsOpen] = useState<boolean>(false)
  const [users, setUsers] = useState<ContactPerson[]>([])
  const [neighbors, setNeighbors] = useState<ContactPerson[]>([])
  const [emergencyContacts, setEmergencyContacts] = useState<ContactPerson[]>([])
  const [canChange, setCanChange] = useState<boolean>(false)
  const [action, setAction] = useState<string | undefined>(undefined)
  const [contactToBeRemoved, setContactToBeRemoved] = useState<ContactPerson | undefined>(undefined)
  const [peopleModuleNotEnabled, setPeopleModuleNotEnabled] = useState<boolean>(false)

  const returnUrl = `/systems/${PanelId}/people`

  const {
    run: getPanelInfo,
    data: panelInfo,
    isLoading: loadingPanelInfo,
    error: loadPanelInfoError,
  } = usePanelInfo()
  const { run, data: contacts, isLoading, hasLoaded, error: loadContactsError } = useContacts()
  const { run: reorderContactPersons, isLoading: isReorderingContactPersons } = useSetContacts({
    onSuccess: () => {
      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: 'Reorder contacts successful!',
      })
    },
  })
  const isReordering = action === 'reorder' && isReorderingContactPersons

  const { run: addContactPerson, isLoading: isAddingContactPerson } = useSetContacts({
    onSuccess: () => {
      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: 'Add new contact successful!',
      })
      setAddContactDrawerIsOpen(false)
    },
  })
  const isAdding = action === 'add' && isAddingContactPerson

  const { run: removeContactPerson, isLoading: isRemovingContactPerson } = useSetContacts({
    onSuccess: () => {
      addToast({
        type: 'success',
        time: 5000,
        title: t('toast_generic_success_title'),
        content: 'Remove contact successful!',
      })
    },
  })
  const isRemoving = action === 'remove' && isRemovingContactPerson

  const isReordered = useMemo(
    () => computeIsReordered(contacts, [...users, ...neighbors, ...emergencyContacts]),
    [contacts, users, neighbors, emergencyContacts],
  )

  const { promptState: twoFactorPromptState, promptForTwoFactor } = useTwoFactorPrompt()
  const { promptForPassword, promptState } = usePasswordPrompt()

  useOnMount(() => {
    const onLoad = async () => {
      const ValidationCode = await promptForTwoFactor(returnUrl)
      if (!ValidationCode) return
      run({ PanelId, ValidationCode })
    }
    onLoad()
    if (!loadingPanelInfo && PanelId !== panelInfo?.PanelId) {
      getPanelInfo(PanelId)
    }
    if (panelInfo?.Capabilities.includes(Capability.USE_LEGACY_PEOPLE)) {
      setPeopleModuleNotEnabled(true)
    }
  })

  const changeContacts = async (ContactPersons: ContactPerson[], requestFn: any) => {
    try {
      const Password = await promptForPassword()
      if (Password) {
        const ValidationCode = await promptForTwoFactor()
        if (ValidationCode) {
          requestFn({
            ContactPersons,
            PanelId,
            ValidationCode,
            Password,
          })
        }
      }
    } catch (error) {
      /* console.error('Failure') */
    }
  }

  const saveReorder = () => {
    setAction('reorder')
    const ContactPersons: ContactPerson[] = [...users, ...neighbors, ...emergencyContacts]
    changeContacts(ContactPersons, reorderContactPersons)
  }

  const addContact = (newContact: ContactPerson) => {
    if (contacts) {
      setAction('add')
      const ContactPersons: ContactPerson[] = newContactPersons(contacts, newContact)
      changeContacts(ContactPersons, addContactPerson)
    }
  }

  const removeContact = (contactToBeRemoved: ContactPerson) => {
    if (contacts) {
      setAction('remove')
      setContactToBeRemoved(contactToBeRemoved)
      const ContactPersons: ContactPerson[] = contacts.filter(
        (contact) => contact !== contactToBeRemoved,
      )
      changeContacts(ContactPersons, removeContactPerson)
    }
  }

  useEffect(() => {
    if (hasLoaded) {
      let loadedUsers: ContactPerson[] = []
      let loadedNeighbors: ContactPerson[] = []
      let loadedEmergencyContacts: ContactPerson[] = []
      contacts!.forEach((contact: ContactPerson) => {
        switch (contact.Type) {
          case ContactPersonType.RESIDENT:
            loadedUsers.push(contact)
            break
          case ContactPersonType.NEIGHBOR:
            loadedNeighbors.push(contact)
            break
          case ContactPersonType.OTHER:
            loadedEmergencyContacts.push(contact)
            break
        }
      })
      setUsers(loadedUsers)
      setNeighbors(loadedNeighbors)
      setEmergencyContacts(loadedEmergencyContacts)
    }
    // eslint-disable-next-line
  }, [hasLoaded])

  return (
    <TcOrCrowContainer panelInfo={panelInfo} fullscreen>
      <Page
        isLoading={(!contacts && isLoading) || loadingPanelInfo}
        loaderProps={{
          loadingText: { text: 'Loading' },
          errorHandling: {
            loadingFailed:
              (!isLoading && !!loadContactsError) ||
              (!loadingPanelInfo && !!loadPanelInfoError) ||
              peopleModuleNotEnabled,
            errorHeaderText: t('PEOPLE'),
            errorText: peopleModuleNotEnabled
              ? 'People module not enabled for this user'
              : 'Something went wrong. Please try again',
            returnUrl,
          },
        }}
      >
        <BackButton backUrl={returnUrl} />
        <Title large>
          {t('people_contacts_count', {
            postProcess: 'sprintf',
            sprintf: [contacts ? contacts.length : 0],
          })}
        </Title>
        <Description grey={true}>{t('people_contact_users_description_extended')}</Description>
        <AddContactButton onClick={() => setAddContactDrawerIsOpen(true)} disabled={false} />
        <Residents
          title={t('people_permanent_users_count', {
            postProcess: 'sprintf',
            sprintf: [contacts ? users.length : 0],
          })}
          description={t('people_contact_active_users_description')}
          items={users}
          setItems={setUsers}
          canChange={canChange}
          removeContact={removeContact}
          isRemoving={isRemoving}
          contactToBeRemoved={contactToBeRemoved}
        />
        <Neighbors
          title={t('people_contact_neighbours_count', {
            postProcess: 'sprintf',
            sprintf: [contacts ? neighbors.length : 0],
          })}
          description={t('people_contact_neighbours_description')}
          items={neighbors}
          setItems={setNeighbors}
          canChange={canChange}
          removeContact={removeContact}
          isRemoving={isRemoving}
          contactToBeRemoved={contactToBeRemoved}
        />
        <Others
          title={t('people_contact_emergency_count', {
            postProcess: 'sprintf',
            sprintf: [contacts ? emergencyContacts.length : 0],
          })}
          description={t('people_contact_emergency_decription')}
          items={emergencyContacts}
          setItems={setEmergencyContacts}
          canChange={canChange}
          removeContact={removeContact}
          isRemoving={isRemoving}
          contactToBeRemoved={contactToBeRemoved}
        />
        <ButtonRow>
          <ChangeButton
            type="button"
            level={canChange ? 'secondary' : 'primary'}
            size="l"
            onClick={() => setCanChange(!canChange)}
          >
            {canChange ? 'Cancel' : 'Change'}
          </ChangeButton>
          {canChange && (
            <SaveReorderButton
              type="button"
              level="primary"
              size="l"
              onClick={saveReorder}
              disabled={!isReordered || isReordering}
            >
              {isReordering ? <Loader /> : 'Save contacts order'}
            </SaveReorderButton>
          )}
        </ButtonRow>
        <AddContactDrawer
          isOpen={addContactDrawerIsOpen}
          handleClose={() => setAddContactDrawerIsOpen(false)}
          addContact={addContact}
          isAdding={isAdding}
        />
        <PasswordPrompt promptState={promptState} />
        <TwoFactorPrompt promptState={twoFactorPromptState} />
        <Toast />
      </Page>
    </TcOrCrowContainer>
  )
}

const Page = styled(FullscreenPage)`
  min-width: 600px;

  & > div {
    align-items: normal;
  }

  & .grid__half {
    flex: 1;
  }

  & .fullscreen__back:hover {
    color: ${colors.lightBlue};
  }
  & .fullscreen__back::before {
    border-color: ${colors.blue};
  }

  @media only screen and (max-width: 600px) {
    min-width: 0;
  }
`

const Description = styled(Paragraph)``

const AddContactButton = styled(DefaultAddContactButton)`
  margin-top: 16px;
`

const Residents = styled(DefaultSortableList)`
  margin-top: 22px;
`

const Neighbors = styled(DefaultSortableList)`
  margin-top: 42px;
`

const Others = styled(DefaultSortableList)`
  margin-top: 42px;
`

const ButtonRow = styled.div`
  margin-top: 60px;
  display: flex;
  gap: 24px;

  @media only screen and (max-width: 600px) {
    justify-content: center;
  }
`

const ChangeButton = styled(Button)``

const SaveReorderButton = styled(Button)``

export default CrowContactsPage
