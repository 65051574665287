import { format, formatRelative, isAfter, isValid, parseISO, subDays } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import React, { MouseEventHandler, useMemo } from 'react'
import styled from 'styled-components'
import { SmartplugIcon } from '../../../assets/icons/crow'
import { SmartplugOutdoorIcon } from '../../../assets/icons/crow/SmartplugOutdoorIcon'
import { colors } from '../../../components/Crow/Style/theme'
import Tile from '../../../components/Crow/Tile/Tile'
import Toggle from '../../../components/Crow/Toogle/Toggle'
import { Footnote, Heading3 } from '../../../components/Crow/Typography'
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay'
import useI18n from '../../../i18n/useI18n'
import { PanelStatus } from '../../../types/PanelModel'
import { useDateFnsLocale } from '../../../utils/useDateFnsLocale'

interface TimeDetails {
  status?: string
  formatDate?: string
  panelStatus?: PanelStatus
  TimeZoneName?: string
}
interface CrowSmartplugItemProps {
  id?: string
  text: string
  timeDetails: TimeDetails
  checked?: boolean
  onClick?: () => void
  onToggle?: MouseEventHandler<HTMLInputElement>
  isLoading?: boolean
  disabled?: boolean
  className?: string
  isOutdoor?: boolean
}

export const CrowSmartplugItem = ({
  id,
  text,
  timeDetails,
  checked,
  onClick,
  onToggle,
  isLoading,
  disabled,
  className,
  isOutdoor,
}: CrowSmartplugItemProps) => {
  const locale = useDateFnsLocale()
  const { t } = useI18n()

  const ActivityDate = useMemo(() => {
    if (timeDetails.panelStatus) {
      const today = new Date()
      const utcDateObject = new Date(parseISO(timeDetails.formatDate!))
      const isLessThanAWeekOld = isAfter(utcDateObject, subDays(today, 7))

      if (!isValid(utcDateObject)) {
        return null
      }

      const zonedDate = utcToZonedTime(utcDateObject, timeDetails.TimeZoneName!)
      if (isLessThanAWeekOld) {
        return formatRelative(zonedDate, today, {
          locale,
        })
      }
      return format(zonedDate, 'Pp', {
        locale,
      })
    }
  }, [timeDetails.TimeZoneName, timeDetails.formatDate, locale, timeDetails.panelStatus])

  const smartplugState = () => {
    if (timeDetails?.status === 'On') {
      return (
        t('on')
          .charAt(0)
          .toUpperCase() + t('on').slice(1)
      )
    }
    return (
      t('off')
        .charAt(0)
        .toUpperCase() + t('off').slice(1)
    )
  }

  return (
    <Tile onClick={onClick} disabled={disabled} className={className}>
      <ToggleContainer>
        <LoadingOverlay isLoading={isLoading!}>
          <Toggle key={id} onClick={onToggle} enabled={checked!} disabled={disabled} />
        </LoadingOverlay>
        <IconWrapper disabled={disabled}>
          {isOutdoor ? <SmartplugOutdoorIcon /> : <SmartplugIcon />}
        </IconWrapper>
      </ToggleContainer>
      <Title color={disabled ? colors.black600 : colors.black800}>{text}</Title>
      <TextSubContainer>
        {disabled ? (
          <Unreachable>{t('DISCONNECTED')}</Unreachable>
        ) : (
          <StatusText>{`${smartplugState()} ${t('SINCE')} ${ActivityDate}`}</StatusText>
        )}
      </TextSubContainer>
    </Tile>
  )
}

const Title = styled(Heading3)`
  margin-top: 16px;
  line-height: 22px;
  max-width: 138px;
  height: 44px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const StatusText = styled(Footnote)`
  margin-top: 3px;
  color: ${colors.black700};
`

const Unreachable = styled(Footnote)`
  margin-top: 8px;
  color: ${colors.red};
`

const ToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const IconWrapper = styled.span<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? colors.black500 : colors.black900)};
`

const TextSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 36px;
`
