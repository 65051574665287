import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Button from '../../../../components/Crow/Button/Button'
import { Paragraph } from '../../../../components/Crow/Typography'
import { Loader } from '../../../../components/Elements/Loaders'
import useI18n from '../../../../i18n/useI18n'
import { REQUEST_ERROR_RESET } from '../../../../reducers/requestSlice'
import { WifiDetails } from '../../../../types/WifiDetails'
import { AnimatedContainer } from './AnimatedContainer'
import { LoaderContainer } from './StyledWifiComponents'
import { WifiItem } from './WifiItem'

interface CrowWifiListProps {
  wifiList: WifiDetails[]
  onClick: (wifiDetails: WifiDetails) => void
  onCancel: () => void
  loading: boolean
}

export const CrowWifiList = ({ wifiList, onClick, onCancel, loading }: CrowWifiListProps) => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  return (
    <AnimatedContainer>
      <WifiListContainer>
        <Paragraph bold large>
          {t('AVAILABLE_NETWORKS')}
        </Paragraph>
        {loading && (
          <LoaderContainer>
            <Loader />
            <Paragraph>{t('updating_wifi')}</Paragraph>
          </LoaderContainer>
        )}
        {wifiList.map((wifiItem) => (
          <WifiItem
            key={wifiItem.Name}
            onClick={() => {
              onClick(wifiItem)
              dispatch(REQUEST_ERROR_RESET())
            }}
            wifiItem={wifiItem}
          />
        ))}
        <ButtonContainer>
          <Button onClick={onCancel} level="secondary">
            {t('Cancel')}
          </Button>
        </ButtonContainer>
      </WifiListContainer>
    </AnimatedContainer>
  )
}

const WifiListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  padding: 15px;

  & > *:first-child {
    align-self: flex-start;
    padding: 10px;
  }

  & > *:nth-child(n + 2) {
    margin-top: 8px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 20px;
`
