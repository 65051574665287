const ToiletIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 37V31H28C28 34.866 24.866 38 21 38C19.8954 38 19 38.8954 19 40V43C19 43.5523 18.5523 44 18 44H13C12.4477 44 12 43.5523 12 43V41C12 39.3431 10.6569 38 9 38H8C7.44772 38 7 37.5523 7 37Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 26C27.6569 26 29 27.3431 29 29V32H28.9381C28.446 35.9463 25.0796 39 21 39C20.4477 39 20 39.4477 20 40V43C20 44.1046 19.1046 45 18 45H13C11.8954 45 11 44.1046 11 43V41C11 39.8954 10.1046 39 9 39H8C6.89543 39 6 38.1046 6 37L6 32C4.89543 32 4 31.1046 4 30V14C4 12.8954 4.89543 12 6 12H11C12.1046 12 13 12.8954 13 14V26H26ZM11 14H6L6 30H11V18H9C8.44772 18 8 17.5523 8 17C8 16.4477 8.44772 16 9 16H11V14ZM13 30H27V29C27 28.4477 26.5523 28 26 28H13V30ZM8 32V37H9C11.2091 37 13 38.7909 13 41V43H18V40C18 38.3431 19.3431 37 21 37C23.973 37 26.441 34.8377 26.917 32H8Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42 21.5C42 23.433 41.1046 25 40 25V27C40 27.5523 39.5523 28 39 28H33C32.4477 28 32 27.5523 32 27V21C32 19.3431 33.3431 18 35 18H40C41.1046 18 42 19.567 42 21.5ZM38 20H35C34.4477 20 34 20.4477 34 21V26H38V20Z"
        fill="#333131"
      />
    </svg>
  )
}

export { ToiletIcon }
