import { CustomerFeedback } from '../reducers/customerFeedbackSlice'
import createHTTPClient from './createHTTPClient'

const HTTP = createHTTPClient('/')

export const sendCustomerFeedback = (payload: CustomerFeedback) =>
  HTTP.post('persons-feedback/feedback', payload)

export const getCustomerFeedbackStatus = (payload: { personId: string }) => {
  return HTTP.get(`persons-feedback/${payload.personId}/status`)
}

export const deleteCustomerFeedback = (payload: { personId: string }) =>
  HTTP.delete(`persons-feedback/${payload.personId}/feedback`)
