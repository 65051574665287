import { FirebasePerformance, PerformanceTrace, trace } from 'firebase/performance'

export type TraceKey = typeof traceKeys[number]
export const traceKeys = ['Arming_Slider_Ready'] as const
export let traces: Record<TraceKey, PerformanceTrace> | undefined = undefined

export function initializeTraces(firebasePerformance: FirebasePerformance) {
  function createTrace(name: string) {
    return trace(firebasePerformance, name)
  }

  const tempTraces: Record<string, PerformanceTrace> = {}

  traceKeys.forEach((key) => {
    tempTraces[key] = createTrace(key)
  })

  traces = tempTraces
}

function getStartsAndStops(key: TraceKey) {
  const trace = traces?.[key]

  // @ts-ignore-next-line
  const startMarkName = trace?.traceStartMark as string | undefined
  // @ts-ignore-next-line
  const stopMarkName = trace?.traceStopMark as string | undefined

  if (typeof startMarkName !== 'string' || typeof stopMarkName !== 'string') return {}

  const starts = performance.getEntriesByName(startMarkName)
  const stops = performance.getEntriesByName(stopMarkName)

  return { starts, stops }
}

function traceIsActive(key: TraceKey) {
  const { starts, stops } = getStartsAndStops(key)
  if (starts === undefined || stops === undefined) return false
  return starts.length > stops.length && stops.length === 0
}

function traceIsDone(key: TraceKey) {
  const { starts, stops } = getStartsAndStops(key)
  if (starts === undefined || stops === undefined) return false
  return starts.length === stops.length && stops.length > 0
}

function getTraceDuration(key: TraceKey) {
  const trace = traces?.[key]
  // @ts-ignore-next-line
  const measureName = trace?.traceMeasure as string | undefined
  if (typeof measureName !== 'string') return
  const measures = performance.getEntriesByName(measureName)
  if (measures.length === 0) return
  const lastMeasure = measures[measures.length - 1]
  return lastMeasure.duration
}

function isProduction() {
  const environment = process.env.REACT_APP_BUILDVERSION
  return environment?.toLowerCase() === 'production'
}

const consoleCSS = {
  name: `color: #454040; font-weight: 700;`,
  value: `color: #e31b64; font-weight: 900;`,
  subtle: `color: #aaaaaa;`,
}
const consoleDisclaimer = `%c | NB: Firebase trace logs are not shown in production`

export function startPerformanceTimer(key: TraceKey, logic?: (trace: PerformanceTrace) => void) {
  const trace = traces?.[key]
  if (traceIsDone(key) || traceIsActive(key) || trace === undefined) return
  trace.start()
  logic?.(trace)
  if (!isProduction()) {
    // eslint-disable-next-line no-console
    console.log(
      `⏳ %c${key}%c started at ${new Date().toISOString()}${consoleDisclaimer}`,
      consoleCSS.name,
      ``,
      consoleCSS.subtle,
    )
  }
}

export async function stopPerformanceTimer(
  key: TraceKey,
  /** Optional logic to manipulate firebase trace, e.g. to add custom attributes or metrics. */
  logic?: (trace: PerformanceTrace) => void,
) {
  if (traceIsDone(key)) return
  if (!traceIsActive(key)) return

  try {
    const trace = traces?.[key]
    if (trace === undefined) return

    logic?.(trace)

    trace.stop()

    if (!isProduction()) {
      // eslint-disable-next-line no-console
      console.log(`⌛️ %c${key}%c stopped at ${new Date().toISOString()}`, consoleCSS.name, ``)
    }

    const duration = getTraceDuration(key)

    if (!isProduction()) {
      const seconds = ((duration ?? 0) / 1000).toFixed(2)
      // eslint-disable-next-line no-console
      console.log(`⏱️ %c${key}%c took %c${seconds}s`, consoleCSS.name, ``, consoleCSS.value)
      // eslint-disable-next-line no-console
      console.log(`🕵️‍♂️ %c${key}%c complete trace:`, consoleCSS.name, ``, trace)
    }
    return duration
  } catch (e) {
    // @ts-ignore
    if (e?.name !== 'AbortError') throw e
  }
}
