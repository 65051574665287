const CellarIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26 11C21.0294 11 17 15.0294 17 20V44H22V40H26V36H30V32H34V28H44V20C44 15.0294 39.9706 11 35 11H26Z"
        fill="#E3E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 43C45 44.1046 44.1046 45 43 45H4C3.44772 45 3 44.5523 3 44C3 43.4477 3.44772 43 4 43H16V20C16 14.4772 20.4772 10 26 10H35C40.5228 10 45 14.4772 45 20V43ZM26 12H35C39.4183 12 43 15.5817 43 20V27H34C33.4477 27 33 27.4477 33 28V31H30C29.4477 31 29 31.4477 29 32V35H26C25.4477 35 25 35.4477 25 36V39H22C21.4477 39 21 39.4477 21 40V43H18V20C18 15.5817 21.5817 12 26 12ZM35 31H43V29H35V31ZM31 33V35H43V33H31ZM43 37V39H27V37H43ZM23 41V43H43V41H23Z"
        fill="#333131"
      />
    </svg>
  )
}

export { CellarIcon }
