import { FC } from 'react'
import * as yup from 'yup'
import ExpandableHelpTag from '../../../components/ExpandableHelpTag'
import SimpleForm, { Field } from '../../../components/forms/SimpleForm'
import {
  customerNumberValidator,
  phoneRequiredValidator,
} from '../../../components/forms/validators'
import useI18n from '../../../i18n/useI18n'

export type CustomerNrAndPhoneFormValues = Record<keyof typeof resolver.fields, string>

interface Props {
  onSubmit: (formValues: CustomerNrAndPhoneFormValues) => void
  isLoading: boolean
}

type FormField = Field<keyof typeof resolver.fields>

const CustomerLabel: FC = () => {
  const { t } = useI18n()
  return (
    <>
      {t('customer_number')}
      <span className="ml-2 inline-block">
        <ExpandableHelpTag
          className={'inline-block'}
          title={'Customer Number'}
          text={'Your customer number can be found on your invoice.'}
        />
      </span>
    </>
  )
}

const fields: FormField[] = [
  {
    name: 'customerNumber',
    label: CustomerLabel,
    type: 'text',
    placeholder: 'Customer Number',
  },
  { name: 'mobileNumber', label: 'Mobile phone', type: 'text', placeholder: 'Mobile phone' },
]

const resolver = yup.object({
  customerNumber: customerNumberValidator,
  mobileNumber: phoneRequiredValidator,
})

const CustomerNrAndPhoneForm: FC<Props> = ({ onSubmit, isLoading }) => {
  return (
    <SimpleForm isLoading={isLoading} resolver={resolver} onSubmit={onSubmit} fields={fields} />
  )
}

export default CustomerNrAndPhoneForm
