import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'
import useI18n from '../../../i18n/useI18n'
import { usePanelId } from '../../../pages/AlarmSystems/hooks/usePanelId'
import FailedRequest from '../../../pages/onboardingWizard/components/FailedRequest'
import { useValidateSMSCode } from '../../../services/requestHooks/validateSMSCode'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import Button from '../../Button'
import CancelButton from '../../Button/CancelButton'
import { colors } from '../../Crow/Style/theme'
import { Paragraph } from '../../Crow/Typography'
import TextField from '../../forms/TextField'
import { pinCodeValidator } from '../../forms/validators'

type FormValues = {
  ValidationCode: string
}

const resolver = yup.object({
  ValidationCode: pinCodeValidator,
})

type Props = {
  onSubmit: (validationCode: string) => void
  onCancel: () => void
  cancelUrl?: string
  panelIdOptional?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 18rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledParagraph = styled(Paragraph)`
  color: ${colors.grayShade};
`

const TextWrapper = styled.div`
  margin-top: 10px;
`

const ValidateSMSCode: FC<Props> = ({ onSubmit, onCancel, cancelUrl, panelIdOptional }) => {
  const panelId = usePanelId(panelIdOptional)
  const { t } = useI18n()
  const [invalidCode, setInvalidCode] = useState(false)

  const { run: validateSMSCode, isLoading, error } = useValidateSMSCode({
    onSuccess: ({ response }) => {
      onSubmit(response.ValidationCode)
    },
    onFailure: ({ message }) => {
      if (message === 'wrong_pincode') {
        setInvalidCode(true)
      }
    },
  })

  const submit = async ({ ValidationCode }: FormValues) => {
    validateSMSCode({ ValidationCode, PanelId: panelId })
  }

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(resolver),
  })

  const errorText: TranslationKey = invalidCode
    ? 'Wrong credentials provided to CP'
    : 'Something went wrong. Please try again'

  return (
    <Container>
      <form onSubmit={handleSubmit(submit)}>
        <StyledParagraph>
          {t('The code is sendt to account owner and are active for 30 minutes.')}
        </StyledParagraph>
        <TextWrapper>
          <TextField
            {...register('ValidationCode')}
            label="Enter pin code"
            errorKey={errors.ValidationCode?.message as TranslationKey}
          />
        </TextWrapper>
        {!!error && <FailedRequest align="left" text={errorText} />}
        <ButtonWrapper>
          <Button
            disabled={!isValid || isLoading}
            buttonType="primary"
            className="qa-smsvalidation-confirm"
          >
            {t('Confirm')}
          </Button>
          <CancelButton onClick={onCancel} isLoading={isLoading} />
        </ButtonWrapper>
      </form>
    </Container>
  )
}

export default ValidateSMSCode
