import cx from 'classnames'
import {
  ChangeEventHandler,
  ElementRef,
  forwardRef,
  ForwardRefRenderFunction,
  MouseEventHandler,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import useI18n from '../../i18n/useI18n'
import LoadingOverlay from '../LoadingOverlay'

type SwitchProps = {
  checked?: boolean
  name?: string
  id?: string
  testId?: string
  onClick?: MouseEventHandler<HTMLInputElement>
  isLoading?: boolean
  disabled?: boolean
  size?: 'large'
  position?: 'right' | 'center'
  className?: string
  hideText?: boolean
  unControlled?: boolean
  initialValue?: boolean
}

const noOp = () => {}
const Switch: ForwardRefRenderFunction<ElementRef<'input'>, SwitchProps> = (
  {
    checked,
    onClick,
    isLoading = false,
    disabled,
    size,
    position,
    name,
    id,
    className,
    hideText,
    testId,
    initialValue,
    unControlled = false,
    ...rest
  },
  ref,
) => {
  const { t } = useI18n()
  // Only used when not controlled by "checked" field but uses ref instead
  const [unControlledChecked, setUnControlledChecked] = useState(initialValue)
  const handleChange: ChangeEventHandler<HTMLInputElement> = () => {
    setUnControlledChecked(!unControlledChecked)
  }

  const isActuallyChecked = unControlled ? unControlledChecked : checked

  return (
    <LoadingOverlay isLoading={isLoading} className={className}>
      <div className={cx('switch', size && `switch--${size}`, position && `switch--${position}`)}>
        <label className="switch__label">
          <input
            type="checkbox"
            onChange={unControlled ? handleChange : noOp}
            checked={checked}
            onClick={ref ? undefined : onClick}
            ref={ref}
            name={name}
            id={id}
            data-testid={testId}
            readOnly
            disabled={isLoading || disabled}
            {...rest}
          />

          <SwitchBackground className={`switch__bg ${isActuallyChecked ? 'checked' : 'unchecked'}`}>
            {isActuallyChecked && (
              <span className="switch__text-on">{hideText ? '' : t('on')}</span>
            )}
            {!isActuallyChecked && (
              <span className="switch__text-off">{hideText ? '' : t('off')}</span>
            )}
          </SwitchBackground>
          <div className={`switch__indicator ${isActuallyChecked ? 'right' : 'left'}`}></div>
        </label>
      </div>
    </LoadingOverlay>
  )
}

const SwitchBackground = styled.div<{ checkBoxWidth?: number }>`
  ${({ checkBoxWidth }) =>
    checkBoxWidth &&
    css`
      width: ${checkBoxWidth}px;
    `}
`

export default forwardRef(Switch)
