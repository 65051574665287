import styled from 'styled-components'
import { SmileyIcon } from '../../../assets/icons/crow'
import useI18n from '../../../i18n/useI18n'
import Toggle from '../../Crow/Toogle/ToggleForForm'

interface Props {
  register: any
}

const Settings = ({ register }: Props) => {
  const { t } = useI18n()

  return (
    <Wrapper>
      <Title>{t('user_settings')}</Title>
      <Fields>
        <Toggle
          text={t('underage')}
          subText={t('is the person a child')}
          Icon={<SmileyIcon />}
          {...register('IsChild')}
        />
      </Fields>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-top: 26px;
`

const Title = styled.h2`
  font-family: 'Metric';
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
`

const Fields = styled.div`
  margin-top: 8px;
`

export default Settings
