const TemperatureIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ? props.width : '12'}
      height={props.height ? props.height : '24'}
      viewBox="0 0 12 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 14.9287V8.25C5 7.69772 5.44772 7.25 6 7.25C6.55228 7.25 7 7.69772 7 8.25V14.9287C8.09482 15.3349 8.875 16.3888 8.875 17.625C8.875 19.2128 7.58782 20.5 6 20.5C4.41218 20.5 3.125 19.2128 3.125 17.625C3.125 16.3888 3.90518 15.3349 5 14.9287ZM6 16.75C5.51675 16.75 5.125 17.1418 5.125 17.625C5.125 18.1082 5.51675 18.5 6 18.5C6.48325 18.5 6.875 18.1082 6.875 17.625C6.875 17.1418 6.48325 16.75 6 16.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 13.3217V4.5C10 3.43913 9.57857 2.42172 8.82843 1.67157C8.07828 0.921428 7.06087 0.5 6 0.5C4.93913 0.5 3.92172 0.921427 3.17157 1.67157C2.42143 2.42172 2 3.43913 2 4.5V13.3217C1.23207 14.0356 0.666774 14.9443 0.367133 15.9555C0.0195727 17.1283 0.046769 18.3804 0.444927 19.537C0.843084 20.6936 1.59234 21.6971 2.58818 22.4075C3.58401 23.1179 4.77675 23.4997 6 23.4997C7.22325 23.4997 8.41599 23.1179 9.41182 22.4075C10.4077 21.6971 11.1569 20.6936 11.5551 19.537C11.9532 18.3804 11.9804 17.1283 11.6329 15.9555C11.3332 14.9443 10.7679 14.0356 10 13.3217ZM6 2.5C5.46957 2.5 4.96086 2.71071 4.58579 3.08579C4.21071 3.46086 4 3.96957 4 4.5V13.7836C4 14.113 3.83873 14.4143 3.57966 14.5986C2.9618 15.0927 2.5097 15.7645 2.2847 16.5237C2.05546 17.2973 2.0734 18.1231 2.33602 18.886C2.59863 19.6489 3.09282 20.3108 3.74965 20.7793C4.40648 21.2479 5.19318 21.4997 6 21.4997C6.80682 21.4997 7.59352 21.2479 8.25035 20.7793C8.90718 20.3108 9.40137 19.6489 9.66398 18.886C9.9266 18.1231 9.94454 17.2973 9.71529 16.5237C9.4903 15.7645 9.0382 15.0927 8.42034 14.5986C8.16127 14.4143 8 14.113 8 13.7836V4.5C8 3.96957 7.78929 3.46086 7.41421 3.08579C7.03914 2.71071 6.53043 2.5 6 2.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { TemperatureIcon }
