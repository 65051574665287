import React, { ForwardRefRenderFunction, ElementRef, forwardRef } from 'react'
import styled from 'styled-components'
import { colors } from '../Style/theme'
import { Paragraph } from '../Typography'

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-style: solid;
  border-color: #e3e1e1;
  border-width: 1px;
  border-radius: 12px;
  height: 64px;
  margin-top: 8px;
`
const RadioButtonContainer = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;
  padding-left: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 35px;
  width: 100%;
  height: 100%;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ span {
    border: 2px solid #66a8d8;
  }

  input:checked ~ span {
    border: 2px solid #66a8d8;
  }

  input:checked ~ span:after {
    display: block;
  }
`

const Checkmark = styled.span`
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border: 2px solid #807c7c;
  border-radius: 50%;

  &:after {
    content: '';
    display: none;
  }

  &:after {
    justify-content: center;
    margin-top: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #66a8d8;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`

const Text = styled(Paragraph)`
  color: ${colors.black800};
  font-size: 20px;
`

const SubText = styled(Paragraph)`
  font-size: 16px;
  line-height: 18px;
  color: ${colors.black700};
`

interface RadioButtonProps {
  value: string | number
  title: string
  subTitle?: string
  name: string
  defaultChecked?: boolean
  id: string
  onChange?: any
}

const CrowRadioButton: ForwardRefRenderFunction<ElementRef<'input'>, RadioButtonProps> = (
  { value, title, subTitle, name, defaultChecked, id, onChange },
  ref,
) => {
  return (
    <RadioButtonWrapper>
      <RadioButtonContainer htmlFor={id}>
        <input
          ref={ref}
          type="radio"
          name={name}
          defaultChecked={defaultChecked}
          onChange={onChange}
          id={id}
          value={value}
        />
        <Checkmark />
        <TextContainer>
          <Text>{title}</Text>
          {subTitle && <SubText>{subTitle}</SubText>}
        </TextContainer>
      </RadioButtonContainer>
    </RadioButtonWrapper>
  )
}

export default forwardRef(CrowRadioButton)
