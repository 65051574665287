import { FC, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { CancelIcon, CheckmarkIcon } from '../../../../assets/icons/crow'
import BackButton from '../../../../components/BackButton'
import Drawer from '../../../../components/Crow/Drawer/Drawer'
import { colors } from '../../../../components/Crow/Style/theme'
import { Paragraph, SubTitle, Title } from '../../../../components/Crow/Typography'
import FullscreenPage from '../../../../components/pages/FullscreenPage'
import { displayNewNavigationMenu, displayNewSettings } from '../../../../configs'
import useI18n, { useIrishCheck } from '../../../../i18n/useI18n'
import { useUserInfo } from '../../../../services/requestHooks/user'
import { useGetPanelPermissions } from '../../../../services/requestHooks/users'
import { TranslationKey } from '../../../../types/generated/TranslationKey'
import { Capability, PanelModel } from '../../../../types/PanelModel'
import { logBackEvent } from '../../../../utils/firebaseAnalytics'
import OnboardingWizardPage from '../../../onboardingWizard/OnboardingWizardPage'
import CrowSecurityQuestion from '../SecurityQuestion/CrowSecurityQuestion'
import { usePanelId } from '../../hooks/usePanelId'
import AddSmartPlugPage from '../AddSmartplug/AddSmartplugPage'
import AppUsersPage from '../AppUsers/AppUserPage'
import { VideoPrivacyConsent } from '../Consent/ConsentWrapper'
import LocationAndDirectons from '../Directions/LocationAndDirections'
import Notifications from '../Notifications/Notifications'
import PanelUsersPage from '../PanelUsers/PanelUsersPage'
import QuickArming from '../QuickArming/QuickArming'
import SystemNameChange from '../SystemSettings/SystemNameChange'
import DarkFullScreenSuccess from './DarkFullScreenSuccess'
import SettingMenuItemCrow from './SettingMenuItemCrow'
import SettingMenuItem from './SettingsItem'
import { deviceMenuItems, MenuItemMap, panelMenuItems, userMenuItems } from './SettingsMenuItems'
import SettingsPeopleInfo from './SettingsPeopleInfo'

type Props = {
  panelInfo?: PanelModel
  loading?: boolean
}

const SettingsMenu: FC<Props> = ({ panelInfo, loading }) => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const User = useUserInfo()

  const {
    run: getPanelPermissions,
    isLoading: loadingPanelPermissions,
    error: panelPermissionsError,
    data: panelPermissions,
  } = useGetPanelPermissions()

  const newSettingsMenu =
    !panelInfo?.Capabilities?.includes(Capability.USE_LEGACY_HOMESCREEN) && displayNewSettings

  useEffect(() => {
    if (
      !panelInfo?.Capabilities.includes(Capability.USE_LEGACY_PEOPLE) &&
      !panelInfo?.Capabilities.includes(Capability.USE_LEGACY_HOMESCREEN)
    ) {
      getPanelPermissions()
    }
    /* eslint-disable-next-line */
  }, [getPanelPermissions])

  const menuCategories: {
    title: TranslationKey
    items: MenuItemMap
    newTitle?: TranslationKey
  }[] = [
    { title: 'Users', items: userMenuItems },
    { title: 'System', newTitle: 'change_system_name', items: panelMenuItems },
    { title: 'WIFI_INFO_TEXT', items: deviceMenuItems },
  ]

  const { checkIrish } = useIrishCheck()

  const visibleMenuItems = menuCategories
    .filter(
      ({ title }) =>
        panelInfo?.Capabilities.includes(Capability.USE_LEGACY_PEOPLE) ||
        panelInfo?.Capabilities.includes(Capability.USE_LEGACY_HOMESCREEN) ||
        title !== 'Users',
    )
    .map(({ items, title, newTitle }) => ({
      title: newTitle ? newTitle : title,
      items: Object.entries(items)
        .filter(([_, { visibleCondition }]) =>
          visibleCondition(panelInfo || {}, User, newSettingsMenu),
        )
        .filter(([_, { requiresCRM }]) => !requiresCRM || User.NationHasCRM)
        .filter(([_, { accessKey }]) => !accessKey || panelInfo?.Access.includes(accessKey)),
    }))
    .filter(({ items }) => Object.entries(items).length > 0)

  const returnUrl = `/systems/${panelId}`

  const [drawerState, setDrawerState] = useState<{
    isOpen: boolean
    position: 'top' | 'bottom' | 'left' | 'right'
  }>({ isOpen: false, position: 'right' })

  const [settingsMenuItemTitle, setSettingsMenuItemTitle] = useState<TranslationKey>()

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const openDrawer = (
    position: 'top' | 'bottom' | 'left' | 'right',
    title: TranslationKey,
  ) => () => {
    setSettingsMenuItemTitle(title)
    setDrawerState({
      position,
      isOpen: true,
    })
  }

  const closeDrawer = () => {
    setDrawerState((prevValue) => ({ ...prevValue, isOpen: false }))
  }

  const [showSuccessScreen, setShowSuccessScreen] = useState(false)

  let DrawerContent = (title: TranslationKey) => {
    const baseUrl = `/systems/${panelId}/settings`

    switch (t(title)) {
      // Users
      case t('Users and codes'):
        return <PanelUsersPage />
      case t('APP_USERS_SETTING_MENU'):
        return <AppUsersPage />
      case t('Contact persons'):
        return <Redirect to={`${baseUrl}/contacts`} />
      // Alarm system
      case t('change_system_name'):
        return (
          <SystemNameChange
            displayName={panelInfo?.DisplayName!}
            quickArmEnabled={panelInfo?.QuickArmEnabled!}
            permissions={panelPermissions?.Accesses}
          />
        )
      case t('Security question'):
        return <CrowSecurityQuestion />
      case t('New system onboarding'):
        return <OnboardingWizardPage />
      case t('SETTINGS_QUICK_ARMING'):
        return (
          <QuickArming
            displayName={panelInfo?.DisplayName!}
            quickArmEnabled={panelInfo?.QuickArmEnabled!}
            permissions={panelPermissions?.Accesses}
          />
        )
      case t('NOTIFICATIONS_SETTINGS'):
        return <Notifications />
      case t('Location settings'):
        return <LocationAndDirectons permissions={panelPermissions?.Accesses!} />
      // WiFi
      case t('SETTINGS_ADD_SMART_PLUG'):
        return <AddSmartPlugPage />
      case t('WIFI_SETTINGS'):
        return <Redirect to={`${baseUrl}/wifi`} />
      case t('video_consent_modal_title'):
        return <VideoPrivacyConsent />
    }
    return null
  }

  return (
    <>
      {!showSuccessScreen ? (
        <FullscreenPage
          isLoading={loading || loadingPanelPermissions}
          loaderProps={{
            loadingText: { text: 'Loading settings' },
            errorHandling: {
              errorHeaderText: 'Settings',
              errorText: 'Something went wrong. Please try again',
              loadingFailed: !!panelPermissionsError,
              returnUrl: `/systems/${panelId}`,
            },
          }}
        >
          <>
            {(!displayNewNavigationMenu ||
              panelInfo?.Capabilities.includes(Capability.USE_LEGACY_HOMESCREEN)) && (
              <BackButton backUrl={returnUrl} onClick={() => logBackEvent('settings')} />
            )}
            <SettingsTitle>{t('Settings')}</SettingsTitle>
            <>
              {visibleMenuItems.map(({ items, title }) => {
                return (
                  <div key={title}>
                    {newSettingsMenu && t(title) === t('change_system_name') ? (
                      <SettingsSubtitle light>{panelInfo?.DisplayName!}</SettingsSubtitle>
                    ) : (
                      <SettingsSubtitle light>{t(title)}</SettingsSubtitle>
                    )}
                    <ul className="settings" id={title}>
                      {items.map(([url, { svg, title, irishTitle, description, newTitle }]) => {
                        if (newSettingsMenu) {
                          let titleToUse = newTitle ? newTitle : title
                          return (
                            <SettingMenuItemCrow
                              id={title}
                              key={url}
                              url={url}
                              title={irishTitle ? checkIrish(titleToUse, irishTitle) : titleToUse}
                              chevron
                              onClick={openDrawer('right', titleToUse)}
                            />
                          )
                        } else {
                          return (
                            <SettingMenuItem
                              svg={svg}
                              key={url}
                              url={url}
                              title={irishTitle ? checkIrish(title, irishTitle) : title}
                              description={description}
                            />
                          )
                        }
                      })}
                    </ul>
                  </div>
                )
              })}
            </>

            <Drawer
              isOpen={drawerState.isOpen}
              position={drawerState.position}
              handleClose={closeDrawer}
              closeButton={true}
              closeIcon={<CancelIcon color={colors.blue} />}
              closeButtonClassName={'qa-settings-sidedrawer-close'}
              title={t(settingsMenuItemTitle!)}
            >
              <DrawerContentWrapper>{DrawerContent(settingsMenuItemTitle!)}</DrawerContentWrapper>
            </Drawer>
            {!panelInfo?.Capabilities.includes(Capability.USE_LEGACY_HOMESCREEN) && (
              <SettingsPeopleInfo panelId={panelId} />
            )}
          </>
        </FullscreenPage>
      ) : (
        <StyledDarkFullScreenSuccess
          title={t('security_question_updated')}
          subtitle={<Paragraph light>{t('security_question_success_subtitle')}</Paragraph>}
          icon={<Circle>{<CheckmarkIcon color={colors.green} />}</Circle>}
          close={() => setShowSuccessScreen(!showSuccessScreen)}
        />
      )}
    </>
  )
}

const DrawerContentWrapper = styled.div`
  margin-top: 48px;
`

const SettingsTitle = styled(Title)`
  margin: 0px;
  margin-top: 16px;
  margin-bottom: 42px;
`

const StyledDarkFullScreenSuccess = styled(DarkFullScreenSuccess)`
  color: ${colors.green};
  height: 100vh;
`

const Circle = styled.div`
  height: 50px;
  width: 50px;
  border: 1px solid ${colors.green};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SettingsSubtitle = styled(SubTitle)`
  margin-top: 18px;
  margin-left: 16px;
`

export default SettingsMenu
