import classNames from 'classnames'
import { FC } from 'react'
import styled from 'styled-components'
import FullScreenClose from '../Elements/FullScreenClose'
import { LoaderProps } from '../Elements/Loaders'
import LoadPage from '../LoadPage'

type Props = {
  returnURL?: string
  isLoading?: boolean
  loaderProps?: LoaderProps
  className?: string
}

const FullscreenPage: FC<Props> = ({ isLoading, loaderProps, returnURL, children, className }) => {
  return (
    <div className={classNames('w-full', { [`${className}`]: className })}>
      {returnURL && <FullScreenClose returnURL={returnURL} />}
      <GridHalf>
        <StyledLoadPage isLoading={isLoading} loaderProps={loaderProps}>
          {children}
        </StyledLoadPage>
      </GridHalf>
    </div>
  )
}

const GridHalf = styled.div`
  margin-right: auto;
  width: 100%;
  padding: 1rem;
  padding-bottom: 32px;
  @media only screen and (max-width: 767px) {
    margin-top: 26px;
  }
`

const StyledLoadPage = styled(LoadPage)`
  height: 100vh;
  @media only screen and (max-width: 768px) {
    padding-bottom: 100px;
  }
`

export default FullscreenPage
