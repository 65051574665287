import styled from 'styled-components'
import { SmokeDetectorIcon } from '../../../assets/icons/crow'
import { colors } from '../../../components/Crow/Style/theme'
import { Device } from '../../../types/SmokeDetectors'
import { Footnote } from '../../../components/Crow/Typography'

const SmokeDetectorsTileFooter = (props: { props: Device[] }) => {
  const devices = props.props

  const CountRemainderDevices = () => {
    if (devices.length < 4) {
      return null
    }

    return (
      <IconContainer className="w-6 h-6">
        <Footnote>{`+${devices.length - devices.slice(0, 3).length}`}</Footnote>
      </IconContainer>
    )
  }

  const SortedDevices = () => {
    const sortedDevices = ([] as Array<Device>)
      .concat(devices)
      .sort((a, b) =>
        b.Alarm === a.Alarm
          ? Number(b.LowBattery) - Number(a.LowBattery)
          : Number(b.Alarm) - Number(a.Alarm),
      )
      .slice(0, 3)

    // TODO: More cases for: "outlined red" = verification failure (no backend)
    const renderItems = (property?: keyof Device) => {
      if (property === 'Alarm') {
        return sortedDevices
          .filter((device) => device.Alarm)
          .map((device) => (
            <IconContainer
              key={device.Id}
              role="listitem"
              className="w-6 h-6"
              color={colors.lightRed}
            >
              <SmokeDetectorIcon width="14px" color={colors.white} />
            </IconContainer>
          ))
      } else if (property === 'LowBattery') {
        return sortedDevices
          .filter((device) => device.LowBattery && !device.Alarm)
          .map((device) => (
            <IconContainer
              key={device.Id}
              role="listitem"
              className="w-6 h-6"
              color={colors.yellow}
            >
              <SmokeDetectorIcon width="14px" />
            </IconContainer>
          ))
      } else {
        return sortedDevices
          .filter((device) => !device.Alarm && !device.LowBattery)
          .map((device) => (
            <IconContainer key={device.Id} role="listitem" className="w-6 h-6">
              <SmokeDetectorIcon width="14px" />
            </IconContainer>
          ))
      }
    }

    return (
      <SortedDevicesUl role="list" aria-label="devices">
        {renderItems('Alarm')}
        {renderItems('LowBattery')}
        {renderItems()}
        <CountRemainderDevices />
      </SortedDevicesUl>
    )
  }

  return (
    <TileFooterContainer>
      <SortedDevices />
    </TileFooterContainer>
  )
}

const SortedDevicesUl = styled.ul`
  margin-top: 2px;
  display: flex;
  padding-left: 0;
  gap: 12px;
`

const IconContainer = styled.li<{ color?: string; border?: string }>`
  margin-top: 0;
  border-radius: 8px;
  background-color: ${({ color }) => (color ? color : colors.black100)};
  border: ${({ border }) => (border ? border : border)};
  display: flex;
  align-items: center;
  justify-content: center;
`

const TileFooterContainer = styled.div`
  display: flex;
  align-items: center;
`

export default SmokeDetectorsTileFooter
