import styled from 'styled-components'

import BackButton from '../../../components/BackButton'
import { Title } from '../../../components/Crow/Typography/Typography'
import { displayNewNavigationMenu } from '../../../configs'
import useI18n from '../../../i18n/useI18n'
import { usePanelEvents } from '../../../services/requestHooks/history'
import { usePanelInfo } from '../../../services/requestHooks/panelInfo'
import { Capability } from '../../../types/PanelModel'
import { useOnMount } from '../../../utils/commonHooks'
import { logBackEvent } from '../../../utils/firebaseAnalytics'
import { usePanelId } from '../hooks/usePanelId'
import EventsHasErrorOrEmpty from './EmptyEvents'
import EndOfEvents from './EndOfEvents'
import EventList from './EventList'
import EventListTitle from './EventListTitle'
import EventSkeletons from './EventSkeletons'
import { StyledParagraphNoEvents as NoEvents } from './HistoryStyle'

const History = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const {
    run: loadPanelEvents,
    data: panelEvents,
    isLoading: loadingPanelEvents,
    error: panelEventsError,
  } = usePanelEvents()
  const { data: panelInfo } = usePanelInfo()

  useOnMount(() => {
    if (!loadingPanelEvents) {
      loadPanelEvents(panelId)
    }
  })

  const showBackButton =
    panelInfo?.Capabilities.includes(Capability.USE_LEGACY_HOMESCREEN) || !displayNewNavigationMenu
  const returnUrl = `/systems/${panelId}`

  return (
    <Wrapper>
      {showBackButton && <BackButton backUrl={returnUrl} onClick={() => logBackEvent('history')} />}
      <Title large>{t('History')}</Title>
      <EventsHasErrorOrEmpty
        panelEvents={panelEvents}
        panelEventsError={panelEventsError ? panelEventsError : ''}
      />
      {loadingPanelEvents ? (
        <EventSkeletons />
      ) : (
        <>
          {panelEvents?.map(([timestamp, events]) => (
            <EventGroup key={timestamp}>
              <EventListTitle timestamp={timestamp} />
              {events.length === 0 ? (
                // TODO: This will never actually be the case?
                <NoEvents large grey>
                  {t('No events occured for this day')}
                </NoEvents>
              ) : (
                <EventList events={events} />
              )}
            </EventGroup>
          ))}
          <EndOfEvents isTCPanel />
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;
`

const EventGroup = styled.div`
  margin-top: 3rem;
`

export default History
