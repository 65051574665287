const SignOutIcon = (props: React.SVGProps<SVGSVGElement> & { className?: string }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99938 0C8.56043 0 9.01525 0.473654 9.01525 1.05794V7.33943C9.01525 7.92371 8.56043 8.39737 7.99938 8.39737C7.43833 8.39737 6.98351 7.92371 6.98351 7.33943V1.05794C6.98351 0.473654 7.43833 0 7.99938 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.04178 0.99571C5.34782 1.48541 5.21472 2.14076 4.74448 2.45947C3.66617 3.19032 2.8435 4.26448 2.3991 5.52185C1.95469 6.77923 1.91234 8.15249 2.27836 9.43697C2.64438 10.7215 3.39917 11.8484 4.43023 12.6498C5.46129 13.4512 6.71342 13.8841 7.99999 13.8841C9.28656 13.8841 10.5387 13.4512 11.5698 12.6498C12.6008 11.8484 13.3556 10.7215 13.7216 9.437C14.0877 8.15252 14.0453 6.77925 13.6009 5.52188C13.1565 4.2645 12.3338 3.19034 11.2555 2.45949C10.7853 2.14077 10.6522 1.48543 10.9582 0.995723C11.2643 0.506021 11.8936 0.367406 12.3638 0.686118C13.8092 1.66577 14.9119 3.10561 15.5076 4.79103C16.1033 6.47645 16.16 8.31721 15.6694 10.039C15.1788 11.7607 14.167 13.2712 12.785 14.3454C11.4029 15.4197 9.72454 16 7.99998 16C6.27543 16 4.59705 15.4196 3.21499 14.3454C1.83293 13.2712 0.82119 11.7607 0.33057 10.0389C-0.160049 8.31717 -0.103282 6.47641 0.492409 4.79099C1.0881 3.10557 2.19082 1.66574 3.63622 0.686098C4.10645 0.367389 4.73575 0.506007 5.04178 0.99571Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { SignOutIcon }
