import { motion } from 'framer-motion'
import React from 'react'

interface AnimatedContainerProps {
  children: React.ReactNode
}

export const AnimatedContainer = ({ children }: AnimatedContainerProps) => (
  <motion.div
    initial={{
      opacity: 0.2,
      x: 100,
    }}
    animate={{
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        damping: 30,
        mass: 1,
        stiffness: 180,
      },
    }}
  >
    {children}
  </motion.div>
)
