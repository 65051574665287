const ShowRoomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 17.9856C45.7399 17.8608 46.1019 16.9523 45.585 16.3492L41.2993 11.3492C41.1093 11.1276 40.832 11 40.5401 11H4C3.44772 11 3 11.4477 3 12V44C3 44.5523 3.44772 45 4 45H44C44.5523 45 45 44.5523 45 44V17.9856ZM5 18V43H6V25C6 24.4477 6.44772 24 7 24H19C19.5523 24 20 24.4477 20 25V43H43V40H22C21.4477 40 21 39.5523 21 39V20C21 19.4477 21.4477 19 22 19H43V18H5ZM42.6516 16L40.0801 13H5V16H42.6516ZM43 21H40V23.3435L41.1413 24.2052C42.0229 24.8707 42.1981 26.1248 41.5325 27.0064L40.095 28.9106C39.8625 29.2187 39.7284 29.5899 39.7106 29.9755C39.6394 31.5136 37.8737 32.3426 36.6448 31.4149L36.1317 31.0275C35.6917 31.4112 34.9337 31.3844 34.3364 30.9336C33.7392 30.4827 33.5056 29.761 33.7542 29.2326L33.1002 28.7389C31.8713 27.8112 32.1848 25.886 33.6446 25.3961C34.0106 25.2733 34.3309 25.0427 34.5635 24.7347L36.001 22.8305C36.4812 22.1944 37.2679 21.9261 38 22.076V21H23V38H43V21ZM18 43V26H8V43H18ZM38 24.3396V25C38 25.5523 38.4477 26 39 26C39.2989 26 39.5671 25.8689 39.7504 25.661L39.9363 25.8014L38.4988 27.7055C38.0555 28.2928 37.7873 28.9923 37.7234 29.7232L34.4317 27.2382C35.1171 26.9765 35.7163 26.527 36.1597 25.9397L37.5972 24.0355L38 24.3396Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43 19V18H5V43H6V25C6 24.4477 6.44772 24 7 24H19C19.5523 24 20 24.4477 20 25V43H43V40H22C21.4477 40 21 39.5523 21 39V20C21 19.4477 21.4477 19 22 19H43ZM40.0801 13L42.6516 16H5V13H40.0801ZM18 26V43H8V26H18Z"
        fill="#E3E1E1"
      />
    </svg>
  )
}

export { ShowRoomIcon }
