import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { CancelIcon, CheckmarkIcon } from '../../../../assets/icons/crow'
import Button from '../../../../components/Crow/Button/Button'
import { colors } from '../../../../components/Crow/Style/theme'
import { Hr, Paragraph, SubTitle } from '../../../../components/Crow/Typography'
import { Loader } from '../../../../components/Elements/Loaders'
import { ModalPromptProps } from '../../../../components/Modal/Modal'
import TwoFactorPrompt from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { PanelModel } from '../../../../types/PanelModel'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import RemoveVideoControlModal from './RemoveVideoControlModal'
import VideoControlModal from './VideoControlModal'
import VideoPrivacyConsentNoAccess from './VideoPrivacyConsentNoAccess'
import { IconContainer, StyledSelectButton, StyledSpan } from './VideoPrivacyConsentStyle'
type Props = {
  toggleConsent: () => Promise<void>
  panelInfo: PanelModel | undefined
  isLoadingArcConsent: boolean
  arcConsentError: string | undefined
  videoControlState: ModalPromptProps<true>
  removeVideoControlState: ModalPromptProps<true>
  twoFactorPromptState: {
    onSubmit: ((validationCode: string) => void) | undefined
    onCancel: (() => void) | undefined
  }
  toggle: boolean
  setToggle: Dispatch<SetStateAction<boolean>>
}

const NewConsentContent = ({
  toggleConsent,
  panelInfo,
  isLoadingArcConsent,
  arcConsentError,
  videoControlState,
  removeVideoControlState,
  twoFactorPromptState,
  toggle,
  setToggle,
}: Props) => {
  const { t } = useI18n()
  const canChangeVideoConsent = panelInfo?.Access.find((x) => x.includes('ChangeVideoConsent'))

  return (
    <>
      <DrawerContentContainer>
        <VideoPrivacySubtitle>{t('videoprivacy_title')}</VideoPrivacySubtitle>
        <ButtonContainer>
          {canChangeVideoConsent ? (
            <div className="flex flex-wrap justify-center">
              <div className="flex flex-col content-center">
                <StyledSelectButton
                  toggle={toggle === true && true}
                  onClick={() => setToggle(!toggle)}
                >
                  <IconContainer>
                    <CheckmarkIcon color={colors.green} />
                  </IconContainer>
                  <span>{t('Yes')}</span>
                </StyledSelectButton>
                <StyledSpan>{t('videoprivacy_consent_accept')}</StyledSpan>
              </div>
              <div className="flex flex-col content-center">
                <StyledSelectButton
                  toggle={toggle === false && true}
                  onClick={() => setToggle(!toggle)}
                >
                  <IconContainer>
                    <CancelIcon color={colors.red} />
                  </IconContainer>
                  <span>{t('No')}</span>
                </StyledSelectButton>
                <StyledSpan>{t('videoprivacy_consent_decline')}</StyledSpan>
              </div>
            </div>
          ) : (
            <VideoPrivacyConsentNoAccess arcVideoConsent={panelInfo?.ArcVideoConsent!} />
          )}
        </ButtonContainer>
        {canChangeVideoConsent && (
          <StyledMainButton
            size="xl"
            disabled={isLoadingArcConsent}
            level="secondary"
            onClick={toggleConsent}
            className=""
          >
            {isLoadingArcConsent ? <span>{t('Updating')}</span> : <span> {t('Save')}</span>}
          </StyledMainButton>
        )}
        {isLoadingArcConsent && (
          <div className="flex align-center mb-5">
            <Loader />
            <VideoPrivacyConsentLoadingText grey light>
              {t('change_in_progress')}
            </VideoPrivacyConsentLoadingText>
          </div>
        )}
        {arcConsentError && <FailedRequest text={t('Something went wrong. Please try again')} />}
        <Hr width={'100%'}></Hr>
        <VideprivacyFAQContainer>
          <SubTitle>FAQ</SubTitle>
          <VideoPrivacyTextContainer>
            <strong>{t('videoprivacy_faq_question_1')}</strong>
            <span>{t('videoprivacy_faq_answer_1')}</span>
          </VideoPrivacyTextContainer>
          <VideoPrivacyTextContainer>
            <strong>{t('videoprivacy_faq_question_2')}</strong>
            <span>{t('videoprivacy_faq_answer_2')}</span>
          </VideoPrivacyTextContainer>
          <VideoPrivacyTextContainer>
            <strong>{t('videoprivacy_faq_question_3')}</strong>
            <span>{t('videoprivacy_faq_answer_3')}</span>
          </VideoPrivacyTextContainer>
        </VideprivacyFAQContainer>
        <VideoControlModal {...videoControlState} />
        <RemoveVideoControlModal {...removeVideoControlState} />
        <TwoFactorPrompt promptState={twoFactorPromptState} />
      </DrawerContentContainer>
    </>
  )
}

const DrawerContentContainer = styled.div`
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;

  @media only screen and (max-width: 375px) {
    padding-left: 0;
  }
`

const VideoPrivacySubtitle = styled(SubTitle)`
  max-width: 460px;
  margin: 0;
  margin-bottom: 44px;
`

const ButtonContainer = styled.div`
  max-width: 323px;
  margin-bottom: 34px;
`

const StyledMainButton = styled(Button)`
  margin-bottom: 50px;
`

const VideprivacyFAQContainer = styled.div`
  max-width: 460px;
  margin: 0;
  margin-top: 39px;
  margin-bottom: 44px;
  display: flex;
  flex-direction: column;
`

const VideoPrivacyTextContainer = styled.div`
  max-width: 460px;
  margin-top: 16px;
`

const VideoPrivacyConsentLoadingText = styled(Paragraph)`
  line-height: 18px;
  padding-left: 12px;
`

export default NewConsentContent
