import React from 'react'
import styled from 'styled-components'

import {
  Heading3 as Title,
  Paragraph,
  Footnote,
} from '../../../components/Crow/Typography/Typography'
import { ContactPerson, ContactPersonType } from '../../../types/ContactPerson'
import useI18n from '../../../i18n/useI18n'
import { tFunc } from '../../../types/i18n'
import { formatPhoneNumber } from '../../../utils/numberField'
import { colors } from '../../../components/Crow/Style/theme'

const contactGroupTexts = (Type: number, t: tFunc) => {
  switch (Type) {
    case ContactPersonType.RESIDENT:
      return {
        groupTitle: t('SELECT_CONTACT_GROUP_RESIDENT_TITLE'),
        groupDescription: t('people_contact_active_users_description'),
      }
    case ContactPersonType.NEIGHBOR:
      return {
        groupTitle: t('SELECT_CONTACT_GROUP_NEIGHBOUR_TITLE'),
        groupDescription: t('SELECT_CONTACT_GROUP_NEIGHBOUR_DESCRIPTION'),
      }
    case ContactPersonType.OTHER:
      return {
        groupTitle: t('SELECT_CONTACT_GROUP_EMERGENCY_TITLE'),
        groupDescription: t('SELECT_CONTACT_GROUP_EMERGENCY_DESCRIPTION'),
      }
    default:
      return {
        groupTitle: '',
        groupDescription: '',
      }
  }
}

interface Props {
  contact: ContactPerson
  isLoading?: boolean
}

const EditContactForm = ({ contact, isLoading }: Props) => {
  const { t } = useI18n()

  const { groupTitle, groupDescription } = contactGroupTexts(contact.Type, t)

  return (
    <Wrapper>
      <Group>
        <Title color={colors.black800}>{t('Phone number')}</Title>
        <Paragraph large grey>
          {'Mobile '.concat(formatPhoneNumber(contact.Mobile))}
        </Paragraph>
      </Group>
      <Group>
        <Title color={colors.black800}>{t('VIEW_CONTACT_GROUP_TITLE')}</Title>
        <Paragraph large grey>
          {groupTitle}
        </Paragraph>
        <Footnote color={colors.black700}>{groupDescription}</Footnote>
      </Group>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 42px;
`

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export default EditContactForm
