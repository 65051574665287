import styled from 'styled-components'
import { TemperatureIcon } from '../../../assets/icons/crow'
import {
  DoorsAndWindowsApp,
  HistoryApp,
  LeakageDetectorsApp,
  HumidityApp,
  LocksApp,
  PeopleApp,
  PhotoApp,
  SettingsApp,
  SmartPlugApp,
  SmokeDetectorsApp,
  TemperatureApp,
} from '../../../components/Crow/AppLinks/AppLinks'
import { displayTemperatureLiveApp } from '../../../configs'
import useI18n from '../../../i18n/useI18n'
import { useDoorsAndWindows } from '../../../services/requestHooks/doorsandwindows'
import { useLeakageDetectors } from '../../../services/requestHooks/leakageDetectors'
import { usePanelInfo, usePanelStatus } from '../../../services/requestHooks/panelInfo'
import { useSmokeDetectors } from '../../../services/requestHooks/smokeDetectors'
import { usePostTemperatures } from '../../../services/requestHooks/temperatureList'
import {
  useTemperatureAverageProperty,
  useTemperatureAverageSections,
  useTemperatureSettings,
} from '../../../services/requestHooks/temperatureSettings'
import { Capability, isPanelArmed } from '../../../types/PanelModel'
import {
  PropertyAverageTemperature,
  TemperatureAverage,
  TemperaturePreference,
} from '../../../types/Temperature'
import { useOnMount } from '../../../utils/commonHooks'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'

const MiniAppContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  margin-top: 2rem;
`

const LoadingContainer = styled.div`
  margin-top: 1.25rem;
`

const CrowMiniAppList = ({ openDoorOrWindow }: { openDoorOrWindow: boolean }) => {
  const panelId = usePanelId()
  const { t } = useI18n()
  const displayTemperatureSetting = displayTemperatureLiveApp === 'true'

  const {
    run: getTemperatures,
    isLoading: loadingTemperatures,
    data: temperatures,
  } = usePostTemperatures()

  const { run: getHumidity, isLoading: loadingHumidity } = useSmokeDetectors()

  const {
    run: getDoorsAndWindows,
    isLoading: loadingDoorsAndWindows,
    data: doorsAndWindows,
  } = useDoorsAndWindows()

  const {
    data: smokeDetectors,
    run: getSmokeDetectors,
    isLoading: loadingSmokeDetectors,
  } = useSmokeDetectors()

  const {
    data: leakageDetectors,
    run: getLeakageDetectors,
    isLoading: loadingLeakageDetectors,
  } = useLeakageDetectors()

  const {
    data: panelStatus,
    run: loadPanelStatus,
    isLoading: loadingPanelStatus,
  } = usePanelStatus()
  const {
    run: getTemperaturesSettings,
    isLoading: loadingTemperaturesSettings,
    data: temperatureSettings,
  } = useTemperatureSettings({
    onSuccess: ({ response }: any) => {
      response?.forEach((Settings: TemperaturePreference) => {
        if (Settings.Key === 'TemperatureLiveApp') {
          if (!loadingTemperaturesSections && Settings.Value.Option !== 'average') {
            getTemperaturesSections(panelId, Settings.Value.Option)
          } else if (!loadingTemperaturesAverage) {
            getTemperaturesAverage(panelId)
          }
        }
      })
    },
  })
  const {
    run: getTemperaturesAverage,
    isLoading: loadingTemperaturesAverage,
    data: temperaturesPropertyAverage,
  } = useTemperatureAverageProperty()
  const {
    run: getTemperaturesSections,
    isLoading: loadingTemperaturesSections,
    data: temperaturesAverageSections,
  } = useTemperatureAverageSections()

  let newTemperatureAverage: PropertyAverageTemperature[] = []
  if (temperaturesPropertyAverage) {
    newTemperatureAverage.push({
      AverageTemperature: temperaturesPropertyAverage?.AverageTemperature!,
      Key: 'average_property_temperature',
    })
  }
  let newTemperatureAverageList: TemperatureAverage = { Averages: newTemperatureAverage }
  // Note: For Camera mini app, need to update when we have a proper endpoint

  // To be used for checking if panel displays humidity info
  const { data: panelInfo, run: loadPanelInfo, isLoading: loadingPanelInfo } = usePanelInfo()

  useOnMount(() => {
    if (!loadingTemperatures) {
      getTemperatures(panelId)
    }
    if (!loadingHumidity) {
      getHumidity(panelId)
    }
    if (!loadingDoorsAndWindows) {
      getDoorsAndWindows(panelId)
    }

    if (!loadingSmokeDetectors) {
      getSmokeDetectors(panelId)
    }
    if (!loadingLeakageDetectors) {
      getLeakageDetectors(panelId)
    }
    if (!loadingPanelInfo) {
      loadPanelInfo(panelId)
    }

    if (!loadingPanelStatus) {
      loadPanelStatus({ panelId })
    }
    if (!loadingTemperaturesSettings && displayTemperatureSetting) {
      getTemperaturesSettings(panelId)
    }
  })

  const isLoading =
    loadingTemperatures ||
    loadingHumidity ||
    loadingDoorsAndWindows ||
    loadingSmokeDetectors ||
    loadingLeakageDetectors ||
    loadingPanelInfo ||
    loadingTemperaturesSettings ||
    loadingTemperaturesSections ||
    loadingTemperaturesAverage

  const CountOpenDoorOrWindows = doorsAndWindows?.Floors.flatMap((floors) => floors.Rooms)
    .flatMap((room) => room.Devices)
    .filter((isClosed) => !isClosed.Closed).length

  return (
    <>
      {isLoading ? (
        <LoadingContainer>{t('Loading')}</LoadingContainer>
      ) : (
        <MiniAppContainer data-testid="mini-apps" className="qa-miniapp-container">
          {temperatures?.Sections.length! > 0 && (
            <>
              <TemperatureApp
                Icon={TemperatureIcon}
                averageList={
                  temperatureSettings?.length! > 0 &&
                  temperatureSettings![0].Value.Option === 'average'
                    ? newTemperatureAverageList
                    : temperaturesAverageSections
                }
                propertyName={
                  temperatureSettings?.length! > 0 ? temperatureSettings![0].Value.Option : ''
                }
              />
            </>
          )}
          {panelInfo?.HasHumidityReading! && <HumidityApp />}
          {doorsAndWindows?.Floors.length! > 0 && (
            <DoorsAndWindowsApp
              countOpenDoorOrWindows={CountOpenDoorOrWindows!}
              displayExclamationMark={!!panelStatus?.Status && isPanelArmed(panelStatus?.Status)}
            />
          )}

          {panelInfo?.Locks?.length! > 0 && <LocksApp />}
          {!panelInfo?.Capabilities.includes(Capability.USE_LEGACY_PEOPLE) && <PeopleApp />}
          {smokeDetectors?.Floors.length! > 0 && <SmokeDetectorsApp />}
          {leakageDetectors?.Floors.length! > 0 && <LeakageDetectorsApp />}
          {panelInfo?.Photos.length! > 0 && <PhotoApp />}
          {/* <VideoApp /> */}
          {panelInfo?.Smartplugs?.length! > 0 && <SmartPlugApp />}
          {/* Lights App */}

          <HistoryApp />
          <SettingsApp />
        </MiniAppContainer>
      )}
    </>
  )
}

export default CrowMiniAppList
