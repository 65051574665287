const WifiOnIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9991 2.25012C7.65083 2.25012 4.43687 3.5671 2.05132 5.91664C1.65784 6.30419 1.02469 6.29937 0.637147 5.90589C0.249603 5.51241 0.254417 4.87926 0.6479 4.49172C3.40761 1.77366 7.12567 0.250122 10.9991 0.250122C14.8726 0.250122 18.5907 1.77366 21.3504 4.49172C21.7439 4.87926 21.7487 5.51241 21.3611 5.90589C20.9736 6.29937 20.3405 6.30419 19.947 5.91664C17.5614 3.5671 14.3475 2.25012 10.9991 2.25012Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M5.2279 9.10143C6.77073 7.59405 8.8421 6.75012 10.9991 6.75012C13.1561 6.75012 15.2274 7.59405 16.7703 9.10143C17.1653 9.4874 17.7984 9.48004 18.1844 9.085C18.5703 8.68997 18.563 8.05684 18.168 7.67088C16.2515 5.79843 13.6784 4.75012 10.9991 4.75012C8.31972 4.75012 5.74669 5.79843 3.83021 7.67088C3.43517 8.05684 3.42782 8.68997 3.81378 9.085C4.19974 9.48004 4.83286 9.4874 5.2279 9.10143Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M8.40166 12.2913C9.10126 11.623 10.0315 11.2501 10.999 11.2501C11.9665 11.2501 12.8967 11.623 13.5963 12.2913C13.9957 12.6727 14.6287 12.6582 15.0102 12.2589C15.3916 11.8595 15.3771 11.2265 14.9778 10.845C13.906 9.82134 12.481 9.25012 10.999 9.25012C9.51694 9.25012 8.09192 9.82134 7.02022 10.845C6.62085 11.2265 6.60634 11.8595 6.98781 12.2589C7.36929 12.6582 8.00229 12.6727 8.40166 12.2913Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M12.1248 14.7499C12.1248 15.3712 11.6211 15.8749 10.9998 15.8749C10.3784 15.8749 9.87476 15.3712 9.87476 14.7499C9.87476 14.1286 10.3784 13.6249 10.9998 13.6249C11.6211 13.6249 12.1248 14.1286 12.1248 14.7499Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { WifiOnIcon }
