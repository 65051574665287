const PartiallyArmedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1779 0.405615L20.1481 6.42384C20.6731 6.82622 20.9968 7.47356 20.9968 8.16467V18.2417C20.9968 19.2126 20.2357 19.9999 19.3082 19.9999H14.6101C13.4814 19.9999 12.5628 19.0378 12.5628 17.8569V15.0314C12.5628 14.6203 12.2653 14.2791 11.9067 14.2791H9.85943C9.50943 14.2791 9.21199 14.6203 9.21199 15.0227V17.8481C9.21199 19.0378 8.29332 19.9999 7.16477 19.9999H2.68532C1.75788 19.9999 0.996765 19.2126 0.996765 18.2417V8.18211C0.996765 7.49978 1.31177 6.85255 1.83666 6.45006L9.77188 0.414391C10.4893 -0.13674 11.4604 -0.13674 12.1779 0.405615ZM3.05625 8.03777L10.984 2.00777V12.2791H9.86275C8.15791 12.2791 7.21531 13.7838 7.21531 15.0227V17.8481C7.21531 17.9222 7.18821 17.9682 7.16666 17.9908C7.16267 17.995 7.15928 17.998 7.15673 18H3.00009V8.18213C3.00009 8.148 3.00822 8.1138 3.02244 8.08452C3.03608 8.05641 3.05052 8.04238 3.05625 8.03777Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

const SmallPartiallyArmedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.00005C8.01336 1.00017 8.02672 1.00048 8.04007 1.00098C8.31694 1.01147 8.59137 1.10601 8.82678 1.28397L14.4059 5.49673C14.7734 5.7784 15 6.23154 15 6.71531V13.7692C15 14.4489 14.4672 15 13.818 15H10.4331C9.64307 15 9 14.3265 9 13.4998V11.5C9 11.2239 8.77614 11 8.5 11H7.5C7.22386 11 7 11.2239 7 11.5V13.4937C7 14.3265 6.35693 15 5.56695 15H2.18199C1.53278 15 1 14.4489 1 13.7692V6.72752C1 6.24989 1.2205 5.79683 1.58792 5.51509L7.14258 1.29012C7.39802 1.09389 7.69941 0.997467 8 1.00005ZM8 10V2.00018C7.91382 1.99747 7.82726 2.02515 7.75177 2.08314L7.74798 2.08605L2.19532 6.30949C2.08278 6.39638 2 6.55187 2 6.72752V13.7692C2 13.9331 2.12091 14 2.18199 14H5.56695C5.75928 14 6 13.8207 6 13.4937V11.5C6 10.6716 6.67157 10 7.5 10H8Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { PartiallyArmedIcon, SmallPartiallyArmedIcon }
