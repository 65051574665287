import useI18n from '../../i18n/useI18n'

interface RoomNameType {
  roomName: string
}
const RoomName = ({ roomName }: RoomNameType) => {
  const { t } = useI18n()

  switch (roomName) {
    case 'INDOOR_GARAGE_PLACE':
      return t('GARAGE_PLACE')
    case 'UNKNOWN':
      return t('Unknown')
    default:
      return t(roomName)
  }
}

export default RoomName
