import React, { ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { CheckmarkIcon, InfoIcon } from '../../../assets/icons/crow'
import { CancelIcon } from '../../../assets/icons/crow/CancelIcon'
import { colors } from '../Style/theme'

const moveOutTime = 0.5

type ToastType = 'success' | 'error' | 'warning' | 'white'

type Options = {
  time?: number
  type?: ToastType
  title: string
  content?: ReactNode
  showClose?: boolean
}

let addToast: (options: Options) => void = () => {}

const Toast = () => {
  const [toasts, setToasts] = useState<
    {
      id: string
      type?: ToastType
      title: string
      content?: ReactNode
      showClose: boolean
      onCloseClick: () => void
      isUnmounting: boolean
    }[]
  >([])

  useEffect(() => {
    addToast = (options) => {
      const { time, type, title, content, showClose = true } = options
      const timeBeforeClose = time || (!showClose ? 5000 : undefined)
      const id = uuidv4()
      const removeToast = () => {
        setToasts((prevValue) =>
          prevValue.map((toast) => {
            if (toast.id === id) {
              return { ...toast, isUnmounting: true }
            }
            return toast
          }),
        )
        setTimeout(() => {
          setToasts((prevValue) => prevValue.filter((toast) => toast.id !== id))
        }, moveOutTime * 1000)
      }
      setToasts((prevValue) => [
        ...prevValue,
        { id, type, title, content, showClose, onCloseClick: removeToast, isUnmounting: false },
      ])
      if (timeBeforeClose) {
        setTimeout(removeToast, timeBeforeClose + moveOutTime * 1000)
      }
    }
  }, [])

  const render =
    toasts.length > 0 ? (
      <S.Wrapper>
        <S.Container>
          {toasts.map(({ id, type, title, content, showClose, onCloseClick, isUnmounting }) => {
            return (
              <S.Toast key={id} type={type} content={content} isUnmounting={isUnmounting}>
                <S.MainContent content={content}>
                  <S.TitleWrapper>
                    {type === 'success' ? (
                      <CheckmarkIcon color={toastColorPicker(type)} />
                    ) : (
                      <InfoIcon color={toastColorPicker(type)} />
                    )}
                    <S.Title>{title}</S.Title>
                  </S.TitleWrapper>
                  {showClose && (
                    <S.Close name="Close" onClick={onCloseClick}>
                      <CancelIcon color={colors.blue} />
                    </S.Close>
                  )}
                </S.MainContent>
                {content && (
                  <>
                    <S.Separator />
                    <S.SupplementaryContent>{content}</S.SupplementaryContent>
                  </>
                )}
              </S.Toast>
            )
          })}
        </S.Container>
      </S.Wrapper>
    ) : null

  return createPortal(render, document.body)
}

const toastColorPicker = (toastType?: ToastType) => {
  switch (toastType) {
    case 'success':
      return colors.green
    case 'error':
      return colors.red
    case 'warning':
      return colors.yellow
    default:
      return colors.blue
  }
}

const S = {
  Wrapper: styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 300px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    z-index: 11;
  `,
  Container: styled.div`
    position: absolute;
    bottom: 32px;
    right: 32px;

    @media only screen and (max-width: 600px) {
      bottom: 0;
      right: 0;
    }
  `,
  Toast: styled.div<{
    type?: 'success' | 'error' | 'warning' | 'white'
    content?: ReactNode
    isUnmounting: boolean
  }>`
    width: 420px;
    border-radius: 24px;
    margin: 12px;
    display: grid;
    gap: ${({ content }) => (content ? '15px' : '0')};
    grid-template-rows: auto auto 1fr;
    grid-template-areas: 'main' 'separator' 'supplement';
    align-items: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.04),
      0px 8px 8px rgba(0, 0, 0, 0.08), 0px 32px 32px rgba(0, 0, 0, 0.08);
    background-color: ${colors.white};
    color: ${colors.black900};

    animation-duration: ${({ isUnmounting }) => (isUnmounting ? moveOutTime : 0.5)}s;
    animation-name: ${({ isUnmounting }) => (isUnmounting ? 'moveout' : 'movein')};
    animation-timing-function: ${({ isUnmounting }) =>
      isUnmounting ? 'cubic-bezier(.53,-0.34,.81,.54)' : 'cubic-bezier(.25,.5,.11,1.25)'};
    @keyframes movein {
      from {
        transform: translateX(calc(400px));
      }
      to {
        transform: translateX(0);
      }
    }
    @keyframes moveout {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(calc(400px));
      }
    }

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  `,
  MainContent: styled.div<{ content?: ReactNode }>`
    grid-area: main;
    padding: ${({ content }) => (content ? '18px 22px 0 22px' : '10px 22px')};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  TitleWrapper: styled.div`
    display: flex;
  `,
  Title: styled.div`
    margin-left: 12px;
    font-size: 22px;
    line-height: 28px;
  `,
  Close: styled.button`
    display: flex;
    font-size: 14px;
  `,
  Separator: styled.div`
    grid-area: separator;
    width: inherit;
    height: 1px;
    background-color: ${colors.black100};
  `,
  SupplementaryContent: styled.div`
    grid-area: supplement;
    padding: 0 22px 22px 22px;
    line-height: 22px;
    overflow-wrap: break-word;
    inline-size: 420px;
  `,
}

export { addToast }

export default Toast
