const CameraIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 9C15 11.2091 13.2091 13 11 13C8.79086 13 7 11.2091 7 9C7 6.79086 8.79086 5 11 5C13.2091 5 15 6.79086 15 9ZM13 9C13 10.1046 12.1046 11 11 11C9.89543 11 9 10.1046 9 9C9 7.89543 9.89543 7 11 7C12.1046 7 13 7.89543 13 9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 0C7.66562 0 7.35339 0.167101 7.16792 0.4453L5.96424 2H2.5C1.83696 2 1.20107 2.26339 0.732233 2.73223C0.263392 3.20107 0 3.83696 0 4.5V14.5C0 15.163 0.263392 15.7989 0.732233 16.2678C1.20107 16.7366 1.83696 17 2.5 17H19.5C20.163 17 20.7989 16.7366 21.2677 16.2678C21.7366 15.7989 22 15.163 22 14.5V4.5C22 3.83696 21.7366 3.20107 21.2677 2.73223C20.7989 2.26339 20.163 2 19.5 2H16.0346L14.832 0.4453C14.6466 0.167101 14.3343 0 14 0H7.99998ZM7.33148 3.5547L8.49997 2H13.4296L14.6674 3.5547C14.8528 3.8329 15.1651 4 15.4994 4H19.5C19.6326 4 19.7598 4.05268 19.8535 4.14645C19.9473 4.24022 20 4.36739 20 4.5V14.5C20 14.6326 19.9473 14.7598 19.8535 14.8536C19.7598 14.9473 19.6326 15 19.5 15H2.5C2.36739 15 2.24022 14.9473 2.14645 14.8536C2.05268 14.7598 2 14.6326 2 14.5V4.5C2 4.36739 2.05268 4.24022 2.14645 4.14645C2.24022 4.05268 2.36739 4 2.5 4H6.49943C6.83378 4 7.14601 3.8329 7.33148 3.5547Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { CameraIcon }
