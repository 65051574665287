import { RootState } from '../../store'
import {
  getTemperaturePreference,
  postTemperaturePreference,
  deleteTemperaturePreference,
} from '../userPreferenceApi'
import { createUseRequest } from './createUseRequest'
import { temperaturePropertyAverage, temperatureSectionsAverage } from '../apiHouseCheck'
import {
  GET_TEMPERATUREPREFERENCE,
  POST_TEMPERATUREPREFERENCE,
  DELETE_TEMPERATUREPREFERENCE,
  GET_TEMPERATUREAVERAGEPROPERTY,
  GET_TEMPERATUREAVERAGESECTIONS,
} from '../../reducers/temperatureUserPreferenceSlice'

export const useTemperatureSettings = createUseRequest({
  successActionCreator: GET_TEMPERATUREPREFERENCE,
  apiCall: getTemperaturePreference,
  selector: (state: RootState, panelId: string) =>
    state.temperatureSettings[panelId]?.temperatureUserPreference,
  requirePanelId: true,
})
export const usePostTemperatureSettings = createUseRequest({
  successActionCreator: POST_TEMPERATUREPREFERENCE,
  apiCall: postTemperaturePreference,
  requirePanelId: true,
})
export const useResetTemperatureSettings = createUseRequest({
  successActionCreator: DELETE_TEMPERATUREPREFERENCE,
  apiCall: deleteTemperaturePreference,
  requirePanelId: true,
})

export const useTemperatureAverageProperty = createUseRequest({
  successActionCreator: GET_TEMPERATUREAVERAGEPROPERTY,
  apiCall: temperaturePropertyAverage,
  selector: (state: RootState, panelId: string) =>
    state.temperatureAverage[panelId]?.temperaturePropertyAverage,
  requirePanelId: true,
})
export const useTemperatureAverageSections = createUseRequest({
  successActionCreator: GET_TEMPERATUREAVERAGESECTIONS,
  apiCall: temperatureSectionsAverage,
  selector: (state: RootState, panelId: string) =>
    state.temperatureAverage[panelId]?.temperatureAverage,
  requirePanelId: true,
})
