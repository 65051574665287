import directions from '../pages/AlarmSystems/Settings/Directions/state/directionsReducer'
import panelUsers from '../pages/AlarmSystems/Settings/PanelUsers/state/panelUsersSlice'
import addDeviceSlice from './addDeviceSlice'
import appUserRegistration from './appUserRegistrationSlice'
import appUsers from './appUserSlice'
import benefitsSlice from './benefitsSlice'
import { bookingReducer } from './bookingSlice'
import { contactsReducer } from './contactsSlice'
import { customerFeedbackReducer } from './customerFeedbackSlice'
import { doorsandwindowsListReducer } from './doorsandwindowsSlice'
import { historyReducer } from './historySlice'
import { humidityReducer } from './humiditySlice'
import invoices from './invoiceSlice'
import { leakageDetectorsReducer } from './leakageDetectorsSlice'
import locks from './locksReducer'
import messages from './messagesSlice'
import { notificationSettingsReducer } from './notificationSlice'
import order from './orderSlice'
import panel from './panelInfoSlice'
import plugs from './plugsReducer'
import { propertyContactsReducer } from './propertyContactSlice'
import registration from './registrationSlice'
import { requestReducer } from './requestSlice'
import { securityQuestionReducer } from './securityQuestionSlice'
import { simpleRegistrationReducer } from './simpleRegistrationSlice'
import { smokeDetectorsReducer } from './smokeDetectorsSlice'
import { systemListReducer } from './systemListSlice'
import { temperatureHousecheckReducer, temperatureListReducer } from './temperatureListSlice'
import {
  temperatureAverageReducer,
  temperaturePreferenceReducer,
} from './temperatureUserPreferenceSlice'
import termsSlice from './termsSlice'
import { userReducer } from './userSlice'
import usersReducer from './usersSlice'
import { wifiReducer } from './wifiSlice'
const combinedReducers = {
  registration,
  user: userReducer,
  directions,
  plugs,
  locks,
  panelUsers,
  history: historyReducer,
  invoices: invoices,
  messages,
  appUsers,
  systemList: systemListReducer,
  request: requestReducer,
  temperatureList: temperatureListReducer,
  temperatureHousecheckReducer: temperatureHousecheckReducer,
  contacts: contactsReducer,
  securityQuestion: securityQuestionReducer,
  notificationSettings: notificationSettingsReducer,
  appUserRegistration,
  booking: bookingReducer,
  propertyContacts: propertyContactsReducer,
  order,
  panel,
  wifi: wifiReducer,
  simpleRegistration: simpleRegistrationReducer,
  addDevice: addDeviceSlice,
  terms: termsSlice,
  benefits: benefitsSlice,
  doorsAndWindowsList: doorsandwindowsListReducer,
  users: usersReducer,
  smokeDetectors: smokeDetectorsReducer,
  leakageDetectors: leakageDetectorsReducer,
  temperatureSettings: temperaturePreferenceReducer,
  temperatureAverage: temperatureAverageReducer,
  customerFeedback: customerFeedbackReducer,
  humidity: humidityReducer,
}

export default combinedReducers
