const SwitchIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2929 9.70711C13.9024 9.31658 13.9024 8.68342 14.2929 8.29289L16.5858 6L8.92857 6C8.41574 6 8 5.55228 8 5C8 4.44772 8.41574 4 8.92857 4L16.5858 4L14.2929 1.70711C13.9024 1.31658 13.9024 0.683417 14.2929 0.292894C14.6834 -0.0976312 15.3166 -0.0976312 15.7071 0.292894L19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L15.7071 9.70711C15.3166 10.0976 14.6834 10.0976 14.2929 9.70711Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M5.70711 4.29289C6.09763 4.68342 6.09763 5.31658 5.70711 5.70711L3.41421 8L11 8C11.5523 8 12 8.44772 12 9C12 9.55229 11.5523 10 11 10L3.41421 10L5.70711 12.2929C6.09763 12.6834 6.09763 13.3166 5.70711 13.7071C5.31658 14.0976 4.68342 14.0976 4.29289 13.7071L0.292893 9.70711C-0.0976311 9.31658 -0.0976311 8.68342 0.292893 8.29289L4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { SwitchIcon }
