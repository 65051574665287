import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { DisarmedIcon } from '../../../assets/icons/crow'
import { PanelStatus } from '../../../types/PanelModel'
import RadioButton from '../RadioButton/RadioButton'
import { colors } from '../Style/theme'

interface PropertyOptionProps {
  name: string
  selected: boolean
  onClick: (name: string) => void
  panelId: string
  panelTime: string
  panelStatus?: PanelStatus
}
const PropertyOption = ({ name, selected, onClick, panelId }: PropertyOptionProps) => {
  return (
    <Link
      className="no-underline"
      to={'/'}
      onClick={() => (window.location.href = `/systems/${panelId}`)}
    >
      <S.PropertyOption onClick={() => onClick(name)} selected={selected}>
        <S.OptionLeft>
          <RadioButton checked={selected} />
          <S.OptionText>
            <S.PropertyTitle>{name}</S.PropertyTitle>
          </S.OptionText>
        </S.OptionLeft>
        <DisarmedIcon color={colors.white} />
      </S.PropertyOption>
    </Link>
  )
}

const S = {
  PropertyOption: styled.div<{ selected: boolean }>`
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    i {
      font-size: 30px;
    }

    ${({ selected }) => {
      return (
        selected &&
        css`
          border-radius: 8px;
          background-color: ${colors.black700};
        `
      )
    }}
  `,
  OptionLeft: styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
  `,
  PropertyTitle: styled.div`
    font-size: 18px;
    font-weight: 500;
    color: ${colors.white};
  `,
  PropertyDesc: styled.div`
    color: ${colors.black500};
  `,
  OptionText: styled.div`
    margin-left: 1rem;
  `,
}

export default PropertyOption
