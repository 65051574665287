const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ? props.width : 20}
      height={props.height ? props.height : 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 9.25C8.25 8.69771 8.69771 8.25 9.25 8.25H10C10.5523 8.25 11 8.69771 11 9.25V13.5315C11.4313 13.6425 11.75 14.034 11.75 14.5C11.75 15.0523 11.3023 15.5 10.75 15.5H10C9.44772 15.5 9 15.0523 9 14.5V10.2185C8.56869 10.1075 8.25 9.71596 8.25 9.25Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M11.125 5.875C11.125 6.49632 10.6213 7 10 7C9.37868 7 8.875 6.49632 8.875 5.875C8.875 5.25368 9.37868 4.75 10 4.75C10.6213 4.75 11.125 5.25368 11.125 5.875Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { InfoIcon }
