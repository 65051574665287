import styled from 'styled-components'
import Button from '../components/Crow/Button/Button'
import { Title } from '../components/Crow/Typography'
import BackgroundImagePage from '../components/pages/BackgroundImagePage'
import useI18n from '../i18n/useI18n'
import { useAuth } from '../services/authentication/AuthContext'

const UserBlockedPage = () => {
  const { t } = useI18n()
  const { logout } = useAuth()

  return (
    <BackgroundImagePage>
      <Wrapper>
        <Title>{t('USER_BLOCKED_ERROR')}</Title>
        <Button level="primary" onClick={() => logout()}>
          {t('Go to login')}
        </Button>
      </Wrapper>
    </BackgroundImagePage>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  & > *:first-child {
    text-align: center;
  }

  & > *:last-child {
    margin-top: 20px;
  }
`

export default UserBlockedPage
