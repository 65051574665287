import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { useCallback } from 'react'
import { Selector, useDispatch, useSelector } from 'react-redux'
import { EXPIRES_AT, VALIDATION_CODE } from '../../components/TwoFactorPrompt/constants'
import { usePanelId } from '../../pages/AlarmSystems/hooks/usePanelId'
import { REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS } from '../../reducers/requestSlice'
import { AppThunk, RootState } from '../../store'
import { isTranslationKey } from '../../types/generated/TranslationKey'
import { errorCodesTriggerNew2FA } from '../../utils/errorCodesTriggerNew2FA'
import history from '../../utils/history'

function isAxiosError(error: any): error is AxiosError {
  return error.isAxiosError
}

type Callbacks<ResponseType> = {
  onSuccess?: (args: { response: ResponseType; getState: () => RootState }) => void
  onFailure?: (args: { message: string; getState: () => RootState; statusCode?: number }) => void
}

type ActionCreatorOptions<ResponseType> = Callbacks<ResponseType> & { panelId: string }

export const requestActionCreator = <ResponseType, Options extends any[]>(
  action:
    | ActionCreatorWithPayload<ResponseType>
    | ActionCreatorWithPayload<{ data: ResponseType; panelId: string }>,
  apiCall: (...options: Options) => Promise<ResponseType>,
) => {
  const actionCreator = (
    { onSuccess, onFailure, panelId }: ActionCreatorOptions<ResponseType> = { panelId: '' },
  ) => (...options: Options): AppThunk => {
    return async (dispatch, getState) => {
      const requestType = !!panelId ? `${panelId}-${action.toString()}` : action.toString()

      if (getState().request[requestType]?.isLoading) {
        // eslint-disable-next-line no-console
        // console.log(`Request: ${requestType} is already in progress`)
        return
      }

      dispatch(REQUEST_INIT({ requestType: requestType }))
      try {
        const response: ResponseType = await apiCall(...options)
        if (actionTypeGuard(panelId, action)) {
          dispatch(action({ data: response, panelId }))
        } else {
          dispatch(action(response))
        }
        onSuccess?.({ response, getState })
        dispatch(REQUEST_SUCCESS({ requestType: requestType }))
      } catch (error) {
        if (isAxiosError(error)) {
          const statusCode = error.response?.status
          const path = error.response?.config?.url?.toLowerCase()
          let message =
            error.response?.data.Error ||
            error.response?.data ||
            error.message ||
            'Networking error'

          if (errorCodesTriggerNew2FA.includes(message)) {
            sessionStorage.removeItem(VALIDATION_CODE)
            sessionStorage.removeItem(EXPIRES_AT)
          }

          if (message === 'user_blocked') {
            history.push('/blocked')
          }

          if (statusCode === 503 && !isTranslationKey(message)) {
            message = '503statuscode'
          }

          if (statusCode === 401 && !isTranslationKey(message)) {
            message = '401statuscode'
          }

          if (statusCode === 403 && path?.includes('panel/getpanel?panelid')) {
            message = 'app_access_revoked'
          }

          dispatch(REQUEST_ERROR({ requestType: requestType, error: message }))
          onFailure?.({ statusCode, message, getState })
        } else {
          throw error
        }
      }
    }
  }
  return actionCreator
}

function actionTypeGuard<Response>(
  panelId: string,
  action:
    | ActionCreatorWithPayload<Response>
    | ActionCreatorWithPayload<{ data: Response; panelId: string }>,
): action is ActionCreatorWithPayload<{ data: Response; panelId: string }> {
  return !!panelId
}

/* Without selector */
export function createUseRequest<ResponseType, Options extends any[]>(args: {
  successActionCreator: ActionCreatorWithPayload<ResponseType>
  apiCall: (...options: Options) => Promise<ResponseType>
}): (
  actions?: Callbacks<ResponseType>,
) => {
  isLoading: boolean
  error: string | undefined
  run: (...options: Options) => void
  hasLoaded: boolean
}

export function createUseRequest<ResponseType, Options extends any[]>(args: {
  successActionCreator: ActionCreatorWithoutPayload
  apiCall: (...options: Options) => Promise<ResponseType>
}): (
  actions?: Callbacks<ResponseType>,
) => {
  isLoading: boolean
  error: string | undefined
  run: (...options: Options) => void
  hasLoaded: boolean
}

/* With selector */
export function createUseRequest<ResponseType, Data, Options extends any[]>(args: {
  successActionCreator: ActionCreatorWithPayload<ResponseType>
  apiCall: (...options: Options) => Promise<ResponseType>
  selector: Selector<RootState, Data>
}): (
  actions?: Callbacks<ResponseType>,
) => {
  isLoading: boolean
  error: string | undefined
  data: Data | undefined
  run: (...options: Options) => void
  hasLoaded: boolean
}

/* For requests that require panelId */

/* Without selector */
export function createUseRequest<ResponseType, Options extends any[]>(args: {
  successActionCreator: ActionCreatorWithPayload<{
    data: ResponseType
    panelId: string
  }>
  apiCall: (...options: Options) => Promise<ResponseType>
  requirePanelId: true
}): (
  actions?: Callbacks<ResponseType>,
) => {
  isLoading: boolean
  error: string | undefined
  run: (...options: Options) => void
  hasLoaded: boolean
}

export function createUseRequest<ResponseType, Options extends any[]>(args: {
  successActionCreator: ActionCreatorWithPayload<{
    panelId: string
  }>
  apiCall: (...options: Options) => Promise<ResponseType>
  requirePanelId: true
}): (
  actions?: Callbacks<ResponseType>,
) => {
  isLoading: boolean
  error: string | undefined
  run: (...options: Options) => void
  hasLoaded: boolean
}

/* With selector */
export function createUseRequest<ResponseType, Data, Options extends any[]>(args: {
  successActionCreator: ActionCreatorWithPayload<{ data: ResponseType; panelId: string }>
  apiCall: (...options: Options) => Promise<ResponseType>
  selector: (state: RootState, panelId: string) => Data
  requirePanelId: true
}): (
  actions?: Callbacks<ResponseType>,
) => {
  isLoading: boolean
  error: any
  data: Data | undefined
  run: (...options: Options) => void
  hasLoaded: boolean
}

export function createUseRequest<ResponseType, Data, Options extends any[]>(args: {
  successActionCreator:
    | ActionCreatorWithPayload<ResponseType>
    | ActionCreatorWithPayload<{ data: ResponseType; panelId: string }>
  apiCall: (...options: Options) => Promise<ResponseType>
  selector?: (state: RootState, panelId: string) => Data
  requirePanelId?: boolean
}) {
  const { successActionCreator, apiCall, selector = () => undefined } = args
  const actionCreator = requestActionCreator(successActionCreator, apiCall)

  return (callbackOptions?: Callbacks<ResponseType>) => {
    const idFromUrl = usePanelId(!args.requirePanelId)
    const panelId = args.requirePanelId ? idFromUrl : ''
    const { onSuccess, onFailure } = callbackOptions || {}
    const actionCreatorOptions = { panelId, ...callbackOptions }
    const dispatch = useDispatch()
    const run = useCallback(
      (...options: Options) => {
        dispatch(actionCreator(actionCreatorOptions)(...options))
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onSuccess, onFailure, dispatch],
    )

    const { isLoading, hasLoaded, error } = useSelector(
      selectRequestState(successActionCreator.toString(), actionCreatorOptions.panelId),
    )
    const data = useSelector((state: RootState) => selector(state, actionCreatorOptions.panelId))
    return {
      isLoading,
      error,
      data,
      hasLoaded,
      run,
    }
  }
}

const selectRequestState = (type: string, panelId: string) => (store: RootState) => {
  const key = !!panelId ? `${panelId}-${type}` : type
  const request = store.request[key]
  const { error, isLoading, hasLoaded } = request || { isLoading: false }
  return {
    error,
    isLoading,
    hasLoaded,
  }
}
