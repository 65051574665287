import React from 'react'
import styled from 'styled-components'
import { PlusIcon } from '../../../../assets/icons/crow'
import { colors } from '../../../../components/Crow/Style/theme'
import Tooltip from '../../../../components/Crow/Tooltip'
import useI18n from '../../../../i18n/useI18n'

interface Props {
  onClick: () => void
  disabled: boolean
  className?: string
}

const AddContactButton = ({ onClick, disabled, className }: Props) => {
  const { t } = useI18n()

  return (
    <Tooltip
      text={t('system_must_be_disarmed_to_add')}
      position="right"
      active={disabled}
      shouldGrow={false}
      className={className}
    >
      <Button onClick={onClick} disabled={disabled}>
        <PlusIcon />
      </Button>
    </Tooltip>
  )
}

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${colors.blue};
  color: ${colors.white};

  &:hover {
    background-color: ${colors.lightBlue};
  }

  &:disabled {
    cursor: default;
    background-color: ${colors.black200};
    color: ${colors.white};
  }

  &:disabled:hover {
    background-color: ${colors.black200};
    color: ${colors.white};
  }
`

export default AddContactButton
