const StorageRoomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 14H21V25H5V14Z" fill="#E3E1E1" />
      <path d="M5 33H21V44H5V33Z" fill="#E3E1E1" />
      <path d="M43 33H27V44H43V33Z" fill="#E3E1E1" />
      <path d="M16 17H11V19H16V17Z" fill="#333131" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 14C3 14.5523 3.44772 15 4 15V24C4 25.1046 4.89543 26 6 26H20C21.1046 26 22 25.1046 22 24V15C22.5523 15 23 14.5523 23 14V11C23 9.89543 22.1046 9 21 9H5C3.89543 9 3 9.89543 3 11V14ZM21 11H5V13H21V11ZM6 24V15H20V24H6Z"
        fill="#333131"
      />
      <path d="M16 36H11V38H16V36Z" fill="#333131" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 33C3 33.5523 3.44772 34 4 34V43C4 44.1046 4.89543 45 6 45H20C21.1046 45 22 44.1046 22 43V34C22.5523 34 23 33.5523 23 33V30C23 28.8954 22.1046 28 21 28H5C3.89543 28 3 28.8954 3 30V33ZM21 30H5V32H21V30ZM6 43V34H20V43H6Z"
        fill="#333131"
      />
      <path d="M33 36H38V38H33V36Z" fill="#333131" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 34C25.4477 34 25 33.5523 25 33V30C25 28.8954 25.8954 28 27 28H43C44.1046 28 45 28.8954 45 30V33C45 33.5523 44.5523 34 44 34V43C44 44.1046 43.1046 45 42 45H28C26.8954 45 26 44.1046 26 43V34ZM27 30H43V32H27V30ZM28 34V43H42V34H28Z"
        fill="#333131"
      />
    </svg>
  )
}

export { StorageRoomIcon }
