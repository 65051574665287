const AvatarIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ? props.width : '20'}
      height={props.height ? props.height : '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7029 17.4211C18.7277 15.5911 20 12.9441 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 12.9446 1.27267 15.5919 3.29796 17.4219C3.30676 17.4301 3.31572 17.4382 3.32485 17.4461C5.09505 19.0341 7.43472 20 10 20C12.5647 20 14.9039 19.0345 16.674 17.4471C16.6838 17.4386 16.6935 17.4299 16.7029 17.4211ZM10 2C5.58172 2 2 5.58172 2 10C2 11.9272 2.68146 13.6953 3.81657 15.0763C4.39754 14.3081 5.11907 13.6516 5.94692 13.1439C6.2068 12.9845 6.47499 12.8411 6.75 12.7141C5.82673 11.8476 5.25 10.6161 5.25 9.25C5.25 6.62665 7.37665 4.5 10 4.5C12.6234 4.5 14.75 6.62665 14.75 9.25C14.75 10.6161 14.1733 11.8476 13.25 12.7141C13.525 12.8411 13.7932 12.9845 14.0531 13.1439C14.881 13.6516 15.6025 14.3081 16.1835 15.0763C17.3186 13.6952 18 11.9272 18 10C18 5.58172 14.4183 2 10 2ZM14.7181 16.4613C14.2666 15.8141 13.6851 15.2643 13.0075 14.8488C12.1026 14.2938 11.0616 14 10 14C8.93842 14 7.89751 14.2938 6.99253 14.8488C6.31501 15.2643 5.73346 15.8141 5.28197 16.4613C6.60471 17.4288 8.23565 18 10 18C11.7644 18 13.3953 17.4288 14.7181 16.4613ZM10 6.5C8.48122 6.5 7.25 7.73122 7.25 9.25C7.25 10.7688 8.48122 12 10 12C11.5188 12 12.75 10.7688 12.75 9.25C12.75 7.73122 11.5188 6.5 10 6.5Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { AvatarIcon }
