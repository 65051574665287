const GuestBedroomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 31V27H12C14.2091 27 16 28.7909 16 31H5Z" fill="#E3E1E1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 22C5.55228 22 6 22.4477 6 23V26H12C14.419 26 16.4367 27.7178 16.9 30H37C39.7614 30 42 32.2386 42 35V44C42 44.5523 41.5523 45 41 45C40.4477 45 40 44.5523 40 44V42H6V44C6 44.5523 5.55228 45 5 45C4.44772 45 4 44.5523 4 44V23C4 22.4477 4.44772 22 5 22ZM40 40V35C40 33.3431 38.6569 32 37 32H6V40H40ZM6 30V28H12C13.3062 28 14.4175 28.8348 14.8293 30H6Z"
        fill="#333131"
      />
    </svg>
  )
}

export { GuestBedroomIcon }
