import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../Style/theme'

export interface Props {
  col?: boolean
  onClick?: any
  className?: string
}

const Card: FC<Props> = ({ children, col, onClick, className }) => {
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault()
      onClick(e)
    }
  }

  return (
    <Container
      role="button"
      aria-label="Card"
      col={col}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      className={className}
      tabIndex={0}
    >
      {children}
    </Container>
  )
}

const Container = styled.div<{ col?: boolean; onClick?: any }>`
    display: flex;
    ${({ col }) => {
      return (
        col &&
        css`
          flex-direction: column;
        `
      )
    }}
    background-color: ${colors.white};
    padding: 16px;
    border-radius: 12px;

    cursor: ${({ onClick }) => onClick && 'pointer'};
`

export default Card
