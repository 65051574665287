import styled from 'styled-components'
import { colors } from '../../../components/Crow/Style/theme'
import { Paragraph, SubTitle } from '../../../components/Crow/Typography'
import useI18n from '../../../i18n/useI18n'
import { deriveInitials } from '../../../utils/deriveInitials'

interface IAccountDetailsProps {
  firstName: string
  lastName: string
  email: string
  customerNumber: string
  cellPhone: string
}

const AccountDetails = ({
  firstName,
  lastName,
  email,
  customerNumber,
  cellPhone,
}: IAccountDetailsProps) => {
  const { t } = useI18n()
  return (
    <AccountDetailsContainer>
      <TextContainer>
        <SubTitle>{t('full_name')}</SubTitle>
        <Paragraph>
          {firstName} {lastName}
        </Paragraph>
      </TextContainer>
      <TextContainer>
        <SubTitle>{t('initials')}</SubTitle>
        <Paragraph>{deriveInitials(firstName, lastName)}</Paragraph>
      </TextContainer>
      <TextContainer>
        <SubTitle>{t('cell_phone')}</SubTitle>
        <Paragraph>{cellPhone}</Paragraph>
      </TextContainer>
      <TextContainer>
        <SubTitle>{t('EMAIL')}</SubTitle>
        <Paragraph>{email}</Paragraph>
      </TextContainer>
      <TextContainer>
        {customerNumber ? (
          <>
            <SubTitle>{t('customer_number')}</SubTitle>
            <Paragraph>{customerNumber}</Paragraph>
          </>
        ) : null}
      </TextContainer>
    </AccountDetailsContainer>
  )
}

const AccountDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  & > *:first-child {
    font-size: 22px;
    color: ${colors.black800};
    line-height: 28px;
  }
  & > *:last-child {
    font-size: 20px;
    color: ${colors.black600};
    line-height: 24px;
    font-weight: 100;
    margin-top: 5px;
  }
`

export { AccountDetails }
