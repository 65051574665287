const SignalIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ? props.width : '21'}
      height={props.height ? props.height : '22'}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.24963 10.9994C9.24963 10.0319 8.87674 9.10163 8.20849 8.40202C7.82702 8.00265 7.84153 7.36965 8.2409 6.98818C8.64027 6.60671 9.27327 6.62122 9.65474 7.02059C10.6784 8.09229 11.2496 9.51731 11.2496 10.9994C11.2496 12.4814 10.6784 13.9064 9.65474 14.9781C9.27327 15.3775 8.64027 15.392 8.2409 15.0105C7.84153 14.6291 7.82702 13.996 8.20849 13.5967C8.87674 12.8971 9.24963 11.9668 9.24963 10.9994Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7496 10.9993C13.7496 8.84235 12.9057 6.77097 11.3983 5.22814C11.0124 4.8331 11.0197 4.19998 11.4148 3.81402C11.8098 3.42806 12.4429 3.43542 12.8289 3.83045C14.7013 5.74694 15.7496 8.31996 15.7496 10.9993C15.7496 13.6787 14.7013 16.2517 12.8289 18.1682C12.4429 18.5632 11.8098 18.5706 11.4148 18.1846C11.0197 17.7987 11.0124 17.1655 11.3983 16.7705C12.9057 15.2277 13.7496 13.1563 13.7496 10.9993Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2496 10.9994C18.2496 7.65108 16.9327 4.43711 14.5831 2.05156C14.1956 1.65808 14.2004 1.02493 14.5939 0.637391C14.9874 0.249847 15.6205 0.254662 16.008 0.648144C18.7261 3.40785 20.2496 7.12591 20.2496 10.9994C20.2496 14.8729 18.7261 18.5909 16.008 21.3506C15.6205 21.7441 14.9873 21.7489 14.5939 21.3614C14.2004 20.9738 14.1956 20.3407 14.5831 19.9472C16.9327 17.5617 18.2496 14.3477 18.2496 10.9994Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M0 11.0001C-7.24234e-08 12.6569 1.34315 14.0001 3 14.0001C4.65685 14.0001 6 12.6569 6 11.0001C6 9.34321 4.65685 8.00006 3 8.00006C1.34315 8.00006 7.24234e-08 9.34321 0 11.0001Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { SignalIcon }
