const BackGardenIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 37.0002C0.447715 37.0002 0 36.5525 0 36.0002C0 35.4479 0.447715 35.0002 1 35.0002H19V7.74327L0.714128 2.30835C0.290492 2.18244 0 1.79305 0 1.3511C0 0.683146 0.643056 0.203489 1.2833 0.393881L19.5701 5.83194C20.4184 6.0842 21 6.86397 21 7.74897V35.0002H33C33.5523 35.0002 34 35.4479 34 36.0002C34 36.5525 33.5523 37.0002 33 37.0002H1Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 2.00005C28.4477 2.00005 28 2.44777 28 3.00005V3.9353C27.2744 3.84541 26.5929 3.6767 25.9804 3.52508C24.1978 3.08379 23 2.78726 23 5.00002C23 8.31372 25.6863 11 29 11C32.3137 11 35 8.31372 35 5.00002C35 2.78726 33.8022 3.08379 32.0196 3.52508C31.4071 3.6767 30.7256 3.8454 30 3.9353V3.00005C30 2.44777 29.5523 2.00005 29 2.00005ZM32.6233 5.43669C32.7552 5.40382 32.8742 5.37418 32.9851 5.34775C32.8089 7.39407 31.092 9.00002 29 9.00002C26.908 9.00002 25.1911 7.39407 25.0149 5.34775C25.1258 5.37417 25.2447 5.40381 25.3766 5.43667C25.6078 5.49427 25.879 5.56182 26.2161 5.6394C26.9878 5.81691 27.9482 6.00005 29 6.00005C30.0518 6.00005 31.0122 5.81691 31.7839 5.6394C32.1211 5.56182 32.3921 5.4943 32.6233 5.43669Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 28.8773C23.0264 28.9889 24.1089 28.7994 25.1771 28.3003L28.2041 31.3273V32H23C22.4477 32 22 32.4478 22 33.0001C22 33.5523 22.4477 34.0001 23 34.0001H41C41.5523 34.0001 42 33.5523 42 33.0001C42 32.4478 41.5523 32 41 32H30.2041V25.4142L33.3181 22.3003C35.5594 23.3476 37.8635 23.0315 39.5816 21.4319C41.411 19.7287 42.395 16.7413 42.1732 12.9705C42.1434 12.4644 41.7397 12.0607 41.2337 12.0309C37.4628 11.8091 34.4755 12.7932 32.7722 14.6226C31.1727 16.3406 30.8565 18.6447 31.9039 20.8861L30.2041 22.5858V13.0001C30.2041 12.4478 29.7564 12.0001 29.2041 12.0001C28.6518 12.0001 28.2041 12.4478 28.2041 13.0001V28.4989L26.5912 26.886C27.6386 24.6447 27.3224 22.3406 25.7229 20.6226C24.8188 19.6515 23.5529 18.9186 22 18.4778V20.5773C22.985 20.9328 23.7389 21.4267 24.2591 21.9854C25.095 22.8833 25.4205 24.0452 25.0457 25.3404L22 22.2947V25.1232L23.6315 26.7547C23.0577 26.9208 22.5101 26.9494 22 26.8571V28.8773ZM37.5839 15.206L33.4494 19.3405C33.0746 18.0453 33.4001 16.8833 34.236 15.9854C35.2839 14.8599 37.2805 13.9972 40.2044 13.9998C40.2069 16.9237 39.3443 18.9202 38.2187 19.9681C37.3208 20.8041 36.1589 21.1296 34.8636 20.7547L38.9981 16.6202C39.3886 16.2297 39.3886 15.5966 38.9981 15.206C38.6076 14.8155 37.9744 14.8155 37.5839 15.206Z"
        fill="#1D1D1B"
        fillOpacity="0.9"
      />
    </svg>
  )
}

export { BackGardenIcon }
