const HiddenIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.82747 1.01007C4.23613 0.63856 4.86858 0.668677 5.24008 1.07733L7.66133 3.7407C7.67179 3.75169 7.68202 3.76294 7.69202 3.77446L17.7878 14.8798C17.7975 14.89 17.8069 14.9004 17.8162 14.9111L20.2401 17.5773C20.6116 17.986 20.5815 18.6184 20.1728 18.9899C19.7642 19.3615 19.1317 19.3313 18.7602 18.9227L16.8165 16.7846C15.2954 17.4325 13.6548 17.762 11.9961 17.7493C7.91573 17.7481 5.05131 15.9023 3.23057 14.0818C2.32279 13.1741 1.66852 12.2682 1.23987 11.5875C1.02506 11.2463 0.865483 10.9595 0.757886 10.7541C0.704051 10.6513 0.663115 10.5687 0.634681 10.5097C0.620461 10.4801 0.609357 10.4565 0.601321 10.4391L0.591556 10.4178L0.588385 10.4108L0.587224 10.4082L0.58675 10.4071C0.448659 10.0969 2.04047 10.2406 0.586603 9.59327L0.587698 9.59082L0.589692 9.58638L0.595516 9.57356L0.614568 9.53257C0.630578 9.49859 0.653251 9.45147 0.682742 9.39254C0.741699 9.27474 0.828062 9.10945 0.943098 8.90734C1.17287 8.50365 1.51882 7.94992 1.99157 7.33295C2.75448 6.33729 3.86225 5.15795 5.36236 4.18506L3.7602 2.42268C3.3887 2.01402 3.41881 1.38157 3.82747 1.01007ZM6.72924 5.68862C5.32936 6.5345 4.29214 7.61882 3.57911 8.54939C3.12677 9.13972 2.81135 9.66003 2.62328 9.99997C2.70298 10.144 2.8057 10.3207 2.93229 10.5217C3.30051 11.1065 3.86499 11.8879 4.64471 12.6675C6.19837 14.221 8.58268 15.7493 12.0001 15.7493H12.0081C13.1566 15.7584 14.2948 15.571 15.3742 15.1981L14.3839 14.1088C13.5993 14.564 12.6941 14.7886 11.774 14.7448C10.5158 14.6848 9.33283 14.1275 8.48545 13.1954C7.63808 12.2632 7.19567 11.0327 7.25553 9.77441C7.2993 8.85437 7.60898 7.97459 8.13666 7.23679L6.72924 5.68862ZM9.53704 8.77721L12.9835 12.5683C12.6318 12.703 12.2527 12.7653 11.8693 12.747C11.1408 12.7123 10.4559 12.3897 9.96534 11.85C9.47475 11.3104 9.21861 10.5979 9.25327 9.86945C9.27152 9.48596 9.36956 9.11455 9.53704 8.77721Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M0.58675 10.4071C0.448659 10.0969 2.04047 10.2406 0.586603 9.59327C0.471696 9.85184 0.471822 10.1485 0.58675 10.4071Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M9.898 2.42129C10.5931 2.30616 11.2966 2.24885 12.0012 2.24996C16.0834 2.25021 18.9488 4.09687 20.77 5.91826C21.6778 6.82613 22.332 7.73227 22.7607 8.41312C22.9755 8.75433 23.1351 9.04123 23.2427 9.24666C23.3023 9.36053 23.3608 9.47526 23.4133 9.59262L23.4138 9.59369C23.5287 9.85226 23.5291 10.1483 23.4142 10.4069L23.4132 10.4091C23.3503 10.5467 23.2817 10.6818 23.2106 10.8154C23.0821 11.0567 22.8907 11.3928 22.6324 11.788C22.117 12.5762 21.3267 13.6116 20.2247 14.5987C19.8133 14.9671 19.1811 14.9323 18.8126 14.5209C18.4441 14.1095 18.4789 13.4773 18.8903 13.1089C19.8322 12.2653 20.5134 11.3741 20.9584 10.6935C21.1366 10.421 21.2756 10.1843 21.3772 10.0006C21.3828 9.99056 21.3854 10.0155 21.3772 10.0006C21.2975 9.85657 21.1948 9.67978 21.0682 9.47866C20.6999 8.89376 20.1354 8.11221 19.3557 7.3324C17.802 5.77854 15.4177 4.24996 12.0004 4.24996H11.9987C11.4044 4.24899 10.8111 4.2973 10.2248 4.39441C9.67994 4.48465 9.16509 4.11611 9.07484 3.57125C8.9846 3.02639 9.35314 2.51153 9.898 2.42129Z"
        fill={props.color ? props.color : 'currentColor'}
      />
      <path
        d="M12.8947 5.3339C12.3524 5.22976 11.8283 5.58501 11.7241 6.12739C11.62 6.66976 11.9752 7.19387 12.5176 7.29802C13.1022 7.41028 13.6347 7.70907 14.0351 8.14956C14.4356 8.59006 14.6824 9.14851 14.7386 9.74116C14.7907 10.291 15.2786 10.6944 15.8285 10.6423C16.3783 10.5902 16.7817 10.1022 16.7296 9.55241C16.6326 8.5291 16.2064 7.56483 15.515 6.80424C14.8236 6.04365 13.9042 5.52774 12.8947 5.3339Z"
        fill={props.color ? props.color : 'currentColor'}
      />
    </svg>
  )
}

export { HiddenIcon }
