const MasterBedroomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 23C6 21.3431 7.34315 20 9 20H39C40.6569 20 42 21.3431 42 23V29H38V27C38 25.3431 36.6569 24 35 24H29C27.3431 24 26 25.3431 26 27V29H22V27C22 25.3431 20.6569 24 19 24H13C11.3431 24 10 25.3431 10 27V29H6V23Z"
        fill="#E3E1E1"
      />
      <path
        d="M9 35.7271C8.44772 35.7271 8 36.1748 8 36.7271C8 37.2793 8.44772 37.7271 9 37.7271L39 37.7271C39.5523 37.7271 40 37.2793 40 36.7271C40 36.1748 39.5523 35.7271 39 35.7271L9 35.7271Z"
        fill="#333131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43 42H5V44C5 44.5523 4.55228 45 4 45C3.44772 45 3 44.5523 3 44V32C3 30.5194 3.8044 29.2267 5 28.5351V23C5 20.7909 6.79086 19 9 19H39C41.2091 19 43 20.7909 43 23V28.5351C44.1956 29.2267 45 30.5194 45 32V44C45 44.5523 44.5523 45 44 45C43.4477 45 43 44.5523 43 44V42ZM39 21H9C7.89543 21 7 21.8954 7 23V28H9V27C9 24.7909 10.7909 23 13 23H19C21.2091 23 23 24.7909 23 27V28H25V27C25 24.7909 26.7909 23 29 23H35C37.2091 23 39 24.7909 39 27V28H41V23C41 21.8954 40.1046 21 39 21ZM37 28H27V27C27 25.8954 27.8954 25 29 25H35C36.1046 25 37 25.8954 37 27V28ZM5 32C5 30.8954 5.89543 30 7 30H41C42.1046 30 43 30.8954 43 32V40H5V32ZM21 28V27C21 25.8954 20.1046 25 19 25H13C11.8954 25 11 25.8954 11 27V28H21Z"
        fill="#333131"
      />
    </svg>
  )
}

export { MasterBedroomIcon }
