import classNames from 'classnames'
import { MouseEventHandler, useState } from 'react'
import styled from 'styled-components'
import { UserIcon } from '../../../../../assets/icons'
import { CheckmarkIcon } from '../../../../../assets/icons/crow'
import Button from '../../../../../components/Button'
import { ErrorMessage } from '../../../../../components/Crow/ErrorMessage/ErrorMessage'
import { colors } from '../../../../../components/Crow/Style/theme'
import { Loader } from '../../../../../components/Elements/Loaders'
import ExpandableSection, {
  ExpandableSectionSummary,
} from '../../../../../components/ExpandableSection/ExpandableSection'
import useI18n from '../../../../../i18n/useI18n'
import { useResendInviteAppUser } from '../../../../../services/requestHooks/appUser'
import { AccessGroupType } from '../../../../../types/AppUser'
import '../appUser.scss'

interface Props {
  onDelete: () => void
  onSelectUser: () => void
  onUnSelectUser: () => void
  panelId: string
  isSelected: boolean
  user: {
    AppUserId: string
    AccessGroup: AccessGroupType
    PhoneNumber: string
    LastName: string
    FirstName: string
    IsInvite: boolean
  }
}

const noOp = () => {}

const AppUserListItem = ({
  user,
  isSelected,
  onDelete,
  onSelectUser,
  onUnSelectUser,
  panelId,
}: Props) => {
  const { t } = useI18n()
  const { AccessGroup, IsInvite, FirstName, LastName, PhoneNumber } = user
  const [didResendInvite, setDidResendInvite] = useState(false)

  const handleUnselect: MouseEventHandler = (e) => {
    e.stopPropagation()
    onUnSelectUser()
  }

  const {
    run: resendInviteUser,
    isLoading: loadingResend,
    error: resendInviteUserError,
  } = useResendInviteAppUser({
    onSuccess: () => {
      setDidResendInvite(true)
    },
  })

  return (
    <div
      className={classNames({
        'user-active': isSelected,
      })}
    >
      <ExpandableSection open={isSelected} onClick={isSelected ? noOp : onSelectUser} hideIndicator>
        <ExpandableSectionSummary
          className={'list-item status-list__divSelector flex flex-col px-4'}
        >
          <div className="flex flex-col flex-grow">
            <div className="flex flex-wrap pb-2 flex-grow">
              <UserIcon
                className={classNames('icon icon--tiny self-center mr-4 mt-4', {
                  'icon--user-permanent':
                    AccessGroup === AccessGroupType.LEGAL_OWNER ||
                    AccessGroup === AccessGroupType.ADMINISTRATOR,
                  'icon--user-temp':
                    AccessGroup !== AccessGroupType.LEGAL_OWNER &&
                    AccessGroup !== AccessGroupType.ADMINISTRATOR,
                })}
              />
              <div className="">
                <h1 className="text-2xl">
                  {FirstName} {LastName}
                </h1>
                <div>
                  <div className="gray">
                    {AccessGroup === AccessGroupType.LEGAL_OWNER && t('LEGAL_OWNER')}
                    {AccessGroup === AccessGroupType.ADMINISTRATOR && t('ADMINISTRATOR')}
                    {user.AccessGroup === AccessGroupType.USER && t('USER')}
                  </div>
                  {IsInvite && (
                    <div className="gray">
                      <InvitationContainer>
                        {didResendInvite ? (
                          <ResendTextContainer>
                            <ResendText>{t('invitation_resent')}</ResendText>
                            <CheckmarkIcon />
                          </ResendTextContainer>
                        ) : (
                          <span>{t('INVITATION_PENDING')}</span>
                        )}
                        {isSelected && !didResendInvite && (
                          <SimpleButton
                            onClick={() =>
                              resendInviteUser({ AppUserId: user.AppUserId, PanelId: panelId })
                            }
                          >
                            {t('resend_invite')}
                          </SimpleButton>
                        )}
                        {isSelected && loadingResend && <Loader />}
                        {isSelected && resendInviteUserError && (
                          <ErrorMessage
                            text={t('error_toast_generic_title')}
                            errorLocation="AppUserListItem"
                          />
                        )}
                      </InvitationContainer>
                    </div>
                  )}
                </div>
              </div>
              <div className="pt-4 justify-self-end flex-grow justify-end flex">{PhoneNumber}</div>
            </div>

            {isSelected && (
              <div className="flex self-end justify-end">
                {AccessGroup !== AccessGroupType.LEGAL_OWNER && (
                  <Button
                    size="small"
                    buttonType="primary"
                    onClick={onDelete}
                    className="button button--primary button--small"
                  >
                    {t('Delete')}
                  </Button>
                )}
                <Button size="small" buttonType="secondary" onClick={handleUnselect}>
                  {t('Cancel')}
                </Button>
              </div>
            )}
          </div>
        </ExpandableSectionSummary>
      </ExpandableSection>
    </div>
  )
}

const InvitationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const SimpleButton = styled.button`
  border: none;
  outline: none;
  font-size: 16px;
  color: ${colors.blue};

  &:hover {
    color: ${colors.blue};
    text-decoration: underline;
  }
`

const ResendText = styled.span`
  color: ${colors.green};
  font-size: 16px;
`

const ResendTextContainer = styled.div`
  display: flex;
  align-items: center;

  & > *:last-child {
    margin-left: 10px;
  }
`

export default AppUserListItem
