import styled from 'styled-components'
import useI18n from '../../../i18n/useI18n'
import TextField from '../../Crow/Input/TextFieldForForm'

interface Props {
  register: any
  errors: any
}

const Information = ({ register, errors }: Props) => {
  const { t } = useI18n()

  return (
    <Wrapper>
      <TextField
        id="FirstName"
        label={t('First name')}
        {...register('FirstName')}
        error={errors.FirstName?.message}
      />
      <TextField
        id="LastName"
        label={t('Last name')}
        {...register('LastName')}
        error={errors.LastName?.message}
      />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export default Information
